import {
  Button,
  Col,
  Form,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import openNotification from '../../../components/Toastr';
import boxApi from '../../../api/box';
import CreateBoxModal from '../../order/components/couriers/Fedex/components/CreateBoxModal';

function ThirdPartyShipmentBoxesStep({
  form,
  lengthUnit,
  currentStep,
  initialBoxesNumber,
  setMinShipmentWeight,
  isPalletized,
  setInitialBoxesNumber,
}) {
  const { t } = useTranslation();
  const weightUnit = lengthUnit === 'cm' ? 'kg' : 'lb';
  const maxBoxLengthUnit = { cm: 250, inch: 99 };
  const maxBoxWeightUnit = { kg: 68, lb: 150 };
  const maxPalletLengthUnit = { cm: 250, inch: 99 };
  const maxPalletWeightUnit = { kg: 2000, lb: 4500 };
  const [boxes, setBoxes] = useState([{ key: 0 }]);
  const [newBoxFormLocation, setNewBoxFormLocation] = useState(null);
  const [companyBoxes, setCompanyBoxes] = useState();
  const [isCreateBoxModalOpen, setIsCreateBoxModalOpen] = useState(false);
  const { Text } = Typography;
  const formatterNumber = (value) => value.replace(',', '.');
  const parserNumber = (value) => parseFloat(value.replace(',', '.'));
  const products = Form.useWatch('products', form);

  const onBoxSelectChange = async (value, formLocation) => {
    if (value === 'newBox') {
      setNewBoxFormLocation(formLocation);
      setIsCreateBoxModalOpen(true);
    } else {
      const box = companyBoxes.find((b) => b.id === value);
      form.setFieldValue([...formLocation, 'id'], box.id);
      form.setFieldValue([...formLocation, 'name'], box.name);
      form.setFieldValue([...formLocation, 'length'], box.length);
      form.setFieldValue([...formLocation, 'width'], box.width);
      form.setFieldValue([...formLocation, 'height'], box.height);
      form.setFieldValue([...formLocation, 'isPallet'], box.isPallet);
    }
  };

  const createBoxCallback = (box) => {
    form.setFieldValue([...newBoxFormLocation, 'id'], box.id);
    form.setFieldValue([...newBoxFormLocation, 'name'], box.name);
    form.setFieldValue([...newBoxFormLocation, 'length'], box.length);
    form.setFieldValue([...newBoxFormLocation, 'width'], box.width);
    form.setFieldValue([...newBoxFormLocation, 'height'], box.height);
    form.setFieldValue([...newBoxFormLocation, 'isPallet'], box.isPallet);
  };

  const getCompanyBoxes = async () => {
    try {
      const { data } = await boxApi.getByCompanyId(
        lengthUnit.toUpperCase(),
        isPalletized
      );
      setCompanyBoxes(data);
    } catch (error) {
      openNotification({
        status: false,
        content: 'Ha ocurrido un error al obtener las cajas.',
      });
    }
  };
  useEffect(async () => {
    await getCompanyBoxes();
    const previousBoxes = [];
    for (
      let boxNumber = boxes.length;
      boxNumber < initialBoxesNumber;
      boxNumber += 1
    ) {
      previousBoxes.push({ key: boxNumber });
    }
    setBoxes([...boxes, ...previousBoxes]);
    setInitialBoxesNumber();
  }, [initialBoxesNumber]);

  useEffect(async () => {
    if (!initialBoxesNumber) {
      setBoxes([{ key: 0 }]);
      await form.setFieldValue(['boxes'], { 0: {} });
      await getCompanyBoxes();
    }
  }, [isPalletized]);

  const deleteBoxes = (key) => {
    const newData = boxes.filter((item) => item.key !== key);
    const newBoxes = form.getFieldsValue(['boxes']).boxes;
    delete newBoxes[key];
    form.setFieldValue(['boxes'], newBoxes);
    setBoxes(newData);
  };

  const columns = [
    {
      title: t('common.name'),
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      render: (_value, _record, index) => {
        return (
          <Form.Item
            name={['boxes', index, 'name']}
            rules={[{ required: true, message: 'Requerido' }]}
          >
            <Select
              placeholder={
                isPalletized
                  ? t('thirdPartyShipments.pallets.selectPalletPlaceholder')
                  : t('thirdPartyShipments.boxes.selectBoxPlaceholder')
              }
              onChange={(value) => {
                const formLocation = ['boxes', index];
                onBoxSelectChange(value, formLocation);
              }}
              disabled={currentStep > 0}
            >
              {companyBoxes &&
                companyBoxes.map((box) => {
                  return (
                    <Select.Option key={box.id} value={box.id}>
                      {box.name}
                    </Select.Option>
                  );
                })}
              <Select.Option key="newBox" value="newBox">
                <PlusOutlined /> Nueva información de caja
              </Select.Option>
            </Select>
          </Form.Item>
        );
      },
    },
    {
      title: t('common.length'),
      dataIndex: 'length',
      key: 'length',
      width: `18%`,
      render: (_text, record, index) => (
        <Form.Item
          name={['boxes', index, 'length']}
          rules={[
            {
              required: true,
              message: 'Requerido',
            },
            {
              validator: (_, value) =>
                value > 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error('La dimensión debe ser mayor a 0')
                    ),
            },
          ]}
        >
          <InputNumber
            min={0}
            max={
              isPalletized
                ? maxPalletLengthUnit[lengthUnit]
                : maxBoxLengthUnit[lengthUnit]
            }
            addonAfter={<Text>{lengthUnit}</Text>}
            formatter={formatterNumber}
            parser={parserNumber}
            disabled
          />
        </Form.Item>
      ),
    },
    {
      title: t('common.width'),
      dataIndex: 'width',
      key: 'width',
      width: `18%`,
      render: (_text, record, index) => (
        <Form.Item
          name={['boxes', index, 'width']}
          initialValue={record.width}
          rules={[
            {
              required: true,
              message: 'Requerido',
            },
            {
              validator: (_, value) =>
                value > 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error('La dimensión debe ser mayor a 0')
                    ),
            },
          ]}
        >
          <InputNumber
            min={0}
            max={
              isPalletized
                ? maxPalletLengthUnit[lengthUnit]
                : maxBoxLengthUnit[lengthUnit]
            }
            addonAfter={<Text>{lengthUnit}</Text>}
            formatter={formatterNumber}
            parser={parserNumber}
            disabled
          />
        </Form.Item>
      ),
    },
    {
      title: t('common.height'),
      dataIndex: 'height',
      key: 'height',
      width: `18%`,
      render: (_text, record, index) => (
        <Form.Item
          name={['boxes', index, 'height']}
          initialValue={record.height}
          rules={[
            {
              required: true,
              message: 'Requerido',
            },
            {
              validator: (_, value) =>
                value > 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error('La dimensión debe ser mayor a 0')
                    ),
            },
          ]}
        >
          <InputNumber
            min={0}
            max={
              isPalletized
                ? maxPalletLengthUnit[lengthUnit]
                : maxBoxLengthUnit[lengthUnit]
            }
            addonAfter={<Text>{lengthUnit}</Text>}
            formatter={formatterNumber}
            parser={parserNumber}
            disabled
          />
        </Form.Item>
      ),
    },
    {
      title: t('common.weight'),
      dataIndex: 'weight',
      key: 'weight',
      width: '25%',
      render: (_value, _record, index) => {
        return (
          <Form.Item
            name={['boxes', index, 'weight']}
            rules={[{ required: true, message: 'Requerido' }]}
          >
            <InputNumber
              placeholder="0.0"
              min={0.1}
              max={
                isPalletized
                  ? maxPalletWeightUnit[weightUnit]
                  : maxBoxWeightUnit[weightUnit]
              }
              formatter={formatterNumber}
              addonAfter={<Text>{weightUnit}</Text>}
              parser={parserNumber}
              precision={2}
              disabled={currentStep > 0}
            />
          </Form.Item>
        );
      },
    },
    {
      title: '',
      dataIndex: 'erase',
      width: `5%`,
      render: (_, record) => (
        <Popconfirm
          title={t('orders.newOrder.boxes.erase')}
          onConfirm={() => deleteBoxes(record.key)}
          disabled={currentStep > 0}
        >
          <DeleteTwoTone twoToneColor="#646464" />
        </Popconfirm>
      ),
    },
  ];

  const handleAddBox = () => {
    setBoxes([...boxes, { key: boxes.length }]);
  };

  useEffect(() => {
    const weight = products?.reduce((minWeight, product) => {
      if (product?.weight && product?.quantity) {
        return minWeight + product.weight * product.quantity;
      }
      return 0;
    }, 0);
    setMinShipmentWeight(weight);
  }, [products]);

  return (
    <Col span={24}>
      <Form form={form}>
        <Table columns={columns} dataSource={boxes} pagination={false} />
      </Form>
      <Button
        icon={<PlusOutlined />}
        onClick={handleAddBox}
        disabled={currentStep > 0}
        className="addRowTableButton"
      >
        {isPalletized
          ? t('thirdPartyShipments.pallets.newPallet')
          : t('thirdPartyShipments.boxes.newBox')}
      </Button>
      {isCreateBoxModalOpen && (
        <CreateBoxModal
          showCreateBoxModal={isCreateBoxModalOpen}
          setShowCreateBoxModal={setIsCreateBoxModalOpen}
          unitMeasure={lengthUnit.toUpperCase()}
          callback={createBoxCallback}
          isPallet={isPalletized}
        />
      )}
    </Col>
  );
}

ThirdPartyShipmentBoxesStep.propTypes = {
  form: PropTypes.instanceOf(Form).isRequired,
  lengthUnit: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  initialBoxesNumber: PropTypes.number.isRequired,
  setMinShipmentWeight: PropTypes.func.isRequired,
  isPalletized: PropTypes.bool.isRequired,
  setInitialBoxesNumber: PropTypes.func.isRequired,
};

export default ThirdPartyShipmentBoxesStep;

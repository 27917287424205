import {
  Button,
  Col,
  Form,
  InputNumber,
  Popconfirm,
  Select,
  Space,
  Table,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
import { DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

function FedexFbmBoxTable({
  boxes,
  form,
  orderId,
  setShowCreateBoxModal,
  unitMeasure,
  initialBoxesNumber,
}) {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([{ key: 0 }]);
  const [lengthUnit, setLengthUnit] = useState(
    unitMeasure === 'CM' || unitMeasure === '' ? 'cm' : 'inch'
  );
  const [weightUnit, setWeightUnit] = useState(
    unitMeasure === 'CM' || unitMeasure === '' ? 'kg' : 'lb'
  );

  const maxBoxLengthUnit = { cm: 250, inch: 99 };
  const maxBoxWeightUnit = { kg: 68, lb: 150 };
  const formatterNumber = (value) => value.replace(',', '.');
  const parserNumber = (value) => parseFloat(value.replace(',', '.'));

  const addBox = () => {
    setDataSource([...dataSource, { key: dataSource.length }]);
  };

  const deleteBox = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    const newBoxes = form.getFieldsValue(['boxes']).boxes;
    delete newBoxes[orderId][key];
    form.setFieldValue(['boxes'], newBoxes);
    setDataSource(newData);
  };

  const onBoxChange = (boxName, index) => {
    if (!boxName) {
      setShowCreateBoxModal(true);
      form.setFieldValue(['boxes', orderId.toString(), index, 'name'], '');
      form.setFieldValue(['boxes', orderId.toString(), index, 'length'], '');
      form.setFieldValue(['boxes', orderId.toString(), index, 'width'], '');
      form.setFieldValue(['boxes', orderId.toString(), index, 'height'], '');
    } else {
      const selectBox = boxes.find(({ name }) => name === boxName);
      form.setFieldValue(
        ['boxes', orderId.toString(), index, 'length'],
        selectBox.length
      );
      form.setFieldValue(
        ['boxes', orderId.toString(), index, 'width'],
        selectBox.width
      );
      form.setFieldValue(
        ['boxes', orderId.toString(), index, 'height'],
        selectBox.height
      );
    }
  };

  useEffect(() => {
    dataSource.forEach((box) => {
      form.setFieldValue(['boxes', orderId.toString(), box.key, 'name'], '');
      form.setFieldValue(['boxes', orderId.toString(), box.key, 'length'], '');
      form.setFieldValue(['boxes', orderId.toString(), box.key, 'width'], '');
      form.setFieldValue(['boxes', orderId.toString(), box.key, 'height'], '');
      form.setFieldValue(['boxes', orderId.toString(), box.key, 'weight'], '');
    });
    setLengthUnit(unitMeasure === 'CM' || unitMeasure === '' ? 'cm' : 'inch');
    setWeightUnit(unitMeasure === 'CM' || unitMeasure === '' ? 'kg' : 'lb');
  }, [unitMeasure]);

  useEffect(() => {
    if (Object.keys(initialBoxesNumber).length > 0) {
      const numberOfBoxes = initialBoxesNumber[orderId];
      if (numberOfBoxes > 0 && dataSource.length < numberOfBoxes) {
        for (let boxNumber = 1; boxNumber < numberOfBoxes; boxNumber += 1)
          setDataSource([...dataSource, { key: boxNumber }]);
      }
    }
  }, [initialBoxesNumber]);

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      width: `23%`,
      render: (_text, record, index) => (
        <Form.Item
          name={['boxes', orderId.toString(), index, 'name']}
          rules={[
            {
              required: true,
              message: 'Requerido',
            },
          ]}
        >
          <Select onChange={(value) => onBoxChange(value, index)}>
            {boxes?.map((box) => (
              <Select.Option key={box.name} value={box.name}>
                {box.name}
              </Select.Option>
            ))}
            <Select.Option>
              <PlusOutlined /> {t('orders.boxes.new')}
            </Select.Option>
          </Select>
        </Form.Item>
      ),
    },
    {
      title: 'Largo',
      dataIndex: 'length',
      key: 'length',
      width: `18%`,
      render: (_text, record, index) => (
        <Form.Item
          name={['boxes', orderId.toString(), index, 'length']}
          rules={[
            {
              required: true,
              message: 'Requerido',
            },
            {
              validator: (_, value) =>
                value > 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error('La dimensión debe ser mayor a 0')
                    ),
            },
          ]}
        >
          <InputNumber
            min={0}
            max={maxBoxLengthUnit[lengthUnit]}
            addonAfter={<Text>{lengthUnit}</Text>}
            formatter={formatterNumber}
            parser={parserNumber}
            disabled
          />
        </Form.Item>
      ),
    },
    {
      title: 'Ancho',
      dataIndex: 'width',
      key: 'width',
      width: `18%`,
      render: (_text, record, index) => (
        <Form.Item
          name={['boxes', orderId.toString(), index, 'width']}
          initialValue={record.width}
          rules={[
            {
              required: true,
              message: 'Requerido',
            },
            {
              validator: (_, value) =>
                value > 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error('La dimensión debe ser mayor a 0')
                    ),
            },
          ]}
        >
          <InputNumber
            min={0}
            max={maxBoxLengthUnit[lengthUnit]}
            addonAfter={<Text>{lengthUnit}</Text>}
            formatter={formatterNumber}
            parser={parserNumber}
            disabled
          />
        </Form.Item>
      ),
    },
    {
      title: 'Altura',
      dataIndex: 'height',
      key: 'height',
      width: `18%`,
      render: (_text, record, index) => (
        <Form.Item
          name={['boxes', orderId.toString(), index, 'height']}
          initialValue={record.height}
          rules={[
            {
              required: true,
              message: 'Requerido',
            },
            {
              validator: (_, value) =>
                value > 0
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error('La dimensión debe ser mayor a 0')
                    ),
            },
          ]}
        >
          <InputNumber
            min={0}
            max={maxBoxLengthUnit[lengthUnit]}
            addonAfter={<Text>{lengthUnit}</Text>}
            formatter={formatterNumber}
            parser={parserNumber}
            disabled
          />
        </Form.Item>
      ),
    },
    {
      title: 'Peso',
      dataIndex: 'weight',
      key: 'weight',
      width: `18%`,
      render: (_text, record, index) => (
        <Form.Item
          name={['boxes', orderId.toString(), index, 'weight']}
          initialValue={record.weight}
          rules={[
            {
              required: true,
              message: 'Requerido',
            },
            {
              validator: (_, value) =>
                value > 0
                  ? Promise.resolve()
                  : Promise.reject(new Error('El peso debe ser mayor a 0')),
            },
          ]}
        >
          <InputNumber
            min={0}
            max={maxBoxWeightUnit[weightUnit]}
            addonAfter={<Text>{weightUnit}</Text>}
            formatter={formatterNumber}
            parser={parserNumber}
          />
        </Form.Item>
      ),
    },
    {
      title: '',
      dataIndex: 'erase',
      width: `5%`,
      render: (_, record) => (
        <Popconfirm
          title="¿Seguro que quieres eliminar esta caja?"
          onConfirm={() => deleteBox(record.key)}
        >
          <DeleteTwoTone twoToneColor="#eb2f96" />
        </Popconfirm>
      ),
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          sticky
        />
      </Col>
      <Button type="primary" onClick={addBox}>
        <PlusOutlined />
        Agregar caja
      </Button>
    </Space>
  );
}

export default FedexFbmBoxTable;

FedexFbmBoxTable.propTypes = {
  boxes: PropTypes.arrayOf(Object).isRequired,
  unitMeasure: PropTypes.string.isRequired,
  form: PropTypes.instanceOf(Form).isRequired,
  orderId: PropTypes.number.isRequired,
  setShowCreateBoxModal: PropTypes.func.isRequired,
  initialBoxesNumber: PropTypes.instanceOf(Object).isRequired,
};

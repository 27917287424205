import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Spin, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import s3Api from '../api/aws-s3';
import openNotification from './Toastr';

function UploadImageButton({
  limit,
  formItemName,
  currentFormValues,
  setCurrentFormValues,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    setLoading(false);
  }, []);

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const getSizesAndBase64 = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve) => {
      reader.addEventListener('load', async (event) => {
        const loadedImageUrl = event?.target?.result;
        const image = document.createElement('img');
        image.src = loadedImageUrl;
        resolve(
          await new Promise(() => {
            image.addEventListener('load', () => {
              const { width, height } = image;
              resolve({ base64: loadedImageUrl, sizes: { width, height } });
            });
          })
        );
      });
    });
  };

  const uploadImageInS3 = async (image) => {
    try {
      const { data } = await s3Api.upload(image);
      if (!data?.location || !data?.key) {
        openNotification({
          status: false,
          content: t('catalogue.editProductModal.errorUploadImage'),
        });
      }
      return data;
    } catch (error) {
      openNotification({ status: false, content: error.response.data.message });
      return false;
    }
  };

  const deleteImageInS3 = async (tabkey) => {
    setLoading(true);
    try {
      const response = await s3Api.deleteImageByKey(tabkey);
      if (!response?.data?.success) {
        openNotification({
          status: false,
          content: t('catalogue.editProductModal.errorRemoveImage'),
        });
      } else {
        setCurrentFormValues({
          ...currentFormValues,
          [formItemName]: null,
        });
      }
    } catch (error) {
      openNotification({
        status: false,
        content: error?.response?.data?.message,
      });
    }
    setLoading(false);
  };

  const beforeUpload = async (file) => {
    if (!file?.uid) return;
    const currentFile = file;
    const result = await getSizesAndBase64(currentFile);
    setLoading(true);
    const response = await uploadImageInS3({
      type: currentFile.type,
      name: currentFile.name,
      weight: currentFile.size,
      base64: result.base64,
      uid: currentFile.uid,
      foo: 'foo',
    });
    if (response?.location && response?.key) {
      currentFile.preview = response.location;
      currentFile.thumbUrl = response.location;
      currentFile.url = response.location;
      currentFile.key = response.key;
      setImageList([currentFile]);
      setCurrentFormValues({
        ...currentFormValues,
        [formItemName]: String(response.location),
      });
    }
    setLoading(false);
  };

  const deleteImage = async (file) => {
    if (file.id) {
      await deleteImageInS3(file.name);
    } else {
      await deleteImageInS3(file.uid);
    }
    if (imageList?.length > 0) {
      setImageList(imageList?.filter((image) => image.uid !== file.uid));
    }
  };

  return (
    <Spin spinning={loading}>
      <Upload
        listType="picture-card"
        accept="image/jpg,image/jpeg,image/png,image/tiff"
        maxCount={limit}
        customRequest={dummyRequest}
        beforeUpload={(file) => beforeUpload(file)}
        onRemove={(file) => deleteImage(file)}
        fileList={imageList}
      >
        {imageList?.length >= limit ? null : (
          <Row justify="center">
            <Col>
              <PlusOutlined />
              <br />
              <div style={{ marginTop: 8 }}>
                {t('catalogue.editProductModal.uploadImage')}
              </div>
            </Col>
          </Row>
        )}
      </Upload>
    </Spin>
  );
}

UploadImageButton.propTypes = {
  limit: PropTypes.number.isRequired,
  formItemName: PropTypes.string.isRequired,
  currentFormValues: PropTypes.objectOf(Object).isRequired,
  setCurrentFormValues: PropTypes.objectOf(Object).isRequired,
};

export default UploadImageButton;

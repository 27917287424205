import fileDownload from 'js-file-download';
import api from './api';

const prefix = 'dashboard';

export default {
  getMarketplacesPerClient(filter) {
    return api.get(`${prefix}/getMarketplaces`, {
      params: filter,
    });
  },

  soldUnits(filter) {
    return api.get(`${prefix}/soldUnits`, {
      params: filter,
    });
  },

  totalSales(filter) {
    return api.get(`${prefix}/totalSales`, {
      params: filter,
    });
  },

  totalOrders(filter) {
    return api.get(`${prefix}/totalOrders`, {
      params: filter,
    });
  },

  pendingOrders(filter) {
    return api.get(`${prefix}/pendingOrders`, {
      params: filter,
    });
  },

  soldUnitsGraph(filter) {
    return api.get(`${prefix}/getSoldUnits`, {
      params: filter,
    });
  },

  topFiveSales(filter) {
    return api.get(`${prefix}/topFiveSales`, {
      params: filter,
    });
  },

  salesPerMarketplaces(filter) {
    return api.get(`${prefix}/salesPerMarketplaces`, {
      params: filter,
    });
  },

  salesSummary(filter) {
    return api.get(`${prefix}/salesSummary`, {
      params: filter,
    });
  },

  searchProducts(filter) {
    return api.get(`${prefix}/searchProducts`, {
      params: filter,
    });
  },

  allTotalSales(filter) {
    return api.get(`${prefix}/allTotalSales`, {
      params: filter,
    });
  },

  topSalesProductsPerSku(filter) {
    return api.get(`${prefix}/topSalesProductsPerSku`, {
      params: filter,
    });
  },

  topQuantityProductsPerSku(filter) {
    return api.get(`${prefix}/topQuantityProductsPerSku`, {
      params: filter,
    });
  },

  getSkusPerMarketplace(filter) {
    return api.get(`${prefix}/getSkus`, {
      params: filter,
    });
  },

  getInventoryPerSku(filter) {
    return api.get(`${prefix}/inventary`, {
      params: filter,
    });
  },

  downloadInventorySheet(params) {
    let filename = 'inventory.xlsx';
    if (params?.categoryName) {
      filename = `${params?.categoryName.split(' > ').join('_')}.xlsx`;
    } else if (params?.marketplace) {
      filename = `products_${params?.marketplace}.xlsx`;
    }
    return api({
      url: `${prefix}/file/inventory`,
      method: 'GET',
      responseType: 'blob',
      params,
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  },

  downloadSalesSummaryCSV(filter) {
    const filename = 'salesSummary.csv';
    return api({
      url: `${prefix}/csvDownload?tableName=salesSummary`,
      method: 'GET',
      responseType: 'blob',
      params: filter,
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  },

  downloadTotalSalesCSV(filter) {
    const filename = 'TotalSales.csv';
    return api({
      url: `${prefix}/csvDownload?tableName=allTotalSales`,
      method: 'GET',
      responseType: 'blob',
      params: filter,
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  },

  downloadTotalSoldUnitsCSV(filter) {
    const filename = 'totalSoldUnits.csv';
    return api({
      url: `${prefix}/csvDownload?tableName=getSoldUnits`,
      method: 'GET',
      responseType: 'blob',
      params: filter,
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  },

  downloadSearchProductsCSV(filter) {
    const filename = 'searchProducts.csv';
    return api({
      url: `${prefix}/csvDownload?tableName=searchProducts`,
      method: 'GET',
      responseType: 'blob',
      params: filter,
    }).then((res) => {
      fileDownload(res.data, filename);
    });
  },

  getDataMapsPerCountry(filter) {
    return api.get(`${prefix}/searchmap`, {
      params: filter,
    });
  },

  getOnboardingStepsPerClient(partnerId) {
    return api.get(`${prefix}/onboardingSteps?partner=${partnerId}`);
  },
};

import api from './api';

const prefix = 'workers';

export default {
  skipTaskForThread(i) {
    return api.post(`${prefix}/queues/${i}/skip`);
  },
  skipTasks() {
    return api.post(`${prefix}/queues/skip`);
  },
  clearTaskForThread(i) {
    return api.delete(`${prefix}/${i}/queue`);
  },
  clearTasks() {
    return api.delete(`${prefix}/queues`);
  },

  getServerStatus() {
    return api.get(`${prefix}/status`);
  },
  testSync() {
    return api.get('/listings/sync/test');
  },
};

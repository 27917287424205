import { DownloadOutlined } from '@ant-design/icons';
import { Button, Row, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import restockOrdersAPI from '../../../../../../api/restock-orders';
import openNotification from '../../../../../../components/Toastr';
import FboCompanyNames from '../../enum/fboCompanyNames';

function Int99MinutosRestockOrderDocs({ order }) {
  const [isLoadingDocs, setIsLoadingDocs] = useState(false);

  const downloadPDF = (name, pdf) => {
    return new Promise((resolve, reject) => {
      try {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement('a');
        const fileName = `${name}_${order.name}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleDownloadDocs = async () => {
    setIsLoadingDocs(true);
    try {
      const { data } = await restockOrdersAPI.getLabels(order.id, {
        companyName: FboCompanyNames.Fulfill99,
        query: {},
      });

      if (data.success) {
        const pdfPromises = [];
        if (data.labels) {
          pdfPromises.push(downloadPDF('etiquetas', data.labels));
          // await downloadPDF('etiquetas', data.labels)
        }
        if (data.receipt) {
          pdfPromises.push(downloadPDF('bultos', data.receipt));
          // await downloadPDF('recibo', data.receipt)
        }
        if (data.summary) {
          pdfPromises.push(downloadPDF('guia', data.summary));
          // await downloadPDF('resumen', data.summary)
        }

        await Promise.all(pdfPromises);
      }
    } catch (error) {
      openNotification({
        status: false,
        content: 'Ha ocurrido un error al intentar descargar los documentos.',
      });
    }
    setIsLoadingDocs(false);
  };

  return (
    <Space>
      <Row align="middle">
        <span style={{ marginRight: 10 }}>
          Ya puedes descargar los documentos para tus paquetes:
        </span>

        <Button
          disabled={isLoadingDocs}
          className="btn-primary"
          icon={<DownloadOutlined />}
          onClick={handleDownloadDocs}
          loading={isLoadingDocs}
        >
          Descargar documentos
        </Button>
      </Row>
    </Space>
  );
}

export default Int99MinutosRestockOrderDocs;

Int99MinutosRestockOrderDocs.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
};

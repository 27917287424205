import us from './countries/US.json';
import mx from './countries/MX.json';
import cl from './countries/CL.json';
import co from './countries/CO.json';
import ca from './countries/CA.json';

const COUNTRIES = {
  US: us,
  MX: mx,
  CO: co,
  CL: cl,
  CA: ca,
};

export default COUNTRIES;

import { Button, Col, DatePicker, Form, Typography } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function PickUp99Minutos({ createShipment, sendLoading, form }) {
  const { t } = useTranslation();

  const disabledDate = (current) => {
    return current && current < moment(new Date()).subtract(1, 'day');
  };

  return (
    <div>
      <Typography.Title level={5} style={{ marginTop: 15 }}>
        {t('externalShipment.step6Title')}
      </Typography.Title>
      <Col>
        <Form layout="inline" form={form}>
          <Form.Item
            label="Fecha"
            name="pickUp"
            rules={[
              {
                required: true,
                message: 'Ingrese la fecha de entrega.',
              },
            ]}
          >
            <DatePicker
              className="datePickerStyle"
              placeholder="YYYY-MM-DD"
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Form>
      </Col>
      <Button
        onClick={() => createShipment(false)}
        type="primary"
        style={{ marginTop: 15 }}
        loading={sendLoading}
        disabled={form.getFieldsValue().pickUp}
      >
        Crear envío
      </Button>
    </div>
  );
}

PickUp99Minutos.propTypes = {
  createShipment: PropTypes.func.isRequired,
  sendLoading: PropTypes.bool.isRequired,
  form: PropTypes.instanceOf(Form).isRequired,
};

export default PickUp99Minutos;

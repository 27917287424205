import api from './api';

const prefix = 'marketplaces';

export default {
  findAll() {
    return api.get(`${prefix}`);
  },
  findAllv2() {
    return api.get(`${prefix}/v2`);
  },
  updateProductFulfillment: (payload, credentialId) => {
    return api.post(`${prefix}/${credentialId}/fulfillment`, payload);
  },
  updateProductShipping: (payload, marketplace) => {
    return api.post(`${prefix}/${marketplace}/shipping`, payload);
  },
};

import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import PropTypes from 'prop-types';

function TooltipTitle({ label, description }) {
  return (
    <Row>
      <Col style={{ textAlign: 'center', fontStyle: 'normal' }}>
        {label}{' '}
        <Tooltip title={description}>
          <InfoCircleOutlined />
        </Tooltip>
      </Col>
    </Row>
  );
}
TooltipTitle.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default TooltipTitle;

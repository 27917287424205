import { PictureOutlined } from '@ant-design/icons';
import { Avatar, Col, Image, Row, Space, Table, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import selectors from '../../../../redux/analytic/dashboard/selectors';
import { thousandsSeparator } from '../../../../utils/functions';
import getLinkToStore from '../linkToStore';

const { Text } = Typography;

function TopSalesTableBySku({ dataSource }) {
  const { t } = useTranslation();
  const session = useSelector((store) => store.Session.session);
  const currencyState = useSelector(selectors.selectCurrency);

  const renderImg = (record) => {
    const imgUrl = record.url;
    return (
      <Row align="middle" justify="start">
        <Col span={24}>
          <Space>
            {imgUrl ? (
              <Avatar
                style={{ width: 50, height: 50 }}
                size={32}
                shape="square"
                className="home-listing-table-avatar"
                src={<Image src={`${imgUrl}`} />}
              />
            ) : (
              <Avatar
                style={{ width: 50, height: 50 }}
                size={32}
                shape="square"
                className="home-listing-table-avatar"
                icon={<PictureOutlined />}
              />
            )}
          </Space>
        </Col>
      </Row>
    );
  };

  const renderProduct = (record) => (
    <Row align="middle" justify="start">
      <Col span={24}>
        <Space>
          <div>{renderImg(record)}</div>
          <Space direction="vertical" style={{ textAlign: 'left' }}>
            <p
              style={{
                marginBottom: -10,
                width: '12em',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {getLinkToStore[record?.marketplace]
                ? getLinkToStore[record?.marketplace](record)
                : null}
            </p>
            <p
              style={{ marginBottom: -10, fontSize: '12px' }}
            >{`SKU: ${record.sku}`}</p>
            <p style={{ color: 'grey', margin: 0, fontSize: '12px' }}>
              {t(
                `dashboard.marketplaces.${record.marketplace.replace(' ', '')}`
              )}
            </p>
          </Space>
        </Space>
      </Col>
    </Row>
  );

  const columns = [
    {
      title: 'Top',
      dataIndex: 'top',
      key: 'top',
      width: '10%',
      align: 'center',
      render: (value, record, index) => <Text>{index + 1}</Text>,
    },
    {
      title: !session?.userInfo?.isAdmin
        ? t('common.product', { count: 1 })
        : t('common.client'),
      dataIndex: 'title',
      key: 'title',
      width: '35%',
      align: 'center',
      render: (value, record) => {
        const component = renderProduct(record);
        return component;
      },
    },
    {
      title: t('home.charts.totalSales.title'),
      dataIndex: 'sumTotalSoldUSD',
      key: 'sumTotalSoldUSD',
      width: '20%',
      align: 'center',
      render: (value, record) => (
        <Text ellipsis={{ tooltip: record?.sumTotalSoldUSD }}>
          {thousandsSeparator(Math.round(record?.sumTotalSoldUSD))}
          {` ${currencyState}`}
        </Text>
      ),
    },
    {
      title: t('dashboard.table.soldUnits.title'),
      dataIndex: 'sumQuantitySold',
      key: 'sumQuantitySold',
      width: '25%',
      align: 'center',
      render: (value, record) => (
        <Text ellipsis={{ tooltip: record?.sumQuantitySold }}>
          {record?.sumQuantitySold}
        </Text>
      ),
    },
  ];
  return (
    <Table
      dataSource={dataSource.filter((element, index) => index <= 4)}
      key={0}
      pagination={false}
      columns={columns}
      style={{ fontFamily: 'Poppins' }}
    />
  );
}

TopSalesTableBySku.propTypes = {
  dataSource: PropTypes.instanceOf(Object).isRequired,
};

export default React.memo(TopSalesTableBySku);

export const currencies = {
  AED: {
    iso: 'AED',
    formatter: '',
    sign: '',
  },
  AFN: {
    iso: 'AFN',
    formatter: '',
    sign: '',
  },
  ALL: {
    iso: 'ALL',
    formatter: '',
    sign: '',
  },
  AMD: {
    iso: 'AMD',
    formatter: '',
    sign: '',
  },
  ANG: {
    iso: 'ANG',
    formatter: '',
    sign: '',
  },
  AOA: {
    iso: 'AOA',
    formatter: '',
    sign: '',
  },
  ARS: {
    iso: 'ARS',
    formatter: '',
    sign: '',
  },
  AUD: {
    iso: 'AUD',
    formatter: '',
    sign: '',
  },
  AWG: {
    iso: 'AWG',
    formatter: '',
    sign: '',
  },
  AZN: {
    iso: 'AZN',
    formatter: '',
    sign: '',
  },
  BAM: {
    iso: 'BAM',
    formatter: '',
    sign: '',
  },
  BBD: {
    iso: 'BBD',
    formatter: '',
    sign: '',
  },
  BDT: {
    iso: 'BDT',
    formatter: '',
    sign: '',
  },
  BGN: {
    iso: 'BGN',
    formatter: '',
    sign: '',
  },
  BHD: {
    iso: 'BHD',
    formatter: '',
    sign: '',
  },
  BIF: {
    iso: 'BIF',
    formatter: '',
    sign: '',
  },
  BMD: {
    iso: 'BMD',
    formatter: '',
    sign: '',
  },
  BND: {
    iso: 'BND',
    formatter: '',
    sign: '',
  },
  BOB: {
    iso: 'BOB',
    formatter: '',
    sign: '',
  },
  BOV: {
    iso: 'BOV',
    formatter: '',
    sign: '',
  },
  BRL: {
    iso: 'BRL',
    formatter: '',
    sign: '',
  },
  BSD: {
    iso: 'BSD',
    formatter: '',
    sign: '',
  },
  BTN: {
    iso: 'BTN',
    formatter: '',
    sign: '',
  },
  BWP: {
    iso: 'BWP',
    formatter: '',
    sign: '',
  },
  BYR: {
    iso: 'BYR',
    formatter: '',
    sign: '',
  },
  BZD: {
    iso: 'BZD',
    formatter: '',
    sign: '',
  },
  CAD: {
    iso: 'CAD',
    formatter: '',
    sign: '',
  },
  CDF: {
    iso: 'CDF',
    formatter: '',
    sign: '',
  },
  CHE: {
    iso: 'CHE',
    formatter: '',
    sign: '',
  },
  CHF: {
    iso: 'CHF',
    formatter: '',
    sign: '',
  },
  CHW: {
    iso: 'CHW',
    formatter: '',
    sign: '',
  },
  CLF: {
    iso: 'CLF',
    formatter: '',
    sign: '',
  },
  CLP: {
    iso: 'CLP',
    formatter: '',
    sign: '',
  },
  CNY: {
    iso: 'CNY',
    formatter: '',
    sign: '',
  },
  COP: {
    iso: 'COP',
    formatter: '',
    sign: '',
  },
  COU: {
    iso: 'COU',
    formatter: '',
    sign: '',
  },
  CRC: {
    iso: 'CRC',
    formatter: '',
    sign: '',
  },
  CUC: {
    iso: 'CUC',
    formatter: '',
    sign: '',
  },
  CUP: {
    iso: 'CUP',
    formatter: '',
    sign: '',
  },
  CVE: {
    iso: 'CVE',
    formatter: '',
    sign: '',
  },
  CZK: {
    iso: 'CZK',
    formatter: '',
    sign: '',
  },
  DJF: {
    iso: 'DJF',
    formatter: '',
    sign: '',
  },
  DKK: {
    iso: 'DKK',
    formatter: '',
    sign: '',
  },
  DOP: {
    iso: 'DOP',
    formatter: '',
    sign: '',
  },
  DZD: {
    iso: 'DZD',
    formatter: '',
    sign: '',
  },
  EGP: {
    iso: 'EGP',
    formatter: '',
    sign: '',
  },
  ERN: {
    iso: 'ERN',
    formatter: '',
    sign: '',
  },
  ETB: {
    iso: 'ETB',
    formatter: '',
    sign: '',
  },
  EUR: {
    iso: 'EUR',
    formatter: '',
    sign: '',
  },
  FJD: {
    iso: 'FJD',
    formatter: '',
    sign: '',
  },
  FKP: {
    iso: 'FKP',
    formatter: '',
    sign: '',
  },
  GBP: {
    iso: 'GBP',
    formatter: '',
    sign: '',
  },
  GEL: {
    iso: 'GEL',
    formatter: '',
    sign: '',
  },
  GHS: {
    iso: 'GHS',
    formatter: '',
    sign: '',
  },
  GIP: {
    iso: 'GIP',
    formatter: '',
    sign: '',
  },
  GMD: {
    iso: 'GMD',
    formatter: '',
    sign: '',
  },
  GNF: {
    iso: 'GNF',
    formatter: '',
    sign: '',
  },
  GTQ: {
    iso: 'GTQ',
    formatter: '',
    sign: '',
  },
  GYD: {
    iso: 'GYD',
    formatter: '',
    sign: '',
  },
  HKD: {
    iso: 'HKD',
    formatter: '',
    sign: '',
  },
  HNL: {
    iso: 'HNL',
    formatter: '',
    sign: '',
  },
  HRK: {
    iso: 'HRK',
    formatter: '',
    sign: '',
  },
  HTG: {
    iso: 'HTG',
    formatter: '',
    sign: '',
  },
  HUF: {
    iso: 'HUF',
    formatter: '',
    sign: '',
  },
  IDR: {
    iso: 'IDR',
    formatter: '',
    sign: '',
  },
  ILS: {
    iso: 'ILS',
    formatter: '',
    sign: '',
  },
  INR: {
    iso: 'INR',
    formatter: '',
    sign: '',
  },
  IQD: {
    iso: 'IQD',
    formatter: '',
    sign: '',
  },
  IRR: {
    iso: 'IRR',
    formatter: '',
    sign: '',
  },
  ISK: {
    iso: 'ISK',
    formatter: '',
    sign: '',
  },
  JMD: {
    iso: 'JMD',
    formatter: '',
    sign: '',
  },
  JOD: {
    iso: 'JOD',
    formatter: '',
    sign: '',
  },
  JPY: {
    iso: 'JPY',
    formatter: '',
    sign: '',
  },
  KES: {
    iso: 'KES',
    formatter: '',
    sign: '',
  },
  KGS: {
    iso: 'KGS',
    formatter: '',
    sign: '',
  },
  KHR: {
    iso: 'KHR',
    formatter: '',
    sign: '',
  },
  KMF: {
    iso: 'KMF',
    formatter: '',
    sign: '',
  },
  KPW: {
    iso: 'KPW',
    formatter: '',
    sign: '',
  },
  KRW: {
    iso: 'KRW',
    formatter: '',
    sign: '',
  },
  KWD: {
    iso: 'KWD',
    formatter: '',
    sign: '',
  },
  KYD: {
    iso: 'KYD',
    formatter: '',
    sign: '',
  },
  KZT: {
    iso: 'KZT',
    formatter: '',
    sign: '',
  },
  LAK: {
    iso: 'LAK',
    formatter: '',
    sign: '',
  },
  LBP: {
    iso: 'LBP',
    formatter: '',
    sign: '',
  },
  LKR: {
    iso: 'LKR',
    formatter: '',
    sign: '',
  },
  LRD: {
    iso: 'LRD',
    formatter: '',
    sign: '',
  },
  LSL: {
    iso: 'LSL',
    formatter: '',
    sign: '',
  },
  LTL: {
    iso: 'LTL',
    formatter: '',
    sign: '',
  },
  LVL: {
    iso: 'LVL',
    formatter: '',
    sign: '',
  },
  LYD: {
    iso: 'LYD',
    formatter: '',
    sign: '',
  },
  MAD: {
    iso: 'MAD',
    formatter: '',
    sign: '',
  },
  MDL: {
    iso: 'MDL',
    formatter: '',
    sign: '',
  },
  MGA: {
    iso: 'MGA',
    formatter: '',
    sign: '',
  },
  MKD: {
    iso: 'MKD',
    formatter: '',
    sign: '',
  },
  MMK: {
    iso: 'MMK',
    formatter: '',
    sign: '',
  },
  MNT: {
    iso: 'MNT',
    formatter: '',
    sign: '',
  },
  MOP: {
    iso: 'MOP',
    formatter: '',
    sign: '',
  },
  MRO: {
    iso: 'MRO',
    formatter: '',
    sign: '',
  },
  MUR: {
    iso: 'MUR',
    formatter: '',
    sign: '',
  },
  MVR: {
    iso: 'MVR',
    formatter: '',
    sign: '',
  },
  MWK: {
    iso: 'MWK',
    formatter: '',
    sign: '',
  },
  MXN: {
    iso: 'MXN',
    formatter: '',
    sign: '',
  },
  MXV: {
    iso: 'MXV',
    formatter: '',
    sign: '',
  },
  MYR: {
    iso: 'MYR',
    formatter: '',
    sign: '',
  },
  MZN: {
    iso: 'MZN',
    formatter: '',
    sign: '',
  },
  NAD: {
    iso: 'NAD',
    formatter: '',
    sign: '',
  },
  NGN: {
    iso: 'NGN',
    formatter: '',
    sign: '',
  },
  NIO: {
    iso: 'NIO',
    formatter: '',
    sign: '',
  },
  NOK: {
    iso: 'NOK',
    formatter: '',
    sign: '',
  },
  NPR: {
    iso: 'NPR',
    formatter: '',
    sign: '',
  },
  NZD: {
    iso: 'NZD',
    formatter: '',
    sign: '',
  },
  OMR: {
    iso: 'OMR',
    formatter: '',
    sign: '',
  },
  PAB: {
    iso: 'PAB',
    formatter: '',
    sign: '',
  },
  PEN: {
    iso: 'PEN',
    formatter: '',
    sign: '',
  },
  PGK: {
    iso: 'PGK',
    formatter: '',
    sign: '',
  },
  PHP: {
    iso: 'PHP',
    formatter: '',
    sign: '',
  },
  PKR: {
    iso: 'PKR',
    formatter: '',
    sign: '',
  },
  PLN: {
    iso: 'PLN',
    formatter: '',
    sign: '',
  },
  PYG: {
    iso: 'PYG',
    formatter: '',
    sign: '',
  },
  QAR: {
    iso: 'QAR',
    formatter: '',
    sign: '',
  },
  RON: {
    iso: 'RON',
    formatter: '',
    sign: '',
  },
  RSD: {
    iso: 'RSD',
    formatter: '',
    sign: '',
  },
  RUB: {
    iso: 'RUB',
    formatter: '',
    sign: '',
  },
  RWF: {
    iso: 'RWF',
    formatter: '',
    sign: '',
  },
  SAR: {
    iso: 'SAR',
    formatter: '',
    sign: '',
  },
  SBD: {
    iso: 'SBD',
    formatter: '',
    sign: '',
  },
  SCR: {
    iso: 'SCR',
    formatter: '',
    sign: '',
  },
  SDG: {
    iso: 'SDG',
    formatter: '',
    sign: '',
  },
  SEK: {
    iso: 'SEK',
    formatter: '',
    sign: '',
  },
  SGD: {
    iso: 'SGD',
    formatter: '',
    sign: '',
  },
  SHP: {
    iso: 'SHP',
    formatter: '',
    sign: '',
  },
  SLL: {
    iso: 'SLL',
    formatter: '',
    sign: '',
  },
  SOS: {
    iso: 'SOS',
    formatter: '',
    sign: '',
  },
  SRD: {
    iso: 'SRD',
    formatter: '',
    sign: '',
  },
  SSP: {
    iso: 'SSP',
    formatter: '',
    sign: '',
  },
  STD: {
    iso: 'STD',
    formatter: '',
    sign: '',
  },
  SYP: {
    iso: 'SYP',
    formatter: '',
    sign: '',
  },
  SZL: {
    iso: 'SZL',
    formatter: '',
    sign: '',
  },
  THB: {
    iso: 'THB',
    formatter: '',
    sign: '',
  },
  TJS: {
    iso: 'TJS',
    formatter: '',
    sign: '',
  },
  TMT: {
    iso: 'TMT',
    formatter: '',
    sign: '',
  },
  TND: {
    iso: 'TND',
    formatter: '',
    sign: '',
  },
  TOP: {
    iso: 'TOP',
    formatter: '',
    sign: '',
  },
  TRY: {
    iso: 'TRY',
    formatter: '',
    sign: '',
  },
  TTD: {
    iso: 'TTD',
    formatter: '',
    sign: '',
  },
  TWD: {
    iso: 'TWD',
    formatter: '',
    sign: '',
  },
  TZS: {
    iso: 'TZS',
    formatter: '',
    sign: '',
  },
  UAH: {
    iso: 'UAH',
    formatter: '',
    sign: '',
  },
  UGX: {
    iso: 'UGX',
    formatter: '',
    sign: '',
  },
  USD: {
    iso: 'USD',
    formatter: new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    }),
    sign: 'U$D',
  },
  USN: {
    iso: 'USN',
    formatter: '',
    sign: '',
  },
  USS: {
    iso: 'USS',
    formatter: '',
    sign: '',
  },
  UYI: {
    iso: 'UYI',
    formatter: '',
    sign: '',
  },
  UYU: {
    iso: 'UYU',
    formatter: '',
    sign: '',
  },
  UZS: {
    iso: 'UZS',
    formatter: '',
    sign: '',
  },
  VEF: {
    iso: 'VEF',
    formatter: '',
    sign: '',
  },
  VND: {
    iso: 'VND',
    formatter: '',
    sign: '',
  },
  VUV: {
    iso: 'VUV',
    formatter: '',
    sign: '',
  },
  WST: {
    iso: 'WST',
    formatter: '',
    sign: '',
  },
  XAF: {
    iso: 'XAF',
    formatter: '',
    sign: '',
  },
  XAG: {
    iso: 'XAG',
    formatter: '',
    sign: '',
  },
  XAU: {
    iso: 'XAU',
    formatter: '',
    sign: '',
  },
  XBA: {
    iso: 'XBA',
    formatter: '',
    sign: '',
  },
  XBB: {
    iso: 'XBB',
    formatter: '',
    sign: '',
  },
  XBC: {
    iso: 'XBC',
    formatter: '',
    sign: '',
  },
  XBD: {
    iso: 'XBD',
    formatter: '',
    sign: '',
  },
  XCD: {
    iso: 'XCD',
    formatter: '',
    sign: '',
  },
  XDR: {
    iso: 'XDR',
    formatter: '',
    sign: '',
  },
  XFU: {
    iso: 'XFU',
    formatter: '',
    sign: '',
  },
  XOF: {
    iso: 'XOF',
    formatter: '',
    sign: '',
  },
  XPD: {
    iso: 'XPD',
    formatter: '',
    sign: '',
  },
  XPF: {
    iso: 'XPF',
    formatter: '',
    sign: '',
  },
  XPT: {
    iso: 'XPT',
    formatter: '',
    sign: '',
  },
  XTS: {
    iso: 'XTS',
    formatter: '',
    sign: '',
  },
  XXX: {
    iso: 'XXX',
    formatter: '',
    sign: '',
  },
  YER: {
    iso: 'YER',
    formatter: '',
    sign: '',
  },
  ZAR: {
    iso: 'ZAR',
    formatter: '',
    sign: '',
  },
  ZMW: {
    iso: 'ZMW',
    formatter: '',
    sign: '',
  },
};

export const currencyFormatter = (currencyCode, value) => {
  const currentCurrency = currencies[currencyCode];
  return currentCurrency.formatter.format(value);
};

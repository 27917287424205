import { Button, notification, Spin } from 'antd';
import { FrownOutlined } from '@ant-design/icons';
import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { orderGeneralStates } from '../../../../../../utils/const';
import FedExServiceSelect from '../../../../FedExServiceSelect';
import restockOrdersAPI from '../../../../../../api/restock-orders';
import {
  defaultAmazonErrors,
  estimateTransportErrors,
  putTransportDetailsErrors,
  submitFBAInboundCartonContentErrors,
} from '../../../../../../utils/amazon_errors';
import { createCourierShipmentsErrors } from '../../../../../../utils/errors';
import { translateErrors } from '../../../../../../utils/functions';
import shipmentsAPI from '../../../../../../api/shipments';

function NonPartneredAmazonConfirm(
  {
    order,
    selectedService,
    setSelectedService,
    setLoadingConfirmShipments,
    isPickup,
  },
  ref
) {
  const { t } = useTranslation();
  const [translatePath, setTranslatePath] = useState({});
  const [rates, setRates] = useState({});
  const [loadingRates, setLoadingRates] = useState(true);

  const setRatesAndTransitTimesPayload = (shipment) => {
    return {
      restockOrderId: order.id,
      shipmentPlanId: shipment.ShipmentId,
      warehouseId: order.warehouse.id,
    };
  };

  const handleConfirmShipments = async () => {
    setLoadingConfirmShipments(true);
    try {
      const updateInboundShipmentsPayload = {
        shipments: [],
      };
      const shipmentPromises = [];
      const inboundShipmentPlans = order.shipmentPlan.InboundShipmentPlans;
      inboundShipmentPlans.forEach((plan, index) => {
        const body = {
          order: {
            id: order.id,
            shipmentId: plan.ShipmentId,
          },
          shipment: {
            courierName: 'FedEx',
            serviceType: {
              name: selectedService,
            },
            pickup: {
              type: isPickup ? 'SCHEDULE' : 'DROP_OFF',
            },
            rate: {
              value: rates[index][selectedService].rates.ACCOUNT,
              currency: rates[index][selectedService].currency,
            },
          },
        };
        shipmentPromises.push(shipmentsAPI.createRestockOrderShipment(body));
        updateInboundShipmentsPayload.shipments.push({
          shipmentId: plan.ShipmentId,
          shipmentStatus: 'SHIPPED',
        });
      });
      await Promise.all(shipmentPromises);
      await restockOrdersAPI.putTransportDetails(order.id);
      await restockOrdersAPI.submitFBAInboundCartonContent(order.id);
      await restockOrdersAPI.update(order.id, {
        state: orderGeneralStates.READY_TO_SHIP,
      });
      await restockOrdersAPI.updateInboundShipments(
        order.id,
        updateInboundShipmentsPayload
      );
    } catch (error) {
      if (error?.config?.url.includes('FBAInboundCartonContent')) {
        setTranslatePath(
          translateErrors(error, submitFBAInboundCartonContentErrors)
        );
      } else if (error?.config?.url.includes('transportDetails')) {
        setTranslatePath(translateErrors(error, putTransportDetailsErrors));
      } else if (error?.config?.url.includes('estimateTransport')) {
        setTranslatePath(translateErrors(error, estimateTransportErrors));
      } else if (
        error?.config?.url.includes('inboundShipment') &&
        error.config.method === 'put'
      ) {
        setTranslatePath(translateErrors(error, putTransportDetailsErrors));
      } else if (error?.config?.url.includes('shipments/restockOrder')) {
        setTranslatePath(translateErrors(error, createCourierShipmentsErrors));
      } else {
        setTranslatePath(translateErrors(error, defaultAmazonErrors));
      }
      setLoadingConfirmShipments(false);
      throw error;
    }
    setLoadingConfirmShipments(false);
  };

  useImperativeHandle(ref, () => ({
    handleConfirmShipments,
  }));

  useEffect(() => {
    const textError = t(translatePath.error);
    if (Object.keys(translatePath).length !== 0) {
      notification.error({
        style: { zIndex: 'inherit' },
        duration: 0,
        message: translatePath?.titleError
          ? t(translatePath.titleError)
          : 'Error',
        description: textError,
        ...(translatePath?.tooltip
          ? {
              btn: (
                <Button
                  style={{ color: '#00e5a6' }}
                  type="link"
                  href={translatePath?.tooltip}
                  target="_blank"
                >
                  {t('common.wantKnowMore')}
                </Button>
              ),
            }
          : {}),
        icon: (
          <FrownOutlined
            className="error"
            style={{ color: '#F81D22 !important' }}
          />
        ),
      });
    }
  }, [translatePath]);

  return order
    ? ![orderGeneralStates.DRAFT].includes(order.state) && (
        <Spin spinning={loadingRates}>
          <FedExServiceSelect
            shipments={order?.shipmentPlan?.InboundShipmentPlans}
            setRatesAndTransitTimesPayload={setRatesAndTransitTimesPayload}
            setRates={setRates}
            setLoadingRates={setLoadingRates}
            selectedService={selectedService}
            setSelectedService={setSelectedService}
          />
        </Spin>
      )
    : null;
}

NonPartneredAmazonConfirm.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    courier: PropTypes.string,
    warehouse: PropTypes.shape({
      id: PropTypes.number,
    }),
    shipments: PropTypes.arrayOf(Object),
    shipmentPlan: PropTypes.shape({
      InboundShipmentPlans: PropTypes.arrayOf(
        PropTypes.shape({
          ShipmentId: PropTypes.string,
        })
      ),
    }),
    courierShipments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        pickup: PropTypes.shape({
          id: PropTypes.number,
          pickupTime: PropTypes.string,
          latestPickupTime: PropTypes.string,
          pickupDate: PropTypes.string,
        }),
      })
    ),
    steps: {
      step: PropTypes.string,
    },
    boxes: PropTypes.instanceOf(Object),
    isPalletized: PropTypes.bool.isRequired,
    products: PropTypes.instanceOf(Object),
    state: PropTypes.string,
  }),
  selectedService: PropTypes.string.isRequired,
  setSelectedService: PropTypes.func.isRequired,
  setLoadingConfirmShipments: PropTypes.func.isRequired,
  isPickup: PropTypes.bool.isRequired,
};

NonPartneredAmazonConfirm.defaultProps = {
  order: null,
};

export default forwardRef(NonPartneredAmazonConfirm);

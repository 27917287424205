import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import {
  PrinterOutlined,
  DownOutlined,
  UpOutlined,
  InfoCircleOutlined,
  EditOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import { orderGeneralStates } from '../../../../../../utils/const';
import './ContentsTable.css';
import { ProductRow } from '../ProductsModal/ProductsModal';
import productApi from '../../../../../../api/product';
import openNotification from '../../../../../../components/Toastr';
import { getErrorMessage } from '../../../../../../api/api';

function ContentsTable({
  form,
  listingsAndProducts,
  order,
  isPack,
  selectedProducts,
  selectedRowKeys,
  setSelectedRowKeys,
  isPartnered,
  quantityValues,
  setQuantityValues,
  unitsPerPackValues,
  setUnitsPerPackValues,
  expirationDates,
  setExpirationDates,
}) {
  const { t } = useTranslation();
  const { Search } = Input;
  const [dataSource, setDataSource] = useState();
  const [filteredDataSource, setFilteredDataSource] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState();
  const disableInput = order.state !== 'Draft';

  const [editProductModalOpen, setEditProductModalOpen] = useState({});
  const [editProduct, setEditProduct] = useState(null);
  const editProductFormRefs = useRef({});

  const handleQuantityChange = (rowId, value) => {
    setQuantityValues((prevValues) => ({
      ...prevValues,
      [rowId]: value,
    }));
  };

  const handleUnitsPerPackChange = (rowId, value) => {
    setUnitsPerPackValues((prevValues) => ({
      ...prevValues,
      [rowId]: value,
    }));
  };

  const handleExpirationDateChange = (rowId, date) => {
    setExpirationDates((prevDates) => ({
      ...prevDates,
      [rowId]: date,
    }));
  };

  const boxesValidator = (rule) => {
    const key = rule.field.split('-')[1];
    const quantity = form.getFieldValue(`quantity-${key}`);
    const unitsPerPack = form.getFieldValue(`unitsPerPack-${key}`);
    if (quantity !== undefined && unitsPerPack !== undefined) {
      if (quantity / unitsPerPack !== Math.floor(quantity / unitsPerPack)) {
        return Promise.reject(new Error('Inválido'));
      }
    }
    return Promise.resolve();
  };

  const handleEditProductModalOpen = async (productId) => {
    const params = {
      productIds: JSON.stringify([productId]),
    };
    const response = await productApi.getByIds(params);
    if (response.data.length > 0) {
      setEditProduct(response.data[0]);
      setEditProductModalOpen((prevOpen) => ({
        ...prevOpen,
        [productId]: true,
      }));
    }
  };

  const handleEditProductModalClose = (productId) => {
    setEditProductModalOpen((prevOpen) => ({
      ...prevOpen,
      [productId]: false,
    }));
    setEditProduct(null);
  };

  const handleEditProductFormFinish = (formId) => {
    const formRef = editProductFormRefs.current[formId];
    formRef.submit();
    handleEditProductModalClose(formId);
  };

  const onFinishEditProduct = async (id, values) => {
    try {
      await productApi.update(id, values);
      // editProductFormRefs.current[id].resetFields();
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
  };

  const expandIcon = ({ expanded, onExpand, record }) => {
    if (expanded && record.type === 'listing') {
      return (
        <DownOutlined
          className="contentIcon"
          style={{ paddingRight: 5 }}
          onClick={(e) => onExpand(record, e)}
        />
      );
    }
    if (record.type === 'product') return null;
    return (
      <UpOutlined
        className="contentIcon"
        style={{ paddingRight: 5 }}
        onClick={(e) => onExpand(record, e)}
      />
    );
  };

  const filterDataSource = (searchValue) => {
    if (searchValue === '') {
      setFilteredDataSource(dataSource);
    } else {
      const lowercasedValue = searchValue.toLowerCase();
      const filteredData = dataSource
        .map((row) => {
          const matchingChildren = row.children.filter((child) =>
            child.sku.toLowerCase().startsWith(lowercasedValue)
          );
          return matchingChildren.length > 0
            ? { ...row, children: matchingChildren }
            : null;
        })
        .filter((row) => row !== null);
      setFilteredDataSource(filteredData);
    }
  };

  const onSearch = (value) => {
    filterDataSource(value);
  };

  const onPressEnter = (event) => {
    filterDataSource(event.target.value);
  };

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    // Merge new selections with existing ones, filter out unselected keys if they are not in selectedRows
    const updatedSelectedKeys = [
      ...new Set([
        ...selectedRowKeys.filter((key) =>
          selectedRows.some((row) => row.key === key)
        ),
        ...newSelectedRowKeys,
      ]),
    ];

    // Determine which rows have been unselected
    const unselectedRowKeys = selectedRowKeys.filter(
      (key) => !newSelectedRowKeys.includes(key)
    );

    // Update the form values for unselected rows to undefined
    unselectedRowKeys.forEach((key) => {
      form.setFieldsValue({
        [`quantity-${key}`]: undefined,
        [`unitsPerPack-${key}`]: undefined,
        [`expirationDate-${key}`]: undefined,
      });
      handleQuantityChange(key, undefined);
      handleUnitsPerPackChange(key, undefined);
      handleExpirationDateChange(key, undefined);
    });

    // Finally, update the state to reflect the new selection
    setSelectedRowKeys(updatedSelectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    hideSelectAll: true,
    getCheckboxProps: (record) => ({
      disabled: order.state !== orderGeneralStates.DRAFT,
      key: record.key,
    }),
  };

  const expandable = {
    expandedRowKeys,
    onExpand: (expanded, record) => {
      if (expanded) {
        setExpandedRowKeys([...expandedRowKeys, record.key]);
      } else {
        setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key));
      }
    },
    expandIcon,
  };
  const rowClassName = (record) => {
    if (record.type === 'product') {
      return 'product';
    }
    if (record.type === 'listing') {
      return 'listing';
    }
    return null;
  };

  const renderName = (text, row) => {
    if (row.type !== 'listing') {
      return (
        <Row align="middle" justify="start">
          <Col span={24}>
            <Space>
              {row?.image ? (
                <Avatar
                  style={{ width: 40, height: 40 }}
                  shape="square"
                  className="home-listing-table-avatar"
                  src={<Image src={`${row?.image}`} />}
                />
              ) : (
                <Avatar
                  style={{ width: 40, height: 40 }}
                  shape="square"
                  className="home-listing-table-avatar"
                  icon={<PictureOutlined />}
                />
              )}
              <Row>
                <Col span={24}>
                  <Tooltip placement="topLeft" title={text}>
                    <Typography.Text className="title-product-contents-table">
                      {text}
                    </Typography.Text>
                  </Tooltip>
                </Col>
                <Col span={24} className="SKU-product-contents-table">
                  <Tooltip title={text}>SKU: {row.sku}</Tooltip>
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
      );
    }
    return {
      children: (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    };
  };

  const renderQuantity = (_text, row) => {
    if (row.type === 'product') {
      return (
        <Form.Item
          label=""
          className="input-form-margin-bottom"
          name={`quantity-${row.id}`}
          initialValue={row.quantity}
          dependencies={[`unitsPerPack-${row.id}`]}
          rules={[
            {
              required: selectedRowKeys.includes(row.id),
              message: 'Requerido',
            },
            ({ getFieldValue }) => ({
              validator: (rule, value, callback) =>
                boxesValidator(rule, value, callback, getFieldValue),
            }),
          ]}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <InputNumber
            disabled={
              !selectedRowKeys.find((key) => key === row.id) || disableInput
            }
            name={`quantity-${row.id}`}
            min={1}
            onChange={(value) => handleQuantityChange(row.id, value)}
          />
        </Form.Item>
      );
    }
    return null;
  };

  const renderUnitsPerPack = (_text, row) => {
    if (row.type === 'product') {
      return (
        <Form.Item
          label=""
          className="input-form-margin-bottom"
          name={`unitsPerPack-${row.id}`}
          dependencies={[`quantity-${row.id}`]}
          initialValue={row.unitsPerPack}
          rules={[
            {
              required: isPack && selectedRowKeys.includes(row.id),
              message: 'Requerido',
            },
            { validator: (rule, value) => boxesValidator(rule, value) },
          ]}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <InputNumber
            disabled={
              !selectedRowKeys.find((key) => key === row.id) || disableInput
            }
            name={`unitsPerPack-${row.id}`}
            min={1}
            onChange={(value) => handleUnitsPerPackChange(row.id, value)}
          />
        </Form.Item>
      );
    }
    return null;
  };

  const renderActions = (_text, row) => {
    if (row.type === 'product') {
      return (
        <div>
          <Tooltip title="Imprimir etiqueta de producto">
            <Button
              className="actionsButton"
              icon={<PrinterOutlined className="contentIcon" />}
              onClick={() => {
                const url = `https://sellercentral.amazon.com/fba/printitemlabel/?mSku.0=${row.sku}`;
                window.open(url, '_blank');
              }}
            />
          </Tooltip>
          {!isPartnered && (
            <>
              <Modal
                key={row.id}
                title="Editar información del producto"
                open={editProductModalOpen[row.id]}
                onOk={() => handleEditProductFormFinish(row.id)}
                onCancel={() => handleEditProductModalClose(row.id)}
                width="50%"
                okText="Guardar"
              >
                <Form
                  layout="vertical"
                  onFinish={(values) => onFinishEditProduct(row.id, values)}
                  key={row.id}
                  ref={(editForm) => {
                    editProductFormRefs.current[row.id] = editForm;
                  }}
                >
                  <Col span={24}>
                    <ProductRow product={editProduct} />
                  </Col>
                </Form>
              </Modal>
              <Tooltip title="Editar información del producto">
                <Button
                  disabled={
                    !selectedRowKeys.find((key) => key === row.id) ||
                    disableInput
                  }
                  className="actionsButton"
                  icon={<EditOutlined className="contentIcon" />}
                  onClick={() => handleEditProductModalOpen(row.id)}
                />
              </Tooltip>
            </>
          )}
        </div>
      );
    }
    return null;
  };

  const renderExpirationDate = (_text, row) => {
    if (row.type === 'product') {
      return (
        <Tooltip
          title={
            !row.expires &&
            selectedRowKeys.find((key) => key === row.id) &&
            t('orders.contents.expirationDateTooltip')
          }
        >
          <Form.Item
            noStyle
            className="input-form-margin-bottom"
            initialValue={
              row.expirationDate ? moment(row.expirationDate) : undefined
            }
            name={`expirationDate-${row.key}`}
            rules={[
              {
                required: row.expires && selectedRowKeys.includes(row.id),
                message: 'Requerido',
              },
            ]}
          >
            <DatePicker
              className="datePickerStyle"
              placeholder="YYYY-MM-DD"
              value={
                expirationDates[row.id] ? moment(expirationDates[row.id]) : null
              }
              onChange={(date, dateString) =>
                handleExpirationDateChange(row.id, dateString)
              }
              disabled={
                !row.expires ||
                disableInput ||
                !selectedRowKeys.find((key) => key === row.id)
              }
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </Tooltip>
      );
    }
    return null;
  };

  const searchProduct = (
    <Space>
      Producto
      <Search
        placeholder="Buscar por SKU"
        allowClear
        onPressEnter={onPressEnter}
        onSearch={onSearch}
        style={{
          width: 180,
        }}
        className="table-search"
      />
    </Space>
  );

  const columnTitle = (title, tooltip) => (
    <Row justify="center" align="middle">
      <Col className="column-title">{title}</Col>
      <Col>
        <Tooltip placement="bottom" title={tooltip}>
          <InfoCircleOutlined />
        </Tooltip>
      </Col>
    </Row>
  );

  const expirationDateTitle = columnTitle(
    'Fecha de expiración',
    'Selecciona la fecha de vencimiento de tu producto (solo si aplica). El límite máximo permitido es de 2 años a partir de la fecha actual. Es importante tener en cuenta que Amazon remueve los productos 3.5 meses antes de su vencimiento por lo tanto se sugiere un mínimo de un año de vigencia.'
  );

  const boxesTitle = columnTitle(
    'Número de cajas',
    'Este valor se calcula automáticamente dividiendo las unidades totales del producto entre las unidades por caja. Si el resultado no corresponde a un número entero, significa que debes ajustar las unidades totales o las unidades por caja para obtener un número adecuado de cajas.'
  );

  const unitsPerPackTitle = columnTitle(
    'Unidades por caja',
    'Indica la cantidad de unidades que caben en la caja que utilizas para enviar el producto seleccionado.'
  );

  const unitsTitle = columnTitle(
    'Unidades totales',
    'Indica el número total de unidades del producto seleccionado que deseas incluir en tu envío de restock.'
  );

  const columns = [
    {
      title: searchProduct,
      dataIndex: 'name',
      key: 'product',
      align: 'left',
      render: renderName,
      ellipsis: {
        showTitle: false,
      },
      width: isPack ? '35%' : '60%',
      onFilter: (value, record) =>
        record.name.toLowerCase().startsWith(value.toLowerCase()),
      filterSearch: true,
    },
    {
      title: unitsTitle,
      dataIndex: 'quantity',
      key: 'units',
      render: renderQuantity,
      align: 'center',
      width: '14%',
    },
    ...(isPack
      ? [
          {
            title: unitsPerPackTitle,
            dataIndex: 'unitsPerPack',
            key: 'unitsPerPack',
            render: renderUnitsPerPack,
            align: 'center',
            width: '14%',
          },
        ]
      : [{}]),
    ...(isPack
      ? [
          {
            title: boxesTitle,
            key: 'boxes',
            render: (_text, row) => {
              if (row.type === 'product') {
                const quantity = quantityValues[row.id];
                const unitsPerPack = unitsPerPackValues[row.id];

                if (quantity !== undefined && unitsPerPack !== undefined) {
                  const division = quantity / unitsPerPack;
                  return division === Math.ceil(division)
                    ? `${division.toString()} 📦`
                    : 'Inválido';
                }
              }
              return '';
            },
            width: '13%',
            align: 'center',
          },
        ]
      : [{}]),
    {
      title: expirationDateTitle,
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      render: renderExpirationDate,
      width: '15%',
      align: 'center',
    },
    {
      title: 'Acciones',
      key: 'print',
      dataIndex: 'print',
      render: renderActions,
      align: 'center',
      width: '11%',
    },
  ];

  useEffect(() => {
    const getExpandedRowKeys = (listings) => {
      const rowKeys = listings.map((listing) => listing.key); // Expand all rows
      setExpandedRowKeys(rowKeys);
    };
    const formatDataSource = async (listings) => {
      const data = [];
      listings.forEach((listing) => {
        const children = [];
        listing.products.forEach((product) => {
          let imageUrl;
          if (product?.attributes && Array.isArray(product?.attributes))
            imageUrl = product?.attributes.main_offer_image_locator
              ? product?.attributes.main_offer_image_locator[0]?.media_location
              : null;
          else
            imageUrl = product?.attributes.main_offer_image_locator
              ? product?.attributes.main_offer_image_locator?.media_location
              : null;
          let expires;
          if (Array.isArray(product.attributes?.is_expiration_dated_product)) {
            expires = product.attributes?.is_expiration_dated_product
              ? product.attributes.is_expiration_dated_product[0].value
              : false;
          } else {
            expires = product.attributes?.is_expiration_dated_product
              ? product.attributes.is_expiration_dated_product.value
              : false;
          }
          const selectedProduct = selectedProducts.find(
            (prod) => prod.id === product.id
          );
          const expirationDate = selectedProduct?.expirationDate || undefined;
          const quantity = selectedProduct?.quantity || undefined;
          const unitsPerPack = selectedProduct?.unitsPerPack || undefined;
          children.push({
            key: product.id,
            id: product.id,
            type: 'product',
            name: product.name,
            sku: product.sku,
            image: imageUrl,
            expires,
            expirationDate,
            quantity,
            unitsPerPack,
          });
        });
        data.push({
          key: listing.id,
          name: listing.name,
          type: 'listing',
          children,
        });
      });
      return data;
    };
    const updateDataSource = async () => {
      const formattedData = await formatDataSource(listingsAndProducts);
      setDataSource(formattedData);
      setFilteredDataSource(formattedData);
      getExpandedRowKeys(formattedData); // This will expand all rows
    };
    if (listingsAndProducts) {
      updateDataSource();
    }
  }, [listingsAndProducts]);

  return filteredDataSource ? (
    <Col style={{ marginTop: 24 }}>
      <Table
        expandable={expandable}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={filteredDataSource}
        rowClassName={rowClassName}
        pagination={{
          defaultPageSize: 3, // Start with 3 rows per page
          showSizeChanger: true, // Allow the user to change the page size
          pageSizeOptions: ['3', '5', '10', '20'], // Options for page size
        }}
        sticky
        className="listing-shipment-table"
      />
    </Col>
  ) : null;
}

ContentsTable.propTypes = {
  form: PropTypes.instanceOf(Form).isRequired,
  listingsAndProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          attributes: PropTypes.arrayOf(
            PropTypes.shape({
              is_expiration_dated_product: PropTypes.shape({
                value: PropTypes.bool,
              }),
              main_offer_image_locator: PropTypes.arrayOf(
                PropTypes.shape({
                  media_location: PropTypes.string,
                })
              ),
            })
          ),
          productTemplate: PropTypes.shape({
            countryOfManufacture: PropTypes.string,
            harmonizedCode: PropTypes.string,
          }),
          name: PropTypes.string,
          price: PropTypes.string,
          sku: PropTypes.string,
          id: PropTypes.number,
          pack: PropTypes.bool,
        })
      ),
    })
  ).isRequired,
  order: PropTypes.shape({
    state: PropTypes.string,
    clientId: PropTypes.number,
  }).isRequired,
  isPack: PropTypes.bool.isRequired,
  selectedProducts: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedRowKeys: PropTypes.func.isRequired,
  isPartnered: PropTypes.bool.isRequired,
  quantityValues: PropTypes.objectOf(PropTypes.number).isRequired,
  setQuantityValues: PropTypes.func.isRequired,
  unitsPerPackValues: PropTypes.objectOf(PropTypes.number).isRequired,
  setUnitsPerPackValues: PropTypes.func.isRequired,
  expirationDates: PropTypes.objectOf(PropTypes.string).isRequired,
  setExpirationDates: PropTypes.func.isRequired,
};

export default ContentsTable;

import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Spin, Tooltip, Typography } from 'antd';
import * as queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getErrorMessage } from '../../../../api/api';
import accessKeysApi from '../../../../api/aws-access-keys';
import syncStatusApi from '../../../../api/sync-status';
import openNotification from '../../../../components/Toastr';
import * as Actions from '../../../../redux/session/action';
import CollapseMarketplaceRow from '../CollapseMarketplaceRow';
import MarketplaceRow from '../MarketplaceRow';
import MarketplaceRowSkeleton from '../MarketplaceRowSkeleton';
import MySellerAccountFilters from './MySellerAccountFilters';
import MySellerAccountFiltersSkeleton from './MySellerAccountFiltersSkeleton';
import { SyncStatusEnum, SyncStatusTypesEnum } from '../../../../utils/const';
import Banner, { AlertTypeEnum } from '../../../../components/Alert';
import './mySellerAccount.css';

const { Text } = Typography;

function MySellerAccount() {
  const { t } = useTranslation();
  const session = useSelector((store) => store.Session.session);
  const [userMarketplaceCredentials, setUserMarketplaceCredentials] = useState(
    []
  );
  const [
    initialUserMarketplaceCredentials,
    setInitialUserMarketplaceCredentials,
  ] = useState([]);
  const { marketplace, consent } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const urlQueryString = queryString.parse(location.search);
  const adsCode = urlQueryString.code;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    marketplace: '',
  });
  const [isSynchronizing, setIsSynchronizing] = useState(false);

  const getAllUserCredentials = async () => {
    try {
      const { data } = await accessKeysApi.getUserMarketplaceCredentials(
        session?.userInfo?.commercial_partner_id[0]
      );

      setUserMarketplaceCredentials(data);
      setInitialUserMarketplaceCredentials(data);
      setIsLoading(false);
    } catch (error) {
      openNotification({
        status: false,
        content: getErrorMessage(error),
      });
      throw error;
    }
  };

  useEffect(() => {
    getAllUserCredentials();
  }, [session]);

  const handleConsentCode = async () => {
    const response = await accessKeysApi.saveAdsCredentials(
      session?.userInfo?.commercial_partner_id[0],
      'Amazon',
      { authGrant: adsCode }
    );
    if (!response?.data?.success && !response?.data?.id) {
      openNotification({
        status: false,
        content:
          'Error al sincronizar la cuenta de advertising. Intente nuevamente.',
      });
      history.push('/my-account?step=2');
    }
  };

  useEffect(() => {
    if (adsCode && typeof marketplace === 'undefined') {
      handleConsentCode();
      dispatch(Actions.updateAdsAccountStatusSession(true));
      window.location.href = '/my-account?step=2';
    }
    if (consent && marketplace) {
      const qs = queryString.parse(location.search);
      if (!qs) {
        return;
      }
      let market = marketplace;
      if (marketplace === 'Amazon') {
        if (qs?.state?.split('-')[1] === 'eu') {
          market = 'Amazon Europe';
        }
        if (
          qs?.state?.split('-')[1] === 'vendor' &&
          qs?.state?.split('-')[2] === 'na'
        ) {
          market = 'Amazon Vendor';
        }
      }

      const activeSession = session?.userInfo?.commercial_partner_id[0]
        ? session?.userInfo?.commercial_partner_id[0]
        : 0;

      accessKeysApi
        .saveCredentials(activeSession, market, qs)
        .then((resp) => {
          if (!resp?.data?.success && !resp?.data?.id) {
            openNotification({
              status: false,
              content: 'Error al sincronizar el seller. Intente nuevamente.',
            });
            history.push('/my-account?step=2');
            return;
          }
          openNotification({
            status: true,
            content: `Se asoció la cuenta de ${market} con exito!`,
          });
          if (activeSession === 0 && resp?.data?.id) {
            window.location = `/login?link=${resp?.data?.id}`;
          } else {
            history.push('/my-account?step=2');
            dispatch(Actions.updateSellerAccountStatusSession(true));
          }
        })
        .catch((err) => {
          let notificationMessage =
            'Error al sincronizar el seller. Intente nuevamente.';
          if (err?.response?.data?.message) {
            notificationMessage = err.response.data.message;
          }
          history.push('/my-account?step=2');
          openNotification({
            status: false,
            content: notificationMessage,
          });
        });
    }
  }, [consent, marketplace]);

  useEffect(() => {
    if (filters.marketplace === '') {
      setUserMarketplaceCredentials(initialUserMarketplaceCredentials);
    } else {
      setUserMarketplaceCredentials(
        initialUserMarketplaceCredentials.filter((credential) => {
          let finalName;

          if (credential.parentMarketplace) {
            switch (credential?.parentMarketplace?.name) {
              case 'Edicommerce':
                finalName = 'Walmart DSV';
                break;
              default:
                finalName = credential?.parentMarketplace?.name;
            }

            return finalName
              .toLocaleLowerCase()
              .includes(filters?.marketplace?.toLocaleLowerCase());
          }

          switch (credential.name) {
            case 'Edicommerce':
              finalName = 'Walmart DSV';
              break;
            default:
              finalName = credential.name;
          }

          return finalName
            .toLocaleLowerCase()
            .includes(filters.marketplace.toLocaleLowerCase());
        })
      );
    }
  }, [filters]);

  const getSyncStatus = async () => {
    const syncStatusResponse = await syncStatusApi.get();
    const statusTypes = [
      SyncStatusTypesEnum.SYNC_LISTINGS,
      SyncStatusTypesEnum.SALE_ORDERS,
    ];
    if (syncStatusResponse?.data) {
      const status = syncStatusResponse.data?.find(
        (element) =>
          statusTypes.includes(element.type) &&
          element.status === SyncStatusEnum.RUNNING
      );
      setIsSynchronizing(!!status?.id);
    } else {
      setIsSynchronizing(false);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!session || !isSynchronizing) {
        return;
      }
      getSyncStatus();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [isSynchronizing]);

  useEffect(() => {
    if (session) {
      getSyncStatus();
    }
  }, []);

  if (consent && marketplace) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '75vh',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      {isSynchronizing && (
        <Row>
          <Col span={24}>
            <Banner
              message={
                <Row>
                  <Col style={{ maxWidth: 700 }}>
                    <Text style={{ marginRight: 5 }} strong>
                      {t('myAccount.banner.syncMarketplacesTitle')}
                    </Text>
                    <Text>
                      {t('myAccount.banner.syncMarketplacesDescription')}
                    </Text>
                  </Col>
                </Row>
              }
              type={AlertTypeEnum.INFO}
              closable="true"
            />
          </Col>
        </Row>
      )}
      {isLoading && <MySellerAccountFiltersSkeleton />}
      {!isLoading && (
        <MySellerAccountFilters filters={filters} setFilters={setFilters} />
      )}

      <div id="datosCuentaBanco" className="mySellerAccountContainer">
        <Row gutter={[12, 2]} className="contentColumnsMarketplaces">
          <Col xs={24} sm={24} md={5}>
            <span>
              Marketplaces
              <Tooltip
                className="tooltipColumns"
                title={t('myAccount.sellerAccount.switchInfo')}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          </Col>
          <Col xs={24} sm={24} md={5}>
            <span>
              Estado
              <Tooltip
                className="tooltipColumns"
                title={t('myAccount.sellerAccount.switchInfo')}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          </Col>
          <Col xs={24} sm={24} md={5}>
            <span>
              Publicidad
              <Tooltip
                className="tooltipColumns"
                title={t('myAccount.sellerAccount.switchInfo')}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          </Col>
          <Col xs={24} sm={24} md={5}>
            <span>
              Logistica
              <Tooltip
                className="tooltipColumns"
                title={t('myAccount.sellerAccount.switchInfo')}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          </Col>
          <Col xs={24} sm={24} md={4}>
            <span>
              Creación de cuenta
              <Tooltip
                className="tooltipColumns"
                title={t('myAccount.sellerAccount.switchInfo')}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          </Col>
        </Row>

        {isLoading && <MarketplaceRowSkeleton />}

        {!isLoading &&
          userMarketplaceCredentials.length > 0 &&
          userMarketplaceCredentials?.map((creds) => {
            if (
              creds?.parentMarketplace?.multipleCredentials === true &&
              creds?.marketplaceCredentials?.length > 0
            ) {
              return <CollapseMarketplaceRow marketplaceByUser={creds} />;
            }
            return <MarketplaceRow key={creds.id} marketplaceByUser={creds} />;
          })}
      </div>
    </>
  );
}

export default MySellerAccount;

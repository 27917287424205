import React, { useState } from 'react';
import { Col, Row } from 'antd';
import '../plans.css';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function InactiveBanner() {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);
  const dismissBanner = () => setShow(false);

  return (
    <div>
      {show && (
        <Row className="plans-banner-inactive">
          <Col
            span={1}
            style={{
              display: 'flex',
              justifyContent: 'right',
              padding: '2px 10px',
            }}
          >
            <ExclamationCircleOutlined
              id="custom-icon-color"
              style={{ fontSize: '18px' }}
            />
          </Col>
          <Col span={15}>
            <div className="error-banner">
              <span>
                <strong>{t('plans.inactiveBoldMessage')}</strong>{' '}
                {t('plans.inactiveMessage')}
              </span>
            </div>
          </Col>
          <Col
            span={8}
            style={{ display: 'flex', justifyContent: 'right' }}
            align="top"
          >
            <button onClick={dismissBanner} type="button">
              x
            </button>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default InactiveBanner;

import { Button, Alert } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

export const AlertTypeEnum = Object.freeze({
  INFO: 'info',
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
});

function Banner({ message, type, closable }) {
  return (
    <Alert
      message={message}
      type={type}
      showIcon
      action={<Button size="small" type="text" />}
      closable={closable}
      style={{ marginBottom: 30 }}
    />
  );
}

Banner.propTypes = {
  message: PropTypes.objectOf(Object).isRequired,
  type: PropTypes.oneOf(AlertTypeEnum).isRequired,
  closable: PropTypes.bool.isRequired,
};

export default Banner;

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import FedexPickup from './FedexPickup';

function EditPickUpModal({
  isOpen,
  setIsOpen,
  courierShipment,
  courierCredentialId,
  getOrder,
  warehouseId,
}) {
  const { t } = useTranslation();
  const handleCancelModal = () => {
    setIsOpen(false);
  };

  const handleNewPickUp = () => {
    getOrder();
    setIsOpen(false);
  };

  return (
    <Modal
      title={t('orders.shipment.summary.editPickUp')}
      open={isOpen}
      onCancel={handleCancelModal}
      footer={false}
      okText={t('common.next')}
      width="80%"
    >
      <FedexPickup
        warehouseId={warehouseId}
        courierCredentialId={courierCredentialId}
        showConfirmButton
        orderCourierShipments={courierShipment}
        pickUpId={courierShipment?.pickup?.id}
        callback={handleNewPickUp}
      />
    </Modal>
  );
}

EditPickUpModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  courierShipment: PropTypes.isRequired,
  courierCredentialId: PropTypes.string.isRequired,
  getOrder: PropTypes.func.isRequired,
  warehouseId: PropTypes.number.isRequired,
};

export default EditPickUpModal;

import { Button, Form, Input, Modal, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import accessKeysApi from '../../../../api/aws-access-keys';
import openNotification from '../../../../components/Toastr';
import * as Actions from '../../../../redux/session/action';

const { Item } = Form;
function ShopifySellerAccount({ tab, session }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loadingIsValidAccount, setLoadingIsValidAccount] = useState(false);

  const saveCredentials = (credentials) => {
    setLoadingIsValidAccount(true);
    accessKeysApi
      .saveCredentials(
        session?.userInfo?.commercial_partner_id[0],
        tab,
        credentials
      )
      .then(() => {
        Modal.success({
          content: 'Se asoció la cuenta exitosamente',
        });
        form.resetFields();
        setLoadingIsValidAccount(false);
        dispatch(Actions.updateSellerAccountStatusSession(true));
      })
      .catch(() => {
        setLoadingIsValidAccount(false);
        openNotification({
          status: false,
          content: 'No se pudo validar la cuenta',
        });
      });
  };

  return (
    <Form name={`${tab}-form`} form={form} onFinish={saveCredentials}>
      <Row>
        <span
          style={{
            fontSize: '18px',
            marginBottom: 10,
            marginTop: 15,
            width: '100%',
          }}
        >
          Shop name
        </span>
      </Row>
      <Row>
        <Item
          name="shop"
          rules={[{ required: true, message: 'Por favor, ingresar Shop name' }]}
          style={{
            width: '100%',
            borderRadius: '10px',
          }}
        >
          <Input
            placeholder="mi-tienda-lap.myshopify.com"
            className="newModalConnectInput"
          />
        </Item>
      </Row>

      <Row>
        <span
          style={{
            fontSize: '18px',
            marginBottom: 10,
            marginTop: 15,
            width: '100%',
          }}
        >
          Token
        </span>
      </Row>
      <Row>
        <Item
          name="secret_key"
          rules={[{ required: true, message: 'Por favor, ingresar Token' }]}
          style={{
            width: '100%',
            borderRadius: '10px',
          }}
        >
          <Input
            placeholder="shpat_rt2zmvh13iirw3qt91950xbzfhssallc"
            className="newModalConnectInput"
          />
        </Item>
      </Row>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type="primary"
          style={{
            marginTop: 40,
            marginBottom: 15,
            padding: '5px 20px',
            height: '40px',
          }}
          onClick={() => form.submit()}
          loading={loadingIsValidAccount}
          disabled={loadingIsValidAccount}
        >
          Conectar marketplace
        </Button>
      </div>
    </Form>
  );
}

export default ShopifySellerAccount;

ShopifySellerAccount.propTypes = {
  tab: PropTypes.string.isRequired,
  session: PropTypes.instanceOf(Object).isRequired,
};

import { createSelector } from 'reselect';

const selectRaw = (state) => state.Analytic.dashboard;

const selectLoading = createSelector([selectRaw], (raw) => raw.loading);
const selectRows = createSelector([selectRaw], (raw) => raw.rows);

const selectRowsStock = createSelector([selectRaw], (raw) => raw.rowsStock);

const selectRawFilter = createSelector([selectRaw], (raw) => raw.rawFilter);
const selectCurrency = createSelector(
  [selectRaw],
  (raw) => raw.currencyDefault
);

const selectRawFilterResumen = createSelector(
  [selectRaw],
  (raw) => raw.rawFilterResumen
);

const selectLoadingProgress = createSelector(
  [selectRaw],
  (raw) => raw.loadingProgress
);
const selectListMarketplace = createSelector(
  [selectRaw],
  (raw) => raw.marketplaceAvailable
);
const dashboardSelectors = {
  selectLoading,
  selectRows,
  selectRowsStock,
  selectRawFilter,
  selectRawFilterResumen,
  selectLoadingProgress,
  selectListMarketplace,
  selectCurrency,
};

export default dashboardSelectors;

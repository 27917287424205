import React, { useEffect, useRef, useState } from 'react';
import {
  Affix,
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Radio,
  Row,
  Space,
} from 'antd';
import { SendOutlined, InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import 'antd/dist/antd.min.css';
import { useTranslation } from 'react-i18next';
import { validateMessages } from '../../../../../../utils/const';
import boxApi from '../../../../../../api/box';
import openNotification from '../../../../../../components/Toastr';
import productApi from '../../../../../../api/product';
import FBMCouriers from '../../../../../../utils/FBMCouriers';
import FedexFbmBoxTable from '../../Fedex/components/FedexFbmBoxTable';
import CreateBoxModal from '../../Fedex/components/CreateBoxModal';
import ShipsmartFbmProductTable from '../components/ShipsmartFbmProductTable';
import ShipsmartConsiderations from '../components/ShipsmartConsiderations';
import orderSalesApi from '../../../../../../api/order-sales';
import WarehousesAPI from '../../../../../../api/warehouses';
import ShipsmartSpecificInfo from '../components/ShipsmartSpecificInfo';

function ShipsmartProductBoxStep({
  orders,
  setActualStep,
  setData,
  warehouseId,
  courierCredentials,
}) {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const { Item } = Form;
  const [radioDefault, setRadioDefault] = useState(false);
  const [unitMeasure, setUnitMeasurement] = useState('CM');
  const [products, setProducts] = useState();
  const [boxes, setBoxes] = useState([]);
  const [showCreateBoxModal, setShowCreateBoxModal] = useState(false);
  const [initialBoxesNumber, setInitialBoxesNumber] = useState({});
  const [originCountryCode, setOriginCountryCode] = useState(null);
  const mounted = useRef(false);

  const marginRadio = {
    marginBottom: 0,
  };

  const inputRadioActive = {
    border: '1px solid',
  };

  const step = 1;

  useEffect(async () => {
    try {
      const ordersIds = orders.map((order) => {
        return order.id;
      });
      const response = await productApi.getBySalesOrdersId(ordersIds, 'FBM');
      setProducts(response.data);
      setData([]);
    } catch (error) {
      openNotification({
        status: false,
        content: 'Ha ocurrido un error al cargar las ordenes',
      });
    }
  }, []);

  useEffect(async () => {
    try {
      const response = await WarehousesAPI.getWarehouse(warehouseId);
      setOriginCountryCode(response.data.address.countryCode);
    } catch (error) {
      openNotification({
        status: false,
        content: 'Ha ocurrido un error el país de orígen',
      });
    }
  }, []);

  useEffect(() => {
    if (
      [FBMCouriers.FEDEX_US].includes(courierCredentials?.courierCompanyName)
    ) {
      form.setFieldValue('unitMeasurement', 'IN');
    } else {
      form.setFieldValue('unitMeasurement', 'CM');
    }
  }, [courierCredentials]);

  const syncProducts = async () => {
    const changeProducts = {};
    products.forEach((product) => {
      changeProducts[product.id] = {
        productTemplate: {
          harmonizedCode:
            form.getFieldValue()[`harmonizedCode-${product.defaultCode}`],
          unitFOB: form.getFieldValue()[`fob-${product.defaultCode}`],
          countryOfManufacture:
            form.getFieldValue()[`countryOfManufacture-${product.defaultCode}`],
        },
      };
    });
    try {
      const response = await productApi.bulkUpdate(changeProducts);
      return response.status;
    } catch (error) {
      openNotification({
        status: false,
        content: t('orders.shipment.products.saveError'),
      });
    }
    return false;
  };

  const syncBoxes = async () => {
    const payload = [];
    orders.forEach((saleOrder) => {
      const boxIndexes = Object.keys(form.getFieldValue().boxes[saleOrder.id]);
      const orderPayload = { id: saleOrder.id, boxes: [] };
      boxIndexes.forEach((boxIndex) => {
        const selectedBox = boxes.filter(
          (box) =>
            box.name === form.getFieldValue().boxes[saleOrder.id][boxIndex].name
        );
        if (selectedBox.length > 0) {
          orderPayload.boxes.push({
            weight: {
              value:
                form.getFieldValue().boxes[saleOrder.id][boxIndex].weight ||
                null,
            },
            id: selectedBox[0].id,
          });
        }
      });
      payload.push(orderPayload);
    });
    try {
      const response = await orderSalesApi.updateSaleOrderBoxes(payload);
      return response.status;
    } catch (error) {
      openNotification({
        status: false,
        content: t('orders.shipment.boxConfiguration.saveError'),
      });
    }
    return false;
  };

  const onFinish = async (formFields) => {
    const updateProductsStatus = await syncProducts();
    const updateBoxesStatus = await syncBoxes();

    if (updateProductsStatus === 200 && updateBoxesStatus === 200) {
      const shipments = orders.map((order) => {
        const formBoxes = [];
        if (formFields.boxes[order.id]?.length > 0) {
          formFields.boxes[order.id].forEach((box) => {
            formBoxes.push({
              weight: {
                units: unitMeasure === 'CM' ? 'KG' : 'LB',
                value: box.weight,
              },
              dimensions: {
                length: parseFloat(box.length),
                width: parseFloat(box.width),
                height: parseFloat(box.height),
                units: unitMeasure,
              },
            });
          });
        } else {
          openNotification({
            status: false,
            content: t('orders.shipment.boxConfiguration.addBoxesMessage'),
          });
        }
        return {
          ...formFields,
          saleOrderId: order.id,
          boxes: formBoxes,
          warehouseId,
        };
      });
      setData(shipments);
      setActualStep(1);
    }
  };

  const getBoxes = async () => {
    const isPallet = false;
    const newBoxes = await boxApi.getByCompanyId(unitMeasure, isPallet);
    if (newBoxes.data) {
      setBoxes(newBoxes.data);
    }
  };

  useEffect(() => {
    getBoxes();
  }, [unitMeasure]);

  useEffect(() => {
    if (!showCreateBoxModal && mounted.current) {
      getBoxes();
    } else if (!mounted.current) {
      mounted.current = true;
    }
  }, [showCreateBoxModal]);

  useEffect(() => {
    if (mounted.current) {
      const boxesFormData = {};
      const numberOfBoxes = {};
      orders.forEach((order) => {
        if (order.boxes.length > 0) {
          const saleOrderBoxes = {};
          order.boxes.forEach((saleOrderBox) => {
            const selectedBox = boxes.filter(
              (box) => box.id === saleOrderBox.box.id
            );
            if (selectedBox.length > 0) {
              saleOrderBoxes[Object.keys(saleOrderBoxes).length] = {
                name: selectedBox[0].name,
                length: selectedBox[0].length,
                width: selectedBox[0].width,
                height: selectedBox[0].height,
                weight: saleOrderBox.weight,
              };
            }
          });
          boxesFormData[order.id] = saleOrderBoxes;
          numberOfBoxes[order.id] = Object.keys(saleOrderBoxes).length;
        }
      });
      setInitialBoxesNumber(numberOfBoxes);
      form.setFieldsValue({
        boxes: boxesFormData,
      });
    }
  }, [boxes]);

  return (
    <>
      <ShipsmartConsiderations />
      {step && (
        <div id="contents">
          <Form
            name="contentForm"
            form={form}
            initialValues={{
              insurance: 'true',
              taxPayer: 'sender',
            }}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            validateMessages={validateMessages}
            className="text-align-left form-padding-top restockStepOne"
            scrollToFirstError
            onFinish={onFinish}
          >
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{
                  alignItems: 'flex-start',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'column',
                }}
              >
                <h3 className="title-grey-box">
                  {t('orders.shipment.unitMeasurement.title')}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24}>
                <Item
                  className="input-form-margin-bottom"
                  name="unitMeasurement"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                  tooltip={{
                    title: t('orders.shipment.unitMeasurement.title'),
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Radio.Group
                    className="cardRadioGroupContent"
                    name="unitMeasurement"
                    onChange={(e) => {
                      setRadioDefault(true);
                      setUnitMeasurement(e.target.value);
                    }}
                  >
                    <Card
                      className="card-radio-inline cardBaseStyle"
                      style={radioDefault ? inputRadioActive : {}}
                    >
                      <div className="card-content-div">
                        <p className="text-card-radio" style={marginRadio}>
                          {t('orders.shipment.unitMeasurement.metric')}
                        </p>
                        <span className="span-card-radio">CM / KG</span>
                      </div>
                      <Radio className="positionRadioContent" value="CM" />
                    </Card>
                  </Radio.Group>
                </Item>
              </Col>
            </Row>

            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{
                  alignItems: 'flex-start',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'column',
                  marginTop: 25,
                }}
              >
                <h3 className="title-grey-box">
                  Paso 2: completa la información requerida
                </h3>
              </Col>
            </Row>
            <ShipsmartSpecificInfo
              originCountryCode={originCountryCode}
              destinationCountryCode={orders[0].destinationAddress.countryCode}
            />
            <Col
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                marginTop: '3em',
              }}
            >
              <h3 className="title-grey-box">
                {t('orders.shipment.detailShipments')}
              </h3>
            </Col>
            <Collapse defaultActiveKey={orders.map((order) => order.id)}>
              {orders.map((order) => (
                <Panel
                  header={`${order.destinationAddress.city} - ID: ${order.orderId} `}
                  key={order.id}
                >
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <ShipsmartFbmProductTable
                        form={form}
                        order={order}
                        products={products}
                      />
                    </Col>
                  </Row>
                  <Col
                    style={{
                      alignItems: 'flex-start',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexDirection: 'column',
                      marginTop: '3em',
                    }}
                  >
                    <h3 className="title-grey-box">
                      Paso 2: determina la configuración de las cajas
                    </h3>
                  </Col>
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <FedexFbmBoxTable
                        boxes={boxes}
                        form={form}
                        orderId={order.id}
                        setShowCreateBoxModal={setShowCreateBoxModal}
                        unitMeasure={unitMeasure}
                        initialBoxesNumber={initialBoxesNumber}
                      />
                    </Col>
                  </Row>
                </Panel>
              ))}
            </Collapse>
            <Row justify="end" style={{ marginTop: 24 }}>
              <Col>
                <Affix offsetBottom={10}>
                  <Space>
                    <Button
                      className="btn-link-filled"
                      icon={<SendOutlined />}
                      htmlType="submit"
                    >
                      {t('orders.alertButtonSend')}
                    </Button>
                  </Space>
                </Affix>
              </Col>
            </Row>
          </Form>
          <CreateBoxModal
            showCreateBoxModal={showCreateBoxModal}
            setShowCreateBoxModal={setShowCreateBoxModal}
            unitMeasure={unitMeasure}
          />
        </div>
      )}
    </>
  );
}

ShipsmartProductBoxStep.propTypes = {
  orders: PropTypes.arrayOf(Object).isRequired,
  setActualStep: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  warehouseId: PropTypes.number.isRequired,
  courierCredentials: PropTypes.objectOf(Object).isRequired,
};

export default ShipsmartProductBoxStep;

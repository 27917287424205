import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Card,
  Col,
  DatePicker,
  Form,
  Radio,
  Row,
  Spin,
  Steps,
  Typography,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { getErrorMessage } from '../../../../api/api';
import openNotification from '../../../../components/Toastr';
import restockOrdersAPI from '../../../../api/restock-orders';
import FBMCouriers from '../../../../utils/FBMCouriers';
import ShipsmartPickupSummaryStep from '../couriers/Shipsmart/steps/ShipsmartPickupSummaryStep';
import couriersApi from '../../../../api/courier';
import { orderGeneralStates, orderSteps } from '../../../../utils/const';
import NonPartneredAmazonConfirm from './components/NonPartneredAmazonOrderState/NonPartneredAmazonConfirm';
import CheckCards from './components/CheckCards';
import FedexPickup from './components/Pickup/FedexPickup';
import StickyAlert from './components/StickyAlert';

const { Step } = Steps;

function ShipmentOptions({ orderId, setSelected, setSteps, title }) {
  const { t } = useTranslation();
  const [order, setOrder] = useState();
  const [selectedCourier, setSelectedCourier] = useState({});
  const [shipsmartData, setShipsmartData] = useState([]);

  const [isPartnered, setIsPartnered] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [radioDefault, setRadioDefault] = useState(false);
  const [isPickUp, setIsPickUp] = useState();
  const [deliveryOfficeForm] = Form.useForm();
  const [deliveryOfficeDate, setDeliveryOfficeDate] = useState(null);

  const [loadingConfirmShipments, setLoadingConfirmShipments] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const formRef = React.useRef(null);
  const nonPartneredAmazonConfirmRef = useRef(null);
  const shipsmartPickUpSummaryStepRef = useRef(null);
  const fedexPickupRef = useRef(null);

  const generateShipsmartPayload = (restockResponse) => {
    return restockResponse.data.shipmentPlan.InboundShipmentPlans.map(
      (shipment) => {
        const filterBoxes = restockResponse.data.restockOrderBoxes.filter(
          (box) => box.shipmentId === shipment.ShipmentId
        );
        const boxesPayload = filterBoxes.flatMap((box) =>
          Array.from({ length: box.shippingAmount }, () => ({
            dimensions: {
              length: parseFloat(box.box.length),
              width: parseFloat(box.box.width),
              height: parseFloat(box.box.height),
              units: box.box.measureUnit,
            },
            weight: {
              value: parseFloat(box.weight),
              units: box.box.measureUnit === 'CM' ? 'KG' : 'LB',
            },
          }))
        );
        return {
          ...shipment,
          boxes: boxesPayload,
          taxPayer: 'sender',
          insurance: 'false',
          warehouseId: restockResponse.data.warehouse.id,
          restockOrderId: orderId,
        };
      }
    );
  };

  const getOrder = async () => {
    try {
      const restockResponse = await restockOrdersAPI.getContent(orderId);
      setOrder(restockResponse.data);
      setSteps(restockResponse.data.steps);
      const restockOrder = restockResponse.data;
      const partnered =
        restockResponse.data.warehouse.address.countryCode ===
        restockResponse.data.destination;
      setIsPartnered(partnered);
      const courierResponse = await couriersApi.getCourierCredentials(false, 1);
      const data = courierResponse.data.results.map((value) => ({
        key: value.id,
        name: value.courierCompanyName,
        accountNumber: value.accountNumber,
      }));
      const actualCourier = data.find(
        (courier) => courier.key === Number(restockResponse.data.courier)
      );
      setSelectedCourier(actualCourier);
      if (
        actualCourier.name === FBMCouriers.SHIPSMART &&
        restockOrder.state === orderGeneralStates.WORKING
      ) {
        const shipsmartPayload = generateShipsmartPayload(restockResponse);
        setShipsmartData(shipsmartPayload);
      }
    } catch (error) {
      openNotification({
        status: false,
        content: 'Error al obtener orden de restock.',
      });
    }
  };

  useEffect(async () => {
    if (!order) {
      getOrder();
    }
  }, []);

  const disabledPreviousDate = (current) => {
    return current && current < moment(new Date()).subtract(1, 'day');
  };

  const onFinishDelivery = async (values) => {
    return values;
  };

  const onSubmitButtonClickDelivery = () => {
    return new Promise((resolve, reject) => {
      deliveryOfficeForm
        .validateFields()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          openNotification({ status: false, content: getErrorMessage(error) });
          reject(error);
        });
    });
  };

  const handleCancelForm = async () => {
    setLoadingDelete(true);
    try {
      await restockOrdersAPI.deleteRestockOrder(orderId);
      window.location = '/envios';
    } catch (error) {
      openNotification({
        status: false,
        content: 'Ha ocurrido un error al intentar cancelar la orden.',
      });
      setLoadingDelete(false);
    }
  };

  const onSubmitButtonClickPickUp = () => {
    return new Promise((resolve, reject) => {
      formRef.current
        .validateFields()
        .then(() => {
          const formValues = formRef.current.getFieldsValue();
          resolve(formValues);
        })
        .catch((error) => {
          openNotification({ status: false, content: getErrorMessage(error) });
          reject(error);
        });
    });
  };

  const officeDelivery = async () => {
    try {
      await restockOrdersAPI.update(order.id, {
        state: orderGeneralStates.SHIPPED,
        officeDeliveryDate: deliveryOfficeDate,
      });
      openNotification({
        status: true,
        content: t('orders.newOrder.state.success.description'),
        info: t('orders.newOrder.state.success.title'),
      });
    } catch (error) {
      openNotification({
        status: false,
        content: error?.response?.data?.message,
      });
    }
    getOrder();
  };

  const pickupCallback = async () => {
    try {
      await restockOrdersAPI.update(order.id, {
        state: orderGeneralStates.SHIPPED,
      });
      openNotification({
        status: true,
        content: t('orders.newOrder.state.success.description'),
        info: t('orders.newOrder.state.success.title'),
      });
    } catch (error) {
      openNotification({
        status: false,
        content: error?.response?.data?.message,
      });
    }
    getOrder();
  };

  const handleConfirmShipmentsInShipmentOptions = async () => {
    try {
      if (selectedCourier?.name === FBMCouriers.FEDEX) {
        let pickUpValues;
        if (isPickUp) {
          pickUpValues = await onSubmitButtonClickPickUp();
        } else if (!isPickUp && isPickUp !== null) {
          await onSubmitButtonClickDelivery();
        }
        await nonPartneredAmazonConfirmRef.current.handleConfirmShipments();
        if (isPickUp) {
          await fedexPickupRef.current.onPickupFinish(pickUpValues);
        } else if (!isPickUp && isPickUp !== null) {
          await officeDelivery();
        }
      } else if (selectedCourier?.name === FBMCouriers.SHIPSMART) {
        shipsmartPickUpSummaryStepRef.current.onFinish();
      }
      const step = order.steps.find((s) => s.step === orderSteps.ESTADO);
      await restockOrdersAPI.updateOrderStep(order.id, step.id, {
        state: 'Completado',
      });
      await getOrder();
      openNotification({
        status: true,
        content: t('orders.newOrder.shipment.success.description'),
        info: t('orders.newOrder.shipment.success.title'),
      });
      setSelected(3);
    } catch (error) {
      /* empty */
    }
  };

  return (
    <Spin spinning={loadingConfirmShipments || loadingDelete}>
      <div id="boxes" className="text-align-left">
        <div className="title-content">{title}</div>
      </div>
      <Row>
        <Steps progressDot direction="vertical" className="detail-steps">
          <Step
            key="1"
            title={
              <Typography.Text className="shipment-step-title">
                {t('orders.newOrder.shipment.step3Subtitle1')}
              </Typography.Text>
            }
            status="finish"
            description={
              <>
                {order &&
                  selectedCourier?.name === FBMCouriers.SHIPSMART &&
                  order.state === orderGeneralStates.WORKING && (
                    <ShipsmartPickupSummaryStep
                      ref={shipsmartPickUpSummaryStepRef}
                      data={shipsmartData}
                      setData={setShipsmartData}
                      restockOrderId={orderId}
                      setLoadingConfirmShipments={setLoadingConfirmShipments}
                      hideSendButton
                    />
                  )}
                {order && selectedCourier?.name === FBMCouriers.FEDEX && (
                  <NonPartneredAmazonConfirm
                    ref={nonPartneredAmazonConfirmRef}
                    order={order}
                    selectedService={selectedService}
                    setSelectedService={setSelectedService}
                    setLoadingConfirmShipments={setLoadingConfirmShipments}
                    isPickup={isPickUp}
                  />
                )}
              </>
            }
          />
          {selectedCourier?.name === FBMCouriers.FEDEX &&
            !isPartnered &&
            isPartnered !== null && (
              <Step
                key="2"
                title={
                  <Typography.Text className="shipment-step-title">
                    {t('orders.newOrder.shipment.chooseCollect')}
                  </Typography.Text>
                }
                status={selectedService ? 'finish' : 'wait'}
                description={
                  selectedService && (
                    <>
                      <Radio.Group
                        className="cardRadioGroupContent"
                        name="pickUpSelected"
                        onChange={(e) => {
                          setRadioDefault(true);
                          setIsPickUp(e.target.value);
                        }}
                        style={{ marginTop: 24, marginBottom: 18 }}
                      >
                        <CheckCards
                          isPack={isPickUp}
                          radioDefault={radioDefault}
                          inputRadioActive={{
                            border: '1px solid rgba(0, 229, 166, 1)',
                            backgroundColor: 'rgba(0, 229, 166, 0.05)',
                          }}
                          tag={false}
                          title={t('orders.newOrder.pickUp.radioOneTitle')}
                          text={t('orders.newOrder.pickUp.radioOneText')}
                          value
                        />
                        <CheckCards
                          isPack={isPickUp}
                          radioDefault={radioDefault}
                          inputRadioActive={{
                            border: '1px solid rgba(0, 229, 166, 1)',
                            backgroundColor: 'rgba(0, 229, 166, 0.05)',
                          }}
                          tag={false}
                          title={t('orders.newOrder.pickUp.radioTwoTitle')}
                          text={t('orders.newOrder.pickUp.radioTwoText')}
                          value={false}
                        />
                      </Radio.Group>
                      {isPickUp !== undefined && (
                        <Card style={{ borderRadius: 4 }}>
                          {isPickUp !== undefined && isPickUp && (
                            <FedexPickup
                              ref={fedexPickupRef}
                              restockOrderId={order?.id}
                              warehouseId={order?.warehouse?.id}
                              courierCredentialId={order?.courier}
                              callback={pickupCallback}
                              showConfirmButton={false}
                              formRef={formRef}
                            />
                          )}
                          {isPickUp !== undefined && !isPickUp && (
                            <Row gutter={[32, 32]}>
                              <Col>
                                <Form
                                  layout="vertical"
                                  form={deliveryOfficeForm}
                                  onFinish={onFinishDelivery}
                                >
                                  <Form.Item
                                    label={
                                      <div>
                                        {t(
                                          'orders.state.pickUp.officeDelivery'
                                        )}
                                        :{' '}
                                        <Tooltip
                                          title={t(
                                            'orders.state.pickUp.referenceDate'
                                          )}
                                        >
                                          <InfoCircleOutlined />
                                          &nbsp;
                                        </Tooltip>
                                      </div>
                                    }
                                    name="officeDeliveryDate"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Ingrese la fecha de entrega.',
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      className="datePickerStyle"
                                      placeholder="YYYY-MM-DD"
                                      disabledDate={disabledPreviousDate}
                                      onChange={(value) =>
                                        setDeliveryOfficeDate(value)
                                      }
                                      style={{ width: '100%' }}
                                    />
                                  </Form.Item>
                                </Form>
                              </Col>
                            </Row>
                          )}
                        </Card>
                      )}
                    </>
                  )
                }
              />
            )}
        </Steps>
      </Row>
      {order && (
        <StickyAlert
          handleCancelForm={handleCancelForm}
          handleSaveDraft={() => {}}
          sendContent={handleConfirmShipmentsInShipmentOptions}
          firstDisabled={
            order?.state !== orderGeneralStates.DRAFT &&
            order?.state !== orderGeneralStates.WORKING
          }
          secondDisabled={order?.state !== orderGeneralStates.WORKING}
          thirdDisabled
          forthDisabled={!radioDefault && shipsmartData?.length === 0}
        />
      )}
    </Spin>
  );
}

ShipmentOptions.propTypes = {
  orderId: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  setSteps: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
export default ShipmentOptions;

export const updateInboundShipmentErrors = {
  default: {
    text: 'amazonError.updateInboundShipment.default.text',
  },
};

export const putTransportDetailsErrors = {
  default: {
    text: 'amazonError.putTransportDetails.default.text',
  },
};

export const estimateTransportErrors = {
  default: {
    text: 'amazonError.estimateTransport.default.text',
  },
};

export const submitFBAInboundCartonContentErrors = {
  default: {
    text: 'amazonError.submitFBAInboundCartonContent.default.text',
  },
};

export const createInboundShipmentErrors = {
  'Error: You must include a valid ShipmentId with a call to the CreateInboundShipment operation. Get ShipmentId values by calling the CreateInboundShipmentPlan operation. The request to CreateInboundShipment must include only items and quantities that have been previously planned through CreateInboundShipmentPlan. If a ShipmentId is not used to create a shipment within 48 hours it will expire.':
    {
      text: 'amazonError.updateInboundShipment.expired.text',
    },
  ' Reason: Invalid Request - Seller is over volume capacity limit for StorageType':
    {
      text: 'amazonError.createInboundShipment.fullStorage.text',
    },
  default: {
    text: 'amazonError.createInboundShipment.default.text',
  },
};

export const defaultAmazonErrors = {
  default: {
    text: 'amazonError.default.text',
  },
};

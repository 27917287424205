import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Col,
  Collapse,
  Card,
  Image,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { DownOutlined, PictureOutlined, UpOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import EditWarehouseModal from '../../../../shipments/components/EditWarehouseModal';

function ReviewDetailContent({
  products,
  selectedCourier,
  name,
  warehouse,
  destination,
  shipmentType,
  isNational,
  boxType,
  cargoType,
}) {
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = useState(false);
  const [extraDetailInfo, setExtraDetailInfo] = useState(false);
  const { Panel } = Collapse;

  const expandIcon = ({ isActive }) =>
    isActive ? (
      <DownOutlined className="icon-title" />
    ) : (
      <UpOutlined className="icon-title" />
    );

  const renderProduct = (product) => {
    return (
      <Row align="middle" justify="start" style={{ padding: '16px' }}>
        <Col span={24}>
          <Space>
            {product.product?.productTemplate?.mainImage ? (
              <Avatar
                shape="square"
                className="avatar-picture"
                src={
                  <Image
                    src={`${product.product?.productTemplate?.mainImage}`}
                  />
                }
              />
            ) : (
              <Avatar
                shape="square"
                className="avatar-picture"
                icon={<PictureOutlined />}
              />
            )}
            <Row>
              <Col span={24}>
                <Tooltip placement="topLeft" title={product.product?.title}>
                  <Typography.Text className="title-product-contents-table">
                    {product.product?.title}
                  </Typography.Text>
                </Tooltip>
              </Col>
              <Col span={24} className="SKU-product-contents-table">
                <Tooltip title={product.product?.defaultCode}>
                  SKU: {product.product?.defaultCode}
                </Tooltip>
              </Col>
            </Row>
          </Space>
        </Col>
      </Row>
    );
  };

  return (
    <Collapse
      className="revision-restock"
      expandIcon={expandIcon}
      defaultActiveKey={['detailAndContent']}
    >
      <Panel
        key="detailAndContent"
        header={
          <Typography.Text style={{ fontSize: 18, fontWeight: 500 }}>
            {t('orders.newOrder.contents.title')}
          </Typography.Text>
        }
      >
        <Row gutter={[16, 16]} style={{ backgroundColor: '#FAFAFA' }}>
          <Col span={24}>
            <Card
              size="small"
              title={t('orders.shipment.summary.shipmentInformation')}
              style={{ borderRadius: 4 }}
            >
              <Row gutter={[16, 16]}>
                <Col
                  span={8}
                  style={{
                    borderRight: '1px solid #E5E5E5',
                  }}
                >
                  <Row>
                    <Col span={24} style={{ color: '#646464' }}>
                      {t('common.name')}
                    </Col>
                    <Col span={24} style={{ color: '#20303C' }}>
                      {name}
                    </Col>
                  </Row>
                </Col>
                <Col span={16} style={{ paddingLeft: 20 }}>
                  <Row style={{ marginBottom: 28 }}>
                    <Col span={24}>
                      <Row style={{ color: '#646464' }}>
                        <Col span={8}>{t('common.origin')}</Col>
                        <Col span={8}>{t('common.destination')}</Col>
                        <Col span={8}>
                          {t('orders.shipment.summary.shipmentType')}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row style={{ color: '#20303C' }}>
                        <Col span={8}>
                          {warehouse?.name ? warehouse?.name : '-'}
                          <Row>
                            <Button
                              type="link"
                              style={{ color: '#1677FF', paddingLeft: 0 }}
                              onClick={() => setShowEditModal(true)}
                              hidden={!warehouse?.name}
                            >
                              {t('orders.shipment.summary.seeAddress')}
                            </Button>
                          </Row>
                        </Col>
                        <Col span={8}>{destination}</Col>
                        <Col span={8}>{shipmentType}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Row style={{ color: '#646464' }}>
                        <Col span={8}>Courier</Col>
                        <Col span={8} hidden={!boxType}>
                          {t('orders.shipment.summary.boxType')}
                        </Col>
                        <Col span={8} hidden={!cargoType}>
                          {t('orders.shipment.summary.cargoType')}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row style={{ color: '#20303C' }}>
                        <Col span={8}>
                          {`${selectedCourier?.name || '-'}${
                            selectedCourier?.name === 'Fedex'
                              ? ` - ${selectedCourier.accountNumber}`
                              : ''
                          }`}
                        </Col>
                        <Col span={8} hidden={!boxType}>
                          {boxType}
                        </Col>
                        <Col span={8} hidden={!cargoType}>
                          {cargoType}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          {products && (
            <Col span={24}>
              <Card
                size="small"
                title={t('common.product', { count: 2 })}
                style={{ borderRadius: 4, borderBottom: '1px solid #E5E5E5' }}
              >
                {products?.map((product) => (
                  <Row gutter={[16, 16]}>
                    <Col
                      span={8}
                      style={{
                        borderRight: '1px solid #E5E5E5',
                      }}
                    >
                      {renderProduct(product)}
                    </Col>
                    <Col span={16}>
                      <Row style={{ marginBottom: 15 }}>
                        <Col span={24}>
                          <Row style={{ color: '#646464' }}>
                            {product.unitsPerPack &&
                              product.unitsPerPack > 0 && (
                                <Col span={8}>
                                  {t('orders.shipment.summary.numberOfBoxes')}
                                </Col>
                              )}
                            <Col span={8}>{t('common.units')}</Col>
                            <Col span={8}>{t('common.expirationDate')}</Col>
                          </Row>
                        </Col>
                        <Col span={24} style={{ marginBottom: 28 }}>
                          <Row style={{ color: '#20303C' }}>
                            {product.unitsPerPack &&
                              product.unitsPerPack > 0 && (
                                <Col span={8}>
                                  {product.quantity / product.unitsPerPack} 📦
                                </Col>
                              )}
                            <Col span={8}>
                              {product.unitsPerPack
                                ? `${product.quantity} (${
                                    product.unitsPerPack
                                  } ${t('orders.shipment.summary.perBox')})`
                                : product.quantity}
                            </Col>
                            <Col span={8}>
                              {product.expirationDate
                                ? product.expirationDate
                                : '-'}
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24} hidden={extraDetailInfo || isNational}>
                          <Row>
                            <Button
                              type="link"
                              style={{ color: '#1677FF', paddingLeft: 0 }}
                              onClick={() => setExtraDetailInfo(true)}
                              className="icon-download-color"
                            >
                              {t('orders.shipment.summary.seeAllInformation')}
                              <DownOutlined />
                            </Button>
                          </Row>
                        </Col>
                        <Col span={24} hidden={!extraDetailInfo}>
                          <Row style={{ color: '#646464' }}>
                            <Col span={8}>{t('common.unitaryFob')}</Col>
                            <Col span={8}>
                              {t('common.countryOfManufacture')}
                            </Col>
                            <Col span={8}>{t('common.harmonizedCode')}</Col>
                          </Row>
                        </Col>
                        <Col span={24} hidden={!extraDetailInfo}>
                          <Row style={{ color: '#20303C' }}>
                            <Col span={8}>
                              {product?.product?.productTemplate.unitFOB}
                            </Col>
                            <Col span={8}>
                              {
                                product?.product?.productTemplate
                                  .countryOfManufacture
                              }
                            </Col>
                            <Col span={8}>
                              {product?.product?.productTemplate.harmonizedCode}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))}
              </Card>
            </Col>
          )}
        </Row>
      </Panel>
      <EditWarehouseModal
        isModalOpen={showEditModal}
        setIsModalOpen={setShowEditModal}
        warehouseId={warehouse?.id}
        fieldsDisabled
      />
    </Collapse>
  );
}

ReviewDetailContent.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({
        defaultCode: PropTypes.string,
        title: PropTypes.string,
        productTemplate: PropTypes.shape({
          mainImage: PropTypes.string,
          unitFOB: PropTypes.number,
          countryOfManufacture: PropTypes.string,
          harmonizedCode: PropTypes.string,
        }),
        unitsPerPack: PropTypes.number,
        quantity: PropTypes.number,
        expirationDate: PropTypes.string,
      }),
    })
  ),
  selectedCourier: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  warehouse: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  destination: PropTypes.string.isRequired,
  shipmentType: PropTypes.string.isRequired,
  isNational: PropTypes.bool,
  boxType: PropTypes.string,
  cargoType: PropTypes.string,
};

ReviewDetailContent.defaultProps = {
  products: null,
  isNational: false,
  boxType: null,
  cargoType: null,
};

export default ReviewDetailContent;

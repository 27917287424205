import api from './api';

const prefix = '/paymentPlan';
const paymentPlanAPI = {
  get(id) {
    return api.get(`${prefix}/${id}`);
  },
  find(params) {
    return api.get(`${prefix}/find`, { params });
  },
  createStripeCheckoutSession(payload) {
    return api.post(`stripe/checkout/sessions`, payload);
  },
};
export default paymentPlanAPI;

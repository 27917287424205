import React, { useState } from 'react';
import { AutoComplete, Image } from 'antd';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';
import { useTranslation } from 'react-i18next';
import openNotification from '../../components/Toastr';
import { getErrorMessage } from '../../api/api';
import googleLogo from '../../assets/google_on_white.png';
import feesAPI from '../../api/fees';

const usePlacesInput = (
  form,
  setSelectedCountryCode,
  stateOrProvinces,
  fieldDisabled = false
) => {
  const { t } = useTranslation();
  const { google } = window;
  const [sessionToken, setSessionToken] = useState(
    new google.maps.places.AutocompleteSessionToken()
  );

  const findAddressComponent = (place, param) => {
    return place?.address_components?.find((ac) => ac.types.includes(param))
      ?.short_name;
  };

  const {
    value: address,
    suggestions,
    setValue: setAddress,
  } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions: { sessionToken, types: ['address'] },
  });

  const getAddressComponent = async (latLng, component) => {
    try {
      const value = await feesAPI
        .getAddressComponent(latLng, component)
        .then((result) => result.data);
      return value ? { [component]: String(value) } : null;
    } catch (error) {
      return null;
    }
  };

  const handlePlaceSelected = async (value, option) => {
    try {
      const getDetailsArgs = {
        placeId: option.key,
        fields: ['address_components', 'formatted_address', 'geometry'],
        sessionToken,
      };
      const place = await getDetails(getDetailsArgs).catch(() => {
        throw new Error(t('addressErrors.default'));
      });
      let postalCode = findAddressComponent(place, 'postal_code');
      if (!postalCode) {
        const latLng = `${place.geometry.location.lat()},${place.geometry.location.lng()}`;
        postalCode = (await getAddressComponent(latLng, 'postalCode'))
          ?.postalCode;
        if (!postalCode) {
          openNotification({
            status: false,
            content: t('addressErrors.postalCode'),
          });
        }
      }
      const locality = findAddressComponent(place, 'locality');
      const sublocality = findAddressComponent(place, 'sublocality');
      const adminAreaLevel1 = findAddressComponent(
        place,
        'administrative_area_level_1'
      );
      const countryCode = findAddressComponent(place, 'country');
      const stateOrProvinceCode = !!stateOrProvinces.find(
        (row) => row.countryCode === countryCode
      );
      const cityValue = locality || sublocality;
      setSelectedCountryCode(countryCode);
      form.setFieldsValue({
        countryCode,
        city: cityValue,
        ...(stateOrProvinceCode && { stateOrProvinceCode: adminAreaLevel1 }),
        streetName: place.formatted_address,
        ...(postalCode && { postalCode }),
      });
      setSessionToken(new google.maps.places.AutocompleteSessionToken());
    } catch (error) {
      openNotification({
        status: false,
        content: getErrorMessage(error),
      });
    }
  };

  const handleInputChange = (value) => {
    setAddress(value);
  };

  const renderOptions = () => {
    return suggestions.data.map((option) => (
      <AutoComplete.Option key={option.place_id} value={option.description}>
        {option.label}
      </AutoComplete.Option>
    ));
  };

  return (
    <AutoComplete
      value={address}
      onChange={handleInputChange}
      onSelect={(value, option) => handlePlaceSelected(value, option)}
      placeholder={t('warehouses.createForm.address.placeholderStreetName')}
      disabled={fieldDisabled}
    >
      {renderOptions()}
      <AutoComplete.Option
        key="poweredByGoogle"
        disabled
        style={{ textAlign: 'right' }}
      >
        <Image src={googleLogo} alt="poweredByGoogle" preview={false} />
      </AutoComplete.Option>
    </AutoComplete>
  );
};

export default usePlacesInput;

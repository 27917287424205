import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import FedexLapTab from '../FedexLapTab';
import FedexOwnerTab from '../FedexOwnerTab';

const fedexForms = (loading, name, onFinish, refresh, courierCredentials) => {
  return [
    {
      label: 'Convenio con LAP',
      component: (
        <FedexLapTab
          loading={loading}
          name={name}
          onFinish={onFinish}
          refresh={refresh}
          courierCredentials={courierCredentials}
        />
      ),
    },
    {
      label: 'Cuenta propia',
      component: (
        <FedexOwnerTab
          loading={loading}
          name={name}
          onFinish={onFinish}
          refresh={refresh}
          courierCredentials={courierCredentials}
        />
      ),
    },
  ];
};

function FedexForm({ courierCredentials, loading, name, onFinish }) {
  const [refresh, doRefresh] = useState(false);

  const onChange = () => {
    doRefresh(!refresh);
  };

  return (
    <>
      <h1
        style={{
          fontWeight: 600,
          marginBottom: 25,
          marginTop: 15,
        }}
      >
        Conectar cuenta FedEx Cross Border
      </h1>

      <Tabs
        type="card"
        onChange={onChange}
        items={fedexForms(
          loading,
          name,
          onFinish,
          refresh,
          courierCredentials
        ).map((component, i) => {
          const id = String(i + 1);

          return {
            label: component.label,
            key: id,
            children: component.component,
          };
        })}
      />
    </>
  );
}

FedexForm.propTypes = {
  courierCredentials: PropTypes.objectOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default FedexForm;

import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Collapse, Divider, Row, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import EditPickUpModal from './Pickup/EditPickUpModal';
import FBMCouriers from '../../../../../utils/FBMCouriers';

function ReviewShipment({
  selectedCourier,
  courierShipments,
  order,
  getOrder,
  boxes,
  warehouse,
}) {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const [firstCourierShipment, setFirstCourierShipment] = useState();
  const [totalRate, setTotalRate] = useState(0);
  const [currency, setCurrency] = useState('');
  const [courierShipmentIdDictionary, setCourierShipmentIdDictionary] =
    useState({});
  const [openEditPickUpModal, setOpenEditPickUpModal] = useState(false);
  const pickupStates = {
    Completed: t('shipments.pickup.state.completed'),
    Failed: t('shipments.pickup.state.failed'),
    Pending: t('shipments.pickup.state.pending'),
  };

  const language = useSelector((store) => store.Session.language);

  useEffect(() => {
    if (courierShipments && courierShipments.length > 0) {
      setFirstCourierShipment(courierShipments[0]);
      if (courierShipments[0].rate)
        setCurrency(courierShipments[0].rate.match(/[a-zA-Z]+/g)[0]);
      const rateSum = courierShipments.reduce(
        (sum, courierShipment) =>
          sum +
          (courierShipment.rate
            ? Number(courierShipment.rate.match(/\d+/g)[0])
            : 0),
        0
      );
      setTotalRate(rateSum);
    }
  }, [courierShipments]);

  // Only Restock Order
  useEffect(() => {
    if (order && Object.keys(order).length > 0) {
      const dictonary = {};
      order.shipments.map((shipment) => {
        dictonary[shipment.courierShipmentId] = shipment.shipmentPlanId;
        return shipment;
      });
      setCourierShipmentIdDictionary(dictonary);
    }
  }, [order]);

  const expandIcon = ({ isActive }) =>
    isActive ? (
      <DownOutlined className="icon-title" />
    ) : (
      <UpOutlined className="icon-title" />
    );
  return (
    <Collapse
      className="revision-restock"
      expandIcon={expandIcon}
      defaultActiveKey={['detailAndContent']}
    >
      <Panel
        key="detailAndContent"
        header={
          <Typography.Text style={{ fontSize: 18, fontWeight: 500 }}>
            {t('orders.newOrder.state.title')}
          </Typography.Text>
        }
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Card
              size="small"
              title={t('orders.shipment.summary.courierInformation')}
              style={{ borderRadius: 4 }}
            >
              <Row style={{ marginBottom: 15 }}>
                <Col span={24}>
                  <Row style={{ color: '#646464' }}>
                    <Col span={8}>Courier</Col>
                    <Col
                      span={8}
                      hidden={
                        !firstCourierShipment?.courierResponse?.serviceName
                      }
                    >
                      {t('orders.shipment.summary.serviceType')}
                    </Col>
                    <Col span={8} hidden>
                      {t('orders.shipment.summary.deliveryDate')}
                    </Col>
                  </Row>
                </Col>
                <Col span={24} style={{ marginBottom: 28 }}>
                  <Row style={{ color: '#20303C' }}>
                    <Col span={8}>
                      {firstCourierShipment?.courierCompanyName}
                    </Col>
                    <Col
                      span={8}
                      hidden={
                        !firstCourierShipment?.courierResponse?.serviceName
                      }
                    >
                      {firstCourierShipment?.courierResponse?.serviceName}
                    </Col>
                    <Col span={8} hidden>
                      {`${selectedCourier?.name === 'Fedex' ? '-' : '-'}`}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row style={{ color: '#646464' }}>
                    <Col span={8}>
                      {t('orders.shipment.summary.pickUpMode')}
                    </Col>
                    <Col span={8} hidden={!firstCourierShipment?.pickup}>
                      {t('orders.shipment.summary.pickUpDate')}
                    </Col>
                    <Col span={8} hidden={!firstCourierShipment?.pickup}>
                      {t('orders.shipment.summary.pickUpTime')}
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row style={{ color: '#20303C' }}>
                    <Col span={8}>
                      {!order?.officeDeliveryDate ||
                      firstCourierShipment?.courierCompanyName ===
                        FBMCouriers.INT99MINUTOS
                        ? 'Pick up'
                        : t('common.dropOff')}
                    </Col>
                    {firstCourierShipment?.pickup && (
                      <>
                        <Col span={8}>
                          {new Date(
                            firstCourierShipment?.pickup?.pickupDate
                          ).toLocaleDateString(language, {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            timeZone: 'UTC',
                          })}
                        </Col>
                        <Col span={8}>
                          {firstCourierShipment?.pickup?.pickupTime}
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
                {firstCourierShipment?.pickup && (
                  <Col span={24}>
                    <Row justify="end">
                      <Col span={8} style={{ color: '#0F62FE' }}>
                        <Button
                          type="link"
                          onClick={() => {
                            setOpenEditPickUpModal(true);
                          }}
                          style={{ color: '#1677FF', paddingLeft: 0 }}
                          className="icon-download-color"
                        >
                          {t('orders.shipment.summary.editPickUp')}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}
                {firstCourierShipment?.courierCompanyName === 'Fedex' &&
                  firstCourierShipment?.requestedShipment?.pickupType ===
                    'USE_SCHEDULED_PICKUP' &&
                  !firstCourierShipment?.pickup && (
                    <Col span={24}>
                      <Row justify="end">
                        <Col span={8} style={{ color: '#0F62FE' }}>
                          <Typography.Text>
                            {t('orders.shipment.summary.noPickUpScheduled')}
                          </Typography.Text>
                          <Row>
                            <Button
                              type="link"
                              onClick={() => {
                                setOpenEditPickUpModal(true);
                              }}
                              style={{ color: '#1677FF', paddingLeft: 0 }}
                              className="icon-download-color"
                            >
                              {t('orders.shipment.summary.schedulePickUp')}
                            </Button>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                <Col span={24}>
                  <Row>
                    <Col span={8}>
                      <Typography.Text style={{ color: '#646464' }}>
                        {t('externalShipment.pickUp.pickupState')}
                      </Typography.Text>
                      <Row>
                        <Typography.Text>
                          {firstCourierShipment?.pickup?.state
                            ? pickupStates[firstCourierShipment?.pickup?.state]
                            : t('shipments.pickup.noPickup')}
                        </Typography.Text>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24}>
            <Card
              size="small"
              title={t('orders.shipment.summary.shipmentCost')}
              style={{ borderRadius: 4 }}
            >
              {courierShipments?.map((courierShipment, index) => (
                <Row style={{ marginBottom: 15 }}>
                  <Col span={24}>
                    <Row justify="space-between">
                      <Col span={12}>
                        <Typography.Text
                          style={{
                            color: '#20303C',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 500,
                            }}
                          >{`${t('common.destination')} N° ${
                            index + 1
                          }:`}</span>
                          <span
                            style={{
                              fontWeight: 400,
                            }}
                          >
                            {` ${
                              Object.keys(courierShipmentIdDictionary).length >
                              0
                                ? courierShipmentIdDictionary[
                                    courierShipment.id
                                  ]
                                : courierShipment.id
                            }`}
                          </span>
                        </Typography.Text>
                      </Col>
                      {courierShipment.rate && currency && (
                        <Col>
                          <Typography.Text>
                            {new Intl.NumberFormat('es-CL', {
                              style: 'currency',
                              currency,
                            }).format(courierShipment.rate.match(/\d+/g)[0])}
                          </Typography.Text>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  {order && order.restockOrderBoxes && (
                    <Col span={24}>
                      {`${t('common.boxes')}: ${order.restockOrderBoxes
                        .filter(
                          (restockOrderBox) =>
                            restockOrderBox.shipmentId ===
                            courierShipmentIdDictionary[courierShipment.id]
                        )
                        .reduce(
                          (sum, restockOrderBox) =>
                            sum + restockOrderBox.shippingAmount,
                          0
                        )}`}
                    </Col>
                  )}
                  {boxes && (
                    <Col span={24}>
                      {`${t('common.boxes')}: ${
                        boxes.filter((box) => box.box.isPallet === false).length
                      }`}
                      {boxes.filter((box) => box.box.isPallet === true).length >
                        0 && (
                        <>
                          <Divider type="vertical" />
                          {`Pallets: ${
                            boxes.filter((box) => box.box.isPallet === true)
                              .length
                          }`}
                        </>
                      )}
                    </Col>
                  )}
                </Row>
              ))}
              <Divider />
              <Row style={{ marginBottom: 15 }}>
                <Col span={24}>
                  <Row
                    style={{
                      fontWeight: 500,
                    }}
                    justify="space-between"
                  >
                    <Col span={12}>Total</Col>
                    {currency && totalRate && (
                      <Col id="second-column-shipment-cost">
                        <Typography.Text>
                          {new Intl.NumberFormat('es-CL', {
                            style: 'currency',
                            currency,
                          }).format(totalRate)}
                        </Typography.Text>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col span={24}>
                  {order &&
                    order.restockOrderBoxes &&
                    Object.keys(order).length > 0 && (
                      <>
                        {`${t('common.boxes')} ${order.restockOrderBoxes.reduce(
                          (sum, restockOrderBox) =>
                            sum + restockOrderBox.shippingAmount,
                          0
                        )}`}
                        <Divider type="vertical" />
                      </>
                    )}
                  {boxes && (
                    <>
                      {`${t('common.boxes')}: ${
                        boxes.filter((box) => box.box.isPallet === false).length
                      }`}
                      {boxes.filter((box) => box.box.isPallet === true).length >
                        0 && (
                        <>
                          <Divider type="vertical" />
                          {`Pallets: ${
                            boxes.filter((box) => box.box.isPallet === true)
                              .length
                          }`}
                        </>
                      )}
                      <Divider type="vertical" />
                    </>
                  )}
                  {`${t('common.destination')}: ${
                    courierShipments && courierShipments.length
                  }`}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {openEditPickUpModal && (
          <EditPickUpModal
            isOpen={openEditPickUpModal}
            setIsOpen={setOpenEditPickUpModal}
            courierShipment={firstCourierShipment}
            courierCredentialId={selectedCourier?.key}
            getOrder={getOrder}
            warehouseId={order?.warehouse?.id || warehouse.id}
          />
        )}
      </Panel>
    </Collapse>
  );
}

ReviewShipment.propTypes = {
  selectedCourier: PropTypes.string.isRequired,
  courierShipments: PropTypes.arrayOf(
    PropTypes.shape({
      rate: PropTypes.string,
      courierCompanyName: PropTypes.string,
      pickup: PropTypes.shape({
        pickupDate: PropTypes.string,
        pickupTime: PropTypes.string,
      }),
      id: PropTypes.string,
    })
  ).isRequired,
  order: PropTypes.shape({
    officeDeliveryDate: PropTypes.instanceOf(Date),
    warehouse: PropTypes.shape({
      id: PropTypes.number,
    }),
    courier: PropTypes.string,
    shipments: PropTypes.arrayOf(
      PropTypes.shape({
        courierShipmentId: PropTypes.string,
        shipmentPlanId: PropTypes.string,
      })
    ),
    restockOrderBoxes: PropTypes.arrayOf(
      PropTypes.shape({
        shipmentId: PropTypes.string,
        shippingAmount: PropTypes.number,
      })
    ),
  }),
  getOrder: PropTypes.func.isRequired,
  warehouse: PropTypes.shape({
    id: PropTypes.number,
  }),
  boxes: PropTypes.arrayOf(
    PropTypes.shape({
      box: PropTypes.shape({
        isPallet: PropTypes.bool,
      }),
    })
  ),
};

ReviewShipment.defaultProps = {
  boxes: null,
  order: null,
  warehouse: null,
};

export default ReviewShipment;

import { Col, Row, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PriorNotifyPng from './icons/PriorNotifyPng.svg';
import RegulationAvatar from './components/RegulationAvatar';
import documentAPI from '../../api/document';
import PriorNotifyForm from './components/prior-notice/PriorNotifyForm';

function Regulations() {
  const { t } = useTranslation();
  const { Title } = Typography;
  const [dataSource, setDataSource] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);

  const loadTable = async () => {
    setLoadingTable(true);
    const response = await documentAPI.getRegulationsCredentials();
    const data = response.data.results.map((value) => ({
      key: value.id,
      name: value.regulationCompanyName,
      accountNumber: value.accountNumber,
    }));
    setDataSource(data);
    setLoadingTable(false);
  };

  useEffect(() => {
    loadTable();
  }, []);

  if (loadingTable) {
    return <Spin />;
  }

  return (
    <div className="initialContent">
      <Row>
        <Title level={3}>{t('regulations.documents')}</Title>
      </Row>

      <Row justify="space-between" style={{ marginTop: '25px' }}>
        <Col xs={24} md={24} lg={11}>
          <RegulationAvatar
            imgHeight={90}
            icon={PriorNotifyPng}
            name="Prior Notify"
            label="Prior Notify"
            formContent={PriorNotifyForm}
            loadTable={loadTable}
            regulationsCredentials={dataSource}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Regulations;

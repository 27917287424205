import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, Typography, Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { UpOutlined, DownOutlined, CloseOutlined } from '@ant-design/icons';
import Joyride from 'react-joyride';
import { useHistory, useLocation } from 'react-router-dom';
import DashboardFilter from './components/DashboardFilter';
import { dateTypes, sellerMarketplaces } from '../../utils/const';
import * as Actions from '../../redux/partner/action';
import actionsAnalitycs from '../../redux/analytic/dashboard/actions';
import dashboardApi from '../../api/dashboard';
import partnerApi from '../../api/partner';
import companiesAPI from '../../api/company';
import { getErrorMessage } from '../../api/api';
import IndexChart from './components/charts/Index';
import openNotification from '../../components/Toastr';
import 'antd/dist/antd.min.css';
import './home.css';
import SubscriptionConfirmationModal from '../plans/components/SubscriptionConfirmationModal';
import OnboardingButton from './components/onboardingButton';
import { updateOnboarding } from '../../redux/onboarding/action';
import {
  stage5Home,
  stage3NotAvailable,
  stage3Home,
  stage2Home,
  stage1Home,
  newCompanyTourSteps,
} from '../../stepsOnboarding';
import { updateUserInfoNewCompany } from '../../redux/session/action';
import { convertToISOString } from '../../utils/functions';

const { Title } = Typography;

function Home() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const session = useSelector((store) => store.Session.session);
  const onboardingStatus = useSelector((store) => store.Onboarding);
  const [listMarketplaceAvailableClient, setListMarketplaceAvailableClient] =
    useState([]);

  const [salesTotal, setSalesTotal] = useState(0);
  const [pendingSalesTotal, setPendingSalesTotal] = useState(0);
  const [grossSalesTotal, setGrossSalesTotal] = useState(0);
  const [soldUnits, setSoldUnits] = useState(0);
  const [dataSalesSummary, setDataSalesSummary] = useState([]);
  const [dataTopFiveSalesTable, setDataTopFiveSalesTable] = useState([]);
  const [pendingOrders, setPendingOrders] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);

  const [salesTotalLoading, setSalesTotalLoading] = useState(false);
  const [soldUnitsLoading, setSoldUnitsLoading] = useState(false);
  const [dataSalesSummaryLoading, setDataSalesSummaryLoading] = useState(false);
  const [dataTopFiveSalesTableLoading, setDataTopFiveSalesTableLoading] =
    useState(false);
  const [pendingOrdersLoading, setPendingOrdersLoading] = useState(false);
  const [totalOrdersLoading, setTotalOrdersLoading] = useState(false);

  const [listSkus, setListSkus] = useState([]);

  const [onboardingSteps, setOnboardingSteps] = useState({
    has_markets: 0,
    has_couriers: 0,
    has_publications: 0,
    has_shipments: 0,
    has_advertising: 0,
    has_sales: 0,
  });
  const [showOnboardingSteps, setShowOnboardingSteps] = useState(true);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const [startNewCompanyTour, setStartNewCompanyTour] = useState(false);

  const handleOnboardingCourierClick = () => {
    dispatch(updateOnboarding('Courier', 0));
  };

  const handleOnboardingMarketplaceClick = () => {
    dispatch(updateOnboarding('Marketplace', 0));
  };

  const handleOnboardingFirstPostClick = () => {
    if (onboardingSteps.has_markets) {
      dispatch(updateOnboarding('FirstPost', 0));
    } else {
      dispatch(updateOnboarding('FirstPost', 'NOT'));
    }
  };

  const handleOnboardingDashboardClick = () => {
    dispatch(updateOnboarding('Dashboard', 0));
  };

  const handleCancel = () => {
    setShowWarningModal(false);
  };

  const handleCloseOnboardingModal = () => {
    setShowWarningModal(true);
  };

  const toggleOnboardingSteps = () => {
    setShowOnboardingSteps(!showOnboardingSteps);
  };

  const closeOnboardingModal = () => {
    setShowOnboardingModal(!showOnboardingModal);
    setShowWarningModal(false);
    companiesAPI.update(session.userInfo.commercial_partner_id[0], {
      isOnboardingModalActive: false,
    });
  };

  const [
    subscriptionConfirmationModalVisible,
    setSubscriptionConfirmationModalVisible,
  ] = useState(false);
  let partnerId = 1; // admin
  const { isAdmin } = session.userInfo;
  if (!isAdmin) {
    [partnerId] = session.userInfo.commercial_partner_id;
  }

  const { search } = useLocation();
  const searchParams = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );
  const linkToSubscriptionId = searchParams?.get('subscriptionId');
  const stripeCheckoutSessionId = searchParams?.get('session_id');

  const filterArrayToString = (params) => {
    const object = {
      ...params,
      dashboard: 'summary',
      currency: localStorage.getItem('defaultCurrency'),
    };
    return object;
  };

  const getSkusperMarketplace = async (param, rangeOne, partner, period) => {
    const dateRangeOne =
      rangeOne?.length > 0
        ? convertToISOString(rangeOne)
        : convertToISOString([moment().add(-1, 'M'), moment()]);
    let ptrs = [];
    if (!isAdmin) {
      ptrs = [partnerId];
    } else {
      ptrs = [partner];
    }
    const defaultFilters = {
      dashboard: 'summary',
      partner: ptrs,
      marketplace: param && param.length > 0 ? param : 'Amazon',
      category: ['sales', 'stock'],
      compare: false,
      dateType: dateTypes.PURCHASE_DATE,
      dateRangeOne,
      dateRangeTwo: [],
      fulfillment: '',
      period: period || 'currentMonth',
      currency: localStorage.getItem('defaultCurrency'),
    };

    await dashboardApi
      .getSkusPerMarketplace(defaultFilters)
      .then((response) => {
        if (response?.data && response?.data.length > 0) {
          const skuAvailable = response?.data.map((e) => {
            return {
              value: e.sku,
              label: e.sku,
            };
          });
          skuAvailable.splice(0, 0, {
            value: '',
            label: 'All',
          });
          dispatch(
            actionsAnalitycs.setFilter({
              ...defaultFilters,
              listSkus: skuAvailable,
            })
          );
          setListSkus(skuAvailable);
        } else {
          setListSkus([]);
        }
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getMarketplacesByOrders = async (defaultFilters) => {
    await dashboardApi
      .getMarketplacesPerClient(defaultFilters)
      .then((response) => {
        const marketAvailable = [];
        if (response?.data?.length > 0) {
          response.data.forEach((mrk) => {
            if (mrk.name === 'Amazon') {
              marketAvailable.unshift(mrk.name);
            } else {
              marketAvailable.push(mrk.name);
            }
          });

          dispatch(
            actionsAnalitycs.setFilter({
              ...defaultFilters,
              dateRangeOne: [moment().startOf('month'), moment()],
              marketplace: marketAvailable,
              currency: localStorage.getItem('defaultCurrency'),
            })
          );
        }
        setListMarketplaceAvailableClient(marketAvailable);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getMarketplacesCredencials = async () => {
    let ptrs = [];
    if (!isAdmin) {
      ptrs = [partnerId];
    }
    const defaultFilters = {
      dashboard: 'summary',
      partner: ptrs,
      sku: [],
      marketplace: Object.values(sellerMarketplaces),
      category: ['sales', 'stock'],
      compare: false,
      dateType: dateTypes.PURCHASE_DATE,
      dateRangeOne: convertToISOString([moment().add(-12, 'M'), moment()]),
      dateRangeTwo: [],
      period: 'currentMonth',
      fulfillment: '',
      currency: localStorage.getItem('defaultCurrency'),
    };

    await partnerApi
      .getMarketplacesAllowed(
        session?.userInfo?.commercial_partner_id[0],
        'Seller'
      )
      .then((response) => {
        if (response?.data?.length > 0) {
          const marketAvailable = [];
          response.data.forEach((mrk) => {
            if (mrk === 'Amazon') {
              marketAvailable.unshift(mrk);
            } else {
              marketAvailable.push(mrk);
            }
          });

          dispatch(
            actionsAnalitycs.setFilter({
              ...defaultFilters,
              dateRangeOne: [moment().startOf('month'), moment()],
              marketplace: marketAvailable,
              currency: localStorage.getItem('defaultCurrency'),
            })
          );
          setListMarketplaceAvailableClient(marketAvailable);
        } else {
          getMarketplacesByOrders(defaultFilters);
        }
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  useEffect(() => {
    getMarketplacesCredencials();
  }, []);

  const getOnboardingSteps = async (partner) => {
    await dashboardApi
      .getOnboardingStepsPerClient(partner)
      .then((response) => {
        if (response?.data) {
          setShowOnboardingModal(true);
          setOnboardingSteps(response?.data);
        }
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getTotalOrders = async (valuesFilter) => {
    setTotalOrdersLoading(true);
    const filter = filterArrayToString(valuesFilter);
    await dashboardApi
      .totalOrders(filter)
      .then((response) => {
        if (response?.data > 0) {
          setTotalOrders(response?.data);
        } else {
          setTotalOrders(0);
        }
        setTotalOrdersLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getPendingOrders = async (valuesFilter) => {
    setPendingOrdersLoading(true);
    const filter = filterArrayToString(valuesFilter);
    await dashboardApi
      .pendingOrders(filter)
      .then((response) => {
        if (response?.data > 0) {
          setPendingOrders(response?.data);
        } else {
          setPendingOrders(0);
        }
        setPendingOrdersLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getUnidadesDeVentas = async (valuesFilter) => {
    setSoldUnitsLoading(true);
    const filter = filterArrayToString(valuesFilter);
    await dashboardApi
      .soldUnits(filter)
      .then((response) => {
        if (response?.data > 0) {
          setSoldUnits(response?.data);
        } else {
          setSoldUnits(0);
        }
        setSoldUnitsLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getTotalSales = async (valuesFilter) => {
    setSalesTotalLoading(true);
    const filter = filterArrayToString(valuesFilter);
    await dashboardApi
      .totalSales(filter)
      .then((response) => {
        if (response?.data?.total > 0) {
          setSalesTotal(response?.data.total.toFixed());
          setPendingSalesTotal(response?.data.pending.toFixed());
          setGrossSalesTotal(response?.data.gross.toFixed());
        } else {
          setSalesTotal(0);
          setPendingSalesTotal(0);
          setGrossSalesTotal(0);
        }
        setSalesTotalLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getTopFiveSales = async (valuesFilter) => {
    setDataTopFiveSalesTableLoading(true);
    await dashboardApi
      .topFiveSales(valuesFilter)
      .then((response) => {
        if (!session.userInfo.isAdmin && response?.data.length > 0) {
          setDataTopFiveSalesTable(response.data);
        } else {
          setDataTopFiveSalesTable([]);
        }
        setDataTopFiveSalesTableLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getSalesSummary = async (valuesFilter) => {
    setDataSalesSummaryLoading(true);
    const filter = filterArrayToString(valuesFilter);
    await dashboardApi
      .salesSummary(filter)
      .then((response) => {
        if (!session.userInfo.isAdmin && response?.data.length > 0) {
          moment.locale('es');
          const summary = response?.data.map((item) => {
            const newItem = item;
            if (newItem?.date?.length === 7) {
              let label = moment(newItem?.date).format('MMMM[,] YYYY');
              label = label.charAt(0).toUpperCase() + label.slice(1);
              newItem.dateLabel = label;
            } else if (newItem?.date) {
              let label = moment(newItem?.date).format(
                'dddd D [de] MMMM[,] YYYY'
              );
              label = label.charAt(0).toUpperCase() + label.slice(1);
              newItem.dateLabel = label;
            }
            return newItem;
          });
          setDataSalesSummary(summary);
        } else {
          setDataSalesSummary([]);
        }
        setDataSalesSummaryLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const ejectFunctions = (oldValues) => {
    setSoldUnits(0);
    setSalesTotal(0);
    setPendingSalesTotal(0);
    setGrossSalesTotal(0);
    setPendingOrders(0);
    setTotalOrders(0);
    setDataTopFiveSalesTable([]);
    setDataSalesSummary([]);
    if (oldValues) {
      const values = { ...oldValues };
      if (!values.marketplace || values.marketplace.length === 0) {
        values.marketplace = [''];
      }
      dispatch(actionsAnalitycs.setFilter(values));
      getUnidadesDeVentas(values);
      getTotalSales(values);
      getPendingOrders(values);
      getTotalOrders(values);
      getTopFiveSales(values);
      getSalesSummary(values);
    }
  };

  const changeCurrency = (currency) => {
    localStorage.setItem('defaultCurrency', currency);
    dispatch(actionsAnalitycs.defaultCurrency(currency));
  };

  useEffect(() => {
    const existDefaultCurrency = localStorage.getItem('defaultCurrency');
    if (!existDefaultCurrency) {
      changeCurrency('USD');
    } else {
      changeCurrency(existDefaultCurrency);
    }
  }, []);

  useEffect(() => {
    const getPartnerData = async () => {
      try {
        const response = await partnerApi.findById(
          session.userInfo.commercial_partner_id[0]
        );
        const companyResponse = await companiesAPI.findById(
          session.userInfo.commercial_partner_id[0]
        );
        const companyAddress = await companiesAPI.getCompanyAddress(
          session.userInfo.commercial_partner_id[0]
        );
        delete response.data.x_product_template_ids;
        const data = {
          ...response.data,
          address: { ...companyAddress.data },
          isActive: companyResponse.data.active,
          isDemo: companyResponse.data.isDemo,
        };
        if (companyResponse?.data?.isOnboardingModalActive) {
          getOnboardingSteps(session.userInfo.commercial_partner_id[0]);
        }
        dispatch(Actions.getPartnerSuccess(data));
      } catch (error) {
        dispatch(Actions.getPartnerFailed(error));
      }
    };

    if (session) {
      dispatch(Actions.getPartner());
      getPartnerData();
    }
  }, []);

  useEffect(() => {
    if (session.userInfo.is_company_new) {
      setStartNewCompanyTour(true);
    }
  }, []);

  const handleNewCompanyTourCallback = (data) => {
    const { action, lifecycle, status } = data;
    if (
      status === 'finished' ||
      (action === 'skip' && lifecycle === 'complete') ||
      onboardingStatus.state !== null
    ) {
      companiesAPI.update(session.userInfo.commercial_partner_id[0], {
        isNewCompany: false,
      });
      dispatch(updateUserInfoNewCompany(false));
      setStartNewCompanyTour(false);
    }
  };

  const handleSkipTourCallback = (data) => {
    const { action, lifecycle, type } = data;
    if (action === 'close' && lifecycle === 'complete') {
      dispatch(updateOnboarding(null, null));
    } else if (
      type === 'error:target_not_found' &&
      onboardingStatus.state === 'Dashboard' &&
      onboardingStatus.step === 0 &&
      action === 'next'
    ) {
      history.push('/ventas');
    } else if (
      type === 'error:target_not_found' &&
      onboardingStatus.state === 'FirstPost' &&
      onboardingStatus.step === 0
    ) {
      history.push('/catalogo');
    }
  };

  const handleConnectMarketplace = (data) => {
    const { action } = data;
    if (action === 'next') {
      dispatch(updateOnboarding(null, null));
      history.push('/my-account?step=2');
    } else if (action === 'close') {
      dispatch(updateOnboarding(null, null));
    }
  };

  return (
    <div
      className="content-div"
      style={{
        backgroundColor: '#f9f9f9',
        paddingBottom: 20,
        paddingTop: 70,
        paddingLeft: 20,
        paddingRight: 15,
      }}
    >
      <Row className="home-listing-main-row listings-main">
        <Col
          xs={24}
          md={24}
          lg={24}
          xl={24}
          span={24}
          style={{ textAlign: 'left' }}
        >
          <Row>
            <Col xs={24} md={24} lg={24} xl={24} span={24}>
              <Card className="ventas-home-listing-card">
                <div className="welcome-container">
                  <div>
                    <Title level={3} className="titlePages">
                      {t('home.welcome')}
                      {', '}
                      {session?.userInfo?.name}
                    </Title>
                    <span
                      style={{
                        color: '#646464',
                        fontStyle: 'italic',
                        fontWeight: '500',
                      }}
                    >
                      {t('home.greetSubtitle')}
                    </span>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          {showOnboardingModal && (
            <Row>
              <Col xs={24} md={24} lg={24} xl={24} span={24}>
                <Card
                  className="ventas-home-listing-card step1newCompanyTour"
                  style={{ marginTop: 10, paddingBottom: 10 }}
                >
                  <div className="onboardingModal">
                    <div className="titlePages">
                      {t('home.onboarding.title')}
                    </div>
                    <div>
                      <Button
                        className="toggleOnboardingButton"
                        onClick={toggleOnboardingSteps}
                        icon={
                          showOnboardingSteps ? (
                            <UpOutlined className="toggleOnboardingButtonIcon" />
                          ) : (
                            <DownOutlined className="toggleOnboardingButtonIcon" />
                          )
                        }
                      />
                      <Button
                        className="closeOnboardingButton"
                        onClick={handleCloseOnboardingModal}
                        icon={
                          <CloseOutlined className="closeOnboardingButtonIcon" />
                        }
                      />
                    </div>
                  </div>
                  {showOnboardingSteps && (
                    <>
                      <div
                        style={{
                          color: '#191919',
                          fontWeight: '500',
                          marginTop: '5px',
                        }}
                      >
                        {t('home.onboarding.subtitle')}
                      </div>
                      <div style={{ marginTop: '15px' }}>
                        <Row gutter={[8, 8]}>
                          <Col flex={1} style={{ textAlign: 'center' }}>
                            <OnboardingButton
                              {...{
                                step: 1,
                                text: t('home.onboarding.steps.0'),
                                linkTo: '/my-account?step=2',
                                completed:
                                  onboardingSteps.has_markets &&
                                  onboardingSteps.has_advertising,
                                onClick: handleOnboardingMarketplaceClick,
                                disabled: startNewCompanyTour,
                              }}
                            />
                          </Col>
                          <Col flex={1} style={{ textAlign: 'center' }}>
                            <OnboardingButton
                              {...{
                                step: 2,
                                text: t('home.onboarding.steps.1'),
                                linkTo: '/couriers',
                                completed: onboardingSteps.has_couriers,
                                onClick: handleOnboardingCourierClick,
                                disabled: startNewCompanyTour,
                              }}
                            />
                          </Col>
                          <Col flex={1} style={{ textAlign: 'center' }}>
                            <OnboardingButton
                              {...{
                                step: 3,
                                text: t('home.onboarding.steps.2'),
                                linkTo: '/catalogo',
                                completed: onboardingSteps.has_publications,
                                onClick: handleOnboardingFirstPostClick,
                                disabled: startNewCompanyTour,
                              }}
                            />
                          </Col>
                          <Col flex={1} style={{ textAlign: 'center' }}>
                            <OnboardingButton
                              {...{
                                step: 4,
                                text: t('home.onboarding.steps.3'),
                                linkTo: '/envios',
                                completed: onboardingSteps.has_shipments,
                                onClick: null,
                                disabled: startNewCompanyTour,
                              }}
                            />
                          </Col>
                          <Col flex={1} style={{ textAlign: 'center' }}>
                            <OnboardingButton
                              {...{
                                step: 5,
                                text: t('home.onboarding.steps.4'),
                                linkTo: '/ventas',
                                completed: onboardingSteps.has_sales,
                                onClick: handleOnboardingDashboardClick,
                                disabled: startNewCompanyTour,
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                </Card>
              </Col>
            </Row>
          )}
          {!isAdmin && (
            <Row>
              <Col xs={24} md={24} lg={24} xl={24} span={24}>
                <Card
                  className="ventas-home-listing-card"
                  style={{ marginTop: 10 }}
                >
                  <Row>
                    <Col xs={24} span={24} className="intro-filters">
                      <DashboardFilter
                        dashboard="summary"
                        ejectFunctions={ejectFunctions}
                        getSkusperMarketplace={getSkusperMarketplace}
                        listMarketplaceAvailableClient={
                          listMarketplaceAvailableClient
                        }
                        listSkus={listSkus}
                        isAdmin={isAdmin}
                        multiMarket
                        sku={false}
                        partnerId={partnerId}
                        timezoneFilter
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
          <div className="step1Dashboards">
            <IndexChart
              salesTotal={salesTotal}
              pendingSalesTotal={pendingSalesTotal}
              grossSalesTotal={grossSalesTotal}
              soldUnits={soldUnits}
              pendingOrders={pendingOrders}
              totalOrders={totalOrders}
              dataSalesSummary={dataSalesSummary}
              dataTopFiveSalesTable={dataTopFiveSalesTable}
              salesTotalLoading={salesTotalLoading}
              soldUnitsLoading={soldUnitsLoading}
              pendingOrdersLoading={pendingOrdersLoading}
              totalOrdersLoading={totalOrdersLoading}
              dataSalesSummaryLoading={dataSalesSummaryLoading}
              dataTopFiveSalesTableLoading={dataTopFiveSalesTableLoading}
            />
          </div>
          <SubscriptionConfirmationModal
            visible={subscriptionConfirmationModalVisible}
            setVisible={setSubscriptionConfirmationModalVisible}
            subscriptionId={linkToSubscriptionId}
            stripeCheckoutSessionId={stripeCheckoutSessionId}
          />
        </Col>
      </Row>
      <Modal
        open={showWarningModal}
        centered
        width={360}
        footer={null}
        closable={false}
        className="warningModal"
      >
        <Row className="warningRow" gutter={[8, 32]}>
          <Col span={24}>
            <div className="warningTitle">
              {t('home.onboarding.warning.title')}
            </div>
          </Col>
          <Col span={24}>
            <div className="warningText">
              {t('home.onboarding.warning.description')}
            </div>
          </Col>
          <Col span={24}>
            <div className="warningButtons">
              <Button
                className="cancelButton"
                key="submit"
                id="cancelCloseOnboardingModalButton"
                size="large"
                onClick={handleCancel}
              >
                {t('common.cancel')}
              </Button>
              <Button
                className="continueButton"
                key="submit"
                id="closeOnboardingModalButton"
                size="large"
                onClick={closeOnboardingModal}
              >
                {t('common.confirm')}
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
      {onboardingStatus.state === 'Courier' && onboardingStatus.step === 0 && (
        <Joyride
          steps={stage2Home}
          callback={handleSkipTourCallback}
          disableCloseOnEsc
          spotlightClicks
        />
      )}
      {onboardingStatus.state === 'Marketplace' &&
        onboardingStatus.step === 0 && (
          <Joyride
            steps={stage1Home}
            callback={handleSkipTourCallback}
            disableCloseOnEsc
            spotlightClicks
          />
        )}
      {onboardingStatus.state === 'FirstPost' &&
        onboardingStatus.step === 0 && (
          <Joyride
            steps={stage3Home}
            callback={handleSkipTourCallback}
            disableCloseOnEsc
            spotlightClicks
            disableScrolling
          />
        )}
      {onboardingStatus.state === 'FirstPost' &&
        onboardingStatus.step === 'NOT' && (
          <Joyride
            steps={stage3NotAvailable}
            callback={handleConnectMarketplace}
            continuous
            disableCloseOnEsc
            spotlightClicks
            disableScrolling
          />
        )}
      {onboardingStatus.state === 'Dashboard' &&
        onboardingStatus.step === 0 && (
          <Joyride
            steps={stage5Home}
            callback={handleSkipTourCallback}
            disableCloseOnEsc
            disableScrolling
            spotlightClicks
            continuous
            hideBackButton
          />
        )}
      <Joyride
        steps={newCompanyTourSteps}
        run={startNewCompanyTour}
        callback={handleNewCompanyTourCallback}
        disableOverlayClose
        disableCloseOnEsc
        spotlightClicks
        hideCloseButton
        hideBackButton
        continuous
        showSkipButton
        disableScrolling
        styles={{
          buttonNext: { backgroundColor: '#010C33' },
          buttonSkip: { color: '#010C33' },
        }}
      />
    </div>
  );
}

export default Home;

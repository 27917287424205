import { DownOutlined, UpOutlined } from '@ant-design/icons';
import {
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { orderGeneralStates } from '../../../../../../utils/const';

const renderName = (text, row) => {
  if (row.type !== 'listing') {
    return (
      <Row>
        <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
          <Image src={row.image} />
        </Col>
        <Col span={20}>
          <Row style={{ marginLeft: 20 }}>
            <Typography.Text style={{ color: '#67A4FF' }}>
              {text}
            </Typography.Text>
          </Row>
          <Row style={{ marginLeft: 20 }}>
            <Typography.Text>SKU: {row.sku}</Typography.Text>
          </Row>
        </Col>
      </Row>
    );
  }
  return {
    children: <span>{text}</span>,
  };
};

const renderCurrentStock = (_text, row) => {
  if (row.type === 'product') {
    return (
      <Typography.Text>
        {row.stock ? row.stock : 'No disponible'}
      </Typography.Text>
    );
  }
  return null;
};

const onExpandIcon = ({ expanded, onExpand, record }) => {
  const isListing = record.type === 'listing';

  if (isListing) {
    if (expanded) {
      return (
        <DownOutlined
          className="contentIcon"
          style={{ paddingRight: 5 }}
          onClick={(e) => onExpand(record, e)}
        />
      );
    }
    return (
      <UpOutlined
        className="contentIcon"
        style={{ paddingRight: 5 }}
        onClick={(e) => onExpand(record, e)}
      />
    );
  }

  return null;
};

function MelonnRestockOrderProducts({
  order,
  listingAndProducts,
  setSelectedRowKeys: setSelectedRowKeysInParent,
  updateSelectedRowKeys,
}) {
  const [dataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { Search } = Input;
  const { state } = order;

  const formatDataSource = (listings) => {
    const data = [];
    listings.forEach((listing) => {
      const children = listing.products?.map((product) => {
        return {
          key: `${product.id}_${product.defaultCode}`,
          id: product.id,
          type: 'product',
          name: product.title,
          sku: product.defaultCode,
          image:
            product.mainImages && product.mainImages[0]
              ? product.mainImages[0].url
              : null,
          quantity: 0,
          stock: product.inventory?.availableStock ?? null,
        };
      });

      data.push({
        key: listing.id,
        name: listing.name,
        type: 'listing',
        children,
      });
    });

    return data;
  };

  useEffect(() => {
    let formattedData = formatDataSource(listingAndProducts);

    const selectedRowKeysArr = [];
    const rowKeys = [];
    formattedData = formattedData.map((data) => {
      const listing = data;
      listing.children = listing.children?.map((child) => {
        const product = child;
        const existingProductInOrder = order.products.find(
          (orderProduct) => orderProduct.product.id === product.id
        );

        if (existingProductInOrder) {
          rowKeys.push(listing.key);
          selectedRowKeysArr.push(product.key);
          product.quantity = existingProductInOrder.quantity;
        }
        return product;
      });
      return listing;
    });

    setExpandedRowKeys(rowKeys);
    setSelectedRowKeys(selectedRowKeysArr);
    setSelectedRowKeysInParent(selectedRowKeysArr);
    setFilteredDataSource(formattedData);
  }, []);

  const renderQuantity = (_text, row) => {
    if (row.type === 'product') {
      return (
        <Form.Item
          label=""
          className="input-form-margin-bottom"
          name={`quantity-${row.id}`}
          initialValue={row.quantity}
        >
          <InputNumber
            disabled={
              !selectedRowKeys.find((key) => key.split('_')[0] === row.id) ||
              state !== orderGeneralStates.DRAFT
            }
            name={`quantity-${row.id}`}
            min={0}
          />
        </Form.Item>
      );
    }
    return null;
  };

  const columns = [
    {
      title: 'Producto',
      dataIndex: 'name',
      key: 'product',
      render: renderName,
      width: '40%',
      onFilter: (value, record) =>
        record.name.toLowerCase().startsWith(value.toLowerCase()),
      filterSearch: true,
    },
    {
      title: 'Stock Actual',
      dataIndex: 'currentStock',
      key: 'currentStock',
      render: renderCurrentStock,
      width: '30%',
    },
    {
      title: 'Unidades',
      dataIndex: 'quantity',
      key: 'units',
      render: renderQuantity,
      width: '30%',
    },
  ];

  const onSearch = (value) => {
    if (value === '') {
      setFilteredDataSource(dataSource);
    } else {
      const searchValue = value.toLowerCase();
      const filteredData = dataSource.filter((row) =>
        row.children[0].sku.toLowerCase().startsWith(searchValue)
      );
      setFilteredDataSource(filteredData);
    }
  };

  const onExpand = (expanded, record) => {
    if (expandedRowKeys) {
      const expandedKeys = expandedRowKeys;
      if (expanded) {
        expandedKeys.push(record.key);
      } else {
        const index = expandedKeys.indexOf(record.key);
        if (index > -1) {
          expandedKeys.splice(index, 1);
        }
      }
      setExpandedRowKeys(expandedKeys);
    }
  };

  const expandable = {
    expandedRowKeys,
    onExpand,
    expandIcon: onExpandIcon,
  };

  const rowClassName = (record) => {
    if (record.type === 'product') {
      return 'product';
    }
    if (record.type === 'listing') {
      return 'listing';
    }
    return null;
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    updateSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    hideSelectAll: true,
    getCheckboxProps: () => {
      return {
        disabled: state !== orderGeneralStates.DRAFT,
      };
    },
  };

  return (
    <div style={{ marginTop: 50 }}>
      <Col
        style={{
          alignItems: 'flex-start',
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <h2>
          Paso 2: Selecciona los SKU y cantidades que quieres incluir en el
          envío
        </h2>
      </Col>

      <Space direction="vertical" align="start">
        <Search
          placeholder="Buscar por SKU"
          allowClear
          onSearch={onSearch}
          style={{
            width: 200,
          }}
        />

        <Table
          expandable={expandable}
          columns={columns}
          dataSource={filteredDataSource}
          rowClassName={rowClassName}
          rowSelection={rowSelection}
          pagination={false}
          sticky
        />
      </Space>
    </div>
  );
}

export default MelonnRestockOrderProducts;

MelonnRestockOrderProducts.propTypes = {
  listingAndProducts: PropTypes.instanceOf(Array).isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  setSelectedRowKeys: PropTypes.func.isRequired,
  updateSelectedRowKeys: PropTypes.func.isRequired,
};

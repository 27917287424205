import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Modal, Row, Typography, Form, Select, Button } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Banner, { AlertTypeEnum } from '../../../components/Alert';
import inventoryApi from '../../../api/inventory';
import openNotification from '../../../components/Toastr';

const { Title, Text } = Typography;
function SyncInventory(props) {
  const { isModalOpen, setIsModalOpen, loading, setLoading, marketplaces } =
    props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const session = useSelector((store) => store.Session.session);
  const [selectedPrimaryMkp, setSelectedPrimaryMkp] = useState([]);
  const [selectedSecondaryMkp, setSelectedSecondaryMkp] = useState([]);
  const [inventoryGroupByCompany, setInventoryGroupByCompany] = useState(null);
  const [sendEnabled, setSendEnabled] = useState(false);
  const [removeEnabled, setRemoveEnabled] = useState(false);

  const onSelectMarketplace = () => {
    const formValues = form.getFieldsValue();
    if (formValues.primaryMarketplace)
      setSelectedPrimaryMkp([formValues.primaryMarketplace]);
    if (Array.isArray(formValues.secondaryMarketplaces)) {
      setSelectedSecondaryMkp(formValues.secondaryMarketplaces);
    }
  };

  useEffect(() => {
    if (
      selectedPrimaryMkp?.length === 0 ||
      selectedSecondaryMkp?.length === 0
    ) {
      setSendEnabled(false);
    } else if (inventoryGroupByCompany?.id) {
      if (
        inventoryGroupByCompany?.primaryMarketplace !== selectedPrimaryMkp[0] ||
        inventoryGroupByCompany?.secondaryMarketplaces !==
          selectedSecondaryMkp?.join(',')
      ) {
        setSendEnabled(true);
      }
    } else {
      setSendEnabled(true);
    }
  }, [selectedPrimaryMkp, selectedSecondaryMkp]);

  const getInventoryGroup = async () => {
    await inventoryApi
      .getByCompanyId()
      .then((response) => {
        const data = response?.data;
        if (data?.id) {
          setInventoryGroupByCompany(data);
          setRemoveEnabled(true);
          form.setFieldsValue({
            primaryMarketplace: data.primaryMarketplace,
            secondaryMarketplaces: data.secondaryMarketplaces?.split(','),
          });
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (session) {
      getInventoryGroup();
    }
  }, []);

  const onSubmit = async () => {
    const formValues = form.getFieldsValue();
    setLoading(true);
    await inventoryApi
      .saveGroupingMarketplaces(formValues)
      .then((response) => {
        if (response?.data?.success) {
          openNotification({
            status: true,
            content: t('inventory.sync.modal.sendOk'),
          });
          setLoading(false);
        } else {
          openNotification({
            status: false,
            content:
              response?.data?.error || t('inventory.sync.modal.sendError'),
          });
          setLoading(false);
        }
      })
      .catch(() => {
        openNotification({
          status: false,
          content: t('inventory.sync.modal.sendError'),
        });
        setLoading(false);
      });
    setRemoveEnabled(true);
  };

  const inactiveInventoryGroup = async (id) => {
    setLoading(true);
    if (!id) {
      openNotification({
        status: false,
        content: t('inventory.sync.modal.removeError'),
      });
      return;
    }
    await inventoryApi
      .inactiveInventoryGroup(id)
      .then((response) => {
        if (response?.data?.success) {
          openNotification({
            status: true,
            content: t('inventory.sync.modal.removeOk'),
          });
          setLoading(false);
          setSelectedPrimaryMkp([]);
          setSelectedSecondaryMkp([]);
          setRemoveEnabled(false);
          form.resetFields();
        } else {
          openNotification({
            status: false,
            content:
              response?.data?.error || t('inventory.sync.modal.removeError'),
          });
          setLoading(false);
        }
      })
      .catch(() => {
        openNotification({
          status: false,
          content: t('inventory.sync.modal.removeError'),
        });
        setLoading(false);
      });
  };

  return (
    <Modal
      style={{ minWidth: 550 }}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      okText={t('inventory.sync.modal.okButton')}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={false}
      destroyOnClose
    >
      <Row justify="center" style={{ paddingTop: 30 }}>
        <Col>
          <Title level={4}>{t('inventory.sync.modal.title')}</Title>
        </Col>
      </Row>
      <Banner
        message={t('inventory.sync.modal.message')}
        type={AlertTypeEnum.INFO}
        closable
      />
      <Row>
        <Form
          name="formulario"
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          className="text-align-left form-style"
        >
          <Col span={24}>
            <Text strong>{t('inventory.sync.modal.primaryMarketplace')}</Text>
            <Form.Item name="primaryMarketplace">
              <Select
                allowClear
                style={{ width: 500 }}
                maxTagCount="responsive"
                placeholder={t(
                  'inventory.sync.modal.primaryMarketplacePlaceholder'
                )}
                onChange={onSelectMarketplace}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {marketplaces?.map((mkp) => (
                  <Select.Option
                    key={mkp}
                    value={mkp}
                    disabled={selectedSecondaryMkp.some(
                      (element) => element === mkp
                    )}
                  >
                    {t(`dashboard.marketplaces.${mkp.replace(' ', '')}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col style={{ height: 20 }} />
          <Col span={24}>
            <Text strong>
              {t('inventory.sync.modal.secondaryMarketplaces')}
            </Text>
            <Form.Item name="secondaryMarketplaces">
              <Select
                allowClear
                style={{ width: '100%' }}
                maxTagCount="responsive"
                placeholder={t(
                  'inventory.sync.modal.secondaryMarketplacesPlaceholder'
                )}
                mode="multiple"
                onChange={onSelectMarketplace}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {marketplaces?.map((mkp) => (
                  <Select.Option
                    key={mkp}
                    value={mkp}
                    disabled={selectedPrimaryMkp.some(
                      (element) => element === mkp
                    )}
                  >
                    {t(`dashboard.marketplaces.${mkp.replace(' ', '')}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Col style={{ height: 20 }} />
            <Row justify="end">
              {removeEnabled && (
                <Form.Item>
                  <Button
                    style={{ marginRight: 5 }}
                    className="btn-basic-green"
                    type="primary"
                    loading={removeEnabled && loading}
                    disabled={!removeEnabled}
                    onClick={() =>
                      inactiveInventoryGroup(inventoryGroupByCompany?.id)
                    }
                  >
                    {t('inventory.sync.modal.removeButton')}
                  </Button>
                </Form.Item>
              )}

              <Form.Item>
                <Button
                  className="btn-basic-green"
                  type="primary"
                  htmlType="submit"
                  loading={sendEnabled && loading}
                  disabled={!sendEnabled}
                >
                  {t('inventory.sync.modal.okButton')}
                </Button>
              </Form.Item>
            </Row>
          </Col>
        </Form>
      </Row>
    </Modal>
  );
}

SyncInventory.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  marketplaces: PropTypes.instanceOf(Array).isRequired,
};

export default SyncInventory;

import React from 'react';
import PropTypes from 'prop-types';

import AmazonRestockOrderSteps from './Amazon/AmazonRestockOrderSteps';
import FboCompanyNames from './enum/fboCompanyNames';
import Int99MinutosRestockOrderSteps from './Int99Minutos/Int99MinutosRestockOrderSteps';
import MelonnRestockOrderSteps from './Melonn/MelonnRestockOrderSteps';
import { orderStates, orderSteps } from '../../../../utils/const';
import FullOrder from '../steps/FullOrder';

function RenderRestockOrderSteps({ orderDb, setCourierName, courierName }) {
  const { fboFulfillmentCompany } = orderDb;

  return (
    <>
      {fboFulfillmentCompany === FboCompanyNames.Amazon &&
        orderDb.steps?.find((step) => step.step === orderSteps.ESTADO).state !==
          orderStates.COMPLETED && <AmazonRestockOrderSteps order={orderDb} />}
      {fboFulfillmentCompany === FboCompanyNames.Amazon &&
        orderDb.steps?.find((step) => step.step === orderSteps.ESTADO).state ===
          orderStates.COMPLETED && <FullOrder orderId={orderDb.id} />}
      {fboFulfillmentCompany === FboCompanyNames.Fulfill99 && (
        <Int99MinutosRestockOrderSteps
          order={orderDb}
          setCourierName={setCourierName}
          courierName={courierName}
        />
      )}
      {fboFulfillmentCompany === FboCompanyNames.Melonn && (
        <MelonnRestockOrderSteps
          order={orderDb}
          setCourierName={setCourierName}
          courierName={fboFulfillmentCompany}
        />
      )}
    </>
  );
}

RenderRestockOrderSteps.propTypes = {
  orderDb: PropTypes.element.isRequired,
  setCourierName: PropTypes.string.isRequired,
  courierName: PropTypes.string.isRequired,
};

export default RenderRestockOrderSteps;

import { Avatar } from 'antd';
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import React from 'react';
import PropTypes from 'prop-types';

function UpDownButton({
  onUp,
  onDown,
  disableUp = false,
  disableDown = false,
}) {
  const iconStyle = { color: 'black', display: 'flex', alignItems: 'center' };
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <button
        style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
        type="button"
        onClick={onUp}
        aria-label="Up"
        disabled={disableUp}
      >
        <Avatar
          size={22}
          icon={<FaLongArrowAltUp size={22} style={iconStyle} />}
          shape="square"
          style={{ background: 'transparent', borderRadius: 10 }}
        />
      </button>
      <button
        style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
        type="button"
        onClick={onDown}
        aria-label="Down"
        disabled={disableDown}
      >
        <Avatar
          size={22}
          icon={<FaLongArrowAltDown size={22} style={iconStyle} />}
          shape="square"
          style={{ background: 'transparent', borderRadius: 10 }}
        />
      </button>
    </div>
  );
}

UpDownButton.propTypes = {
  onUp: PropTypes.func.isRequired,
  onDown: PropTypes.func.isRequired,
  disableUp: PropTypes.bool.isRequired,
  disableDown: PropTypes.bool.isRequired,
};

export default UpDownButton;

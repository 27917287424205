import api from './api';

const prefix = 'imports';

const importApi = {
  getAll() {
    return api.get(`${prefix}`);
  },
  getCompletedImports(marketplace) {
    return api.get(`${prefix}/completed?marketplace=${marketplace}`);
  },
};

export default importApi;

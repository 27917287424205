import PropTypes from 'prop-types';
import Link from 'antd/lib/typography/Link';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import SingleFileUpload from './components/SingleFileUpload';

function DocumentRow({
  title,
  bulletPoints,
  index,
  handleFileChange,
  downloadLink,
  optionalUpload,
}) {
  const { t } = useTranslation();
  return (
    <Row>
      <Col span={24}>
        <Row>
          <p style={{ fontWeight: 500, fontSize: 16 }}>
            <FilePdfOutlined
              id="custom-icon-color-pdf"
              style={{
                color: 'black',
                fontSize: 16,
                position: 'relative',
                top: 0,
                marginRight: 5,
              }}
            />{' '}
            {title}
          </p>
          {downloadLink && (
            <>
              <Divider type="vertical" style={{ top: 5 }} />
              <p id="custom-icon-color-download" style={{ marginTop: 1 }}>
                <DownloadOutlined
                  style={{
                    color: 'black',
                    fontSize: 16,
                    position: 'relative',
                    top: 0,
                  }}
                />{' '}
                <Link href={downloadLink} title="Ver más" target="_blank">
                  {t('common.download')}
                </Link>
              </p>
            </>
          )}
        </Row>
        <ul>
          {bulletPoints.map((bulletPoint) => (
            <li>
              {bulletPoint.value}{' '}
              {bulletPoint.link && (
                <Link href={bulletPoint.link} title="Ver más" target="_blank">
                  {bulletPoint.linkText}
                </Link>
              )}
            </li>
          ))}
        </ul>
        <SingleFileUpload
          onFileChange={handleFileChange}
          index={index}
          isOptional={optionalUpload}
        />
      </Col>
    </Row>
  );
}

DocumentRow.propTypes = {
  title: PropTypes.string.isRequired,
  bulletPoints: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      link: PropTypes.string,
      linkText: PropTypes.string,
    })
  ).isRequired,
  index: PropTypes.number.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  downloadLink: PropTypes.string,
  optionalUpload: PropTypes.bool,
};

DocumentRow.defaultProps = {
  downloadLink: null,
  optionalUpload: false,
};

export default DocumentRow;

import { LinkOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Modal, Row, Select } from 'antd';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LapMarketplaceImg from '../../../assets/LAP_GLOBAL.png';
import {
  FboCouriersEnum,
  FulfillmentLabelMap,
  fulfillmentType as FulfillmentTypes,
} from '../../../utils/const';
import { confirmDeleteCredential } from '../helpers/credentials';
import toggleFulfillment from '../helpers/fulfillment';
import ModalConect from './ModalConnectNew';
import './marketplaceRow.css';

function CollapseMarketplaceRowHeader({
  marketplaceByUser,
  handleOpenModalConnect,
}) {
  const { parentMarketplace } = marketplaceByUser;

  const articleLink =
    parentMarketplace?.article_link || marketplaceByUser?.article_link;

  const finalMarketplaceName = useMemo(() => {
    switch (parentMarketplace?.name) {
      case 'Edicommerce':
        return 'Walmart DSV';
      case 'Mercadolibre':
        return 'Mercado libre';

      default:
        return parentMarketplace?.name;
    }
  }, [parentMarketplace?.name]);

  const marketplaceImg = parentMarketplace?.svg || marketplaceByUser?.svg;

  const consentUrl = useMemo(() => {
    return marketplaceByUser?.consentUrl?.consentUrl;
  }, [marketplaceByUser]);

  const handleConnect = () => {
    switch (parentMarketplace?.name) {
      case 'Shopify':
        handleOpenModalConnect();
        break;
      case 'Mercadolibre':
        window.open(consentUrl, '_blank');
        break;
      default:
        break;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <Col xs={24} sm={24} md={5} className="">
        {marketplaceImg ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '80%',
              marginLeft: 5,
            }}
          >
            <img
              height={60}
              width={60}
              alt={parentMarketplace?.name}
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                parse(marketplaceImg)
              )}`}
              className="marketplaceImg"
            />
            <span
              style={{
                fontWeight: 500,
                marginLeft: 20,
                width: '100%',
              }}
            >
              {finalMarketplaceName}
            </span>
          </div>
        ) : (
          <img
            src={LapMarketplaceImg}
            height={50}
            width={50}
            alt="lap_marketplace_logo"
            className="marketplaceImg"
          />
        )}
      </Col>
      <Col
        xs={24}
        sm={24}
        md={5}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          type="primary"
          block
          onClick={handleConnect}
          className="marketplacesSelectAndBtnBaseToConnect marketplacesSelectAndBtnToConnect customMediaQuery"
        >
          Conectar
        </Button>
      </Col>
      <Col xs={24} sm={24} md={5} />
      <Col xs={24} sm={24} md={5} />
      <Col
        xs={24}
        sm={24}
        md={4}
        style={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          target="_blank"
          disabled={!articleLink}
          href={articleLink}
          type="primary"
          className="btnDownloadManual customMediaQueryArticle"
        >
          Ver paso a paso <LinkOutlined />
        </Button>
      </Col>
    </div>
  );
}

CollapseMarketplaceRowHeader.propTypes = {
  marketplaceByUser: PropTypes.instanceOf(Object).isRequired,
  handleOpenModalConnect: PropTypes.func.isRequired,
};

function CollapseMarketplaceRowContent({
  marketplaceCredential,
  parentMarketplace,
  session,
}) {
  const [modal, contextHolder] = Modal.useModal();

  const dispatch = useDispatch();

  const [isHovering, setIsHovering] = useState(false);

  const { country } = marketplaceCredential;

  const { fulfillmentCompanies: allFulfillmentCompanies } = parentMarketplace;

  const finalCountryImg = useMemo(() => {
    if (country?.flag_svg) {
      return (
        <>
          <img
            width={46}
            height={30}
            alt=""
            src={`data:image/svg+xml;utf8,${encodeURIComponent(
              parse(country?.flag_svg)
            )}`}
            style={{
              borderRadius: 5,
            }}
          />
          <span style={{ fontWeight: 500, marginLeft: 10 }}>
            {country?.country_code}
          </span>
        </>
      );
    }
    return (
      <img
        width={46}
        height={30}
        alt="lap_marketplace_logo"
        src={LapMarketplaceImg}
      />
    );
  }, [marketplaceCredential]);

  const finalFullfillmentCompanies = useMemo(() => {
    if (parentMarketplace?.fulfillmentCompanies) {
      const options = [];
      if (parentMarketplace?.fulfillmentCompanies.length > 0) {
        parentMarketplace.fulfillmentCompanies.forEach((company) => {
          options.push({
            name: company,
            value: company,
          });

          if (company === FboCouriersEnum.MELONN) {
            options.push({
              name: `${FboCouriersEnum.MELONN} / ${FulfillmentLabelMap.FBM}`,
              value: `${FboCouriersEnum.MELONN} / ${FulfillmentLabelMap.FBM}`,
            });
          }
        });
      }

      options.unshift({
        value: FulfillmentTypes.FBM,
        label: FulfillmentLabelMap.FBM,
      });

      return options;
    }

    return [
      {
        value: FulfillmentTypes.FBM,
        label: FulfillmentLabelMap.FBM,
      },
    ];
  }, [marketplaceCredential?.fulfillmentCompanies]);

  const fulfillmentCompanySelected = useMemo(() => {
    if (
      marketplaceCredential.fulfillmentType === FulfillmentTypes.FBO &&
      marketplaceCredential.shippingType === FulfillmentTypes.FBM
    ) {
      return `${marketplaceCredential.fboFulfillmentCompany} / ${FulfillmentLabelMap.FBM}`;
    }
    return marketplaceCredential.fboFulfillmentCompany || FulfillmentTypes.FBM;
  });

  return (
    <>
      {contextHolder}
      <div className="listMarketplaceRow">
        <Row gutter={[12, 2]} className="contentListMarketplaces">
          <Col
            xs={24}
            sm={24}
            md={5}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {finalCountryImg}
            </div>
          </Col>
          {/* CREDENTIALS BUTTON */}
          <Col xs={24} sm={24} md={5}>
            <Button
              type="primary"
              block
              className="marketplacesSelectAndBtnBase marketplacesSelectAndBtnActive marketplaceConnectedButton customWidth"
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              onClick={() =>
                confirmDeleteCredential(
                  session,
                  {
                    ...marketplaceCredential,
                    ...parentMarketplace,
                  },
                  modal,
                  'Seller',
                  dispatch
                )
              }
              style={{
                marginLeft: 18,
              }}
            >
              {isHovering ? 'Desconectar' : 'Conectado'}
            </Button>
          </Col>
          {/* ADS CREDENTIALS BUTTON */}
          <Col xs={24} sm={24} md={5} className="contentButtonsMarketplaces">
            <Button
              disabled
              type="primary"
              block
              className="marketplacesSelectAndBtnBaseToConnect marketplacesSelectAndBtnToConnect customWidth"
            >
              Conectar
            </Button>
          </Col>
          <Col xs={24} sm={24} md={5} className="contentButtonsMarketplaces">
            <Select
              defaultValue={fulfillmentCompanySelected}
              disabled={!allFulfillmentCompanies}
              name="status"
              className={
                allFulfillmentCompanies
                  ? 'marketplacesSelectAndBtnBase marketplacesSelectAndBtnActive'
                  : `selectBg marketplacesSelectAndBtnBase marketplacesSelectAndBtnInactive`
              }
              options={finalFullfillmentCompanies}
              onChange={(values) =>
                toggleFulfillment(marketplaceCredential, values)
              }
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

CollapseMarketplaceRowContent.propTypes = {
  marketplaceCredential: PropTypes.instanceOf(Object).isRequired,
  parentMarketplace: PropTypes.instanceOf(Object).isRequired,
  session: PropTypes.instanceOf(Object).isRequired,
};

function CollapseMarketplaceRow({ marketplaceByUser }) {
  const { parentMarketplace } = marketplaceByUser;
  const [_, contextHolder] = Modal.useModal();
  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false);
  const session = useSelector((store) => store.Session.session);

  const handleOpenModalConnect = () => {
    setIsModalConnectOpen(true);
  };

  useEffect(() => {
    setIsModalConnectOpen(false);
  }, [session]);

  return (
    <>
      <ModalConect
        isModalOpen={isModalConnectOpen}
        handleCancel={() => setIsModalConnectOpen(false)}
        allProps={{
          marketplaceByUser,
          parentMarketplace,
          session,
        }}
      />
      {contextHolder}
      <Collapse
        defaultActiveKey={parentMarketplace.id || '1'}
        className="customMarketplaceCollapse"
      >
        <Collapse.Panel
          header={
            <CollapseMarketplaceRowHeader
              marketplaceByUser={marketplaceByUser}
              handleOpenModalConnect={handleOpenModalConnect}
            />
          }
          key={parentMarketplace?.id || '1'}
        >
          {marketplaceByUser?.marketplaceCredentials?.map((mkpCred) => (
            <CollapseMarketplaceRowContent
              marketplaceCredential={mkpCred}
              parentMarketplace={marketplaceByUser}
              session={session}
            />
          ))}
        </Collapse.Panel>
      </Collapse>
    </>
  );
}

CollapseMarketplaceRow.propTypes = {
  marketplaceByUser: PropTypes.instanceOf(Object).isRequired,
};

export default CollapseMarketplaceRow;

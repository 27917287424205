import * as React from 'react';
import PropTypes from 'prop-types';

function SvgLiverpool({ height, width }) {
  return (
    <svg
      height={height || 15}
      width={width || 78}
      viewBox="0 0 67 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9558 8.88571H4.73014C4.37168 8.88571 4.25578 8.66848 4.25578 8.37192C4.25578 8.13164 4.25391 0.817871 4.25391 0.817871H3.12012V9.40225C3.12012 9.76245 3.43605 10.0004 3.66879 10.0004H7.9558V8.88571Z"
        fill="#CF1F87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.95599 10.4256V11.5427H2.22907C1.83696 11.5427 1.56543 11.2535 1.56543 10.8795C1.56917 10.4404 1.56543 0.817383 1.56543 0.817383H2.69455V9.73152C2.69455 10.1226 2.867 10.4256 3.35071 10.4256H7.95599Z"
        fill="#CF1F87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.817871H1.13566V11.194C1.13566 11.5699 1.32587 11.967 1.88155 11.967H7.95571V13.0813H1.34924C0.595405 13.0813 0 12.4651 0 11.7299V0.817871Z"
        fill="#CF1F87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.67871 0.817383H5.81391V6.91034C5.81391 7.13172 5.91579 7.34341 6.23873 7.34341H7.95578V8.46137H5.25542C4.83995 8.46137 4.67871 8.20125 4.67871 7.90424V0.817383Z"
        fill="#CF1F87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.24363 5.01268H9.46882C9.82775 5.01268 9.94365 5.23037 9.94365 5.52692C9.94365 5.76629 9.94505 13.0801 9.94505 13.0801H11.0788V4.49613C11.0788 4.13639 10.7629 3.89795 10.5302 3.89795H6.24316L6.24363 5.01268Z"
        fill="#CF1F87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.24268 3.47348V2.35645H11.9701C12.3617 2.35645 12.6337 2.64562 12.6337 3.01966C12.6295 3.45826 12.6337 13.0813 12.6337 13.0813H11.5046V4.16759C11.5046 3.77603 11.3317 3.47348 10.8489 3.47348H6.24268Z"
        fill="#CF1F87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1984 13.0808H13.0632V2.70463C13.0632 2.32921 12.8725 1.93211 12.3168 1.93211H6.24268V0.817383H12.8501C13.6035 0.817383 14.1984 1.43447 14.1984 2.16917V13.0808Z"
        fill="#CF1F87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.52023 13.081H8.38457V6.98896C8.38457 6.76712 8.28222 6.55543 7.96021 6.55543H6.24316V5.43701H8.94258C9.35806 5.43701 9.52023 5.69805 9.52023 5.99461V13.081Z"
        fill="#CF1F87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.2485 7.77897C40.2485 7.39618 40.5415 7.08855 40.9275 7.08855H41.9422V5.96045H40.7397C39.8251 5.96045 39.1025 6.69192 39.1025 7.59265V13.08H40.2494L40.2485 7.77897Z"
        fill="#CF1F87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4238 13.0794H23.5698V5.93994H22.4238V13.0794Z"
        fill="#CF1F87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.6085 6.72424C47.0872 6.72424 48.2883 7.91184 48.2883 9.3697C48.2883 10.828 47.0872 12.0156 45.6085 12.0156C44.1307 12.0156 42.9296 10.828 42.9296 9.3697C42.9296 7.91184 44.1307 6.72424 45.6085 6.72424ZM52.1187 6.72424C53.5974 6.72424 54.7999 7.91184 54.7999 9.3697C54.7999 10.828 53.5974 12.0156 52.1187 12.0156C50.6409 12.0156 49.4379 10.828 49.4379 9.3697C49.4379 7.91184 50.6409 6.72424 52.1187 6.72424ZM58.6298 6.72424C60.1076 6.72424 61.3091 7.91184 61.3091 9.3697C61.3091 10.828 60.1076 12.0156 58.6298 12.0156C57.1506 12.0156 55.9495 10.828 55.9495 9.3697C55.9495 7.91184 57.1506 6.72424 58.6298 6.72424ZM41.7832 15.8173H42.9394V12.0714C43.6278 12.7346 44.5691 13.1437 45.6085 13.1437C46.9844 13.1437 48.1906 12.4266 48.8636 11.351C49.537 12.4266 50.7437 13.1437 52.1187 13.1437C53.4931 13.1437 54.7022 12.4266 55.3738 11.351C56.0472 12.4266 57.2525 13.1437 58.6298 13.1437C60.7418 13.1437 62.4532 11.4543 62.4532 9.3697C62.4532 7.28506 60.7418 5.59521 58.6298 5.59521C57.2525 5.59521 56.0472 6.31285 55.3738 7.3893C54.7022 6.31285 53.4931 5.59521 52.1187 5.59521C50.7437 5.59521 49.537 6.31331 48.8636 7.3893C48.1906 6.31331 46.9844 5.59521 45.6085 5.59521C43.4965 5.59521 41.7832 7.28506 41.7832 9.3697V15.8173Z"
        fill="#CF1F87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7133 6.68544C35.8676 6.68544 36.9154 7.40953 37.2987 8.53994H32.1237C32.5064 7.40953 33.5585 6.68544 34.7133 6.68544ZM34.7133 13.1049C36.4719 13.1049 37.9745 11.9358 38.3881 10.2547H37.2795C36.8977 11.3321 35.8709 12.0562 34.7133 12.0562C33.3061 12.0562 32.1363 11.0101 31.9989 9.5901H38.4965V9.37011C38.4965 7.31083 36.7986 5.63574 34.7133 5.63574C32.6261 5.63574 30.9277 7.31083 30.9277 9.37011C30.9277 11.4289 32.6261 13.1049 34.7133 13.1049Z"
        fill="#CF1F87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4027 13.0799L31.2727 5.94043H29.9646L27.6549 11.6866L25.3453 5.94043H24.0386L26.9076 13.0799H28.4027Z"
        fill="#CF1F87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.021 13.0793H64.1683V3.49268H63.021V13.0793Z"
        fill="#CF1F87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4822 3.49365V11.2834C18.486 11.6708 18.7126 11.9733 19.1608 11.9733H21.9775V13.0788L18.0962 13.0784C17.6064 13.0784 17.3223 12.7832 17.3223 12.3561V3.49365H18.4822Z"
        fill="#CF1F87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.2333 4.19398C66.3352 4.19398 66.4015 4.1557 66.4015 4.07268C66.4015 3.99935 66.3413 3.95323 66.2567 3.95323H66.0445V4.19398H66.2333ZM65.9132 3.84438H66.2707C66.4726 3.84438 66.5394 3.96799 66.5394 4.05331C66.5394 4.18383 66.4469 4.27699 66.3104 4.28437V4.28991C66.3796 4.31297 66.4301 4.38215 66.4969 4.48869L66.596 4.64457H66.4375L66.3646 4.51959C66.2618 4.34018 66.2235 4.30467 66.1244 4.30467H66.0445V4.64457H65.9132V3.84438ZM66.2436 3.61148C65.8963 3.61148 65.6164 3.8882 65.6164 4.23226C65.6164 4.57539 65.8963 4.85488 66.2436 4.85488C66.5913 4.85488 66.875 4.57539 66.875 4.23226C66.875 3.8882 66.5913 3.61148 66.2436 3.61148ZM66.2436 4.97895C65.8272 4.97895 65.4897 4.64457 65.4897 4.23226C65.4897 3.81948 65.8272 3.48926 66.2436 3.48926C66.6619 3.48926 67.0002 3.81948 67.0002 4.23226C67.0002 4.64457 66.6619 4.97895 66.2436 4.97895Z"
        fill="#CF1F87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4224 4.63836H23.5697V3.49365H22.4224V4.63836Z"
        fill="#CF1F87"
      />
    </svg>
  );
}

SvgLiverpool.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

SvgLiverpool.defaultProps = {
  width: 78,
  height: 15,
};

export default SvgLiverpool;

import * as types from './types';
import dashboardApi from '../../../api/dashboard';
import {
  convertToISOString,
  mixPanelCreateEvent,
} from '../../../utils/functions';

const dashboardActions = {
  doReset: () => async (dispatch) => {
    dispatch({
      type: types.DASHBOARD_RESETED,
    });
  },

  defaultCurrency: (currency) => async (dispatch) => {
    dispatch({
      type: types.SET_CURRENCY_DEFAULT,
      payload: {
        currencyDefault: currency,
      },
    });
  },

  setFilter: (rawFilter) => async (dispatch) => {
    const pendingBatches = [];
    const modifiableRawFilter = { ...rawFilter };
    dispatch({
      type: types.DASHBOARD_FETCH_STARTED,
      payload: {
        rawFilter,
        rowsStock: [],
        rows: [],
        loadingProgress: {
          totalToProcess: pendingBatches.length,
          progress: 0,
        },
      },
    });
    const skus = rawFilter.listSkus;
    delete modifiableRawFilter.listSkus;

    dispatch({
      type: types.DASHBOARD_FETCH_SUCCESS,
      payload: {
        rows: [],
        isAdmin: modifiableRawFilter.isAdmin,
        modifiableRawFilter,
        marketplaceAvailable: [],
        listSkus: skus,
        loadingProgress: {
          totalToProcess: pendingBatches.length,
          progress: 0 + 1,
        },
      },
    });

    dispatch({
      type: types.DASHBOARD_FETCH_FINISH,
    });
  },

  doFetch: (rawFilter) => async (dispatch) => {
    try {
      const modifiableRawFilter = { ...rawFilter };
      dispatch({
        type: types.DASHBOARD_FETCH_STARTED,
        payload: {
          rawFilter,
          rowsStock: [],
          rows: [],
          loading: true,
          marketplaceAvailable: [],
          loadingProgress: {},
        },
      });

      const response = await dashboardApi.getMarketplacesPerClient({
        ...rawFilter,
        dateRangeOne: convertToISOString(rawFilter.dateRangeOne),
      });
      if (response?.data?.length > 0) {
        const values = [];
        response?.data.forEach((e) => {
          values.push(e.name);
        });
        modifiableRawFilter.marketplace = values;
        dispatch({
          type: types.DASHBOARD_FETCH_SUCCESS,
          payload: {
            isAdmin: rawFilter.isAdmin,
            modifiableRawFilter,
            marketplaceAvailable: modifiableRawFilter.marketplace,
            loading: false,
            loadingProgress: {},
          },
        });
      }

      dispatch({
        type: types.DASHBOARD_FETCH_FINISH,
      });
    } catch (error) {
      mixPanelCreateEvent('API Rest', {
        status: error,
        message: 'Dashboard data not responding',
      });
      dispatch({
        type: types.DASHBOARD_FETCH_ERROR,
      });
    }
  },
};

export default dashboardActions;

import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Tooltip,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { orderGeneralStates } from '../../../../../../utils/const';

function Int99minutosRestockOrderDetails({ order }) {
  const { attributes, state } = order;
  const { content = {} } = attributes ?? {};
  const {
    name,
    fechaEnvio,
    paquetes,
    nombreContacto,
    direccionContacto,
    celularContacto,
  } = content ?? {};

  const { t } = useTranslation();

  return (
    <>
      <Col
        style={{
          alignItems: 'flex-start',
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <h2>{t('orders.newOrder.contents.title')}</h2>
      </Col>

      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Row>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label={
                <div>
                  <span>Nombre de envío:</span>{' '}
                  <Tooltip title="Este es el nombre con el cual registraste  tu envío. Te recomendamos que siga un formato que te permita identificarlo fácilmente en el futuro.">
                    {' '}
                    <InfoCircleOutlined />
                  </Tooltip>{' '}
                </div>
              }
              name="name"
              rules={[{ required: true, message: 'Requerido' }]}
              initialValue={name}
            >
              <Input
                disabled={state !== orderGeneralStates.DRAFT}
                placeholder="Ejemplo: 20231212 - Fulfill99 - 01"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label={
                <div>
                  <span>Fecha de envío:</span>{' '}
                  <Tooltip title="Esta es la fecha en la cual enviarás tu producto.">
                    {' '}
                    <InfoCircleOutlined />
                  </Tooltip>{' '}
                </div>
              }
              name="fechaEnvio"
              rules={[{ required: true, message: 'Requerido' }]}
              initialValue={fechaEnvio ? moment(fechaEnvio) : null}
            >
              <DatePicker
                disabled={state !== orderGeneralStates.DRAFT}
                disabledDate={(current) =>
                  current && current < moment().endOf('day')
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24}>
            <div>
              <span style={{ fontSize: 14, marginRight: 20 }}>
                Información de contacto:
              </span>{' '}
              <Tooltip title="Esta es la información de contacto que Fulfill99 utilizará ante cualquier inconveniente con su envío.">
                {' '}
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col md={6} lg={8}>
            <Form.Item
              label={
                <div>
                  <span>Nombre</span>
                </div>
              }
              name="nombreContacto"
              rules={[{ required: true, message: 'Requerido' }]}
              initialValue={nombreContacto}
            >
              <Input disabled={state !== orderGeneralStates.DRAFT} />
            </Form.Item>
          </Col>

          <Col md={6} lg={8}>
            <Form.Item
              label={
                <div>
                  <span style={{ marginRight: 10 }}>Número de celular</span>
                </div>
              }
              name="celularContacto"
              rules={[{ required: true, message: 'Requerido' }]}
              initialValue={celularContacto}
            >
              <Input disabled={state !== orderGeneralStates.DRAFT} />
            </Form.Item>
          </Col>

          <Col md={12} lg={8}>
            <Form.Item
              label={
                <div>
                  <span>Dirección</span>
                </div>
              }
              name="direccionContacto"
              initialValue={direccionContacto}
            >
              <Input disabled={state !== orderGeneralStates.DRAFT} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label={
                <div>
                  <span>Cantidad de paquestes a enviar:</span>{' '}
                  <Tooltip title="Esta es la cantidad total de paquetes a enviar.">
                    {' '}
                    <InfoCircleOutlined />
                  </Tooltip>{' '}
                </div>
              }
              name="paquetes"
              rules={[{ required: true, message: 'Requerido' }]}
              initialValue={paquetes}
            >
              <InputNumber
                min={1}
                disabled={state !== orderGeneralStates.DRAFT}
              />
            </Form.Item>
          </Col>
        </Row>
      </Space>
    </>
  );
}

export default Int99minutosRestockOrderDetails;

Int99minutosRestockOrderDetails.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Space,
  Switch,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import LapLogo from '../../../assets/LAP_GLOBAL.png';
import './checkout.css';
import paymentPlanAPI from '../../../api/payment-plan';
import openNotification from '../../../components/Toastr';
import { paymentMethodIcons as icons } from '../../../utils/functions';
import { getErrorMessage } from '../../../api/api';

const { Title, Text } = Typography;
const { Search } = Input;

function Checkout({
  form,
  loading,
  submitForms,
  onFinish,
  paymentPlan,
  plan,
  billingCountry,
  onApplyCoupon,
}) {
  const { t } = useTranslation();
  const [extraPlans, setExtraPlans] = useState([]);
  const [selectedExtraPlan, setSelectedExtraPlan] = useState([]);

  const parseCurrency = (currency) => {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency,
    });
  };

  const logo = (
    <img
      src={LapLogo}
      className="logo"
      alt="Logo de LAP MKT"
      height={50}
      style={{
        transition: 'all 0.5s',
        filter: 'brightness(0) invert(1)',
      }}
    />
  );

  const paymentMethods = ['Mercado Pago', 'PayPal', 'Stripe'];

  const netPrice =
    paymentPlan && billingCountry === 'CL'
      ? paymentPlan.price / 1.19
      : paymentPlan?.price;
  const taxes = netPrice * 0.19;

  const onSelectPaymentMethod = () => {
    form.resetFields(['couponCode', 'extraPlan']);
    setExtraPlans([]);
    setSelectedExtraPlan(null);
  };

  const showExtraPlans = async (checked) => {
    try {
      if (!checked) {
        setExtraPlans([]);
        form.resetFields(['extraPlan']);
        setSelectedExtraPlan(null);
        return;
      }
      const params = {
        paymentMethod: 'Stripe',
        entityType: billingCountry === 'CL' ? 'NATIONAL' : 'INTERNATIONAL',
        planType: 'KAM',
      };
      const { data } = await paymentPlanAPI.find(params);
      setExtraPlans(data);
      if (!data.length) {
        throw new Error(t('checkout.error.noAdditionalPlanFound'));
      }
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
  };

  const onSelectExtraPlan = (event) => {
    const selected = extraPlans.find(
      (extraPlan) => extraPlan.externalId === event.target.value
    );
    if (selected) setSelectedExtraPlan(selected);
  };

  return (
    <Col span={24}>
      <Space
        direction="vertical"
        style={{
          display: 'flex',
        }}
      >
        <Row>
          <Col span={24}>
            <Title level={4}>{t('checkout.summary')}</Title>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <Avatar
              style={{
                width: 80,
                height: 80,
                backgroundColor: 'rgb(1, 12, 51)',
                display: 'flex',
                alignItems: 'center',
              }}
              shape="square"
            >
              {logo}
            </Avatar>
          </Col>
          <Col span={18}>
            <Row>
              <Text>{plan?.name}</Text>
            </Row>
            <Row>
              <Text
                style={{ fontSize: 12, color: '#888888', marginBottom: 10 }}
              >
                {`${t('checkout.plan')} ${t(
                  `checkout.planTypes.${plan?.type}`
                )}`}
              </Text>
            </Row>
            {paymentPlan && (
              <>
                <Row>
                  <Col span={24}>
                    {parseCurrency(paymentPlan.currency).format(
                      paymentPlan.price
                    )}
                  </Col>
                </Row>
                <Row>
                  {paymentPlan.freeTrialFrequency > 0 && (
                    <Col>
                      <Text>
                        {t(
                          `checkout.freeTrial.${paymentPlan.freeTrialFrequencyType}`,
                          { count: paymentPlan.freeTrialFrequency }
                        )}
                      </Text>
                    </Col>
                  )}
                </Row>
              </>
            )}
          </Col>
        </Row>

        {paymentPlan && (
          <>
            <Card className="planInfoCard">
              <Row>
                <Col span={8}>
                  <Text>{t('checkout.product')}</Text>
                </Col>
                <Col span={16} style={{ textAlign: 'right' }}>
                  <Text>
                    {parseCurrency(paymentPlan.currency).format(
                      billingCountry === 'CL'
                        ? Math.round(netPrice)
                        : netPrice.toFixed(2)
                    )}
                  </Text>
                </Col>
              </Row>
            </Card>
            <Card className="planInfoCard">
              <Row>
                <Col span={8}>
                  <Text>{t('checkout.taxes')}</Text>
                </Col>
                <Col span={16} style={{ textAlign: 'right' }}>
                  <Text>
                    {parseCurrency(paymentPlan.currency).format(
                      billingCountry === 'CL' ? Math.round(taxes) : 0
                    )}
                  </Text>
                </Col>
              </Row>
            </Card>
          </>
        )}

        <Form form={form} onFinish={onFinish}>
          <Row style={{ marginTop: 10 }}>
            <Col span={24}>
              <Title level={5}>{t('checkout.paymentMethod')}</Title>
            </Col>
          </Row>
          <Form.Item
            name="paymentMethod"
            rules={[
              {
                required: true,
                message: t('common.required'),
              },
            ]}
          >
            <Radio.Group
              size="large"
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              onChange={onSelectPaymentMethod}
            >
              <Space
                direction="vertical"
                style={{
                  display: 'flex',
                }}
              >
                {paymentMethods.map((method) => {
                  return method !== 'Mercado Pago' ||
                    billingCountry === 'CL' ? (
                    <Card className="planInfoCard">
                      <Row>
                        <Col
                          span={12}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Text>{method}</Text>
                        </Col>
                        <Col span={10}>
                          <Image
                            preview={false}
                            style={{ width: 25, height: 25 }}
                            src={icons[method]}
                          />
                        </Col>
                        <Col
                          span={2}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'right',
                          }}
                        >
                          <Radio value={method} />
                        </Col>
                      </Row>
                    </Card>
                  ) : null;
                })}
              </Space>
            </Radio.Group>
          </Form.Item>
          {form.getFieldValue('paymentMethod') &&
            form.getFieldValue('paymentMethod') !== 'Stripe' && (
              <>
                <Row style={{ marginTop: 10 }}>
                  <Col span={24}>
                    <Title level={5}>{t('checkout.doYouHaveCoupon')}</Title>
                  </Col>
                </Row>
                <Form.Item name="couponCode">
                  <Card className="planInfoCard">
                    <Row>
                      <Col
                        span={7}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Text>{t('checkout.couponCode')}</Text>
                      </Col>
                      <Col
                        span={17}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'right',
                        }}
                      >
                        <Search
                          enterButton={t('common.apply')}
                          size="large"
                          onSearch={onApplyCoupon}
                          disabled={
                            !form.getFieldValue('paymentMethod') ||
                            form.getFieldValue('couponCode') ||
                            !paymentPlan
                          }
                        />
                      </Col>
                    </Row>
                  </Card>
                </Form.Item>
              </>
            )}
          {plan?.type === 'TECH' &&
            form.getFieldValue('paymentMethod') === 'Stripe' && (
              <Row style={{ marginTop: 10 }} justify="space-between">
                <Title level={5}>{t('checkout.wantAdditionalPlan')}</Title>
                <Switch
                  checkedChildren="Sí"
                  unCheckedChildren="No"
                  onChange={showExtraPlans}
                />
              </Row>
            )}
          {extraPlans.length > 0 && (
            <Form.Item
              name="extraPlan"
              rules={[
                {
                  required: true,
                  message: t('common.required'),
                },
              ]}
            >
              <Radio.Group
                size="large"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                onChange={onSelectExtraPlan}
              >
                <Space
                  direction="vertical"
                  style={{
                    display: 'flex',
                  }}
                >
                  {extraPlans.map((extraPlan) => (
                    <Card className="planInfoCard">
                      <Row>
                        <Col
                          span={12}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Text>{extraPlan.plan.name}</Text>
                        </Col>
                        <Col
                          span={10}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Text className="w-500">
                            {parseCurrency(extraPlan.currency).format(
                              extraPlan.price
                            )}
                          </Text>
                        </Col>
                        <Col
                          span={2}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'right',
                          }}
                        >
                          <Radio value={extraPlan.externalId} />
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          )}
          <Row style={{ marginTop: 10 }}>
            <Col span={12}>
              <Title level={5}>{t('common.total')}</Title>
            </Col>
            <Col span={12}>
              {paymentPlan && (
                <Title level={5} style={{ textAlign: 'right' }}>
                  {parseCurrency(paymentPlan.currency).format(
                    paymentPlan.price + (selectedExtraPlan?.price ?? 0)
                  )}{' '}
                  / {t(`checkout.frequencyType.${paymentPlan.frequencyType}`)}
                </Title>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Button
                block
                type="primary"
                onClick={submitForms}
                loading={loading}
                disabled={!paymentPlan}
              >
                {t('checkout.pay')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Space>
    </Col>
  );
}

Checkout.propTypes = {
  plan: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.oneOf(['TECH', 'KAM']),
    transactionsAmount: PropTypes.number,
    skusAmount: PropTypes.number,
    marketplacesAmount: PropTypes.number,
  }).isRequired,
  paymentPlan: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.number,
    currency: PropTypes.string,
    externalId: PropTypes.string,
    frequencyType: PropTypes.string,
    paymentMethod: PropTypes.oneOf(['Mercado Pago', 'PayPal']),
    freeTrialFrequency: PropTypes.number,
    freeTrialFrequencyType: PropTypes.oneOf(['DAY', 'WEEK', 'MONTH', 'YEAR']),
  }).isRequired,
  form: PropTypes.instanceOf(Form).isRequired,
  loading: PropTypes.bool.isRequired,
  submitForms: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  billingCountry: PropTypes.string.isRequired,
  onApplyCoupon: PropTypes.func.isRequired,
};
export default Checkout;

import { Avatar } from 'antd';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import React from 'react';
import PropTypes from 'prop-types';

function RevealEyeButton({ onClick, show = false }) {
  const iconStyle = { color: 'black', display: 'flex', alignItems: 'center' };
  return (
    <button
      style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
      type="button"
      aria-label="Reveal"
      onClick={onClick}
    >
      <Avatar
        size={22}
        icon={
          !show ? (
            <FaEye size={22} style={iconStyle} />
          ) : (
            <FaEyeSlash size={22} style={iconStyle} />
          )
        }
        shape="square"
        style={{ background: 'transparent', borderRadius: 10 }}
      />
    </button>
  );
}

export default RevealEyeButton;

RevealEyeButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

import { Col, Radio, Row, Space, Tag } from 'antd';
import 'antd/dist/antd.min.css';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function ShipsmartServiceSelect({ rates, data, setData, allRates }) {
  const [selectedService, setSelectedService] = useState(rates[0]?.code || '');

  const { t } = useTranslation();

  useEffect(() => {
    const newData = [];
    data.forEach((shipment) => {
      let finalData = {};
      const orderRates =
        allRates[
          shipment.saleOrderId ||
            shipment.thirdPartyShipmentId ||
            shipment.ShipmentId // restock
        ];
      if (orderRates) {
        const carrier = orderRates.find(
          (rate) => rate.code === selectedService
        );
        finalData = {
          ...shipment,
          shipsmartData: {
            quotationData: {
              ...shipment.quotationData,
              ...carrier,
            },
          },
        };
      } else {
        finalData = {
          ...shipment,
          shipsmartData: { quotationData: null },
        };
      }
      newData.push(finalData);
    });

    setData(newData);
  }, [selectedService]);

  if (rates.length === 0) {
    return (
      <Row justify="center" align="middle">
        <Col span={24}>
          <Tag color="red">{t('orders.shipment.summary.ratesNotFound')}</Tag>
        </Col>
      </Row>
    );
  }

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      {rates &&
        rates.map((rate) => (
          <Row
            key={rate.code}
            justify="space-between"
            align="middle"
            style={{
              padding: '10px',
              border: `1px solid ${
                selectedService === rate.code ? '#00e5a6' : '#A3A3A336'
              }`,
              borderRadius: '8px',
            }}
          >
            <Col span={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <Radio
                value={rate.code}
                style={{ margin: 0 }}
                checked={selectedService === rate.code}
                onChange={(e) => setSelectedService(e.target.value)}
              />
            </Col>
            <Col flex="auto">
              <Space direction="vertical">
                <Col span={24} className="w-600">
                  {rate.name}
                </Col>
              </Space>
            </Col>
          </Row>
        ))}
    </Space>
  );
}

ShipsmartServiceSelect.propTypes = {
  rates: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  setData: PropTypes.func.isRequired,
  allRates: PropTypes.instanceOf(Object).isRequired,
};

export default ShipsmartServiceSelect;

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Pie } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';
import { thousandsSeparator, pieChartValue } from '../../../../utils/functions';
import selectors from '../../../../redux/analytic/dashboard/selectors';

function SalesPieChartByMarketplace({ dataSource, currency }) {
  const { t } = useTranslation();

  const currencyState = useSelector(selectors.selectCurrency);

  const config = {
    appendPadding: 10,
    data: dataSource,
    width: 100,
    height: 348,
    angleField: 'value',
    colorField: 'type',
    theme: {
      colors10: [
        '#00E5A6',
        '#010C33',
        '#969696',
        '#CACACA',
        '#E8E8E8',
        '#9FB40F',
        '#76523B',
        '#DAD5B5',
        '#0E8E89',
        '#E19348',
        '#F383A2',
        '#247FEA',
      ],
    },
    radius: 1,
    legend: {
      layout: 'vertical',
      position: 'right',
    },
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: ({ percent }) => pieChartValue(percent),
      autoRotate: false,
      style: {
        textAlign: 'center',
        fontSize: 18,
      },
    },
    tooltip: {
      formatter: (datum) => ({
        name: t(`dashboard.marketplaces.${datum.type.replace(' ', '')}`),
        value: `${thousandsSeparator(Math.round(datum.value))} ${
          currency ? currencyState : ''
        }`,
      }),
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    meta: {
      value: {
        formatter: (v) => `${v}`,
      },
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: '',
      },
    },
  };

  return (
    <Pie
      appendPadding={config.appendPadding}
      data={config.data}
      width={config.width}
      height={config.height}
      angleField={config.angleField}
      colorField={config.colorField}
      theme={config.theme}
      radius={config.radius}
      innerRadius={config.innerRadius}
      legend={config.legend}
      label={config.label}
      tooltip={config.tooltip}
      interactions={config.interactions}
      meta={config.meta}
      statistic={config.statistic}
    />
  );
}
export default React.memo(SalesPieChartByMarketplace);

SalesPieChartByMarketplace.propTypes = {
  dataSource: PropTypes.arrayOf(Object).isRequired,
  currency: PropTypes.string.isRequired,
};

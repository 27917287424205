import React, { useState, useRef } from 'react';
import { Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function SingleFileUpload({ onFileChange, index, isOptional }) {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const { t } = useTranslation();

  const handleFileChange = () => {
    const selectedFile = fileInputRef.current.files[0];
    setFile(selectedFile);
    onFileChange(selectedFile, index, isOptional);
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        accept="application/pdf"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />

      <Button
        onClick={() => fileInputRef.current.click()}
        style={{
          border: '1px solid black',
          borderRadius: '4px',
          padding: '8px 16px 32px',
          cursor: 'pointer',
          display: 'inline-block',
        }}
      >
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <UploadOutlined
            id="custom-icon-color-pdf"
            style={{
              color: 'black',
              fontSize: 14,
              marginRight: 5,
            }}
          />
          <span style={{ fontWeight: 400, fontSize: 14 }}>
            {t('common.uploadDoc')}
          </span>
        </span>
      </Button>

      {file && (
        <p>
          {' '}
          {t('common.selectedFile')} {file.name}
        </p>
      )}
    </div>
  );
}

export default SingleFileUpload;

SingleFileUpload.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isOptional: PropTypes.bool.isRequired,
};

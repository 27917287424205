import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import phones from '../../../utils/json/phones.json';
import stateOrProvinces from '../../../utils/json/stateOrProvinces.json';
import usePlacesInput from '../../shared/usePlacesInput';
import './destinationModal.css';

function DestinationModal({
  form,
  open,
  setOpen,
  externalForm,
  handleNewDirections,
  setDestinationCountryCode,
}) {
  const { t } = useTranslation();
  const [selectedCountryCode, setSelectedCountryCode] = useState();
  const [currStateOrProvinces, setCurrStateOrProvinces] = useState();
  const { Option } = Select;
  const company = useSelector((store) => store.Partner.partner);

  const addressInput = usePlacesInput(
    form,
    setSelectedCountryCode,
    stateOrProvinces
  );

  useEffect(() => {
    if (open && company?.isDemo) {
      form.setFieldsValue({
        streetName: '10240 Old Dowd Rd, Charlotte, NC 28214, USA',
        postalCode: '28214',
        countryCode: 'US',
        city: 'Charlotte',
        stateOrProvinceCode: 'NC',
        contactName: 'Cliente de Prueba',
        email: 'demo@lap.com',
        phoneExtension: '+1',
        phoneNumber: '9999999999',
      });
      setSelectedCountryCode('US');
    }
  }, [open]);

  useEffect(() => {
    if (selectedCountryCode) {
      setDestinationCountryCode(selectedCountryCode);
      setCurrStateOrProvinces(
        stateOrProvinces.find((row) => row.countryCode === selectedCountryCode)
      );
    } else {
      setCurrStateOrProvinces(
        stateOrProvinces.find(
          (row) => row.countryCode === form.getFieldValue().countryCode
        )
      );
    }
  }, [selectedCountryCode]);

  const getStatesOrProvinces = () => {
    return currStateOrProvinces?.states.map((state) => (
      <Select.Option key={state.code} value={state.code}>
        {state.name}
      </Select.Option>
    ));
  };

  const validatePhoneNumber = (_, value, callback) => {
    const phoneNumberRegex = /^\d+$/;
    if (
      ['US', 'CA'].includes(form.getFieldValue('countryCode')) &&
      value.length !== 10
    ) {
      callback(t('thirdPartyShipments.destination.phoneUsaError'));
    } else if (value && phoneNumberRegex.test(value)) {
      callback();
    } else {
      callback(
        `Por favor ingresa ${!value ? 'un número válido' : 'sólo dígitos'}`
      );
    }
  };

  const onCountryCodeChange = () => {
    const countryCode = form.getFieldValue('countryCode');
    setSelectedCountryCode(countryCode);
  };

  const onCancel = () => {
    setOpen(false);
    form.setFieldValue('destinationAddress', null);
    form.setFieldValue('destinationContact', null);
  };

  const onFinish = () => {
    const streetName = form.getFieldValue('streetName');
    externalForm.setFieldValue('destination', `${streetName}`);
    handleNewDirections();
    setOpen(false);
  };

  const onOk = () => {
    form.submit();
  };

  const validatePhoneExtension = (_, value, callback) => {
    if (
      ['US', 'CA'].includes(form.getFieldValue('countryCode')) &&
      value !== '+1'
    ) {
      callback(t('thirdPartyShipments.destination.phoneExtensionUsaError'));
    } else {
      callback();
    }
  };

  const prefixSelector = (
    <Form.Item
      name="phoneExtension"
      noStyle
      rules={[
        { required: true, message: 'Requerido' },
        { required: true, validator: validatePhoneExtension },
      ]}
    >
      <Select
        className="contentOptionsPhone"
        showSearch
        optionFilterProp="children"
      >
        {phones.length > 0 &&
          phones.map((element) => (
            <Select.Option
              className="flag"
              value={`+${element.dial_code}`}
              key={element.code}
            >
              {element.name} (+
              {element.dial_code})
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
  return (
    <Modal
      open={open}
      className="newDestinationModal"
      width="65%"
      footer={null}
      onCancel={onCancel}
    >
      <Row>
        <Col span={24}>
          <div className="titleNewDestinationModal">
            {t('warehouses.createForm.title')}
          </div>
        </Col>
        <Col span={24}>
          <Form
            name="create-warehouse-form"
            layout="vertical"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Row gutter={[16, 20]}>
              <Col span={12}>
                <Form.Item
                  label={t('warehouses.createForm.address.streetName')}
                  name="streetName"
                  rules={[{ required: true }]}
                  className="labelNewDestinationItem"
                >
                  {addressInput}
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t(
                    'warehouses.createForm.address.additionalAddressInfo'
                  )}
                  name="additionalAddressInfo"
                >
                  <Input
                    placeholder={t(
                      'warehouses.createForm.address.additionalAddressInfoPlaceholder'
                    )}
                    showCount
                    maxLength={35}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={t('warehouses.createForm.address.city')}
                  name="city"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={t(
                      'warehouses.createForm.address.cityPlaceholder'
                    )}
                    showCount
                    maxLength={30}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={t('warehouses.createForm.address.postalCode')}
                  name="postalCode"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Ej: 26125" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={t('warehouses.createForm.address.countryCode')}
                  name="countryCode"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    onChange={onCountryCodeChange}
                    filterOption={(input, option) =>
                      option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {phones.map((row) => (
                      <Option key={row.name} value={row.code}>
                        {row.flag} {row.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                {currStateOrProvinces && (
                  <Form.Item
                    label={t(
                      'warehouses.createForm.address.stateOrProvinceCode'
                    )}
                    name="stateOrProvinceCode"
                    rules={[{ required: true }]}
                  >
                    <Select>{getStatesOrProvinces()}</Select>
                  </Form.Item>
                )}
              </Col>

              <Col span={24} />

              <Col span={12}>
                <Form.Item
                  label={t('warehouses.createForm.contactName')}
                  name="contactName"
                  rules={[{ required: true }, { type: 'string', min: 5 }]}
                >
                  <Input maxLength={70} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t('warehouses.createForm.companyName')}
                  name="companyName"
                >
                  <Input maxLength={35} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t('warehouses.createForm.email')}
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: t('warehouses.createForm.validEmail'),
                    },
                  ]}
                >
                  <Input maxLength={80} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t('warehouses.createForm.phoneNumber')}
                  name="phoneNumber"
                  rules={[{ required: true, validator: validatePhoneNumber }]}
                >
                  <Input addonBefore={prefixSelector} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row
        gutter={[16, 20]}
        style={{ marginTop: 30, display: 'flex', justifyContent: 'right' }}
      >
        <Col>
          <Button
            className="cancelButtonNewDestinationModal"
            onClick={onCancel}
          >
            {t('common.cancel')}
          </Button>
        </Col>
        <Col>
          <Button
            className="okButtonNewDestinationModal"
            onClick={onOk}
            type="primary"
          >
            {t('common.accept')}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

DestinationModal.propTypes = {
  form: PropTypes.instanceOf(Form).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  externalForm: PropTypes.instanceOf(Form).isRequired,
  handleNewDirections: PropTypes.func.isRequired,
  setDestinationCountryCode: PropTypes.func.isRequired,
};

export default DestinationModal;

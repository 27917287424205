import types from './type';
import marketingApi from '../../../api/marketing';

const dashboardActions = {
  getClients: (partnerId) => async (dispatch, _getState) => {
    try {
      const response = await marketingApi.getClients(partnerId);
      dispatch({
        type: types.GET_LIST_CLIENTS_MRKT,
        payload: {
          clientsMkt: response.data,
        },
      });
    } catch (error) {
      dispatch({
        type: types.GET_LIST_CLIENTS_MRKT,
        payload: {
          clientsMkt: false,
        },
      });
    }
  },
};

export default dashboardActions;

import api from './api';

const prefix = 'users';
const UsersAPI = {
  findById(id) {
    return api.get(`${prefix}/${id}`);
  },
  find(limit, page, keyword = '', companyId = null) {
    const params = {
      take: limit,
      page,
      keyword,
      companyId,
    };
    return api.get(`${prefix}/find/paginate`, {
      params,
    });
  },
  create(payload) {
    return api.post(prefix, payload);
  },
  update(id, payload) {
    return api.put(`${prefix}/${id}`, payload);
  },
  delete(id) {
    return api.delete(`${prefix}/${id}`);
  },
  cognitoSignUp(payload) {
    return api.post(`${prefix}/signup`, payload);
  },
  resendConfirmationCode(payload) {
    return api.post(`${prefix}/signup/resendConfirmationCode`, payload);
  },
  confirmCognitoSignUp(payload) {
    return api.post(`${prefix}/signup/confirm`, payload);
  },
  forgotPassword(payload) {
    return api.post(`${prefix}/forgotPassword`, payload);
  },
  confirmForgotPassword(payload) {
    return api.post(`${prefix}/confirmForgotPassword`, payload);
  },
};
export default UsersAPI;

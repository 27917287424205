import { Button, Form, Input, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import accessKeysApi from '../../../../api/aws-access-keys';
import openNotification from '../../../../components/Toastr';
import * as Actions from '../../../../redux/session/action';

const { Item } = Form;

function WalmartSellerAccount(props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { tab, session } = props;
  const [isLoading, setIsLoading] = useState(false);

  const saveCredentials = (credentials) => {
    setIsLoading(true);
    accessKeysApi
      .saveCredentials(
        session?.userInfo?.commercial_partner_id[0],
        tab,
        credentials
      )
      .then(() => {
        Modal.success({
          content: 'Se asoció la cuenta exitosamente',
        });
        form.resetFields();
        setIsLoading(false);
        dispatch(Actions.updateSellerAccountStatusSession(true));
      })
      .catch(() => {
        setIsLoading(false);
        openNotification({
          status: false,
          content: 'No se pudo validar la cuenta',
        });
      });
  };

  return (
    <Form
      name="walmart-form"
      form={form}
      onFinish={saveCredentials}
      style={{ width: '100%' }}
    >
      <Row>
        <span
          style={{
            fontSize: '18px',
            marginBottom: 10,
            marginTop: 15,
            width: '100%',
          }}
        >
          Client ID
        </span>
      </Row>
      <Row>
        <Item
          name="client_id"
          rules={[
            { required: true, message: 'Por favor ingresar Clave de API' },
          ]}
          style={{
            width: '100%',
            borderRadius: '10px',
          }}
        >
          <Input className="newModalConnectInput" />
        </Item>
      </Row>
      <Row>
        <span
          style={{
            fontSize: '18px',
            marginBottom: 10,
            marginTop: 15,
            width: '100%',
          }}
        >
          Secret Key
        </span>
      </Row>
      <Row>
        <Item
          name="secret_key"
          rules={[
            {
              required: true,
              message: 'Por favor ingresar el secret key',
            },
          ]}
          style={{
            width: '100%',
            borderRadius: '10px',
          }}
        >
          <Input className="newModalConnectInput" />
        </Item>
      </Row>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type="primary"
          style={{
            marginTop: 40,
            marginBottom: 15,
            padding: '5px 20px',
            height: '40px',
          }}
          onClick={() => form.submit()}
          loading={isLoading}
          disabled={isLoading}
        >
          Conectar marketplace
        </Button>
      </div>
    </Form>
  );
}

WalmartSellerAccount.propTypes = {
  tab: PropTypes.string.isRequired,
  session: PropTypes.instanceOf(Object).isRequired,
};

export default WalmartSellerAccount;

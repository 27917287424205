import api from './api';

const prefix = 'box';

const boxApi = {
  getByCompanyId(measureUnit, isPallet) {
    return api.get(`${prefix}/company/${measureUnit}`, {
      params: { isPallet },
    });
  },
  create(payload) {
    return api.post(`${prefix}`, payload);
  },
};

export default boxApi;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import * as queryString from 'query-string';
import { Card, Col } from 'antd';
import subscriptionAPI from '../../../api/subscription';
import companyAPI from '../../../api/company';
import * as Actions from '../../../redux/session/action';
import paymentPlanAPI from '../../../api/payment-plan';
import openNotification from '../../../components/Toastr';

function PayPalCheckout() {
  const { paymentPlanId, cancellingId } = queryString.parse(
    window.location.search
  );
  const [paymentPlan, setPaymentPlan] = useState();
  const companyId = useSelector(
    (store) => store.Session.session.userInfo.company_id[0]
  );

  useEffect(() => {
    const getPaymentPlan = async () => {
      try {
        const response = await paymentPlanAPI.get(paymentPlanId);
        setPaymentPlan(response.data);
      } catch (error) {
        openNotification({ status: false });
      }
    };
    if (!paymentPlan && paymentPlanId) {
      getPaymentPlan();
    }
  }, [paymentPlan]);

  const payPalButtonStyle = {
    layout: 'vertical',
    color: 'blue',
  };
  const dispatch = useDispatch();

  const onApproveSubscription = async (data, actions) => {
    const createSubscriptionPayload = {
      paymentPlanId: paymentPlan.id,
      externalId: data.subscriptionID,
      companyId,
      paymentMethod: 'PayPal',
    };

    const response = await subscriptionAPI.create(createSubscriptionPayload);
    await companyAPI.update(companyId, { active: true });
    if (cancellingId) await subscriptionAPI.cancel(cancellingId);
    const subscription = await subscriptionAPI.getMetadata(
      response.data.externalId,
      companyId
    );
    dispatch(Actions.subscribeSuccessfully(subscription.data));
    actions.subscription.activate();
    window.location = `/?subscriptionId=${subscription.data.externalId}`;
  };

  const createPaypalSubscription = (data, actions) => {
    return actions.subscription.create({
      plan_id: paymentPlan.externalId,
      amount: {
        currency_code: paymentPlan.currency,
        value: paymentPlan.price,
      },
    });
  };
  return (
    <Col span={12} offset={6} style={{ marginTop: 70 }}>
      <Card>
        <PayPalScriptProvider
          options={{
            'client-id': process.env.REACT_APP_PAYPAL_API_CLIENT_ID,
            vault: true,
            intent: 'subscription',
          }}
        >
          <PayPalButtons
            style={payPalButtonStyle}
            disabled={false}
            forceReRender={[
              paymentPlan?.price,
              paymentPlan?.currency,
              payPalButtonStyle,
            ]}
            createSubscription={createPaypalSubscription}
            onApprove={onApproveSubscription}
          />
        </PayPalScriptProvider>
      </Card>
    </Col>
  );
}

export default React.memo(PayPalCheckout);

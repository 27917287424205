export default function createReducer(initialState, handlers) {
  return function reducer(state, action) {
    let currentState = state;
    if (!state) {
      currentState = initialState;
    }
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action);
    }
    return currentState;
  };
}

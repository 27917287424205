import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Menu, Avatar, Spin, Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  AppstoreOutlined,
  ProfileOutlined,
  MessageOutlined,
  HomeOutlined,
  LogoutOutlined,
  IdcardOutlined,
  FolderOutlined,
  TeamOutlined,
  LoadingOutlined,
  UserOutlined,
  SendOutlined,
  ShoppingCartOutlined,
  InfoCircleOutlined,
  ReadOutlined,
  CodeSandboxOutlined,
  CalculatorOutlined,
} from '@ant-design/icons';
import { nameToSlug } from '../utils/functions';
import authApi from '../api/auth';
import * as Actions from '../redux/session/action';
import partnerApi from '../api/partner';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function MenuOptions({ widthMenu }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dashboardAnalitycs, setDashboardAnalitycs] = useState([]);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const session = useSelector((store) => store.Session.session);
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [urlActual] = useState('');
  const [menuKeys, setMenuKeys] = useState();

  const linkAdapte = (value) => {
    const link = String(value);
    const arrayBase = link.split(
      'https://datastudio.google.com/embed/reporting/'
    );
    const arrayFinal = arrayBase.splice(1);
    return arrayFinal[0];
  };

  const handleLogout = async () => {
    authApi.logout();
    await dispatch(Actions.logout());
    window.location = '/login';
  };

  const menuItems = [
    {
      label: t('menu.dashboards'),
      key: '/',
      icon: <AppstoreOutlined />,
      children: [
        {
          label: t('menu.summary'),
          key: '/',
        },
        {
          label: t('menu.sales'),
          key: '/ventas',
        },
        {
          label: t('menu.advertising'),
          key: '/publicidad',
        },
      ],
    },
    {
      label: t('menu.calculator'),
      key: '/calculadora',
      icon: <CalculatorOutlined />,
    },
    {
      label: t('menu.catalogue'),
      key: '/catalogo',
      icon: <ProfileOutlined />,
    },
    {
      label: t('menu.inventory'),
      key: '/inventario',
      icon: <CodeSandboxOutlined />,
    },
    {
      label: t('menu.saleOrders'),
      key: '/pedidos',
      icon: <ShoppingCartOutlined />,
    },
    {
      label: t('menu.shipments'),
      key: '/envios',
      icon: <SendOutlined />,
    },
    {
      label: t('menu.assistant'),
      key: '/asistente-regulaciones',
      icon: <MessageOutlined />,
    },
    {
      label: t('menu.helpCenter'),
      key: 'ayuda',
      icon: <InfoCircleOutlined />,
    },
  ];

  // only active accounts
  if (session?.companyActive) {
    menuItems.push({
      label: t('menu.lapAcademy'),
      key: '#',
      onClick: async () => {
        try {
          setShowLoadingModal(true);
          const learnWorldsResponse = await authApi.learnworlds({
            redirectUrl: 'https://lap-academy.getlearnworlds.com',
          });
          window.open(learnWorldsResponse.data.url, '_blank');
          setShowLoadingModal(false);
        } catch (err) {
          setShowLoadingModal(false);
        }
      },
      icon: <ReadOutlined />,
    });
  }

  useEffect(() => {
    if (session?.userInfo.website) {
      const validData = session?.userInfo.website.split(
        'https://datastudio.google.com/embed/reporting/'
      );
      if (validData[0] === '') {
        const valueArrayViejo = validData.splice(1);
        const newData = valueArrayViejo.map((data, index) => {
          let name;
          switch (index) {
            case index === 0:
              name = 'Ventas';
              break;
            case index === 1:
              name = 'Marketing';
              break;
            case index === 2:
              name = 'Reportes en vivo';
              break;
            default:
              name = 'NameDefault';
          }
          return {
            id: index,
            name,
            url: `https://datastudio.google.com/embed/reporting/${data}`,
          };
        });
        setDashboardAnalitycs(newData);
      } else {
        const link = session?.userInfo.website;
        const arrayBase = link.split('[');
        if (Array.isArray(arrayBase) && arrayBase?.length > 1) {
          try {
            const newJson = JSON.parse(`[${arrayBase[1]}`);
            setDashboardAnalitycs(newJson);
          } catch (_) {
            /* empty */
          }
        }
      }
    }
  }, []);

  const getLogo = () => {
    setLoading(true);
    partnerApi
      .findLogoById(session?.userInfo?.commercial_partner_id[0])
      .then((res) => {
        setLogo(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (session && session?.userInfo) {
      if (!logo) {
        getLogo();
      }
    }
    return () => {
      setLogo(null);
      setLoading(false);
    };
  }, [session]);

  return (
    <div className="contentMenuOptions">
      <p>{urlActual}</p>
      {!widthMenu.mobile && widthMenu.options && (
        <div style={{ marginTop: 9 }}>
          <Menu
            mode={widthMenu.orientation}
            theme="dark"
            defaultSelectedKeys={['/']}
            defaultOpenKeys={
              menuKeys ||
              setMenuKeys([
                '/',
                '/catalogo/visualizar',
                '/restock',
                '/couriers',
              ])
            }
            selectedKeys={[window.location.pathname]}
            style={{ marginLeft: 'auto', backgroundColor: '#010c33' }}
            inlineCollapsed={false}
            collapsible
          >
            {menuItems.map((item) => {
              let className = '';
              if (item.children && item.children.length > 0) {
                return (
                  <Menu.SubMenu
                    key={item.key}
                    icon={item.icon}
                    title={item.label}
                  >
                    {item.children.map((itemChild) => {
                      if (itemChild.label === t('menu.sales')) {
                        className = 'step2Dashboards';
                      } else if (itemChild.label === t('menu.advertising')) {
                        className = 'step4Dashboards';
                      }
                      return (
                        <Menu.Item
                          key={itemChild.key}
                          className={`${className}`}
                        >
                          <Link to={itemChild.key}>{itemChild.label}</Link>
                        </Menu.Item>
                      );
                    })}
                    {menuItems[0].label === item.label &&
                      dashboardAnalitycs.map((itemExtra) => [
                        <Menu.Item
                          key={`/data-studio/${nameToSlug(
                            itemExtra.name
                          )}/${linkAdapte(itemExtra.url)}`}
                        >
                          <Link
                            to={`/data-studio/${nameToSlug(
                              itemExtra.name
                            )}/${linkAdapte(itemExtra.url)}`}
                          >
                            {itemExtra.name}
                          </Link>
                        </Menu.Item>,
                      ])}
                  </Menu.SubMenu>
                );
              }
              if (item.label === t('menu.catalogue')) {
                className = 'step0FirstPost';
              } else if (item.label === t('menu.lapAcademy')) {
                className = 'step8FirstPost';
                return (
                  <Menu.Item
                    key={item.key}
                    icon={item.icon}
                    className={`${className}`}
                    disabled
                  >
                    <Link
                      to={item.key}
                      onClick={() => {
                        if (item.onClick) {
                          item.onClick();
                        }
                      }}
                    >
                      <div>
                        <span>{item.label}</span>
                        <span style={{ marginLeft: 5, fontSize: 10 }}>
                          ({t('menu.soon')}...)
                        </span>
                      </div>
                    </Link>
                  </Menu.Item>
                );
              }
              return (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  className={`${className}`}
                >
                  {item.label === t('menu.helpCenter') ? (
                    <a
                      href="https://ayuda.lapmarketplace.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.label}
                    </a>
                  ) : (
                    <Link
                      to={item.key}
                      onClick={() => {
                        if (item.onClick) {
                          item.onClick();
                        }
                      }}
                    >
                      {item.label}
                    </Link>
                  )}
                </Menu.Item>
              );
            })}
          </Menu>
        </div>
      )}
      {!widthMenu.mobile && !widthMenu.options && (
        <div style={{ marginTop: 30 }}>
          <Menu
            mode={widthMenu.orientation}
            theme="dark"
            defaultSelectedKeys={['/']}
            selectedKeys={[window.location.pathname]}
            style={{ marginLeft: 'auto', backgroundColor: '#010c33' }}
            inlineCollapsed={false}
          >
            {menuItems.map((item) => {
              if (item.children && item.children.length > 0) {
                return (
                  <Menu.SubMenu
                    key={item.key}
                    icon={item.icon}
                    title={item.label}
                  >
                    {item.children.map((itemChild) => (
                      <Menu.Item key={itemChild.key}>
                        <Link to={itemChild.key}>{itemChild.label}</Link>
                      </Menu.Item>
                    ))}
                    {menuItems[0].label === item.label &&
                      dashboardAnalitycs.map((itemExtra) => [
                        <Menu.Item
                          key={`/data-studio/${nameToSlug(
                            itemExtra.name
                          )}/${linkAdapte(itemExtra.url)}`}
                        >
                          <Link
                            to={`/data-studio/${nameToSlug(
                              itemExtra.name
                            )}/${linkAdapte(itemExtra.url)}`}
                          >
                            {itemExtra.name}
                          </Link>
                        </Menu.Item>,
                      ])}
                  </Menu.SubMenu>
                );
              }
              if (item.label === t('menu.lapAcademy')) {
                return (
                  <Menu.Item
                    key={item.key}
                    icon={item.icon}
                    className="step8FirstPost"
                    disabled
                  >
                    <Link
                      to={item.key}
                      onClick={() => {
                        if (item.onClick) {
                          item.onClick();
                        }
                      }}
                    >
                      <div>
                        <span>{item.label}</span>
                        <span style={{ marginLeft: 5, fontSize: 10 }}>
                          ({t('menu.soon')}...)
                        </span>
                      </div>
                    </Link>
                  </Menu.Item>
                );
              }
              return (
                <Menu.Item key={item.key} icon={item.icon}>
                  {item.label === t('menu.helpCenter') ? (
                    <a
                      href="https://ayuda.lapmarketplace.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.label}
                    </a>
                  ) : (
                    <Link
                      to={item.key}
                      onClick={() => {
                        if (item.onClick) {
                          item.onClick();
                        }
                      }}
                    >
                      {item.label}
                    </Link>
                  )}
                </Menu.Item>
              );
            })}
            {session?.userInfo.isAdmin && (
              <>
                <Menu.Item key="/clientes">
                  <Link to="/clientes">
                    <TeamOutlined /> {t('menu.clients')}
                  </Link>
                </Menu.Item>
                <Menu.Item key="/status">
                  <Link to="/status">
                    <IdcardOutlined /> {t('menu.status')}
                  </Link>
                </Menu.Item>
              </>
            )}
          </Menu>
        </div>
      )}

      {widthMenu.mobile && (
        <div style={{ marginTop: 9 }}>
          <Menu
            mode={widthMenu.orientation}
            theme="dark"
            defaultSelectedKeys={['/']}
            selectedKeys={[window.location.pathname]}
            style={{ marginLeft: 'auto', backgroundColor: '#010c33' }}
            inlineCollapsed={false}
          >
            {menuItems.map((item) => {
              if (item.children && item.children.length > 0) {
                return (
                  <Menu.SubMenu
                    key={item.key}
                    icon={item.icon}
                    title={item.label}
                  >
                    {item.children.map((itemChild) => (
                      <Menu.Item key={itemChild.key}>
                        <Link
                          to={itemChild.key}
                          onClick={() => {
                            if (itemChild.onClick) {
                              itemChild.onClick();
                            }
                          }}
                        >
                          {itemChild.label}
                        </Link>
                      </Menu.Item>
                    ))}
                    {menuItems[0].label === item.label &&
                      dashboardAnalitycs.map((itemExtra) => [
                        <Menu.Item
                          key={`/data-studio/${nameToSlug(
                            itemExtra.name
                          )}/${linkAdapte(itemExtra.url)}`}
                        >
                          <Link
                            to={`/data-studio/${nameToSlug(
                              itemExtra.name
                            )}/${linkAdapte(itemExtra.url)}`}
                          >
                            {itemExtra.name}
                          </Link>
                        </Menu.Item>,
                      ])}
                  </Menu.SubMenu>
                );
              }
              if (item.label === t('menu.lapAcademy')) {
                return (
                  <Menu.Item
                    key={item.key}
                    icon={item.icon}
                    className="step8FirstPost"
                    disabled
                  >
                    <Link
                      to={item.key}
                      onClick={() => {
                        if (item.onClick) {
                          item.onClick();
                        }
                      }}
                    >
                      <div>
                        <span>{item.label}</span>
                        <span style={{ marginLeft: 5, fontSize: 10 }}>
                          ({t('menu.soon')}...)
                        </span>
                      </div>
                    </Link>
                  </Menu.Item>
                );
              }
              return (
                <Menu.Item key={item.key} icon={item.icon}>
                  <Link to={item.key}>{item.label}</Link>
                </Menu.Item>
              );
            })}
            <Menu.SubMenu
              style={{ marginTop: 80 }}
              key="#1"
              title={
                <>
                  {loading && (
                    <Spin indicator={antIcon}>
                      <Avatar icon={<UserOutlined />} />{' '}
                    </Spin>
                  )}
                  {!loading && logo && (
                    <Avatar
                      style={{ marginRight: 5 }}
                      src={`data:image/png;base64,${logo}`}
                    />
                  )}
                  {!loading && !logo && (
                    <Avatar
                      style={{ marginRight: 5 }}
                      icon={<UserOutlined />}
                    />
                  )}{' '}
                  {t('menu.myAccount')}
                </>
              }
            >
              {!session?.userInfo?.isAdmin ? (
                <>
                  <Menu.Item key="/my-account">
                    <Link to="/my-account">
                      <HomeOutlined /> {t('menu.myAccount')}
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/my-documents">
                    <Link to="/my-documents">
                      <FolderOutlined /> {t('menu.my_documents')}
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/soporte">
                    <Link to="/soporte">
                      <MessageOutlined /> {t('menu.support')}
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key=""
                    style={{ marginTop: 30 }}
                    onClick={handleLogout}
                  >
                    <Link to="/login">
                      <LogoutOutlined /> {t('navMenu.logout')}
                    </Link>
                  </Menu.Item>
                </>
              ) : (
                <>
                  <Menu.Item key="/soporte">
                    <Link to="/soporte">
                      <MessageOutlined /> {t('menu.support')}{' '}
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key=""
                    style={{ marginTop: 30 }}
                    onClick={handleLogout}
                  >
                    <Link to="/#">
                      <LogoutOutlined /> {t('navMenu.logout')}
                    </Link>
                  </Menu.Item>
                </>
              )}
            </Menu.SubMenu>
            {session?.userInfo.isAdmin && (
              <>
                <Menu.Item key="/clientes">
                  <Link to="/clientes">
                    <TeamOutlined /> {t('menu.clients')}
                  </Link>
                </Menu.Item>
                <Menu.Item key="/status">
                  <Link to="/status">
                    <IdcardOutlined /> {t('menu.status')}
                  </Link>
                </Menu.Item>
              </>
            )}
          </Menu>
        </div>
      )}

      <Modal
        open={showLoadingModal}
        centered
        width={200}
        footer={null}
        closable={false}
        style={{ textAlignVertical: 'center', textAlign: 'center' }}
        className="loadingModal"
      >
        <h3 className="title-primary">{t('menu.redirecting')}</h3>
        <Spin spinning />
      </Modal>
    </div>
  );
}

MenuOptions.propTypes = {
  widthMenu: PropTypes.shape({
    mobile: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        path: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    orientation: PropTypes.string,
  }).isRequired,
};

export default MenuOptions;

import React, { useEffect, useRef, useState } from 'react';
import {
  Input,
  Button,
  List,
  Layout,
  Col,
  Row,
  Typography,
  Menu,
  Avatar,
  Card,
} from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import {
  LoadingOutlined,
  PlusOutlined,
  SendOutlined,
  UserOutlined,
} from '@ant-design/icons';
import openAIAPI from '../../api/openai';
import openNotification from '../../components/Toastr';
import { getErrorMessage } from '../../api/api';
import Message from './components/Message';
import './assistant.css';

const { TextArea } = Input;

function Assistant() {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(undefined);
  const [newRun, setNewRun] = useState(null);
  const [threads, setThreads] = useState();
  const [selectedThread, setSelectedThread] = useState(null);
  const [threadStatus, setThreadStatus] = useState(0);
  const [selectedMenuKeys, setSelectedMenuKeys] = useState([]);
  const infiniteScrollRef = useRef();

  const { t } = useTranslation();

  let intervalId;

  const scrollToBottom = () => {
    if (infiniteScrollRef.current) {
      const scrollContainer = infiniteScrollRef.current.scrollComponent;

      const dummyElement = document.createElement('div');
      scrollContainer.appendChild(dummyElement);

      dummyElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      scrollContainer.removeChild(dummyElement);
    }
  };

  const getCompanyThreads = async () => {
    try {
      const response = await openAIAPI.listCompanyThreads();
      const items = response.data
        .map((thread, index) => ({
          key: thread.threadId,
          label: `${t('assistant.chat')} ${index + 1}`,
          ...thread,
        }))
        .reverse();
      const newThread = {
        key: 'new',
        label: t('assistant.newChat'),
        icon: <PlusOutlined />,
      };
      setThreads([newThread, ...items]);
    } catch (error) {
      openNotification({
        status: false,
        content: t('assistant.errors.get'),
      });
      setThreads([]);
    }
  };

  const newThread = async () => {
    try {
      const { data } = await openAIAPI.createThread();
      const newThreads = [
        threads[0],
        {
          key: data.threadId,
          label: `${t('assistant.chat')} ${threads.length}`,
          ...data,
        },
        ...threads.slice(1),
      ];
      setThreads(newThreads);
      setSelectedThread(data);
      setSelectedMenuKeys([data.threadId]);
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
  };

  const handleSendMessage = async (guidedMessage = null) => {
    if (!guidedMessage && inputValue.trim() === '') return;
    setLoading(true);
    await setMessages([
      ...messages,
      {
        content: guidedMessage || inputValue,
        sender: 'user',
        contentType: 'text',
      },
      { sender: 'assistant', contentType: 'text', content: '' },
    ]);
    setInputValue('');
    try {
      if ([2, 3].includes(threadStatus)) {
        const key =
          threadStatus === 2 ? 'countryOfManufacture' : 'destinationCountry';
        const response = await openAIAPI.updateThreadMetadata(
          selectedThread.threadId,
          {
            key,
            value: inputValue,
          }
        );
        setSelectedThread(response.data);
        setLoading(false);
      } else {
        const { data } = await openAIAPI.addMessageToThread(
          selectedThread.threadId,
          {
            message: guidedMessage || inputValue,
            ...(guidedMessage ? { guidedMessage: true } : {}),
          }
        );
        if (!data) throw new Error(t('assistant.errors.send'));
        setNewRun(data);
      }
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
  };

  const createGuidedMessage = async () => {
    const metadata = selectedThread.guidedThreadMetadata;
    const message = t('assistant.request', {
      topic: metadata.topic.value,
      productType: metadata.productType.value,
      countryOfManufacture: metadata.countryOfManufacture.value,
      destinationCountry: metadata.destinationCountry.value,
    });
    await handleSendMessage(message);
  };

  const handleStatus = (threadMessages, status) => {
    switch (status) {
      case 0:
        setMessages([
          ...threadMessages,
          {
            sender: 'assistant',
            content: [
              {
                key: t('assistant.options.regulations'),
                label: t('assistant.options.regulations'),
              },
              {
                key: t('assistant.options.docs'),
                label: t('assistant.options.docs'),
              },
              {
                key: t('assistant.options.labelling'),
                label: t('assistant.options.labelling'),
              },
            ],
            contentType: 'options',
          },
        ]);
        break;
      case 1:
        setMessages([
          ...threadMessages,
          {
            sender: 'assistant',
            content: [
              {
                key: t('assistant.options.food'),
                label: t('assistant.options.food'),
              },
              {
                key: t('assistant.options.textiles'),
                label: t('assistant.options.textiles'),
              },
              {
                key: t('assistant.options.electro'),
                label: t('assistant.options.electro'),
              },
              {
                key: t('assistant.options.supplements'),
                label: t('assistant.options.supplements'),
              },
              {
                key: t('assistant.options.cosmetics'),
                label: t('assistant.options.cosmetics'),
              },
              {
                key: t('assistant.options.fertilizers'),
                label: t('assistant.options.fertilizers'),
              },
              {
                key: t('assistant.options.children'),
                label: t('assistant.options.children'),
              },
              {
                key: t('assistant.options.alcohol'),
                label: t('assistant.options.alcohol'),
              },
              {
                key: t('assistant.options.jewelry'),
                label: t('assistant.options.jewelry'),
              },
            ],
            contentType: 'options',
          },
        ]);
        break;
      default:
        setMessages(threadMessages);
        scrollToBottom();
    }
  };

  const setThreadMessages = async (id, status) => {
    const { data } = await openAIAPI.listThreadMessages(id);
    const threadMessages = data.map((m) => {
      return {
        sender: m.role === 'user' ? 'user' : 'assistant',
        content: m.content?.[0]?.text?.value,
        contentType: 'text',
      };
    });
    handleStatus(threadMessages, status);
  };

  const getThreadStatus = async () => {
    const metadata = selectedThread.guidedThreadMetadata;
    if (metadata.requestCompleted) return 5;
    if (metadata.destinationCountry) return 4;
    if (metadata.countryOfManufacture) return 3;
    if (metadata.productType) return 2;
    if (metadata.topic) return 1;
    return 0;
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const getRunStatus = async (runId) => {
    setLoading(true);
    try {
      const { data } = await openAIAPI.checkRunStatus(
        selectedThread.threadId,
        runId
      );
      if (['completed', 'failed'].includes(data)) {
        if (threadStatus === 4) {
          setThreadStatus(5); // Freestyle status
          setThreadMessages(selectedThread.threadId, 5);
        } else {
          await setThreadMessages(selectedThread.threadId, threadStatus);
        }
        clearInterval(intervalId);
        setLoading(false);
      }
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
  };

  const handleMenuItemSelected = async (item) => {
    if (item.key === 'new') {
      await newThread();
    } else {
      await getCompanyThreads();
      const thread = await threads.find((e) => e.threadId === item.key);
      setSelectedThread(thread);
      setSelectedMenuKeys([item.key]);
      setMessages([]);
    }
  };

  useEffect(() => {
    getCompanyThreads();
  }, []);

  useEffect(() => {
    if (!newRun) return;
    scrollToBottom();
    intervalId = setInterval(() => getRunStatus(newRun), 5000);
    setTimeout(() => clearInterval(intervalId), 300000);
  }, [newRun]);

  useEffect(async () => {
    if (selectedThread) {
      const status = await getThreadStatus();
      await setThreadStatus(status);
      await setThreadMessages(selectedThread.threadId, status);
      scrollToBottom();
    }
  }, [selectedThread]);

  useEffect(() => {
    if (threadStatus === 4) createGuidedMessage();
  }, [threadStatus]);

  return (
    <Row className="assistant-container" justify="center">
      <Card className="chat-card">
        <Layout className="chat-layout">
          <Header className="header" align="middle">
            <Col span={24}>
              <Row className="title-container" align="middle">
                <Avatar
                  className="title-avatar"
                  size={54}
                  icon={<UserOutlined />}
                />
                <Typography.Text>{t('assistant.title')}</Typography.Text>
              </Row>
            </Col>
          </Header>
          <Content className="content-container">
            <Row>
              <Col className="threads-container" id="threadsList" span={5}>
                {threads && (
                  <InfiniteScroll
                    dataLength={threads.length}
                    hasMore={threads.length < 50}
                    scrollableTarget="threadsList"
                  >
                    <Menu
                      className="threads-menu"
                      items={threads}
                      onSelect={handleMenuItemSelected}
                      selectedKeys={selectedMenuKeys}
                    />
                  </InfiniteScroll>
                )}
              </Col>
              <Col
                id="messagesList"
                span={19}
                style={{ height: '400px', overflowY: 'auto' }}
              >
                <InfiniteScroll
                  dataLength={messages.length}
                  hasMore={messages.length < 50}
                  scrollableTarget="messagesList"
                  ref={infiniteScrollRef}
                >
                  <List
                    dataSource={messages}
                    style={{ padding: 30 }}
                    locale={{ emptyText: <Row /> }}
                    renderItem={(message) => (
                      <Message
                        message={message}
                        threadId={selectedThread.threadId}
                        status={threadStatus}
                        setSelectedThread={setSelectedThread}
                        setLoading={setLoading}
                      />
                    )}
                  />
                </InfiniteScroll>
              </Col>
            </Row>
          </Content>

          <Footer className="footer-container">
            <Row>
              <Col span={5} />
              <Col span={19}>
                <Row>
                  <Col className="text-area-container" span={23}>
                    <TextArea
                      rows={4}
                      value={inputValue}
                      onChange={handleInputChange}
                      onPressEnter={() => handleSendMessage()}
                      autoSize
                      disabled={loading || [0, 1].includes(threadStatus)}
                    />
                  </Col>
                  <Col span={1} style={{ paddingLeft: 10 }}>
                    <Button
                      type="text"
                      size="large"
                      icon={
                        loading ? (
                          <LoadingOutlined />
                        ) : (
                          <SendOutlined size="large" />
                        )
                      }
                      onClick={() => handleSendMessage()}
                      disabled={loading}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Footer>
        </Layout>
      </Card>
    </Row>
  );
}

export default Assistant;

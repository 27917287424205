import api from './api';

const prefix = 'inventory';

export default {
  getByCompanyId() {
    return api.get(`${prefix}/inventory-grouping-marketplaces`);
  },
  saveGroupingMarketplaces(body) {
    return api.post(`${prefix}/inventory-grouping-marketplaces`, body);
  },
  inactiveInventoryGroup(id) {
    return api.patch(`${prefix}/inventory-grouping/${id}/inactive`);
  },
};

import * as types from './types';

export const getMe = (data) => ({
  type: types.GET_SESSION_ME,
  payload: data,
});

export const login = () => ({ type: types.LOGIN });

export const logout = () => ({ type: types.LOGOUT });

export const loginSuccess = (data) => ({
  type: types.LOGIN_SUCCESS,
  payload: data,
});

export const loginFailed = (data) => ({
  type: types.LOGIN_FAILED,
  payload: data,
});

export const refreshSession = (data) => ({
  type: types.REFRESH_SESSION,
  payload: data,
});

export const updateSellerAccountStatusSession = (data) => ({
  type: types.UPDATE_STATE_SELLER_ACCOUNT,
  payload: data,
});

export const updateAdsAccountStatusSession = (data) => ({
  type: types.UPDATE_STATE_ADS_ACCOUNT,
  payload: data,
});

export const updateUserInfo = (data) => ({
  type: types.UPDATE_USER_INFO,
  payload: data,
});

export const updateUserInfoNewCompany = (data) => ({
  type: types.UPDATE_USER_INFO_NEW_COMPANY,
  payload: data,
});

export const subscribeSuccessfully = (data) => ({
  type: types.SUBSCRIBE_SUCCESSFULLY,
  payload: data,
});

export const cancelSubscription = (data) => ({
  type: types.CANCEL_SUBSCRIPTION,
  payload: data,
});

export const updateLanguague = (data) => ({
  type: types.UPDATE_LANGUAGE,
  payload: data,
});

import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RevealEyeButton from '../../../../../components/RevealEyeButton';

function Fulfill99Form({ loading, name, onFinish, courierCredentials }) {
  const [form] = useForm();
  const [credentials, setCredentials] = useState(undefined);
  const [showSecret, setShowSecret] = useState(false);

  useEffect(() => {
    if (courierCredentials && courierCredentials.credentials) {
      setCredentials({
        client_id: courierCredentials.credentials.client_id,
        client_secret: courierCredentials.credentials.client_secret,
      });
    }
  }, [courierCredentials]);

  useEffect(() => {
    if (credentials) {
      form.setFieldsValue({
        client_id: credentials.client_id,
        client_secret: credentials.client_secret,
      });
    }
  }, [credentials]);

  return (
    <Form
      form={form}
      name={`${name}-form`}
      layout="vertical"
      labelCol={{
        span: 8,
        offset: 0,
      }}
      wrapperCol={{
        span: 20,
        offset: 0,
      }}
      initialValues={{
        remember: true,
        fbm: false,
        fbo: true,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <h1
        style={{
          fontWeight: 600,
          marginBottom: 40,
          marginTop: 15,
        }}
      >
        Conectar cuenta Fulfill99
      </h1>

      <span
        style={{
          paddingLeft: 25,
          maxWidth: '100%',
          textAlign: 'left',
          fontSize: '18px',
          marginBottom: 30,
        }}
      >
        Client Id
      </span>
      <Form.Item
        name="client_id"
        rules={[
          {
            required: true,
            message: 'Ingrese su cliente id de Fulfill99',
          },
        ]}
        style={{
          fontSize: '18px',
          maxWidth: '100%',
          paddingLeft: 25,
          paddingRight: 25,
        }}
      >
        <Input
          defaultValue={credentials ? credentials.client_id : ''}
          onChange={(e) => form.setFieldValue('client_id', e.target.value)}
          size="large"
          style={{ borderRadius: 10, marginTop: 15 }}
        />
      </Form.Item>

      <span
        style={{
          paddingLeft: 25,
          maxWidth: '100%',
          textAlign: 'left',
          fontSize: '18px',
          marginBottom: 30,
        }}
      >
        Client Secret
      </span>
      <Form.Item
        name="client_secret"
        rules={[
          {
            required: true,
            message: 'Ingrese su cliente secret de Fulfill99',
          },
        ]}
        style={{
          fontSize: '18px',
          maxWidth: '100%',
          paddingLeft: 25,
          paddingRight: 25,
        }}
      >
        <Input
          defaultValue={credentials ? credentials.client_secret : ''}
          type={showSecret ? 'text' : 'password'}
          onChange={(e) => form.setFieldValue('client_secret', e.target.value)}
          size="large"
          style={{ borderRadius: 10, marginTop: 15 }}
        />
        <div style={{ position: 'absolute', right: '-35px', top: '33%' }}>
          <RevealEyeButton
            onClick={() => setShowSecret(!showSecret)}
            show={showSecret}
          />
        </div>
      </Form.Item>

      <Form.Item
        label="El courier es FBM?"
        name="fbm"
        style={{ display: 'none' }}
      />

      <Form.Item
        label="El courier es FBO?"
        name="fbo"
        style={{ display: 'none' }}
      />

      <Form.Item
        wrapperCol={{
          span: 24,
        }}
        style={{
          marginTop: 40,
          textAlign: 'center',
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{
            height: '50px',
            width: '30%',
          }}
        >
          {courierCredentials ? 'Actualizar' : 'Conectar'}
        </Button>
      </Form.Item>
    </Form>
  );
}
Fulfill99Form.propTypes = {
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  courierCredentials: PropTypes.objectOf(Object).isRequired,
};

export default Fulfill99Form;

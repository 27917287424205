import api from './api';

const prefix = 'auth';

export default {
  login(credentials) {
    return api.post(`${prefix}/token`, credentials);
  },
  learnworlds(params) {
    return api.post(`${prefix}/learnworlds`, params);
  },
  resetPassword(userId, passwords) {
    return api.put(`${prefix}/${userId}/resetPassword`, passwords);
  },
  sendRecoveryEmail(email) {
    return api.post(`${prefix}/sendRecoverEmail`, email);
  },
  logout() {
    return api.get(`${prefix}/logout`);
  },
};

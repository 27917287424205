import { EyeOutlined } from '@ant-design/icons';
import { Table, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Text } = Typography;

function GroupedMarketplaceTable({
  handleProductPriceChange,
  pagination,
  expandable,
  renderProductColumn,
  loading,
  filteredParentProducts,
  rowKeys,
  handleSingleSelection,
}) {
  const { Paragraph } = Typography;

  const rowSelection = {
    type: 'checkbox',
    getCheckboxProps: (record) => {
      const checkboxProps = {};
      if (record.isVariant) {
        checkboxProps.disabled = true;
      }
      return checkboxProps;
    },
    hideSelectAll: true,
    selectedRowKeys: rowKeys,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowsKeys, selectedRows, info) => {
      if (info.type === 'single') {
        handleSingleSelection(selectedRows);
      }
    },
  };

  const groupedColumns = [
    {
      title: 'SKU',
      ellipsis: true,
      dataIndex: ['defaultCode'],
      key: 'id',
      width: '20%',
      render: (value) => (
        <Tooltip title={value}>
          <Text style={{ fontSize: 12 }}>{value}</Text>
        </Tooltip>
      ),
    },
    {
      title: 'Producto',
      dataIndex: ['title'],
      key: 'title',
      align: 'center',
      ellipsis: true,
      width: '45%',
      render: (value, record) => renderProductColumn(value, record),
    },
    {
      title: 'Precio',
      dataIndex: ['price'],
      key: 'id',
      align: 'center',
      width: '10%',
      ellipsis: false,
      render: (value, record) => {
        if (record.marketplaces?.includes('Edicommerce')) {
          return (
            <Paragraph
              editable={{
                tooltip: 'click to edit text',
                onChange: (e) => handleProductPriceChange(e, record),
                triggerType: 'text',
              }}
              className="labelToInput"
            >
              {record.price}
            </Paragraph>
          );
        }
        if (
          !record.isVariant &&
          record.children.length > 0 &&
          value === '0.00'
        ) {
          return '-';
        }
        return value;
      },
    },
    {
      title: 'Variantes',
      dataIndex: ['children'],
      key: 'id',
      width: '10%',
      align: 'center',
      ellipsis: false,
      render: (value, record) => {
        if (record.isVariant) {
          return '-';
        }
        return value?.length;
      },
    },
    {
      title: 'Marketplaces',
      dataIndex: ['marketplaces'],
      key: 'id',
      width: '20%',
      align: 'center',
      ellipsis: false,
      render: (value) => {
        const tooltip = value?.join(', ');
        return (
          <div>
            <span>{value?.length || 0}</span>
            &nbsp;&nbsp;
            <Tooltip title={`${tooltip}`}>
              <EyeOutlined />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      dataSource={filteredParentProducts}
      loading={loading}
      columns={groupedColumns}
      pagination={{
        ...pagination,
      }}
      expandable={{
        ...expandable,
      }}
      rowSelection={{
        ...rowSelection,
      }}
      showHeader
      scroll={{ x: '100%' }}
    />
  );
}

GroupedMarketplaceTable.propTypes = {
  pagination: PropTypes.objectOf(Object).isRequired,
  expandable: PropTypes.objectOf(Object).isRequired,
  renderProductColumn: PropTypes.func.isRequired,
  filteredParentProducts: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool.isRequired,
  handleProductPriceChange: PropTypes.func.isRequired,
  rowKeys: PropTypes.instanceOf(Array).isRequired,
  handleSingleSelection: PropTypes.func.isRequired,
};

export default GroupedMarketplaceTable;

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Select,
  Spin,
  InputNumber,
  Popconfirm,
  Alert,
} from 'antd';
import { SaveFilled, WarningOutlined, RedoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import openNotification from '../../../components/Toastr';
import { getErrorMessage } from '../../../api/api';
import listingAPI from '../../../api/listing';
import 'antd/dist/antd.min.css';
import { sellerMarketplaces } from '../../../utils/const';

const antIcon = <RedoOutlined spin />;

function FBMModal({
  visible,
  setVisible,
  setLoadingSyncStock,
  loadingSyncStock,
  clientListings,
  refreshMethod,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [productMarkets, setProductMarkets] = useState([]);

  useEffect(() => {
    if (visible?.product?.defaultCode && visible?.clientListings?.length > 0) {
      const marketplaces = [];
      let productMkts = [];
      Object.values(clientListings)?.forEach((listing) => {
        Object.values(listing?.products)?.forEach((product) => {
          if (
            product?.defaultCode === visible?.product?.defaultCode &&
            product.marketplace
          ) {
            marketplaces.push(product.marketplace);
          }
        });
      });
      productMkts = [...new Set(marketplaces)];
      setProductMarkets(productMkts);
      form.setFieldsValue({
        marketplacesToUpdate: productMkts,
        quantity: visible?.product?.inventory?.availableStock || 0,
      });
    }
  }, [visible]);

  const handleCancelModal = () => {
    setVisible({ value: false, data: null });
  };

  const syncFbmStockByListing = async (data) => {
    const values = {
      sku: visible.product.defaultCode,
      ...data,
    };
    setLoadingSyncStock({
      loading: true,
      listingIndex: visible.product.productIndex,
    });
    try {
      await listingAPI.syncFbmStock(values);
      refreshMethod();
      openNotification({
        status: true,
        content: t('home.listing.modalFBM.syncStock.success'),
      });
    } catch (error) {
      openNotification({
        status: false,
        content: getErrorMessage(error),
        duration: 12,
      });
    }
    setLoadingSyncStock({ loading: false, listingIndex: null });
  };

  const onSubmit = (values) => {
    syncFbmStockByListing(values);
  };

  return (
    <div>
      <Modal
        title={visible?.title}
        visible={visible?.value}
        onCancel={handleCancelModal}
        width={800}
        centered
        destroyOnClose
        footer={[
          <Button key="cancel" onClick={handleCancelModal}>
            {t('common.close')}
          </Button>,
          <Popconfirm
            title={t('home.listing.modalFBM.syncStock.warning')}
            onConfirm={() => form.submit()}
            onCancel={() => {}}
            icon={<WarningOutlined />}
            okText={t('yes')}
            cancelText={t('no')}
            okButtonProps={{ style: { backgroundColor: '#5365E3' } }}
          >
            <Button
              type="text"
              icon={
                loadingSyncStock.loading ? (
                  <Spin indicator={antIcon} />
                ) : (
                  <SaveFilled />
                )
              }
            >
              {t('common.update')}
            </Button>
          </Popconfirm>,
        ]}
      >
        {productMarkets?.includes(sellerMarketplaces.EBAY) && (
          <Alert
            style={{ marginBottom: 20 }}
            message={t('home.listing.modalFBM.ebayWarning')}
            type="warning"
            showIcon
          />
        )}
        <Form
          name="formulario"
          form={form}
          onFinish={onSubmit}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="quantity"
                rules={[{ required: true }]}
                label={t('home.listing.stock')}
              >
                <InputNumber
                  min={0}
                  style={{ width: '100%' }}
                  placeholder={t('common.quantity')}
                />
              </Form.Item>
            </Col>
            {productMarkets?.length > 0 && (
              <Col span={24}>
                <Form.Item
                  name="marketplacesToUpdate"
                  rules={[{ required: true }]}
                  label={t('home.listing.modalFBM.marketplaces')}
                  tooltip={t('home.listing.modalFBM.marketplacesDescription')}
                >
                  <Select mode="multiple" style={{ width: '100%' }}>
                    {productMarkets.map((item) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

FBMModal.propTypes = {
  visible: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    product: PropTypes.shape({
      defaultCode: PropTypes.string.isRequired,
      productIndex: PropTypes.string,
      inventory: PropTypes.shape({
        availableStock: PropTypes.number,
      }),
      marketplace: PropTypes.string,
    }),
    clientListings: PropTypes.arrayOf(
      PropTypes.shape({
        products: PropTypes.arrayOf(
          PropTypes.shape({
            defaultCode: PropTypes.string.isRequired,
            marketplace: PropTypes.string.isRequired,
          })
        ),
      })
    ).isRequired,
  }).isRequired,
  setVisible: PropTypes.func.isRequired,
  setLoadingSyncStock: PropTypes.shape({
    defaultCode: PropTypes.string.isRequired,
    marketplace: PropTypes.string,
  }).isRequired,
  loadingSyncStock: PropTypes.shape({
    loading: PropTypes.bool,
  }).isRequired,
  clientListings: PropTypes.arrayOf(
    PropTypes.shape({
      products: PropTypes.shape({
        defaultCode: PropTypes.string.isRequired,
        marketplace: PropTypes.string,
      }),
    })
  ).isRequired,
  refreshMethod: PropTypes.func.isRequired,
};

export default FBMModal;

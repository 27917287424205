import api from './api';

const prefix = '/warehouses';
const endpoints = {
  getCompanyWarehouses: (limit, page, companyId) => {
    const params = {
      take: limit,
      page,
      companyId,
    };
    return api.get(`${prefix}/find/paginate`, { params });
  },
  getWarehouse: (warehouseId) => api.get(`${prefix}/${warehouseId}`),
  createWarehouse: (payload) => api.post(prefix, payload),
  updateWarehouse: (payload, warehouseId) => {
    return api.put(`${prefix}/${warehouseId}`, payload);
  },
  delete: (id) => api.delete(`${prefix}/${id}`),
};
export default endpoints;

import { AreaMap } from '@ant-design/maps';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function Map({ country }) {
  const { t } = useTranslation();
  const color = ['rgb(232, 232, 232)', 'rgb(150, 150, 150)', 'rgb(1, 12, 51)'];
  const config = {
    map: {
      type: 'mapbox',
      style: 'blank',
      center: [120.19382669582967, 30.258134],
      zoom: 2,
      pitch: 0,
    },
    source: {
      data: country,
      parser: {
        type: 'geojson',
      },
    },
    autoFit: true,
    color: {
      field: 'density',
      value: color,
      scale: {
        type: 'quantile',
      },
    },
    style: {
      opacity: 1,
      stroke: 'rgb(93,112,146)',
      lineType: 'dash',
      lineDash: [2, 2],
      lineWidth: 0.6,
      lineOpacity: 1,
    },
    state: {
      active: true,
      select: true,
    },
    tooltip: {
      items: ['name', 'orders'],
    },
    zoom: {
      position: 'bottomright',
      formatter: '',
    },
  };

  return Object.keys(country).length > 0 ? (
    <AreaMap
      map={config.map}
      color={config.color}
      source={config.source}
      autoFit={config.autoFit}
      style={config.style}
      state={config.state}
      tooltip={config.tooltip}
      zoom={config.zoom}
    />
  ) : (
    <p style={{ marginTop: '15%', marginLeft: '20%' }}>
      {t('home.charts.emptyCountryData')}
    </p>
  );
}

Map.propTypes = {
  country: PropTypes.arrayOf(Object).isRequired,
};

export default Map;

import api from './api';

const prefix = '/orders-sales';

export default {
  getByFilter(values) {
    return api.put(`${prefix}/filters`, values);
  },

  getHistorical(partnerId) {
    return api.get(`${prefix}/partner/${partnerId}/historical`);
  },

  getHistoricalByAllPartners() {
    return api.get(`${prefix}/partners/historical`);
  },

  cancelSyncOrders(partnerId) {
    return api.delete(`${prefix}/partner/${partnerId}/historical`);
  },

  getHistoricalSyncStatus(partnerId) {
    return api.get(`${prefix}/partner/${partnerId}/historical/status`);
  },

  saveSaleOrder(values) {
    return api.post(`${prefix}`, values);
  },

  saveFulfillmentOutbound(values) {
    return api.post(`${prefix}/fulfillmentOutbound`, values);
  },

  updateFBAFulfillmentOutbound(values) {
    return api.put(
      `${prefix}/fulfillmentOutbound/FBA/${values?.shipment?.sellerFulfillmentOrderId}`,
      values
    );
  },

  cancelFBAFulfillmentOutbound(values) {
    return api.put(
      `${prefix}/fulfillmentOutbound/FBA/${values?.shipment?.sellerFulfillmentOrderId}/cancel`,
      values
    );
  },

  getCancellationReason(mkt) {
    return api.get(`${prefix}/marketplace/${mkt}/cancellationReason`);
  },

  updateSaleOrder(id, values) {
    return api.put(`${prefix}/${id}`, values);
  },

  getSaleOrder(id) {
    return api.get(`${prefix}/${id}`);
  },

  getProductsByOrderId(id) {
    return api.get(`${prefix}/orderProducts/${id}`);
  },

  getShipmentServices(values) {
    return api.put(`${prefix}/shipmentServices`, values);
  },

  getByOrderId(values) {
    return api.get(
      `${prefix}/${values?.orderId}/partner/${values?.partnerId}/marketplace/${values?.marketplace}`
    );
  },

  getSenderAddressByMarketplace(values) {
    return api.get(
      `${prefix}/${values?.orderId}/addresses/partner/${values?.partnerId}/marketplace/${values?.marketplace}`
    );
  },

  getStateOrProvinceCode(countryCode) {
    return api.get(`${prefix}/stateOrProvinceCode/country/${countryCode}`);
  },

  createSenderAddress(address) {
    return api.post(`${prefix}/senderAddress`, address);
  },

  updateSenderAddress(address) {
    return api.put(`${prefix}/senderAddress/${address?.id}`, address);
  },

  deleteSenderAddress(address) {
    return api.delete(`${prefix}/senderAddress/${address?.id}`);
  },

  getShipment(product) {
    return {
      id: '1668 - 1',
      date: product?.date,
      status: product?.status,
      carrier: 'FedEx',
      trackingNumber: null,
      fulfillment: product?.fulfillment,
      channel: product?.channel,
    };
    // return api.get(`${prefix}/shipment/${id}`);
  },
  saveContent(values) {
    return api.post(`${prefix}/saveContent`, values);
  },
  sendOrder(values) {
    return api.post(`${prefix}/sendOrderSale`, values);
  },
  saveShipmentOptions(values) {
    return api.post(`${prefix}/saveShipmentOptions`, values);
  },
  changeShipmentState(state) {
    return api.post(`${prefix}/changeShippmentState`, state);
  },
  printPackagingSlip(values) {
    return api.post(`${prefix}/printPackagingSlip`, values);
  },
  getExcelReport(values) {
    const config = { headers: { accept: '*/*' }, responseType: 'arraybuffer' };
    return api.post(`${prefix}/exportExcel`, values, config);
  },
  syncSaleOrderItems(partnerId) {
    return api.get(`${prefix}/partner/${partnerId}/order-items/sync`);
  },
  updateSaleOrderItemBySku(sku, payload) {
    return api.put(`${prefix}/order-items/${sku}`, payload);
  },
  updateSaleOrderBoxes(payload) {
    return api.put(`${prefix}/boxes`, payload);
  },
};

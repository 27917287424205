import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function OnSubscriptionButton({ planId, subscriptionPlanId, subscriptionId }) {
  const { t } = useTranslation();
  const cancellingQueryParam = subscriptionId
    ? `&cancellingId=${subscriptionId}`
    : '';
  return subscriptionPlanId === planId ? (
    <div className="on-subscription-button">
      <span className="empty-background-link" id="subscribe-button-link">
        {t('plans.currentPlan')}
      </span>
    </div>
  ) : (
    <Button className="empty-background-button">
      {planId && (
        <Link
          className="empty-background-link"
          to={`/suscripciones?planId=${planId}${cancellingQueryParam}`}
          id="subscribe-button-link"
        >
          <center>
            <span style={{ fontStyle: 'italic' }}>{t('plans.updatePlan')}</span>
          </center>
        </Link>
      )}
    </Button>
  );
}

OnSubscriptionButton.propTypes = {
  planId: PropTypes.number.isRequired,
  subscriptionPlanId: PropTypes.number,
  subscriptionId: PropTypes.number,
};

OnSubscriptionButton.defaultProps = {
  subscriptionPlanId: null,
  subscriptionId: null,
};

export default React.memo(OnSubscriptionButton);

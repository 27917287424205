import i18n from 'i18next';
import marketplacesApi from '../../../api/marketplace';
import openNotification from '../../../components/Toastr';
import {
  FboCouriersEnum,
  FulfillmentTypeMap,
  fulfillmentType as FulfillmentTypes,
} from '../../../utils/const';

const ToggleFulfillment = async (credential, fboCompany) => {
  const credentialId = credential.id;
  let fulfillmentType = FulfillmentTypeMap[fboCompany] || fboCompany;
  let shippingType = null;
  let finalFboCompany = fboCompany;
  if (fboCompany === 'Melonn / Manual') {
    finalFboCompany = FboCouriersEnum.MELONN;
    shippingType = FulfillmentTypes.FBM;
  } else {
    shippingType = fulfillmentType;
  }
  if (fboCompany?.includes('Amazon')) {
    fulfillmentType = FulfillmentTypes.FBA;
  }
  const payload = {
    fulfillmentType,
    fboFulfillmentCompany:
      fulfillmentType !== FulfillmentTypes.FBM ? finalFboCompany : null,
    shippingType,
  };
  marketplacesApi
    .updateProductFulfillment(payload, credentialId)
    .then(() => {
      openNotification({
        status: true,
        content: i18n.t('home.listing.successfulLogistics'),
      });
    })
    .catch(() => {
      openNotification({
        status: false,
        content: i18n.t('home.listing.unsuccessfulLogistics'),
      });
    });
};

export default ToggleFulfillment;

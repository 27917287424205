import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import './PerformanceTables.css';

function PerformanceTables({ dataSource, columns, summary, loading }) {
  return (
    <Table
      dataSource={
        Array.isArray(dataSource)
          ? dataSource.map((item, index) => ({ ...item, key: index }))
          : null
      }
      pagination={false}
      columns={columns}
      summary={summary}
      loading={loading}
    />
  );
}

PerformanceTables.propTypes = {
  dataSource: PropTypes.objectOf(Object).isRequired,
  columns: PropTypes.objectOf(Object).isRequired,
  summary: PropTypes.oneOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default React.memo(PerformanceTables);

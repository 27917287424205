import { Table, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function TableLocalization({ data }) {
  const { t } = useTranslation();

  const columns = [
    {
      title: 'N°',
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      render: (text, e, index) => <p>{index + 1}</p>,
    },
    {
      title: (
        <Tooltip title={t('home.charts.tablePerState.stateOrProvince.tooltip')}>
          {t('home.charts.tablePerState.stateOrProvince.title')}{' '}
          <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: 'stateOrProvince',
      align: 'center',
      key: 'stateOrProvince',
    },
    {
      title: (
        <Tooltip title={t('home.charts.tablePerState.orders.tooltip')}>
          {t('home.charts.tablePerState.orders.title')} <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: 'orders',
      align: 'center',
      key: 'orders',
    },
    {
      title: (
        <Tooltip title={t('home.charts.tablePerState.percentage.tooltip')}>
          {t('home.charts.tablePerState.percentage.title')}{' '}
          <InfoCircleOutlined />
        </Tooltip>
      ),
      key: 'percentage',
      align: 'center',
      dataIndex: 'percentage',
      render: (text) => <p>{Number(text).toFixed(0)}%</p>,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={{
        pageSize: 5,
      }}
    />
  );
}

TableLocalization.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      countryCode: PropTypes.string.isRequired,
      orders: PropTypes.string.isRequired,
      percentage: PropTypes.string.isRequired,
      stateOrProvince: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TableLocalization;

import {
  Avatar,
  Button,
  Card,
  Col,
  Image,
  Modal,
  Row,
  Form,
  InputNumber,
  Input,
  Space,
  Tooltip,
  Typography,
} from 'antd';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  InfoCircleOutlined,
  PictureOutlined,
  RobotOutlined,
  ExclamationCircleTwoTone,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Search from 'antd/lib/input/Search';
import { marketplaceCurrency } from '../../../utils/const';
import partnerApi from '../../../api/partner';
import openAIAPI from '../../../api/openai';
import { getErrorMessage } from '../../../api/api';
import openNotification from '../../../components/Toastr';
import './editProductsModal.css';
import UploadImageButton from '../../../components/UploadImages';
import KeywordSelector from './KeywordSelector';
import { mixPanelCreateEvent } from '../../../utils/functions';

function EditProductsModal({ listingToEdit, setListingToEdit }) {
  const { TextArea } = Input;
  const { t } = useTranslation();
  const session = useSelector((store) => store.Session.session);
  const [currentFormValues, setCurrentFormValues] = useState({});
  const [loadingEdition, setLoadingEdition] = useState(false);
  const [loadingOptimize, setLoadingOptimize] = useState(false);
  const [asins, setAsins] = useState([]);
  const [keywords, setKeywords] = useState(
    listingToEdit?.marketplace.includes('Amazon')
      ? [
          ...(listingToEdit?.attributes?.generic_keyword
            ? listingToEdit.attributes.generic_keyword
            : []),
        ]
      : []
  );
  const bulletPoints = listingToEdit?.marketplace.includes('Amazon')
    ? [
        ...(listingToEdit?.attributes?.bullet_point
          ? listingToEdit.attributes.bullet_point
          : []),
        ...Array(5).fill({ value: '' }, 0, 5),
      ].slice(0, 5)
    : [];
  const [formData] = Form.useForm();
  const OTHER_PRODUCT_IMAGE = [
    'other_product_image_locator_1',
    'other_product_image_locator_2',
    'other_product_image_locator_3',
    'other_product_image_locator_4',
    'other_product_image_locator_5',
    'other_product_image_locator_6',
    'other_product_image_locator_7',
    'other_product_image_locator_8',
  ];

  const isButtonDisabled = () => {
    return Object.values(currentFormValues).every(
      (value) => !value || (Array.isArray(value) && value?.length === 0)
    );
  };

  const onBulletPointChange = (index, value) => {
    formData.setFieldValue(['bullet_point', index], value);
  };

  const onASINSearch = (value) => {
    setAsins([value]);
  };

  const handleModalCancel = () => {
    setListingToEdit(null);
    formData.resetFields();
    setCurrentFormValues({});
  };

  const processNewData = (formValues) => {
    const finalData = [];
    if (listingToEdit?.marketplace.includes('Amazon')) {
      if (formValues.bullet_point) {
        const languageTag = bulletPoints[0]?.language_tag;
        const marketplaceId = bulletPoints[0]?.marketplace_id;
        const filteredBulletPoints = formValues.bullet_point.filter(
          (value) => value
        );
        const newBulletPoints = filteredBulletPoints.map((value) => ({
          language_tag: languageTag,
          marketplace_id: marketplaceId,
          value,
        }));
        finalData.push({
          attributeName: 'bullet_point',
          attributeValue: newBulletPoints,
        });
      }
      if (
        formValues.purchasable_offer &&
        formValues.purchasable_offer !== '0.00'
      ) {
        finalData.push({
          attributeName: 'purchasable_offer',
          attributeValue: formValues.purchasable_offer,
        });
      }
      if (formValues.keywords && formValues.keywords?.length > 0) {
        finalData.push({
          attributeName: 'generic_keyword',
          attributeValue: keywords.join(' '),
        });
      }
      Object.keys(formValues).forEach((key) => {
        if (
          key !== 'purchasable_offer' &&
          key !== 'bullet_point' &&
          key !== 'keywords' &&
          formValues[key]?.replace(/\s/g, '').length > 0
        ) {
          finalData.push({
            attributeName: key,
            attributeValue: formValues[key],
          });
        }
      });
    } else if (
      listingToEdit?.marketplace.includes('Ebay') ||
      listingToEdit?.marketplace.includes('Mercadolibre')
    ) {
      Object.keys(formValues).forEach((key) => {
        if (key === 'purchasable_offer' && formValues[key]) {
          finalData.push({
            attributeName: 'price',
            attributeValue: formValues[key],
          });
        } else if (key === 'item_name' && formValues[key]) {
          finalData.push({
            attributeName: 'title',
            attributeValue: formValues[key],
          });
        } else if (key === 'product_description' && formValues[key]) {
          finalData.push({
            attributeName: 'description',
            attributeValue: formValues[key],
          });
        } else if (formValues[key]) {
          finalData.push({
            attributeName: key,
            attributeValue: formValues[key],
          });
        }
      });
    }
    return finalData;
  };

  const onFinish = async (formValues) => {
    setLoadingEdition(true);
    const data = {
      attributeName: undefined,
      attributeValue: undefined,
      defaultCode: listingToEdit?.defaultCode,
      body: processNewData(formValues),
    };
    await partnerApi
      .updateProperty(
        session.userInfo.commercial_partner_id?.[0],
        listingToEdit?.listingId,
        listingToEdit?.marketplace,
        data
      )
      .then((response) => {
        if (response?.data?.success) {
          openNotification({
            status: true,
            content: 'Edición solicitada con éxito.',
          });
          setListingToEdit(null);
        } else {
          openNotification({
            status: false,
            content:
              response?.data?.message?.message || response?.data?.message,
          });
        }
        setLoadingEdition(false);
        setCurrentFormValues({});
        formData.resetFields();
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
        setLoadingEdition(false);
        setCurrentFormValues({});
        formData.resetFields();
      });
  };

  const titleFormComponent = (title, explanation) => {
    return (
      <p style={{ marginBottom: 0 }}>
        {title}
        {explanation && (
          <Tooltip title={explanation}>
            {' '}
            <InfoCircleOutlined />
          </Tooltip>
        )}
      </p>
    );
  };

  const getListingDescription = () => {
    if (listingToEdit?.marketplace.includes('Amazon')) {
      if (Array.isArray(listingToEdit?.attributes?.product_description)) {
        return listingToEdit?.attributes?.product_description?.[0]?.value;
      }
      return listingToEdit?.attributes?.product_description?.value;
    }
    if (listingToEdit?.marketplace.includes('Mercadolibre')) {
      return listingToEdit?.attributes?.description;
    }
    if (
      listingToEdit?.marketplace.includes('Ebay') &&
      listingToEdit?.children?.length === 0
    ) {
      return listingToEdit?.attributes?.description;
    }
    if (
      listingToEdit?.marketplace.includes('Ebay') &&
      listingToEdit?.children?.length > 0
    ) {
      return (
        listingToEdit?.children[0].attributes?.listingDescription ||
        listingToEdit?.children[0].attributes?.description
      );
    }
    if (
      listingToEdit?.marketplace.includes('Ebay') &&
      listingToEdit?.isVariant
    ) {
      return (
        listingToEdit?.attributes?.listingDescription ||
        listingToEdit?.attributes?.description
      );
    }
    return null;
  };

  const getListingPrice = () => {
    if (!listingToEdit?.marketplace.includes('Ebay')) {
      return listingToEdit?.price;
    }
    if (
      listingToEdit?.marketplace.includes('Ebay') &&
      listingToEdit?.children?.length > 0
    ) {
      return listingToEdit?.children[0].price;
    }
    if (
      listingToEdit?.marketplace.includes('Ebay') &&
      listingToEdit?.children?.length === 0
    ) {
      return listingToEdit?.price;
    }
    if (
      listingToEdit?.marketplace.includes('Ebay') &&
      listingToEdit?.isVariant
    ) {
      return listingToEdit?.price;
    }
    return null;
  };

  const optimizeListing = async () => {
    try {
      setLoadingOptimize(true);
      const title = await formData.getFieldValue('item_name');
      const description = await formData.getFieldValue('product_description');
      const newBulletPoints = await formData.getFieldValue('bullet_point');
      const payload = {
        marketplace: listingToEdit.marketplace,
        ...(title ? { title } : {}),
        ...(description ? { description } : {}),
        ...(keywords.length > 0 ? { keywords } : {}),
        ...(newBulletPoints?.length > 0
          ? { bulletPoints: newBulletPoints?.map((bp) => bp ?? '') }
          : {}),
      };
      const { data } = await openAIAPI.optimizeListing(payload);
      mixPanelCreateEvent('Action Button', {
        action: 'Button optimize listing',
        marketplace: listingToEdit.marketplace,
      });
      const newFields = {
        item_name: data?.title || title,
        product_description: data?.description || description,
        ...(data?.bulletPoints?.length > 0
          ? { bullet_point: data?.bulletPoints }
          : {}),
      };
      formData.setFieldValue('bullet_point', ['a', 'b', 'c', 'd', 'e']);
      formData.setFieldsValue(newFields);
      setCurrentFormValues({
        ...currentFormValues,
        ...newFields,
      });
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    } finally {
      setLoadingOptimize(false);
    }
  };

  const optimizeButton = (
    <Button
      onClick={optimizeListing}
      loading={loadingOptimize}
      disabled={
        !formData.getFieldValue('item_name') &&
        !formData.getFieldValue('product_description') &&
        !formData.getFieldValue('bullet_point')
      }
    >
      Optimizar con&nbsp;<strong>IA</strong> <RobotOutlined />
    </Button>
  );

  useEffect(() => {
    formData.setFieldValue('keywords', keywords);
    setCurrentFormValues({
      ...currentFormValues,
      keywords,
    });
  }, [keywords]);

  return (
    <Modal
      title={
        t('catalogue.editProductModal.title') +
        (listingToEdit && listingToEdit.marketplace
          ? listingToEdit.marketplace
          : '')
      }
      open={listingToEdit}
      cancelText={t('common.return')}
      className="publishProductsModal"
      footer={false}
      keyboard={false}
      onCancel={handleModalCancel}
      width={1000}
      scrollable
      maskClosable={false}
      destroyOnClose
    >
      <Row style={{ marginLeft: '12', marginRight: '12' }}>
        <Col span={24}>
          {listingToEdit.marketplace.includes('Amazon') && (
            <Card
              size="small"
              style={{
                backgroundColor: '#FCF4D6',
                border: `solid 1px rgb(250, 173, 20)`,
                borderRadius: 4,
                display: `block`,
              }}
            >
              <Space style={{ alignItems: 'baseline' }}>
                <ExclamationCircleTwoTone twoToneColor="rgb(250, 173, 20, .5)" />
                {t('catalogue.editProductModal.warningAmazonParent')}
              </Space>
            </Card>
          )}
          <Typography.Title level={5}>
            {t('catalogue.editProductModal.subtitleTwo')}
          </Typography.Title>

          <Row span={24}>
            <Form
              form={formData}
              layout="vertical"
              onFinish={onFinish}
              style={{ width: '100%' }}
              onValuesChange={(changedValues) => {
                setCurrentFormValues({
                  ...currentFormValues,
                  ...changedValues,
                });
              }}
            >
              {!(
                (listingToEdit?.marketplace.includes('Ebay') ||
                  listingToEdit?.marketplace.includes('Mercadolibre')) &&
                listingToEdit.isVariant
              ) && (
                <>
                  <Form.Item
                    label={titleFormComponent(
                      t('catalogue.editProductModal.newProductTitle'),
                      t('catalogue.editProductModal.newTitleTooltip')
                    )}
                    name="item_name"
                    initialValue={listingToEdit?.title}
                  >
                    <Input
                      placeholder={t(
                        'catalogue.editProductModal.newProductTitlePlaceholder'
                      )}
                      maxLength={
                        listingToEdit &&
                        listingToEdit.marketplace.includes('Amazon')
                          ? 200
                          : 60
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label={titleFormComponent(
                      t('catalogue.editProductModal.newProductDescription'),
                      t('catalogue.editProductModal.newDescriptionTooltip')
                    )}
                    className="formItemEditProductModal"
                    name="product_description"
                    initialValue={getListingDescription()}
                  >
                    <TextArea
                      showCount
                      autoSize
                      maxLength={2000}
                      placeholder={t(
                        'catalogue.editProductModal.newProductDescriptionPlaceholder'
                      )}
                    />
                  </Form.Item>
                  {bulletPoints.map((row, index) => {
                    return (
                      <Form.Item
                        label={titleFormComponent(
                          `${t('catalogue.editProductModal.newBulletPoint')} ${
                            index + 1
                          }`,
                          t('catalogue.editProductModal.newBulletPointTooltip')
                        )}
                        className="formItemEditProductModal"
                        name={['bullet_point', index]}
                        initialValue={row.value}
                        onChange={(e) =>
                          onBulletPointChange(index, e.target.value)
                        }
                      >
                        <TextArea
                          showCount
                          autoSize
                          maxLength={500}
                          placeholder={t(
                            'catalogue.editProductModal.newBulletPointPlaceholder'
                          )}
                        />
                      </Form.Item>
                    );
                  })}
                  {listingToEdit?.marketplace.includes('Amazon') && (
                    <Col span={24}>
                      <Typography.Title level={5}>Keywords</Typography.Title>
                      <Row align="bottom">
                        <Col span={18}>
                          <Search
                            placeholder="Ingresa un ASIN de Amazon para recomendarte keywords"
                            onSearch={onASINSearch}
                            enterButton
                          />
                        </Col>
                      </Row>
                      <Form.Item
                        initialValue={keywords}
                        name="keywords"
                        label="Keywords"
                        className="formItemEditProductModal"
                        style={{ width: '100%' }}
                      >
                        <KeywordSelector
                          asins={asins}
                          setKeywords={setKeywords}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Row justify="space-between" align="bottom">
                    <Form.Item
                      initialValue={getListingPrice()}
                      label={titleFormComponent(
                        t('catalogue.editProductModal.newProductPrice'),
                        t('catalogue.editProductModal.newPriceTooltip')
                      )}
                      className="formItemEditProductModal"
                      name="purchasable_offer"
                    >
                      <InputNumber
                        min={0}
                        addonBefore={
                          marketplaceCurrency[listingToEdit?.marketplace]
                        }
                        placeholder={listingToEdit?.price || ''}
                      />
                    </Form.Item>
                    <Col
                      span={10}
                      className="formItemEditProductModal"
                      style={{ display: 'flex', justifyContent: 'right' }}
                    >
                      {optimizeButton}
                    </Col>
                  </Row>
                  <Col span={24} className="itemEditProductModal itemTitle">
                    {t('catalogue.editProductModal.productMainImage')}:
                  </Col>
                  <Col span={24} className="itemEditProductModal contentItem">
                    <Avatar
                      shape="square"
                      size={100}
                      src={
                        listingToEdit?.mainImages?.length > 0 ? (
                          <Image src={listingToEdit?.mainImages?.[0].url} />
                        ) : (
                          <PictureOutlined />
                        )
                      }
                    />
                  </Col>
                  <Col span={24} className="itemEditProductModal itemTitle">
                    {t('catalogue.editProductModal.productSecondaryImages')}:
                  </Col>
                  <Col span={24} className="contentItem">
                    <Row gutter={[8, 8]}>
                      <Col span={8}>
                        <Avatar
                          shape="square"
                          size={75}
                          src={
                            listingToEdit?.productImages?.[0] ? (
                              <Image
                                src={listingToEdit?.productImages?.[0].url}
                              />
                            ) : (
                              <PictureOutlined />
                            )
                          }
                        />
                      </Col>
                      <Col span={8}>
                        <Avatar
                          shape="square"
                          size={75}
                          src={
                            listingToEdit?.productImages?.[1] ? (
                              <Image
                                src={listingToEdit?.productImages?.[1].url}
                              />
                            ) : (
                              <PictureOutlined />
                            )
                          }
                        />
                      </Col>
                      <Col span={8}>
                        <Avatar
                          shape="square"
                          size={75}
                          src={
                            listingToEdit?.productImages?.[2] ? (
                              <Image
                                src={listingToEdit?.productImages?.[2].url}
                              />
                            ) : (
                              <PictureOutlined />
                            )
                          }
                        />
                      </Col>

                      <Col span={8}>
                        <Avatar
                          shape="square"
                          size={75}
                          src={
                            listingToEdit?.productImages?.[3] ? (
                              <Image
                                src={listingToEdit?.productImages?.[3].url}
                              />
                            ) : (
                              <PictureOutlined />
                            )
                          }
                        />
                      </Col>
                      <Col span={8}>
                        <Avatar
                          shape="square"
                          size={75}
                          src={
                            listingToEdit?.productImages?.[4] ? (
                              <Image
                                src={listingToEdit?.productImages?.[4].url}
                              />
                            ) : (
                              <PictureOutlined />
                            )
                          }
                        />
                      </Col>
                      <Col span={8}>
                        <Avatar
                          shape="square"
                          size={75}
                          src={
                            listingToEdit?.productImages?.[5] ? (
                              <Image
                                src={listingToEdit?.productImages?.[5].url}
                              />
                            ) : (
                              <PictureOutlined />
                            )
                          }
                        />
                      </Col>

                      <Col span={8}>
                        <Avatar
                          shape="square"
                          size={75}
                          src={
                            listingToEdit?.productImages?.[6] ? (
                              <Image
                                src={listingToEdit?.productImages?.[6].url}
                              />
                            ) : (
                              <PictureOutlined />
                            )
                          }
                        />
                      </Col>
                      <Col span={8}>
                        <Avatar
                          shape="square"
                          size={75}
                          src={
                            listingToEdit?.productImages?.[7] ? (
                              <Image
                                src={listingToEdit?.productImages?.[7].url}
                              />
                            ) : (
                              <PictureOutlined />
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
              {!(
                listingToEdit?.marketplace.includes('Mercadolibre') &&
                listingToEdit?.children?.length > 0
              ) && (
                <>
                  <Form.Item
                    label={titleFormComponent(
                      t('catalogue.editProductModal.newProductMainImage'),
                      t('catalogue.editProductModal.newMainImgTooltip')
                    )}
                    className="formItemEditProductModal"
                    name="main_product_image_locator"
                  >
                    <Row>
                      <Col span={6}>
                        <UploadImageButton
                          limit={1}
                          setCurrentFormValues={setCurrentFormValues}
                          currentFormValues={currentFormValues}
                          formItemName="main_product_image_locator"
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item
                    label={titleFormComponent(
                      t('catalogue.editProductModal.newProductSecondaryImages'),
                      t('catalogue.editProductModal.newSecondaryImgTooltip')
                    )}
                    className="formItemEditProductModal"
                  >
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {OTHER_PRODUCT_IMAGE?.map((element) => (
                        <Col key={element}>
                          <Form.Item name={element} style={{ height: 0 }} />
                          <UploadImageButton
                            limit={1}
                            setCurrentFormValues={setCurrentFormValues}
                            currentFormValues={currentFormValues}
                            formItemName={element}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Form.Item>
                </>
              )}
              <div className="buttonModalEdit">
                <Form.Item>
                  <Button
                    className="btn-basic-green"
                    type="primary"
                    htmlType="submit"
                    loading={loadingEdition}
                    disabled={isButtonDisabled()}
                  >
                    {t('catalogue.editProductModal.saveButton')}
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}

EditProductsModal.propTypes = {
  listingToEdit: PropTypes.objectOf(Object).isRequired,
  setListingToEdit: PropTypes.func.isRequired,
};

export default EditProductsModal;

import React, { useEffect, useState } from 'react';
import {
  PictureOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons';
import { Avatar, Col, Image, Row, Table, Tooltip, Space, Spin } from 'antd';
import { Resizable } from 'react-resizable';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import getLinkToStore from './linkToStore';
import { thousandsSeparator } from '../../../utils/functions';
import './dashboardTable.css';
import selectors from '../../../redux/analytic/dashboard/selectors';

const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

function ResizableTitle({
  title,
  onResize,
  width,
  colSpan,
  rowSpan,
  className,
  style,
  onMouseEnter,
  onMouseLeave,
}) {
  if (!width) {
    return (
      <th
        colSpan={colSpan}
        rowSpan={rowSpan}
        className={className}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {title}
      </th>
    );
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
          aria-hidden="true"
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th
        colSpan={colSpan}
        rowSpan={rowSpan}
        className={className}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {title}
      </th>
    </Resizable>
  );
}

ResizableTitle.propTypes = {
  title: PropTypes.string.isRequired,
  onResize: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  colSpan: PropTypes.number,
  rowSpan: PropTypes.number,
  className: PropTypes.string.isRequired,
  style: PropTypes.instanceOf(Object).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

ResizableTitle.defaultProps = {
  colSpan: null,
  rowSpan: null,
};

function DashBoardTable({ dataSource, loading, currency }) {
  const { t } = useTranslation();

  const [columnTable, setColumnTable] = useState([]);

  const currencyState = useSelector(selectors.selectCurrency);

  const [columnWidths, setColumnWidths] = useState({
    name: 350,
    price: 80,
    sumTotalSold: 150,
    sumTotalSoldUSD: 150,
    sumTotalSoldUSDCompare: 100,
    sumTotalSoldVariance: 100,
    sumQuantitySold: 100,
    sumQuantitySoldCompare: 100,
    sumQuantitySoldVariance: 100,
    ordersQuantity: 100,
    ordersQuantityCompare: 100,
    numberUnsoldDays: 100,
    salesShare: 100,
    availableStock: 100,
    totalStock: 100,
    daysOutStock: 100,
    refunds: 80,
    title: 100,
    marketplace: 100,
  });

  const percentageCalculation = (object, paramCompare, param) => {
    const values = [
      Number(object[`${param}`]),
      Number(object[`${paramCompare}`]),
    ];
    const numericalOrder = values.sort((a, b) => b - a);
    const rest = numericalOrder[0] - numericalOrder[1];
    let differentialPercentage = 0;
    let result = 0;
    if (object[`${paramCompare}`] > 0) {
      differentialPercentage = rest / object[`${paramCompare}`];
      result = differentialPercentage * 100;
    } else if (object[`${param}`] === 0) {
      result = 0;
    } else {
      differentialPercentage = rest / rest;
      result = differentialPercentage * 100;
    }
    if (Number(object[`${paramCompare}`]) > Number(object[`${param}`])) {
      return (
        <span style={{ color: '#F25154', marginLeft: 10 }}>
          <ArrowDownOutlined id="arrowVariantRed" />
          {` ${Math.round(result.toFixed() * 100) / 100}%`}
        </span>
      );
    }
    if (Number(object[`${paramCompare}`]) === Number(object[`${param}`])) {
      return (
        <span style={{ color: 'grey', marginLeft: 10 }}>
          {' '}
          {`${Math.round(result * 100) / 100}%`}
        </span>
      );
    }
    return (
      <span style={{ color: '#33BA6A', marginLeft: 10 }}>
        <ArrowUpOutlined id="arrowVariantGreen" />
        {` ${Math.round(result.toFixed() * 100) / 100}%`}
      </span>
    );
  };

  const orderRowsTable = (data) => {
    setColumnTable(
      data?.sort(
        (a, b) => Number(b.sumTotalSoldUSD) - Number(a.sumTotalSoldUSD)
      )
    );
  };

  useEffect(() => {
    orderRowsTable(dataSource);
  }, [dataSource]);

  const renderCurrentCurrency = (
    value,
    row,
    currentCurrency,
    currentCurrencyState
  ) => {
    const currencyValue = currentCurrency ? ` ${currentCurrencyState}` : '';
    const currencySign =
      row === 'sumQuantitySold' || row === 'ordersQuantity'
        ? ''
        : currencyValue;
    if (!value[row]) return 0;
    let string;
    if (value[row] === '-') string = '-';
    else if (row === 'sumTotalSoldUSD')
      string = thousandsSeparator(Math.round(value[row] * 100) / 100);
    else string = thousandsSeparator(value[row]);
    return (
      <span>
        {string} {currencySign}
      </span>
    );
  };
  const renderCurrentCurrencyUnit = (
    value,
    row,
    currentCurrency,
    currentCurrencyState
  ) => {
    const currencyValue = currentCurrency ? ` ${currentCurrencyState}` : '';
    const currencySign =
      row === 'sumQuantitySold' || row === 'ordersQuantity'
        ? ''
        : currencyValue;
    if (!value[row]) return 0;
    let string;
    if (value[row] === '-') string = '-';
    else if (row === 'sumTotalSoldUSD')
      string = thousandsSeparator(
        Number(value.rate) !== 0
          ? Math.round((value[row] / Number(value.rate)) * 100) / 100
          : Math.round(value[row] * 100) / 100
      );
    else
      string = thousandsSeparator(
        Number(value.rate) !== 0
          ? Math.round((value[row] / Number(value.rate)) * 100) / 100
          : Math.round(value[row] * 100) / 100
      );
    return (
      <span>
        {string}
        {currencySign}{' '}
      </span>
    );
  };

  const renderProduct = (record) => {
    const imgUrl = record.url;
    return (
      <Row align="middle" justify="start">
        <Col span={24}>
          <Space>
            {imgUrl ? (
              <Avatar
                style={{ width: 50, height: 50 }}
                shape="square"
                className="home-listing-table-avatar"
                src={<Image src={`${imgUrl}`} />}
              />
            ) : (
              <Avatar
                style={{ width: 50, height: 50 }}
                shape="square"
                className="home-listing-table-avatar"
                icon={<PictureOutlined />}
              />
            )}
            <Space direction="vertical" style={{ textAlign: 'left' }}>
              {getLinkToStore[record?.marketplace](record)}
              <Tooltip title={record.title}>
                <span style={{ fontSize: 12 }}>SKU: {record.sku}</span>
              </Tooltip>
            </Space>
          </Space>
        </Col>
      </Row>
    );
  };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const handleResize =
    (index) =>
    (e, { size }) => {
      setColumnWidths((columns) => {
        const nextColumns = { ...columns };
        nextColumns[index] = size.width;
        return nextColumns;
      });
    };

  const columnsMainModalListProducts = [
    {
      title: t('common.product', { count: 1 }),
      dataIndex: 'title',
      key: 'title',
      width: 100,
      align: 'left',
      onHeaderCell: (column) => ({
        width: column.width,
        title: column.title,
        onResize: 10,
      }),
      render: (value, record) => renderProduct(record),
    },
    {
      title: (
        <Tooltip title={t('dashboard.table.price.tooltip')}>
          {t('dashboard.table.price.title')} <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: 'price',
      key: 'price',
      width: columnWidths.price,
      align: 'center',
      onHeaderCell: (column) => ({
        width: column.width,
        title: column.title,
        onResize: handleResize('price'),
      }),
      render: (value, record) =>
        renderCurrentCurrencyUnit(record, 'price', currency, currencyState),
    },
    {
      title: (
        <Tooltip title={t('dashboard.table.totalSales.tooltip')}>
          {t('dashboard.table.totalSales.title')} <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: 'sumTotalSoldUSD',
      width: columnWidths.sumTotalSoldUSD,
      align: 'center',
      key: 'sumTotalSoldUSD',
      onHeaderCell: (column) => ({
        width: column.width,
        title: column.title,
        onResize: handleResize('sumTotalSoldUSD'),
      }),
      render: (value, record) => {
        const priceTotal = renderCurrentCurrency(
          record,
          'sumTotalSoldUSD',
          currency,
          currencyState
        );
        const percentage = percentageCalculation(
          record,
          'sumTotalSoldUSDCompare',
          'sumTotalSoldUSD'
        );
        return (
          <>
            {priceTotal !== 0 ? priceTotal : `0 ${currencyState}`}
            {percentage}
          </>
        );
      },
    },
    {
      ellipsis: true,
      title: (
        <Tooltip title={t('dashboard.table.soldUnits.tooltip')}>
          {t('dashboard.table.soldUnits.title')} <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: 'sumQuantitySold',
      width: columnWidths.sumQuantitySold,
      align: 'center',
      onHeaderCell: (column) => ({
        width: column.width,
        title: column.title,
        onResize: handleResize('sumQuantitySold'),
      }),
      key: 'sumQuantitySold',
      render: (value, record) => {
        const priceTotal = renderCurrentCurrency(
          record,
          'sumQuantitySold',
          currency,
          currencyState
        );
        const percentage = percentageCalculation(
          record,
          'sumQuantitySoldCompare',
          'sumQuantitySold'
        );
        return (
          <>
            {priceTotal}
            {percentage}
          </>
        );
      },
    },
    {
      ellipsis: true,
      title: (
        <Tooltip title={t('dashboard.table.totalOrders.tooltip')}>
          {t('dashboard.table.totalOrders.title')} <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: 'ordersQuantity',
      align: 'center',
      width: columnWidths.ordersQuantity,
      onHeaderCell: (column) => ({
        width: column.width,
        title: column.title,
        onResize: handleResize('ordersQuantity'),
      }),
      key: 'ordersQuantity',
      render: (value, record) => {
        const priceTotal = renderCurrentCurrency(
          record,
          'ordersQuantity',
          currency,
          currencyState
        );
        const percentage = percentageCalculation(
          record,
          'ordersQuantityCompare',
          'ordersQuantity'
        );
        return (
          <>
            {priceTotal}
            {percentage}
          </>
        );
      },
    },
  ];

  return !loading ? (
    <Table
      className="dashboardtable-ventas"
      rowKey="table-not-expand-all"
      bordered
      components={components}
      columns={columnsMainModalListProducts}
      dataSource={columnTable}
      pagination={false}
      scroll={{
        y: 470,
        x: true,
      }}
    />
  ) : (
    <center>
      <Spin style={{ marginTop: '15%' }} indicator={antIcon} />
    </center>
  );
}

DashBoardTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  dataSource: PropTypes.arrayOf(Object).isRequired,
  currency: PropTypes.string.isRequired,
};

export default React.memo(DashBoardTable);

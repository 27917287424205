import React, { useState, useEffect } from 'react';
import { Button, Col, List, Row } from 'antd';
import ReactDragListView from 'react-drag-listview';
import PropTypes from 'prop-types';
import UpDownButton from '../../../../components/UpDownButton';
import './Int99ShipmentTypesList.style.css';

function Int99ShipmentTypesList({ shipmentTypes, loadingTypes, onUpdate }) {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(undefined);

  useEffect(() => {
    setSelectedItem(undefined);
    if (shipmentTypes && shipmentTypes.length >= 1) {
      const orderShipments = shipmentTypes.sort((s1, s2) => {
        if (s1.order < s2.order) {
          return -1;
        }
        if (s1.order > s2.order) {
          return 1;
        }
        return 0;
      });
      const newDataSource = [];
      orderShipments.forEach((shipment) => {
        newDataSource.push({
          id: shipment.id,
          code: shipment.shipmentType.code,
          title: shipment.shipmentType.name,
          description: shipment.shipmentType.description,
          order: shipment.order,
        });
      });
      setData(newDataSource);
    }
  }, [shipmentTypes]);

  const onDragEnd = (fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex >= data.length) return; // Ignores if outside designated area
    let order = -1;
    const items = [...data];
    const item = items.splice(fromIndex, 1)[0];
    setSelectedItem({ ...item, order: toIndex });
    items.splice(toIndex, 0, item);
    setData(
      items.map((i) => {
        order += 1;
        return { ...i, order };
      })
    );
  };

  return (
    <div>
      <Col>
        <Row>
          <span
            style={{
              paddingLeft: 25,
              maxWidth: '100%',
              textAlign: 'left',
              fontSize: '18px',
            }}
          >
            Tipos de envío
          </span>
        </Row>
        <Row>
          <span
            style={{
              paddingLeft: 25,
            }}
          >
            Ordena el tipo de envío según tus prioridades
          </span>
        </Row>
      </Col>
      <div
        className="shipment-list-drag"
        style={{ marginTop: 15, padding: '0 18px' }}
      >
        <div className="shipment-list-container">
          <ReactDragListView
            nodeSelector=".ant-list-item.draggble"
            onDragEnd={onDragEnd}
            lineClassName="line-class-drag"
          >
            <List
              size="small"
              bordered
              dataSource={data}
              className="shipment-list"
              renderItem={(item) => {
                return (
                  <List.Item
                    key={item.code}
                    className={`draggble ${
                      selectedItem && item.id === selectedItem.id
                        ? 'selected'
                        : ''
                    }`}
                    onClick={() => setSelectedItem(item)}
                    style={{ margin: '10px 10px 0 10px' }}
                  >
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                );
              }}
            />
          </ReactDragListView>

          <Button
            type="primary"
            htmlType="submit"
            loading={loadingTypes}
            style={{ width: '100%', marginTop: '10px' }}
            onClick={() => onUpdate(data)}
          >
            Guardar
          </Button>
        </div>

        <UpDownButton
          onUp={() => onDragEnd(selectedItem.order, selectedItem.order - 1)}
          onDown={() => onDragEnd(selectedItem.order, selectedItem.order + 1)}
          disableUp={!selectedItem}
          disableDown={!selectedItem}
        />
      </div>
    </div>
  );
}
export default Int99ShipmentTypesList;

Int99ShipmentTypesList.propTypes = {
  shipmentTypes: PropTypes.instanceOf(Array).isRequired,
  loadingTypes: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

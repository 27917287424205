import FedexSvg from './fedex.svg';
import ShipmartSvg from './shipsmart.svg';
import INT99MinutosSvg from './99minutos.svg';
import DefaultSvg from './default.svg';
import FBMCouriers from '../../../utils/FBMCouriers';

const CouriersIcons = (courier) => {
  switch (courier) {
    case FBMCouriers.FEDEX:
      return FedexSvg;
    case FBMCouriers.SHIPSMART:
      return ShipmartSvg;
    case FBMCouriers.INT99MINUTOS:
      return INT99MinutosSvg;
    default:
      return DefaultSvg;
  }
};

export default CouriersIcons;

import api from './api';

const prefix = 'notifications';
const NotificationsAPI = {
  get() {
    return api.get(`${prefix}`);
  },

  update(id, data) {
    return api.patch(`${prefix}/${id}`, data);
  },

  delete(id) {
    return api.delete(`${prefix}/${id}`);
  },
};
export default NotificationsAPI;

import api from './api';

const prefix = '/shipment';

const shipmentAPI = {
  getShipmentsTable: (query) => {
    return api.get(`${prefix}/all`, { params: query });
  },
};

export default shipmentAPI;

import {
  Affix,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Radio,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import {
  InfoCircleOutlined,
  PrinterOutlined,
  SendOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import FedexPickup from '../../../steps/components/Pickup/FedexPickup';
import FedExServiceSelect from '../../../../FedExServiceSelect';
import openNotification from '../../../../../../components/Toastr';
import couriersAPI from '../../../../../../api/courier';
import './PickupSummaryStep.css';
import pickUpTypes from '../../enum/pickUpType';
import { createCourierShipmentsErrors } from '../../../../../../utils/errors';
import { translateErrors } from '../../../../../../utils/functions';
import shipmentsAPI from '../../../../../../api/shipments';

function PickupSummaryStep({
  orders,
  warehouseId,
  fboOrigin,
  data,
  courierId,
  courierCredentials,
}) {
  const { t } = useTranslation();
  const [dateTime, setDateTime] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [productSend, setProductSend] = useState(false);
  const [courierShipments, setCourierShipments] = useState([]);
  const [loadingRates, setLoadingRates] = useState(true);
  const [rates, setRates] = useState({});
  const [selectedService, setSelectedService] = useState();
  const [deliveryOfficeDate, setDeliveryOfficeDate] = useState();
  const [pickUpType, setPickUpType] = useState();

  const [deliveryOfficeForm] = Form.useForm();

  const setRatesAndTransitTimesPayload = (shipment) => {
    return {
      saleOrderId: shipment.saleOrderId,
      warehouseId: shipment.warehouseId,
      boxes: shipment.boxes,
    };
  };

  const onFinish = async () => {
    if (pickUpType) {
      if (pickUpType === pickUpTypes.PICKUP && !dateTime) {
        openNotification({
          status: false,
          content: t('orders.shipment.pickUp.officeDelivery.required'),
        });
        return;
      }
      if (pickUpType === pickUpTypes.OFFICEDELIVERY && !deliveryOfficeDate) {
        openNotification({
          status: false,
          content: t('orders.shipment.pickUp.schedule.required'),
        });
        return;
      }
      let createShipmentCourierResponse;
      let shipments;
      try {
        setIsLoading(true);
        if (courierCredentials.courierCompanyName === 'Fedex') {
          const promises = data.map((shipment, index) => {
            return shipmentsAPI.createSalesOrderShipment({
              orderId: shipment.saleOrderId,
              shipment: {
                courierName: 'FedEx',
                serviceType: {
                  name: selectedService,
                },
                pickup: {
                  type:
                    pickUpType === pickUpTypes.PICKUP ? 'SCHEDULE' : 'DROP_OFF',
                },
                rate: {
                  currency: rates[index][selectedService].currency,
                  value: rates[index][selectedService].rates.ACCOUNT,
                },
              },
            });
          });
          createShipmentCourierResponse = await Promise.all(promises);
          shipments = createShipmentCourierResponse.map(
            (shipment) => shipment.data
          );
          setCourierShipments(shipments);
        } else {
          createShipmentCourierResponse =
            await couriersAPI.createMultipleCourierShipments(
              courierCredentials.courierCompanyName,
              data.map((shipment, index) => ({
                ...shipment,
                serviceType: selectedService,
                rate:
                  rates &&
                  `${rates[index][selectedService].rates.ACCOUNT} ${rates[index][selectedService].currency}`,
                fboOrigin,
              }))
            );
          setCourierShipments(createShipmentCourierResponse.data);
        }
      } catch (error) {
        const errorTranslation = translateErrors(
          error,
          createCourierShipmentsErrors
        );
        openNotification({
          status: false,
          content: t(errorTranslation.error),
        });
        setIsLoading(false);
        return;
      }
      if (pickUpType === pickUpTypes.PICKUP) {
        try {
          const payload = {
            warehouseId,
            pickupDate: dateTime.pickupDate,
            readyTime: dateTime.readyTime,
            closeTime: dateTime.closeTime,
            courierShipmentIds: shipments.map((shipment) => shipment.id),
          };
          await couriersAPI.createPickup(
            courierCredentials.courierCompanyName,
            payload
          );
        } catch (error) {
          openNotification({
            status: false,
            content: t('orders.shipment.summary.shipmentError'),
          });
          setIsLoading(false);
          return;
        }
      }
      openNotification({
        status: true,
        content: t('orders.shipment.summary.success'),
      });
      setProductSend(true);
      setIsLoading(false);
    } else {
      openNotification({
        status: false,
        content: t('orders.shipment.summary.completePickUpInfo'),
      });
    }
  };

  const downloadCourierShipmentsLabels = async () => {
    if (courierShipments.length > 0) {
      const { courierCompanyName, courierName } = courierShipments[0];
      const trackingNumbers = courierShipments.map(
        (courierShipment) => courierShipment.masterTrackingNumber
      );
      try {
        const response = await couriersAPI.getMultipleShipmentDocument(
          courierCompanyName || courierName,
          trackingNumbers
        );
        response.data.forEach(async (courierShipmentDocument) => {
          const linkSource = courierShipmentDocument.url;
          const downloadLink = document.createElement('a');
          downloadLink.target = '_blank';
          downloadLink.href = linkSource;
          downloadLink.click();
        });
      } catch (error) {
        openNotification({
          status: false,
          content: 'Ha ocurrido un error al intentar descargar las etiquetas.',
        });
      }
    }
  };

  const officeDelivery = async (value) => {
    setDeliveryOfficeDate(value);
  };

  const disabledPreviousDate = (current) => {
    return current && current < moment(new Date()).subtract(1, 'day');
  };

  return (
    <Col>
      <Col
        style={{
          alignItems: 'flex-start',
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          marginTop: '3em',
        }}
      >
        <h3 className="title-grey-box">{t('orders.shipment.pickUp.title')}</h3>
      </Col>
      <Radio.Group
        className="cardRadioGroupContent"
        name="isPalletized"
        disabled={productSend}
        value={pickUpType}
        onChange={(e) => {
          setPickUpType(e.target.value);
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Card
              onClick={() => !productSend && setPickUpType(pickUpTypes.PICKUP)}
              hoverable={!productSend}
              className={pickUpType === pickUpTypes.PICKUP && 'selected-card'}
              bodyStyle={{ height: '100%' }}
            >
              <Row
                justify="space-between"
                align="middle"
                style={{ height: '100%' }}
              >
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                  span={2}
                >
                  <Radio value="pickUp" />
                </Col>
                <Col span={22} className="card-content-div">
                  <Typography.Title level={5}>
                    {t('orders.shipment.pickUp.schedule.title')}
                  </Typography.Title>
                  <Typography.Text>
                    {t('orders.shipment.pickUp.schedule.description')}
                  </Typography.Text>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              onClick={() => setPickUpType(pickUpTypes.OFFICEDELIVERY)}
              hoverable={!productSend}
              className={
                pickUpType === pickUpTypes.OFFICEDELIVERY && 'selected-card'
              }
              bodyStyle={{ height: '100%' }}
            >
              <Row
                justify="space-between"
                align="middle"
                style={{ height: '100%' }}
              >
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                  span={2}
                >
                  <Radio value="officeDelivery" />
                </Col>
                <Col span={22} className="card-content-div">
                  <Typography.Title level={5}>
                    {t('orders.shipment.pickUp.officeDelivery.title')}
                  </Typography.Title>
                  <Typography.Text>
                    {t('orders.shipment.pickUp.officeDelivery.description')}
                  </Typography.Text>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Radio.Group>
      {pickUpType === pickUpTypes.PICKUP && (
        <Col span={24} className="date-container">
          <FedexPickup
            warehouseId={warehouseId}
            courierCredentialId={courierId}
            showConfirmButton={false}
            setDateTime={setDateTime}
          />
        </Col>
      )}
      {pickUpType === pickUpTypes.OFFICEDELIVERY && (
        <Col span={24} className="date-container">
          <Row gutter={[32, 32]}>
            <Col>
              <Form layout="vertical" form={deliveryOfficeForm}>
                <Form.Item
                  label={
                    <div>
                      {t('orders.state.pickUp.officeDelivery')}:{' '}
                      <Tooltip title={t('orders.state.pickUp.referenceDate')}>
                        <InfoCircleOutlined />
                        &nbsp;
                      </Tooltip>
                    </div>
                  }
                  name="officeDeliveryDate"
                  rules={[
                    {
                      required: true,
                      message: 'Ingrese la fecha de entrega.',
                    },
                  ]}
                >
                  <DatePicker
                    className="datePickerStyle"
                    placeholder="YYYY-MM-DD"
                    disabledDate={disabledPreviousDate}
                    disabled={productSend}
                    onChange={(value) => officeDelivery(value)}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      )}
      <Col
        style={{
          alignItems: 'flex-start',
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          marginTop: '3em',
        }}
      >
        <h3 className="title-grey-box">
          {t('orders.shipment.serviceType.title')}
        </h3>
      </Col>
      {loadingRates && (
        <Typography.Text className="grey-box">
          Calculando costos de envío...
        </Typography.Text>
      )}
      <FedExServiceSelect
        shipments={data}
        setRatesAndTransitTimesPayload={setRatesAndTransitTimesPayload}
        setRates={setRates}
        setLoadingRates={setLoadingRates}
        selectedService={selectedService}
        setSelectedService={setSelectedService}
      />
      <Col
        style={{
          alignItems: 'flex-start',
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          marginTop: '3em',
        }}
      >
        <h3 className="title-grey-box">{t('orders.shipment.summary.title')}</h3>
      </Col>
      {data.map((shipment, index) => (
        <Row
          style={{
            border: '1px solid #C4C4C4',
            borderRadius: '8px',
            padding: '20px 36px',
            marginBottom: '20px',
          }}
          key={shipment.saleOrderId}
        >
          <Col span={6}>
            <Row className="summary-title">
              {t('orders.shipment.summary.content')}:
            </Row>
            <Col>
              <Row style={{ marginBottom: '40px' }}>
                <div className="grey-box">
                  {t('common.boxes')}: {shipment.boxes.length}
                </div>
              </Row>
              <Row style={{ marginBottom: '40px' }}>
                <div className="grey-box">
                  {t('orders.shipment.summary.products')}:{' '}
                  {
                    orders.find(({ id }) => id === shipment.saleOrderId)
                      .orderItems.length
                  }
                </div>
              </Row>
              <Row>
                <div className="grey-box">
                  {t('common.units')}: {shipment.boxes.length}
                </div>
              </Row>
            </Col>
          </Col>

          <Col span={1}>
            <Divider
              type="vertical"
              style={{ height: '100%', marginLeft: '50%' }}
            />
          </Col>
          <Col span={17}>
            <Row className="summary-title">
              {t('orders.shipment.summary.products')}:
            </Row>
            {orders
              .find(({ id }) => id === shipment.saleOrderId)
              .orderItems.map((product) => {
                return (
                  <>
                    <Row>
                      <div style={{ fontSize: '18px', color: '#67A4FF' }}>
                        {product.title}
                      </div>
                    </Row>
                    <Row>
                      {t('orders.shipment.summary.sendTo')}:{' '}
                      {
                        orders.find(({ id }) => id === shipment.saleOrderId)
                          .destinationAddress.address1
                      }
                    </Row>
                  </>
                );
              })}
            <Row
              justify="end"
              style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
            >
              {!loadingRates && (
                <Typography.Text className="grey-box">
                  {selectedService &&
                    (rates[index]
                      ? `Costo de envío: ${new Intl.NumberFormat('es-CL', {
                          style: 'currency',
                          currency: rates[index][selectedService].currency,
                        }).format(rates[index][selectedService].rates.ACCOUNT)}`
                      : 'Error al estimar el costo de este envío')}
                  {!selectedService &&
                    'Selecciona un tipo de servicio de envío'}
                </Typography.Text>
              )}
            </Row>
          </Col>
        </Row>
      ))}
      <Row justify="end" style={{ marginTop: 24 }}>
        {productSend && (
          <Col style={{ marginRight: 24 }}>
            <Affix offsetBottom={10}>
              <Space>
                <Button
                  className="ant-btn"
                  onClick={downloadCourierShipmentsLabels}
                >
                  {t('common.print')} <PrinterOutlined />
                </Button>
              </Space>
            </Affix>
          </Col>
        )}
        <Col>
          <Affix offsetBottom={10}>
            <Space>
              <Button
                className={
                  productSend ? 'btn-link-filled-disabled' : 'btn-link-filled'
                }
                icon={<SendOutlined />}
                onClick={onFinish}
                loading={isLoading}
                disabled={productSend || !selectedService}
              >
                {t('common.send')}
              </Button>
            </Space>
          </Affix>
        </Col>
      </Row>
    </Col>
  );
}

PickupSummaryStep.propTypes = {
  orders: PropTypes.arrayOf(Object).isRequired,
  warehouseId: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  courierId: PropTypes.number.isRequired,
  courierCredentials: PropTypes.instanceOf(Object).isRequired,
  fboOrigin: PropTypes.instanceOf(Object),
};

PickupSummaryStep.defaultProps = {
  fboOrigin: undefined,
};

export default PickupSummaryStep;

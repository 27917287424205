import {
  Button,
  Col,
  Form,
  InputNumber,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

function Pallets({ shipmentId, pallets, setPallets, unit, disabled }) {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState();
  const [count, setCount] = useState();
  const [form] = useForm();

  const lengthUnit = unit === 'cm' ? 'cm' : 'in';
  const weightUnit = unit === 'cm' ? 'kg' : 'lb';

  const formatterNumber = (value) => value.replace(',', '.');
  const parserNumber = (value) => parseFloat(value.replace(',', '.'));

  useEffect(() => {
    const shipmentPallets = pallets.find(
      (pallet) => pallet.shipmentId === shipmentId
    );
    if (shipmentPallets) {
      setDataSource(
        shipmentPallets.pallets.map((row, index) => ({ ...row, key: index }))
      );
      setCount(shipmentPallets.pallets.length + 1);
    } else setDataSource([]);
  }, [pallets]);

  const handleRemovePallet = (key) => {
    const newPallets = pallets;
    const index = newPallets.findIndex(
      (shipmentPallets) => shipmentPallets.shipmentId === shipmentId
    );
    newPallets[index].pallets.splice(key, 1);
    setPallets(newPallets);
    const newDataSource = [...dataSource];
    newDataSource.splice(key, 1);
    setDataSource(newDataSource);
  };

  const handleAddPallet = (values) => {
    const newPalletRow = {
      key: count,
      ...values,
    };
    setDataSource([...dataSource, newPalletRow]);
    setCount(count + 1);
    const actualPallets = pallets;
    let shipmentPallets = actualPallets.find(
      (shipment) => shipment.shipmentId === shipmentId
    );
    if (!shipmentPallets) {
      shipmentPallets = {
        shipmentId,
        pallets: [],
      };
      actualPallets.push(shipmentPallets);
    }
    const shipmentPalletsIndex = pallets.findIndex(
      (shipment) => shipment.shipmentId === shipmentId
    );
    actualPallets[shipmentPalletsIndex].pallets.push(values);
    setPallets(actualPallets);
    form.resetFields();
  };

  const columns = [
    {
      title: t('common.length'),
      dataIndex: 'length',
      key: 'length',
    },
    {
      title: t('common.width'),
      dataIndex: 'width',
      key: 'width',
    },
    {
      title: t('common.height'),
      dataIndex: 'height',
      key: 'height',
    },
    {
      title: t('common.weight'),
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: t('common.remove'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_text, row, index) => (
        <Popconfirm
          title={t('orders.pallets.erase')}
          onConfirm={() => handleRemovePallet(index)}
          okButtonProps={{ danger: true }}
          okText={t('common.remove')}
          cancelText={t('common.cancel')}
        >
          <Button danger disabled={disabled}>
            <DeleteOutlined style={{ fill: 'red' }} />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Col span={24}>
      <Row>
        <Col
          style={{
            alignItems: 'flex-start',
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Typography.Text className="restock-boxes-table-title">
            {t('orders.pallets.determine')}
          </Typography.Text>
        </Col>
      </Row>
      <Row span={24}>
        <Col span={24}>
          {dataSource && (
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              className="listing-shipment-table"
            />
          )}
          <Form form={form} onFinish={handleAddPallet} layout="inline">
            <Space>
              <Form.Item
                label={t('common.length')}
                name="length"
                rules={[
                  { required: true, message: t('orders.pallets.enterLength') },
                ]}
              >
                <InputNumber
                  min={0}
                  addonAfter={<Typography.Text>{lengthUnit}</Typography.Text>}
                  formatter={formatterNumber}
                  parser={parserNumber}
                  className="form-input-length"
                  disabled={disabled}
                />
              </Form.Item>
              <Form.Item
                label={t('common.width')}
                name="width"
                rules={[
                  { required: true, message: t('orders.pallets.enterWidth') },
                ]}
              >
                <InputNumber
                  min={0}
                  addonAfter={<Typography.Text>{lengthUnit}</Typography.Text>}
                  formatter={formatterNumber}
                  parser={parserNumber}
                  className="form-input-length"
                  disabled={disabled}
                />
              </Form.Item>
              <Form.Item
                label={t('common.height')}
                name="height"
                rules={[
                  { required: true, message: t('orders.pallets.enterHeight') },
                ]}
                disabled={disabled}
              >
                <InputNumber
                  min={0}
                  addonAfter={<Typography.Text>{lengthUnit}</Typography.Text>}
                  formatter={formatterNumber}
                  parser={parserNumber}
                  className="form-input-length"
                  disabled={disabled}
                />
              </Form.Item>
              <Form.Item
                label={t('common.weight')}
                name="weight"
                rules={[
                  { required: true, message: t('orders.pallets.enterWeight') },
                ]}
              >
                <InputNumber
                  min={0}
                  addonAfter={<Typography.Text>{weightUnit}</Typography.Text>}
                  formatter={formatterNumber}
                  parser={parserNumber}
                  className="form-input-length"
                  disabled={disabled}
                />
              </Form.Item>
            </Space>
            <Row style={{ marginTop: 10 }}>
              <Button
                icon={<PlusOutlined />}
                onClick={() => form.submit()}
                disabled={disabled}
              >
                {t('orders.pallets.add')}
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
    </Col>
  );
}

Pallets.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  pallets: PropTypes.arrayOf(
    PropTypes.shape({
      pallets: PropTypes.arrayOf({
        length: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        weight: PropTypes.number,
      }),
      shipmentId: PropTypes.string,
    })
  ).isRequired,
  setPallets: PropTypes.func.isRequired,
  unit: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Pallets;

import React, { useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { useHistory, useLocation } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Spin,
  Typography,
  Modal,
  Space,
  Checkbox,
} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useStore } from 'react-redux';
import VerificationInput from 'react-verification-input';
import * as queryString from 'query-string';
import * as Actions from '../../../redux/session/action';
import {
  keyPressSpaceBar,
  mixPanelCreateEvent,
} from '../../../utils/functions';
import UsersAPI from '../../../api/users';
import CompaniesAPI from '../../../api/company';
import AuthApi from '../../../api/auth';
import openNotification from '../../../components/Toastr';
import { getErrorMessage, initAxiosInterceptors } from '../../../api/api';
import './register.css';

const { Item } = Form;

function Register() {
  const { Title, Text, Link } = Typography;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const history = useHistory();
  const location = useLocation();
  const store = useStore();

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [codeForm] = Form.useForm();
  const [credentials, setCredentials] = useState({
    companyName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    validationCode: '',
  });

  const [formFinished, setFormFinished] = useState(false);
  const [confirmedUser, setConfirmedUser] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [createAccounteEnabled, setCreateAccounteEnabled] = useState(false);

  const UserErrors = {
    'A user with the provided email already exists.':
      'register.errors.existingUser',
  };

  const qs = queryString.parse(location.search);
  let learnworldsRegister = false;
  if (['signup', 'passwordreset'].includes(qs.action) && qs.redirectUrl) {
    learnworldsRegister = true;
  } else if (['login'].includes(qs.action) && qs.redirectUrl) {
    history.replace('/login');
  }

  const passwordIconRender = (visible) =>
    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value.trim(),
    });
  };

  const onCancel = () => {
    form.resetFields();
    codeForm.resetFields();
    setShowModal(false);
  };

  const cognitoSignUp = async (values) => {
    setLoading(true);
    const payload = {
      email: values.email,
      password: values.password,
    };
    try {
      await UsersAPI.cognitoSignUp(payload);
      setFormFinished(true);
      setShowModal(true);
      openNotification({
        status: true,
        content: t('register.verificationCodeSent'),
      });
    } catch (error) {
      openNotification({
        status: false,
        content: UserErrors[error.response?.data?.message]
          ? t(UserErrors[error.response?.data?.message])
          : getErrorMessage(error),
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmCognitoSignUp = async () => {
    const email = form.getFieldValue('email');
    const code = codeForm.getFieldValue('validationCode');
    const payload = {
      email,
      code,
    };
    await UsersAPI.confirmCognitoSignUp(payload);
    setConfirmedUser(true);
  };

  const createUser = async (values, companyId) => {
    const payload = {
      email: values.email,
      name: values.username,
      companyId,
    };
    try {
      await UsersAPI.create(payload);
    } catch (error) {
      openNotification({ status: false });
    }
  };

  const createCompany = async (values) => {
    let response;
    const payload = {
      name: values.companyName,
      email: values.email,
      active: false,
    };
    try {
      response = await CompaniesAPI.create(payload);
      return response.data;
    } catch (error) {
      openNotification({ status: false });
      return null;
    }
  };

  const onFinishForm = async (values) => {
    if (!formFinished) {
      await cognitoSignUp(values);
    }
  };

  const login = async (creds) => {
    const loginPayload = creds;
    setLoading(true);
    dispatch(Actions.login());

    if (qs && qs.link) {
      loginPayload.link = qs.link;
    }
    try {
      const response = await AuthApi.login(loginPayload);
      initAxiosInterceptors(store);
      if (response.status === 200 && response.data.success) {
        dispatch(Actions.loginSuccess(response.data));
        mixpanel.identify(response.data?.userInfo?.login);
        mixpanel.people.set({ $name: response.data?.userInfo?.name });
        mixpanel.people.set({ $email: response.data?.userInfo?.login });
        mixpanel.people.set({ $createdAt: response.data?.userInfo.createdAt });
        mixpanel.people.set({ $companyActive: response.data?.companyActive });
        mixpanel.people.set({
          $subscriptionType: response.data?.userInfo?.subscription?.type || '',
        });
        mixpanel.people.set({
          $subscriptionName: response.data?.userInfo?.subscription?.name || '',
        });
        mixPanelCreateEvent('Successful login');
      } else {
        dispatch(Actions.loginFailed(response.data.message));
        setLoading(false);
      }
      if (learnworldsRegister) {
        const learnWorldsResponse = await AuthApi.learnworlds({
          redirectUrl: qs.redirectUrl,
        });
        window.location.href = learnWorldsResponse.data.url;
      } else {
        history.push('/');
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(Actions.loginFailed(t('login.IncorrectEmailOrPassword')));
        setLoading(false);
      } else {
        dispatch(Actions.loginFailed(error.response.data.message));
        setLoading(false);
      }
    }
  };

  const register = async () => {
    setLoading(true);
    try {
      if (!confirmedUser) {
        await confirmCognitoSignUp();
      }
      const company = await createCompany(credentials);
      await createUser(credentials, company.id);

      const loginCreds = {
        username: credentials.email,
        password: credentials.password,
      };
      await login(loginCreds);
      setLoading(false);
    } catch (error) {
      openNotification({
        status: false,
        content: t('register.verificationError'),
      });
      setLoading(false);
    }
  };

  const validatePassword = async (rule, value) => {
    const lower = /[a-z]+/.test(value);
    const upper = /[A-Z]+/.test(value);
    const number = /[0-9]+/.test(value);
    const special = /(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/.test(value);
    const length = value?.length >= 8;
    if (!lower) {
      return Promise.reject(Error(t('login.passwordLowerCaseError')));
    }
    if (!upper) {
      return Promise.reject(Error(t('login.passwordUpperCaseError')));
    }
    if (!number) {
      return Promise.reject(Error(t('login.passwordNumberError')));
    }
    if (!special) {
      return Promise.reject(Error(t('login.passwordSpecialCharError')));
    }
    if (!length) {
      return Promise.reject(Error(t('login.passwordLengthError')));
    }
    return Promise.resolve();
  };

  const validateConfirmPassword = async (rule, value) => {
    const passwordsAreSame = value === form.getFieldValue('password');
    if (!passwordsAreSame) {
      return Promise.reject(Error(t('register.confirmPassword.error')));
    }
    return Promise.resolve();
  };

  const onCheckboxChange = (e) => {
    if (e.target.checked) {
      setCreateAccounteEnabled(true);
    } else {
      setCreateAccounteEnabled(false);
    }
  };

  return (
    <div className="register-container">
      <Modal open={showModal} width="50%" footer={null} onCancel={onCancel}>
        <Title>{t('register.verificationModal.title')}</Title>
        <Form form={codeForm} onFinish={register} layout="vertical">
          <Text
            style={{ textAlign: 'left', fontSize: '15px', fontWeight: 400 }}
          >
            {t('register.verificationModal.description')}
          </Text>
          <br />
          <Form.Item name="validationCode" style={{ paddingTop: '20px' }}>
            <div>
              <Text
                style={{ textAlign: 'left', fontSize: '15px', fontWeight: 400 }}
              >
                {t('register.verificationModal.input')}
              </Text>
              <VerificationInput
                validChars="0-9"
                inputProps={{ inputMode: 'numeric', name: 'validationCode' }}
                onChange={(value) =>
                  setCredentials({
                    ...credentials,
                    validationCode: value.trim(),
                  })
                }
                classNames={{
                  character: 'character',
                  characterInactive: 'character--inactive',
                  characterSelected: 'character--selected',
                }}
              />
            </div>
          </Form.Item>
          <div style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              onClick={() => codeForm.submit()}
              loading={loading}
            >
              {t('common.confirm')}
            </Button>
          </div>
        </Form>
      </Modal>
      <Row className="register-layout">
        <Col xs={0} sm={12} span={12} className="register-left">
          <div className="register-content-texts">
            <div className="register-logo-container">
              <Title style={{ textAlign: 'left', color: 'white' }} level={2}>
                {t('login.header.0')}{' '}
                <span style={{ color: '#00e5a6' }}>{t('login.header.1')}</span>
              </Title>
              <hr className="register-hr-texts" />
              <Text
                style={{ textAlign: 'left', color: 'white', marginRight: 22 }}
                className="register-title"
              >
                {t('login.header.2')}
              </Text>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} span={12}>
          <div className="register-content-inputs">
            <Spin spinning={loading}>
              <div className="register-logo-container">
                <Title className="register-title-right" level={2}>
                  {t('login.formHeader.0')}
                </Title>
                {learnworldsRegister ? (
                  <Text className="register-subtitle">
                    {t('register.formHeader.lapAcademy')}
                  </Text>
                ) : (
                  <Text className="register-subtitle">
                    {t('register.formHeader.normal')}
                  </Text>
                )}
              </div>
              <Form
                name="formulario"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: -3,
                }}
                layout="vertical"
                onFinish={onFinishForm}
                form={form}
              >
                <Space
                  direction="vertical"
                  size="large"
                  style={{ width: '100%' }}
                >
                  <Item
                    name="username"
                    label="Nombre de usuario"
                    rules={[
                      {
                        required: true,
                        message: t('login.usernameEmpty'),
                      },
                    ]}
                  >
                    <Input
                      name="username"
                      placeholder="Juan Pérez"
                      autoComplete="username"
                      className="register-form-item"
                      onChange={(e) => handleChange(e)}
                      onKeyPress={(e) => keyPressSpaceBar(e)}
                    />
                  </Item>
                  <Item
                    name="companyName"
                    label={t('register.companyName.label')}
                    rules={[
                      {
                        required: true,
                        message: t('register.companyName.error'),
                      },
                    ]}
                  >
                    <Input
                      name="companyName"
                      placeholder={t('register.companyName.placeholder')}
                      className="register-form-item"
                      autoComplete="organization"
                      onChange={(e) => handleChange(e)}
                      onKeyPress={(e) => keyPressSpaceBar(e)}
                    />
                  </Item>
                  <Item
                    label={t('login.email')}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t('login.emailEmpty'),
                      },
                      {
                        type: 'email',
                        message: t('login.invalidEmail'),
                      },
                    ]}
                  >
                    <Input
                      name="email"
                      autoComplete="email"
                      className="register-form-item"
                      type="email"
                      placeholder={t('login.emailPlaceholder')}
                      onChange={(e) => handleChange(e)}
                      onKeyPress={(e) => keyPressSpaceBar(e)}
                    />
                  </Item>
                  <Item
                    label={t('login.password')}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: t('login.passwordEmpty'),
                      },
                      {
                        validator: validatePassword,
                      },
                    ]}
                  >
                    <Input.Password
                      name="password"
                      type="password"
                      className="register-form-item"
                      autoComplete="new-password"
                      placeholder={t('register.newPassword')}
                      onChange={(e) => handleChange(e)}
                      onKeyPress={(e) => keyPressSpaceBar(e)}
                      iconRender={passwordIconRender}
                    />
                  </Item>
                  <Item
                    label={t('register.confirmPassword.label')}
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: t('login.passwordEmpty'),
                      },
                      {
                        validator: validateConfirmPassword,
                      },
                    ]}
                  >
                    <Input.Password
                      name="confirmPassword"
                      autoComplete="new-password"
                      className="register-form-item"
                      type="password"
                      placeholder={t('register.confirmPassword.placeholder')}
                      onChange={(e) => handleChange(e)}
                      onKeyPress={(e) => keyPressSpaceBar(e)}
                      iconRender={passwordIconRender}
                    />
                  </Item>
                </Space>
                <Col span={24} style={{ paddingTop: '16px' }}>
                  <Row style={{ 'justify-content': 'center' }}>
                    <Checkbox onChange={onCheckboxChange}>
                      {t('register.termsAndConditions.0')}{' '}
                      <Link
                        href="https://app.lapmarketplace.com/terms-and-conditions-lap-marketplace.pdf"
                        target="_blank"
                      >
                        {t('register.termsAndConditions.1')}
                      </Link>{' '}
                      {t('register.termsAndConditions.2')}{' '}
                      <Link
                        href="https://app.lapmarketplace.com/privacy-policy-lap-marketplace.pdf"
                        target="_blank"
                      >
                        {t('register.termsAndConditions.3')}
                      </Link>{' '}
                      {t('register.termsAndConditions.4')}
                    </Checkbox>
                  </Row>
                </Col>
                <Col span={24} className="register-button-container">
                  <Button
                    style={{ marginLeft: -1 }}
                    disabled={!createAccounteEnabled}
                    loading={loading}
                    className="btn-primary-green md-btn register"
                    htmlType="submit"
                  >
                    {t('login.createAccount')}
                  </Button>
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                  {!learnworldsRegister ? (
                    <Button
                      type="link"
                      className="ant-btn-link"
                      onClick={() => history.push('/login')}
                    >
                      {`${t('login.loginLink')}`}
                    </Button>
                  ) : (
                    <div />
                  )}
                </Col>
              </Form>
            </Spin>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Register;

import React from 'react';
import 'antd/dist/antd.min.css';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import error from '../assets/error.png';

function NotFound() {
  const { t } = useTranslation();

  return (
    <div className="content-div">
      <Row justify="center">
        <Col xs={24} sm={24} md={24}>
          <img
            alt="Not Found"
            src={error}
            style={{ maxHeight: 350, width: 'auto' }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} style={{ paddingTop: 10 }}>
          <h1>{t('notFound.header')}</h1>
          <Link to="/">
            <Button icon={<ArrowLeftOutlined />} className="btn-primary">
              {' '}
              {t('notFound.goBack')}
            </Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
}

export default NotFound;

import api from './api';

const prefix = '/restock-orders';

const restockOrdersAPI = {
  create(companyId, fboCompanyName) {
    return api.post(`${prefix}`, { companyId, fboCompanyName });
  },
  update(id, payload) {
    return api.put(`${prefix}/${id}`, payload);
  },
  deleteRestockOrder(id, params = {}) {
    return api.delete(`${prefix}/${id}`, params);
  },
  getContent(orderId) {
    return api.get(`${prefix}/${orderId}/content`);
  },
  updateContent(orderId, data, params = {}) {
    return api.put(`${prefix}/${orderId}/updateContent`, data, params);
  },
  sendContent(orderId, data, params = {}) {
    return api.put(`${prefix}/${orderId}/sendContent`, data, params);
  },
  updateBoxes(orderId, data) {
    return api.put(`${prefix}/${orderId}/updateOrderBoxes`, data);
  },
  createBoxes(orderId, data) {
    return api.post(`${prefix}/${orderId}/restockOrderBoxes`, data);
  },
  deleteBoxes(orderId) {
    return api.delete(`${prefix}/${orderId}/orderBoxes`);
  },
  getOrdersPerPage(values) {
    return api.get(`${prefix}/${values.partnerId}/ordersPerPage`, {
      params: values,
    });
  },
  createInboundShipment(orderId) {
    return api.post(`${prefix}/${orderId}/inboundShipment`);
  },
  updateInboundShipments(orderId, payload) {
    return api.put(`${prefix}/${orderId}/inboundShipments`, payload);
  },
  getTransportDetails(orderId) {
    return api.get(`${prefix}/${orderId}/transportDetails`);
  },
  getLabels(orderId, params) {
    return api.get(`${prefix}/${orderId}/labels`, { params });
  },
  getPastOrderBoxes() {
    return api.get(`${prefix}/pastOrderBoxes`);
  },
  getListingAndProducts(params = {}) {
    return api.get(`${prefix}/listing-products`, params);
  },
  cancelRestockOrder(id, data = {}, params = {}) {
    return api.put(`${prefix}/${id}/cancel`, data, params);
  },
  putTransportDetails(id, payload = null) {
    return api.put(`${prefix}/${id}/transportDetails`, payload);
  },
  submitFBAInboundCartonContent(id) {
    return api.post(`${prefix}/${id}/FBAInboundCartonContent`);
  },
  createCourierShipments(
    id,
    serviceType = null,
    rate = null,
    shipsmartData = null
  ) {
    const payload = { serviceType, rate, shipsmartData };
    return api.post(`${prefix}/${id}/courierShipment`, payload);
  },
  updateOrderStep(id, stepId, payload) {
    return api.put(`${prefix}/${id}/step/${stepId}`, payload);
  },
  estimateTransport(id) {
    return api.post(`${prefix}/${id}/estimateTransport`);
  },
  confirmTransport(id) {
    return api.post(`${prefix}/${id}/confirmTransport`);
  },
  voidTransport(id) {
    return api.post(`${prefix}/${id}/voidTransport`);
  },
  recreatePlan(id, products) {
    const payload = { products };
    return api.put(`${prefix}/${id}/recreatePlan`, payload);
  },
};

export default restockOrdersAPI;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoComplete, Image } from 'antd';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';
import openNotification from '../../../components/Toastr';
import { getErrorMessage } from '../../../api/api';
import googleLogo from '../../../assets/google_on_white.png';
import feesAPI from '../../../api/fees';

const usePlacesInput = (setAddress) => {
  const { t } = useTranslation();
  const { google } = window;
  const [sessionToken, setSessionToken] = useState(
    new google.maps.places.AutocompleteSessionToken()
  );

  const findAddressComponent = (place, param) => {
    return place?.address_components?.find((ac) => ac.types.includes(param))
      ?.short_name;
  };

  const {
    value: location,
    suggestions,
    setValue: setLocation,
  } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions: { sessionToken },
  });

  const getAddressComponent = async (latLng, component) => {
    try {
      const value = await feesAPI
        .getAddressComponent(latLng, component)
        .then((result) => result.data);
      return value ? { [component]: String(value) } : null;
    } catch (error) {
      return null;
    }
  };

  const handlePlaceSelected = async (value, option) => {
    try {
      const getDetailsArgs = {
        placeId: option.key,
        fields: ['address_components', 'formatted_address', 'geometry'],
        sessionToken,
      };
      const place = await getDetails(getDetailsArgs).catch(() => {
        throw new Error(t('calculator.addressInput.errors.0'));
      });
      const latLng = `${place.geometry.location.lat()},${place.geometry.location.lng()}`;
      let addressComponent;
      addressComponent = await getAddressComponent(latLng, 'postalCode');
      const city =
        findAddressComponent(place, 'locality') ||
        findAddressComponent(place, 'sublocality');
      if (!addressComponent) {
        addressComponent = city
          ? { city: String(city) }
          : await getAddressComponent(latLng, 'city');
      }
      if (!addressComponent) {
        throw new Error(t('calculator.addressInput.errors.1'));
      }
      const countryCode = findAddressComponent(place, 'country');
      setAddress({
        address: place.formatted_address,
        countryCode,
        city,
        ...addressComponent,
      });
      setSessionToken(new google.maps.places.AutocompleteSessionToken());
    } catch (error) {
      openNotification({
        status: false,
        content: getErrorMessage(error),
      });
    }
  };

  const handleInputChange = (value) => {
    setLocation(value);
  };

  const renderOptions = () => {
    return suggestions.data.map((option) => (
      <AutoComplete.Option key={option.place_id} value={option.description}>
        {option.label}
      </AutoComplete.Option>
    ));
  };

  return (
    <AutoComplete
      value={location}
      onChange={handleInputChange}
      onSelect={(value, option) => handlePlaceSelected(value, option)}
      placeholder={t('calculator.addressInput.placeholder')}
    >
      {renderOptions()}
      <AutoComplete.Option
        key="poweredByGoogle"
        disabled
        style={{ textAlign: 'right' }}
      >
        <Image src={googleLogo} alt="poweredByGoogle" preview={false} />
      </AutoComplete.Option>
    </AutoComplete>
  );
};

export default usePlacesInput;

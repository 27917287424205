import Joyride from 'react-joyride';
import {
  Col,
  Row,
  Table,
  Space,
  Card,
  Button,
  Tooltip,
  Typography,
  Upload,
  Spin,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import importApi from '../../api/import';
import openNotification from '../../components/Toastr';
import { getErrorMessage } from '../../api/api';
import listingsApi from '../../api/listing';
import { updateOnboarding } from '../../redux/onboarding/action';
import './imports.css';
import { importStatus } from '../../utils/const';
import { stage3PreImport, stage3Import } from '../../stepsOnboarding';

function Imports() {
  const history = useHistory();
  const { Title } = Typography;
  const [imports, setImports] = useState([]);
  const [loadingAPI, setLoadingAPI] = useState(false);
  const dispatch = useDispatch();
  const session = useSelector((store) => store.Session.session);
  const onboardingStatus = useSelector((store) => store.Onboarding);
  const { t } = useTranslation();

  const getImports = async () => {
    importApi
      .getAll()
      .then((result) => {
        const newResult = Array.from(
          result.data.map((item) => ({
            ...item,
            completeStatus: {
              status: item.status,
              mssg: item.errors,
            },
          }))
        );
        setImports(newResult);
        setLoadingAPI(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
        setLoadingAPI(false);
      });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!session || imports?.length === 0) {
        return;
      }
      if (imports?.find((element) => element?.status === 'Processing')) {
        getImports();
      }
    }, 20000);
    return () => clearInterval(intervalId);
  }, [imports]);

  useEffect(() => {
    if (!session) {
      return;
    }
    setLoadingAPI(true);
    getImports();
  }, [session]);

  const handleUpload = async (file) => {
    listingsApi
      .uploadProductsSheet(file)
      .then(() => {
        setLoadingAPI(true);
        window.location = '/imports';
      })
      .catch((error) => {
        let content = error?.message;
        if (error?.response?.data?.message) {
          content = error.response.data.message;
        }
        openNotification({ status: false, content });
      });
  };

  const renderErrorsTooltip = (value) => {
    if (Array.isArray(value)) {
      return (
        <div className="tooltipVerticalScroll">
          <ul>
            {value?.[0]?.errors?.[0]?.message
              ? value[0].errors.map((obj) => (
                  <li>{obj?.message ?? t('imports.uploadError')}</li>
                ))
              : value?.map((obj) => (
                  <li>{obj?.message ?? t('imports.uploadError')}</li>
                ))}
          </ul>
        </div>
      );
    }
    if (!Array.isArray(value) && typeof value === 'object' && value) {
      return (
        <div className="tooltipVerticalScroll">
          <ul>
            {Object.keys(value).map(
              (key) =>
                value[key]?.errors && (
                  <li key={key}>
                    {key === 'all' ? t('imports.generic') : key}
                    <ol>
                      {value[key]?.errors?.map((messObj) => {
                        if (messObj?.message.includes('http')) {
                          const urlRegex = /(https?:\/\/[^\s]+)/g;
                          const parts = messObj?.message.split(urlRegex);
                          return parts.map((part) =>
                            urlRegex.test(part) ? (
                              <a
                                href={part}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {part}
                              </a>
                            ) : (
                              <span>{part}</span>
                            )
                          );
                        }
                        return <li>{messObj?.message}</li>;
                      })}
                    </ol>
                  </li>
                )
            )}
          </ul>
        </div>
      );
    }
    return '-';
  };

  const antIcon = (
    <LoadingOutlined
      className="importSpin"
      style={{ fontSize: 12, marginLeft: 5 }}
      spin
    />
  );

  const columns = [
    {
      title: t('imports.table.fileName.title'),
      ellipsis: true,
      dataIndex: ['fileName'],
      key: 'id',
      align: 'center',
      width: 160,
      render: (value) => {
        if (value != null) {
          return `${value}`;
        }
        return t('imports.table.fileName.emptyValue');
      },
    },
    {
      title: t('imports.table.createdAt.title'),
      ellipsis: false,
      dataIndex: ['createdAt'],
      key: 'id',
      align: 'center',
      width: 120,
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt),
      render: (value) => {
        if (value != null) {
          return moment(value).format('DD-MM-YYYY');
        }
        return t('imports.table.createdAt.emptyValue');
      },
    },
    {
      title: t('imports.table.updatedAt.title'),
      dataIndex: ['updatedAt'],
      key: 'title',
      align: 'center',
      width: 180,
      ellipsis: false,
      sorter: (a, b) => moment(a.updatedAt) - moment(b.updatedAt),
      render: (value) => {
        if (value != null) {
          return moment(value).format('DD-MM-YYYY');
        }
        return t('imports.table.updatedAt.emptyValue');
      },
    },
    {
      title: 'Marketplace',
      dataIndex: ['marketplace'],
      key: 'id',
      width: 100,
      align: 'center',
      ellipsis: false,
      render: (value) => {
        if (value != null) {
          return `${value}`;
        }
        return '-';
      },
    },
    {
      title: t('imports.table.status.title'),
      dataIndex: ['completeStatus'],
      key: 'id',
      width: 100,
      align: 'center',
      ellipsis: false,
      render: (value) => {
        if (value.status === importStatus.PROCESSING) {
          return (
            <div className="pillBox processingImport">
              {t('imports.table.status.processing')}
              <Spin indicator={antIcon} />
            </div>
          );
        }
        if (value.status === importStatus.POSTING) {
          return (
            <div className="pillBox postingImport">
              {t('imports.table.status.publishing')}
            </div>
          );
        }
        if (value.status === importStatus.COMPLETED) {
          return (
            <div className="pillBox completeImport">
              {t('imports.table.status.completed')}
            </div>
          );
        }
        if (value.status === importStatus.WARNING) {
          return (
            <Tooltip
              title={renderErrorsTooltip(value?.mssg)}
              overlayStyle={{ maxWidth: '500px' }}
            >
              <div className="pillBox warningImport">
                {value?.mssg?.length > 0
                  ? `${t('imports.table.status.warning')} (${
                      value?.mssg?.length
                    })`
                  : `${t('imports.table.status.warning')}`}
              </div>
            </Tooltip>
          );
        }
        if (value.status === importStatus.FAILED) {
          let errorsLength = 0;
          if (value?.mssg?.length > 0) {
            errorsLength = value?.mssg?.length;
          } else if (Object.keys(value?.mssg || {}).length > 0) {
            errorsLength = Object.values(value?.mssg).reduce(
              (partialSum, errorsArray) =>
                partialSum + Object.keys(errorsArray.errors || {}).length,
              0
            );
          }
          return (
            <Tooltip
              title={renderErrorsTooltip(value?.mssg)}
              overlayStyle={{ maxWidth: '500px' }}
            >
              <div className="pillBox errorImport">
                {errorsLength > 0 ? `Error (${errorsLength})` : `Error`}
              </div>
            </Tooltip>
          );
        }
        return <div>-</div>;
      },
    },
    {
      title: t('imports.table.updateExcel'),
      dataIndex: ['completeStatus'],
      key: 'id',
      width: 100,
      align: 'center',
      ellipsis: false,
      render: (value) => {
        if (
          value.status === importStatus.FAILED ||
          value.status === importStatus.WARNING
        ) {
          return (
            <Upload
              maxCount="1"
              name="file"
              beforeUpload={handleUpload}
              className="uploadProductsFile width100"
            >
              <Button className="btn-basic width100 rightButton">
                {t('common.uploadFile')}
              </Button>
            </Upload>
          );
        }
        return (
          <Upload
            maxCount="1"
            name="file"
            beforeUpload={handleUpload}
            className="uploadProductsFile width100"
          >
            <Button className="btn-basic width100 rightButton" disabled>
              {t('common.uploadFile')}
            </Button>
          </Upload>
        );
      },
    },
  ];

  const handleSkipTourCallback = (data) => {
    const { action } = data;
    if (action === 'close') {
      dispatch(updateOnboarding(null, null));
    } else if (action === 'next') {
      if (
        onboardingStatus.state === 'FirstPost' &&
        onboardingStatus.step === 7
      ) {
        dispatch(updateOnboarding('FirstPost', 8));
      }
    }
  };

  const handleEndTourCallback = (data) => {
    const { action, status } = data;
    if (action === 'close' || (action === 'next' && status === 'finished')) {
      dispatch(updateOnboarding(null, null));
    }
  };

  return (
    <div className="content-div container-products">
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Card>
          <Button
            className="btn-basic"
            onClick={() => history.push('/catalogo/editar')}
          >
            <Space>{t('imports.fields.backToCatalogue')}</Space>
          </Button>
        </Card>
        <Card>
          <div className="welcome-container">
            <div>
              <Title level={3} className="titlePages">
                {t('imports.mainTitle')}
              </Title>
              <span className="subtitlePages">{t('imports.mainSubtitle')}</span>
            </div>
          </div>
        </Card>
        <Card>
          <Row className="products-table">
            <Col span={24} className="stepPre8FirstPost">
              <Table
                dataSource={imports}
                loading={loadingAPI}
                columns={columns}
                pagination
                expandable={{ showExpandColumn: false }}
                showHeader
                scroll={{ x: '100%' }}
              />
            </Col>
          </Row>
        </Card>
      </Space>
      {onboardingStatus.state === 'FirstPost' &&
        onboardingStatus.step === 7 && (
          <Joyride
            steps={stage3PreImport}
            callback={handleSkipTourCallback}
            disableCloseOnEsc
            spotlightClicks
            disableScrolling
            continuous
            hideBackButton
          />
        )}
      {onboardingStatus.state === 'FirstPost' &&
        onboardingStatus.step === 8 && (
          <Joyride
            steps={stage3Import}
            callback={handleEndTourCallback}
            disableCloseOnEsc
            spotlightClicks
            disableScrolling
            continuous
            hideBackButton
          />
        )}
    </div>
  );
}

export default Imports;

import api from './api';

const prefix = 'partners';

const partnerApi = {
  getAll() {
    return api.get(`${prefix}`);
  },
  findById(id) {
    return api.get(`${prefix}/${id}`);
  },
  findByIdPartner(id) {
    return api.get(`${prefix}/${id}/partner`);
  },
  findLogoById(id) {
    return api.get(`${prefix}/${id}/image_1920`);
  },
  insert(partner) {
    return api.post(`${prefix}`, partner);
  },
  update(partner) {
    return api.put(`${prefix}`, partner);
  },
  updateWebsite(partner) {
    return api.put(`${prefix}/partner/website`, partner);
  },
  delete(partnerId) {
    return api.delete(`${prefix}`, partnerId);
  },
  getAllListings(partnerId) {
    return api.get(`${prefix}/${partnerId}/tableListings`);
  },
  getListingsPerPage(values) {
    return api.get(`${prefix}/${values.partnerId}/listingsPerPage`, {
      params: values,
    });
  },
  updateCanPublish(values) {
    return api.put(
      `${prefix}/${values.partnerId}/canPublish/${values.canPublish}`
    );
  },
  updateHasAutofulfillmentOutboundFBA(values) {
    return api.put(
      `${prefix}/${values.partnerId}/marketplace/${values.marketplace}/hasAutofulfillmentOutboundFBA/${values.canAutofulfillmentFBA}`
    );
  },
  insertListing(partnerId, listing) {
    return api.post(`${prefix}/${partnerId}/listings`, listing);
  },
  insertListingOrderProposal(partner, partnerId, listingId) {
    return api.post(
      `${prefix}/${partnerId}/listings/${listingId}/orderProposal`,
      partner
    );
  },
  insertListingOrderDraft(partner, partnerId, listingId) {
    return api.post(
      `${prefix}/${partnerId}/listings/${listingId}/orderDraft`,
      partner
    );
  },
  getAllDocuments(partnerId) {
    return api.get(`${prefix}/${partnerId}/documents`);
  },
  getPrices(partnerId, listingId) {
    return api.get(`${prefix}/${partnerId}/listings/${listingId}/prices`);
  },
  getCompetitorDocument(partnerId, listingId, clientId) {
    return api.get(
      `${prefix}/${partnerId}/listings/${listingId}/competitorDocument/${clientId}`
    );
  },
  updatePrices(partnerId, listingId, data) {
    return api.put(`${prefix}/${partnerId}/listings/${listingId}/prices`, data);
  },
  getOrder(partnerId, listingId) {
    return api.get(`${prefix}/${partnerId}/listings/${listingId}/order`);
  },
  sendPrices(partnerId, listingId, data) {
    return api.put(
      `${prefix}/${partnerId}/listings/${listingId}/sendPrice`,
      data
    );
  },
  acceptProposal(partnerId, listingId, step) {
    return api.put(
      `${prefix}/${partnerId}/listings/${listingId}/acceptProposal`,
      step
    );
  },
  rejectProposal(partnerId, listingId, data) {
    return api.put(
      `${prefix}/${partnerId}/listings/${listingId}/rejectProposal`,
      data
    );
  },
  deleteListing(partnerId, listingId) {
    return api.delete(`${prefix}/${partnerId}/listings/${listingId}`);
  },
  revertListing(partnerId, listingId, data) {
    return api.post(
      `${prefix}/${partnerId}/listings/${listingId}/revert`,
      data
    );
  },
  updateOrder(partnerId, listingId, data) {
    return api.put(`${prefix}/${partnerId}/listings/${listingId}/order`, data);
  },
  sendOrder(partnerId, listingId, data) {
    return api.put(
      `${prefix}/${partnerId}/listings/${listingId}/sendOrder`,
      data
    );
  },
  updateProperty(partnerId, listingId, marketplace, data) {
    return api.put(
      `${prefix}/${partnerId}/listings/${listingId}/${marketplace}/updateProperty`,
      data
    );
  },
  updateProductsByListingAndMarketplace(
    partnerId,
    listingId,
    marketplace,
    data
  ) {
    return api.put(
      `${prefix}/${partnerId}/listings/${listingId}/${marketplace}/updateProducts`,
      data
    );
  },
  getFulfillment(partnerId, listingId) {
    return api.get(`${prefix}/${partnerId}/listings/${listingId}/fulfillment`);
  },
  updateFulfillment(partnerId, listingId, data) {
    return api.put(
      `${prefix}/${partnerId}/listings/${listingId}/fulfillment`,
      data
    );
  },
  sendFulfillment(partnerId, listingId, data) {
    return api.put(
      `${prefix}/${partnerId}/listings/${listingId}/sendFulfillment`,
      data
    );
  },
  getMeasures(partnerId, listingId) {
    return api.get(`${prefix}/${partnerId}/listings/${listingId}/measures`);
  },
  updateMeasures(partnerId, listingId, data) {
    return api.put(
      `${prefix}/${partnerId}/listings/${listingId}/measures`,
      data
    );
  },
  sendMeasures(partnerId, listingId, data) {
    return api.put(
      `${prefix}/${partnerId}/listings/${listingId}/sendMeasures`,
      data
    );
  },
  exportListing(partnerId, listingId, marketplace) {
    const config = { headers: { accept: '*/*' }, responseType: 'arraybuffer' };
    return api.post(
      `${prefix}/${partnerId}/listings/${listingId}/marketplace/${marketplace}/exportListing`,
      {},
      config
    );
  },
  listingsAndProducts(
    companyId,
    allMarketplaces = false,
    marketplace = undefined
  ) {
    const params = {
      allMarketplaces,
    };
    if (allMarketplaces) {
      return api.get(
        `${prefix}/${companyId}/listingsAndProducts/${marketplace}`,
        { params }
      );
    }
    if (marketplace)
      return api.get(
        `${prefix}/${companyId}/listingsAndProducts/${marketplace}`
      );
    return null;
  },
  productsWithInventoryAtOriginFba(partnerId, marketplace) {
    return api.get(
      `${prefix}/${partnerId}/products/marketplace/${marketplace}/inventoryAtOriginFba`
    );
  },
  updateFbaSkuByProduct(partnerId, productId, fbaSku) {
    return api.put(
      `${prefix}/${partnerId}/products/${productId}/fbaSku/${fbaSku}`
    );
  },
  deleteFbaSkuByProduct(partnerId, productId) {
    return api.delete(`${prefix}/${partnerId}/products/${productId}/fbaSku`);
  },
  migrateListings(partnerId, marketplace, data) {
    return api.put(
      `${prefix}/${partnerId}/listings/${marketplace}/migrateListings`,
      data
    );
  },
  policiesByPartnerAndMarketplace(partnerId, marketplace) {
    return api.get(
      `${prefix}/${partnerId}/marketplace/${marketplace}/policiesSatate`
    );
  },
  homologateListings(partnerId, payload) {
    return api.put(`${prefix}/${partnerId}/homologate/listings`, payload);
  },
  homologateCategories(partnerId, payload) {
    return api.post(`${prefix}/${partnerId}/homologate/categories`, payload);
  },
  getCategoriesHomologated(partnerId) {
    return api.get(`${prefix}/${partnerId}/homologate/categories`);
  },
  getMarketplacesAllowed(partnerId, type) {
    return api.get(`${prefix}/${partnerId}/marketplaces_allowed/${type}`);
  },
};

export default partnerApi;

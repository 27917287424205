import { Link } from 'react-router-dom';
import {
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import productApi from '../../../../../../api/product';
import phones from '../../../../../../utils/json/phones.json';

function ProductRow({ product }) {
  const { t } = useTranslation();
  const formatterNumber = (value) => value.replace(',', '.');

  const parserNumber = (value) => parseFloat(value.replace(',', '.'));

  let image;
  if (product?.attributes && Array.isArray(product?.attributes))
    image = product?.attributes.main_offer_image_locator
      ? product?.attributes.main_offer_image_locator[0]?.media_location
      : null;
  else
    image = product?.attributes.main_offer_image_locator
      ? product?.attributes.main_offer_image_locator[0]?.media_location
      : null;
  return (
    <Col span={24}>
      <Row>
        <Col span={4}>
          <Image preview={false} src={image} />
        </Col>
        <Col span={20} style={{ display: 'flex', alignItems: 'center' }}>
          <Row gutter={[16, 16]}>
            <Col>{product.title}</Col>
            <Space>
              <Col>SKU:</Col>
              <Col>
                <Typography.Text strong> {product.defaultCode}</Typography.Text>
              </Col>
            </Space>
          </Row>
        </Col>
      </Row>
      <Row
        style={{ display: 'flex', justifyContent: 'center' }}
        gutter={[10, 0]}
      >
        <Col span={8}>
          <Form.Item
            label={
              <Tooltip
                title={
                  <>
                    {t('orders.shipment.products.unitFobTooltip')}{' '}
                    <Link
                      to={{
                        pathname:
                          'https://ayuda.lapmarketplace.com/hc/es-cl/articles/12321298472859-FOB-Declarado',
                      }}
                      target="_blank"
                    >
                      {t('common.wantKnowMore')}
                    </Link>
                  </>
                }
              >
                {t('orders.shipment.products.unitFob')} <InfoCircleOutlined />
              </Tooltip>
            }
            initialValue={product.productTemplate.unitFOB}
            name={['productTemplate', 'unitFOB']}
            rules={[
              {
                required: true,
                message: 'Debe ingresar el FOB unitario.',
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              name={`fob-${product.id}`}
              formatter={formatterNumber}
              parser={parserNumber}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <Tooltip
                title={t(
                  'orders.shipment.products.countryOfManufactureTooltip'
                )}
              >
                {t('orders.shipment.products.countryOfManufacture')}{' '}
                <InfoCircleOutlined />
              </Tooltip>
            }
            name={['productTemplate', 'countryOfManufacture']}
            initialValue={product.productTemplate.countryOfManufacture}
            rules={[
              {
                required: true,
                message: 'Debe seleccionar el país de manufactura',
              },
            ]}
          >
            <Select
              showSearch
              filterOption={(input, option) =>
                option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {phones.map((phoneRow) => (
                <Select.Option key={phoneRow.name} value={phoneRow.code}>
                  {phoneRow.flag} {phoneRow.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <Tooltip
                title={
                  <>
                    {t('orders.shipment.products.harmonizedCodeTooltip')}{' '}
                    <Link
                      to={{
                        pathname:
                          'https://ayuda.lapmarketplace.com/hc/es-cl/articles/12743698364955',
                      }}
                      target="_blank"
                    >
                      {t('common.wantKnowMore')}
                    </Link>
                  </>
                }
              >
                {t('orders.shipment.products.harmonizedCode')}{' '}
                <InfoCircleOutlined />
              </Tooltip>
            }
            name={['productTemplate', 'harmonizedCode']}
            initialValue={product.productTemplate.harmonizedCode}
            rules={[
              {
                required: true,
                message: 'Debe ingresar el código armonizado del producto.',
              },
            ]}
          >
            <Input name={`harmonizedCode-${product.id}`} />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );
}

function ProductsModal({
  selectedRowKeys,
  sendContentLoading,
  setSendContentLoading,
  contentsForm,
  isPartnered,
}) {
  const [form] = Form.useForm();
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState();
  const [currentProductIndex, setCurrentProductIndex] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [closeModal, setCloseModal] = useState(false);

  useEffect(() => {
    if (sendContentLoading && products.length > 0 && !isPartnered) {
      setIsOpen(true);
    } else if (sendContentLoading && !closeModal) {
      contentsForm.submit();
    } else setCloseModal(false);
  }, [sendContentLoading]);

  const onFinish = async (values) => {
    try {
      await productApi.update(currentProduct.id, values);
      if (currentProductIndex === products.length - 1) {
        setIsOpen(false);
        contentsForm.submit();
      }
      setCurrentProductIndex(currentProductIndex + 1);
      setCurrentProduct(products[currentProductIndex + 1]);
      form.resetFields();
    } catch (error) {
      /* empty */
    }
  };

  useEffect(() => {
    const getProductsByIds = async () => {
      try {
        const params = {
          productIds: JSON.stringify(selectedRowKeys),
        };
        const response = await productApi.getByIds(params);

        if (response.data.length > 0) {
          const missingAttributesProducts = [];
          response.data.forEach((product) => {
            if (
              !product.productTemplate.countryOfManufacture ||
              !product.productTemplate.unitFOB ||
              !product.productTemplate.harmonizedCode
            ) {
              missingAttributesProducts.push(product);
            }
          });
          setProducts(missingAttributesProducts);
          if (missingAttributesProducts.length > 0) {
            setCurrentProduct(missingAttributesProducts[0]);
            setCurrentProductIndex(0);
          }
        }
      } catch (error) {
        /* empty */
      }
    };
    getProductsByIds();
  }, [selectedRowKeys, sendContentLoading]);

  const handleNext = async () => {
    form.submit();
  };
  const handleCancel = () => {
    form.resetFields();
    setIsOpen(false);
    setCloseModal(true);
    setSendContentLoading(false);
  };
  return (
    <Modal
      title="Confirma la información de los productos"
      open={isOpen}
      onOk={handleNext}
      onCancel={handleCancel}
      okText="Siguiente"
      width="80%"
    >
      {sendContentLoading && (
        <Form form={form} layout="vertical" onFinish={onFinish}>
          {products.length > 0 && currentProduct && (
            <Col span={24}>
              <ProductRow product={currentProduct} />
            </Col>
          )}
        </Form>
      )}
    </Modal>
  );
}

ProductsModal.propTypes = {
  selectedRowKeys: PropTypes.arrayOf(PropTypes.number).isRequired,
  sendContentLoading: PropTypes.bool.isRequired,
  setSendContentLoading: PropTypes.func.isRequired,
  contentsForm: PropTypes.instanceOf(Form).isRequired,
  isPartnered: PropTypes.bool.isRequired,
};

ProductRow.propTypes = {
  product: PropTypes.shape({
    productTemplate: PropTypes.shape({
      unitFOB: PropTypes.number,
      countryOfManufacture: PropTypes.string,
      harmonizedCode: PropTypes.string,
    }),
    defaultCode: PropTypes.string,
    title: PropTypes.string,
    attributes: PropTypes.arrayOf(
      PropTypes.shape({
        is_expiration_dated_product: PropTypes.shape({
          value: PropTypes.bool,
        }),
        main_offer_image_locator: PropTypes.arrayOf(
          PropTypes.shape({
            media_location: PropTypes.string,
          })
        ),
      })
    ),
    name: PropTypes.string,
    price: PropTypes.string,
    sku: PropTypes.string,
    id: PropTypes.number,
    pack: PropTypes.bool,
  }).isRequired,
};

export { ProductRow, ProductsModal };

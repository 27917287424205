import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import TableOrderSales from './TableOrderSales';
import {
  orderFulfillmentStatusPedidos,
  orderStatesPedidos,
} from '../../../utils/const';

const { TabPane } = Tabs;

function OrderSalesTabs({
  orderSales,
  setModalVisible,
  tableSelectedRows,
  setTableSelectedRows,
  showShipmentModal,
  setShowShipmentModal,
  updateOrders,
}) {
  const [data, setData] = useState({
    pending: [],
    unshipped: [],
    unshippedErrors: [],
    readyForPickup: [],
    shipped: [],
    canceled: [],
    completed: [],
    refunded: [],
  });

  useEffect(() => {
    const currentData = {
      pending: [],
      unshipped: [],
      unshippedErrors: [],
      readyForPickup: [],
      shipped: [],
      canceled: [],
      completed: [],
      refunded: [],
    };
    if (orderSales?.length > 0) {
      orderSales.forEach((order) => {
        if (order?.orderStatus) {
          if (order?.refunds) {
            currentData.refunded.push(order);
            return;
          }
          if (order?.marketplace === 'Edicommerce') {
            switch (order?.orderFulfillmentStatus) {
              case orderFulfillmentStatusPedidos.PENDING:
                currentData.pending.push(order);
                break;
              case orderFulfillmentStatusPedidos.CREATED:
                currentData.readyForPickup.push(order);
                break;
              case orderFulfillmentStatusPedidos.CANCELED:
                currentData.canceled.push(order);
                break;
              case orderFulfillmentStatusPedidos.COMPLETED:
                currentData.shipped.push(order);
                break;
              default:
                break;
            }
          } else {
            switch (order?.orderStatus) {
              case orderStatesPedidos.PENDING:
                currentData.pending.push(order);
                break;
              case orderStatesPedidos.INVOICE_UNCONFIRMED:
                currentData.pending.push(order);
                break;
              case orderStatesPedidos.PENDING_AVAILABILITY:
                currentData.pending.push(order);
                break;
              case orderStatesPedidos.UNSHIPPED:
                currentData.pending.push(order);
                if (order?.shipment?.canAutoFulfillmentOutbound) {
                  currentData.unshipped.push(order);
                } else {
                  currentData.unshippedErrors.push(order);
                }
                break;
              case orderStatesPedidos.READY_FOR_PICKUP:
                currentData.readyForPickup.push(order);
                break;
              case orderStatesPedidos.PARTIALLY_SHIPPED:
                currentData.readyForPickup.push(order);
                break;
              case orderStatesPedidos.SHIPPED:
                if (order.orderFulfillmentStatus === 'Completed') {
                  currentData.shipped.push(order);
                } else if (order.orderFulfillmentStatus === 'Processing') {
                  currentData.readyForPickup.push(order);
                } else if (order.orderFulfillmentStatus === 'Canceled') {
                  currentData.canceled.push(order);
                } else if (order.orderFulfillmentStatus === 'Unfulfillable') {
                  currentData.canceled.push(order);
                } else if (order.orderFulfillmentStatus === 'Invalid') {
                  currentData.canceled.push(order);
                } else if (order.orderFulfillmentStatus === 'New') {
                  currentData.pending.push(order);
                } else if (order.orderFulfillmentStatus === 'None') {
                  currentData.pending.push(order);
                } else if (order.orderFulfillmentStatus === 'Planning') {
                  currentData.pending.push(order);
                } else if (order.orderFulfillmentStatus === 'Received') {
                  currentData.pending.push(order);
                } else if (order.orderFulfillmentStatus === 'Pending') {
                  currentData.readyForPickup.push(order);
                }
                break;
              case orderStatesPedidos.CANCELED:
                currentData.canceled.push(order);
                break;
              case orderStatesPedidos.UNFULFILLABLE:
                currentData.canceled.push(order);
                break;
              case orderStatesPedidos.INCIDENT:
                currentData.canceled.push(order);
                break;
              default:
                break;
            }
          }
        }
      });
    }
    setData(currentData);
  }, [orderSales]);

  return (
    <Tabs defaultActiveKey={orderStatesPedidos.PENDING}>
      <TabPane
        tab={`Por preparar (${data?.pending?.length || 0})`}
        key={
          orderStatesPedidos.PENDING ||
          orderStatesPedidos.PENDING_AVAILABILITY ||
          orderStatesPedidos.INVOICE_UNCONFIRMED ||
          orderStatesPedidos.UNSHIPPED
        }
      >
        <TableOrderSales
          orderSales={data?.pending}
          setModalVisible={setModalVisible}
          orderStatus={orderStatesPedidos.PENDING}
          tableSelectedRows={tableSelectedRows}
          setTableSelectedRows={setTableSelectedRows}
          showShipmentModal={showShipmentModal}
          setShowShipmentModal={setShowShipmentModal}
          updateOrders={updateOrders}
        />
      </TabPane>
      <TabPane
        tab={`En tránsito (${data?.readyForPickup?.length || 0})`}
        key={
          orderStatesPedidos.READY_FOR_PICKUP ||
          orderStatesPedidos.PARTIALLY_SHIPPED
        }
      >
        <TableOrderSales
          orderSales={data?.readyForPickup}
          setModalVisible={setModalVisible}
          orderStatus={orderStatesPedidos.READY_FOR_PICKUP}
          tableSelectedRows={tableSelectedRows}
          setTableSelectedRows={setTableSelectedRows}
          showShipmentModal={showShipmentModal}
          setShowShipmentModal={setShowShipmentModal}
          updateOrders={updateOrders}
        />
      </TabPane>
      <TabPane
        tab={`Cancelado (${data?.canceled?.length || 0})`}
        key={
          orderStatesPedidos.CANCELED ||
          orderStatesPedidos.UNFULFILLABLE ||
          orderStatesPedidos.INCIDENT
        }
      >
        <TableOrderSales
          orderSales={data?.canceled}
          setModalVisible={setModalVisible}
          orderStatus={orderStatesPedidos.CANCELED}
          tableSelectedRows={tableSelectedRows}
          setTableSelectedRows={setTableSelectedRows}
          showShipmentModal={showShipmentModal}
          setShowShipmentModal={setShowShipmentModal}
          updateOrders={updateOrders}
        />
      </TabPane>
      <TabPane
        tab={`Entregado (${data?.shipped?.length || 0})`}
        key={orderStatesPedidos.SHIPPED}
      >
        <TableOrderSales
          orderSales={data?.shipped}
          setModalVisible={setModalVisible}
          orderStatus={orderStatesPedidos.SHIPPED}
          tableSelectedRows={tableSelectedRows}
          setTableSelectedRows={setTableSelectedRows}
          showShipmentModal={showShipmentModal}
          setShowShipmentModal={setShowShipmentModal}
          updateOrders={updateOrders}
        />
      </TabPane>
    </Tabs>
  );
}

OrderSalesTabs.propTypes = {
  orderSales: PropTypes.arrayOf(Object).isRequired,
  setModalVisible: PropTypes.func.isRequired,
  tableSelectedRows: PropTypes.arrayOf(Object).isRequired,
  setTableSelectedRows: PropTypes.func.isRequired,
  showShipmentModal: PropTypes.bool.isRequired,
  setShowShipmentModal: PropTypes.func.isRequired,
  updateOrders: PropTypes.func.isRequired,
};

export default React.memo(OrderSalesTabs);

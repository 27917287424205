import { combineReducers } from 'redux';
import dashboard from './dashboard/reducer';
import marketing from './marketing/reducer';
import matrix from './matrix/reducer';

export default combineReducers({
  marketing,
  dashboard,
  matrix,
});

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, Tooltip, Spin } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { thousandsSeparator } from '../../../../../utils/functions';
import 'antd/dist/antd.min.css';
import './numericCardInfo.css';
import selectors from '../../../../../redux/analytic/dashboard/selectors';

const { Title } = Typography;

function NumericCardInfo({ title, tooltipTitle, loader, data, currency }) {
  const currencyState = useSelector(selectors.selectCurrency);

  return (
    <Card bordered={false} style={{ marginRight: 5 }}>
      <Title level={4}>
        <center>
          {title}{' '}
          <Tooltip title={tooltipTitle} placement="top">
            <InfoCircleOutlined />
          </Tooltip>
        </center>
      </Title>
      {!loader ? (
        <Title style={{ color: '#010c33' }} level={1}>
          <center>
            {data > 0 ? thousandsSeparator(data) : 0}
            {currency ? ` ${currencyState}` : ''}
          </center>
        </Title>
      ) : (
        <Card>
          <center>
            <Spin size="large" />
          </center>
        </Card>
      )}
    </Card>
  );
}

NumericCardInfo.propTypes = {
  title: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.objectOf(Object).isRequired,
  data: PropTypes.objectOf(Object).isRequired,
  loader: PropTypes.bool.isRequired,
  currency: PropTypes.bool.isRequired,
};
export default NumericCardInfo;

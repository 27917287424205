import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import newLogo from '../assets/LAP_GLOBAL.png';

function Headers({ widthMenu, loggedIn }) {
  const margin = {
    marginTop: widthMenu.marginTop,
    marginLeft: widthMenu.marginLeft,
    marginRight: widthMenu.marginRight,
  };

  const img = (
    <img
      src={newLogo}
      className="logo"
      alt="logo de LAP MKT"
      height={widthMenu.widthLogo}
      style={{ transition: 'all 0.5s' }}
    />
  );

  return (
    <div style={{ display: 'inline-flex', width: '100%' }}>
      <div className="logo LayoutHeaderLogo" style={margin}>
        {!loggedIn ? (
          <a href="https://www.lapmarketplace.com">{img}</a>
        ) : (
          <Link to="/">{img}</Link>
        )}
      </div>
    </div>
  );
}

Headers.propTypes = {
  widthMenu: PropTypes.isRequired,
  loggedIn: PropTypes.bool,
};

Headers.defaultProps = {
  loggedIn: false,
};

export default Headers;

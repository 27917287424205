import PropTypes from 'prop-types';
import { Button, Col, Form, Input, Modal, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import VerificationInput from 'react-verification-input';
import Title from 'antd/lib/typography/Title';
import { useEffect, useState } from 'react';
import openNotification from '../../../components/Toastr';
import UsersAPI from '../../../api/users';

const { Text } = Typography;
function ResetPassword({ open, setOpen }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingResendCode, setLoadingResendCode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [emailForm] = Form.useForm();
  const [recoverPasswordForm] = Form.useForm();

  const handleResendCode = () => {
    emailForm.submit();
  };

  const forgotPassword = async (values) => {
    if (codeSent) setLoadingResendCode(true);
    else setLoading(true);
    const payload = {
      email: values.email,
    };
    try {
      await UsersAPI.forgotPassword(payload);
      openNotification({
        status: true,
        content: t('forgotPassword.notifications.codeSent'),
      });
      setCodeSent(true);
    } catch (error) {
      openNotification({
        status: false,
        content: t('forgotPassword.notifications.error'),
      });
    }
    setLoadingResendCode(false);
    setLoading(false);
  };

  const onCancel = () => {
    emailForm.resetFields();
    setCodeSent(false);
    recoverPasswordForm.resetFields();
    setOpen(false);
  };

  const confirmForgotPassword = async (values) => {
    setLoadingConfirm(true);
    const email = emailForm.getFieldValue('email');
    const payload = {
      email,
      code: values.code,
      password: values.password,
    };
    try {
      await UsersAPI.confirmForgotPassword(payload);
      openNotification({
        status: true,
        content: t('forgotPassword.notifications.passwordChangeSuccess'),
      });
      setOpen(false);
    } catch (error) {
      openNotification({ status: false });
    }
    setLoadingConfirm(false);
    recoverPasswordForm.resetFields();
  };

  const onEmailFinish = async (values) => {
    await forgotPassword(values);
  };

  const onFinish = async (values) => {
    if (codeSent) {
      await confirmForgotPassword(values);
    }
  };

  useEffect(() => {
    setShowModal(open);
  }, [open]);

  const validatePasswordRules = [
    {
      required: true,
      message: t('forgotPassword.passwordValidations.nonEmpty'),
    },
    {
      required: true,
      pattern: /[a-z]+/,
      message: t('forgotPassword.passwordValidations.lowerCase'),
    },
    {
      required: true,
      pattern: /[A-Z]+/,
      message: t('forgotPassword.passwordValidations.upperCase'),
    },
    {
      required: true,
      pattern: /[0-9]+/,
      message: t('forgotPassword.passwordValidations.number'),
    },
    {
      required: true,
      pattern: /(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/,
      message: t('forgotPassword.passwordValidations.specialCharacter'),
    },
    {
      required: true,
      min: 8,
      message: t('forgotPassword.passwordValidations.minLength'),
    },
  ];

  const validateConfirmPassword = async (_rule, value) => {
    const passwordsAreSame =
      value === recoverPasswordForm.getFieldValue('password');
    if (codeSent && !passwordsAreSame) {
      return Promise.reject(
        new Error(t('forgotPassword.confirmPassword.notEqual'))
      );
    }
    return Promise.resolve();
  };

  return (
    <Modal open={showModal} width="40%" footer={null} onCancel={onCancel}>
      <Title level={5}>{t('forgotPassword.title')}</Title>
      <Form
        form={emailForm}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{ marginTop: 20 }}
        onFinish={onEmailFinish}
        hidden={codeSent}
      >
        <Form.Item
          name="email"
          label={t('forgotPassword.formLabels.userEmail')}
          rules={[
            {
              required: true,
              message: t('forgotPassword.validateEmail'),
            },
          ]}
        >
          <Input
            placeholder="jose@abcconcept.com"
            autoComplete="new-password"
          />
        </Form.Item>
        <Col style={{ marginTop: 15 }}>
          <Button
            type="primary"
            onClick={() => emailForm.submit()}
            loading={loading}
          >
            {t('forgotPassword.buttons.sendConfirmationCode')}
          </Button>
        </Col>
      </Form>
      {codeSent && (
        <Form
          form={recoverPasswordForm}
          style={{ marginTop: 20 }}
          layout="vertical"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="code"
            label={t('forgotPassword.formLabels.enterCode')}
            style={{ marginBottom: 5 }}
            rules={[
              {
                required: true,
                message: t('forgotPassword.validateCode'),
              },
            ]}
          >
            <VerificationInput
              validChars="0-9"
              inputProps={{ inputMode: 'numeric', name: 'validationCode' }}
              classNames={{
                character: 'character',
                characterInactive: 'character--inactive',
                characterSelected: 'character--selected',
              }}
            />
          </Form.Item>
          {codeSent && (
            <Row style={{ marginBottom: 15 }}>
              <Col>
                <Text> {t('forgotPassword.codeNotReceived')}</Text>
                <Button
                  className="ant-btn-link"
                  onClick={handleResendCode}
                  loading={loadingResendCode}
                  type="link"
                >
                  <Text> {t('forgotPassword.resendCode')}</Text>
                </Button>
              </Col>
            </Row>
          )}

          <Form.Item
            name="password"
            label={t('forgotPassword.formLabels.newPassword')}
            style={{ marginBottom: 5 }}
            rules={validatePasswordRules}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label={t('forgotPassword.formLabels.confirmPassword')}
            validateTrigger="onSubmit"
            style={{ marginBottom: 5 }}
            rules={[
              {
                required: true,
                message: t('forgotPassword.confirmPassword.nonEmpty'),
              },
              {
                validator: validateConfirmPassword,
              },
            ]}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>
          <Row>
            <Col span={12} style={{ marginTop: 15 }}>
              <Button
                type="primary"
                onClick={() => recoverPasswordForm.submit()}
                loading={loadingConfirm}
              >
                {t('forgotPassword.buttons.confirm')}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
}

ResetPassword.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ResetPassword;

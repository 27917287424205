import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import RevealEyeButton from '../../../../components/RevealEyeButton';

function EdarkstoreForm({ loading, name, onFinish, courierCredentials }) {
  const [form] = useForm();
  const [showSecret, setShowSecret] = useState(false);

  return (
    <Form
      form={form}
      name={`${name}-form`}
      layout="vertical"
      labelCol={{
        span: 8,
        offset: 0,
      }}
      wrapperCol={{
        span: 20,
        offset: 0,
      }}
      initialValues={{
        remember: true,
        fbm: false,
        fbo: true,
      }}
      onFinish={(values) => {
        onFinish(values, form);
      }}
      autoComplete="off"
    >
      <h1
        style={{
          fontWeight: 600,
          marginBottom: 40,
          marginTop: 15,
        }}
      >
        Conectar cuenta eDarkstore
      </h1>

      <span
        style={{
          paddingLeft: 25,
          maxWidth: '100%',
          textAlign: 'left',
          fontSize: '18px',
          marginBottom: 30,
        }}
      >
        Seller Id
      </span>
      <Form.Item
        name="seller_id"
        rules={[
          {
            required: true,
            message: 'Ingrese su seller id de eDarkstore',
          },
        ]}
        style={{
          fontSize: '18px',
          maxWidth: '100%',
          paddingLeft: 25,
          paddingRight: 25,
        }}
      >
        <Input
          defaultValue={courierCredentials?.client_id}
          onChange={(e) => form.setFieldValue('seller_id', e.target.value)}
          size="large"
          style={{ borderRadius: 10, marginTop: 15, marginBottom: 15 }}
        />
      </Form.Item>

      <span
        style={{
          paddingLeft: 25,
          maxWidth: '100%',
          textAlign: 'left',
          fontSize: '18px',
          marginBottom: 30,
        }}
      >
        Password
      </span>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Ingrese su password de eDarkstore',
          },
        ]}
        style={{
          fontSize: '18px',
          maxWidth: '100%',
          paddingLeft: 25,
          paddingRight: 25,
        }}
      >
        <Input
          defaultValue={courierCredentials?.client_secret}
          type={showSecret ? 'text' : 'password'}
          onChange={(e) => form.setFieldValue('password', e.target.value)}
          size="large"
          style={{ borderRadius: 10, marginTop: 15 }}
        />
        <div style={{ position: 'absolute', right: '-35px', top: '33%' }}>
          <RevealEyeButton
            onClick={() => setShowSecret(!showSecret)}
            show={showSecret}
          />
        </div>
      </Form.Item>

      <Form.Item
        label="El courier es FBM?"
        name="fbm"
        style={{ display: 'none' }}
      />

      <Form.Item
        label="El courier es FBO?"
        name="fbo"
        style={{ display: 'none' }}
      />

      <Form.Item
        wrapperCol={{
          span: 24,
        }}
        style={{
          marginTop: 40,
          textAlign: 'center',
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{
            height: '50px',
            width: '30%',
          }}
        >
          {courierCredentials ? 'Actualizar' : 'Conectar'}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default EdarkstoreForm;

EdarkstoreForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  courierCredentials: PropTypes.instanceOf(Object).isRequired,
};

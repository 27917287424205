import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as queryString from 'query-string';
import { Card, Col, Spin } from 'antd';
import './mercado-pago-checkout.css';
import SubscriptionAPI from '../../../api/subscription';
import openNotification from '../../../components/Toastr';
import paymentPlanAPI from '../../../api/payment-plan';
import companyAPI from '../../../api/company';
import * as Actions from '../../../redux/session/action';
import { getErrorMessage } from '../../../api/api';

function MercadoPagoCheckout() {
  const { email, paymentPlanId, cancellingId } = queryString.parse(
    window.location.search
  );
  const [loading, setLoading] = useState(true);
  const [paymentPlan, setPaymentPlan] = useState();
  const [mp, setMp] = useState();
  const companyId = useSelector(
    (store) => store.Session.session.userInfo.company_id[0]
  );
  const dispatch = useDispatch();
  useEffect(() => {
    const getPaymentPlan = async () => {
      try {
        const response = await paymentPlanAPI.get(paymentPlanId);
        setPaymentPlan(response.data);
      } catch (error) {
        openNotification({ status: false });
      }
    };
    if (!paymentPlan && paymentPlanId) {
      getPaymentPlan();
    }
  }, [paymentPlan]);

  const onError = (error) => {
    openNotification({ status: false, content: getErrorMessage(error) });
  };
  const onSubmit = async (cardFormData) => {
    const payload = {
      paymentPlanId: paymentPlan.id,
      companyId,
      paymentMethod: 'Mercado Pago',
      payerEmail: email,
      cardTokenId: cardFormData.token,
    };
    try {
      const response = await SubscriptionAPI.create(payload);
      await companyAPI.update(companyId, { active: true });
      if (cancellingId) await SubscriptionAPI.cancel(cancellingId);
      const subscription = await SubscriptionAPI.getMetadata(
        response.data.externalId,
        companyId
      );
      dispatch(Actions.subscribeSuccessfully(subscription.data));
      window.location = `/?subscriptionId=${subscription.data.externalId}`;
    } catch (error) {
      onError(error);
    }
  };
  useEffect(() => {
    if (process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY) {
      setMp(
        new window.MercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY, {
          locale: 'es-CL',
        })
      );
    }
  }, []);

  useEffect(() => {
    const renderCardPaymentBrick = async (bricksBuilder) => {
      const settings = {
        initialization: {
          amount: paymentPlan?.price,
          payer: {
            email,
          },
        },
        customization: {
          visual: {
            style: {
              theme: 'default',
            },
          },
        },
        callbacks: {
          onReady: () => setLoading(false),
          onSubmit,
          onError,
        },
      };
      window.cardPaymentBrickController = await bricksBuilder.create(
        'cardPayment',
        'cardPaymentBrick_container',
        settings
      );
    };
    if (mp && email && paymentPlan) {
      const bricksBuilder = mp.bricks();
      renderCardPaymentBrick(bricksBuilder);
    }
  }, [mp, email, paymentPlan]);
  return (
    <Col id="cardPayment" span={12} offset={6} style={{ marginTop: 70 }}>
      {loading && <Spin />}
      <Card>{mp && <div id="cardPaymentBrick_container" />}</Card>
    </Col>
  );
}

export default MercadoPagoCheckout;

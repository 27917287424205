import { Button, Col, Form, Modal, Select, Space, Row, Typography } from 'antd';
import { PlusOutlined, PrinterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import warehousesAPI from '../../../api/warehouses';
import openNotification from '../../../components/Toastr';
import Int99OrderForm from './couriers/Int99Minutos/Int99OrderForm';
import FBMCouriers from '../../../utils/FBMCouriers';
import couriersAPI from '../../../api/courier';
import './ShipmentModal.css';
import { FboCouriersEnum } from '../../../utils/const';
import fulfillmentAPI from '../../../api/fulfillment';
import orderSales from '../../../api/order-sales';

function ShipmentModal({
  orders,
  showShipmentModal,
  setShowShipmentModal,
  updateOrders,
  setTableSelectedRows,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [courierForm] = Form.useForm();
  const [int99Form] = Form.useForm();
  const [warehouses, setWarehouses] = useState([]);
  const [fboCompanies, setFboCompanies] = useState([]);
  const [fboWarehouses, setFboWarehouses] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [selectedOrigin, setSelectedOrigin] = useState();
  const [selectedFboWarehouse, setSelectedFboWarehouse] = useState();
  const [allAddressExists, setAllAddressExists] = useState(true);
  const [selectedCourier, setSelectedCourier] = useState('');
  const [loading, setLoading] = useState();
  const [disableOkButton, setDisableOkButton] = useState(true);
  const [hiddenOkButton, setHiddenOkButton] = useState(false);
  const [buttonText, setButtonText] = useState('Continuar');
  const [courierId, setCourierId] = useState();
  const [send99Minutos, setSend99Minutos] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [courierShipments, setCourierShipments] = useState([]);

  const { Title } = Typography;

  const history = useHistory();

  const updateSalesOrderWarehouse = async (orderId, warehouseId) => {
    try {
      await orderSales.updateSaleOrder(orderId, {
        warehouse: { id: warehouseId },
      });
    } catch (error) {
      openNotification({ status: false });
    }
  };

  const onCancel = () => {
    updateOrders();
    setSelectedOrigin(undefined);
    setSelectedCourier(undefined);
    setTableSelectedRows([]);

    form.resetFields();
    courierForm.resetFields();
    int99Form.resetFields();
    setShowShipmentModal(false);
  };

  const onOk = async () => {
    const updateWarerhousePromises = orders.map((order) =>
      updateSalesOrderWarehouse(order.id, selectedOrigin)
    );
    await Promise.all(updateWarerhousePromises);

    if (
      selectedCourier.toUpperCase() === FBMCouriers.INT99MINUTOS.toUpperCase()
    ) {
      int99Form.submit();
    } else if (
      [
        FBMCouriers.FEDEX.toUpperCase(),
        FBMCouriers.FEDEX_US.toUpperCase(),
      ].includes(selectedCourier.toUpperCase())
    ) {
      history.push({
        pathname: '/envio-fedex',
        state: {
          orders,
          warehouseId: selectedOrigin,
          courierId,
          fboOrigin: selectedFboWarehouse,
        },
      });
    } else if (
      selectedCourier.toLowerCase() === FBMCouriers.SHIPSMART.toLowerCase()
    ) {
      history.push({
        pathname: '/envio-shipsmart',
        state: {
          orders,
          warehouseId: selectedOrigin,
          courierId,
          fboOrigin: selectedFboWarehouse,
        },
      });
    }
  };

  useEffect(() => {
    setAllAddressExists(
      !orders.find(
        (order) => Object.keys(order.destinationAddress).length === 0
      )
    );
  }, []);

  useEffect(() => {
    if (!allAddressExists) {
      openNotification({
        status: false,
        content: 'No todas las ordenes tienen dirección de destino.',
      });
    }
  }, [allAddressExists]);

  useEffect(async () => {
    const response = await warehousesAPI.getCompanyWarehouses();
    setWarehouses(response.data.results);
  }, []);

  useEffect(async () => {
    // get fbo for marketplaces
    const marketplaces = orders
      .map((o) => o.marketplace)
      .filter((value, index, self) => self.indexOf(value) === index);
    if (marketplaces && marketplaces.length >= 1) {
      const marketplace = marketplaces[0];
      try {
        const response = await fulfillmentAPI.getMarkeplaceFBMCompaniesFBO(
          marketplace
        );
        setFboCompanies(response.data);
      } catch (err) {
        /* empty */
      }
    }
  }, [orders]);

  const handleNewWarehouse = async (e, isWarehouse = false) => {
    if (!e) {
      history.push('/bodegas');
    } else {
      let body = e;
      if (!isWarehouse) {
        body = fboWarehouses.find((f) => f.apiName === String(e));
        setSelectedFboWarehouse(body);
      }
      try {
        const availability = await couriersAPI.getCourierCredentialsByCoverage(
          `${orders.map((order) => {
            return order.id;
          })}`,
          body
        );
        if (availability.data.length > 0) {
          setCouriers(availability.data);
        } else {
          openNotification({
            status: false,
            content: 'No hay courier disponible para este trayecto.',
          });
        }
      } catch (error) {
        openNotification({ status: false });
      }
    }
  };

  const handleOrigin = async (e) => {
    setFboWarehouses([]);
    setSelectedOrigin(e);
    setSelectedFboWarehouse(undefined);
    if (Object.values(FboCouriersEnum).includes(e)) {
      const warehousesFinal = await fulfillmentAPI.getWarehouses(e).data;
      setFboWarehouses(warehousesFinal);
    } else {
      courierForm.resetFields(['fboWarehouse']);
      handleNewWarehouse(e, true);
    }
  };

  const onChangeCourier = (value, select) => {
    setCourierId(select.key);
    setSelectedCourier(value);
    if (
      [
        FBMCouriers.FEDEX.toUpperCase(),
        FBMCouriers.FEDEX_US.toUpperCase(),
      ].includes(value.toUpperCase()) ||
      value.toLowerCase() === FBMCouriers.SHIPSMART.toLowerCase()
    ) {
      setDisableOkButton(false);
    } else if (value.toUpperCase() === FBMCouriers.INT99MINUTOS.toUpperCase()) {
      setButtonText('Crear envío');
    }
  };

  const downloadCourierShipmentsLabels = async () => {
    if (courierShipments.length > 0) {
      const { courierCompanyName } = courierShipments[0];
      const trackingNumbers = courierShipments.map(
        (courierShipment) => courierShipment.masterTrackingNumber
      );
      try {
        const response = await couriersAPI.getMultipleShipmentDocument(
          courierCompanyName,
          trackingNumbers
        );
        response.data.forEach(async (courierShipmentDocument) => {
          const linkSource = courierShipmentDocument.url;
          const downloadLink = document.createElement('a');
          downloadLink.target = '_blank';
          const fileName = `etiqueta_${courierCompanyName}.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        });
      } catch (error) {
        openNotification({
          status: false,
          content: 'Ha ocurrido un error al intentar descargar las etiquetas.',
        });
      }
    }
  };

  const downloadLabels = async () => {
    setPrintLoading(true);
    await downloadCourierShipmentsLabels();
    setPrintLoading(false);
  };

  return (
    <Modal
      open={showShipmentModal}
      closable
      footer={[null]}
      onCancel={onCancel}
    >
      <Row justify="center">
        <Title level={2} style={{ fontStyle: 'italic' }}>
          Nuevo Envío
        </Title>
      </Row>
      <Col>
        <Form
          form={courierForm}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 24,
          }}
          className="text-align-left"
          autoComplete="off"
        >
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <div>
              <span>Destino</span>{' '}
            </div>
            {orders.map((order) => (
              <Col justify="start">
                <div style={{ fontSize: 15 }}>
                  {order.destinationAddress?.address1 || 'sin datos'}
                </div>
                <div style={{ fontSize: 12 }}>
                  {`${order.buyerInfo?.name || 'Sin nombre'} / ${
                    order.buyerInfo?.email || 'Sin email'
                  } / Tel: ${
                    order.destinationAddress?.phone || 'Sin telefono'
                  }` || ''}
                </div>
              </Col>
            ))}
            <div>
              <span>Origen</span>{' '}
            </div>
            <Form.Item
              name="warehouseId"
              rules={[{ required: true, message: 'Requerido' }]}
            >
              <Select
                onChange={handleOrigin}
                placeholder="Seleccionar bodegas"
                style={{ width: '100%' }}
                size="large"
                disabled={send99Minutos}
              >
                {fboCompanies &&
                  fboCompanies.map((fboCompany) => (
                    <Select.Option key={fboCompany} value={fboCompany}>
                      {fboCompany}
                    </Select.Option>
                  ))}
                {warehouses &&
                  warehouses.map((warehouse) => (
                    <Select.Option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </Select.Option>
                  ))}
                <Select.Option>
                  <PlusOutlined /> Nueva Bodega
                </Select.Option>
              </Select>
            </Form.Item>
            {fboWarehouses && fboWarehouses.length >= 1 && (
              <>
                <div>
                  <span>Bodega</span>{' '}
                </div>
                <Form.Item
                  name="fboWarehouse"
                  rules={[{ required: true, message: 'Requerido' }]}
                >
                  <Select
                    onChange={(e) => handleNewWarehouse(e, false)}
                    placeholder={`Seleccionar bodega de ${selectedOrigin}`}
                    style={{ width: '100%' }}
                    size="large"
                    disabled={send99Minutos}
                  >
                    {fboWarehouses &&
                      fboWarehouses.map((fboWarehouse) => (
                        <Select.Option
                          key={fboWarehouse.apiName}
                          value={fboWarehouse.apiName}
                        >
                          {fboWarehouse.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </>
            )}
            <div>
              <span>Courier</span>{' '}
            </div>
            <Form.Item
              name="courier"
              rules={[
                {
                  required: true,
                  message: 'Seleccione un courier para su envío.',
                },
              ]}
            >
              <Select
                onChange={onChangeCourier}
                disabled={
                  !allAddressExists ||
                  !selectedOrigin ||
                  couriers.length === 0 ||
                  send99Minutos
                }
                placeholder="Seleccionar courier"
                style={{ width: '100%' }}
                size="large"
              >
                {couriers &&
                  couriers.map((courier) => (
                    <Select.Option
                      key={courier.id}
                      value={courier.courierCompanyName}
                    >
                      {courier.courierCompanyName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Space>
        </Form>

        {selectedCourier &&
          selectedCourier.toUpperCase() ===
            FBMCouriers.INT99MINUTOS.toUpperCase() && (
            <Int99OrderForm
              form={int99Form}
              formName="int99Form"
              orders={orders}
              warehouse={selectedOrigin}
              fboOrigin={selectedFboWarehouse}
              setLoading={setLoading}
              setDisableOkButton={setDisableOkButton}
              setHiddenOkButton={setHiddenOkButton}
              setSend99Minutos={setSend99Minutos}
              send99Minutos={send99Minutos}
              setCourierShipments={setCourierShipments}
            />
          )}
      </Col>
      <Col justify="center" style={{ marginTop: 64 }}>
        {send99Minutos && (
          <Row justify="center" style={{ paddingBottom: '16px' }}>
            <Button
              className="ant-btn btn-dimensions"
              onClick={downloadLabels}
              loading={printLoading}
            >
              {t('common.print')} <PrinterOutlined />
            </Button>
          </Row>
        )}
        <Row justify="center">
          <Button
            onClick={onOk}
            disabled={disableOkButton}
            hidden={hiddenOkButton}
            loading={loading}
            className="ant-btn ant-btn-primary btn-dimensions"
          >
            {buttonText}
          </Button>
        </Row>
      </Col>
    </Modal>
  );
}

ShipmentModal.propTypes = {
  orders: PropTypes.arrayOf(Object).isRequired,
  showShipmentModal: PropTypes.bool.isRequired,
  setShowShipmentModal: PropTypes.func.isRequired,
  updateOrders: PropTypes.func.isRequired,
  setTableSelectedRows: PropTypes.func.isRequired,
};

export default ShipmentModal;

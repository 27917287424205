export const GET_PARTNER = 'GET_PARTNER';
export const GET_PARTNER_FAILED = 'GET_PARTNER_FAILED';
export const GET_PARTNER_SUCCESS = 'GET_PARTNER_SUCCESS';

export const UPDATE_PARTNER = 'UPDATE_PARTNER';
export const UPDATE_PARTNER_FAILED = 'UPDATE_PARTNER_FAILED';
export const UPDATE_PARTNER_SUCCESS = 'UPDATE_PARTNER_SUCCESS';

export const ADD_PARTNER = 'ADD_PARTNER';
export const ADD_PARTNER_FAILED = 'ADD_PARTNER_FAILED';
export const ADD_PARTNER_SUCCESS = 'ADD_PARTNER_SUCCESS';

export const LOADING = 'LOADING';
export const PARTNER_COMPLETED = 'PARTNER_COMPLETED';

export const SAVE_PARTNER_DATA = 'SAVE_PARTNER_DATA';

// MARKETPLACES + ADVERTISING
export const stage1Home = [
  {
    target: '.step1Marketplace',
    content: <div>Haz click en tu nombre de usuario</div>,
    disableBeacon: true,
    hideFooter: true,
    placement: 'bottom',
    styles: {
      options: {
        textColor: '#010c33',
      },
    },
  },
];

export const stage1Navbar = [
  {
    target: '.step2Marketplace',
    content: (
      <div>
        <span>Selecciona</span>
        <span style={{ display: 'block' }}>
          <strong>&quot;Marketplaces&quot;</strong>
        </span>
      </div>
    ),
    disableBeacon: true,
    hideFooter: true,
    placement: 'bottom',
    styles: {
      options: {
        textColor: '#010c33',
        zIndex: 5000,
      },
    },
  },
];

export const stage1Marketplace = [
  {
    target: '.step3Marketplace',
    content: (
      <div>
        Si aún no tienes cuenta, haz click en
        <strong>&quot;Ver paso a paso&quot;</strong>
        para crearla
      </div>
    ),
    disableBeacon: true,
    placement: 'top',
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
    locale: {
      next: 'Siguiente',
    },
  },
  {
    target: '.step4Marketplace',
    content: (
      <div>
        Haz click en
        <strong>&quot;Conectar&quot;</strong>
      </div>
    ),
    disableBeacon: true,
    hideFooter: true,
    placement: 'top',
    styles: {
      options: {
        textColor: '#010c33',
      },
    },
  },
];

export const stage1Marketing = [
  {
    target: '.step1Marketing',
    content: (
      <div>
        Para conectar la publicidad haz click en
        <strong>&quot;Conectar&quot;</strong>
      </div>
    ),
    disableBeacon: true,
    hideFooter: true,
    placement: 'top',
    styles: {
      options: {
        textColor: '#010c33',
      },
    },
  },
];

export const stage1End = [
  {
    target: 'body',
    content: (
      <div>
        Haz finalizado el tour
        <br />
        <strong>&quot;Conectar marketplace y publicidad&quot;</strong>
      </div>
    ),
    disableBeacon: true,
    placement: 'center',
    locale: {
      last: 'Terminar tutorial',
    },
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
  },
];

// COURIER
export const stage2Home = [
  {
    target: '.step1Courier',
    content: <div>Haz click en tu nombre de usuario</div>,
    disableBeacon: true,
    hideFooter: true,
    placement: 'left',
    styles: {
      options: {
        textColor: '#010c33',
      },
    },
  },
];

export const stage2Navbar = [
  {
    target: '.step2Courier',
    content: (
      <div>
        Selecciona
        <strong>&quot;Operadores Logísticos&quot;</strong>
      </div>
    ),
    disableBeacon: true,
    hideFooter: true,
    placement: 'bottom',
    styles: {
      options: {
        textColor: '#010c33',
        zIndex: 5000,
      },
    },
  },
];

export const stage2Courier = [
  {
    target: '.step3Courier',
    content: (
      <div>
        Haz click en
        <strong>&quot;Conectar&quot;</strong>
      </div>
    ),
    disableBeacon: true,
    hideFooter: true,
    placement: 'top',
    styles: {
      options: {
        textColor: '#010c33',
      },
    },
  },
];

export const stage2CourierModal = [
  {
    target: '.step4Courier',
    content: (
      <div>
        Si aún no tienes número de cuenta, haz click en
        <strong>&quot;Ver paso a paso&quot;</strong>
        para obtenerla
      </div>
    ),
    disableBeacon: true,
    placement: 'right',
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
    locale: {
      next: 'Siguiente',
    },
  },
  {
    target: '.step5Courier',
    content: <div>Selecciona tu país y escribe tu número de cuenta FedEx</div>,
    disableBeacon: true,
    placement: 'right',
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
  },
  {
    target: '.step6Courier',
    content: (
      <div>
        Selecciona
        <strong>&quot;Conectar&quot;</strong>
        cuando los datos solicitados estén completos
      </div>
    ),
    disableBeacon: true,
    placement: 'right',
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
  },
  {
    target: 'body',
    content: (
      <div>
        Haz finalizado el tour
        <br />
        <strong>&quot;Conectar courier&quot;</strong>
      </div>
    ),
    disableBeacon: true,
    placement: 'center',
    locale: {
      last: 'Terminar tutorial',
    },
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
  },
];

// PUBLICAR
export const stage3NotAvailable = [
  {
    target: 'body',
    content: (
      <div>
        Para visualizar este tour primero debes
        <strong>conectar un marketplace</strong>
      </div>
    ),
    disableBeacon: true,
    placement: 'center',
    locale: {
      last: 'Conectar marketplace',
    },
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
  },
];

export const stage3Home = [
  {
    target: '.step0FirstPost',
    content: (
      <div>
        Haz click en
        <strong>&quot;Catálogo&quot;</strong>
      </div>
    ),
    disableBeacon: true,
    hideFooter: true,
    placement: 'right',
    styles: {
      options: {
        textColor: '#010c33',
      },
    },
  },
];

export const stage3Post = [
  {
    target: '.step1FirstPost',
    content: (
      <div>
        Haz click en
        <strong>&quot;Publicar productos&quot;</strong> y selecciona el
        marketplace en el que quieres publicar
      </div>
    ),
    disableBeacon: true,
    hideFooter: true,
    placement: 'left',
    styles: {
      options: {
        textColor: '#010c33',
      },
    },
  },
];

export const stage3Modal1 = [
  {
    target: '.step2FirstPost',
    content: (
      <div>
        Lee atentamente cada uno de los pasos. Si necesitas más información, haz
        click en &quot;VER MÁS&quot;
        <br />
        <br />
        Cuando los tengas completados, marca las casillas para seguir avanzando
        <br />
        <br />
        <strong>
          Al marcar todas las casillas, se habilitará la sección de categoría
        </strong>
      </div>
    ),
    disableBeacon: true,
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
  },
];

export const stage3Modal2 = [
  {
    target: '.step3FirstPost',
    content: (
      <div>
        En este paso, debes seleccionar la categoría que más se asemeje a tus
        productos
      </div>
    ),
    disableBeacon: true,
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
  },
];

export const stage3Modal3 = [
  {
    target: '.step4FirstPost',
    content: (
      <div>
        Deberás descargar la planilla y completar toda la información solicitada
      </div>
    ),
    disableBeacon: true,
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
  },
];

export const stage3Modal4 = [
  {
    target: '.step5FirstPost',
    content: (
      <div>
        Ahora, deberás subir la planilla ya completada para poder realizar la
        publicación
      </div>
    ),
    disableBeacon: true,
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
  },
];

export const stage3Modal5 = [
  {
    target: '.step6FirstPost',
    content: (
      <div>
        Por último, presiona <strong>&quot;Publicar&quot;</strong>
      </div>
    ),
    disableBeacon: true,
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
    locale: {
      last: 'Siguiente',
    },
  },
];

export const stage3Catalogue = [
  {
    target: '.step7FirstPost',
    content: (
      <div>
        Haz click en
        <strong>&quot;Ver estado de cargas&quot;</strong>
        para hacer seguimiento de los productos que acabas de publicar
      </div>
    ),
    hideFooter: true,
    disableBeacon: true,
    styles: {
      options: {
        textColor: '#010c33',
      },
    },
  },
];

export const stage3PreImport = [
  {
    target: '.stepPre8FirstPost',
    content: (
      <div>
        En esta tabla podrás ver cuál es el estado de carga de tus productos. La
        columna
        <strong>&quot;Estado&quot;</strong>
        muestra la completitud del proceso o qué errores hay que resolver.
      </div>
    ),
    placement: 'top',
    disableBeacon: true,
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
    locale: {
      last: 'Siguiente',
    },
  },
];

export const stage3Import = [
  {
    target: '.step8FirstPost',
    content: (
      <div>
        Si quieres saber más o ver un video de carga de listings, puedes
        encontrarlo en
        <strong>&quot;LAP Academy&quot;</strong>
      </div>
    ),
    placement: 'top-end',
    disableBeacon: true,
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
    locale: {
      next: 'Siguiente',
    },
  },
  {
    target: 'body',
    content: (
      <div>
        Haz finalizado el tour
        <br />
        <strong>&quot;Publicar productos&quot;</strong>
      </div>
    ),
    disableBeacon: true,
    placement: 'center',
    locale: {
      last: 'Terminar tutorial',
    },
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
  },
];

// DASHBOARDS
export const stage5Home = [
  {
    target: '.step1Dashboards',
    content: (
      <div>
        En los tableros de la sección
        <strong>&quot;Resumen&quot;</strong>
        tenemos una vista general de distintas estadísticas de todos los
        marketplaces combinados
      </div>
    ),
    disableBeacon: true,
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
    locale: {
      next: 'Siguiente',
    },
  },
  {
    target: '.step2Dashboards',
    content: (
      <div>
        Haz click en
        <strong>&quot;Ventas&quot;</strong>
        para ver el siguiente tablero
      </div>
    ),
    disableBeacon: true,
    hideFooter: true,
    placement: 'right',
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
  },
];

export const stage5Sales = [
  {
    target: '.step3Dashboards',
    content: (
      <div>
        En
        <strong>&quot;Ventas&quot;</strong>
        podemos ver estadísticas de cada marketplace con más detalle
      </div>
    ),
    disableBeacon: true,
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
    locale: {
      next: 'Siguiente',
    },
  },
  {
    target: '.step4Dashboards',
    content: (
      <div>
        Haz click en
        <strong>&quot;Publicidad&quot;</strong>
        para ver el último tablero
      </div>
    ),
    disableBeacon: true,
    hideFooter: true,
    placement: 'right',
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
  },
];

export const stage5Adv = [
  {
    target: '.step5Dashboards',
    content: (
      <div>
        En
        <strong>&quot;Publicidad&quot;</strong>
        podemos ver el rendimiento de tus campañas de Amazon
      </div>
    ),
    disableBeacon: true,
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
    locale: {
      next: 'Siguiente',
    },
  },
  {
    target: '.step6Dashboards',
    content: (
      <div>
        Como ayuda, en todas las secciones encontrarás estos símbolos. Si
        colocas el cursor sobre ellos aparecerán
        <strong>&quot;carteles de ayuda&quot;</strong>, donde se explica qué es
        la información señalada
      </div>
    ),
    disableBeacon: true,
    placement: 'right',
    locale: {
      next: 'Siguiente',
    },
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
  },
  {
    target: 'body',
    content: (
      <div>
        Haz finalizado el tour
        <br />
        <strong>&quot;Resumen&quot;</strong>
      </div>
    ),
    disableBeacon: true,
    placement: 'center',
    locale: {
      last: 'Terminar tutorial',
    },
    styles: {
      options: {
        textColor: '#010c33',
      },
      buttonNext: {
        backgroundColor: '#010c33',
      },
    },
  },
];

// NEW COMPANY
export const newCompanyTourSteps = [
  {
    target: 'body',
    content: (
      <div>
        <strong>¡BIENVENIDO A LAP!</strong>
        <br />
        <br />
        Te recomendamos seguir las 5 etapas que hemos diseñado para ayudarte a
        expandir tu negocio a nivel internacional.
        <br />
        <br />
        Haz click en cada una de ellas para acceder al tutorial y descubrir los
        pasos que necesitas seguir para completarlas.
      </div>
    ),
    disableBeacon: true,
    placement: 'center',
    locale: {
      next: 'SEGUIR TUTORIAL',
      skip: 'SALTAR TUTORIAL',
    },
  },
  {
    target: '.step1newCompanyTour',
    content: (
      <div>
        En este modal encontrarás los 6 tutoriales necesarios para usar la
        plataforma y comenzar a vender tus productos
      </div>
    ),
    disableBeacon: true,
    placement: 'top',
    locale: {
      last: '¡ENTENDIDO!',
    },
  },
];

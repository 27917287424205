import React from 'react';
import PropTypes from 'prop-types';
import { Card, Spin, Typography, Divider, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import SubscribeButton from './SubscribeButton';
import OnSubscriptionButton from './OnSubscriptionButton';
import { thousandsSeparator } from '../../../utils/functions';

function KamPlanCard({
  plan,
  loader,
  loggedIn,
  subscriptionPlanId,
  subscriptionId,
  hasTech,
}) {
  const { t } = useTranslation();
  const { Title, Text } = Typography;
  const subscribed = subscriptionPlanId === plan.id ? 'subscribed' : '';
  const subscribeButton = (
    <SubscribeButton planId={plan.id} loggedIn={loggedIn} disabled={!hasTech} />
  );

  return (
    <Card className={`plan-card kam ${subscribed}`} bordered={false}>
      {!loader ? (
        <>
          <Title level={4} className="plan-title">
            <center>{plan.name}</center>
          </Title>
          <Text level={2} className="plan-price">
            <center>
              {new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }).format(plan.price)}
            </center>
          </Text>
          <Text level={2} className="plan-price-detail">
            <center>{t('plans.priceDetails')}</center>
          </Text>
          <Divider />
          {plan.productLinesAmount && (
            <Text level={2} italic className="plan-benefit" if>
              <center>
                <Text className="plan-benefit bold">
                  {thousandsSeparator(plan.productLinesAmount)}
                </Text>
                {plan.productLinesAmount === 1
                  ? t('plans.oneProductLineAmount')
                  : t('plans.productLinesAmount')}
              </center>
            </Text>
          )}
          {plan.kamMeeting && (
            <Text level={2} italic className="plan-benefit">
              <center>
                <Text className="plan-benefit bold">1&nbsp;</Text>
                {t('plans.kamMeetingDescription')}
                <Text className="plan-benefit bold">
                  {t(`plans.kamMeeting.${plan.kamMeeting}`)}
                </Text>
              </center>
            </Text>
          )}
          {subscriptionPlanId ? (
            <OnSubscriptionButton
              planId={plan.id}
              loggedIn={loggedIn}
              subscriptionPlanId={subscriptionPlanId}
              subscriptionId={subscriptionId}
            />
          ) : (
            <div>
              {!hasTech ? (
                <Popover
                  className="kam-popover"
                  content={t('plans.kamRestriction')}
                  placement="bottom"
                  getPopupContainer={() => document.getElementById('empty-div')}
                >
                  {subscribeButton}
                  <div id="empty-div" />
                </Popover>
              ) : (
                subscribeButton
              )}
            </div>
          )}
        </>
      ) : (
        <Card>
          <center>
            <Spin size="large" />
          </center>
        </Card>
      )}
    </Card>
  );
}

KamPlanCard.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
    currency: PropTypes.string,
    type: PropTypes.oneOf(['KAM']),
    productLinesAmount: PropTypes.number,
    kamMeeting: PropTypes.oneOf(['MONTHLY', 'BIMONTHLY', 'UNLIMITED']),
  }).isRequired,
  loader: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  subscriptionPlanId: PropTypes.number,
  subscriptionId: PropTypes.number,
  hasTech: PropTypes.bool,
};

KamPlanCard.defaultProps = {
  subscriptionPlanId: null,
  subscriptionId: null,
  hasTech: false,
};

export default React.memo(KamPlanCard);

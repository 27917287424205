import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Pagination,
  Table,
  Button,
  Row,
  Col,
  Card,
  Tooltip,
  Popconfirm,
} from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import WarehousesAPI from '../../../api/warehouses';
import openNotification from '../../../components/Toastr';
import phones from '../../../utils/json/phones.json';
import stateOrProvinces from '../../../utils/json/stateOrProvinces.json';
import CreateWarehouseModal from './CreateWarehouseModal';
import EditWarehouseModal from './EditWarehouseModal';

function Warehouses() {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editWarehouseId, setEditWarehouseId] = useState(0);

  const loadPage = async () => {
    setLoading(true);
    const response = await WarehousesAPI.getCompanyWarehouses(
      pageSize,
      currentPage
    );
    setCurrentPage(response.data.page);
    setPageSize(response.data.limit);
    setTotal(response.data.totalResults);
    const data = response.data.results.map((value) => ({
      key: value.id,
      name: value.name,
      contactName: value.contactName,
      email: value.email,
      phoneNumber: `${value.phoneExtension} ${value.phoneNumber}`,
      streetName: value.address.streetName,
      country: phones.find((row) => row.code === value.address.countryCode)
        .name,
      city: value.address.city,
      stateOrProvinceName:
        stateOrProvinces
          .find((row) => value.address.countryCode === row.countryCode)
          ?.states.find((row) => row.code === value.address.stateOrProvinceCode)
          ?.name || 'No aplica',
      postalCode: value.address.postalCode || '',
    }));
    setDataSource(data);
    setLoading(false);
  };
  const updatePageNumber = (newPage) => setCurrentPage(newPage);

  const handleDeleteWarehouse = async (id) => {
    try {
      await WarehousesAPI.delete(id);
      openNotification({
        status: true,
        content: 'Bodega eliminada correctamente',
      });
      await loadPage();
    } catch (error) {
      openNotification({ status: false, content: 'Error al eliminar bodega.' });
    }
  };

  const handleEditWarehouse = async (warehouseId) => {
    setShowEditModal(true);
    setEditWarehouseId(warehouseId);
  };

  const handleCreateWarehouse = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    loadPage();
  }, [currentPage]);

  const renderActions = (_text, record) => (
    <Row justify="space-around">
      <Col>
        <Tooltip title={t('warehouses.edit.tooltip')}>
          <Button
            type="primary"
            icon={<EditOutlined className="contentIcon" />}
            onClick={() => handleEditWarehouse(record.key)}
          />
        </Tooltip>
      </Col>
      <Col>
        <Popconfirm
          title={t('warehouses.confirmDelete')}
          onConfirm={() => handleDeleteWarehouse(record.key)}
          okButtonProps={{ danger: true }}
          okText={t('common.remove')}
          cancelText={t('common.cancel')}
        >
          <Button danger>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </Col>
    </Row>
  );

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Dirección',
      dataIndex: 'streetName',
      key: 'streetName',
    },
    {
      title: 'País',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Ciudad',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Estado o provincia',
      dataIndex: 'stateOrProvinceName',
      key: 'stateOrProvinceName',
    },
    {
      title: 'Código Postal',
      dataIndex: 'postalCode',
      key: 'postalCode',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: renderActions,
      align: 'center',
      width: '10%',
    },
  ];

  return (
    <Col span={24} style={{ padding: 40 }} className="initialContent">
      <Card>
        <Row>
          <Col span={24}>
            <Row gutter={[0, 12]}>
              <Button onClick={handleCreateWarehouse}>
                <PlusOutlined />
                {t('warehouses.createNewWarehouse')}
              </Button>
              <Col span={24}>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  loading={loading}
                  pagination={false}
                  style={{ marginBottom: 20 }}
                />
                <Pagination
                  current={currentPage}
                  total={total}
                  pageSize={pageSize}
                  onChange={updatePageNumber}
                />
                <CreateWarehouseModal
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  reloadTable={loadPage}
                />
                <EditWarehouseModal
                  isModalOpen={showEditModal}
                  setIsModalOpen={setShowEditModal}
                  reloadTable={loadPage}
                  warehouseId={editWarehouseId}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}
export default Warehouses;

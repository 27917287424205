import { Input } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function MySellerAccountFilters({ filters, setFilters }) {
  const [inputValue, setInputValue] = useState(filters.marketplace);

  const session = useSelector((store) => store.Session.session);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setFilters({
        marketplace: inputValue,
      });
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [inputValue]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    setInputValue('');
  }, [session]);

  return (
    <div
      style={{
        display: 'flex',
        marginBottom: '40px',
        gap: '35px',
        marginTop: '20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '20%',
          gap: '8px',
        }}
      >
        <span style={{ fontWeight: 500 }}>Marketplace</span>
        <Input
          onChange={handleChange}
          value={inputValue}
          className="customInputSkeleton"
        />
      </div>
    </div>
  );
}

MySellerAccountFilters.propTypes = {
  filters: PropTypes.instanceOf(Object).isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default MySellerAccountFilters;

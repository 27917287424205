import api from './api';

const prefix = '/jobs';
const JobAPI = {
  addBulkUpdateStockJob(file) {
    const formData = new FormData();
    formData.append('file', file);
    return api({
      url: `${prefix}/stock/jobs/bulk-update-stock`,
      method: 'POST',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
};

export default JobAPI;

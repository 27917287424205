import React, { useEffect, useState } from 'react';
import { Typography, Modal, Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { CheckCircleFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import subscriptionAPI from '../../../api/subscription';
import companyAPI from '../../../api/company';
import * as Actions from '../../../redux/session/action';
import openNotification from '../../../components/Toastr';
import { getErrorMessage } from '../../../api/api';

const { Text, Title } = Typography;
function SubscriptionConfirmationModal({
  visible,
  setVisible,
  subscriptionId,
  stripeCheckoutSessionId,
}) {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const companyId = useSelector(
    (store) => store.Session.session.userInfo.company_id[0]
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  moment.locale('es');
  const { search } = useLocation();
  const searchParams = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const getSubscription = async () => {
    if (!subscriptionId) {
      return null;
    }
    const subscriptionData = await subscriptionAPI.get(subscriptionId);
    let subscription;
    if (subscriptionData) {
      subscription = subscriptionData.data;
    }
    return [subscription];
  };

  const getStripeSubscription = async () => {
    const { data } = await subscriptionAPI.importFromStripeCheckoutSession({
      sessionId: stripeCheckoutSessionId,
    });
    await companyAPI.update(companyId, { active: true });
    await Promise.all(
      data.map(async (subscription) => {
        const { data: subscriptionData } = await subscriptionAPI.getMetadata(
          subscription.externalId,
          companyId
        );
        dispatch(Actions.subscribeSuccessfully(subscriptionData));
      })
    );
    const cancellingId = searchParams?.get('cancellingId');
    if (cancellingId) await subscriptionAPI.cancel(cancellingId);
    return data;
  };

  useEffect(async () => {
    if (!subscriptionId && !stripeCheckoutSessionId) {
      return;
    }
    setLoading(true);
    setVisible(true);
    try {
      let result = [];
      if (subscriptionId) {
        result = await getSubscription();
      } else if (stripeCheckoutSessionId) {
        result = await getStripeSubscription();
      }
      setSubscriptions(result);
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
      setVisible(false);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Modal
      okButtonProps={{
        className: 'btn-primary-darkBlue lg-btn',
        disabled: loading,
        id: 'btn-subscription-confirmation',
        style: { width: '100%' },
      }}
      okText={t('plans.subscriptionConfirmationModal.okButton')}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={false}
      centered
      open={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      className="subscription-confirmation-modal"
    >
      {loading ? (
        <Card
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
          }}
        >
          <Spin
            size="large"
            spinning={loading}
            tip={
              <Text>{t('plans.subscriptionConfirmationModal.validating')}</Text>
            }
          />{' '}
        </Card>
      ) : (
        <center>
          <CheckCircleFilled className="subscription-confirmation-check-icon" />
          <Title level={3} className="subscription-confirmation-title">
            {t('plans.subscriptionConfirmationModal.title')}
          </Title>
          <Text className="subscription-confirmation-description">
            {t('plans.subscriptionConfirmationModal.description')}
          </Text>
          {subscriptions.length && (
            <Card
              className="subscription-details"
              title={t('plans.subscriptionConfirmationModal.detailsTitle')}
              extra={
                <span
                  style={{
                    background: '#ECF9EE',
                    borderRadius: 12,
                    color: '#35A84C',
                    padding: '2px 12px',
                  }}
                >
                  {t('plans.subscriptionConfirmationModal.subscriptionPaid')}
                </span>
              }
              headStyle={{ textAlign: 'left', color: '#969696' }}
            >
              <p>
                <span className="subscription-detail-title">
                  {t('plans.subscriptionConfirmationModal.product')}
                </span>
                <span className="subscription-detail-value">
                  {subscriptions.reduce(
                    (prev, curr) =>
                      `${prev}${prev ? ' + ' : ''}${
                        curr.paymentPlan.plan.name
                      }`,
                    ''
                  )}
                </span>
              </p>
              <p>
                <span className="subscription-detail-title">
                  {t('plans.subscriptionConfirmationModal.productCode')}
                </span>
                <span className="subscription-detail-value">
                  {subscriptions.reduce(
                    (prev, curr) =>
                      `${prev}${prev ? ' / ' : ''}${curr.externalId}`,
                    ''
                  )}
                </span>
              </p>
              <p>
                <span className="subscription-detail-title">
                  {t(
                    'plans.subscriptionConfirmationModal.subscriptionFrequencyType'
                  )}
                </span>
                <span className="subscription-detail-value">
                  {t(
                    `common.periodicity.${subscriptions[0].paymentPlan.frequencyType.toLowerCase()}`
                  )}
                </span>
              </p>
              <p>
                <span className="subscription-detail-title">
                  {t('plans.subscriptionConfirmationModal.subscriptionDate')}
                </span>
                <span className="subscription-detail-value">
                  {moment(subscriptions[0].dateCreated).format('D MMM YYYY')}
                </span>
              </p>
            </Card>
          )}
        </center>
      )}
    </Modal>
  );
}

SubscriptionConfirmationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  subscriptionId: PropTypes.string,
  stripeCheckoutSessionId: PropTypes.string,
};

SubscriptionConfirmationModal.defaultProps = {
  subscriptionId: null,
  stripeCheckoutSessionId: null,
};

export default SubscriptionConfirmationModal;

import us from './US.json';
import mx from './MX.json';
import cl from './CL.json';
import co from './CO.json';
import ca from './CA.json';

const STATESORPROVINCE = {
  US: us,
  MX: mx,
  CL: cl,
  CO: co,
  CA: ca,
};

export default STATESORPROVINCE;

import { Button, Card, Col, Modal, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import openNotification from '../../../components/Toastr';
import documentAPI from '../../../api/document';

function RegulationAvatar({
  imgPadding,
  imgHeight,
  icon,
  name,
  label,
  formContent: FormContent,
  loadTable,
  regulationsCredentials,
}) {
  const { t } = useTranslation();
  const { Text } = Typography;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [singleCredentials, setSingleCredentials] = useState(null);

  useEffect(() => {
    const credentialByName = regulationsCredentials.find(
      (c) => c.name === name
    );
    setSingleCredentials(credentialByName);
  }, [regulationsCredentials]);

  const onFinish = async (values) => {
    try {
      const payload = { ...values, regulationCompanyName: name };
      setLoading(true);
      await documentAPI.syncRegulationsCredentials(payload);
      setIsModalOpen(false);
      openNotification({
        status: true,
        content: t('regulations.linkAccount.success'),
      });
      loadTable();
    } catch (error) {
      openNotification({
        status: false,
        content: t('regulations.linkAccount.error'),
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Card
        style={{
          borderRadius: '10px',
          marginBottom: '18px',
        }}
        className="courierAvatarCard"
      >
        <Row justify="space-between">
          <div style={{ display: 'flex' }}>
            <Col>
              <Card
                style={{ width: 100, textAlign: 'center' }}
                className="courierAvatarImageCard"
              >
                <div style={{ justifyContent: 'center' }}>
                  <img
                    height={imgHeight}
                    width="100%"
                    src={icon}
                    style={imgPadding ? { padding: imgPadding } : null}
                    alt={`img_${name}`}
                  />
                </div>
              </Card>
            </Col>

            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Text style={{ marginLeft: '20px', fontSize: '16px' }}>
                {label}
              </Text>
            </Col>
          </div>

          <div style={{ display: 'flex', width: '28%' }}>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Button
                style={{
                  backgroundColor: `${
                    singleCredentials ? '#33BA6A' : '#CACACA'
                  }`,
                  color: `${singleCredentials ? 'white' : 'black'}`,
                  width: '150px',
                  height: '42px',
                }}
                onClick={() => setIsModalOpen(true)}
                className={
                  singleCredentials ? 'courierConnected' : 'courierNotConnected'
                }
              >
                {singleCredentials
                  ? t('common.connected')
                  : t('common.connect')}
              </Button>
            </Col>
          </div>
        </Row>
      </Card>

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <FormContent
          loading={loading}
          regulationsCredentials={regulationsCredentials.find(
            (c) => c.name === name
          )}
          name={name}
          onFinish={onFinish}
        />
      </Modal>
    </>
  );
}

export default RegulationAvatar;

RegulationAvatar.defaultProps = {
  imgPadding: null,
  label: null,
};

RegulationAvatar.propTypes = {
  imgPadding: PropTypes.string,
  imgHeight: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  formContent: PropTypes.func.isRequired,
  loadTable: PropTypes.func.isRequired,
  regulationsCredentials: PropTypes.instanceOf(Array).isRequired,
  label: PropTypes.string,
};

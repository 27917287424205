import { getAllTimezones } from 'countries-and-timezones';
import { FilterOutlined, SyncOutlined } from '@ant-design/icons';
import {
  Col,
  Radio,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Button,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import actions from '../../../redux/analytic/dashboard/actions';
import selectors from '../../../redux/analytic/dashboard/selectors';
import useLocalStorage from '../../shared/hooks/useLocalStorage';
import { dateTypes } from '../../../utils/const';
import {
  convertToISOString,
  mixPanelCreateEvent,
} from '../../../utils/functions';
import './dashboardFilter.css';
import SyncInventory from './SyncInventory';

const emptyValues = {
  partner: [],
  sku: [],
  marketplace: [],
  category: [],
  compare: false,
  dateRangeOne: [],
  dateRangeTwo: [],
};
const timezones = getAllTimezones();
const defaultTimezone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Santiago';

function DashboardFilter({
  isAdmin,
  getMarketplaces,
  partnerId,
  getSkusperMarketplaceDisabled,
  listMarketplaceAvailableClient,
  listSkus,
  ejectFunctions,
  getSkusperMarketplace,
  sku,
  multiMarket,
  inventory,
  dashboard,
  timezoneFilter,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const firstRender = useRef(true);

  const rawFilter = useSelector(selectors.selectRawFilter);
  const session = useSelector((store) => store.Session.session);
  const currencyState = useSelector(selectors.selectCurrency);

  const [loadingPartners] = useState(false);
  const [loadingSyncInventory, setLoadingSyncInventory] = useState(false);
  const [showInventorySyncModal, setShowInventorySyncModal] = useState(false);
  const [partners] = useState([]);
  const [personalizedButton, setPersonalizedButton] = useState(false);
  const [datePrev, setDatePrev] = useState([]);
  const query = useLocalStorage(`query_${partnerId}`, emptyValues);
  const [partnersSelected, setPartnersSelected] = useState(() => {
    if (!isAdmin) {
      return [partnerId];
    }
    return query?.partner?.length > 0 ? query?.partner : [];
  });

  const [initialValues] = useState(() => ({
    ...emptyValues,
    ...query,
    dateRangeOne: query.dateRangeOne
      ? query.dateRangeOne.map((date) => moment(date, 'YYYY-MM-DD'))
      : [],
    dateRangeTwo: query.dateRangeTwo
      ? query.dateRangeTwo.map((date) => moment(date, 'YYYY-MM-DD'))
      : [],
    partner: partnersSelected,
    dateType: dateTypes.PURCHASE_DATE,
    isAdmin,
  }));

  const [form] = Form.useForm();

  const timezoneOptions = Object.keys(timezones).map((tz) => ({
    label: `(GMT ${timezones[tz].utcOffsetStr}) ${timezones[tz].name}`,
    value: tz,
  }));

  useEffect(() => {
    if (listMarketplaceAvailableClient?.length > 0) {
      let market;
      if (multiMarket) {
        market = listMarketplaceAvailableClient;
      } else {
        [market] = listMarketplaceAvailableClient;
      }
      const timezoneOffsetStr = timezones[defaultTimezone]?.utcOffsetStr;
      const timezone = Number(timezoneOffsetStr?.slice(0, 3)) || 0;
      const filter = {
        ...rawFilter,
        marketplace: market,
        ...(timezone ? { timezone: defaultTimezone } : {}),
      };
      form.setFieldsValue(filter);
      if (!getSkusperMarketplaceDisabled) {
        getSkusperMarketplace(
          filter.marketplace,
          filter.dateRangeOne,
          filter.partner
        );
      }
      if (!session?.userInfo?.isAdmin) {
        ejectFunctions({
          ...rawFilter,
          marketplace: multiMarket
            ? rawFilter.marketplace
            : rawFilter.marketplace[0],
          currency: localStorage.getItem('defaultCurrency'),
          dateRangeOne: convertToISOString(rawFilter.dateRangeOne),
          timezone,
        });
      }
    }
  }, [listMarketplaceAvailableClient]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form]);

  const onSubmit = (values) => {
    const formValues = values || {};
    setDatePrev(values.dateRangeOne);
    formValues.dashboard = values.dashboard;
    let actionName = 'Find Sales in Dashboard';
    if (values?.dashboard) {
      actionName = `Find Sales in Dashboard ${values?.dashboard}`;
    }
    const timezoneOffsetStr = timezones[values?.timezone]?.utcOffsetStr;
    const timezone = Number(timezoneOffsetStr?.slice(0, 3)) || 0;

    mixPanelCreateEvent('Action Button', {
      action: actionName,
      dashboard: values?.dashboard,
      dateRangeFrom: values?.dateRangeOne[0]?.toISOString(),
      dateRangeTo: values?.dateRangeOne[1]?.toISOString(),
      dateType: values?.dateType,
      period: values?.period,
      marketplace: values?.marketplace,
      partner: values?.partner,
      sku: values?.sku,
      timezone,
    });
    ejectFunctions({
      ...values,
      marketplace: values.marketplace,
      currency: localStorage.getItem('defaultCurrency'),
      dateRangeOne: convertToISOString(values.dateRangeOne),
      timezone,
    });
  };

  const onLoadDefaultQuery = () => {
    let ptrs = [];
    if (!isAdmin) {
      ptrs = [partnerId];
    } else {
      ptrs = partners?.map((p) => p.id) || [];
      setPartnersSelected(ptrs);
    }

    const defaultFilters = {
      partner: ptrs,
      sku: [],
      marketplace:
        listMarketplaceAvailableClient?.length > 0
          ? listMarketplaceAvailableClient[0]
          : [],
      category: ['sales', 'stock'],
      compare: false,
      dateRangeOne: [moment().startOf('month'), moment()],
      dateRangeTwo: [],
      period: 'currentMonth',
      dateType: dateTypes.PURCHASE_DATE,
      isAdmin,
    };
    setDatePrev(defaultFilters.dateRangeOne);
    form.setFieldsValue(defaultFilters);
  };

  useEffect(() => {
    onLoadDefaultQuery();
  }, []);

  const onPartnerValuesChange = () => {
    const ptrs = form.getFieldValue('partner');
    getMarketplaces(ptrs);
    form.setFieldsValue({ sku: [] });
  };

  const resetDasboard = () => {
    dispatch(actions.doReset());
  };

  useEffect(() => {
    resetDasboard();
  }, []);

  const onDateRangeChange = (values) => {
    const filters = form.getFieldsValue();
    if (datePrev !== values.dateRangeOne) {
      if (filters.dateRangeOne) {
        const dateRangeOneEnd = moment(
          filters.dateRangeOne[1],
          'ddd MMM D YYYY HH:mm:ss ZZ'
        );
        dateRangeOneEnd.set({ h: 23, m: 59, s: 59 });
        filters.dateRangeOne[1] = dateRangeOneEnd;
      }
    }
  };

  const changeListSku = (e) => {
    form.setFieldsValue({ sku: [] });
    dispatch(
      actions.setFilter({
        ...rawFilter,
        sku: '',
      })
    );
    const filters = form.getFieldValue();
    if (!getSkusperMarketplaceDisabled) {
      if (!isAdmin) {
        getSkusperMarketplace(e, filters.dateRangeOne);
      } else {
        getSkusperMarketplace(
          e,
          filters.dateRangeOne,
          form.getFieldValue('partner')
        );
      }
    }
  };

  useEffect(() => {
    const filters = form.getFieldValue();
    form.setFieldsValue({
      ...filters,
      sku: '',
    });
  }, [listSkus]);

  const searchSku = () => {
    const filters = form.getFieldsValue();
    ejectFunctions({
      ...filters,
      dateRangeOne: convertToISOString(filters.dateRangeOne),
    });
  };

  const handlePersonalizedButton = () => {
    const filters = form.getFieldsValue();
    let dateRangeOne;
    switch (filters.period) {
      case 'custom':
        break;
      case 'currentWeek':
        dateRangeOne = [moment().add(-moment().weekday(), 'd'), moment()];
        break;
      case 'last7days':
        dateRangeOne = [moment().add(-6, 'd'), moment()];
        break;
      case 'currentMonth':
        dateRangeOne = [moment().startOf('month'), moment()];
        break;
      case 'lastMonth':
        dateRangeOne = [
          moment().subtract(1, 'months').startOf('month'),
          moment().subtract(1, 'months').endOf('month'),
        ];
        break;
      case 'last30days':
        dateRangeOne = [moment().add(-30, 'd'), moment()];
        break;
      case 'currentYear':
        dateRangeOne = [moment().startOf('year'), moment()];
        break;
      case 'last12months':
        dateRangeOne = [moment().add(-12, 'M'), moment()];
        break;
      default:
        break;
    }

    if (filters.period === 'custom') {
      setPersonalizedButton(true);
    } else {
      setPersonalizedButton(false);
      form.setFieldsValue({
        ...filters,
        category: [],
        dateRangeOne,
      });
      if (!getSkusperMarketplaceDisabled) {
        getSkusperMarketplace(
          filters.marketplace,
          dateRangeOne,
          form.getFieldValue('partner'),
          filters.period
        );
      }
    }
  };

  const noMarketingMessage = <span>{t('dashboard.messages.noDataAds')}</span>;
  const noSyncMessage = <span>{t('dashboard.messages.noData')}</span>;

  const filterTimezoneOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    const filters = form.getFieldValue();
    onSubmit(filters);
  }, [currencyState]);

  const loading = loadingPartners;

  return (
    <>
      {loading && <Spin />}
      {!loading && (
        <Form
          name="formulario"
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          className="text-align-left form-style"
        >
          <Row gutter={24} justify="space-between">
            {isAdmin && (
              <Col xs={24} lg={4} md={4}>
                <Form.Item
                  name="partner"
                  label={t('dashboard.fields.partner')}
                  rules={[{ required: true }]}
                >
                  <Select
                    allowClear
                    maxTagCount="responsive"
                    onChange={onPartnerValuesChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                  >
                    {partners?.length > 0 &&
                      partners?.map((partner) => (
                        <Select.Option key={partner.id} value={partner.id}>
                          {partner.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {!isAdmin && (
              <Form.Item hidden name="partner">
                <Input hidden />
              </Form.Item>
            )}
            <Col xs={24} lg={4} md={4}>
              {listMarketplaceAvailableClient?.length > 0 ? (
                <Form.Item
                  name="marketplace"
                  label={
                    multiMarket
                      ? t('dashboard.fields.marketplace_plural')
                      : t('dashboard.fields.marketplace')
                  }
                >
                  {multiMarket ? (
                    <Select
                      maxTagCount="responsive"
                      onChange={changeListSku}
                      defaultValue={['Shopify', 'Amazon']}
                      mode="multiple"
                    >
                      {listMarketplaceAvailableClient?.map((mkp) => (
                        <Select.Option key={mkp} value={mkp}>
                          {t(`dashboard.marketplaces.${mkp.replace(' ', '')}`)}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      maxTagCount="responsive"
                      onChange={changeListSku}
                      defaultValue=""
                    >
                      {listMarketplaceAvailableClient?.map((mkp) => (
                        <Select.Option key={mkp} value={mkp}>
                          {t(`dashboard.marketplaces.${mkp.replace(' ', '')}`)}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              ) : (
                <Form.Item
                  name="marketplace"
                  label={
                    multiMarket
                      ? t('dashboard.fields.marketplace_plural')
                      : t('dashboard.fields.marketplace')
                  }
                >
                  {!isAdmin &&
                    (dashboard === 'marketing'
                      ? noMarketingMessage
                      : noSyncMessage)}
                  {isAdmin && (
                    <Select maxTagCount="responsive" onChange={changeListSku}>
                      {listMarketplaceAvailableClient?.map((mkp) => (
                        <Select.Option key={mkp} value={mkp} />
                      ))}
                    </Select>
                  )}
                </Form.Item>
              )}
            </Col>
            {!inventory && (
              <Col xs={24} lg={4} md={4}>
                <Form.Item name="period" label={t('dashboard.fields.period')}>
                  <Select
                    defaultValue={
                      form.getFieldValue('partner') !== '' ? 'currentMonth' : ''
                    }
                    onChange={handlePersonalizedButton}
                    options={
                      form.getFieldValue('partner') !== ''
                        ? [
                            {
                              value: `currentWeek`,
                              label: t('dashboard.filter.currentWeek'),
                            },
                            {
                              value: `last7days`,
                              label: t('dashboard.filter.last7days'),
                            },
                            {
                              value: `currentMonth`,
                              label: t('dashboard.filter.currentMonth'),
                            },
                            {
                              value: `lastMonth`,
                              label: t('dashboard.filter.lastMonth'),
                            },
                            {
                              value: `last30days`,
                              label: t('dashboard.filter.last30days'),
                            },
                            {
                              value: `currentYear`,
                              label: t('dashboard.filter.currentYear'),
                            },
                            {
                              value: `last12months`,
                              label: t('dashboard.filter.last12months'),
                            },
                            {
                              value: 'custom',
                              label: t('dashboard.filter.custom'),
                            },
                          ]
                        : []
                    }
                  />
                </Form.Item>
              </Col>
            )}
            {timezoneFilter && (
              <Col xs={24} lg={6} md={6}>
                <Form.Item
                  defaultValue={defaultTimezone}
                  name="timezone"
                  label={t('dashboard.fields.timezone')}
                >
                  <Select
                    showSearch
                    filterOption={filterTimezoneOption}
                    options={timezoneOptions}
                    defaultValue={defaultTimezone}
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} lg={4} md={4}>
              {sku && (
                <Form.Item name="sku" label={t('dashboard.fields.sku')}>
                  <Select
                    showSearch
                    onChange={searchSku}
                    defaultValue=""
                    options={listSkus}
                  />
                </Form.Item>
              )}
            </Col>
            {personalizedButton ? (
              <Col xs={24} lg={6} md={6}>
                <Form.Item
                  name="dateRangeOne"
                  label={t('dashboard.fields.rangeOne')}
                  rules={[
                    {
                      required: true,
                      message: t('dashboard.required.rangeOne'),
                    },
                  ]}
                >
                  <DatePicker.RangePicker
                    onChange={onDateRangeChange}
                    style={{ width: '100%' }}
                    disabledDate={(d) => !d || d.isAfter(moment())}
                  />
                </Form.Item>
              </Col>
            ) : (
              <Col xs={24} lg={6} md={6}>
                <Form.Item
                  hidden
                  name="dateRangeOne"
                  label={t('dashboard.fields.rangeOne')}
                  rules={[
                    {
                      required: true,
                      message: t('dashboard.required.rangeOne'),
                    },
                  ]}
                >
                  <DatePicker.RangePicker
                    onChange={onDateRangeChange}
                    style={{ width: '100%' }}
                    disabledDate={(d) => !d || d.isAfter(moment())}
                  />
                </Form.Item>
              </Col>
            )}
            {dashboard === 'inventory' && (
              <Col style={{ marginTop: 25 }}>
                <Button
                  loading={loadingSyncInventory}
                  className="btn-basic"
                  icon={<SyncOutlined />}
                  onClick={() =>
                    setShowInventorySyncModal(!showInventorySyncModal)
                  }
                >
                  {t('inventory.actions.syncInventory')}
                </Button>
              </Col>
            )}
          </Row>
          <Form.Item hidden name="sku" />
          <Form.Item hidden name="compare" />
          <Form.Item hidden name="dateRangeTwo" />
          <Form.Item hidden name="dateType">
            <Radio.Group defaultValue={dateTypes.PURCHASE_DATE}>
              <Radio value={dateTypes.PURCHASE_DATE}>
                {t('dashboard.fields.byPurchaseDate')}
              </Radio>
              <Radio value={dateTypes.LAST_UPDATE_DATE}>
                {t('dashboard.fields.byLastUpdateDate')}
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Row style={{ marginTop: 10 }}>
            <Button icon={<FilterOutlined />} type="primary" htmlType="submit">
              {t('dashboard.button')}
            </Button>
          </Row>
        </Form>
      )}
      <SyncInventory
        isModalOpen={showInventorySyncModal}
        setIsModalOpen={setShowInventorySyncModal}
        loading={loadingSyncInventory}
        setLoading={setLoadingSyncInventory}
        marketplaces={listMarketplaceAvailableClient}
      />
    </>
  );
}

DashboardFilter.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  getMarketplaces: PropTypes.func.isRequired,
  partnerId: PropTypes.number.isRequired,
  getSkusperMarketplaceDisabled: PropTypes.bool.isRequired,
  listMarketplaceAvailableClient: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
  listSkus: PropTypes.PropTypes.arrayOf(PropTypes.string).isRequired,
  ejectFunctions: PropTypes.func.isRequired,
  getSkusperMarketplace: PropTypes.func.isRequired,
  sku: PropTypes.string.isRequired,
  multiMarket: PropTypes.bool.isRequired,
  inventory: PropTypes.bool.isRequired,
  dashboard: PropTypes.string.isRequired,
  timezoneFilter: PropTypes.bool,
};

DashboardFilter.defaultProps = {
  timezoneFilter: false,
};

export default React.memo(DashboardFilter);

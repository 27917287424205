import createReducer from '../../store/createReducer';
import * as types from './types';

const initialData = {
  rows: [],
  rowsStock: [],
  loading: false,
  filter: {},
  rawFilter: {},
  listSkus: [],
  rawFilterResumen: {},
  loadingProgress: {},
  marketplaceAvailable: [],
  currencyDefault:
    localStorage.getItem('defaultCurrency') &&
    localStorage.getItem('defaultCurrency') !== ''
      ? localStorage.getItem('defaultCurrency')
      : 'USD',
};

const dashboard = createReducer(initialData, {
  [types.DASHBOARD_FETCH_STARTED](state, action) {
    return {
      ...state,
      rows: [],
      rowsStock: [],
      loading: true,
      filter: action.payload ? action.payload.filter : {},
      rawFilter: action.payload ? action.payload.rawFilter : {},
      loadingProgress: action.payload.loadingProgress,
    };
  },

  [types.DASHBOARD_FETCH_STARTED_STOCK](state, action) {
    return {
      ...state,
      rowsStock: [],
      loading: true,
      rawFilter: {},
      loadingProgress: action.payload.loadingProgress,
    };
  },

  [types.DASHBOARD_FETCH_SUCCESS](state, action) {
    if (!action.payload.isAdmin) {
      return {
        ...state,
        loading: true,
        rawFilterResumen: action.payload.rawFilterResumen
          ? action.payload.rawFilterResumen
          : [],
        rows: action.payload.rows ? action.payload.rows : [],
        loadingProgress: action.payload.loadingProgress,
        marketplaceAvailable: action.payload.marketplaceAvailable,
      };
    }
    return {
      ...state,
      loading: true,
      rawFilterResumen: action.payload.rawFilterResumen,
      rows: [...state.rows, ...action.payload.rows],
      loadingProgress: action.payload.loadingProgress,
      marketplaceAvailable: action.payload.marketplaceAvailable,
    };
  },

  [types.DASHBOARD_FETCH_SUCCESS_STOCK](state, action) {
    action.payload.rowsStock.map((partner) => {
      const newPartner = partner;
      newPartner.children = partner.children.map((market) => {
        const childrenWithoutParents = [];
        const parents = [];
        const modifiedMarket = market;
        modifiedMarket.children.forEach((listing) => {
          if (listing.children.length === 1 && !listing.children[0].isVariant) {
            childrenWithoutParents.push(listing.children[0]);
          } else {
            parents.push(listing);
          }
        });
        modifiedMarket.children = [...childrenWithoutParents, ...parents];
        return modifiedMarket;
      });
      return newPartner;
    });
    return {
      ...state,
      loading: true,
      rowsStock: [...state.rowsStock, ...action.payload.rowsStock],
      loadingProgress: action.payload.loadingProgress,
    };
  },

  [types.DASHBOARD_FETCH_FINISH](state, _action) {
    return {
      ...state,
      loading: false,
    };
  },
  [types.DASHBOARD_FETCH_ERROR](state, _action) {
    return {
      ...state,
      loading: false,
      rows: [],
    };
  },
  [types.DASHBOARD_RESETED](_state, _action) {
    return {
      ...initialData,
    };
  },
  [types.SET_CURRENCY_DEFAULT](state, action) {
    return {
      ...initialData,
      currencyDefault: action.payload.currencyDefault,
    };
  },
});

export default dashboard;

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography, Table, Image } from 'antd';
import fbaLogo from '../../../../assets/fbaLogo.png';
import 'antd/dist/antd.min.css';

const { Text } = Typography;

function FBAFromAmazon({ order, loadingShipment }) {
  const columnsShipment = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      render: (value, record) => (
        <Text ellipsis={{ tooltip: record?.orderId }}>{record?.orderId}</Text>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'orderStatus',
      key: 'status',
      width: 70,
      render: (value, record) => (
        <Text ellipsis={{ tooltip: record?.orderStatus }}>
          {record?.orderStatus}
        </Text>
      ),
    },
    {
      title: 'ITEMS IN SHIPMENT',
      dataIndex: 'date',
      key: 'date',
      width: 250,
      render: (value, record) => {
        return record.orderItems.map((items) => (
          <Col>
            <Row>
              <Col span={12}>
                <Text ellipsis={{ tooltip: items?.sku }}>
                  SKU: {items?.sku}
                </Text>
              </Col>
              <Col span={12}>
                <Text ellipsis={{ tooltip: items?.asin }}>
                  ASIN: {items?.asin}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Text
                  ellipsis={{ tooltip: items?.title }}
                  style={{ width: 250 }}
                >
                  TITLE: {items?.title}
                </Text>
              </Col>
            </Row>
          </Col>
        ));
      },
    },
    {
      title: 'SHIPPED VIA',
      dataIndex: 'fulfillment',
      key: 'fulfillment',
      width: 110,
      render: () => {
        if (fbaLogo)
          return (
            <Image style={{ width: '100px' }} preview={false} src={fbaLogo} />
          );
        return (
          <Text size="large" type="text">
            {' '}
            FBA{' '}
          </Text>
        );
      },
    },
  ];

  return (
    <Col>
      {['Shipped'].includes(order?.orderStatus) &&
        order?.orderItems?.length > 0 && (
          <>
            <Text style={{ fontSize: '22px' }}>Shipments</Text>
            <Row>
              <Table
                className="home-listing-table"
                size="small"
                loading={loadingShipment}
                columns={columnsShipment}
                dataSource={[order]}
                pagination={false}
                scroll={{ x: 1000 }}
              />
            </Row>
          </>
        )}
    </Col>
  );
}

FBAFromAmazon.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  loadingShipment: PropTypes.bool,
};

FBAFromAmazon.defaultProps = {
  loadingShipment: false,
};

export default React.memo(FBAFromAmazon);

import { Button, Form, Input, Modal, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import accessKeysApi from '../../../../api/aws-access-keys';
import openNotification from '../../../../components/Toastr';
import * as Actions from '../../../../redux/session/action';

const { Item } = Form;

function MiraklSellerAccount({ tab, session }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const saveCredentials = async (credentials) => {
    setIsLoading(true);
    try {
      await accessKeysApi.saveCredentials(
        session?.userInfo?.commercial_partner_id[0],
        tab,
        credentials
      );
      Modal.success({
        content: 'Se asoció la cuenta exitosamente',
      });
      dispatch(Actions.updateSellerAccountStatusSession(true));
    } catch (error) {
      openNotification({
        status: false,
        content: 'No se pudo validar la cuenta',
      });
    }
    setIsLoading(false);
  };

  return (
    <Form
      name={`${tab}-form`}
      form={form}
      onFinish={saveCredentials}
      style={{ width: '100%' }}
    >
      <Row>
        <span
          style={{
            fontSize: '18px',
            marginBottom: 10,
            marginTop: 15,
            width: '100%',
          }}
        >
          Clave de API
        </span>
      </Row>
      <Row>
        <Item
          name="shop_key"
          rules={[
            { required: true, message: 'Por favor ingresar Clave de API' },
          ]}
          style={{
            width: '100%',
            borderRadius: '10px',
          }}
        >
          <Input className="newModalConnectInput" />
        </Item>
      </Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type="primary"
          style={{
            marginTop: 40,
            marginBottom: 15,
            padding: '5px 20px',
            height: '40px',
          }}
          loading={isLoading}
          onClick={() => form.submit()}
        >
          Conectar marketplace
        </Button>
      </div>
    </Form>
  );
}

MiraklSellerAccount.propTypes = {
  tab: PropTypes.string.isRequired,
  session: PropTypes.instanceOf(Object).isRequired,
};

export default MiraklSellerAccount;

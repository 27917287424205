import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import PropTypes from 'prop-types';
import { CaretLeftOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import openNotification from '../../../../components/Toastr';
import companiesAPI from '../../../../api/company';
import usersAPI from '../../../../api/users';
import phones from '../../../../utils/json/phones.json';
import stateOrProvinces from '../../../../utils/json/stateOrProvinces.json';
import usePlacesInput from '../../../shared/usePlacesInput';

const { Title, Text } = Typography;
function CompanyData({
  form,
  onFinish,
  showBillingForm,
  showAccountForm,
  setCurrentAddress,
}) {
  const [company, setCompany] = useState();
  const [user, setUser] = useState();
  const session = useSelector((store) => store.Session.session);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [selectedCountryCode, setSelectedCountryCode] = useState();
  const [currStateOrProvinces, setCurrStateOrProvinces] = useState();

  const addressInput = usePlacesInput(
    form,
    setSelectedCountryCode,
    stateOrProvinces
  );

  const getStatesOrProvinces = () => {
    return currStateOrProvinces?.states.map((state) => (
      <Select.Option key={state.code} value={state.code}>
        {state.name}
      </Select.Option>
    ));
  };

  const onCountryCodeSelect = () => {
    const countryCode = form.getFieldValue('countryCode');
    setSelectedCountryCode(countryCode);
  };

  useEffect(() => {
    const newStateOrProvinces = stateOrProvinces.find(
      (row) => row.countryCode === selectedCountryCode
    );
    setCurrStateOrProvinces(newStateOrProvinces);
    form.setFieldsValue({
      stateOrProvinceCode:
        selectedCountryCode === company?.address?.countryCode &&
        newStateOrProvinces
          ? company?.address?.stateOrProvinceCode
          : undefined,
    });
  }, [selectedCountryCode]);

  useEffect(() => {
    const getCompany = async () => {
      try {
        const companyId = session.userInfo.company_id[0];
        const response = await companiesAPI.findById(companyId);
        setCompany(response.data);
        setSelectedCountryCode(response.data.address?.countryCode);
        setCurrentAddress(response.data.address);
      } catch (error) {
        openNotification({
          status: false,
          content: t('myAccount.defaultError'),
        });
      }
    };
    if (!company && session) {
      getCompany();
    }
  }, [company]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const userId = session.userInfo.id;
        const response = await usersAPI.findById(userId);
        setUser(response.data);
      } catch (error) {
        openNotification({
          status: false,
          content: t('myAccount.defaultError'),
        });
      }
    };
    if (!user && showAccountForm && session) {
      getUser();
    }
  }, [company]);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Col span={24} style={{ marginBottom: 20 }}>
        {showAccountForm && (
          <>
            <Row>
              <Col span={24}>
                <Row justify="space-between">
                  <Title level={4}>{t('myAccount.accountInfo.title')}</Title>
                </Row>
              </Col>
            </Row>
            {user && company && (
              <Row gutter={[16, 24]}>
                <Col span={12}>
                  <Form.Item
                    label={t('myAccount.accountInfo.name.label')}
                    name="name"
                    initialValue={company.name}
                    rules={[
                      {
                        required: true,
                        message: t('common.required'),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t('myAccount.accountInfo.name.placeholder')}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t('myAccount.accountInfo.userName.label')}
                    name="userName"
                    initialValue={user.name}
                    rules={[
                      {
                        required: true,
                        message: t('common.required'),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        'myAccount.accountInfo.userName.placeholder'
                      )}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={t('myAccount.accountInfo.userEmail.label')}
                    name="userEmail"
                    initialValue={user.email}
                    rules={[
                      {
                        required: true,
                        message: t('common.required'),
                      },
                    ]}
                  >
                    <Input
                      disabled
                      placeholder={t(
                        'myAccount.accountInfo.userEmail.placeholder'
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </>
        )}
      </Col>
      <Col span={24}>
        {showBillingForm && (
          <>
            <Row>
              <Col span={18}>
                <Title level={4}>{t('myAccount.billingInfo.title')}</Title>
              </Col>
              <Col span={6}>
                {location && location.pathname === '/suscripciones' && (
                  <Button
                    icon={<CaretLeftOutlined />}
                    size="small"
                    onClick={() => history.push('/planes')}
                  >
                    <Text level={6}>
                      {t('myAccount.billingInfo.backToPlans')}
                    </Text>
                  </Button>
                )}
              </Col>
            </Row>
            {company && (
              <Row gutter={[16, 24]}>
                <Col span={12}>
                  <Form.Item
                    label={t('myAccount.billingInfo.contactName.label')}
                    name="contactName"
                    initialValue={company.contactName}
                    rules={[
                      {
                        required: true,
                        message: t('common.required'),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        'myAccount.billingInfo.contactName.placeholder'
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t('myAccount.billingInfo.email.label')}
                    name="email"
                    initialValue={company.email}
                    rules={[
                      {
                        required: true,
                        message: t('common.required'),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t('myAccount.billingInfo.email.placeholder')}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label={t('myAccount.billingInfo.fantasyName.label')}
                    name="fantasyName"
                    initialValue={company.fantasyName}
                    rules={[
                      {
                        required: true,
                        message: t('common.required'),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        'myAccount.billingInfo.fantasyName.placeholder'
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={t('myAccount.billingInfo.ref.label')}
                    name="ref"
                    initialValue={company.ref}
                    rules={[
                      {
                        required: true,
                        message: t('common.required'),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t('myAccount.billingInfo.ref.placeholder')}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={t('myAccount.billingInfo.industry.label')}
                    name="industry"
                    initialValue={company.industry}
                    rules={[
                      {
                        required: true,
                        message: t('common.required'),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        'myAccount.billingInfo.industry.placeholder'
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t('myAccount.billingInfo.address.streetName')}
                    name="streetName"
                    initialValue={company?.address?.streetName}
                    rules={[
                      {
                        required: true,
                        message: t('common.required'),
                      },
                    ]}
                  >
                    {addressInput}
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    label={t(
                      'myAccount.billingInfo.address.additionalAddressInfo'
                    )}
                    name="additionalAddressInfo"
                    initialValue={company?.address?.additionalAddressInfo}
                  >
                    <Input showCount maxLength={35} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={t('myAccount.billingInfo.address.postalCode')}
                    name="postalCode"
                    initialValue={company?.address?.postalCode}
                    rules={[{ required: true, message: t('common.required') }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={t('myAccount.billingInfo.address.countryCode.label')}
                    name="countryCode"
                    initialValue={
                      company?.address ? company?.address?.countryCode : 'CL'
                    }
                    rules={[
                      {
                        required: true,
                        message: t('common.required'),
                      },
                    ]}
                  >
                    <Select
                      placeholder={t(
                        'myAccount.billingInfo.address.countryCode.placeholder'
                      )}
                      onSelect={onCountryCodeSelect}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {phones.length > 0 &&
                        phones.map((phoneElement) => (
                          <Select.Option
                            key={phoneElement.code}
                            label={phoneElement.name}
                          >
                            {phoneElement.flag} {phoneElement.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={t(
                      'myAccount.billingInfo.address.stateOrProvinceCode'
                    )}
                    name="stateOrProvinceCode"
                    rules={[
                      {
                        required: currStateOrProvinces,
                        message: t('common.required'),
                      },
                    ]}
                  >
                    <Select disabled={!currStateOrProvinces}>
                      {getStatesOrProvinces()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={t('myAccount.billingInfo.address.city')}
                    name="city"
                    initialValue={company?.address?.city}
                    rules={[
                      {
                        required: true,
                        message: t('common.required'),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </>
        )}
      </Col>
    </Form>
  );
}

CompanyData.propTypes = {
  form: PropTypes.instanceOf(Form).isRequired,
  onFinish: PropTypes.func.isRequired,
  showBillingForm: PropTypes.bool,
  showAccountForm: PropTypes.bool,
  setCurrentAddress: PropTypes.func.isRequired,
};

CompanyData.defaultProps = {
  showBillingForm: false,
  showAccountForm: false,
};

export default CompanyData;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Modal,
  Button,
  Row,
  Col,
  Divider,
  Form,
  Input,
  Select,
  Tooltip,
  Table,
  InputNumber,
  Skeleton,
  Typography,
  Alert,
  Image,
} from 'antd';
import {
  CheckCircleOutlined,
  Loading3QuartersOutlined,
  SmileFilled,
  SaveFilled,
  CalendarOutlined,
  FormOutlined,
  InfoCircleOutlined,
  EnvironmentFilled,
  ShoppingFilled,
  DownloadOutlined,
  RocketOutlined,
  StopOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.min.css';
import { useTranslation } from 'react-i18next';
import { orderSaleStates } from '../../../utils/const';
import openNotification from '../../../components/Toastr';
import { getErrorMessage } from '../../../api/api';
import orderSalesApi from '../../../api/order-sales';
import listingApi from '../../../api/listing';
import partnerApi from '../../../api/partner';
import courierApi from '../../../api/courier';
import FBAFromAnotherMarketplace from './fulfillmentOutbound/FBAFromAnotherMarketplace';
import FBAFromAmazon from './fulfillmentOutbound/FBAFromAmazon';
import FedexLogo from '../../../assets/fedex-logo.png';
import { mixPanelCreateEvent } from '../../../utils/functions';
import Int99Logo from '../../../assets/int99logo.png';
import FBMCouriers from '../../../utils/FBMCouriers';

const FBMLogo = {
  [FBMCouriers.FEDEX]: FedexLogo,
  [FBMCouriers.INT99MINUTOS]: Int99Logo,
};

const { Text, Title } = Typography;

const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

function FormByFulfillmentType({
  isReadOnly,
  cancelFBAFulfillmentOutbound,
  order,
  loadingSendFulfillment,
  loadingUpdateFBA,
  loadingCancelFBA,
  disabled,
  form,
}) {
  const fulfillmentType = order?.fulfillmentChannel;
  switch (fulfillmentType) {
    case 'FBA':
      if (!isReadOnly)
        return (
          <FBAFromAnotherMarketplace
            order={order}
            disabled={disabled}
            form={form}
            isReadOnly={isReadOnly}
            loadingSendFulfillment={loadingSendFulfillment}
            loadingCancelFBA={loadingCancelFBA}
            loadingUpdateFBA={loadingUpdateFBA}
            cancelFBAFulfillmentOutbound={cancelFBAFulfillmentOutbound}
            loadingShipment={false}
          />
        );
      if (
        [
          orderSaleStates.UNSHIPPED,
          orderSaleStates.SHIPPED,
          orderSaleStates.PARTIALLY_SHIPPED,
        ].includes(order.orderStatus) &&
        order.marketplace !== 'Amazon'
      ) {
        return (
          <FBAFromAnotherMarketplace
            order={order}
            disabled={disabled}
            form={form}
            isReadOnly={isReadOnly}
            loadingSendFulfillment={loadingSendFulfillment}
            loadingCancelFBA={loadingCancelFBA}
            loadingUpdateFBA={loadingUpdateFBA}
            cancelFBAFulfillmentOutbound={cancelFBAFulfillmentOutbound}
            loadingShipment={false}
          />
        );
      }
      return <FBAFromAmazon order={order} loadingShipment={false} />;
    default:
      return <Col />;
  }
}

FormByFulfillmentType.propTypes = {
  isReadOnly: PropTypes.bool.isRequired,
  cancelFBAFulfillmentOutbound: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  loadingSendFulfillment: PropTypes.bool.isRequired,
  loadingUpdateFBA: PropTypes.bool.isRequired,
  loadingCancelFBA: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
};

function FulfillmentModal({ isModalVisible, setModalVisible }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [loadingSendFulfillment, setLoadingSendFulfillment] = useState(false);
  const [loadingCancelFBA, setLoadingCancelFBA] = useState(false);
  const [loadingUpdateFBA, setLoadingUpdateFBA] = useState(false);
  const [order, setOrder] = useState();
  const [currency, setCurrency] = useState('USD');
  const [isReadOnly, setReadOnly] = useState(false);
  const [productsToMatchOnFBA, setProductsToMatchOnFBA] = useState();
  const [loadingListingsAndProducts, setLoadingGetProducts] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [footerButtons, setFooterButtons] = useState(false);
  const [loadingCreateShipment, setLoadingCreateShipment] = useState(false);
  const [loadingCancelShipment, setLoadingCancelShipment] = useState(false);
  const [courierShipment, setCourierShipment] = useState();
  const [labelUrl, setLabelUrl] = useState();
  const { t } = useTranslation();

  const skuTitle = (
    <Text>
      SKU FBA{' '}
      <Tooltip placement="bottom" title={t('home.fulfillmentModal.skuFBA')}>
        <InfoCircleOutlined style={{ color: 'black' }} />
      </Tooltip>
    </Text>
  );

  const updateOrder = (payload) => {
    setLoading(true);
    orderSalesApi
      .updateSaleOrder(payload?.id, payload)
      .then(() => {
        openNotification({
          status: true,
          content: t('home.fulfillmentModal.successfulUpdate'),
        });
        setLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
        setLoading(false);
      });
  };

  const saveOrder = (payload) => {
    setLoading(true);
    orderSalesApi
      .saveSaleOrder(payload)
      .then((res) => {
        form.setFieldsValue({ id: res?.data?.id });
        openNotification({
          status: true,
          content: t('home.fulfillmentModal.successfulSave'),
        });
        setLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
        setLoading(false);
      });
  };

  const handleSave = () => {
    const data = form.getFieldsValue();
    const payload = { ...order, ...data };
    if (data?.id) {
      payload.id = data?.id;
      updateOrder(payload);
    } else {
      saveOrder(payload);
    }
  };

  const handleCancelModal = () => {
    setModalVisible({ state: false, data: {} });
  };

  const getTotalDetail = (orderData) => {
    const data = {
      subtotal: 0,
      shippingPrice: 0,
      shippingTax: 0,
      taxPrice: 0,
      discount: 0,
      total: 0,
    };
    orderData?.orderItems.forEach((item) => {
      data.subtotal +=
        parseFloat(String(item?.itemPrice?.amount)) * (item.quantity || 0);
      data.taxPrice += parseFloat(String(item?.itemTax?.amount));
      data.shippingPrice += parseFloat(String(item?.shippingPrice?.amount));
      data.deliveryCost += parseFloat(String(item?.deliveryCost?.amount));
      data.discount += parseFloat(String(item?.promotionDiscount?.amount));
      data.discount += parseFloat(String(item?.shippingDiscount?.amount));
    });

    data.total = order?.orderTotal ? parseFloat(String(order?.orderTotal)) : 0;
    data.subtotal = data.subtotal?.toFixed(2) || 0;
    data.taxPrice = data.taxPrice?.toFixed(2) || 0;
    data.shippingPrice = data.shippingPrice?.toFixed(2) || 0;
    data.discount = data.discount?.toFixed(2) || 0;
    form.setFieldsValue(data);
  };

  useEffect(() => {
    if (order) {
      setDisabled(
        ['Canceled', 'Shipped'].includes(order?.orderStatus) ||
          order?.orderFulfillmentStatus === 'Completed'
      );
      if (['FBM', 'FBF', 'FBL'].includes(order?.fulfillmentChannel)) {
        setFooterButtons([
          <Button key="cancel" onClick={handleCancelModal}>
            {t('common.close')}
          </Button>,
        ]);
      } else {
        setFooterButtons([
          <Button key="cancel" onClick={handleCancelModal}>
            {t('common.close')}
          </Button>,
          <Button
            icon={<SaveFilled />}
            key="save"
            type="submit"
            className="btn-link-filled"
            loading={loading}
            onClick={handleSave}
          >
            {t('common.save')}
          </Button>,
        ]);
      }
    }
  }, [order]);

  const session = useSelector((store) => store.Session.session);
  const getDetails = () => {
    setLoadingItems(true);
    const values = {
      orderId: isModalVisible?.order?.orderId,
      partnerId: isModalVisible?.order?.partnerId,
      marketplace: isModalVisible?.order?.marketplace,
    };
    orderSalesApi
      .getByOrderId(values)
      .then((res) => {
        const data = {
          ...isModalVisible?.order,
          ...res?.data,
        };

        setReadOnly(
          data?.fulfillmentChannel === 'FBM' ||
            data?.marketplace === 'Amazon' ||
            data?.shipment?.canAutoFulfillmentOutbound
        );
        setOrder(data);
        getTotalDetail(data);
        setLoadingItems(false);
        form.setFieldsValue({
          shipment: {
            ...data.shipment,
            marketplaceId: data.marketplaceId || 'ATVPDKIKX0DER',
            shippingSpeedCategory: data.shippingSpeedCategory || 'Standard',
          },
          orderItems: data.orderItems,
          notificationEmails:
            data?.notificationEmails?.length > 0
              ? data?.notificationEmails
              : [session?.userInfo?.email, data?.buyerInfo?.email],
        });
      })
      .catch(() => {
        setLoadingItems(false);
      });
  };
  const getListingsByPartner = async () => {
    setLoadingGetProducts(true);
    const products = [];
    await partnerApi
      .productsWithInventoryAtOriginFba(
        isModalVisible?.order?.partnerId,
        isModalVisible?.order?.marketplace
      )
      .then((response) => {
        response?.data.forEach((product) => {
          if (product?.defaultCode && product?.externalId) {
            if (
              !products?.find(
                (p) =>
                  p.defaultCode === product.defaultCode &&
                  p.marketplace === product.marketplace
              )
            ) {
              products.push(product);
            }
          }
        });
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
    setLoadingGetProducts(false);
    setProductsToMatchOnFBA(products);
  };

  useEffect(() => {
    if (isModalVisible?.order) {
      form.resetFields();
      getDetails();
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (order) {
      form.setFieldsValue(order);
      setCurrency(order?.currency || 'USD');
    }
  }, [order]);

  useEffect(() => {
    if (!isReadOnly && order?.fulfillmentChannel === 'FBA') {
      getListingsByPartner();
    }
  }, [isReadOnly, order]);

  useEffect(() => {
    if (productsToMatchOnFBA?.length > 0) {
      const items = [];
      order?.orderItems.forEach((itemOrdered) => {
        const skuMatched = productsToMatchOnFBA?.find(
          (item) =>
            item?.sku === itemOrdered?.sellerSku ||
            item?.sku === itemOrdered?.sku
        );
        if (skuMatched) {
          items.push({
            ...itemOrdered,
            sellerSku: skuMatched.sku,
            sellerFulfillmentOrderItemId: skuMatched.sku,
          });
        } else {
          items.push({
            ...itemOrdered,
            sellerSku: t('home.fulfillmentModal.selectSellerSku'),
            sellerFulfillmentOrderItemId: null,
          });
        }
      });
      form.setFieldsValue({ orderItems: items });
    }
  }, [productsToMatchOnFBA]);

  useEffect(() => {
    const setShipmentLabelUrl = async () => {
      const shipmentDocument = await courierApi.getShipmentDocument(
        order.fbmCourier,
        courierShipment.masterTrackingNumber
      );
      setLabelUrl(shipmentDocument.data.url);
      return shipmentDocument;
    };
    if (courierShipment && order) {
      setShipmentLabelUrl();
    }
  }, [courierShipment]);

  const handleDownloadLabels = async () => {
    switch (courierShipment.courierCompanyName) {
      case FBMCouriers.FEDEX:
        window.open(labelUrl, '_blank');
        break;
      case FBMCouriers.INT99MINUTOS:
        await courierApi
          .getShipmentDocument(order.fbmCourier, courierShipment.id)
          .then((response) => {
            const linkSource = response.data[0].url;
            const downloadLink = document.createElement('a');
            const fileName = `etiqueta_${FBMCouriers.INT99MINUTOS}.pdf`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
          });

        break;
      default:
        break;
    }
  };

  const syncFbaStockByPartner = async (payload) => {
    const values = {
      partnerId: payload?.partnerId,
    };
    await listingApi
      .syncFbaStock(values)
      .then(() => {
        openNotification({
          status: true,
          content: t('home.fulfillmentModal.successfulSync'),
        });
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
    setLoadingSendFulfillment(false);
  };

  const updateFBAFulfillmentOutbound = (values) => {
    setLoadingUpdateFBA(true);
    const payload = { ...order, ...values };
    orderSalesApi
      .updateFBAFulfillmentOutbound(payload)
      .then((res) => {
        const response = res.data;
        if (res.data.success) {
          syncFbaStockByPartner(payload);
        }
        setLoadingUpdateFBA(false);
        openNotification({
          status: response.success,
          content: !response.success
            ? response.errors.message
            : t('home.fulfillmentModal.updatedFulfillment'),
          duration: 8,
        });
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
        setLoadingUpdateFBA(false);
      });
  };

  const saveFulfillment = (payload) => {
    setLoadingSendFulfillment(true);
    orderSalesApi
      .saveFulfillmentOutbound(payload)
      .then((res) => {
        const response = res.data;
        if (res.data.success) {
          syncFbaStockByPartner(payload);
        }
        openNotification({
          status: response.success,
          content: !response.success
            ? response.errors.message
            : t('home.fulfillmentModal.fulfillmentToFBA'),
          duration: 8,
        });
        setLoadingSendFulfillment(false);
      })
      .catch((error) => {
        setLoadingSendFulfillment(false);
        openNotification({ status: false, content: error });
      });
  };

  const doFulfillmentOutbound = (values) => {
    const newValues = values;
    const payload = {
      ...order,
      ...values,
      partnerId: isModalVisible?.order?.partnerId,
    };
    const fulfillmentType =
      !isReadOnly && order?.fulfillmentChannel === 'FBA' ? 'FBA' : 'FBM';
    const items = values?.orderItems?.map((item, index) => ({
      ...order?.orderItems[index],
      ...item,
      sellerFulfillmentOrderId: item.sku,
    }));
    switch (fulfillmentType) {
      case 'FBA':
        newValues.orderItems = items;
        if (
          order?.shipment?.sellerFulfillmentOrderId &&
          ['New', 'Received'].includes(order?.orderFulfillmentStatus)
        ) {
          updateFBAFulfillmentOutbound(payload);
        } else {
          saveFulfillment(payload);
        }
        break;
      case 'FBF':
      case 'FBM':
        break;
      default:
        break;
    }
  };

  const cancelFBAFulfillmentOutbound = (payload) => {
    setLoadingCancelFBA(true);
    if (payload) {
      order.cancellationReasonCode = payload;
    }
    orderSalesApi
      .cancelFBAFulfillmentOutbound(order)
      .then((res) => {
        const response = res.data;
        setLoadingCancelFBA(false);
        openNotification({
          status: response.success,
          content: !response.success
            ? response.errors.message
            : t('home.fulfillmentModal.cancelled'),
          duration: 8,
        });
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
        setLoadingCancelFBA(false);
      });
  };

  const calculateTotal = () => {
    const formData = form.getFieldsValue();
    const total =
      parseFloat(formData?.subtotal) +
      parseFloat(formData?.shippingPrice) +
      parseFloat(formData?.taxPrice);
    form.setFieldsValue({ total: parseFloat(total).toFixed(2) });
  };

  const columnsItems = [
    {
      title: 'SKU',
      dataIndex: 'image',
      key: 'image',
      width: 200,
      render: (value, record) => (
        <Text style={{ width: 200 }} ellipsis={{ tooltip: record?.sku }}>
          {record?.sku}
        </Text>
      ),
    },
    {
      title: skuTitle,
      dataIndex: 'image',
      key: 'image',
      width: 300,
      render: (_value, record, index) => {
        if (isReadOnly)
          return (
            <Text
              style={{ width: 300 }}
              ellipsis={{ tooltip: record?.sellerSku }}
            >
              {record?.sellerSku}
            </Text>
          );
        return (
          <>
            <Item
              rules={[{ required: true }]}
              name={['orderItems', index, 'sellerSku']}
            >
              <Select
                loading={loadingListingsAndProducts}
                style={{ width: '100%', marginTop: 15 }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={disabled}
              >
                {productsToMatchOnFBA?.map((item) => (
                  <Option key={item.sku} value={item.sku}>
                    {item?.sku}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item hidden name={['orderItems', index, 'sku']}>
              <Input />
            </Item>
            <Item hidden name={['orderItems', index, 'quantity']}>
              <Input />
            </Item>
            <Item hidden name={['orderItems', index, 'title']}>
              <Input />
            </Item>
          </>
        );
      },
    },
    {
      title: t('common.title'),
      dataIndex: 'image',
      key: 'image',
      width: 190,
      render: (value, record) => (
        <Text style={{ width: 190 }} ellipsis={{ tooltip: record?.title }}>
          {record?.title}
        </Text>
      ),
    },
    {
      title: t('home.fulfillmentModal.orderedQuantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      width: 160,
      render: (value, record) => <Text>{`${record?.quantity || 0}`}</Text>,
    },
    {
      title: t('common.price'),
      dataIndex: 'price',
      key: 'price',
      width: 140,
      render: (value, record) => (
        <Text>{`${record?.itemPrice?.currencyCode || currency} ${
          record?.itemPrice?.amount || 0
        }`}</Text>
      ),
    },
    {
      title: t('common.tax'),
      dataIndex: 'tax',
      key: 'tax',
      width: 130,
      render: (value, record) => (
        <Text>{`${record?.itemTax?.currencyCode || currency} ${
          record?.itemTax?.amount || 0
        }`}</Text>
      ),
    },
    {
      title: t('common.total'),
      dataIndex: 'x',
      key: 'x',
      width: 80,
      render: (value, record) => {
        const price = parseFloat(record?.itemPrice?.amount);
        const tax =
          parseFloat(record?.itemTax?.amount).toFixed(2) * record.quantity;

        return <Text>{`${price + tax || 0} `}</Text>;
      },
    },
  ];

  const generateShipment = async () => {
    setLoadingCreateShipment(true);
    try {
      const response = await courierApi.createCourierShipment(
        order.fbmCourier,
        order.id
      );
      mixPanelCreateEvent('API Rest', {
        status: true,
        message: 'Create Fedex Shipment',
        method: 'POST',
        url: response.config.url,
      });
      setCourierShipment(response.data);
    } catch (error) {
      if (error.response.status === 422)
        openNotification({
          status: false,
          content: t('home.fulfillmentModal.createErrors.0'),
        });
      else
        openNotification({
          status: false,
          content: t('home.fulfillmentModal.createErrors.1'),
        });
    }
    setLoadingCreateShipment(false);
  };

  const cancelShipment = async () => {
    setLoadingCancelShipment(true);
    try {
      const response = await courierApi.cancelCourierShipment(
        order.fbmCourier,
        courierShipment.id
      );
      if (response.data.success) {
        setCourierShipment(undefined);
      }
    } catch (error) {
      openNotification({
        status: false,
        content: getErrorMessage(t('home.fulfillmentModal.cancelError')),
      });
    }
    setLoadingCancelShipment(false);
  };

  return (
    <div id="datosCuentaBanco">
      <Modal
        title={`${t('common.saleOrder')} ${order?.orderId || ''}`}
        visible={isModalVisible.state}
        onCancel={handleCancelModal}
        width={1200}
        centered
        destroyOnClose
        footer={footerButtons}
      >
        {loadingItems ? (
          <div className="generic-spinner">
            <Skeleton active />
          </div>
        ) : (
          <Row>
            {!['FBA', 'FBM'].includes(order?.fulfillmentChannel) ? (
              <Col span={24}>
                <Title level={2}>{t('common.soon')}</Title>
              </Col>
            ) : (
              <>
                <Col span={5} xs={5} sm={5} md={5}>
                  <Row>
                    <Text style={{ fontSize: '22px', marginBottom: '15px' }}>
                      {t('common.details')}
                    </Text>
                  </Row>
                  <Row>
                    {' '}
                    <Text style={{ fontSize: '20px', color: 'grey' }}>
                      {t('common.date')}
                    </Text>
                  </Row>
                  <Row>
                    <Col>
                      <CalendarOutlined />
                    </Col>
                    <Text style={{ marginLeft: '5px' }}>
                      {new Date(order?.purchaseDate)?.toLocaleString()}
                    </Text>
                  </Row>
                  <Divider
                    style={{
                      display: 'block',
                      height: '1px',
                      border: 0,
                      borderTop: '1px solid #ccc',
                      margin: '1em 0',
                      padding: 0,
                    }}
                  />
                  <Text style={{ fontSize: '20px', color: 'grey' }}>
                    {t('common.status')}
                  </Text>
                  <Row>
                    {order.statusDetails &&
                      !order?.statusDetails[`${orderSaleStates.CANCELED}`] && (
                        <>
                          <Col style={{ marginTop: '5px' }} span={24}>
                            {!order?.statusDetails[
                              `${orderSaleStates.PAID}`
                            ] ? (
                              <Loading3QuartersOutlined
                                style={{ color: 'grey', marginRight: '5px' }}
                              />
                            ) : (
                              <CheckCircleOutlined
                                style={{ color: 'green', marginRight: '5px' }}
                              />
                            )}
                            <Text>{t('common.payment')}</Text>
                          </Col>
                          <Col style={{ marginTop: '5px' }} span={24}>
                            {!order?.statusDetails[
                              `${orderSaleStates.UNSHIPPED}`
                            ] ? (
                              <Loading3QuartersOutlined
                                style={{ color: 'grey', marginRight: '5px' }}
                              />
                            ) : (
                              <CheckCircleOutlined
                                style={{ color: 'green', marginRight: '5px' }}
                              />
                            )}
                            <Text>
                              {t('home.fulfillmentModal.readyToShip')}
                            </Text>
                          </Col>
                          <Col style={{ marginTop: '5px' }} span={24}>
                            {!order?.statusDetails[
                              `${orderSaleStates.SHIPPED}`
                            ] ? (
                              <Loading3QuartersOutlined
                                style={{ color: 'grey', marginRight: '5px' }}
                              />
                            ) : (
                              <CheckCircleOutlined
                                style={{ color: 'green', marginRight: '5px' }}
                              />
                            )}
                            <Text>{t('common.sent')}</Text>
                          </Col>
                        </>
                      )}
                    {order.statusDetails &&
                      order?.statusDetails[`${orderSaleStates.CANCELED}`] && (
                        <Col style={{ marginTop: '5px' }} span={24}>
                          {!order?.statusDetails[
                            `${orderSaleStates.CANCELED}`
                          ] ? (
                            <Loading3QuartersOutlined
                              style={{ color: 'grey', marginRight: '5px' }}
                            />
                          ) : (
                            <CheckCircleOutlined
                              style={{ color: 'green', marginRight: '5px' }}
                            />
                          )}
                          <Text>{t('common.cancelled')}</Text>
                        </Col>
                      )}
                  </Row>
                  <Divider
                    style={{
                      display: 'block',
                      height: '1px',
                      border: 0,
                      borderTop: '1px solid #ccc',
                      margin: '1em 0',
                      padding: 0,
                    }}
                  />
                  <Text style={{ fontSize: '20px', color: 'grey' }}>
                    {t('home.fulfillmentModal.shipmentPreference')}
                  </Text>
                  <Row style={{ marginTop: '5px' }}>
                    <Col>
                      <FormOutlined style={{ marginRight: '5px' }} />
                    </Col>
                    <Text>{order?.orderType || 'n/a'}</Text>
                  </Row>
                  <Divider
                    style={{
                      display: 'block',
                      height: '1px',
                      border: 0,
                      borderTop: '1px solid #ccc',
                      margin: '1em 0',
                      padding: 0,
                    }}
                  />
                  <Text style={{ fontSize: '20px', color: 'grey' }}>
                    {t('home.fulfillmentModal.shipmentAddress.title')}
                  </Text>
                  <Row style={{ flexFlow: 'no-wrap' }}>
                    <Col span={2}>
                      <EnvironmentFilled />
                    </Col>
                    <Col span={18}>
                      <Text>
                        {t('home.fulfillmentModal.shipmentAddress.city')}:{' '}
                        {order?.destinationAddress?.city || 'n/a'}{' '}
                      </Text>
                      <br />
                      <Text>
                        {t('home.fulfillmentModal.shipmentAddress.countryCode')}
                        : {order?.destinationAddress?.countryCode || 'n/a'}
                      </Text>
                      <br />
                      <Text>
                        {t('home.fulfillmentModal.shipmentAddress.postalCode')}:{' '}
                        {order?.destinationAddress?.postalCode || 'n/a'}
                      </Text>
                      <br />
                      <Text>
                        {t(
                          'home.fulfillmentModal.shipmentAddress.stateOrProvince'
                        )}
                        : {order?.destinationAddress?.stateOrProvince || 'n/a'}{' '}
                      </Text>
                      <br />
                      {order?.destinationAddress?.address1 && (
                        <>
                          <Text>
                            {t(
                              'home.fulfillmentModal.shipmentAddress.address1'
                            )}
                            : {order?.destinationAddress?.address1}{' '}
                          </Text>
                          <br />
                        </>
                      )}
                      {order?.destinationAddress?.address2 && (
                        <>
                          <Text>
                            {t(
                              'home.fulfillmentModal.shipmentAddress.address2'
                            )}
                            : {order?.destinationAddress?.address2}{' '}
                          </Text>
                          <br />
                        </>
                      )}
                      {order?.destinationAddress?.address3 && (
                        <>
                          <Text>
                            {t(
                              'home.fulfillmentModal.shipmentAddress.address3'
                            )}
                            : {order?.destinationAddress?.address3}{' '}
                          </Text>
                          <br />
                        </>
                      )}
                      {order?.destinationAddress?.phone && (
                        <Text>
                          {t('home.fulfillmentModal.shipmentAddress.phone')}:{' '}
                          {order?.destinationAddress?.phone}{' '}
                        </Text>
                      )}
                    </Col>
                  </Row>
                  {(order?.buyerInfo?.name ||
                    order?.buyerInfo?.username ||
                    order?.buyerInfo?.email) && (
                    <>
                      <Divider
                        style={{
                          display: 'block',
                          height: '1px',
                          border: 0,
                          borderTop: '1px solid #ccc',
                          margin: '1em 0',
                          padding: 0,
                        }}
                      />
                      <Text style={{ fontSize: '20px', color: 'grey' }}>
                        {t('home.fulfillmentModal.soldTo')}
                      </Text>
                      <Row>
                        <Col span={2}>
                          <SmileFilled style={{ color: 'grey' }} />
                        </Col>
                        <Col span={18}>
                          {order?.buyerInfo?.name && (
                            <Text
                              style={{
                                marginLeft: '5px',
                                width: 200,
                                textTransform: 'capitalize',
                              }}
                              ellipsis={{ tooltip: order?.buyerInfo?.name }}
                            >
                              {' '}
                              {order?.buyerInfo?.name}
                            </Text>
                          )}
                          {order?.buyerInfo?.username && (
                            <Text
                              style={{ marginLeft: '5px', width: 200 }}
                              ellipsis={{ tooltip: order?.buyerInfo?.username }}
                            >
                              {' '}
                              {order?.buyerInfo?.username}
                            </Text>
                          )}
                          {order?.buyerInfo?.email && (
                            <Text
                              style={{ marginLeft: '5px', width: 200 }}
                              ellipsis={{ tooltip: order?.buyerInfo?.email }}
                            >
                              {' '}
                              {order?.buyerInfo?.email}
                            </Text>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                  <Divider
                    style={{
                      display: 'block',
                      height: '1px',
                      border: 0,
                      borderTop: '1px solid #ccc',
                      margin: '1em 0',
                      padding: 0,
                    }}
                  />
                  <Text style={{ fontSize: '20px', color: 'grey' }}>
                    {t('home.fulfillmentModal.salesChannel')}
                  </Text>
                  <Row>
                    <Col>
                      <ShoppingFilled style={{ color: 'grey' }} />
                    </Col>
                    <Text style={{ marginLeft: '5px' }}>
                      {order?.salesChannel || 'n/a'}
                    </Text>
                  </Row>
                  <Divider
                    style={{
                      display: 'block',
                      height: '1px',
                      border: 0,
                      borderTop: '1px solid #ccc',
                      margin: '1em 0',
                      padding: 0,
                    }}
                  />
                </Col>
                <Col span={1} xs={1} sm={1} md={1} />
                <Col span={18} xs={18} sm={18} md={18}>
                  {order?.fulfillmentChannel === 'FBM' &&
                    (order?.orderStatus === orderSaleStates.UNSHIPPED ||
                      order?.orderStatus ===
                        orderSaleStates.READY_FOR_PICKUP) && (
                      <>
                        <Text style={{ fontSize: '22px' }}>
                          {t('common.labels')}
                          <Image
                            style={{ paddingLeft: '8px', paddingBottom: '5px' }}
                            height={28}
                            src={
                              courierShipment &&
                              FBMLogo[courierShipment.courierCompanyName]
                            }
                            preview={false}
                          />
                        </Text>

                        <Row>
                          {!courierShipment && (
                            <Button
                              onClick={generateShipment}
                              loading={loadingCreateShipment}
                            >
                              {' '}
                              <RocketOutlined style={{ fontSize: 16 }} />{' '}
                              {t(
                                'home.fulfillmentModal.shipmentOrder.generate'
                              )}
                            </Button>
                          )}

                          {!courierShipment && (
                            <Button
                              onClick={cancelShipment}
                              loading={loadingCancelShipment}
                            >
                              {' '}
                              <StopOutlined style={{ fontSize: 16 }} />{' '}
                              {t('home.fulfillmentModal.shipmentOrder.cancel')}
                            </Button>
                          )}

                          {courierShipment && (
                              <Button
                                onClick={cancelShipment}
                                loading={loadingCancelShipment}
                              >
                                {' '}
                                <StopOutlined style={{ fontSize: 16 }} />{' '}
                                {t(
                                  'home.fulfillmentModal.shipmentOrder.cancel'
                                )}
                              </Button>
                            ) && (
                              <Button
                                target="_blank"
                                onClick={handleDownloadLabels}
                              >
                                <DownloadOutlined />
                                {t('home.fulfillmentModal.downloadLabels')}
                              </Button>
                            )}
                        </Row>
                      </>
                    )}
                  {order?.shipment?.errors?.length > 0 && (
                    <Alert
                      style={{ marginBottom: 20 }}
                      message={<Text>{t('home.fulfillmentModal.alert')}</Text>}
                      description={order?.shipment?.errors.map((error) => (
                        <>
                          <Text key={error}>{error}</Text>
                          <br />
                        </>
                      ))}
                      type="warning"
                      showIcon
                    />
                  )}
                  <Text style={{ fontSize: '22px' }}>{t('common.items')}</Text>
                  <Form
                    form={form}
                    labelCol={{
                      span: 0,
                    }}
                    wrapperCol={{
                      span: 22,
                    }}
                    layout="vertical"
                    onFinish={doFulfillmentOutbound}
                  >
                    <Item hidden name={['id']}>
                      <Input hidden />
                    </Item>
                    <Table
                      className="home-listing-table"
                      columns={columnsItems}
                      dataSource={order?.orderItems?.map((item, index) => ({
                        ...item,
                        key: index,
                      }))}
                      pagination={false}
                      size="small"
                      scroll={{ x: 1300 }}
                    />
                    <Row
                      style={{
                        justifyContent: 'flex-end',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      <Col style={{ width: '220px' }}>
                        <Row
                          style={{
                            justifyContent: 'space-between',
                            wrap: 'nowrap',
                          }}
                        >
                          <Text>{t('common.subtotal')}</Text>
                          <Item style={{ marginBottom: '0' }} name="subtotal">
                            <InputNumber
                              precision={2}
                              min="0"
                              disabled
                              style={{ width: 120, marginLeft: '4px' }}
                              onChange={calculateTotal}
                              formatter={(value) => `${currency} ${value}`}
                              parser={(value) =>
                                parseFloat(value.replace(currency, ''))
                              }
                            />
                          </Item>
                        </Row>
                        {form.getFieldValue('shippingPrice') > 0 && (
                          <>
                            <Divider style={{ margin: '10px 0 5px 0' }} />
                            <Row
                              style={{
                                justifyContent: 'space-between',
                                wrap: 'nowrap',
                              }}
                            >
                              <Text>{t('common.shipping')}</Text>
                              <Item
                                style={{ marginBottom: '0' }}
                                rules={[{ required: true }]}
                                name="shippingPrice"
                              >
                                <InputNumber
                                  disabled
                                  onChange={calculateTotal}
                                  formatter={(value) => `${currency} ${value}`}
                                  parser={(value) =>
                                    parseFloat(value.replace(currency, ''))
                                  }
                                  style={{ width: 120, marginLeft: '4px' }}
                                  precision={2}
                                  min="0"
                                />
                              </Item>
                            </Row>
                          </>
                        )}
                        {form.getFieldValue('taxPrice') > 0 && (
                          <>
                            <Divider style={{ margin: '10px 0 5px 0' }} />
                            <Row style={{ justifyContent: 'space-between' }}>
                              <Text>{t('common.tax')}</Text>
                              <Item
                                style={{ marginBottom: '0' }}
                                rules={[{ required: true }]}
                                name="taxPrice"
                              >
                                <InputNumber
                                  disabled
                                  onChange={calculateTotal}
                                  style={{ width: 120, marginLeft: '4px' }}
                                  precision={2}
                                  min="0"
                                  formatter={(value) => `${currency} ${value}`}
                                  parser={(value) =>
                                    parseFloat(value.replace(currency, ''))
                                  }
                                />
                              </Item>
                            </Row>
                          </>
                        )}
                        {form.getFieldValue('discount') > 0 && (
                          <>
                            <Divider style={{ margin: '10px 0 5px 0' }} />
                            <Row style={{ justifyContent: 'space-between' }}>
                              <Text>{t('common.discount')}</Text>
                              <Item
                                style={{ marginBottom: '0' }}
                                rules={[{ required: true }]}
                                name="discount"
                              >
                                <InputNumber
                                  disabled
                                  onChange={calculateTotal}
                                  style={{ width: 120, marginLeft: '4px' }}
                                  precision={2}
                                  min="0"
                                  formatter={(value) => `${currency} ${value}`}
                                  parser={(value) =>
                                    parseFloat(value.replace(currency, ''))
                                  }
                                />
                              </Item>
                            </Row>
                          </>
                        )}
                        <Divider style={{ margin: '10px 0 5px 0' }} />
                        <Row style={{ justifyContent: 'space-between' }}>
                          <Text>{t('common.total')}</Text>
                          <Item style={{ marginBottom: '0' }} name="total">
                            <InputNumber
                              disabled
                              precision={2}
                              min="0"
                              style={{ width: 120, marginLeft: '4px' }}
                              formatter={(value) => `${currency} ${value}`}
                              parser={(value) =>
                                parseFloat(value.replace(currency, ''))
                              }
                            />
                          </Item>
                        </Row>
                      </Col>
                    </Row>
                    <Divider style={{ margin: '10px 0 5px 0' }} />

                    <FormByFulfillmentType
                      isReadOnly={isReadOnly}
                      cancelFBAFulfillmentOutbound={
                        cancelFBAFulfillmentOutbound
                      }
                      order={order}
                      loadingSendFulfillment={loadingSendFulfillment}
                      loadingUpdateFBA={loadingUpdateFBA}
                      loadingCancelFBA={loadingCancelFBA}
                      isModalVisible={isModalVisible}
                      disabled={disabled}
                      form={form}
                    />
                    <Row style={{ marginTop: 20 }}>
                      <Row>
                        <Text style={{ fontSize: '22px' }}>
                          {t('common.notes')}
                        </Text>
                      </Row>
                      <Row>
                        <FormOutlined
                          style={{
                            marginLeft: '7px',
                            marginTop: '10px',
                            fontSize: '14px',
                          }}
                        />
                      </Row>
                    </Row>
                    <Row>
                      <Item style={{ width: '100%' }} name={['note']}>
                        <TextArea rows={3} />
                      </Item>
                    </Row>
                  </Form>
                </Col>
              </>
            )}
          </Row>
        )}
      </Modal>
    </div>
  );
}

FulfillmentModal.propTypes = {
  isModalVisible: PropTypes.shape({
    state: PropTypes.bool,
    order: PropTypes.instanceOf(Object),
  }).isRequired,
  setModalVisible: PropTypes.func.isRequired,
};

export default FulfillmentModal;

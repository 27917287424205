import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  List,
  Divider,
  Typography,
  Button,
  Row,
  Col,
  Space,
  Image,
} from 'antd';
import PropTypes from 'prop-types';
import { format, register } from 'timeago.js';
import {
  AlertOutlined,
  CloseCircleFilled,
  FallOutlined,
  ShopOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import {
  getSvgMarketplace,
  changeUrlNotification,
  paymentMethodIcons,
} from '../../utils/functions';
import LapLogo from '../../assets/LAP.png';
import es from '../../i18n/locales/es';

function ListItem({ item, id, update, deleteItem, setOpen }) {
  const [showDelete, setShowDelete] = useState(false);
  const { t } = useTranslation();
  const language = useSelector((store) => store.Session.language);
  const logo = (
    <img
      src={LapLogo}
      className="logo"
      alt="Logo de LAP"
      height={50}
      style={{
        transition: 'all 0.5s',
        filter: 'brightness(0) invert(1)',
      }}
    />
  );

  register(
    'es_ES',
    (number, index) =>
      [
        ['justo ahora', 'ahora mismo'],
        ['hace %s segundos', 'en %s segundos'],
        ['hace 1 minuto', 'en 1 minuto'],
        ['hace %s minutos', 'en %s minutos'],
        ['hace 1 hora', 'en 1 hora'],
        ['hace %s horas', 'in %s horas'],
        ['hace 1 día', 'en 1 día'],
        ['hace %s días', 'en %s días'],
        ['hace 1 semana', 'en 1 semana'],
        ['hace %s semanas', 'en %s semanas'],
        ['1 mes', 'en 1 mes'],
        ['hace %s meses', 'en %s meses'],
        ['hace 1 año', 'en 1 año'],
        ['hace %s años', 'en %s años'],
      ][index]
  );

  const getAccountStatusChangedErrors = (metadata, marketplace) => {
    const type = 'ACCOUNT_STATUS_CHANGED';
    let mergedErrors = [];
    Object.keys(metadata).forEach((key) => {
      if (metadata[key]?.errors) {
        mergedErrors = mergedErrors.concat(metadata[key].errors);
      }
    });
    const uniqueErrorsSet = Array.from(new Set(mergedErrors));
    const baseMarketplace = marketplace.split(' ')[0];
    const translationErrorBaseKey = `notifications.message.${type}.3.${baseMarketplace}`;
    const errorMessages = uniqueErrorsSet
      .map((error) => {
        const errorKeyExists =
          es.notifications.message[type][3][baseMarketplace][error];
        return errorKeyExists
          ? t(`${translationErrorBaseKey}.${error}`)
          : `${t(`notifications.message.${type}.3.defaultError`)} ${error}.`;
      })
      .join('\n');
    return errorMessages;
  };

  const buildMessage = (type, data) => {
    switch (type) {
      case 'NEW_SALE_ORDER':
        return (
          <div>
            <span>{t(`notifications.message.${type}.0`)}</span>
            <ul style={{ margin: '4px 0px' }}>
              {Object.keys(data.summary).map((marketplace) => (
                <li>
                  <span className="w-500">{marketplace}:</span>{' '}
                  {` ${t(`notifications.message.${type}.1.orders`, {
                    count: data.summary[marketplace],
                  })}`}
                </li>
              ))}
            </ul>
            <span>{t(`notifications.message.${type}.2`)}</span>
          </div>
        );
      case 'LOW_STOCK':
      case 'OUT_OF_STOCK':
        return (
          <div>
            <span>{t(`notifications.message.${type}.0`)}</span>
            <ul style={{ margin: '4px 0px' }}>
              {Object.keys(data.summary).map((marketplace) => (
                <li>
                  <span className="w-500">{marketplace}:</span>{' '}
                  {data.summary[marketplace].length <= 3
                    ? data.summary[marketplace]
                        .map((product) => product.defaultCode)
                        .join(', ')
                    : `${data.summary[marketplace].length} ${t(
                        'common.product',
                        { count: 2 }
                      ).toLowerCase()}`}
                </li>
              ))}
            </ul>
            <span>{t(`notifications.message.${item.type}.1`)}</span>
          </div>
        );
      case 'UPCOMING_SUBSCRIPTION_BILLING':
      case 'FAILED_SUBSCRIPTION_BILLING': {
        const newData = {
          ...data,
          plan: data.plans ? data.plans.join(' + ') : data.plan,
          amount: `${new Intl.NumberFormat('es-CL', {
            style: 'currency',
            currency: data.currency,
          }).format(data.amount)}`,
        };
        return t(`notifications.message.${type}`, newData);
      }
      case 'ACCOUNT_STATUS_CHANGED': {
        const newData = {
          ...data,
          previousAccountStatus: t(
            `notifications.message.${type}.0.${data.previousAccountStatus}`
          ),
          actualAccountStatus: t(
            `notifications.message.${type}.0.${data.actualAccountStatus}`
          ),
        };
        const errorMessages = getAccountStatusChangedErrors(
          newData.metadata,
          newData.marketplace
        );
        const message = `${t(`notifications.message.${type}.1`, newData)}\n ${
          errorMessages.length > 0
            ? `${t(`notifications.message.${type}.2`)} ${errorMessages}`
            : ''
        }`;
        return message;
      }
      default:
        return t(`notifications.message.${type}`, data);
    }
  };

  const getAvatar = (type, data) => {
    let avatar = null;
    const style = { fontSize: '24px' };
    switch (type) {
      case 'ACCOUNT_STATUS_CHANGED':
      case 'SYNCED_MARKETPLACE':
      case 'SUCCESSFUL_CATALOGUE_UPLOAD':
      case 'FAILED_CATALOGUE_UPLOAD':
        avatar = getSvgMarketplace(data.marketplace);
        break;
      case 'LOW_STOCK':
        avatar = <FallOutlined style={style} />;
        break;
      case 'OUT_OF_STOCK':
        avatar = <AlertOutlined style={style} />;
        break;
      case 'NEW_SALE_ORDER':
        avatar = <ShoppingOutlined style={style} />;
        break;
      case 'UPCOMING_SUBSCRIPTION_BILLING':
      case 'FAILED_SUBSCRIPTION_BILLING':
        if (data.paymentMethod) {
          avatar = (
            <Image
              preview={false}
              style={{ width: 24, height: 24 }}
              src={paymentMethodIcons[data.paymentMethod]}
            />
          );
        }
        break;
      case 'FULFILLMENT_SALE_ORDER_ERROR':
        avatar = <ShopOutlined style={style} />;
        break;
      default:
        break;
    }
    return (
      <Avatar
        className="notification-avatar"
        shape="square"
        size={44}
        style={{
          backgroundColor: avatar ? 'white' : 'rgb(1, 12, 51)',
          display: 'flex',
          alignItems: 'center',
        }}
        src={avatar}
      >
        {!avatar && logo}
      </Avatar>
    );
  };

  const onClickNotification = async () => {
    if (!item?.read) {
      await update(item.id, { read: true });
    }
    setOpen(false);
  };

  const onClickDelete = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    await deleteItem(item.id);
  };

  return (
    <Link
      to={changeUrlNotification(item?.type)}
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
      onClick={onClickNotification}
    >
      <List.Item
        className={`notifications-item ${item.read ? 'read' : 'unread'}`}
        key={id}
      >
        <List.Item.Meta
          avatar={getAvatar(item.type, item.data)}
          title={
            <Row>
              <Col span={22}>
                <Typography.Text className={item.read ? 'w-500' : 'w-600'}>
                  {t(`notifications.type.${item.type}`)}
                </Typography.Text>
              </Col>
              <Col
                span={2}
                style={{ display: 'flex', justifyContent: 'right' }}
              >
                {showDelete && (
                  <Button
                    className="notification-close"
                    type="text"
                    shape="circle"
                    onClick={(e) => onClickDelete(e)}
                    style={{ position: 'absolute', padding: 0 }}
                    icon={<CloseCircleFilled style={{ fontSize: '18px' }} />}
                  />
                )}
              </Col>
            </Row>
          }
          description={
            <Space direction="vertical" style={{ width: '100%' }}>
              <Col span={22}>
                <Typography.Text>
                  {buildMessage(item.type, item.data)}
                </Typography.Text>
              </Col>
              <Row justify="end">
                <Typography.Text
                  className={`notification-timeago ${item.read ? '' : 'w-500'}`}
                >
                  {format(
                    item?.createdAt,
                    language === 'es' ? 'es_ES' : 'en_US'
                  )}
                </Typography.Text>
              </Row>
            </Space>
          }
        />
      </List.Item>
      <Divider style={{ marginBottom: '0px', marginTop: '0px' }} />
    </Link>
  );
}

ListItem.propTypes = {
  item: PropTypes.instanceOf(Array).isRequired,
  update: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ListItem;

import api from './api';

const prefix = '/amazonAds/marketplace/amazon/advertising';

export default {
  getClients(partnerId) {
    return api.get(`${prefix}/${partnerId}/profileId/list`);
  },
  generateReport(profileId, partnerId) {
    return api.post(`${prefix}/${partnerId}/${profileId}/report`);
  },
  getReport(reportId, profileId, filter, partnerId) {
    return api.get(
      `${prefix}/${partnerId}/${profileId}/report/${reportId}/summary`,
      filter
    );
  },
  getReportCampaign(reportId, profileId, partnerId) {
    return api.get(
      `${prefix}/${partnerId}/${profileId}/report/${reportId}/summary/campaign`
    );
  },
  getTotalSales(filter) {
    return api.get(`${prefix}/getTotalSales`, {
      params: filter,
    });
  },
  getSalesPerAdvertising(filter) {
    return api.get(`${prefix}/getSalesPerAdvertising`, {
      params: filter,
    });
  },
  getOrganicSales(filter) {
    return api.get(`${prefix}/getOrganicSales`, {
      params: filter,
    });
  },
  getInvestment(filter) {
    return api.get(`${prefix}/getInvestment`, {
      params: filter,
    });
  },
  getAds(filter) {
    return api.get(`${prefix}/getAdsAdvertising`, {
      params: filter,
    });
  },
  getTacos(filter) {
    return api.get(`${prefix}/getTacos`, {
      params: filter,
    });
  },
  getSoldUnits(filter) {
    return api.get(`${prefix}/getSoldUnits`, {
      params: filter,
    });
  },
  getUnitsPerAdvertising(filter) {
    return api.get(`${prefix}/getUnitsPerAdvertising`, {
      params: filter,
    });
  },
  getOrganicUnits(filter) {
    return api.get(`${prefix}/getOrganicUnits`, {
      params: filter,
    });
  },
  getDataDualAxeGraph(filter) {
    return api.get(`${prefix}/getDataDualAxeGraph`, {
      params: filter,
    });
  },
  getDataPerformanceBySkuTable(filter) {
    return api.get(`${prefix}/getDataPerformanceBySkuTable`, {
      params: filter,
    });
  },
  getDataPerformanceByCampaignTable(filter) {
    return api.get(`${prefix}/getDataPerformanceByCampaignTable`, {
      params: filter,
    });
  },
  getDataPerformanceByKeywordTable(filter) {
    return api.get(`${prefix}/getDataPerformanceByKeywordTable`, {
      params: filter,
    });
  },
  getImpressions(filter) {
    return api.get(`${prefix}/getImpressions`, {
      params: filter,
    });
  },
  getClicks(filter) {
    return api.get(`${prefix}/getClicks`, {
      params: filter,
    });
  },
  getCtr(filter) {
    return api.get(`${prefix}/getCtr`, {
      params: filter,
    });
  },
  getCvr(filter) {
    return api.get(`${prefix}/getCvr`, {
      params: filter,
    });
  },
  getCpc(filter) {
    return api.get(`${prefix}/getCpc`, {
      params: filter,
    });
  },
  getAcos(filter) {
    return api.get(`${prefix}/getAcos`, {
      params: filter,
    });
  },
  getKeywordRecommendation(payload) {
    return api.post(`${prefix}/keyword/recommendation`, payload);
  },
};

import React from 'react';
import { Pie } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  pieChartValue,
  thousandsSeparator,
} from '../../../../../utils/functions';
import './chartDonut.css';
import selectors from '../../../../../redux/analytic/dashboard/selectors';

function DemoPie({ dataSource, currency }) {
  const currencyState = useSelector(selectors.selectCurrency);
  const config = {
    appendPadding: 10,
    data: dataSource.map((i) => ({
      ...i,
      type: i.type || i.product_name || '-',
    })),
    width: 100,
    height: 300,
    angleField: 'value',
    colorField: 'type',
    color: ['#00E5A6', '#010C33', '#969696', '#CACACA', '#E8E8E8'],
    radius: 1,
    legend: {
      layout: 'vertical',
      position: 'right',
    },
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      autoRotate: false,
      content: ({ percent }) => pieChartValue(percent),
      style: {
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 500,
        fontFamily: 'Poppins',
      },
      layout: [
        {
          type: 'adjust-color',
        },
      ],
    },
    tooltip: {
      fields: ['product_name', 'type', 'value'],
      formatter: (datum) => ({
        name: `${datum.product_name ? datum.product_name : datum.type}`,
        value: `${thousandsSeparator(datum.value)} ${
          currency ? currencyState : ''
        }`,
      }),
      domStyles: {
        'g2-tooltip': { fontFamily: 'Poppins' },
        'g2-tooltip-title': { fontFamily: 'Poppins' },
        'g2-tooltip-list': { fontFamily: 'Poppins' },
        'g2-tooltip-list-item': { fontFamily: 'Poppins' },
        'g2-tooltip-marker': { fontFamily: 'Poppins' },
        'g2-tooltip-value': { fontFamily: 'Poppins' },
        'g2-tooltip-name': { fontFamily: 'Poppins' },
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    meta: {
      value: {
        formatter: (v) => `${v}`,
      },
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: '',
      },
    },
  };
  return (
    <Pie
      appendPadding={config.appendPadding}
      data={config.data}
      width={config.width}
      height={config.height}
      angleField={config.angleField}
      colorField={config.colorField}
      color={config.color}
      radius={config.radius}
      legend={config.legend}
      innerRadius={config.innerRadius}
      label={config.label}
      tooltip={config.tooltip}
      interactions={config.interactions}
      meta={config.meta}
      statistic={config.statistic}
    />
  );
}

DemoPie.propTypes = {
  dataSource: PropTypes.instanceOf(Object).isRequired,
  currency: PropTypes.string.isRequired,
};

export default DemoPie;

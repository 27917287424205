import {
  EditOutlined,
  FrownOutlined,
  InfoCircleOutlined,
  PictureOutlined,
  VerticalAlignBottomOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  Image,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  notification,
  Upload,
} from 'antd';
import 'antd/dist/antd.min.css';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorMessage } from '../../api/api';
import dashboardApi from '../../api/dashboard';
import openNotification from '../../components/Toastr';
import actionsAnalitycs from '../../redux/analytic/dashboard/actions';
import selectors from '../../redux/analytic/dashboard/selectors';
import {
  FboCouriersEnum,
  FulfillmentLabel,
  FulfillmentTypeMap,
  fulfillmentType as FulfillmentTypes,
  dateTypes,
  sellerMarketplaces,
} from '../../utils/const';
import DashboardFilter from './components/DashboardFilter';
import FBMModal from './components/FBMModal';
import getLinkToStore from './components/linkToStore';
import './home.css';
import { convertToISOString, translateErrors } from '../../utils/functions';
import fulfillmentAPI from '../../api/fulfillment';
import { changeFulfillmentErrors } from '../../utils/errors';
import JobAPI from '../../api/job';

function Inventario() {
  const dispatch = useDispatch();
  const session = useSelector((store) => store.Session.session);
  const rawfilter = useSelector(selectors.selectRawFilter);
  const [listSkus, setListSkus] = useState([]);
  const [listMarketplaceAvailableClient, setListMarketplaceAvailableClient] =
    useState([]);
  const [listInventory, setListInventory] = useState([]);
  const [fulfillmentCompanies, setFulfillmentCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [clientListings, setClientListings] = useState([]);
  const [lastFormFilterValues, setLastFormFilterValues] = useState(null);
  const [fbmModal, setFbmModal] = useState({
    value: false,
    data: {},
    title: null,
  });
  const [loadingSyncStock, setLoadingSyncStock] = useState({
    loading: false,
    listingIndex: null,
  });
  const [translatePath, setTranslatePath] = useState({});
  const { t } = useTranslation();

  let partnerId = 1; // admin
  const { isAdmin } = session.userInfo;

  if (!isAdmin) {
    [partnerId] = session.userInfo.commercial_partner_id;
  }

  const processInventoryData = (products) => {
    if (!products || products.length === 0) return products;
    const listingIds = new Set();
    products.forEach((product) => {
      if (product.is_variant) {
        listingIds.add(product.listing_id);
      }
    });

    return products.filter((product) => {
      // Variants
      if (product.is_variant) {
        return true;
      }
      // Parents without variants
      return !listingIds.has(product.listing_id);
    });
  };

  const getInventoryperSku = async (formFilter) => {
    setLoading(true);
    setLastFormFilterValues(formFilter);
    await fulfillmentAPI
      .getFulfillmentByPartnerAndMarketplace(formFilter.marketplace)
      .then((response) => {
        setFulfillmentCompanies(response.data);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });

    await dashboardApi
      .getInventoryPerSku(formFilter)
      .then((response) => {
        const skuAvailable = [];
        if (response?.data?.length > 0) {
          const products = processInventoryData(response?.data);
          setListInventory(products);
          setClientListings([
            {
              products: products?.map((inventory, index) => ({
                ...inventory,
                key: index,
                defaultCode: inventory?.sku,
              })),
            },
          ]);
          if (formFilter.marketplace !== rawfilter.marketplace) {
            setListSkus([]);
            response?.data.forEach((e) => {
              skuAvailable.push({
                value: e.sku,
                label: e.sku,
              });
            });
            skuAvailable.splice(0, 0, {
              value: '',
              label: 'All',
            });
            dispatch(
              actionsAnalitycs.setFilter({
                ...formFilter,
                listSkus: skuAvailable,
              })
            );
            setListSkus(skuAvailable);
          }
          setLoading(false);
        } else {
          setListSkus([]);
          setListInventory([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const getMarketplaces = async (partner) => {
    let ptrs = [];
    if (!isAdmin) {
      ptrs = [partnerId];
    } else {
      ptrs = [partner];
    }
    const defaultFilters = {
      partner: ptrs,
      sku: [],
      marketplace: Object.values(sellerMarketplaces),
      category: ['sales', 'stock'],
      compare: false,
      dateType: dateTypes.PURCHASE_DATE,
      dateRangeOne: convertToISOString([moment().add(-1, 'M'), moment()]),
      dateRangeTwo: [],
      fulfillment: '',
      inventory: true,
    };

    await dashboardApi
      .getMarketplacesPerClient(defaultFilters)
      .then((response) => {
        const marketAvailable = [];
        if (response?.data?.length > 0) {
          response?.data.forEach((e) => {
            if (e.name === 'Amazon') {
              marketAvailable.unshift(e.name);
            } else {
              marketAvailable.push(e.name);
            }
          });
          dispatch(
            actionsAnalitycs.setFilter({
              ...defaultFilters,
              dateRangeOne: [moment().startOf('month'), moment()],
              marketplace: marketAvailable,
            })
          );
        }
        setListMarketplaceAvailableClient(marketAvailable);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const refreshInventoryData = () => {
    if (lastFormFilterValues) {
      getInventoryperSku(lastFormFilterValues);
    }
  };

  const handleChangeFulfillment = async (fboCompanyCompleteName, product) => {
    let fboCompany = fboCompanyCompleteName;
    if (fboCompany.includes('Amazon')) {
      fboCompany = FboCouriersEnum.AMAZON;
    }

    const fulfillmentType = FulfillmentTypeMap[fboCompany] || fboCompany;
    setLoading(true);
    const payload = {
      productDefaultCode: product.sku,
      fulfillmentType,
      fboFulfillmentCompany:
        fulfillmentType !== FulfillmentTypes.FBM ? fboCompany : null,
      marketplace: lastFormFilterValues.marketplace,
    };

    await fulfillmentAPI
      .updateProductFulfillment(payload)
      .then(() => {
        setLoading(false);
        openNotification({
          status: true,
          content: t('home.listing.successfulLogistics'),
        });
      })
      .catch((error) => {
        setLoading(false);
        setTranslatePath(translateErrors(error, changeFulfillmentErrors));
      });
    refreshInventoryData();
  };

  useEffect(() => {
    const textError = t(translatePath.error);
    if (Object.keys(translatePath).length !== 0) {
      notification.error({
        style: { zIndex: 'inherit' },
        duration: 0,
        message: translatePath?.titleError
          ? t(translatePath.titleError)
          : 'Error',
        description: textError,
        ...(translatePath?.tooltip
          ? {
              btn: (
                <Button
                  style={{ color: '#00e5a6' }}
                  type="link"
                  href={translatePath?.tooltip}
                  target="_blank"
                >
                  {t('common.wantKnowMore')}
                </Button>
              ),
            }
          : {}),
        icon: (
          <FrownOutlined
            className="error"
            style={{ color: '#F81D22 !important' }}
          />
        ),
      });
    }
  }, [translatePath]);

  const getFulfillmentLabel = (value) => {
    const fulfillmentType = value.fulfillment_type;
    const fulfillmentCompany = value?.fbo_fulfillment_company || undefined;
    return FulfillmentLabel[fulfillmentType] || fulfillmentCompany;
  };

  useEffect(() => {
    getMarketplaces();
  }, []);

  const ejectFunctions = (values) => {
    setListInventory([]);
    if (values) {
      dispatch(actionsAnalitycs.setFilter(values));
      getInventoryperSku(values);
    }
  };

  const numberWithCommas = (x) =>
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const renderCurrentCurrency = (value, row) => {
    const number = Number(value[row]);
    if (number > 0) {
      return !number ? undefined : (
        <span>
          {number === '-' ? '-' : numberWithCommas(number.toFixed())}{' '}
        </span>
      );
    }
    return 0;
  };

  const renderProduct = (record) => {
    const imgUrl = record?.url;
    return (
      <Row align="middle" justify="start">
        <Col span={24}>
          <Space>
            {imgUrl ? (
              <Avatar
                style={{ width: 40, height: 40 }}
                shape="square"
                className="home-listing-table-avatar"
                src={<Image src={`${imgUrl}`} />}
              />
            ) : (
              <Avatar
                style={{ width: 40, height: 40 }}
                shape="square"
                className="home-listing-table-avatar"
                icon={<PictureOutlined />}
              />
            )}
            <Space direction="vertical" size={0}>
              {getLinkToStore[record?.marketplace](record)}
              <Tooltip title={record.title}>SKU: {record.sku}</Tooltip>
            </Space>
          </Space>
        </Col>
      </Row>
    );
  };

  const showFBMStock = (record) => {
    if (record?.fulfillment_type === FulfillmentTypes.FBM) {
      return true;
    }
    return false;
  };

  const columns = [
    {
      title: (
        <div className="topMargin-header">
          {t('inventory.table.headers.product')}
        </div>
      ),
      dataIndex: 'title',
      align: 'left',
      key: 'title',
      ellipsis: true,
      width: '40%',
      render: (value, record) => {
        const component = renderProduct(record);
        return component;
      },
    },
    {
      title: (
        <div className="topMargin-header">
          <Tooltip title={t('inventory.table.tooltips.fulfillment')}>
            {t('inventory.table.headers.fulfillment')} <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      align: 'center',
      width: '15%',
      key: 'fulfillmentType',
      render: (value) => {
        return (
          <Select
            disabled={
              fulfillmentCompanies.length === 0 ||
              getFulfillmentLabel(value) === 'Amazon'
            }
            onChange={(fboCompany) =>
              handleChangeFulfillment(fboCompany, value)
            }
            value={getFulfillmentLabel(value)}
          >
            {fulfillmentCompanies.map((company) => (
              <Select.Option key={company} value={company}>
                {company}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: (
        <div className="topMargin-header">
          <Tooltip title={t('inventory.table.tooltips.availableStock')}>
            {t('inventory.table.headers.availableStock')} <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'available_stock',
      align: 'center',
      width: '15%',
      key: 'available_stock',
      sorter: (a, b) => a.available_stock - b.available_stock,
      render: (text, record, index) => (
        <Row justify="center">
          <Col span={6}>{record?.available_stock}</Col>
          {showFBMStock(record) ? (
            <Col span={4}>
              <Tooltip placement="topLeft" title="Actualizar stock FBM">
                <Button
                  type="text"
                  onClick={() =>
                    setFbmModal({
                      value: true,
                      clientListings,
                      product: {
                        ...record,
                        key: index,
                        defaultCode: record?.sku,
                        inventory: { availableStock: record?.available_stock },
                      },
                      title: `Actualización manual de stock para ${record?.sku}`,
                    })
                  }
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            </Col>
          ) : (
            <Col span={4} />
          )}
        </Row>
      ),
    },
    {
      title: (
        <div className="topMargin-header">
          <Tooltip title={t('inventory.table.tooltips.totalStock')}>
            {t('inventory.table.headers.totalStock')} <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'total_stock',
      align: 'center',
      sorter: (a, b) => a.total_stock - b.total_stock,
      width: '15%',
      key: 'total_stock',
    },
    {
      title: (
        <>
          <div className="firstLine-header">
            {t('inventory.table.headers.soldUnits')}
          </div>
          <div className="secondLine-header">
            <Tooltip title={t('inventory.table.tooltips.last30days')}>
              {t('inventory.table.headers.last30days')} <InfoCircleOutlined />
            </Tooltip>
          </div>
        </>
      ),
      dataIndex: 'last30days',
      align: 'center',
      width: '15%',
      sorter: (a, b) => a.last30days - b.last30days,
      key: 'last30days',
      render: (value, record) => {
        const priceTotal = renderCurrentCurrency(record, 'last30days');
        return priceTotal;
      },
    },
    {
      title: (
        <>
          <div className="firstLine-header">
            {t('inventory.table.headers.soldUnits')}
          </div>
          <div className="secondLine-header">
            <Tooltip title={t('inventory.table.tooltips.last90days')}>
              {t('inventory.table.headers.last90days')} <InfoCircleOutlined />
            </Tooltip>
          </div>
        </>
      ),
      dataIndex: 'last90days',
      align: 'center',
      width: '15%',
      sorter: (a, b) => a.last90days - b.last90days,
      key: 'last90days',
      render: (value, record) => {
        const priceTotal = renderCurrentCurrency(record, 'last90days');
        return priceTotal;
      },
    },
  ];

  const downloadFileInventory = async () => {
    setLoadingFile(true);
    const params = { marketplace: rawfilter?.marketplace };
    return dashboardApi
      .downloadInventorySheet(params)
      .then(() => {
        setLoadingFile(false);
      })
      .catch(() => {
        openNotification({
          status: false,
          content: t('home.inventory.errorDownloadFile'),
        });
      });
  };

  const uploadFileInventory = async (file) => {
    setUploadingFile(true);
    try {
      await JobAPI.addBulkUpdateStockJob(file);
      openNotification({
        status: true,
        content: t('home.inventory.successUploadFile'),
      });
    } catch (error) {
      openNotification({
        status: false,
        content: t('home.inventory.errorUploadFile'),
      });
    }
    setUploadingFile(false);
  };

  return (
    <Row className="initialContent">
      <Col span={24} xs={24} sm={24} md={24}>
        <Row className="filtros-detalles">
          <Col xs={24} md={24} lg={24} xl={24} span={12}>
            <Card
              bordered={false}
              style={{ width: '100%', height: '100%' }}
              className="contentCard"
            >
              <Row>
                <Col xs={24} span={24} className="intro-filters">
                  <DashboardFilter
                    dashboard="inventory"
                    getMarketplaces={getMarketplaces}
                    ejectFunctions={ejectFunctions}
                    listMarketplaceAvailableClient={
                      listMarketplaceAvailableClient
                    }
                    isAdmin={isAdmin}
                    listSkus={listSkus}
                    multiMarket={false}
                    inventory
                    sku
                    getSkusperMarketplaceDisabled
                    partnerId={partnerId}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            xs={24}
            md={24}
            lg={24}
            xl={24}
            span={12}
            style={{ textAlign: 'right' }}
          >
            <Card
              bordered={false}
              style={{ width: '100%', height: '100%' }}
              className="contentCard"
            >
              <Upload
                className="btn-basic"
                name="file"
                accept=".xlsx"
                showUploadList={false}
                beforeUpload={(file) => {
                  uploadFileInventory(file);
                }}
              >
                <Button className="btn-basic">
                  <Space>
                    {t('products.fields.uploadProductsFile')}{' '}
                    {uploadingFile ? (
                      <Spin style={{ marginTop: '3px' }} size="small" />
                    ) : (
                      <UploadOutlined />
                    )}
                  </Space>
                </Button>
              </Upload>
              <Button className="btn-basic" onClick={downloadFileInventory}>
                <Space>
                  {t('products.fields.downloadProductsFile')}{' '}
                  {loadingFile ? (
                    <Spin style={{ marginTop: '3px' }} size="small" />
                  ) : (
                    <VerticalAlignBottomOutlined />
                  )}
                </Space>
              </Button>
            </Card>
          </Col>
          <Col xs={24} md={24} lg={24} xl={24} span={12}>
            <Card
              bordered={false}
              style={{ width: '100%', height: '100%' }}
              className="contentCard"
            >
              <Table
                loading={loading}
                size="middle"
                columns={columns}
                dataSource={listInventory}
                pagination
              />
            </Card>
          </Col>
        </Row>
      </Col>
      <FBMModal
        partnerId={partnerId}
        visible={fbmModal}
        setVisible={setFbmModal}
        setLoadingSyncStock={setLoadingSyncStock}
        loadingSyncStock={loadingSyncStock}
        clientListings={clientListings}
        refreshMethod={refreshInventoryData}
      />
    </Row>
  );
}

export default Inventario;

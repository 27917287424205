import PropTypes from 'prop-types';
import { Card, Space } from 'antd';

function InformationBox({ icon, status, textInformationBox, display }) {
  const colors = {
    processing: {
      background: 'rgba(237, 245, 255, 1)',
      borderColor: 'rgba(0, 74, 217, 0.4)',
    },
    warning: {
      background: '#FCF4D6',
      borderColor: 'rgb(250, 173, 20)',
    },
  };
  return (
    <Card
      size="small"
      style={{
        backgroundColor: colors[status].background,
        border: `solid 1px ${colors[status].borderColor}`,
        borderRadius: 4,
        display: `${display}`,
      }}
    >
      <Space style={{ alignItems: 'baseline' }}>
        {icon}
        {textInformationBox}
      </Space>
    </Card>
  );
}

InformationBox.propTypes = {
  icon: PropTypes.element.isRequired,
  status: PropTypes.string.isRequired,
  textInformationBox: PropTypes.element.isRequired,
  display: PropTypes.string,
};

InformationBox.defaultProps = {
  display: 'none',
};

export default InformationBox;

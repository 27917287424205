/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Button, Spin, Avatar, Row, Col, Typography } from 'antd';
import {
  UserOutlined,
  LoadingOutlined,
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
  AlignLeftOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, Route, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isExpired } from '../helpers/auth-helpers';
import partnerApi from '../api/partner';
import NavMenu from '../layouts/NavMenu';
import MenuOptions from '../layouts/MenuOptions';
import Headers from '../layouts/Header';
import { mixPanelCreateEvent } from '../utils/functions';
import api from '../api/api';
import * as Actions from '../redux/session/action';
import refreshSession from '../api/refreshSession';

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 30, marginTop: 2, marginRight: 10 }}
    spin
  />
);
const { Header, Content, Sider } = Layout;
const { Title } = Typography;

function AuthenticatedRoute({ path, component: Component, exact }) {
  const { t } = useTranslation();
  const session = useSelector((store) => store.Session.session);
  const dispatch = useDispatch();
  const history = useHistory();
  const showPlans = path === '/planes';
  const showSubscriptions = path === '/suscripciones';
  const showCheckout =
    path === '/checkout/mercadoPago' || path === '/checkout/payPal';
  const stripeSessionCompleted =
    path === '/' && history.location.search.includes('session_id=');
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [route, setRoute] = useState(false);

  useEffect(() => {
    const handleRedirect = async () => {
      if (session) {
        if (!session.token || isExpired(session.token)) {
          try {
            const result = await refreshSession(api, session);
            if (result?.token) {
              dispatch(Actions.refreshSession(result));
            }
          } catch (error) {
            return '/login';
          }
        } else if (
          !session.companyActive &&
          !showSubscriptions &&
          !showCheckout &&
          !showPlans &&
          !stripeSessionCompleted
        ) {
          return '/planes';
        } else {
          mixPanelCreateEvent('Page View', {
            url: location.pathname,
          });
        }
      } else {
        return '/login';
      }
      return false;
    };
    handleRedirect().then((redirect) => {
      if (redirect) {
        history.replace(redirect);
      }
      setRoute(true);
    });
    if (window._cio) {
      window._cio.page(location.pathname);
    }
  }, [session, location]);

  const [widthMenu, setWidthMenu] = useState({
    width: 240,
    marginTop: '10px',
    orientation: 'vertical',
    mobile: false,
    marginLeft: 'auto',
    marginRight: 'auto',
    positionShowMenu: true,
    widthLogo: 50,
    options: [
      {
        title: t('menu.dashboards'),
        path: 'dashboard',
        url: '/',
      },
      {
        title: t('menu.sales'),
        path: 'ventas',
        url: '/ventas',
      },
      {
        title: t('menu.listings'),
        path: 'listings',
        url: '/listings',
        subOptions: [
          {
            title: t('menu.your_products'),
            path: 'tus_productos',
            url: '/tus_productos',
          },
          {
            title: t('menu.your_publications'),
            path: 'tus_publicaciones',
            url: '/tus_publicaciones',
          },
        ],
      },
      {
        title: t('menu.shopping'),
        path: 'compras',
        url: '/compras',
      },
      {
        title: t('menu.support'),
        path: 'soporte',
        url: '/soporte',
      },
      {
        title: t('menu.status'),
        path: 'estado',
        url: '/estado',
      },
    ],
  });
  const [widthMenuMobile, setWidthMenuMobile] = useState({
    width: 0,
    marginTop: '10px',
    orientation: 'vertical',
    mobile: false,
    marginLeft: 'auto',
    marginRight: 'auto',
    widthLogo: 25,
    positionShowMenu: false,
    options: false,
  });

  const changeMenu = (value) => {
    setWidthMenu(
      value
        ? {
            width: 90,
            marginTop: '10px',
            orientation: 'vertical',
            mobile: false,
            marginLeft: 'auto',
            marginRight: 'auto',
            widthLogo: 25,
            positionShowMenu: false,
            options: false,
          }
        : {
            width: 250,
            marginTop: '10px',
            orientation: 'inline',
            mobile: false,
            marginLeft: 'auto',
            marginRight: 'auto',
            widthLogo: 50,
            positionShowMenu: true,
            options: [
              {
                title: t('menu.dashboards'),
                path: 'dashboard',
                url: '/',
              },
              {
                title: t('menu.sales'),
                path: 'ventas',
                url: '/ventas',
              },
              {
                title: t('menu.listings'),
                path: 'listings',
                url: '/listings',
                subOptions: [
                  {
                    title: t('menu.your_products'),
                    path: 'tus_productos',
                    url: '/tus_productos',
                  },
                  {
                    title: t('menu.your_publications'),
                    path: 'tus_publicaciones',
                    url: '/tus_publicaciones',
                  },
                ],
              },
              {
                title: t('menu.shopping'),
                path: 'compras',
                url: '/compras',
              },
              {
                title: t('menu.support'),
                path: 'soporte',
                url: '/soporte',
              },
              {
                title: t('menu.status'),
                path: 'estado',
                url: '/estado',
              },
            ],
          }
    );
  };

  const getLogo = async () => {
    setLoading(true);
    try {
      const response = await partnerApi.findLogoById(
        session.userInfo.commercial_partner_id[0]
      );
      setLogo(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    changeMenu(false);
  }, []);

  useEffect(() => {
    if (session && session.userInfo) {
      if (!logo) {
        getLogo();
      }
    }
    return () => {
      setLogo(null);
      setLoading(false);
    };
  }, [session]);

  const changeMenuMobile = (value) => {
    setWidthMenuMobile(
      value
        ? {
            width: 0,
            marginTop: '10px',
            orientation: 'vertical',
            mobile: true,
            marginLeft: 'auto',
            marginRight: 'auto',
            widthLogo: 25,
            positionShowMenu: false,
            options: false,
          }
        : {
            width: 250,
            marginTop: '10px',
            orientation: 'inline',
            mobile: true,
            marginLeft: 'auto',
            marginRight: 'auto',
            widthLogo: 50,
            positionShowMenu: true,
            options: [
              {
                title: t('menu.dashboards'),
                path: 'dashboard',
                url: '/',
              },
              {
                title: t('menu.sales'),
                path: 'ventas',
                url: '/ventas',
              },
              {
                title: t('menu.listings'),
                path: 'listings',
                url: '/listings',
                subOptions: [
                  {
                    title: t('menu.your_products'),
                    path: 'tus_productos',
                    url: '/tus_productos',
                  },
                  {
                    title: t('menu.your_publications'),
                    path: 'tus_publicaciones',
                    url: '/tus_publicaciones',
                  },
                ],
              },
              {
                title: t('menu.shopping'),
                path: 'compras',
                url: '/compras',
              },
              {
                title: t('menu.support'),
                path: 'soporte',
                url: '/soporte',
              },
              {
                title: t('menu.status'),
                path: 'estado',
                url: '/estado',
              },
            ],
          }
    );
  };

  return (
    <Layout>
      <Header className="menu-mobil">
        <Row>
          <Col span={6}>
            {loading && (
              <Spin
                style={{ marginRight: 30, left: -7, top: -3 }}
                indicator={antIcon}
              >
                <Avatar
                  style={{
                    marginRight: 5,
                    width: '50px',
                    height: '50px',
                    marginTop: 25,
                    fontSize: 30,
                  }}
                  icon={<UserOutlined />}
                />{' '}
              </Spin>
            )}
            {logo ? (
              <Avatar
                style={{
                  marginRight: 5,
                  width: '50px',
                  height: '50px',
                  marginTop: 25,
                  fontSize: 30,
                }}
                src={`data:image/png;base64,${logo}`}
              />
            ) : (
              <Avatar
                style={{
                  marginRight: 5,
                  width: '50px',
                  height: '50px',
                  marginTop: 25,
                  fontSize: 30,
                }}
                icon={<UserOutlined />}
              />
            )}
          </Col>
          {session && session.userInfo && (
            <Col span={18} style={{ textAlign: 'left', color: 'white' }}>
              <span>{t('home.welcome')}</span>
              <Title style={{ position: 'relative', top: -20 }} level={4}>
                {session.userInfo.name}
              </Title>
            </Col>
          )}
        </Row>
        {!widthMenuMobile.options ? (
          <AlignLeftOutlined
            className="btnMenuMbole"
            style={{
              zIndex: 1,
              position: 'fixed',
              left: '330px',
              top: '30px',
            }}
            onClick={() => changeMenuMobile(false)}
          />
        ) : (
          <CloseOutlined
            className="btnMenuMbole"
            style={{
              zIndex: 1,
              position: 'fixed',
              left: '330px',
              top: '30px',
            }}
            onClick={() => changeMenuMobile(true)}
          />
        )}
        <div
          className={
            !widthMenuMobile.options
              ? 'menuLateralMobileHidden'
              : 'menuLateralMobile'
          }
        >
          <Headers widthMenu={widthMenuMobile} loggedIn />
          <MenuOptions changeMenu={changeMenu} widthMenu={widthMenuMobile} />
        </div>
      </Header>
      <Content>
        <Layout className="site-layout-background">
          <Sider
            className="menu-main"
            style={{
              transition: 'all 0.2s',
              width: widthMenu.width,
              posicion: 'absolute',
              height: '100vh',
            }}
            width={widthMenu.width}
          >
            {!widthMenu.options ? (
              <Button
                style={{
                  zIndex: 1,
                  transition: 'all 0.6s',
                  color: 'white',
                  backgroundColor: '#010c33',
                  height: '50px',
                  position: 'fixed',
                  left: '70px',
                  top: '70px',
                }}
                onClick={() => changeMenu(false)}
              >
                <RightOutlined style={{ marginLeft: '50%' }} />
              </Button>
            ) : (
              <Button
                style={{
                  zIndex: 1,
                  transition: 'all 0.5s',
                  color: 'white',
                  width: '100px',
                  backgroundColor: '#010c33',
                  height: '50px',
                  position: 'fixed',
                  left: '175px',
                  top: '70px',
                }}
                onClick={() => changeMenu(true)}
              >
                <LeftOutlined style={{ marginLeft: '90%' }} />
              </Button>
            )}
            <div
              className="menuLateral"
              style={{ width: widthMenu.width, transition: 'all 0.5s' }}
            >
              <Headers widthMenu={widthMenu} loggedIn />
              <MenuOptions changeMenu={changeMenu} widthMenu={widthMenu} />
            </div>
          </Sider>
          <Content style={{ background: '#F9F9F9' }}>
            <NavMenu sidebarWidth={widthMenu.width} />
            {route && (
              <Route path={path} exact={exact}>
                <Component />
              </Route>
            )}
          </Content>
        </Layout>
      </Content>
    </Layout>
  );
}

AuthenticatedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
  exact: PropTypes.bool,
};

AuthenticatedRoute.defaultProps = {
  exact: false,
};

export default AuthenticatedRoute;

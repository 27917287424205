import { Col, Form, Row, Select } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import Search from 'antd/lib/input/Search';
import partnerApi from '../../../api/partner';
import productApi from '../../../api/product';

function CatalogueFilter({
  setMarketplaces,
  filter,
  setFilter,
  setDefaultFilterValues,
  successUploadProductTemplate,
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const session = useSelector((store) => store.Session.session);
  const [filterMarketplaces, setFilterMarketplaces] = useState([]);

  const firstSetup = useRef(true);

  const [statusOptions, setStatusOptions] = useState();

  const groupedMarketplacesStatuses = [
    {
      label: 'Sin productos publicados',
      value: 'INACTIVE',
    },
    {
      label: 'Con productos publicados',
      value: 'ACTIVE',
    },
  ];

  const handleFilterChange = async (newValue, changedFilter) => {
    if (changedFilter === 'marketplace') {
      const newOptions =
        newValue === 'groupedMarketplaces'
          ? groupedMarketplacesStatuses
          : await productApi
              .getProductStatuses({ marketplace: newValue })
              .then(({ data }) =>
                data.map((value) => ({ value, label: value }))
              )
              .then((options) => {
                if (options.length > 1) {
                  options.unshift({ value: 'ALL', label: 'Todos' });
                }
                return options;
              });
      form.setFieldValue('status', newOptions);
      setStatusOptions(newOptions);
    }
    setFilter({
      ...filter,
      [changedFilter]: newValue,
      limit: 50,
      page: 1,
    });
  };

  const getMarketplacesList = async () => {
    await partnerApi
      .getMarketplacesAllowed(
        session?.userInfo?.commercial_partner_id[0],
        'Seller'
      )
      .then((result) => {
        setMarketplaces(result.data);
        const mkts = result?.data?.map((marketplace) => {
          if (marketplace === 'Edicommerce') {
            return {
              value: marketplace,
              label: 'Walmart DSV MX',
            };
          }
          return {
            value: marketplace,
            label: `${t(
              'dashboard.marketplaces.'.concat(marketplace.replace(' ', ''))
            )}`,
          };
        });
        mkts.push({
          value: 'groupedMarketplaces',
          label: `${t('products.fields.groupedMarketplaces')}`,
        });
        setFilterMarketplaces(mkts);
        if (mkts[0]?.value) {
          handleFilterChange(mkts[0]?.value, 'marketplace');
          form.setFieldValue('marketplace', mkts[0]?.value);
        }
        setDefaultFilterValues();
      })
      .catch(() => {
        setMarketplaces([]);
      });
  };

  useEffect(() => {
    if (!session || !firstSetup) {
      return;
    }
    getMarketplacesList();
    firstSetup.current = false;
  }, [session]);

  useEffect(() => {
    if (successUploadProductTemplate) {
      form.setFieldValue('marketplace', 'groupedMarketplaces');
      form.setFieldValue('status', [
        t('productTemplates.fields.withoutPublishedProducts'),
      ]);
      handleFilterChange('groupedMarketplaces', 'marketplace');
    }
  }, [successUploadProductTemplate]);

  return (
    <Form
      name="formulario"
      form={form}
      onFinish={() => {}}
      layout="vertical"
      className="text-align-left"
    >
      <Row gutter={24}>
        <Col xs={24} lg={6} md={12}>
          <Form.Item
            name="marketplace"
            label={t('products.fields.marketplace')}
          >
            <Select
              showSearch
              className="select-before"
              placeholder={t('products.fields.marketplacePlaceholder')}
              onChange={(newValue) => {
                handleFilterChange(newValue, 'marketplace');
              }}
              filterOption={(input, option) =>
                (option?.value ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {filterMarketplaces?.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={6} md={12}>
          <Form.Item name="title" label={t('products.fields.productName')}>
            <Search
              placeholder={t('products.fields.productNamePlaceholder')}
              allowClear
              onSearch={(value) => handleFilterChange(value, 'title')}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={6} md={12}>
          <Form.Item name="status" label={t('products.fields.status')}>
            <Select
              allowClear
              mode="multiple"
              className="select-before"
              placeholder={t('products.fields.statusPlaceholder')}
              onSelect={(newValue) => {
                if (
                  newValue === 'ALL' ||
                  form.getFieldValue('status').length ===
                    statusOptions.length - 1
                ) {
                  form.setFieldValue(
                    'status',
                    statusOptions.map((o) => o.value)
                  );
                }
                handleFilterChange(form.getFieldValue('status'), 'statuses');
              }}
              onDeselect={(newValue) => {
                const values = form.getFieldValue('status');
                if (newValue === 'ALL') {
                  form.setFieldValue('status', undefined);
                } else if (values.includes('ALL')) {
                  form.setFieldValue(
                    'status',
                    values.filter((v) => v !== 'ALL')
                  );
                } else if (values.length === 0) {
                  form.setFieldValue('status', undefined);
                }
                handleFilterChange(form.getFieldValue('status'), 'statuses');
              }}
              filterOption={(input, option) =>
                (option?.value ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {statusOptions?.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={6} md={12}>
          <Form.Item name="sku" label={t('products.fields.sku')}>
            <Search
              placeholder={t('products.fields.skuPlaceholder')}
              allowClear
              onSearch={(value) => handleFilterChange(value, 'sku')}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
CatalogueFilter.propTypes = {
  setMarketplaces: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    marketplace: PropTypes.string,
    status: PropTypes.string,
    fulfillmentType: PropTypes.string,
    sku: PropTypes.string,
    title: PropTypes.string,
    limit: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
  setFilter: PropTypes.func.isRequired,
  setDefaultFilterValues: PropTypes.instanceOf(Array).isRequired,
  successUploadProductTemplate: PropTypes.bool.isRequired,
};

export default CatalogueFilter;

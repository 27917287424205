import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import PropTypes from 'prop-types';

function FedexOwnerTab({
  loading,
  name,
  onFinish,
  refresh,
  courierCredentials,
}) {
  const [form] = useForm();

  useEffect(() => {
    form.resetFields();
  }, [refresh]);

  return (
    <Form
      form={form}
      name={`${name}-form`}
      layout="vertical"
      labelCol={{
        span: 8,
        offset: 0,
      }}
      wrapperCol={{
        span: 20,
        offset: 0,
      }}
      initialValues={{
        remember: true,
        fbm: true,
        fbo: false,
        accountNumber: courierCredentials
          ? courierCredentials.accountNumber
          : null,
      }}
      onFinish={onFinish}
      autoComplete="off"
      style={{
        marginBottom: 20,
      }}
    >
      <div style={{ marginTop: 12 }}>
        <span
          style={{
            paddingLeft: 25,
            maxWidth: '100%',
            textAlign: 'left',
            fontSize: '18px',
            marginBottom: 30,
          }}
        >
          Número de cuenta
        </span>
        <Form.Item
          name="accountNumber"
          labelAlign="left"
          wrapperCol={{
            span: 24,
          }}
          style={{
            fontSize: '18px',
            maxWidth: '100%',
            paddingLeft: 25,
            paddingRight: 25,
            marginBottom: 18,
          }}
          rules={[
            {
              required: true,
              message: 'Ingrese su número de cuenta de Fedex',
            },
            {
              required: true,
              min: 9,
              max: 9,
              message: 'El número de cuenta de Fedex debe ser de 9 dígitos.',
            },
          ]}
        >
          <Input
            size="large"
            style={{ borderRadius: 10, marginTop: 15 }}
            defaultValue={
              courierCredentials ? courierCredentials.accountNumber : null
            }
          />
        </Form.Item>
        <span
          style={{
            paddingLeft: 25,
            maxWidth: '100%',
            textAlign: 'left',
            fontSize: '18px',
            marginBottom: 30,
          }}
        >
          Api key
        </span>
        <Form.Item
          name="clientId"
          labelAlign="left"
          wrapperCol={{
            span: 24,
          }}
          style={{
            fontSize: '18px',
            maxWidth: '100%',
            paddingLeft: 25,
            paddingRight: 25,
            marginBottom: 18,
          }}
          rules={[
            {
              required: true,
              message: 'Ingrese su api key de Fedex',
            },
          ]}
        >
          <Input
            size="large"
            style={{ borderRadius: 10, marginTop: 6 }}
            placeholder={
              courierCredentials && courierCredentials.managedByLap === false
                ? '**************************'
                : null
            }
          />
        </Form.Item>

        <span
          style={{
            paddingLeft: 25,
            maxWidth: '100%',
            textAlign: 'left',
            fontSize: '18px',
            marginBottom: 30,
          }}
        >
          Secret key
        </span>
        <Form.Item
          name="clientSecret"
          labelAlign="left"
          wrapperCol={{
            span: 24,
          }}
          style={{
            fontSize: '18px',
            maxWidth: '100%',
            paddingLeft: 25,
            paddingRight: 25,
          }}
          rules={[
            {
              required: true,
              message: 'Ingrese su secret key de Fedex',
            },
          ]}
        >
          <Input
            size="large"
            style={{ borderRadius: 10, marginTop: 6 }}
            placeholder={
              courierCredentials && courierCredentials.managedByLap === false
                ? '**************************'
                : null
            }
          />
        </Form.Item>

        <div
          style={{
            fontSize: 17,
            marginTop: 30,
            textAlign: 'center',
          }}
        >
          <span style={{ color: '#969696' }}>¿Aún no tienes una cuenta?</span>
          <Button
            target="_blank"
            href="https://ayuda.lapmarketplace.com/hc/es-cl/articles/13063954938267"
            type="link"
            block
          >
            <span
              style={{
                color: '#63B9F0',
                fontSize: 17,
              }}
            >
              Ver el paso a paso
            </span>
          </Button>
        </div>
        <Form.Item
          wrapperCol={{
            span: 24,
          }}
          style={{
            marginTop: 40,
            textAlign: 'center',
          }}
        >
          <Button
            style={{
              height: '50px',
              width: '30%',
            }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {courierCredentials ? 'Actualizar' : 'Conectar'}
          </Button>
        </Form.Item>

        <Form.Item
          name="managedByLap"
          style={{ display: 'none' }}
          initialValue={false}
        />
      </div>
    </Form>
  );
}

export default FedexOwnerTab;

FedexOwnerTab.propTypes = {
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  courierCredentials: PropTypes.instanceOf(Object).isRequired,
};

import { ExclamationCircleOutlined } from '@ant-design/icons';
import accessKeysApi from '../../../api/aws-access-keys';
import openNotification from '../../../components/Toastr';
import { getErrorMessage } from '../../../api/api';
import * as Actions from '../../../redux/session/action';

export const deleteCredentials = async (
  session,
  credentialId,
  modal,
  dispatch
) => {
  try {
    await accessKeysApi.forgetCredentials(
      session?.userInfo?.commercial_partner_id[0],
      credentialId
    );
    openNotification({ status: true, content: 'Credenciales desconectadas' });
    dispatch(Actions.updateSellerAccountStatusSession(true));
  } catch (error) {
    openNotification({ status: false, content: getErrorMessage(error) });
  }
};

export const deleteAdsCredentials = async (
  session,
  market,
  modal,
  dispatch
) => {
  // Ads Switch is off
  try {
    await accessKeysApi.forgetAdsCredentials(
      session?.userInfo?.commercial_partner_id[0],
      market
    );
    openNotification({
      status: true,
      content: 'Credenciales de publicidad desconectadas',
    });
    dispatch(Actions.updateSellerAccountStatusSession(true));
  } catch (error) {
    openNotification({ status: false, content: getErrorMessage(error) });
  }
};

export const confirmToggleDeleteCredentials = async (
  session,
  marketplaceCredential,
  type,
  modal,
  dispatch
) => {
  if (type !== 'Advertising') {
    await deleteCredentials(session, marketplaceCredential.id, modal, dispatch);
  } else {
    await deleteAdsCredentials(
      session,
      marketplaceCredential.parentMarketplace?.name,
      modal,
      dispatch
    );
  }
};

export const confirmDeleteCredential = (
  session,
  marketplaceCredential,
  modal,
  type,
  dispatch
) => {
  const { country, parentMarketplace } = marketplaceCredential;

  let finalName = parentMarketplace?.name;
  if (parentMarketplace?.name === 'Edicommerce') {
    finalName = 'Walmart DSV MX';
  }
  modal.confirm({
    title: 'Confirmar',
    icon: <ExclamationCircleOutlined />,
    content: `¿Está seguro que quiere desconectar ${finalName} ${
      country?.country_code ?? ''
    }?`,
    okText: 'Desconectar',
    cancelText: 'Cancelar',
    onOk: () =>
      confirmToggleDeleteCredentials(
        session,
        marketplaceCredential,
        type,
        modal,
        dispatch
      ),
    okButtonProps: {
      style: { backgroundColor: '#F25154', color: 'white', border: 'none' },
    },
  });
};

import { Card, Spin, Col, Row } from 'antd';
import 'antd/dist/antd.min.css';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../order/order.css';

import { getErrorMessage } from '../../api/api';
import restockOrdersAPI from '../../api/restock-orders';
import openNotification from '../../components/Toastr';
import NotFound from '../NotFound';
import RestockSteps from './RestockSteps';

function NewRestock() {
  const { orderId } = useParams();
  const [orderDb, setOrderDb] = useState({});
  const [isOrderDbLoading, setIsOrderDbLoading] = useState(false);

  const getOrderFromDb = async () => {
    try {
      setIsOrderDbLoading(true);
      const { data } = await restockOrdersAPI.getContent(orderId);
      setOrderDb(data);
    } catch (error) {
      openNotification({
        status: false,
        content: `Error al obtener la orden: ${getErrorMessage(error)}`,
      });
    }
    setIsOrderDbLoading(false);
  };

  useEffect(() => {
    getOrderFromDb();
  }, []);

  if (isOrderDbLoading) {
    return <Spin />;
  }

  if (!isOrderDbLoading && !orderDb) {
    return <NotFound />;
  }

  return (
    <div id="outline-card" className="content-div">
      <Row className="home-listing-main-row listings-main">
        <Col xs={24} md={24} lg={24} xl={24} span={12}>
          <Card className="ventas-home-listing-card restock-card">
            <RestockSteps orderDb={orderDb} />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default NewRestock;

import { InfoCircleOutlined, PictureOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Col,
  Form,
  Image,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getLinkToStore from '../../../../../home/components/linkToStore';
import phones from '../../../../../../utils/json/phones.json';
import './ShipsmartFbmProductTable.css';

function ShipsmartFbmProductTable({ form, order, products }) {
  const { t } = useTranslation();

  const rowClassName = (record) => {
    if (record.type === 'product') {
      return 'product';
    }
    if (record.type === 'listing') {
      return 'listing';
    }
    return null;
  };

  useEffect(() => {
    products?.forEach((product) => {
      form.setFieldsValue({
        [`fob-${product.defaultCode}`]: product.productTemplate.unitFOB,
        [`harmonizedCode-${product.defaultCode}`]:
          product.productTemplate.harmonizedCode,
        [`countryOfManufacture-${product.defaultCode}`]:
          product.productTemplate.countryOfManufacture,
      });
    });
  }, [products]);

  const renderProduct = (value, record) => {
    const imgUrl =
      record?.mainImages?.length > 0 ? record?.mainImages[0]?.url : null;
    return record?.orderItems.map((item) => (
      <Row align="middle" justify="start">
        <Col
          span={24}
          style={{
            marginBottom: -10,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingBottom: 20,
            }}
          >
            <Space size="middle">
              {imgUrl ? (
                <Avatar
                  shape="square"
                  size="large"
                  src={<Image src={`${imgUrl}`} />}
                />
              ) : (
                <Avatar
                  shape="square"
                  size="large"
                  icon={<PictureOutlined />}
                />
              )}
              <Space direction="vertical" style={{ textAlign: 'left' }}>
                <p
                  style={{
                    marginBottom: -10,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {getLinkToStore[record?.marketplace](item) || 'Sin datos'}
                </p>
                <p style={{ marginBottom: -10, fontSize: '12px' }}>{`SKU: ${
                  item?.sku || 'Sin datos'
                } / Cantidad: ${item?.quantity || 'Sin datos'}`}</p>
                <p style={{ marginBottom: -10, fontSize: '12px' }}>{`ID: ${
                  item?.itemId || 'Sin datos'
                }`}</p>
              </Space>
            </Space>
          </div>
        </Col>
      </Row>
    ));
  };

  const renderUnitPrice = (value, record) => {
    return record?.orderItems.map((item) => (
      <Row align="middle" justify="center">
        $ {item.itemPrice?.amount} {item.itemPrice?.currency}
      </Row>
    ));
  };

  const renderCountryOfManufacture = (value, record) => {
    return record?.orderItems.map((item) => (
      <Form.Item
        label=""
        className="input-form-margin-bottom"
        name={`countryOfManufacture-${item.sku}`}
        rules={[
          {
            required: true,
            message: 'Requerido',
          },
        ]}
      >
        <Select
          showSearch
          filterOption={(input, option) =>
            option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {phones.map((phoneRow) => (
            <Select.Option key={phoneRow.name} value={phoneRow.code}>
              {phoneRow.flag} {phoneRow.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ));
  };

  const renderHarmonizedCode = (value, record) => {
    return record?.orderItems.map((item) => (
      <Form.Item
        label=""
        className="input-form-margin-bottom"
        name={`harmonizedCode-${item.sku}`}
        rules={[
          {
            required: true,
            message: 'Requerido',
          },
        ]}
      >
        <Input name={`harmonizedCode-${item.sku}`} min={7} />
      </Form.Item>
    ));
  };

  const renderFob = (value, record) => {
    return record?.orderItems.map((item) => (
      <Form.Item
        label=""
        className="input-form-margin-bottom"
        name={`fob-${item.sku}`}
        rules={[
          {
            required: true,
            message: 'Requerido',
          },
        ]}
      >
        <Input addonBefore="USD" name={`fob-${item.sku}`} min={0} />
      </Form.Item>
    ));
  };

  const columns = [
    {
      title: t('common.description'),
      dataIndex: 'name',
      key: 'product',
      render: renderProduct,
      width: `25%`,
      onFilter: (value, record) =>
        record.name.toLowerCase().startsWith(value.toLowerCase()),
      filterSearch: true,
    },
    {
      title: t('orders.shipment.products.unitPrice'),
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: renderUnitPrice,
      width: `${75 / 4}%`,
      align: 'center',
    },
    {
      title: (
        <Tooltip
          title={t('orders.shipment.products.countryOfManufactureTooltip')}
        >
          {t('orders.shipment.products.countryOfManufacture')}{' '}
          <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: 'countryOfManufacture',
      key: 'countryOfManufacture',
      render: renderCountryOfManufacture,
      width: `${75 / 4}%`,
    },
    {
      title: (
        <Tooltip
          title={
            <>
              {t('orders.shipment.products.harmonizedCodeTooltip')}{' '}
              <Link
                to={{
                  pathname:
                    'https://ayuda.lapmarketplace.com/hc/es-cl/articles/12743698364955',
                }}
                target="_blank"
              >
                {t('common.wantKnowMore')}
              </Link>
            </>
          }
        >
          {t('orders.shipment.products.harmonizedCode')} <InfoCircleOutlined />
        </Tooltip>
      ),
      key: 'harmonizedCode',
      dataIndex: 'harmonizedCode',
      render: renderHarmonizedCode,
      width: `${75 / 4}%`,
    },
    {
      title: (
        <Tooltip
          title={
            <>
              {t('orders.shipment.products.declaredFobTooltip')}{' '}
              <Link
                to={{
                  pathname:
                    'https://ayuda.lapmarketplace.com/hc/es-cl/articles/12321298472859-FOB-Declarado',
                }}
                target="_blank"
              >
                {t('common.wantKnowMore')}
              </Link>
            </>
          }
        >
          {t('orders.shipment.products.declaredFob')} <InfoCircleOutlined />
        </Tooltip>
      ),
      key: 'fob',
      dataIndex: 'fob',
      render: renderFob,
      width: `${75 / 4}%`,
    },
  ];

  return (
    <Space direction="vertical">
      {products && (
        <Table
          columns={columns}
          dataSource={[order]}
          rowClassName={rowClassName}
          pagination={false}
          sticky
        />
      )}
    </Space>
  );
}

export default ShipsmartFbmProductTable;

ShipsmartFbmProductTable.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  products: PropTypes.arrayOf(Object),
  form: PropTypes.instanceOf(Form).isRequired,
};

ShipsmartFbmProductTable.defaultProps = {
  products: [],
};

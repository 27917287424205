import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Menu,
  Avatar,
  Spin,
  Breadcrumb,
  Row,
  Tooltip,
  Typography,
  Select,
  Alert,
  Col,
  Space,
  Badge,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import {
  DownOutlined,
  LoadingOutlined,
  UserOutlined,
  InfoCircleOutlined,
  BellOutlined,
} from '@ant-design/icons';
import Joyride from 'react-joyride';
import IconEEUU from '../assets/banderas/eeuu.png';
import IconCanada from '../assets/banderas/canada.png';
import IconBrasil from '../assets/banderas/brasil.png';
import IconChile from '../assets/banderas/chile.png';
import IconMexico from '../assets/banderas/mexico.png';
import IconEuropa from '../assets/banderas/europa.png';
import authApi from '../api/auth';
import * as Actions from '../redux/session/action';
import actionsAnalitycs from '../redux/analytic/dashboard/actions';
import partnerApi from '../api/partner';
import { updateOnboarding } from '../redux/onboarding/action';
import { stage1Navbar, stage2Navbar } from '../stepsOnboarding';
import './navmenu.css';
import Notifications from './components/Notification';
import NotificationsApi from '../api/notifications';
// import selectors from '../redux/analytic/dashboard/selectors';
import openNotification from '../components/Toastr';
import { getErrorMessage } from '../api/api';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function NavMenu({ sidebarWidth }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const session = useSelector((store) => store.Session.session);
  const language = useSelector((store) => store.Session.language);
  const onTechPaymentProcessing =
    session?.userInfo?.subscriptions?.techSubscription?.onPaymentProcessing ??
    false;
  const onKamPaymentProcessing =
    session?.userInfo?.subscriptions?.kamSubscription?.onPaymentProcessing ??
    false;
  const onboardingStatus = useSelector((store) => store.Onboarding);
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currencySelected, setCurrencySelected] = useState('USD');
  const [showNotifications, setShowNotifications] = useState(false);
  const [listNotifications, setListNotifications] = useState([]);
  const location = useLocation();
  const searchParams = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );
  const linkToStep = searchParams?.get('step');
  const pathNames = location.pathname.split('/').filter((path) => path !== '');
  const topbarWidth = `calc(100% - ${sidebarWidth}px)`;
  const languagueOptions = [
    { value: 'es', label: 'ES' },
    { value: 'en', label: 'EN' },
  ];

  const handleLogout = async () => {
    authApi.logout();
    await dispatch(Actions.logout());
    window.location = '/login';
  };

  const changeCurrency = (currency) => {
    setCurrencySelected(currency);
    localStorage.setItem('defaultCurrency', currency);
    dispatch(actionsAnalitycs.defaultCurrency(currency));
    // window.location = window.location.href;
  };

  const getNotifications = async () => {
    await NotificationsApi.get()
      .then((response) => {
        setListNotifications(response?.data);
      })
      .catch((error) => {
        return error;
      });
  };

  const updateNotifications = async (id, data) => {
    await NotificationsApi.update(id, data)
      .then(() => {
        getNotifications();
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
        return error;
      });
  };

  const deleteNotifications = async (id) => {
    await NotificationsApi.delete(id)
      .then(() => {
        getNotifications();
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
        return error;
      });
  };

  useEffect(() => {
    getNotifications();
  }, [session, location]);

  useEffect(() => {
    const existDefaultCurrency = localStorage.getItem('defaultCurrency');
    if (!existDefaultCurrency) {
      changeCurrency('USD');
    } else {
      changeCurrency(existDefaultCurrency);
    }
    const getLogo = async () => {
      setLoading(true);
      try {
        const response = await partnerApi.findLogoById(
          session?.userInfo?.commercial_partner_id[0]
        );
        setLogo(response.data);
      } catch (error) {
        /* empty */
      }
      setLoading(false);
    };
    if (session && session?.userInfo && !logo) {
      getLogo();
    }
  }, []);

  const openNotifications = () => {
    setShowNotifications(!showNotifications);
    if (!showNotifications === false) {
      getNotifications();
    }
  };

  const flagsCurrency = [
    {
      name: 'USD',
      icon: IconEEUU,
      key: 'USD',
    },
    {
      name: 'MXN',
      icon: IconMexico,
      key: 'MXN',
    },
    {
      name: 'CLP',
      icon: IconChile,
      key: 'CLP',
    },
    {
      name: 'BRL',
      icon: IconBrasil,
      key: 'BRL',
    },
    {
      name: 'CAD',
      icon: IconCanada,
      key: 'CAD',
    },
    {
      name: 'EUR',
      icon: IconEuropa,
      key: 'EUR',
    },
  ];

  const pathBreadcrumbs = {
    couriers: t('navMenu.couriers'),
    bodegas: t('warehouses.title'),
    resumen: t('menu.summary'),
    ventas: t('menu.sales'),
    publicidad: t('menu.advertising'),
    calculadora: t('menu.calculator'),
    catalogo: t('menu.catalogue'),
    inventario: t('menu.inventory'),
    pedidos: t('menu.saleOrders'),
    envios: t('menu.shipments'),
    'asistente-regulaciones': t('menu.assistant'),
  };

  const dashboardChilds = ['resumen', 'ventas', 'publicidad'];

  const handleClickCourier = () => {
    if (onboardingStatus.state === 'Courier' && onboardingStatus.step === 1) {
      dispatch(updateOnboarding('Courier', 2));
    }
  };

  const handleClickMarketplace = () => {
    if (
      onboardingStatus.state === 'Marketplace' &&
      onboardingStatus.step === 1
    ) {
      dispatch(updateOnboarding('Marketplace', 2));
    }
  };

  const onOpenChange = () => {
    setTimeout(() => {
      if (
        onboardingStatus.state === 'Marketplace' &&
        onboardingStatus.step === 0
      ) {
        dispatch(updateOnboarding('Marketplace', 1));
      } else if (
        onboardingStatus.state === 'Courier' &&
        onboardingStatus.step === 0
      ) {
        dispatch(updateOnboarding('Courier', 1));
      }
    }, 100);
  };

  const handleSkipTourCallback = (data) => {
    const { action, lifecycle } = data;
    if (action === 'close' && lifecycle === 'complete') {
      dispatch(updateOnboarding(null, null));
    }
  };

  const handleLanguageChange = (value) => {
    i18next.changeLanguage(value);
    dispatch(Actions.updateLanguague(value));
  };

  return (
    <Row
      className="topmenuContainer"
      style={{ width: topbarWidth, transition: 'all 0.5s' }}
    >
      <Col span={24}>
        {onTechPaymentProcessing && (
          <Alert
            type="warning"
            message={t('subscriptions.paymentProcessing', {
              plan: t('plans.tech'),
            })}
            banner
            closable
          />
        )}
        {onKamPaymentProcessing && (
          <Alert
            type="info"
            message={t('subscriptions.paymentProcessing', {
              plan: t('plans.kam'),
            })}
            banner
            closable
          />
        )}
      </Col>
      <Breadcrumb separator=">">
        {location.pathname === '/' && (
          <>
            <Breadcrumb.Item>
              <a href="/">{t('menu.dashboards')}</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="/">{t('menu.summary')}</a>
              <Tooltip title={t('menu.tooltips.resumen')}>
                {' '}
                <InfoCircleOutlined />
              </Tooltip>
            </Breadcrumb.Item>
          </>
        )}
        {pathNames.map((item) => {
          return (
            <>
              {item === 'my-account' && linkToStep && linkToStep === '1' && (
                <Breadcrumb.Item>
                  <a href={`/${item}`}>{t('menu.myAccount')}</a>
                </Breadcrumb.Item>
              )}
              {item === 'my-account' && linkToStep && linkToStep === '2' && (
                <Breadcrumb.Item>
                  <a href={`/${item}`}>{t('menu.myMarketplaces')}</a>
                </Breadcrumb.Item>
              )}
              {item in pathBreadcrumbs && (
                <>
                  {dashboardChilds.includes(item) && (
                    <Breadcrumb.Item>
                      <a href="/">{t('menu.dashboards')}</a>
                    </Breadcrumb.Item>
                  )}
                  <Breadcrumb.Item>
                    <a href={`/${item}`}>{pathBreadcrumbs[item]}</a>
                    {t(`menu.tooltips.${item}`) && (
                      <Tooltip title={t(`menu.tooltips.${item}`)}>
                        {' '}
                        <InfoCircleOutlined />
                      </Tooltip>
                    )}
                  </Breadcrumb.Item>
                </>
              )}
            </>
          );
        })}
      </Breadcrumb>
      <Space direction="horizontal" size="large">
        <div className="filterCurrency">
          <span>{t('common.currency')}</span>
          <Select
            className="filterCurrency"
            maxTagCount="responsive"
            onChange={changeCurrency}
            defaultValue={`${
              localStorage.getItem('defaultCurrency')
                ? localStorage.getItem('defaultCurrency')
                : currencySelected
            }`}
          >
            {flagsCurrency.map((currency) => (
              <Select.Option key={currency.name} value={currency.name}>
                <img alt={`${currency.name}`} src={currency.icon} width={10} />{' '}
                {currency.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <Select
            value={language}
            onChange={handleLanguageChange}
            options={languagueOptions}
          />
        </div>
        <div>
          <div
            onClick={openNotifications}
            role="presentation"
            style={{ cursor: 'pointer' }}
          >
            <Badge
              count={listNotifications?.filter((item) => !item.read).length}
              overflowCount={99}
            >
              <BellOutlined style={{ fontSize: '20px' }} />
            </Badge>
          </div>
          <Notifications
            list={listNotifications}
            update={updateNotifications}
            deleteItem={deleteNotifications}
            open={showNotifications}
            setOpen={openNotifications}
          />
        </div>
        <Menu
          mode="horizontal"
          className="topmenu"
          triggerSubMenuAction="click"
          onOpenChange={onOpenChange}
        >
          <Menu.SubMenu
            className="menuAvatar step1Courier step1Marketplace"
            key="perfil"
            title={
              <>
                {loading && <Spin indicator={antIcon} />}
                {!loading && logo && (
                  <Avatar
                    className="avatar"
                    src={`data:image/png;base64,${logo}`}
                    alt={session?.userInfo?.commercial_partner_id[1]}
                  />
                )}
                {!loading && !logo && (
                  <Avatar className="avatar" icon={<UserOutlined />} />
                )}
                <Typography.Text
                  style={{ color: '#969696', fontSize: 12, marginLeft: 10 }}
                >
                  {session?.userInfo?.commercial_partner_id[1]} <DownOutlined />
                </Typography.Text>
              </>
            }
          >
            <Menu.Item className="dropdownTopMenu" key="/my-account">
              <Link to="/my-account?step=1">{t('navMenu.data')}</Link>
            </Menu.Item>
            <Menu.Item
              className="dropdownTopMenu"
              key="/my-account/marketplaces"
              onClick={handleClickMarketplace}
            >
              <Link to="/my-account?step=2" className="step2Marketplace">
                {t('navMenu.marketplaces')}
              </Link>
            </Menu.Item>
            <Menu.Item
              className="dropdownTopMenu"
              key="/couriers"
              onClick={handleClickCourier}
            >
              <Link to="/couriers" className="step2Courier">
                {t('navMenu.couriers')}
              </Link>
            </Menu.Item>
            <Menu.Item className="dropdownTopMenu" key="/bodegas">
              <Link to="/bodegas">{t('navMenu.warehouses')}</Link>
            </Menu.Item>
            {/* <Menu.Item className="dropdownTopMenu" key="/regulaciones">
            <Link to="/regulaciones">{t('navMenu.regulation')}</Link>
          </Menu.Item> */}
            <Menu.Item className="dropdownTopMenu" key="/planes">
              <Link to="/planes">{t('navMenu.subscriptions')}</Link>
            </Menu.Item>
            <Menu.Item
              className="dropdownTopMenu"
              onClick={handleLogout}
              key=""
            >
              <Link to="/login">{t('navMenu.logout')}</Link>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </Space>
      {onboardingStatus.state === 'Marketplace' &&
        onboardingStatus.step === 1 && (
          <Joyride
            steps={stage1Navbar}
            callback={handleSkipTourCallback}
            disableCloseOnEsc
            spotlightClicks
            disableScrolling
          />
        )}
      {onboardingStatus.state === 'Courier' && onboardingStatus.step === 1 && (
        <Joyride
          steps={stage2Navbar}
          callback={handleSkipTourCallback}
          disableCloseOnEsc
          spotlightClicks
          disableScrolling
        />
      )}
    </Row>
  );
}

NavMenu.propTypes = {
  sidebarWidth: PropTypes.number.isRequired,
};

export default NavMenu;

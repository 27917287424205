import PropTypes from 'prop-types';
import CaseMixedTable from './CaseMixedTable';
import CasePackedTable from './CasePackedTable';

function ProductAssignmentTable({
  shipmentId,
  order,
  items,
  boxes,
  onFinishProductAssignment,
  setBoxes,
  unit,
  pallets,
  setPallets,
  formRefs,
  saveDraft,
  index,
  convertWeightUnit,
}) {
  return (
    <>
      {order.isPack && pallets && (
        <CasePackedTable
          order={order}
          items={items}
          boxes={boxes}
          setBoxes={setBoxes}
          shipmentId={shipmentId}
          unit={unit}
          pallets={pallets}
          setPallets={setPallets}
          formRefs={formRefs}
          shipmentIndex={index}
          convertWeightUnit={convertWeightUnit}
        />
      )}
      {!order.isPack && pallets && (
        <CaseMixedTable
          order={order}
          items={items}
          boxes={boxes}
          setBoxes={setBoxes}
          onFinishProductAssignment={onFinishProductAssignment}
          unit={unit}
          shipmentId={shipmentId}
          pallets={pallets}
          setPallets={setPallets}
          formRefs={formRefs}
          saveDraft={saveDraft}
          shipmentIndex={index}
          convertWeightUnit={convertWeightUnit}
        />
      )}
    </>
  );
}

ProductAssignmentTable.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  order: PropTypes.shape({
    boxes: PropTypes.instanceOf(Object),
    isPalletized: PropTypes.bool.isRequired,
    unity: PropTypes.string.isRequired,
    products: PropTypes.instanceOf(Object),
    isPack: PropTypes.bool,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      FulfillmentNetworkSKU: PropTypes.string,
      Quantity: PropTypes.number,
      SellerSKU: PropTypes.string,
    })
  ).isRequired,
  boxes: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      weight: PropTypes.string,
    })
  ).isRequired,
  onFinishProductAssignment: PropTypes.func,
  setBoxes: PropTypes.func.isRequired,
  unit: PropTypes.string.isRequired,
  pallets: PropTypes.arrayOf(
    PropTypes.shape({
      pallets: PropTypes.arrayOf({
        length: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        weight: PropTypes.number,
      }),
      shipmentId: PropTypes.string,
    })
  ).isRequired,
  setPallets: PropTypes.func.isRequired,
  formRefs: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  saveDraft: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  convertWeightUnit: PropTypes.func.isRequired,
};

ProductAssignmentTable.defaultProps = {
  onFinishProductAssignment: () => {},
};
export default ProductAssignmentTable;

import PropTypes from 'prop-types';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Tag } from 'antd';
import '../shipments.css';

function ShipmentTypeTag({ text, icon }) {
  return (
    <div>
      <Tag className="shipment-type-tag" icon={icon}>
        {text.toUpperCase()}
      </Tag>
    </div>
  );
}

ShipmentTypeTag.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.instanceOf(Icon).isRequired,
};

export default ShipmentTypeTag;

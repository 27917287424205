import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { Col, DatePicker, Row, Select } from 'antd';

import * as queryString from 'query-string';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../shipments.css';

function ShipmentsTableFilters({
  shipmentType,
  startDate,
  endDate,
  status,
  callback,
}) {
  const { t } = useTranslation();
  const locale = useSelector((store) => store.Session.language);
  const history = useHistory();
  const location = useLocation();
  const [dateRange, setDateRange] = useState([
    moment(startDate),
    moment(endDate),
  ]);
  const [type, setType] = useState(shipmentType);
  const [statuses, setStatuses] = useState(status);

  const onShipmentTypeChange = async (value) => {
    await setType(value);
  };

  const updateQueryParams = async () => {
    const start = dateRange[0].toISOString().substring(0, 10);
    const end = dateRange[1].toISOString().substring(0, 10);
    await history.push({
      pathname: location.pathname,
      search: `?startDate=${start}&endDate=${end}&type=${type}&statuses=${statuses}`,
    });
    const qs = queryString.parse(window.location.search);
    await callback(qs);
  };

  const onDateRangeChange = (dates) => {
    setDateRange([dates[0], dates[1]]);
  };

  const onStatusChange = (values) => {
    setStatuses(values.join(','));
  };

  const statusOptions = [
    { label: t('shipments.status.All'), value: 'ALL' },
    { label: t('shipments.status.Draft'), value: 'Draft' },
    { label: t('shipments.status.Working'), value: 'Working' },
    { label: t('shipments.status.ReadyToShip'), value: 'ReadyToShip' },
    { label: t('shipments.status.Delivered'), value: 'Delivered' },
    { label: t('shipments.status.CheckedIn'), value: 'CheckedIn' },
    { label: t('shipments.status.Receiving'), value: 'Receiving' },
    { label: t('shipments.status.Closed'), value: 'Closed' },
    { label: t('shipments.status.Error'), value: 'Error' },
    { label: t('shipments.status.Cancelled'), value: 'Cancelled' },
    { label: t('shipments.status.Deleted'), value: 'Deleted' },
    { label: t('shipments.status.InTransit'), value: 'InTransit' },
    { label: t('shipments.status.Shipped'), value: 'Shipped' },
  ];

  useEffect(() => {
    updateQueryParams();
  }, [dateRange, type, statuses]);
  return (
    <Row style={{ marginTop: 2 }}>
      <Col span={8}>
        <div className="filter-shipment-title">
          {t('shipments.filter.period')}
        </div>
        <DatePicker.RangePicker
          style={{ width: '80%' }}
          locale={locale}
          defaultValue={dateRange}
          defaultPickerValue={[moment(), moment().subtract(30, 'days')]}
          ranges={{
            [t('shipments.filter.periodRanges.today')]: [moment(), moment()],
            [t('shipments.filter.periodRanges.thisWeek')]: [
              moment().startOf('week'),
              moment().endOf('week'),
            ],
            [t('shipments.filter.periodRanges.past7Days')]: [
              moment().subtract(7, 'days'),
              moment(),
            ],
            [t('shipments.filter.periodRanges.thisMonth')]: [
              moment().startOf('month'),
              moment().endOf('month'),
            ],
            [t('shipments.filter.periodRanges.lastMonth')]: [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            [t('shipments.filter.periodRanges.past30Days')]: [
              moment().subtract(30, 'days'),
              moment(),
            ],
            [t('shipments.filter.periodRanges.thisYear')]: [
              moment().startOf('year'),
              moment(),
            ],
            [t('shipments.filter.periodRanges.past12Months')]: [
              moment().subtract(12, 'months'),
              moment(),
            ],
          }}
          onChange={onDateRangeChange}
        />
      </Col>

      <Col span={8}>
        <div className="filter-shipment-title">
          {t('shipments.filter.type')}
        </div>
        <Select
          style={{ width: '80%' }}
          onChange={onShipmentTypeChange}
          defaultValue={type}
        >
          <Select.Option value="ALL"> {t('shipments.types.all')}</Select.Option>
          <Select.Option value="RestockOrder">
            {t('shipments.types.restock')}
          </Select.Option>
          <Select.Option value="SaleOrder">
            {t('shipments.types.saleOrder')}
          </Select.Option>
          <Select.Option value="ThirdPartyShipment">
            {t('shipments.types.thirdPartyShipments')}
          </Select.Option>
        </Select>
      </Col>

      <Col span={8}>
        <div className="filter-shipment-title">
          {t('shipments.filter.status')}
        </div>
        <Select
          style={{ width: '80%' }}
          mode="multiple"
          defaultValue={status.split(',')}
          onChange={onStatusChange}
          options={statusOptions}
        />
      </Col>
    </Row>
  );
}

ShipmentsTableFilters.propTypes = {
  shipmentType: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  status: PropTypes.string,
  callback: PropTypes.func.isRequired,
};

ShipmentsTableFilters.defaultProps = {
  shipmentType: 'ALL',
  startDate: moment().subtract(30, 'days').toISOString().substring(0, 10),
  endDate: moment().toISOString().substring(0, 10),
  status: 'ALL',
};

export default ShipmentsTableFilters;

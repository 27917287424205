import { Card, Col, Collapse, Row, Spin, Typography } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import KamPlanCard from './components/KamPlanCard';
import TechPlanCard from './components/TechPlanCard';
import SubscriptionAPI from '../../api/subscription';
import planApi from '../../api/plan';
import './plans.css';
import openNotification from '../../components/Toastr';
import { getErrorMessage } from '../../api/api';
import CurrentSubscription from './components/CurrentSubscription';
import * as Actions from '../../redux/session/action';
import Consume from './components/Consume';
import InactiveBanner from './components/InactiveBannerr';
import identifyCustomer from '../../utils/customerIoUtils';

const { Title, Text } = Typography;

function Plans({ loggedIn }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [dataTechPlans, setDataTechPlans] = useState([]);
  const [dataKamPlans, setDataKamPlans] = useState([]);

  const [dataTechPlansLoading, setDataTechPlansLoading] = useState(false);
  const [dataKamPlansLoading, setDataKamPlansLoading] = useState(false);

  const [cancellingTech, setCancellingTech] = useState(false);
  const [cancellingKam, setCancellingKam] = useState(false);

  const session = useSelector((store) => store.Session.session);
  const techSubscription =
    session?.userInfo?.subscriptions?.techSubscription ?? null;
  const kamSubscription =
    session?.userInfo?.subscriptions?.kamSubscription ?? null;

  const getTechPlans = async () => {
    setDataTechPlansLoading(true);
    try {
      const type = 'TECH';
      const active = true;
      const response = await planApi.list(type, active);
      if (response.data) {
        setDataTechPlans(response.data);
      }
      setDataTechPlansLoading(false);
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
  };

  const getKamPlans = async () => {
    setDataKamPlansLoading(true);
    try {
      const type = 'KAM';
      const active = true;
      const response = await planApi.list(type, active);
      if (response.data) {
        setDataKamPlans(response.data);
      }
      setDataKamPlansLoading(false);
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
  };

  useEffect(() => {
    const getPlans = async () => {
      await getTechPlans();
      await getKamPlans();
    };
    getPlans();
  }, []);

  const cancelSubscription = async (subscription) => {
    if (subscription.type !== 'KAM') setCancellingTech(true);
    if (kamSubscription?.active) setCancellingKam(true);
    const response = await SubscriptionAPI.cancel(subscription.id);
    if (response.status === 200) {
      dispatch(Actions.cancelSubscription(subscription.type));
      if (subscription.type === 'TECH') setCancellingTech(false);
      if (subscription.type === 'KAM') setCancellingKam(false);
      identifyCustomer(window, session.userInfo, {
        cancelled_by_user_at: Math.floor(Date.now() / 1000),
        plan_name: subscription.name,
      });
      if (subscription.type !== 'KAM' && kamSubscription?.active) {
        await cancelSubscription(kamSubscription);
      }
    }
  };

  const notLoggedInExtraClass = !loggedIn ? 'not-logged-in-plans' : '';

  return (
    <div className="content-div-plans">
      <Row>
        <Col span={24}>
          <div className={`plans ${notLoggedInExtraClass}`}>
            {session && !session.companyActive && <InactiveBanner />}
            {techSubscription && !techSubscription?.isExpired && (
              <>
                <Consume />
                <Card className="plans-section">
                  <Collapse defaultActiveKey={['1']} ghost>
                    <CollapsePanel
                      header={
                        <Title className="collapse-header-title" level={3}>
                          {t('plans.subscriptionDetails')}
                        </Title>
                      }
                    >
                      <CurrentSubscription
                        subscription={techSubscription}
                        cancelling={cancellingTech}
                        cancelSubscription={cancelSubscription}
                      />
                      {kamSubscription && !kamSubscription?.isExpired && (
                        <CurrentSubscription
                          subscription={kamSubscription}
                          cancelling={cancellingKam}
                          cancelSubscription={cancelSubscription}
                        />
                      )}
                    </CollapsePanel>
                  </Collapse>
                </Card>
              </>
            )}
            <Card
              className="plans-section"
              cover={
                (!techSubscription || techSubscription?.isExpired) && (
                  <div className="plans-banner">
                    <p className="plans-banner-text">
                      {t('plans.header.0')}&nbsp;
                      <Text className="bolder">{t('plans.header.1')}</Text>
                    </p>
                  </div>
                )
              }
            >
              {dataKamPlansLoading || dataTechPlansLoading ? (
                <Card>
                  <center>
                    <Spin size="large" />
                  </center>
                </Card>
              ) : (
                <>
                  {dataTechPlans.length > 0 && (
                    <>
                      <div className="plan-description">
                        <Title level={3} className="plan-description-title">
                          {t('plans.techPlans')}
                        </Title>
                        <span className="plan-description-body">
                          {t('plans.techPlansDescription')}
                        </span>
                        <p className="plans-price-detail">
                          {t('plans.priceDetailsMessage')}
                        </p>
                      </div>
                      <Row
                        justify="center"
                        align="middle"
                        className="plans-cards-row"
                      >
                        {dataTechPlans.map((techPlan) => (
                          <Col>
                            <TechPlanCard
                              plan={techPlan}
                              loader={dataTechPlansLoading}
                              loggedIn={loggedIn}
                              subscriptionPlanId={
                                techSubscription && !techSubscription?.isExpired
                                  ? techSubscription.planId
                                  : null
                              }
                              subscriptionId={
                                techSubscription?.active &&
                                !techSubscription?.isExpired
                                  ? techSubscription.id
                                  : null
                              }
                            />
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                  {dataKamPlans.length > 0 && (
                    <>
                      <div className="plan-description">
                        <Title level={3} className="plan-description-title">
                          {t('plans.kamPlans')}
                        </Title>
                        <span className="plan-description-body">
                          {t('plans.kamPlansDescription')}
                        </span>
                        <p className="plans-price-detail">
                          {t('plans.priceDetailsMessage')}
                        </p>
                      </div>
                      <Row
                        justify="center"
                        align="bottom"
                        className="plans-cards-row"
                      >
                        {dataKamPlans.map((kamPlan) => (
                          <Col>
                            <KamPlanCard
                              plan={kamPlan}
                              loader={dataKamPlansLoading}
                              loggedIn={loggedIn}
                              subscriptionPlanId={
                                kamSubscription && !kamSubscription?.isExpired
                                  ? kamSubscription.planId
                                  : null
                              }
                              subscriptionId={
                                kamSubscription?.active &&
                                !kamSubscription?.isExpired
                                  ? kamSubscription.id
                                  : null
                              }
                              hasTech={
                                techSubscription && !techSubscription?.isExpired
                              }
                            />
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                </>
              )}
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

Plans.propTypes = {
  loggedIn: PropTypes.bool,
};

Plans.defaultProps = {
  loggedIn: true,
};

export default Plans;

import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function PriorNotifyForm({ loading, name, onFinish, regulationsCredentials }) {
  const { t } = useTranslation();
  const [form] = useForm();
  const [credentials, setCredentials] = useState(undefined);

  useEffect(() => {
    if (regulationsCredentials && regulationsCredentials.credentials) {
      setCredentials({
        api_key: regulationsCredentials.credentials.api_key,
      });
      form.setFieldsValue({
        api_key: credentials.api_key,
      });
    }
  }, [regulationsCredentials]);

  return (
    <Form
      form={form}
      name={`${name}-form`}
      layout="vertical"
      labelCol={{
        span: 8,
        offset: 0,
      }}
      wrapperCol={{
        span: 20,
        offset: 0,
      }}
      initialValues={{
        remember: true,
        fbm: true,
        fbo: false,
      }}
      onFinish={onFinish}
      autoComplete="off"
      style={{
        marginBottom: 20,
      }}
    >
      <h1
        style={{
          fontWeight: 600,
          marginBottom: 40,
          marginTop: 15,
        }}
      >
        {t('regulations.priorNotify.connectAccount')}
      </h1>
      <span
        style={{
          paddingLeft: 25,
          maxWidth: '100%',
          textAlign: 'left',
          fontSize: '18px',
          marginBottom: 30,
        }}
      >
        API Key
      </span>
      <Form.Item
        name="api_key"
        labelAlign="left"
        wrapperCol={{
          span: 24,
        }}
        style={{
          fontSize: '18px',
          maxWidth: '100%',
          paddingLeft: 25,
          paddingRight: 25,
          marginBottom: 18,
        }}
        rules={[
          {
            required: true,
            message: t('regulations.priorNotify.requiredApiKey'),
          },
        ]}
      >
        <Input
          defaultValue={credentials ? credentials.client_id : ''}
          onChange={(e) => form.setFieldValue('client_id', e.target.value)}
          size="large"
          style={{ borderRadius: 10, marginTop: 6 }}
        />
      </Form.Item>

      {/* <div
        style={{
          fontSize: 17,
          marginTop: 30,
          textAlign: 'center',
        }}
      >
        <span style={{ color: '#969696' }}>¿Aún no tienes una cuenta?</span>
        <Button
          target="_blank"
          href="https://ayuda.lapmarketplace.com/hc/es-cl/articles/14265207898011"
          type="link"
          block
        >
          <span
            style={{
              color: '#63B9F0',
              fontSize: 17,
            }}
          >
            Ver el paso a paso
          </span>
        </Button>
      </div> */}

      <Form.Item
        wrapperCol={{
          span: 24,
        }}
        style={{
          marginTop: 35,
          textAlign: 'center',
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{
            height: '50px',
            width: '30%',
          }}
        >
          {regulationsCredentials ? t('common.update') : t('common.connect')}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default PriorNotifyForm;

PriorNotifyForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  regulationsCredentials: PropTypes.instanceOf(Object).isRequired,
};

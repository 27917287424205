import { DualAxes } from '@ant-design/plots';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import React from 'react';
import { thousandsSeparator } from '../../../utils/functions';
import selectors from '../../../redux/analytic/dashboard/selectors';

function DualAxesChart({ dataSource }) {
  const columnData = dataSource?.columnDataSource || [];
  const lineData = dataSource?.lineDataSource || [];
  const currencyState = useSelector(selectors.selectCurrency);

  const config = {
    data: [columnData, lineData],
    xField: 'month',
    yField: ['value', 'value'],
    yAxis: {
      value: {
        label: null,
        grid: null,
      },
    },
    xAxis: {
      label: {
        formatter: (month) => {
          moment.locale('es');
          if (month?.length === 7) {
            return moment(month).format('MMM');
          }
          return moment(month).format('D MMM');
        },
      },
    },
    legend: {
      layout: 'horizontal',
      position: 'top-right',
      selected: {
        Inversión: true,
        'Ventas orgánicas': true,
        'Ventas por publicidad': true,
        TACOS: false,
        'Ads %': false,
        'Ventas totales': false,
      },
    },
    geometryOptions: [
      {
        geometry: 'column',
        isGroup: true,
        isStack: true,
        seriesField: 'type',
        groupField: 'name',
        marginRatio: 0,
        color: ['#C4C4C433', '#00E5A6', '#D9D9D9', '#010C33'],
      },
      {
        geometry: 'line',
        seriesField: 'name',
        isStack: true,
        color: ['#969696', '#85B6FF80'],
      },
    ],
    tooltip: {
      customItems: (originalItems) => {
        return originalItems.map((tooltipRow) => {
          let value = 0;
          if (
            tooltipRow.name.includes('Inversión') ||
            tooltipRow.name.includes('Ventas')
          ) {
            value = `${thousandsSeparator(
              parseInt(tooltipRow.data.value, 10)
            )} ${currencyState}`;
          } else {
            value = `${thousandsSeparator(
              parseInt(tooltipRow.data.value, 10)
            )}%`;
          }
          return { ...tooltipRow, value };
        });
      },
      title: 'dateLabel',
    },
  };

  return (
    <DualAxes
      data={config.data}
      xField={config.xField}
      yField={config.yField}
      yAxis={config.yAxis}
      xAxis={config.xAxis}
      legend={config.legend}
      geometryOptions={config.geometryOptions}
      tooltip={config.tooltip}
    />
  );
}

export default React.memo(DualAxesChart);

DualAxesChart.propTypes = {
  dataSource: PropTypes.instanceOf(Object).isRequired,
};

import { Col, Row, Card, Typography, Radio, Form, Button, Select } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LegalEntityMx from './components/MX/LegalEntityMx';
import './requestAccount.css';
import NaturalPersonMx from './components/MX/NaturalPersonMx';
import AccountCL from './components/CL/AccountCL';
import AccountCO from './components/CO/AccountCO';
import CouriersApi from '../../../../../api/courier';
import openNotification from '../../../../../components/Toastr';
import { getErrorMessage } from '../../../../../api/api';
import ChileFlag from '../../../../../assets/banderas/chile.png';
import MexicoFlag from '../../../../../assets/banderas/mexico.png';
import ColombiaFlag from '../../../../../assets/banderas/colombia.png';

function RequestAccount() {
  const [sent, setSent] = useState(false);
  const [isLegalEntity, setIsLegalEntity] = useState();
  const [radioDefault, setRadioDefault] = useState(false);
  const { t } = useTranslation();
  const { Item } = Form;
  const { Option } = Select;
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const inputRadioActive = {
    border: '1px solid rgba(0, 229, 166, 1)',
  };

  const handleEnviar = async (formData) => {
    try {
      const response = await CouriersApi.requestAccount(formData)
        .then(() => {
          setSent(true);
        })
        .catch((error) => {
          openNotification({ status: false, content: getErrorMessage(error) });
          return error;
        });
      return response.data;
    } catch (error) {
      return `Error en la solicitud:, ${error}`;
    }
  };

  const handleEntendido = async () => {
    window.location = '/couriers';
  };

  return sent === false ? (
    <div className="initialContent">
      <Card>
        <p style={{ fontWeight: 500, fontSize: 24 }}>
          {t('requestAccount.contents.title')}
        </p>
        <Row gutter={[16, 16]}>
          <Col>
            <Typography.Text className="request-step-title">
              {t('requestAccount.contents.titleStep1')}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Select
              placeholder="Selecciona tu país"
              onChange={handleCountryChange}
              value={selectedCountry}
              style={{ width: '300px' }}
            >
              <Option value="chile">
                <img
                  src={ChileFlag}
                  alt="Chile"
                  style={{ marginRight: '8px', width: '24px' }}
                />
                Chile
              </Option>
              <Option value="mexico">
                <img
                  src={MexicoFlag}
                  alt="Mexico"
                  style={{ marginRight: '8px', width: '24px' }}
                />
                México
              </Option>
              <Option value="colombia">
                <img
                  src={ColombiaFlag}
                  alt="Colombia"
                  style={{ marginRight: '8px', width: '24px' }}
                />
                Colombia
              </Option>
            </Select>
          </Col>
        </Row>
        {selectedCountry === 'mexico' && (
          <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
            <Col>
              <Typography.Text className="request-step-title">
                {t('requestAccount.contents.titleStep2')}
              </Typography.Text>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Item
                noStyle
                className="input-form-margin-bottom"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <Radio.Group
                  className="cardRadioGroupContent"
                  name="isLegalEntity"
                  onChange={(e) => {
                    setRadioDefault(true);
                    setIsLegalEntity(e.target.value);
                  }}
                >
                  <Card
                    className="card-radio-inline cardBaseStyle"
                    style={
                      isLegalEntity && radioDefault ? inputRadioActive : {}
                    }
                    bodyStyle={{ height: '100%', width: '100%' }}
                  >
                    <Row
                      justify="space-between"
                      align="middle"
                      style={{ height: '100%' }}
                    >
                      <Col
                        style={{
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                        span={3}
                      >
                        <Radio value />
                      </Col>
                      <Col span={20} className="card-content-div">
                        <Row>
                          <Col span={18}>
                            <Typography.Title level={5}>
                              {t('requestAccount.contents.legalEntity')}
                            </Typography.Title>
                          </Col>
                        </Row>
                        <Typography.Text>
                          {t('requestAccount.contents.legalEntityDescription')}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    className="card-radio-inline cardBaseStyle"
                    style={
                      !isLegalEntity && radioDefault ? inputRadioActive : {}
                    }
                    bodyStyle={{ height: '100%' }}
                  >
                    <Row
                      justify="space-between"
                      align="middle"
                      style={{ height: '100%' }}
                    >
                      <Col
                        style={{
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                        span={3}
                      >
                        <Radio value={false} />
                      </Col>
                      <Col span={20} className="card-content-div">
                        <Typography.Title level={5}>
                          {t('requestAccount.contents.naturalPerson')}
                        </Typography.Title>
                        <Typography.Text>
                          {t(
                            'requestAccount.contents.naturalPersonDescription'
                          )}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Card>
                </Radio.Group>
              </Item>
            </Col>
          </Row>
        )}
        {isLegalEntity != null && selectedCountry === 'mexico' && (
          <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
            <Col>
              <Typography.Text className="request-step-title">
                {t('requestAccount.contents.titleStep3')}
              </Typography.Text>
            </Col>
            {isLegalEntity === true && (
              <Col>
                <LegalEntityMx sendDocs={handleEnviar} />
              </Col>
            )}
            {isLegalEntity === false && (
              <Col>
                <NaturalPersonMx sendDocs={handleEnviar} />
              </Col>
            )}
          </Row>
        )}
        {selectedCountry === 'chile' && (
          <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
            <Col>
              <Typography.Text className="request-step-title">
                {t('requestAccount.contents.titleStep3')}
              </Typography.Text>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <AccountCL sendDocs={handleEnviar} />
            </Col>
          </Row>
        )}
        {selectedCountry === 'colombia' && (
          <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
            <Col>
              <Typography.Text className="request-step-title">
                {t('requestAccount.contents.titleStep3')}
              </Typography.Text>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <AccountCO sendDocs={handleEnviar} />
            </Col>
          </Row>
        )}
      </Card>
    </div>
  ) : (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Col span={10} style={{ textAlign: 'center' }}>
        <CheckCircleFilled style={{ fontSize: '48px', color: 'green' }} />
        <p style={{ fontWeight: 500, fontSize: 24, marginTop: '16px' }}>
          {t('requestAccount.success.message1')}
        </p>
        <p style={{ fontWeight: 400, fontSize: 18 }}>
          {t('requestAccount.success.message2')}
        </p>
        <p style={{ fontWeight: 400, fontSize: 18 }}>
          {t('requestAccount.success.message3')}
        </p>
        <Button
          className="btn-link-filled"
          onClick={handleEntendido}
          style={{
            borderRadius: '4px',
            padding: '8px 16px 32px',
            cursor: 'pointer',
            display: 'inline-block',
          }}
        >
          {t('plans.subscriptionConfirmationModal.okButton')}
        </Button>
      </Col>
    </Row>
  );
}

export default RequestAccount;

import api from './api';

const prefix = 'subscription';
const SubscriptionAPI = {
  create(payload) {
    return api.post(prefix, payload);
  },
  get(subscriptionId) {
    return api.get(`${prefix}/${subscriptionId}`);
  },
  getMetadata(subscriptionId) {
    return api.get(`${prefix}/${subscriptionId}/metadata`);
  },
  cancel(subscriptionId) {
    return api.put(`${prefix}/${subscriptionId}/cancel`);
  },
  importFromStripeCheckoutSession(payload) {
    return api.post('/stripe/subscriptions/import', payload);
  },
};
export default SubscriptionAPI;

import {
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import Link from 'antd/lib/typography/Link';
import openNotification from '../../../components/Toastr';
import { currencies } from '../../../utils/currencies';
import phones from '../../../utils/json/phones.json';
import UploadImageButton from '../../../components/UploadImages';
import productTemplateApi from '../../../api/product-template';
import { translateErrors } from '../../../utils/functions';

function UploadProductModal({
  isUploadProductModalOpen,
  setIsUploadProductModalOpen,
  onSuccessUploadProduct,
}) {
  const [modalLoading, setIsModalLoading] = useState(false);
  const [form] = Form.useForm();
  const [currentImgValues, setCurrentImgValues] = useState({});

  const { t } = useTranslation();
  const uploadProductTemplateErrors = {
    'A product template with the provided default code already exists for this company.':
      {
        text: 'catalogue.uploadProductModal.defaultCodeError',
      },
    'Bad Request Exception': {
      text: 'catalogue.uploadProductModal.badRequestException',
    },
    default: {
      text: 'catalogue.uploadProductModal.defaultError',
    },
  };

  const handleModalOk = () => {
    form.submit();
  };

  const handleModalCancel = () => {
    form.resetFields();
    setIsUploadProductModalOpen(false);
  };

  const onSubmit = async (data) => {
    setIsModalLoading(true);
    const payload = data;
    ['weight', 'length', 'width', 'height', 'price', 'unitFOB'].forEach(
      (value) => {
        payload[value] = parseFloat(data[value]);
      }
    );
    payload.parentSku = data.defaultCode;
    payload.productIdentifier = data.defaultCode;
    payload.attributes = { brand: data.brand };
    try {
      await productTemplateApi.uploadProductTemplate(payload);

      await onSuccessUploadProduct();
      openNotification({
        status: true,
        content: t('catalogue.uploadProductModal.success'),
      });
      form.resetFields();
      setIsUploadProductModalOpen(false);
    } catch (error) {
      const translatePath = translateErrors(error, uploadProductTemplateErrors);
      openNotification({ status: false, content: t(translatePath.error) });
    } finally {
      setIsModalLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue(currentImgValues);
  }, [currentImgValues]);

  const labelTooltip = (label, description) => (
    <Space>
      <Col>
        <Typography.Text>{label}</Typography.Text>
      </Col>
      <Col>
        <Tooltip title={description}>
          <InfoCircleOutlined />
        </Tooltip>
      </Col>
    </Space>
  );

  const pricePrefixSelector = (
    <Form.Item name="currency" initialValue="USD" noStyle>
      <Select style={{ width: 70 }} showSearch>
        {Object.keys(currencies).map((currency) => (
          <Select.Option value={currency} />
        ))}
      </Select>
    </Form.Item>
  );

  const lengthPrefixSelector = (
    <Form.Item name="measureUnity" initialValue="CM" noStyle>
      <Select style={{ width: 70 }}>
        <Select.Option value="CM">cm</Select.Option>
        <Select.Option value="IN">in</Select.Option>
      </Select>
    </Form.Item>
  );

  const weightPrefixSelector = (
    <Form.Item name="weightUnity" initialValue="KG" noStyle>
      <Select style={{ width: 70 }}>
        <Select.Option value="KG">kg</Select.Option>
        <Select.Option value="LB">lb</Select.Option>
      </Select>
    </Form.Item>
  );

  const formatterNumber = (value) => value.replace(',', '.');
  const parserNumber = (value) => parseFloat(value.replace(',', '.'));

  return (
    <Modal
      title={t('catalogue.uploadProductModal.modalTitle')}
      open={isUploadProductModalOpen}
      width="60%"
      cancelText={t('common.cancel')}
      okButtonProps={{ style: {} }}
      onOk={handleModalOk}
      onCancel={handleModalCancel}
    >
      <Spin spinning={modalLoading}>
        <Form
          name="uploadProductForm"
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          className="text-align-left"
        >
          <Space direction="vertical" size="middle">
            <Row gutter={[24, 24]} style={{ width: '100%' }}>
              <Col span={8}>
                <Form.Item
                  name="title"
                  label={labelTooltip(
                    t('catalogue.uploadProductModal.title.title'),
                    t('catalogue.uploadProductModal.title.tooltip')
                  )}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'catalogue.uploadProductModal.title.emptyError'
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder={t(
                      'catalogue.uploadProductModal.title.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="brand"
                  label={labelTooltip(
                    t('catalogue.uploadProductModal.brand.title'),
                    t('catalogue.uploadProductModal.brand.tooltip')
                  )}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'catalogue.uploadProductModal.brand.emptyError'
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder={t(
                      'catalogue.uploadProductModal.brand.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="defaultCode"
                  label={labelTooltip(
                    t('catalogue.uploadProductModal.sku.title'),
                    t('catalogue.uploadProductModal.sku.tooltip')
                  )}
                  rules={[
                    {
                      required: true,
                      message: t('catalogue.uploadProductModal.sku.emptyError'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t(
                      'catalogue.uploadProductModal.sku.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ width: '100%' }}>
              <Col span={18}>
                <Row style={{ width: '100%' }} justify="space-between">
                  <Col className="upload-img-btn">
                    <Form.Item
                      label={labelTooltip(
                        t('catalogue.uploadProductModal.mainImage.title'),
                        t('catalogue.uploadProductModal.mainImage.tooltip')
                      )}
                      name="mainImage"
                      rules={[
                        {
                          required: true,
                          message: t(
                            'catalogue.uploadProductModal.mainImage.emptyError'
                          ),
                        },
                      ]}
                    >
                      <UploadImageButton
                        limit={1}
                        setCurrentFormValues={setCurrentImgValues}
                        currentFormValues={currentImgValues}
                        formItemName="mainImage"
                      />
                    </Form.Item>
                  </Col>
                  <Col flex="auto">
                    <Form.Item
                      name="description"
                      label={labelTooltip(
                        t('catalogue.uploadProductModal.description.title'),
                        t('catalogue.uploadProductModal.description.tooltip')
                      )}
                      rules={[
                        {
                          required: true,
                          message: t(
                            'catalogue.uploadProductModal.description.emptyError'
                          ),
                        },
                        {
                          max: 800,
                          message: t(
                            'catalogue.uploadProductModal.description.maxLengthError'
                          ),
                        },
                      ]}
                    >
                      <TextArea
                        style={{ height: '109px' }}
                        placeholder={t(
                          'catalogue.uploadProductModal.description.placeholder'
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="price"
                  label={labelTooltip(
                    t('calculator.price.title'),
                    t('calculator.price.tooltip')
                  )}
                  rules={[
                    {
                      required: true,
                      message: t('calculator.price.emptyError'),
                    },
                  ]}
                >
                  <InputNumber
                    min="0.05"
                    addonBefore={pricePrefixSelector}
                    step="0.01"
                    stringMode
                    formatter={formatterNumber}
                    parser={parserNumber}
                    controls={false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ width: '100%' }}>
              <Col span={6}>
                <Form.Item
                  name="length"
                  label={labelTooltip(
                    t('calculator.unit.length'),
                    t('calculator.unit.tooltip', {
                      unit: t('calculator.unit.length'),
                      object: t('calculator.product'),
                    }),
                    false
                  )}
                  rules={[
                    {
                      required: true,
                      message: t('calculator.unit.emptyError', {
                        unit: t('calculator.unit.length').toLowerCase(),
                        object: t('calculator.product'),
                      }),
                    },
                  ]}
                >
                  <InputNumber
                    min="1"
                    addonBefore={lengthPrefixSelector}
                    step="0.01"
                    stringMode
                    formatter={formatterNumber}
                    parser={parserNumber}
                    controls={false}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="width"
                  label={labelTooltip(
                    t('calculator.unit.width'),
                    t('calculator.unit.tooltip', {
                      unit: t('calculator.unit.width'),
                      object: t('calculator.product'),
                    })
                  )}
                  rules={[
                    {
                      required: true,
                      message: t('calculator.unit.emptyError', {
                        unit: t('calculator.unit.width').toLowerCase(),
                        object: t('calculator.product'),
                      }),
                    },
                  ]}
                >
                  <InputNumber
                    min="1"
                    addonBefore={lengthPrefixSelector}
                    step="0.01"
                    stringMode
                    formatter={formatterNumber}
                    parser={parserNumber}
                    controls={false}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="height"
                  label={labelTooltip(
                    t('calculator.unit.height'),
                    t('calculator.unit.tooltip', {
                      unit: t('calculator.unit.height'),
                      object: t('calculator.product'),
                    })
                  )}
                  rules={[
                    {
                      required: true,
                      message: t('calculator.unit.emptyError', {
                        unit: t('calculator.unit.height').toLowerCase(),
                        object: t('calculator.product'),
                      }),
                    },
                  ]}
                >
                  <InputNumber
                    min="1"
                    addonBefore={lengthPrefixSelector}
                    step="0.01"
                    stringMode
                    formatter={formatterNumber}
                    parser={parserNumber}
                    controls={false}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="weight"
                  label={labelTooltip(
                    t('calculator.unit.weight'),
                    t('calculator.unit.tooltip', {
                      unit: t('calculator.unit.weight'),
                      object: t('calculator.product'),
                    })
                  )}
                  rules={[
                    {
                      required: true,
                      message: t('calculator.unit.emptyError', {
                        unit: t('calculator.unit.weight').toLowerCase(),
                        object: t('calculator.product'),
                      }),
                    },
                  ]}
                >
                  <InputNumber
                    min="0.01"
                    addonBefore={weightPrefixSelector}
                    step="0.01"
                    stringMode
                    formatter={formatterNumber}
                    parser={parserNumber}
                    controls={false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ width: '100%' }}>
              <Col span={8}>
                <Form.Item
                  name="countryOfManufacture"
                  label={labelTooltip(
                    t(
                      'catalogue.uploadProductModal.countryOfManufacture.title'
                    ),
                    t(
                      'catalogue.uploadProductModal.countryOfManufacture.tooltip'
                    )
                  )}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'catalogue.uploadProductModal.countryOfManufacture.emptyError'
                      ),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    className="select-before"
                    filterOption={(input, option) =>
                      option?.key.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {phones.map((phone) => (
                      <Select.Option key={phone.name} value={phone.code}>
                        {phone.flag} {phone.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="harmonizedCode"
                  label={labelTooltip(
                    t('catalogue.uploadProductModal.harmonizedCode.title'),
                    <Typography.Text>
                      {t('catalogue.uploadProductModal.harmonizedCode.tooltip')}{' '}
                      <Link
                        href={t(
                          'catalogue.uploadProductModal.harmonizedCode.seeMore'
                        )}
                      >
                        {t('common.wantKnowMore')}
                      </Link>
                    </Typography.Text>
                  )}
                  rules={[
                    {
                      required: false,
                      message: t(
                        'catalogue.uploadProductModal.harmonizedCode.emptyError'
                      ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="unitFOB"
                  label={labelTooltip(
                    t('catalogue.uploadProductModal.unitFOB.title'),
                    <Typography.Text>
                      {t('catalogue.uploadProductModal.unitFOB.tooltip')}{' '}
                      <Link
                        href={t('catalogue.uploadProductModal.unitFOB.seeMore')}
                      >
                        {t('common.wantKnowMore')}
                      </Link>
                    </Typography.Text>
                  )}
                  rules={[
                    {
                      required: false,
                      message: t(
                        'catalogue.uploadProductModal.unitFOB.emptyError'
                      ),
                    },
                  ]}
                >
                  <InputNumber
                    min="0.01"
                    addonBefore="USD"
                    step="0.01"
                    stringMode
                    formatter={formatterNumber}
                    parser={parserNumber}
                    controls={false}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Form>
      </Spin>
    </Modal>
  );
}

UploadProductModal.propTypes = {
  isUploadProductModalOpen: PropTypes.bool.isRequired,
  setIsUploadProductModalOpen: PropTypes.func.isRequired,
  onSuccessUploadProduct: PropTypes.func.isRequired,
};

export default UploadProductModal;

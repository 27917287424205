import {
  Col,
  Row,
  Card,
  Divider,
  Affix,
  Space,
  Button,
  Alert,
  Popconfirm,
  Tooltip,
} from 'antd';
import {
  SendOutlined,
  WarningOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../requestAccount.css';
import DocumentRow from '../../DocumentRow';
import openNotification from '../../../../../../../components/Toastr';

function AccountCL({ sendDocs }) {
  const [files, setFiles] = useState(Array(2).fill(null));
  const [loading, setLoading] = useState(false);
  const [optionalFile, setOptionalFile] = useState(null);
  const { t } = useTranslation();

  const handleFileChange = (file, index, isOptional) => {
    if (isOptional) {
      setOptionalFile(file);
    } else {
      const updatedFiles = [...files];
      updatedFiles[index] = file;
      setFiles(updatedFiles);
    }
  };

  const handleCancelForm = async () => {
    window.location = '/couriers';
  };

  const handleEnviarClick = async () => {
    if (files.length === 2) {
      setLoading(true);
      const allFiles = [...files];
      if (optionalFile) {
        allFiles.push(optionalFile);
      }
      const formData = new FormData();
      allFiles.forEach((file) => {
        formData.append(`${file.name}`, file);
      });

      await sendDocs(formData);
      setLoading(false);
    } else {
      openNotification({
        status: false,
        content: t('requestAccount.error.missingDocuments', {
          missingFilesAmount: files.length,
        }),
      });
    }
  };
  return (
    <Card>
      <DocumentRow
        title={t('requestAccount.CL.doc1.title')}
        downloadLink="https://fedex-labels.s3.amazonaws.com/fedex_account_documents/CL/Formulario_apertura_cuenta_fedex.pdf"
        bulletPoints={[
          {
            value: t('requestAccount.CL.doc1.bullet_point1'),
            linkText: null,
            link: null,
          },
          {
            value: t('requestAccount.CL.doc1.bullet_point2'),
            linkText: null,
            link: null,
          },
        ]}
        index={0}
        handleFileChange={handleFileChange}
      />

      <Divider />

      <DocumentRow
        title={t('requestAccount.CL.doc2.title')}
        downloadLink="https://fedex-labels.s3.amazonaws.com/fedex_account_documents/CL/ANEXO+FACT.+ELECTRONICA_FEDEX_2023+(1).pdf"
        bulletPoints={[
          {
            value: t('requestAccount.CL.doc2.bullet_point1'),
            linkText: null,
            link: null,
          },
          {
            value: t('requestAccount.CL.doc2.bullet_point2'),
            linkText: null,
            link: null,
          },
        ]}
        index={1}
        handleFileChange={handleFileChange}
      />

      <Row justify="end" style={{ marginTop: 24 }}>
        <Col>
          <Affix offsetBottom={10}>
            <Alert
              className="sticky-alert-message"
              action={
                <Space>
                  <Popconfirm
                    title={t('requestAccount.contents.cancel')}
                    onConfirm={handleCancelForm}
                    onCancel={() => {}}
                    icon={<WarningOutlined />}
                    okText={t('yes')}
                    cancelText={t('no')}
                    okButtonProps={{
                      style: { backgroundColor: '#5365E3' },
                    }}
                  >
                    <Button size="small" type="link" icon={<CloseOutlined />}>
                      {t('orders.alertButtonClose')}
                    </Button>
                  </Popconfirm>
                  <Button
                    disabled={files.some((file) => file === null)}
                    className="btn-link-filled"
                    icon={<SendOutlined />}
                    loading={loading}
                    onClick={handleEnviarClick}
                  >
                    {t('orders.alertButtonSend')}
                    <Tooltip title={t('logistics.tooltip.couriers')} />
                  </Button>
                </Space>
              }
            />
          </Affix>
        </Col>
      </Row>
    </Card>
  );
}

export default AccountCL;

AccountCL.propTypes = { sendDocs: PropTypes.func.isRequired };

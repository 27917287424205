import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function SubscribeButton({ planId, loggedIn, disabled }) {
  const { t } = useTranslation();
  return (
    <Button className="empty-background-button" disabled={disabled}>
      {planId && (
        <Link
          className="empty-background-link"
          to={loggedIn ? `/suscripciones?planId=${planId}` : '/register'}
          id="subscribe-button-link"
          disabled={disabled}
        >
          <center>
            <span style={{ fontStyle: 'italic' }}>{t('plans.subscribe')}</span>
          </center>
        </Link>
      )}
    </Button>
  );
}
SubscribeButton.propTypes = {
  planId: PropTypes.number.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

SubscribeButton.defaultProps = {
  disabled: false,
};

export default React.memo(SubscribeButton);

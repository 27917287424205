/* eslint-disable eqeqeq */
import {
  CloseOutlined,
  SaveOutlined,
  SendOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Affix,
  Alert,
  Button,
  Col,
  Form,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Spin,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getErrorMessage } from '../../../../../api/api';
import restockOrdersAPI from '../../../../../api/restock-orders';
import openNotification from '../../../../../components/Toastr';
import { orderGeneralStates } from '../../../../../utils/const';
import ResponseBaseStatus from '../../../../shared/response-status';
import FboCompanyNames from '../enum/fboCompanyNames';
import Int99minutosRestockOrderDetails from './components/Int99minutosRestockOrderDetails';
import Int99MinutosRestockOrderDocs from './components/Int99MinutosRestockOrderDocs';
import Int99MinutosRestockOrderProducts from './components/Int99MinutosRestockOrderProducts';

function Int99MinutosRestockOrderSteps({ order, setCourierName, courierName }) {
  const { t } = useTranslation();
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [loadingListingsAndProducts, setLoadingListingsAndProducts] =
    useState(false);
  const [listingAndProducts, setListingAndProducts] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const history = useHistory();

  const getListingsAndProductsByPartner = async () => {
    try {
      setLoadingListingsAndProducts(true);
      const { data } = await restockOrdersAPI.getListingAndProducts({
        params: {
          companyName: FboCompanyNames.Fulfill99,
        },
      });
      setListingAndProducts(data);
    } catch (error) {
      openNotification({
        status: false,
        content: `Error al obtener los listings por partner: ${getErrorMessage(
          error
        )}`,
      });
    }
    setLoadingListingsAndProducts(false);
  };

  useEffect(() => {
    getListingsAndProductsByPartner();
  }, [order]);

  const [form] = Form.useForm();
  const { state, id: orderId } = order;

  const handleSaveDraft = () => {
    setIsUpdating(true);
    form.submit();
  };

  const handleSendDraft = () => {
    setIsUpdating(false);
    form.submit();
  };

  const sendOrUpdateDraft = async () => {
    setIsSavingDraft(true);
    const productsForRestock = [];
    selectedRowKeys.forEach((selectedRowKey) => {
      const productId = selectedRowKey.split('_')[0];

      listingAndProducts.forEach((listing) => {
        const existingProduct = listing.products.find(
          (product) => product.id == productId
        );
        if (existingProduct) {
          const productQty = Object.entries(form.getFieldsValue()).find(
            ([key]) => key.split('-')[1] == productId
          )[1];
          existingProduct.selectedQtyForRestock = productQty;

          if (productQty > 0) {
            productsForRestock.push(existingProduct);
          }
        }
      });
    });

    const payload = {
      content: {
        ...form.getFieldsValue(),
        // eslint-disable-next-line no-underscore-dangle
        fechaEnvio: form.getFieldsValue().fechaEnvio._d,
        products: productsForRestock,
        name: form.getFieldsValue().name.trim(),
      },
    };

    if (isUpdating) {
      await restockOrdersAPI.updateContent(order.id, payload, {
        params: {
          companyName: FboCompanyNames.Fulfill99,
        },
      });

      openNotification({
        status: true,
        content: 'Borrador guardado correctamente.',
      });
      setIsSavingDraft(false);
    } else {
      try {
        if (!payload.content.products || payload.content.products.length == 0) {
          openNotification({
            status: false,
            content: 'Debe seleccionar al menos un producto para restock.',
          });
          setIsSavingDraft(false);
          return;
        }
        const { data } = await restockOrdersAPI.sendContent(order.id, payload, {
          params: {
            companyName: FboCompanyNames.Fulfill99,
          },
        });

        if (data.status != ResponseBaseStatus.OK) {
          let cad = '';
          data.message.forEach((error) => {
            cad += `${error}\n`;
          });
          openNotification({ status: false, content: cad });
          setIsSavingDraft(false);
        } else {
          openNotification({
            status: true,
            content: 'Orden de restock enviada correctamente.',
          });
          if (courierName == FboCompanyNames.Fulfill99) {
            setCourierName('');
          } else {
            setCourierName(FboCompanyNames.Fulfill99);
          }
        }
      } catch (error) {
        openNotification({
          status: false,
          content: `Error al enviar orden de restock: ${error}`,
        });
        setIsSavingDraft(false);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      await sendOrUpdateDraft();
    } catch (error) {
      openNotification({
        status: false,
        content: `Error al guardar cambios: ${getErrorMessage(error)}`,
      });
    }
  };

  const updateSelectedRowKeys = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleCancelRestockOrder = async () => {
    setIsSavingDraft(true);
    try {
      const { data } = await restockOrdersAPI.cancelRestockOrder(
        orderId,
        null,
        {
          params: {
            companyName: FboCompanyNames.Fulfill99,
          },
        }
      );

      if (
        data.status === ResponseBaseStatus.ERROR_NEGOCIO ||
        data.status === ResponseBaseStatus.ERROR_APLICACION
      ) {
        openNotification({ status: false, content: data.message.join(', ') });
      } else {
        history.push('/restock');
        openNotification({
          status: true,
          content: 'Orden de restock cancelada correctamente.',
        });
      }
    } catch (error) {
      openNotification({
        status: false,
        content: 'Ha ocurrido un error al intentar cancelar la orden.',
      });
    }
    setIsSavingDraft(false);
  };

  const handleDeleteRestockOrder = async () => {
    setIsSavingDraft(true);
    try {
      await restockOrdersAPI.deleteRestockOrder(orderId, {
        params: {
          companyName: FboCompanyNames.Fulfill99,
        },
      });

      history.push('/restock');

      openNotification({
        status: true,
        content: 'Restock order eliminada correctamente.',
      });
    } catch (error) {
      openNotification({
        status: false,
        content: 'Ha ocurrido un error al intentar cancelar la orden.',
      });
    }
    setIsSavingDraft(false);
  };

  if (loadingListingsAndProducts) {
    return (
      <Space style={{ width: '100%', justifyContent: 'center', marginTop: 50 }}>
        <Spin />
      </Space>
    );
  }

  return (
    <>
      <PageHeader
        title={`Centro de fulfillment: ${order?.fboFulfillmentCompany}`}
      />

      <Form
        name="contentForm"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 18 }}
        className="text-align-left form-padding-top restockStepOne"
        scrollToFirstError
        onFinish={handleSubmit}
      >
        {(state === orderGeneralStates.READY_TO_SHIP ||
          state === orderGeneralStates.CLOSED ||
          state === orderGeneralStates.IN_TRANSIT) && (
          <Int99MinutosRestockOrderDocs order={order} />
        )}

        <Int99minutosRestockOrderDetails order={order} />
        <Int99MinutosRestockOrderProducts
          listingAndProducts={listingAndProducts}
          order={order}
          updateSelectedRowKeys={updateSelectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />

        <Row justify="end" style={{ marginTop: 24 }}>
          <Col>
            <Affix offsetBottom={10}>
              <Alert
                className="sticky-alert-message"
                action={
                  <Space>
                    <Popconfirm
                      title={
                        state === orderGeneralStates.DRAFT
                          ? t('orders.confirmDelete')
                          : `¿Está seguro que desea de cancelar la orden en ${order?.fboFulfillmentCompany}?`
                      }
                      disabled={
                        isSavingDraft ||
                        state == orderGeneralStates.CANCELLED ||
                        state == orderGeneralStates.CLOSED ||
                        state == orderGeneralStates.IN_TRANSIT
                      }
                      onConfirm={
                        state === orderGeneralStates.DRAFT
                          ? handleDeleteRestockOrder
                          : handleCancelRestockOrder
                      }
                      onCancel={() => {}}
                      icon={<WarningOutlined />}
                      okText={t('yes')}
                      cancelText={t('no')}
                      okButtonProps={{ style: { backgroundColor: '#5365E3' } }}
                    >
                      <Button
                        size="small"
                        type="link"
                        icon={<CloseOutlined />}
                        disabled={
                          isSavingDraft ||
                          state == orderGeneralStates.CANCELLED ||
                          state == orderGeneralStates.CLOSED ||
                          state == orderGeneralStates.IN_TRANSIT
                        }
                      >
                        {t('orders.alertButtonClose')}
                      </Button>
                    </Popconfirm>

                    <Button
                      disabled={
                        state !== orderGeneralStates.DRAFT || isSavingDraft
                      }
                      className="btn-primary"
                      icon={<SaveOutlined />}
                      onClick={handleSaveDraft}
                      loading={isSavingDraft}
                    >
                      {t('orders.alertButtonSave')}
                    </Button>

                    <Button
                      disabled={
                        state !== orderGeneralStates.DRAFT || isSavingDraft
                      }
                      className="btn-link-filled"
                      icon={<SendOutlined />}
                      onClick={handleSendDraft}
                      loading={isSavingDraft}
                    >
                      {t('orders.alertButtonSend')}
                    </Button>
                  </Space>
                }
              />
            </Affix>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default Int99MinutosRestockOrderSteps;

Int99MinutosRestockOrderSteps.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  courierName: PropTypes.string.isRequired,
  setCourierName: PropTypes.func.isRequired,
};

import PropTypes from 'prop-types';
import { Col, Divider, Form, Input, Modal, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import phones from '../../../utils/json/phones.json';
import stateOrProvinces from '../../../utils/json/stateOrProvinces.json';
import WarehousesAPI from '../../../api/warehouses';
import openNotification from '../../../components/Toastr';
import usePlacesInput from '../../shared/usePlacesInput';

function EditWarehouseModal({
  isModalOpen,
  setIsModalOpen,
  reloadTable,
  warehouseId,
  fieldsDisabled,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [selectedCountryCode, setSelectedCountryCode] = useState();
  const [currStateOrProvinces, setCurrStateOrProvinces] = useState();
  const [initialCountry, setInitialCountry] = useState('');
  const { Option } = Select;

  const addressInput = usePlacesInput(
    form,
    setSelectedCountryCode,
    stateOrProvinces,
    fieldsDisabled
  );

  useEffect(() => {
    setCurrStateOrProvinces(
      stateOrProvinces.find((row) => row.countryCode === selectedCountryCode)
    );
    if (initialCountry !== selectedCountryCode) {
      form.setFieldValue('stateOrProvinceCode', '');
    }
  }, [selectedCountryCode]);

  const getStatesOrProvinces = () => {
    return currStateOrProvinces?.states.map((state) => (
      <Select.Option key={state.code} value={state.code}>
        {state.name}
      </Select.Option>
    ));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFieldChange = () => {
    const countryCode = form.getFieldValue('countryCode');
    setSelectedCountryCode(countryCode);
  };

  const onFinish = async (values) => {
    const payload = {
      ...values,
    };
    // Sets stateOrProvinceCode as countryCode
    if (!values.stateOrProvinceCode)
      payload.stateOrProvinceCode = values.countryCode;
    try {
      await WarehousesAPI.updateWarehouse(payload, warehouseId);
      openNotification({
        status: true,
        content: t('warehouses.edit.success'),
      });
      await reloadTable();
      setIsModalOpen(false);
    } catch (error) {
      openNotification({
        status: false,
        content: t('warehouses.edit.error'),
      });
    }
  };

  useEffect(async () => {
    if (isModalOpen) {
      form.setFieldsValue({});
      const response = await WarehousesAPI.getWarehouse(warehouseId);
      form.setFieldsValue({ ...response.data, ...response.data.address });
      setInitialCountry(response.data.address.countryCode);
      const countryCode = form.getFieldValue('countryCode');
      setSelectedCountryCode(countryCode);
    }
  }, [isModalOpen]);

  const prefixSelector = (
    <Form.Item
      name="phoneExtension"
      noStyle
      rules={[
        {
          required: true,
          message: 'Extensión es requerida',
        },
      ]}
    >
      <Select
        className="contentOptionsPhone"
        showSearch
        optionFilterProp="children"
        disabled={fieldsDisabled}
      >
        {phones.length > 0 &&
          phones.map((element) => (
            <Option
              className="flag"
              value={`+${element.dial_code}`}
              key={element.code}
            >
              {element.name} (+
              {element.dial_code})
            </Option>
          ))}
      </Select>
    </Form.Item>
  );

  return (
    <Modal
      className="create-warehouse-modal"
      title={t('warehouses.edit.title')}
      open={isModalOpen}
      onOk={() => form.submit()}
      onCancel={handleCancel}
      width="75%"
      style={{
        top: 20,
      }}
      footer={fieldsDisabled ? null : undefined}
    >
      <Form
        name="create-warehouse-form"
        layout="vertical"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Row gutter={[16, 20]}>
          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.name')}
              name="name"
              rules={[{ required: true }]}
            >
              <Input disabled={fieldsDisabled} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.contactName')}
              name="contactName"
              rules={[{ required: true }]}
            >
              <Input disabled={fieldsDisabled} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.email')}
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Por favor, ingresa un email válido',
                },
              ]}
            >
              <Input disabled={fieldsDisabled} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.phoneNumber')}
              name="phoneNumber"
              rules={[{ required: true }]}
            >
              <Input addonBefore={prefixSelector} disabled={fieldsDisabled} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.address.streetName')}
              name="streetName"
              rules={[{ required: true }]}
            >
              {addressInput}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.address.additionalAddressInfo')}
              name="additionalAddressInfo"
            >
              <Input showCount maxLength={35} disabled={fieldsDisabled} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.address.city')}
              name="city"
              rules={[{ required: true }]}
            >
              <Input showCount maxLength={35} disabled={fieldsDisabled} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.address.postalCode')}
              name="postalCode"
              rules={[{ required: true }]}
            >
              <Input disabled={fieldsDisabled} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.address.countryCode')}
              name="countryCode"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                onChange={onFieldChange}
                filterOption={(input, option) =>
                  option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={fieldsDisabled}
              >
                {phones.map((row) => (
                  <Option key={row.name} value={row.code}>
                    {row.flag} {row.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            {currStateOrProvinces && (
              <Form.Item
                label={t('warehouses.createForm.address.stateOrProvinceCode')}
                name="stateOrProvinceCode"
                rules={[{ required: true }]}
              >
                <Select disabled={fieldsDisabled}>
                  {getStatesOrProvinces()}
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

EditWarehouseModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  reloadTable: PropTypes.func,
  warehouseId: PropTypes.number.isRequired,
  fieldsDisabled: PropTypes.bool,
};

EditWarehouseModal.defaultProps = {
  reloadTable: () => {},
  fieldsDisabled: false,
};

export default EditWarehouseModal;

import api from './api';

const prefix = '/restockV2024';

const restockOrdersV2024API = {
  createInboundShipmentPlan(orderId, data, params = {}) {
    return api.put(
      `${prefix}/${orderId}/createInboundShipmentPlan`,
      data,
      params
    );
  },
  setPackingInformation(orderId) {
    return api.post(`${prefix}/${orderId}/setPackingInformation`);
  },
  getShipmentOptions(orderId, params) {
    return api.get(`${prefix}/${orderId}/shipmentOptions`, { params });
  },
  confirmShipment(orderId, body, params) {
    return api.post(`${prefix}/${orderId}/confirmShipment`, body, params);
  },
};

export default restockOrdersV2024API;

import * as React from 'react';
import PropTypes from 'prop-types';

function SvgDafiti({ height, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height || 15}
      width={width || 78}
      viewBox="0 0 684 617"
    >
      <rect width="684" height="617" rx="102" fill="black" />
      <path d="M200 205H179V374.046V391H200V205Z" fill="white" />
      <circle cx="129" cy="324" r="71" fill="white" />
      <ellipse cx="129.5" cy="323.5" rx="49.5" ry="50.5" fill="black" />
      <path d="M356 253H335V382.056V395H356V253Z" fill="white" />
      <circle cx="285" cy="324" r="71" fill="white" />
      <ellipse cx="285.5" cy="323.5" rx="49.5" ry="50.5" fill="black" />
      <path d="M411 256H390V380.512V393H411V256Z" fill="white" />
      <path
        d="M453 206V227C439.5 230 422.204 240.378 416 255.5H393.5C401.11 229.015 426.5 206 453 206Z"
        fill="white"
      />
      <path
        d="M452 275L452 255L377.475 255L370 255L370 275L452 275Z"
        fill="white"
      />
      <path d="M488 255H467V380.421V393H488V255Z" fill="white" />
      <path d="M488 206H467V225.086V227H488V206Z" fill="white" />
      <path d="M539 206H517V313.244V324H539V206Z" fill="white" />
      <path
        d="M579 275L579 255L509.928 255L503 255L503 275L579 275Z"
        fill="white"
      />
      <path
        d="M580 391V369.352C554.905 362.807 538.844 345.69 538.844 318H517.264C513.75 351.731 545.872 391 580 391Z"
        fill="white"
      />
      <path d="M615 255H594V380.421V393H615V255Z" fill="white" />
      <path d="M615 206H594V225.086V227H615V206Z" fill="white" />
    </svg>
  );
}

SvgDafiti.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

SvgDafiti.defaultProps = {
  width: 78,
  height: 15,
};

export default SvgDafiti;

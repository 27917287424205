import { Button, Form, Input, Modal, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import accessKeysApi from '../../../../api/aws-access-keys';
import openNotification from '../../../../components/Toastr';
import * as Actions from '../../../../redux/session/action';

const { Item } = Form;

export default function EdicommerceSellerAccount({ tab, session }) {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const saveCredentials = (credentials) => {
    setIsLoading(true);
    accessKeysApi
      .saveCredentials(
        session?.userInfo?.commercial_partner_id[0],
        tab,
        credentials
      )
      .then(() => {
        Modal.success({
          content: 'Se asoció la cuenta exitosamente',
        });
        setIsLoading(false);
        dispatch(Actions.updateSellerAccountStatusSession(true));
      })
      .catch(() => {
        setIsLoading(false);
        openNotification({
          status: false,
          content: 'No se pudo validar la cuenta',
        });
      });
  };

  return (
    <Form
      name={`${tab}-form`}
      form={form}
      onFinish={saveCredentials}
      style={{ width: '100%' }}
    >
      <Row>
        <span
          style={{
            fontSize: '18px',
            marginBottom: 10,
            marginTop: 15,
            width: '100%',
          }}
        >
          Usuario
        </span>
      </Row>
      <Row>
        <Item
          name="usuario"
          rules={[{ required: true }]}
          style={{
            width: '100%',
            borderRadius: '10px',
            marginBottom: 15,
          }}
        >
          <Input />
        </Item>
      </Row>

      <Row>
        <span
          style={{
            fontSize: '18px',
            marginBottom: 10,
            width: '100%',
          }}
        >
          Clave
        </span>
      </Row>
      <Row>
        <Item
          name="clave"
          rules={[{ required: true }]}
          style={{
            width: '100%',
            borderRadius: '10px',
            marginBottom: 15,
          }}
        >
          <Input />
        </Item>
      </Row>

      <Row>
        <span
          style={{
            fontSize: '18px',
            marginBottom: 10,
            width: '100%',
          }}
        >
          Proveedor
        </span>
      </Row>
      <Row>
        <Item
          name="proveedor"
          rules={[{ required: true }]}
          style={{
            width: '100%',
            borderRadius: '10px',
          }}
        >
          <Input />
        </Item>
      </Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type="primary"
          style={{
            marginTop: 40,
            marginBottom: 15,
            padding: '5px 20px',
            height: '40px',
          }}
          loading={isLoading}
          disabled={isLoading}
          onClick={() => form.submit()}
        >
          Conectar marketplace
        </Button>
      </div>
    </Form>
  );
}

EdicommerceSellerAccount.propTypes = {
  tab: PropTypes.string.isRequired,
  session: PropTypes.instanceOf(Object).isRequired,
};

import { Col, Row, Spin, Typography, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Joyride from 'react-joyride';
import CourierAvatar from './components/CourierAvatar';
import FedexForm from './components/fedex/fedex-international/FedexForm';
import CouriersApi from '../../api/courier';
import Int99MinutosForm from './components/99Minutos/Int99MinutosForm';
import './Couriers.css';
import Fulfill99Svg from './icons/fulfill99.svg';
import Int99MinutosSvg from './icons/99minutos.svg';
import ShipsmartSvg from './icons/shipsmart.svg';
import FedexSvg from './icons/fedex.svg';
import Fulfill99Form from './components/fulfillments/fulfill99/Fulfill99Form';
import SvgEdarkstore from './icons/SvgEdarkstore.svg';
import SvgMelonn from './icons/melonn.svg';
import EdarkstoreForm from './components/edarkstore/EdarkstoreForm';
import FBA from './components/FBA';
import FedexUsForm from './components/fedex/fedex-us/FedexUsForm';
import accessKeysApi from '../../api/aws-access-keys';
import MelonnForm from './components/fulfillments/melonn/MelonnForm';
import { stage2Courier } from '../../stepsOnboarding';
import { updateOnboarding } from '../../redux/onboarding/action';
import ShipsmartForm from './components/shipsmart/ShipsmartForm';
import fulfillmentAPI from '../../api/fulfillment';

function Couriers() {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const session = useSelector((store) => store.Session.session);
  const [dataSource, setDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [hasFulfill99Permissions, setHasFulfill99Permissions] = useState(false);
  const [hasFBACredentials, setHasFBACredentials] = useState(null);
  const onboardingStatus = useSelector((store) => store.Onboarding);
  const { t } = useTranslation();

  const verifyFulfill99Permissions = async () => {
    try {
      const response = await fulfillmentAPI.getFulfill99Permissions();
      return response.data;
    } catch (error) {
      return false;
    }
  };

  const handleSkipTourCallback = (data) => {
    const { action, lifecycle } = data;
    if (action === 'close' && lifecycle === 'complete') {
      dispatch(updateOnboarding(null, null));
    }
  };

  const loadTable = async () => {
    setLoadingTable(true);
    const response = await CouriersApi.getCourierCredentials(
      pageSize,
      currentPage
    );
    setCurrentPage(response.data.page);
    setPageSize(response.data.limit);
    const data = response.data.results.map((value) => ({
      key: value.id,
      name: value.courierCompanyName,
      accountNumber: value.accountNumber,
      fbo: value.fbo,
      fbm: value.fbm,
      managedByLap: value.managedByLap,
      countryCode: value.countryCode,
    }));
    const int99MinutosCredentials = data.find(
      (name) => name.name === '99Minutos'
    );
    if (int99MinutosCredentials) {
      const fulfill99Permissions = await verifyFulfill99Permissions();
      setHasFulfill99Permissions(fulfill99Permissions);
    }
    setDataSource(data);
    const fbaResponse = await accessKeysApi.getUserCredentials(
      session?.userInfo?.commercial_partner_id[0],
      'Amazon',
      'Seller'
    );
    setHasFBACredentials(!!fbaResponse.data);
    setLoadingTable(false);
  };

  useEffect(() => {
    loadTable();
  }, [currentPage]);

  if (loadingTable) {
    return <Spin />;
  }

  return (
    <div className="initialContent">
      <Row>
        <Title level={3}>Centros de fulfillment</Title>
        <Tooltip title={t('logistics.tooltip.fulfillment')}>
          <InfoCircleOutlined style={{ fontSize: '18px', padding: '7px' }} />
        </Tooltip>
      </Row>

      <Row justify="space-between" style={{ marginTop: '25px' }}>
        <Col xs={24} md={24} lg={11}>
          <FBA hasCredentials={hasFBACredentials} />
        </Col>

        <Col xs={24} md={24} lg={11}>
          <CourierAvatar
            imgHeight={90}
            imgPadding="10px"
            icon={SvgEdarkstore}
            name="eDarkstore"
            formContent={EdarkstoreForm}
            loadTable={loadTable}
            couriersCredentials={dataSource}
            tooltipText={t('logistics.tooltip.eDarkstore')}
            isFulfillment
          />
        </Col>
      </Row>

      <Row justify="space-between">
        <Col xs={24} md={24} lg={11}>
          <CourierAvatar
            imgHeight={90}
            imgPadding="10px"
            icon={Fulfill99Svg}
            name="Fulfill99"
            formContent={Fulfill99Form}
            loadTable={loadTable}
            couriersCredentials={dataSource}
            isSwitch
            isSwitchDisabled={!hasFulfill99Permissions}
            tooltipText={t('logistics.tooltip.Fulfill99')}
            isFulfillment
          />
        </Col>
        <Col xs={24} md={24} lg={11}>
          <CourierAvatar
            imgHeight={90}
            imgPadding="10px"
            icon={SvgMelonn}
            name="Melonn"
            formContent={MelonnForm}
            loadTable={loadTable}
            couriersCredentials={dataSource}
            label="Melonn"
            tooltipText={t('logistics.tooltip.Melonn')}
            isFulfillment
          />
        </Col>
      </Row>

      <div
        style={{
          marginTop: '70px',
        }}
      >
        <Row>
          <Title level={3}>Couriers</Title>
          <Tooltip title={t('logistics.tooltip.couriers')}>
            <InfoCircleOutlined style={{ fontSize: '18px', padding: '7px' }} />
          </Tooltip>
        </Row>

        <Row justify="space-between" style={{ marginTop: '25px' }}>
          <Col xs={24} md={24} lg={11}>
            <CourierAvatar
              imgHeight={90}
              imgPadding="10px"
              icon={FedexSvg}
              name="Fedex"
              label="FedEx Cross Border"
              formContent={FedexForm}
              loadTable={loadTable}
              couriersCredentials={dataSource}
              tooltipText={t('logistics.tooltip.FedExCB')}
            />
          </Col>

          <Col xs={24} md={24} lg={11}>
            <CourierAvatar
              imgHeight={90}
              imgPadding="10px"
              icon={FedexSvg}
              name="FedexUS"
              label="FedEx Local US"
              formContent={FedexUsForm}
              loadTable={loadTable}
              couriersCredentials={dataSource}
              tooltipText={t('logistics.tooltip.FedExLocal')}
            />
          </Col>

          <Col xs={24} md={24} lg={11}>
            <CourierAvatar
              imgHeight={90}
              icon={Int99MinutosSvg}
              name="99Minutos"
              formContent={Int99MinutosForm}
              loadTable={loadTable}
              couriersCredentials={dataSource}
              tooltipText={t('logistics.tooltip.minutos99')}
            />
          </Col>

          <Col xs={24} md={24} lg={11}>
            <CourierAvatar
              imgHeight={90}
              imgPadding="10px"
              icon={ShipsmartSvg}
              name="Shipsmart"
              formContent={ShipsmartForm}
              loadTable={loadTable}
              couriersCredentials={dataSource}
            />
          </Col>
        </Row>
      </div>
      {onboardingStatus.state === 'Courier' && onboardingStatus.step === 2 && (
        <Joyride
          steps={stage2Courier}
          callback={handleSkipTourCallback}
          disableCloseOnEsc
          spotlightClicks
          disableScrolling
        />
      )}
    </div>
  );
}

export default Couriers;

import { PictureOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import {
  Avatar,
  Col,
  Image,
  Row,
  Space,
  Card,
  Button,
  Dropdown,
  Menu,
  Tabs,
  Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Joyride from 'react-joyride';
import productTemplateApi from '../../api/product-template';
import productApi from '../../api/product';
import listingsApi from '../../api/listing';
import openNotification from '../../components/Toastr';
import { getErrorMessage } from '../../api/api';
import './catalogue.css';
import getLinkToStore from '../home/components/linkToStore';
import UploadProductModal from './components/UploadProductModal';
import PublishProductsModal from './components/PublishProductsModal';
import { stage3Post, stage3Catalogue } from '../../stepsOnboarding';
import { updateOnboarding } from '../../redux/onboarding/action';
import EdicommerceTable from './components/EdicommerceTable';
import GroupedMarketplaceTable from './components/GroupedMarketplaceTable';
import MarketplaceTable from './components/MarketplaceTable';
import CatalogueFilter from './components/CatalogueFilter';
import Banner, { AlertTypeEnum } from '../../components/Alert';
import { SyncStatusEnum, SyncStatusTypesEnum } from '../../utils/const';
import syncStatusApi from '../../api/sync-status';
import ExportProductsModal from './components/ExportProductsModal';
import CheckboxDropdown from './components/CheckboxDropdown';

const { Text } = Typography;

function Catalogue() {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredProductsLoading, setFilteredProductsLoading] = useState(false);
  const [filteredParentProducts, setFilteredParentProducts] = useState([]);
  const [loadingAPI, setLoadingAPI] = useState(false);
  const [marketplaces, setMarketplaces] = useState([]);
  const [rowKeys, setRowKeys] = useState([]);
  const [skus, setSkus] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [isUploadProductModalOpen, setIsUploadProductModalOpen] =
    useState(false);
  const [successUploadProductTemplate, setSuccessUploadProductTemplate] =
    useState(false);
  const [currentMarketplace, setCurrentMarketplace] = useState(null);
  const [isSynchronizing, setIsSynchronizing] = useState(false);
  const session = useSelector((store) => store.Session.session);
  const onboardingStatus = useSelector((store) => store.Onboarding);
  const [filter, setFilter] = useState({
    marketplace: null,
    statuses: null,
    fulfillmentType: null,
    sku: null,
    title: null,
    limit: 50,
    page: 1,
  });
  const [totalResults, setTotalResults] = useState(0);
  const [totalParents, setTotalParents] = useState(null);
  const [initialTotal, setInitialTotal] = useState(null);
  const [selectAll, setSelectAll] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedTotal = selectAll
    ? initialTotal - Number(productIds?.length)
    : Number(productIds?.length);

  const expandIcon = ({ expanded, onExpand, record }) =>
    record?.children?.length > 0 ? (
      <Button type="link" onClick={(e) => onExpand(record, e)}>
        {expanded ? <UpOutlined /> : <DownOutlined />}
      </Button>
    ) : null;

  const getTemplateProducts = async () => {
    setLoadingAPI(true);
    productTemplateApi
      .buildProductsTree(filter)
      .then((result) => {
        const { data } = result;
        setFilteredProducts(data.results);
        setFilteredParentProducts(data.tree);
        setTotalResults(data.totalResults);
        if (!filter.sku && !filter.statuses && !filter.title) {
          setInitialTotal(data.totalResults);
        }
        setTotalParents(Object.keys(data.tree).length);
        setFilteredProductsLoading(false);
        setLoadingAPI(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
        setLoadingAPI(false);
      });
  };

  const setDefaultFilterValues = () => {
    setRowKeys([]);
    setSkus([]);
    setProductIds([]);
    setSelectAll(false);
  };

  useEffect(async () => {
    if (!filter.marketplace) return;
    if (filter.marketplace === 'groupedMarketplaces') {
      await getTemplateProducts();
      return;
    }
    setFilteredProductsLoading(true);
    await productApi
      .buildProductsTree(filter)
      .then((result) => {
        const { data } = result;
        setFilteredProducts(data.rows);
        setFilteredParentProducts(data.tree);
        setTotalResults(data.totalParents + data.totalChildren);
        if (!filter.sku && !filter.statuses && !filter.title) {
          setInitialTotal(data.totalParents + data.totalChildren);
        }
        setTotalParents(data.totalParents);
        setFilteredProductsLoading(false);
        const currentPageRows = [
          ...data.rows.map((obj) => obj?.id),
          ...data.rows.flatMap((obj) =>
            obj.children?.map((child) => child?.id)
          ),
        ].filter((id) =>
          selectAll ? !productIds.includes(id) : productIds.includes(id)
        );
        setRowKeys(currentPageRows);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
        setFilteredProductsLoading(false);
      });
  }, [filter]);

  useEffect(() => {
    if (productIds.length === initialTotal && initialTotal > 0) {
      setSelectAll(!selectAll);
      setSkus([]);
      setProductIds([]);
    }
  }, [productIds]);

  const getDataSources = async () => {
    setFilteredParentProducts([]);
    await getTemplateProducts();
  };

  const getSyncStatus = async () => {
    const syncStatusResponse = await syncStatusApi.get();
    const statusTypes = [SyncStatusTypesEnum.BULK_LISTINGS];
    if (syncStatusResponse?.data) {
      const status = syncStatusResponse.data?.find(
        (element) =>
          statusTypes.includes(element.type) &&
          element.status === SyncStatusEnum.RUNNING
      );
      setIsSynchronizing(!!status?.id);
    } else {
      setIsSynchronizing(false);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!session || !isSynchronizing) {
        return;
      }
      getSyncStatus();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [isSynchronizing]);

  // get all products
  useEffect(async () => {
    if (session) {
      await getSyncStatus();
    }
  }, [session]);

  const updateProductPrice = async (record, newPrice) => {
    await productApi.updateByDefaultCode(record.defaultCode, {
      dashboardPrice: newPrice,
    });
  };

  const handleProductPriceChange = (value, record) => {
    if (!Number(value) && Number(value) !== 0) {
      return;
    }

    if (Number(value).toFixed(2) === Number(record.dashboardPrice).toFixed(2)) {
      return;
    }
    const newValue = Number(value).toFixed(2);

    try {
      updateProductPrice(record, newValue);

      const newProductsWithPrice = filteredProducts.map((product) => {
        const newProduct = product;
        if (product.id === record.id) {
          newProduct.dashboardPrice = newValue;
        }
        return newProduct;
      });

      setFilteredProducts(newProductsWithPrice);

      openNotification({
        status: true,
        content: 'El precio se actualizó correctamente',
      });
    } catch (error) {
      openNotification({
        status: false,
        content: 'Hubo un error al actualizar el precio',
      });
    }
  };

  const getRecordIdentifier = (record) => {
    if (record.asin) {
      return `ASIN: ${record?.asin}`;
    }
    return `Identificador: ${record?.externalId}`;
  };

  const renderProductColumn = (value, record) => {
    const groupedMkts = filter.marketplace === 'groupedMarketplaces';
    const imgUrlGroupedMkts =
      record?.mainImages?.length > 0 ? record?.mainImages[0]?.url : null;

    const imgUrlByMkt = record?.mainImage ? record?.mainImage : null;
    return (
      <Row align="middle" justify="start">
        <Col span={24}>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Space size="middle">
              {imgUrlGroupedMkts && !groupedMkts ? (
                <Avatar
                  shape="square"
                  size={55}
                  style={{ backgroundColor: '#FFFFFF' }}
                  src={
                    (record?.children?.length === 0 || record.isVariant) &&
                    imgUrlGroupedMkts ? (
                      <Image src={`${imgUrlGroupedMkts}`} />
                    ) : null
                  }
                />
              ) : null}
              {imgUrlByMkt && groupedMkts ? (
                <Avatar
                  shape="square"
                  size={55}
                  style={{ backgroundColor: '#FFFFFF' }}
                  src={
                    (record?.children?.length === 0 || record.isVariant) &&
                    imgUrlByMkt ? (
                      <Image src={`${imgUrlByMkt}`} />
                    ) : null
                  }
                />
              ) : null}
              {!(imgUrlGroupedMkts && !groupedMkts) &&
              !(imgUrlByMkt && groupedMkts) ? (
                <Avatar
                  shape="square"
                  size={55}
                  style={{ backgroundColor: '#FFFFFF' }}
                  src={
                    record?.children?.length === 0 || record?.isVariant ? (
                      <PictureOutlined />
                    ) : null
                  }
                />
              ) : null}
              <Space direction="vertical" style={{ textAlign: 'left' }}>
                <p
                  style={{
                    marginBottom: -10,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {!groupedMkts
                    ? getLinkToStore[record?.marketplace](record)
                    : record?.title}
                </p>
                <p
                  style={{
                    marginBottom: -4,
                    fontSize: '12px',
                  }}
                >
                  {!groupedMkts
                    ? getRecordIdentifier(record)
                    : `${record?.productIdentifierType?.toUpperCase()}: ${
                        record?.productIdentifier
                      }`}
                </p>
                <p
                  style={{
                    marginBottom: -10,
                    fontSize: '12px',
                    color: '#646464',
                  }}
                >
                  {record.updatedAt
                    ? `Última modificación: ${moment(record.updatedAt).format(
                        'MMM DD, YY'
                      )}`
                    : `${record?.productIdentifierType?.toUpperCase()}: ${
                        record?.productIdentifier
                      }`}
                </p>
              </Space>
            </Space>
          </div>
        </Col>
      </Row>
    );
  };

  // const showUploadProductModal = () => {
  //   setIsUploadProductModalOpen(true);
  // };

  const handleProductsFileDownload = async () => {
    const params = {};
    if (rowKeys) {
      params.skus = skus;
      params.all = selectAll;
    }
    params.marketplace = filter.marketplace;
    return listingsApi.downloadProductsSheet(params).catch((error) => {
      openNotification({
        status: false,
        content: getErrorMessage(error),
      });
    });
  };

  const onMarketplaceSelect = (value) => {
    setCurrentMarketplace(value);
    if (onboardingStatus.state === 'FirstPost' && onboardingStatus.step === 0) {
      dispatch(updateOnboarding('FirstPost', 1));
    }
  };

  const onSuccessUploadProduct = async () => {
    await getTemplateProducts();
    setSuccessUploadProductTemplate(true);
  };

  const mergeArraysWithoutDuplicates = (array1, array2) => [
    ...new Set([...array1, ...array2]),
  ];

  const mergeParentChildKeys = (array, key) => {
    return new Set([
      ...array.map((obj) => obj?.[key]),
      ...array.flatMap((obj) => obj.children?.map((child) => child?.[key])),
    ]);
  };

  const handleSingleSelection = (selectedRows) => {
    const selectedRowsOnlyParents =
      selectedRows?.filter((obj) => obj && !obj?.isVariant) || [];
    const selectedSkus = mergeParentChildKeys(
      selectedRowsOnlyParents,
      'defaultCode'
    );
    const pageSkus = mergeParentChildKeys(filteredProducts, 'defaultCode');
    const selectedIds = mergeParentChildKeys(selectedRowsOnlyParents, 'id');
    const pageIds = mergeParentChildKeys(filteredProducts, 'id');
    setRowKeys([...selectedIds]);
    if (selectAll) {
      setSkus(
        mergeArraysWithoutDuplicates(
          skus.filter((s) => !pageSkus.has(s)),
          [...pageSkus].filter((s) => !selectedSkus.has(s))
        )
      );
      setProductIds(
        mergeArraysWithoutDuplicates(
          productIds.filter((i) => !pageIds.has(i)),
          [...pageIds].filter((i) => !selectedIds.has(i))
        )
      );
    } else {
      setSkus(
        mergeArraysWithoutDuplicates(
          skus.filter((s) => !pageSkus.has(s)),
          selectedSkus
        )
      );
      setProductIds(
        mergeArraysWithoutDuplicates(
          productIds.filter((i) => !pageIds.has(i)),
          selectedIds
        )
      );
    }
  };

  const dropdownMenu = (theme) =>
    marketplaces.length > 0 ? (
      <Menu theme={theme}>
        {marketplaces.map((mkp) => (
          <Menu.Item key={mkp} onClick={() => onMarketplaceSelect(mkp)}>
            {t('dashboard.marketplaces.'.concat(mkp.replace(' ', '')))}
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <Menu theme="light">
        <Col
          style={{
            maxWidth: 300,
            display: 'flex-wrap',
            justifyContent: 'center',
            padding: '2px 10px',
          }}
        >
          <Row>
            <p>{t('catalogue.noMarketplaceConnected')}</p>
          </Row>
          <Row>
            <Button
              style={{
                justifyContent: 'left',
                padding: '6px',
              }}
              className="btn-basic"
              onClick={() => history.push('/my-account?step=2')}
            >
              {t('common.connectMarket')}
            </Button>
          </Row>
        </Col>
      </Menu>
    );

  const pagination = {
    defaultPageSize: 50,
    pageSize: filter.limit,
    pageSizeOptions: [10, 20, 50],
    current: filter.page,
    onChange: (page) => {
      setFilter({ ...filter, page });
    },
    onShowSizeChange: (limit) => setFilter({ ...filter, limit, page: 1 }),
    total: totalResults,
  };

  const expandable = {
    expandedRowRender: (record) => record,
    rowExpandable: (record) => record?.children?.length > 0,
    expandIconColumnIndex: 1,
    expandIcon,
  };

  const rowSelectionByMkt = {
    type: 'checkbox',
    getCheckboxProps: (record) => {
      const checkboxProps = {};
      if (record.isVariant) {
        checkboxProps.disabled = true;
      }
      return checkboxProps;
    },
    selectedRowKeys: rowKeys,
    preserveSelectedRowKeys: true,
    columnTitle: (
      <CheckboxDropdown
        initialTotal={initialTotal}
        totalResults={totalResults}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        rowKeys={rowKeys}
        setRowKeys={setRowKeys}
        productIds={productIds}
        setProductIds={setProductIds}
        skus={skus}
        setSkus={setSkus}
        filteredProducts={filteredProducts}
      />
    ),
    onChange: (selectedRowsKeys, selectedRows, info) => {
      if (info.type === 'single') {
        handleSingleSelection(selectedRows);
      }
    },
  };

  const handleSkipTourCallback = (data) => {
    const { action, lifecycle } = data;
    if (action === 'close' && lifecycle === 'complete') {
      dispatch(updateOnboarding(null, null));
    }
  };

  const handleSeeImports = () => {
    if (onboardingStatus.state === 'FirstPost' && onboardingStatus.step === 6) {
      dispatch(updateOnboarding('FirstPost', 7));
    }
    history.push('/imports');
  };

  return (
    <div className="content-div container-products">
      {isSynchronizing && (
        <Row>
          <Col span={24}>
            <Banner
              message={
                <Row>
                  <Col style={{ maxWidth: 700 }}>
                    <Text style={{ marginRight: 5 }} strong>
                      {t('catalogue.banner.syncImportTitle')}
                    </Text>
                    <Text>{t('catalogue.banner.syncImportDescription')}</Text>
                  </Col>
                </Row>
              }
              type={AlertTypeEnum.INFO}
              closable="true"
            />
          </Col>
        </Row>
      )}
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Card>
          <CatalogueFilter
            filter={filter}
            setFilter={setFilter}
            setMarketplaces={setMarketplaces}
            setDefaultFilterValues={setDefaultFilterValues}
            successUploadProductTemplate={successUploadProductTemplate}
          />
        </Card>
        <Card>
          <Row style={{ justifyContent: 'space-between' }}>
            <Col>
              <div style={{ color: '#00E5A6' }}>
                {selectAll || productIds?.length > 0
                  ? `${
                      filter.marketplace === 'groupedMarketplaces'
                        ? t('catalogue.select.selectedProducts')
                        : t('catalogue.select.selectedListings')
                    }: ${selectedTotal}`
                  : ''}
              </div>
            </Col>
            <Col>
              <Space>
                <Button
                  className="btn-basic step7FirstPost"
                  onClick={handleSeeImports}
                >
                  <Space>{t('products.fields.seeImports')}</Space>
                </Button>
                {
                  // <Button className="btn-basic" onClick={showUploadProductModal}>
                  //  <Space>{t('products.fields.addProduct')}</Space>
                  // </Button>
                }
                {filter.marketplace === 'groupedMarketplaces' ? null : (
                  <Button
                    className="btn-basic"
                    onClick={handleProductsFileDownload}
                    disabled={skus.length === 0 && !selectAll}
                  >
                    <Space>{t('products.fields.downloadProductsFile')}</Space>
                  </Button>
                )}
                <UploadProductModal
                  isUploadProductModalOpen={isUploadProductModalOpen}
                  setIsUploadProductModalOpen={setIsUploadProductModalOpen}
                  onSuccessUploadProduct={onSuccessUploadProduct}
                />
                <Dropdown overlay={dropdownMenu('dark')}>
                  <Button className="btn-basic-green step1FirstPost">
                    <Space>
                      {t(
                        `products.fields.${
                          selectedTotal > 0 ? 'export' : 'publish'
                        }Products`
                      )}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
                <PublishProductsModal
                  marketplaceToPublish={currentMarketplace}
                  setMarketplaceToPublish={setCurrentMarketplace}
                  originMarketplace={filter.marketplace}
                  selectedListings={skus}
                  all={selectAll}
                  total={selectedTotal}
                />
                <ExportProductsModal
                  marketplaceToExport={currentMarketplace}
                  setMarketplaceToExport={setCurrentMarketplace}
                  originMarketplace={filter.marketplace}
                  selectedListings={skus}
                  all={selectAll}
                  total={selectedTotal}
                />
              </Space>
            </Col>
          </Row>
          <Tabs>
            <Tabs.TabPane
              tab={
                filter.marketplace === 'groupedMarketplaces'
                  ? `${totalResults || '-'} ${t('common.product', {
                      count: totalResults,
                    }).toLowerCase()} (${t('catalogue.showingParents', {
                      count: totalParents,
                    })})`
                  : `${totalResults || '-'} ${t('common.listing', {
                      count: totalResults,
                    }).toLowerCase()} (${totalParents || '-'} ${t(
                      'common.parent',
                      { count: totalParents }
                    ).toLowerCase()})`
              }
              key="item-1"
            >
              <Row className="products-table">
                <Col span={24}>
                  {filter.marketplace === 'groupedMarketplaces' ? (
                    <GroupedMarketplaceTable
                      handleProductPriceChange={handleProductPriceChange}
                      filteredParentProducts={filteredParentProducts}
                      loading={loadingAPI}
                      pagination={pagination}
                      expandable={expandable}
                      renderProductColumn={renderProductColumn}
                      rowKeys={productIds}
                      handleSingleSelection={handleSingleSelection}
                    />
                  ) : null}
                  {filter.marketplace === 'Edicommerce' ? (
                    <EdicommerceTable
                      dataSource={filteredProducts}
                      loading={filteredProductsLoading}
                      setDataSource={setFilteredProducts}
                      handleProductPriceChange={handleProductPriceChange}
                      pagination={pagination}
                      expandable={expandable}
                      rowSelection={rowSelectionByMkt}
                      renderProductColumn={renderProductColumn}
                    />
                  ) : null}
                  {!['Edicommerce', 'groupedMarketplaces'].includes(
                    filter.marketplace
                  ) ? (
                    <MarketplaceTable
                      getDataSources={getDataSources}
                      filteredProducts={filteredProducts}
                      loading={filteredProductsLoading}
                      pagination={pagination}
                      expandable={expandable}
                      rowSelection={rowSelectionByMkt}
                      renderProductColumn={renderProductColumn}
                    />
                  ) : null}
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </Space>
      {onboardingStatus.state === 'FirstPost' &&
        onboardingStatus.step === 0 && (
          <Joyride
            steps={stage3Post}
            callback={handleSkipTourCallback}
            disableCloseOnEsc
            spotlightClicks
          />
        )}
      {onboardingStatus.state === 'FirstPost' &&
        onboardingStatus.step === 6 && (
          <Joyride
            steps={stage3Catalogue}
            callback={handleSkipTourCallback}
            disableCloseOnEsc
            spotlightClicks
          />
        )}
    </div>
  );
}

export default Catalogue;

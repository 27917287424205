import { Button, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import openAIAPI from '../../../api/openai';
import openNotification from '../../../components/Toastr';
import { getErrorMessage } from '../../../api/api';

function Message({ message, threadId, status, setSelectedThread, setLoading }) {
  const { t } = useTranslation();
  const metadataStatusKeys = {
    0: 'topic',
    1: 'productType',
    2: 'countryOfManufacture',
    3: 'destinationCountry',
  };
  const handleOptionSelect = async (option) => {
    const payload = {
      key: metadataStatusKeys[status],
      value: option,
    };
    setLoading(true);
    try {
      const response = await openAIAPI.updateThreadMetadata(threadId, payload);
      setSelectedThread(response.data);
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
    setLoading(false);
  };
  return (
    <Row style={{ maxWidth: '100%', marginBottom: 10 }}>
      <Col span={24}>
        <Row>
          {message.contentType === 'text' && (
            <Col
              span={20}
              offset={message.sender === 'user' ? 4 : 0}
              style={{
                borderRadius: '0px 8px 8px 8px',
                border: '1px solid #E5E5E5',
                background: message.sender === 'assistant' ? '#FFF' : '#1890FF',
              }}
            >
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexShrink: 0,
                }}
              >
                {message.content !== '' ? (
                  <Typography.Text
                    style={{
                      padding: 10,
                      ...(message.sender === 'user' ? { color: '#FFF' } : {}),
                    }}
                  >
                    {message.content}
                  </Typography.Text>
                ) : (
                  <div
                    style={{ padding: 10 }}
                    className="loading-animation w-600"
                  >
                    {t('assistant.writing')}
                  </div>
                )}
              </Row>
            </Col>
          )}

          {message.contentType === 'options' && (
            <Row gutter={[8, 16]}>
              {message.content.map((opt) => (
                <Col>
                  <Button
                    key={opt.key}
                    onClick={() => handleOptionSelect(opt.key)}
                    style={{
                      borderRadius: '24px',
                      border: '1px solid var(--Neutral-5, #D9D9D9)',
                      background: 'var(--Neutral-1, #FFF)',
                      boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
                    }}
                  >
                    {opt.label}
                  </Button>
                </Col>
              ))}
            </Row>
          )}
        </Row>
      </Col>
    </Row>
  );
}

Message.propTypes = {
  threadId: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  message: PropTypes.shape({
    contentType: PropTypes.string,
    content: PropTypes.oneOfType([
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    sender: PropTypes.string,
  }).isRequired,
  setSelectedThread: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};
export default Message;

import { Buffer } from 'buffer';
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Col,
  Collapse,
  Card,
  Divider,
  Image,
  Row,
  Space,
  Tabs,
  Tooltip,
  Typography,
  Alert,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DownloadOutlined,
  DownOutlined,
  InfoCircleTwoTone,
  PrinterOutlined,
  PictureOutlined,
  UpOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { forEach } from 'lodash';
import InformationBox from './InformationBox';
import openNotification from '../../../../../components/Toastr';
import AmazonSvg from '../../../../../utils/icons/amazon.svg';
import { getErrorMessage } from '../../../../../api/api';
import FBMCouriers from '../../../../../utils/FBMCouriers';
import couriersAPI from '../../../../../api/courier';
import PrintLabelButton from '../../PrintLabelButton';
import CouriersIcons from '../../../../shipments/icons/CouriersIcons';
import DocumentUpload from './DocumentUpload';
import { translateErrors } from '../../../../../utils/functions';
import { uploadDocumentErrors } from '../../../../../utils/errors';
import shipmentsAPI from '../../../../../api/shipments';
import documentApi from '../../../../../api/document';

function ReviewLabelsDocuments({
  restockOrder,
  courierShipments,
  isNational,
  fdaProducts,
  commercialInvoiceRequirements,
}) {
  const { t } = useTranslation();
  const [courier, setCourier] = useState('');
  const [documentTypeUploading, setDocumentTypeUploading] = useState();
  const [courierShipmentIdUploading, setCourierShipmentIdUploading] =
    useState();
  const [documentUploaded, setDocumentUploaded] = useState();
  const [uploadingDocument, setUploadingDocument] = useState({
    COMMERCIAL_INVOICE: false,
    CERTIFICATE_OF_ORIGIN: false,
    PRIOR_NOTICE: false,
  });
  const [uploadStatus, setUploadStatus] = useState({});
  const [printLabelAmazon, setPrintLabelAmazon] = useState(false);
  const [selectedShipmentId, setSelectedShipmentId] = useState();
  const [activeKey, setActiveKey] = useState();
  const [amazonProducts, setAmazonProducts] = useState([]);
  const [printingLabels, setPrintingLabels] = useState(false);
  const [shipmentStatus, setShipmentStatus] = useState({ paid: false });
  const { Panel } = Collapse;

  useEffect(async () => {
    if (courierShipments?.length > 0) {
      setCourier(courierShipments[0].courierCompanyName);
      setActiveKey(courierShipments[0].id.toString());
      const shipmentDocumentsStatus = {};
      courierShipments.forEach((courierShipment) => {
        const status = {
          COMMERCIAL_INVOICE: courierShipment?.shipmentDocuments?.find(
            (doc) => doc.contentType === 'COMMERCIAL_INVOICE'
          )?.uploaded,
          CERTIFICATE_OF_ORIGIN: courierShipment?.shipmentDocuments?.find(
            (doc) => doc.contentType === 'CERTIFICATE_OF_ORIGIN'
          )?.uploaded,
          PRIOR_NOTICE: courierShipment?.shipmentDocuments?.find(
            (doc) => doc.contentType === 'OTHER'
          )?.uploaded,
        };
        shipmentDocumentsStatus[courierShipment.id] = status;
      });
      setUploadStatus(shipmentDocumentsStatus);
    }
  }, [courierShipments]);

  useEffect(async () => {
    const shipment = courierShipments?.filter(
      (s) => s.id.toString() === activeKey
    )[0];
    if (shipment) {
      const shipmentsStatusResponse = await shipmentsAPI.getShipmentStatus(
        shipment.courierCompanyName,
        shipment.transactionId
      );
      setShipmentStatus(shipmentsStatusResponse.data);
    }
  }, [activeKey]);

  const expandIcon = ({ isActive }) =>
    isActive ? (
      <DownOutlined className="icon-title" />
    ) : (
      <UpOutlined className="icon-title" />
    );

  const printDocumentsInfo = (
    <Col span={24}>
      <Typography.Text>
        {t('orders.documents.no_electronic_upload')}
      </Typography.Text>
      <ul>
        <li>
          {`${t('orders.documents.COMMERCIAL_INVOICE.label')} (${t(
            'orders.documents.COMMERCIAL_INVOICE.copies'
          )})`}
        </li>
        <li>
          {' '}
          {`${t('orders.documents.ORIGIN_CERTIFICATE.label')} (${t(
            'orders.documents.ORIGIN_CERTIFICATE.copies'
          )})`}
        </li>
      </ul>
    </Col>
  );

  const downloadCertificateOrigin = async (courierShipmentId) => {
    const response = await documentApi.get(restockOrder.id, courierShipmentId);
    const buffer = Buffer.from(response.data);
    const blob = new Blob([buffer]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = 'certificate_of_origin.xlsx';
    a.click();
  };

  const downloadCommercialInvoiceTemplate = async () => {
    const url =
      'https://fedex-documents.s3.amazonaws.com/FedEx-Commercial-Invoice.pdf';
    window.open(url, '_blank');
  };

  const beforeUpload = (file) => {
    setDocumentUploaded(file);
    return false;
  };

  const uploadDocument = async () => {
    if (
      courierShipmentIdUploading &&
      documentTypeUploading &&
      documentUploaded
    ) {
      const payload = {
        courierShipmentId: courierShipmentIdUploading,
        shipDocumentType: documentTypeUploading,
      };
      try {
        setUploadingDocument({
          ...uploadingDocument,
          [documentTypeUploading]: true,
        });
        await couriersAPI.uploadDocument(documentUploaded, payload, courier);
        setCourierShipmentIdUploading();
        setDocumentTypeUploading();
        setDocumentUploaded();
        setUploadingDocument({
          ...uploadDocument,
          [documentTypeUploading]: false,
        });
        setUploadStatus({
          ...uploadStatus,
          [courierShipmentIdUploading]: {
            ...uploadStatus[courierShipmentIdUploading],
            [documentTypeUploading]: true,
          },
        });
        openNotification({
          status: true,
          content: t('orders.shipment.uploadDocument.success'),
        });
      } catch (error) {
        setUploadingDocument({
          ...uploadDocument,
          [documentTypeUploading]: false,
        });
        const translatedError = translateErrors(error, uploadDocumentErrors);
        openNotification({
          status: false,
          info: t(translatedError.titleError),
          content: t(translatedError.error),
        });
      }
    }
  };

  useEffect(() => {
    uploadDocument();
  }, [documentUploaded]);

  const handleUpload = async (courierShipmentId, shipDocumentType) => {
    setDocumentTypeUploading(shipDocumentType);
    setCourierShipmentIdUploading(courierShipmentId.toString());
  };

  const printCourierLabels = async (trackingNumber) => {
    try {
      const response = await couriersAPI.getShipmentDocument(
        courier,
        trackingNumber
      );
      if (response.data?.length > 1) {
        response.data.map((doc) => {
          const downloadLink = document.createElement('a');
          downloadLink.target = '_blank';
          downloadLink.href = doc.url;
          downloadLink.click();
          return doc.url;
        });
      } else if (response.data !== null && response.data !== undefined) {
        const downloadLink = document.createElement('a');
        downloadLink.target = '_blank';
        downloadLink.href = response.data.url;
        downloadLink.click();
        return response.data.url;
      }
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
    return null;
  };

  const handlePrint = (courierShipment, selectedPrint, shipmentPlanId) => {
    setPrintingLabels(true);
    if (selectedPrint.includes('Amazon')) {
      if (shipmentPlanId) {
        setSelectedShipmentId(shipmentPlanId);
      } else {
        const shipmentRelationId = restockOrder.shipments.find(
          (shipment) => shipment.courierShipmentId === courierShipment.id
        );
        setSelectedShipmentId(shipmentRelationId?.shipmentPlanId);
      }
      setPrintLabelAmazon(true);
    } else {
      const trackingNumber = courierShipment.masterTrackingNumber;
      printCourierLabels(trackingNumber);
    }
    setPrintingLabels(false);
  };

  useEffect(() => {
    if (Object.keys(restockOrder).length !== 0 && activeKey) {
      const actualProducts = [];
      const shipmentRelationId = restockOrder.shipments.find(
        (shipment) => shipment.courierShipmentId === Number(activeKey)
      );
      const filteredRestockOrderBoxes = restockOrder.restockOrderBoxes.filter(
        (restockOrderBox) =>
          restockOrderBox.shipmentId === shipmentRelationId?.shipmentPlanId
      );
      forEach(filteredRestockOrderBoxes, (box) => {
        forEach(box.products, (product) => {
          const productExist = actualProducts.some((actualProduct) => {
            if (product.product.id === actualProduct.id) {
              return true;
            }
            return false;
          });

          if (!productExist) {
            actualProducts.push(product.product);
          }
        });
      });
      setAmazonProducts(actualProducts);
    }
  }, [restockOrder, activeKey]);

  const onChangeTab = (key) => {
    setActiveKey(key);
  };

  const renderLabels = (
    image,
    companyName,
    companyType,
    courierShipment,
    shipmentPlanId = null
  ) => {
    return (
      <Col>
        {!shipmentStatus?.paid && companyType === 'Courier' && (
          <Alert message={t('orders.shipment.unpaidAlert')} type="warning" />
        )}
        <Row justify="space-between" style={{ padding: '16px' }}>
          <Col span={15}>
            <Space>
              <img
                width="100%"
                src={image}
                alt={`img_${companyName}`}
                className="avatar-picture"
              />
              <Row>
                <Col span={24}>
                  <Typography.Text>{companyName}</Typography.Text>
                </Col>
                <Col span={24} className="SKU-product-contents-table">
                  {companyType}
                </Col>
              </Row>
            </Space>
          </Col>
          <Col>
            <Button
              className="ant-btn print-button icon-color"
              onClick={() => {
                handlePrint(courierShipment, companyName, shipmentPlanId);
              }}
              loading={printingLabels}
              disabled={!shipmentStatus?.paid && companyType === 'Courier'}
            >
              <DownloadOutlined /> {t('common.download')}
            </Button>
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <Collapse
      className="revision-restock boxes-shipment"
      expandIcon={expandIcon}
      defaultActiveKey={['labelsDocuments']}
    >
      <Panel
        key="labelsDocuments"
        header={
          <Typography.Text style={{ fontSize: 18, fontWeight: 500 }}>
            {t('orders.shipment.summary.labelsAndDocuments')}
          </Typography.Text>
        }
        style={{ backgroundColor: 'rgba(237, 245, 255, 1)' }}
      >
        <Tabs type="card" activeKey={activeKey} onChange={onChangeTab}>
          {courierShipments?.map((courierShipment, index) => (
            <Tabs.TabPane
              tab={`${t('common.destination')} N° ${index + 1}`}
              key={`${courierShipment.id}`}
            >
              <Row gutter={[12, 12]}>
                {Object.keys(restockOrder).length !== 0 && (
                  <Col span={24}>
                    <Card
                      size="small"
                      title={t('orders.shipment.summary.labelsForProducts')}
                      style={{ borderRadius: 4 }}
                    >
                      {amazonProducts?.map((product) => (
                        <Row
                          style={{ padding: '16px' }}
                          justify="space-between"
                        >
                          <Col span={15}>
                            <Space>
                              {product.productTemplate?.mainImage ? (
                                <Avatar
                                  shape="square"
                                  className="avatar-picture"
                                  src={
                                    <Image
                                      src={`${product.productTemplate?.mainImage}`}
                                    />
                                  }
                                />
                              ) : (
                                <Avatar
                                  shape="square"
                                  className="avatar-picture"
                                  icon={<PictureOutlined />}
                                />
                              )}
                              <Row>
                                <Col span={24}>
                                  <Tooltip
                                    placement="topLeft"
                                    title={product.title}
                                  >
                                    <Typography.Text className="title-product-contents-table">
                                      {product.title}
                                    </Typography.Text>
                                  </Tooltip>
                                </Col>
                                <Col
                                  span={24}
                                  className="SKU-product-contents-table"
                                >
                                  <Tooltip title={product.defaultCode}>
                                    SKU: {product.defaultCode}
                                  </Tooltip>
                                </Col>
                              </Row>
                            </Space>
                          </Col>
                          <Col>
                            <Button
                              className="print-button icon-color"
                              onClick={() => {
                                const url = `https://sellercentral.amazon.com/fba/printitemlabel/?mSku.0=${product.defaultCode}`;
                                window.open(url, '_blank');
                              }}
                            >
                              <PrinterOutlined /> {t('common.print')}
                            </Button>
                          </Col>
                        </Row>
                      ))}
                    </Card>
                  </Col>
                )}
                <Col span={24}>
                  <Card
                    size="small"
                    title={t('orders.shipment.summary.labelsForBoxesPallets')}
                    style={{ borderRadius: 4 }}
                  >
                    {Object.keys(restockOrder).length !== 0 && (
                      <>
                        {renderLabels(
                          AmazonSvg,
                          `Amazon ${restockOrder.destination}`,
                          'Centro de fulfillment',
                          courierShipment
                        )}
                        <Divider />
                      </>
                    )}
                    {renderLabels(
                      CouriersIcons(courierShipment.courierCompanyName),
                      courierShipment.courierCompanyName,
                      'Courier',
                      courierShipment
                    )}
                    {courierShipment.courierCompanyName ===
                      FBMCouriers.FEDEX && (
                      <div style={{ padding: '0px 16px 16px' }}>
                        {commercialInvoiceRequirements &&
                          commercialInvoiceRequirements.documentGeneration && (
                            <InformationBox
                              icon={<InfoCircleTwoTone />}
                              status="processing"
                              textInformationBox={
                                <Typography.Text>
                                  {t(
                                    'orders.documents.COMMERCIAL_INVOICE_INCLUDED'
                                  )}
                                </Typography.Text>
                              }
                              display="block"
                            />
                          )}
                      </div>
                    )}
                  </Card>
                </Col>
                <Col span={24}>
                  {!isNational &&
                    commercialInvoiceRequirements &&
                    !commercialInvoiceRequirements.electronicDocumentsAllowed && (
                      <InformationBox
                        icon={<InfoCircleOutlined />}
                        status="warning"
                        display="block"
                        textInformationBox={printDocumentsInfo}
                      />
                    )}
                  {!isNational && (
                    <Card
                      size="small"
                      title="Documentos opcionales"
                      style={{ borderRadius: 4 }}
                    >
                      <Row
                        span={24}
                        align="top"
                        className="RestockEnvioContenido"
                        style={{ paddingBottom: 0 }}
                      >
                        <Col span={24}>
                          {courierShipment.courierCompanyName ===
                            FBMCouriers.FEDEX && (
                            <>
                              <DocumentUpload
                                onClick={() =>
                                  handleUpload(
                                    courierShipment.id,
                                    'CERTIFICATE_OF_ORIGIN'
                                  )
                                }
                                beforeUpload={beforeUpload}
                                loading={
                                  uploadingDocument.CERTIFICATE_OF_ORIGIN
                                }
                                uploaded={
                                  uploadStatus[courierShipment.id]
                                    ?.CERTIFICATE_OF_ORIGIN
                                }
                                title={
                                  <Typography.Text>
                                    {t(
                                      'orders.documents.ORIGIN_CERTIFICATE.label'
                                    )}
                                  </Typography.Text>
                                }
                                downloadDocument={
                                  Object.keys(restockOrder).length !== 0
                                }
                                downloadDocumentOnClick={() => {
                                  downloadCertificateOrigin(courierShipment.id);
                                }}
                              />
                              {commercialInvoiceRequirements &&
                                !commercialInvoiceRequirements.documentGeneration && (
                                  <>
                                    <Divider />
                                    <DocumentUpload
                                      onClick={() =>
                                        handleUpload(
                                          courierShipment.id,
                                          'COMMERCIAL_INVOICE'
                                        )
                                      }
                                      beforeUpload={beforeUpload}
                                      loading={
                                        uploadingDocument.COMMERCIAL_INVOICE
                                      }
                                      title={
                                        <Typography.Text>
                                          {t(
                                            'orders.documents.COMMERCIAL_INVOICE.label'
                                          )}
                                        </Typography.Text>
                                      }
                                      uploaded={
                                        uploadStatus[courierShipment.id]
                                          ?.COMMERCIAL_INVOICE
                                      }
                                      downloadDocument
                                      downloadDocumentOnClick={
                                        downloadCommercialInvoiceTemplate
                                      }
                                    />
                                  </>
                                )}
                              {fdaProducts.length > 0 && (
                                <>
                                  <Divider />
                                  <DocumentUpload
                                    onClick={() =>
                                      handleUpload(courierShipment.id, 'OTHER')
                                    }
                                    beforeUpload={beforeUpload}
                                    loading={uploadingDocument.PRIOR_NOTICE}
                                    uploaded={
                                      uploadStatus[courierShipment.id]?.OTHER
                                    }
                                    title={
                                      <Typography.Text>
                                        {t(
                                          'orders.documents.PRIOR_NOTICE.label'
                                        )}
                                      </Typography.Text>
                                    }
                                  />
                                </>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </Card>
                  )}
                </Col>
              </Row>
            </Tabs.TabPane>
          ))}
          {(!courierShipments || courierShipments.length === 0) &&
            Object.keys(restockOrder).length !== 0 && (
              <Tabs.TabPane
                tab={`${t('common.destination')} N° 1`}
                key={`${restockOrder.id}`}
              >
                <Row gutter={[12, 12]}>
                  {Object.keys(restockOrder).length !== 0 && (
                    <Col span={24}>
                      <Card
                        size="small"
                        title={t('orders.shipment.summary.labelsForProducts')}
                        style={{ borderRadius: 4 }}
                      >
                        {amazonProducts?.map((product) => (
                          <Row
                            style={{ padding: '16px' }}
                            justify="space-between"
                          >
                            <Col span={15}>
                              <Space>
                                {product.productTemplate?.mainImage ? (
                                  <Avatar
                                    shape="square"
                                    className="avatar-picture"
                                    src={
                                      <Image
                                        src={`${product.productTemplate?.mainImage}`}
                                      />
                                    }
                                  />
                                ) : (
                                  <Avatar
                                    shape="square"
                                    className="avatar-picture"
                                    icon={<PictureOutlined />}
                                  />
                                )}
                                <Row>
                                  <Col span={24}>
                                    <Tooltip
                                      placement="topLeft"
                                      title={product.title}
                                    >
                                      <Typography.Text className="title-product-contents-table">
                                        {product.title}
                                      </Typography.Text>
                                    </Tooltip>
                                  </Col>
                                  <Col
                                    span={24}
                                    className="SKU-product-contents-table"
                                  >
                                    <Tooltip title={product.defaultCode}>
                                      SKU: {product.defaultCode}
                                    </Tooltip>
                                  </Col>
                                </Row>
                              </Space>
                            </Col>
                            <Col>
                              <Button
                                className="print-button icon-color"
                                onClick={() => {
                                  const url = `https://sellercentral.amazon.com/fba/printitemlabel/?mSku.0=${product.defaultCode}`;
                                  window.open(url, '_blank');
                                }}
                              >
                                <PrinterOutlined /> {t('common.print')}
                              </Button>
                            </Col>
                          </Row>
                        ))}
                      </Card>
                    </Col>
                  )}
                  <Col span={24}>
                    <Card
                      size="small"
                      title={t('orders.shipment.summary.labelsForBoxes')}
                      style={{ borderRadius: 4 }}
                    >
                      {restockOrder.shipmentPlan.InboundShipmentPlans.forEach(
                        (inboundShipmentPlan) => (
                          <>
                            {renderLabels(
                              AmazonSvg,
                              'Amazon US',
                              'Centro de fulfillment',
                              null,
                              inboundShipmentPlan.ShipmentId
                            )}
                            <Divider />
                          </>
                        )
                      )}
                    </Card>
                  </Col>
                </Row>
              </Tabs.TabPane>
            )}
        </Tabs>
      </Panel>
      <PrintLabelButton
        printLabelAmazon={printLabelAmazon}
        setPrintLabelAmazon={setPrintLabelAmazon}
        order={restockOrder}
        shipmentId={selectedShipmentId}
        partnered={false}
      />
    </Collapse>
  );
}

ReviewLabelsDocuments.propTypes = {
  restockOrder: PropTypes,
  courierShipments: PropTypes.isRequired,
  isNational: PropTypes.bool.isRequired,
  fdaProducts: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  commercialInvoiceRequirements: PropTypes.shape({
    electronicDocumentsAllowed: PropTypes.bool,
    documentGeneration: PropTypes.bool,
    letterheadRequired: PropTypes.bool,
    signatureRequired: PropTypes.bool,
  }).isRequired,
};

ReviewLabelsDocuments.defaultProps = {
  restockOrder: {},
};

export default ReviewLabelsDocuments;

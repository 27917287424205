import React from 'react';
import { Card, Typography, Tooltip, Spin } from 'antd';
import PropTypes from 'prop-types';
import {
  InfoCircleOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { thousandsSeparator } from '../../../utils/functions';
import 'antd/dist/antd.min.css';
import './cardData.css';
import selectors from '../../../redux/analytic/dashboard/selectors';

const { Title } = Typography;

function CardData({
  title,
  tooltipTitle,
  loader,
  data,
  currency,
  percentage,
  normalPercentageColor = true,
}) {
  const { t } = useTranslation();
  const currencyState = useSelector(selectors.selectCurrency);

  let valueTitle = '';
  let arrowIcon = null;
  let arrowColor = '';

  if (!loader) {
    if (Number(data?.percentage) < 0) {
      valueTitle = data?.data > 0 ? thousandsSeparator(data?.data) : 0;
      valueTitle += currency ? ` ${currencyState}` : '';
      valueTitle += percentage ? '%' : '';

      arrowColor = normalPercentageColor ? '#F25154' : '#33BA6A';
      arrowIcon = (
        <ArrowDownOutlined
          id={normalPercentageColor ? 'arrowVariantRed' : 'arrowVariantGreen'}
        />
      );
    } else if (Number(data?.percentage) > 0) {
      valueTitle = data?.data > 0 ? thousandsSeparator(data?.data) : 0;
      valueTitle += currency ? ` ${currencyState}` : '';
      valueTitle += percentage ? '%' : '';

      arrowColor = normalPercentageColor ? '#33BA6A' : '#F25154';
      arrowIcon = (
        <ArrowUpOutlined
          id={normalPercentageColor ? 'arrowVariantGreen' : 'arrowVariantRed'}
        />
      );
    } else {
      valueTitle = data?.data > 0 ? thousandsSeparator(data?.data) : 0;
      valueTitle += currency ? ` ${currencyState}` : '';
      valueTitle += percentage ? '%' : '';

      arrowIcon = null;
      arrowColor = 'grey';
    }
  }

  const valueElement = (
    <Title
      style={{ color: '#010c33', fontSize: 25, fontStyle: 'normal' }}
      level={3}
    >
      {valueTitle}
    </Title>
  );

  let percentageValue = 0;
  if (Number(data?.percentage)) {
    if (Number(data?.percentage) !== 'Infinity') {
      if (Number(data?.percentage) < 0) {
        percentageValue = -Number(data?.percentage);
      } else {
        percentageValue = Number(data?.percentage);
      }
    }
  }

  const percentageElement = (
    <Title
      style={{
        fontStyle: 'normal',
        fontSize: 17,
        marginTop: 5,
        fontWeight: 600,
        color: arrowColor,
      }}
      level={4}
    >
      {arrowIcon}
      {percentageValue}%
    </Title>
  );

  return (
    <Card
      bordered={false}
      style={{ marginRight: 10, marginBottom: 15, borderRadius: 0 }}
    >
      <div
        className={`container-title${
          title === t('marketing.indexGraphics.totalSales.title')
            ? ' step6Dashboards'
            : ''
        }`}
      >
        <span>
          <Title
            level={2}
            style={{
              fontSize: 14,
              fontWeight: 400,
              fontStyle: 'normal',
              color: '#010C33',
              textAlign: 'left',
            }}
          >
            {title}
          </Title>
        </span>
        <Tooltip title={tooltipTitle} placement="top">
          <InfoCircleOutlined style={{ marginTop: 2 }} />
        </Tooltip>
      </div>
      {!loader ? (
        <>
          {valueTitle !== '' && valueElement}
          {percentageElement}
        </>
      ) : (
        <Card>
          <center>
            <Spin size="large" />
          </center>
        </Card>
      )}
    </Card>
  );
}

export default CardData;

CardData.propTypes = {
  title: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
  loader: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  currency: PropTypes.bool.isRequired,
  percentage: PropTypes.bool.isRequired,
  normalPercentageColor: PropTypes.bool.isRequired,
};

import axios from 'axios';
import i18n from 'i18next';
import refreshSession from './refreshSession';
import * as Actions from '../redux/session/action';

const apiUrl = process.env.REACT_APP_BASE_URL_BACKEND;

const instance = axios.create({
  baseURL: apiUrl,
});

axios.defaults.withCredentials = true;
instance.defaults.withCredentials = false;

export const initAxiosInterceptors = (store) => {
  instance.interceptors.request.use(async (config) => {
    const newConfig = config;
    const { session, language } = store.getState().Session;
    if (session?.token) {
      newConfig.headers.Authorization = `Bearer ${session.token}`;
    }
    if (language) {
      newConfig.headers.lang = language;
    }
    return newConfig;
  });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { session } = store.getState().Session;
      const { dispatch } = store;
      const config = error?.config;
      if (error?.response?.status === 401 && !config?.retry) {
        config.retry = true;
        const result = await refreshSession(instance, session);
        try {
          if (result?.token) {
            config.headers = {
              ...config.headers,
              Authorization: `Bearer ${result?.token}`,
            };
            dispatch(Actions.refreshSession(result));
            return instance(config);
          }
        } catch (_error) {
          window.location = '/login';
          return Promise.reject(_error);
        }
      }
      return Promise.reject(error);
    }
  );
};

export function getErrorMessage(error) {
  if (error?.response?.data?.message) {
    return error.response.data.message;
  }
  if (error?.message) {
    return error.message;
  }
  return i18n.t('common.defaultErrorMessage');
}

export default instance;

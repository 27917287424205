import { SmileOutlined, FrownOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import i18n from 'i18next';

function openNotification({ info, content, status, duration, helpUrl }) {
  const failedRequest = i18n.t('common.failedRequest');
  const successfulRequest = i18n.t('common.successfulRequest');
  const wantKnowMore = i18n.t('common.wantKnowMore');

  return status
    ? notification.success({
        style: { zIndex: 'inherit' },
        duration: duration || 6,
        message: info || 'Éxito',
        description: content || successfulRequest,
        icon: (
          <SmileOutlined
            className="success"
            style={{ color: '#389e0d !important' }}
          />
        ),
      })
    : notification.error({
        style: { zIndex: 'inherit' },
        duration: duration || 0,
        message: info || 'Error',
        description: content || failedRequest,
        ...(helpUrl
          ? {
              btn: (
                <Button
                  style={{ color: '#00e5a6' }}
                  type="link"
                  href={helpUrl}
                  target="_blank"
                >
                  {wantKnowMore}
                </Button>
              ),
            }
          : {}),
        icon: (
          <FrownOutlined
            className="error"
            style={{ color: '#F81D22 !important' }}
          />
        ),
      });
}

export default openNotification;

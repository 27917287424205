import api from './api';

const prefix = 'product-templates';

const productTemplateApi = {
  buildProductsTree(params) {
    return api.get(`${prefix}/buildProductsTree`, { params });
  },
  uploadProductTemplate(payload) {
    return api.post(`${prefix}/`, payload);
  },
};

export default productTemplateApi;

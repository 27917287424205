/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { useHistory, useLocation } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Spin,
  Typography,
  Checkbox,
  Modal,
} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useStore } from 'react-redux';
import * as queryString from 'query-string';
import AuthApi from '../../../api/auth';
import * as Actions from '../../../redux/session/action';
import {
  keyPressSpaceBar,
  mixPanelCreateEvent,
} from '../../../utils/functions';

import './login.css';
import ResetPassword from '../ResetPassword/ResetPassword';
import { initAxiosInterceptors } from '../../../api/api';

const { Item } = Form;

function Login() {
  const { t } = useTranslation();

  const store = useStore();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { Title, Text } = Typography;
  const [form] = Form.useForm();
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });
  const [showRecoverPassword, setShowRecoverPassword] = useState(false);

  const [credentialsError, setCredentialsError] = useState(false);
  const qs = queryString.parse(location.search);
  let learnworldsLogin = false;
  if (['login', 'passwordreset'].includes(qs.action) && qs.redirectUrl) {
    learnworldsLogin = true;
  } else if (['signup'].includes(qs.action) && qs.redirectUrl) {
    history.replace('/register');
  }

  const passwordIconRender = (visible) =>
    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value.trim(),
    });
  };

  const login = async (creds) => {
    const loginPayload = creds;
    setLoading(true);
    dispatch(Actions.login());

    if (qs && qs.link) {
      loginPayload.link = qs.link;
    }
    try {
      const response = await AuthApi.login(loginPayload);
      initAxiosInterceptors(store);
      if (response.status === 200 && response.data.success) {
        dispatch(Actions.loginSuccess(response.data));
        dispatch(Actions.updateLanguague(i18next.language.split('-')[0]));
        mixpanel.identify(response.data?.userInfo?.login);
        if (window._cio) {
          window._cio.identify({
            id: response.data?.userInfo.id,
            email: response.data?.userInfo?.login,
            name: response.data?.userInfo?.name,
            created_at: parseInt(
              new Date(response.data?.userInfo?.createdAt).getTime() / 1000,
              10
            ),
            last_succesful_login: Math.floor(Date.now() / 1000),
          });
        }
        mixpanel.people.set({ $name: response.data?.userInfo?.name });
        mixpanel.people.set({ $email: response.data?.userInfo?.login });
        mixpanel.people.set({ $createdAt: response.data?.userInfo.createdAt });
        mixpanel.people.set({ $companyActive: response.data?.companyActive });
        mixpanel.people.set({
          $subscriptionType: response.data?.userInfo?.subscription?.type || '',
        });
        mixpanel.people.set({
          $subscriptionName: response.data?.userInfo?.subscription?.name || '',
        });
        mixPanelCreateEvent('Successful login');
      } else {
        dispatch(Actions.loginFailed(response.data.message));
        setLoading(false);
      }
      if (learnworldsLogin) {
        const learnWorldsResponse = await AuthApi.learnworlds({
          redirectUrl: qs.redirectUrl,
        });
        window.location.href = learnWorldsResponse.data.url;
      } else {
        history.push('/');
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        setCredentialsError({ enable: true });
        dispatch(Actions.loginFailed(t('login.IncorrectEmailOrPassword')));
        setLoading(false);
      } else {
        dispatch(Actions.loginFailed(error?.response?.data?.message));
        setLoading(false);
      }
    }
  };

  const plainOptions = [t('login.rememberPassword')];

  const showModal = () => {
    setCredentialsError(true);
  };

  const handleOk = () => {
    setCredentialsError(false);
  };

  const handleCancel = () => {
    setCredentialsError(false);
  };

  useEffect(() => {
    if (credentialsError) {
      showModal();
    }
  }, [credentialsError]);

  return (
    <div className="login-container">
      <Row className="login-layout">
        <Col xs={0} sm={12} span={12} className="login-left">
          <div className="login-content-texts">
            <div className="login-logo-container">
              <Title
                style={{ textAlign: 'left', color: 'white' }}
                white
                level={2}
              >
                {t('login.header.0')}{' '}
                <span style={{ color: '#00e5a6' }}>{t('login.header.1')}</span>
              </Title>
              <hr className="login-hr-texts" />
              <Text
                style={{ textAlign: 'left', color: 'white', marginRight: 22 }}
                className="login-title"
              >
                {t('login.header.2')}
              </Text>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} span={12}>
          <div className="login-content-inputs">
            <Spin spinning={loading}>
              <div className="login-logo-container">
                <Title className="login-title-right" level={2}>
                  {t('login.formHeader.0')}
                </Title>
                {learnworldsLogin ? (
                  <Text className="login-subtitle-right">
                    {t('login.formHeader.1.lapAcademy')}
                  </Text>
                ) : (
                  <Text className="login-subtitle-right">
                    {t('login.formHeader.1.normal')}
                  </Text>
                )}
              </div>
              <Form
                name="formulario"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: -3,
                }}
                layout="vertical"
                onChange={() => {
                  if (credentialsError.enable) {
                    setCredentialsError({ enable: false });
                  }
                }}
                onFinish={() => login({ ...credentials })}
                form={form}
              >
                <Item
                  className="login-email-item"
                  label={t('login.email')}
                  name="email"
                  rules={[
                    {
                      required: false,
                      message: t('login.emailEmpty'),
                    },
                    {
                      type: 'email',
                      message: t('login.invalidEmail'),
                    },
                  ]}
                >
                  <Input
                    className="login-input-item"
                    name="username"
                    type="email"
                    placeholder={t('login.emailPlaceholder')}
                    onChange={(e) => handleChange(e)}
                    onKeyPress={(e) => keyPressSpaceBar(e)}
                  />
                </Item>
                <Item
                  className="login-password-item"
                  label={t('login.password')}
                  name="password"
                  validateTrigger="onSubmit"
                  rules={[
                    {
                      required: false,
                      message: t('login.passwordEmpty'),
                    },
                  ]}
                >
                  <Input.Password
                    className="login-input-item"
                    name="password"
                    type="password"
                    placeholder={`@${t('login.password')}`}
                    onChange={(e) => handleChange(e)}
                    onKeyPress={(e) => keyPressSpaceBar(e)}
                    iconRender={passwordIconRender}
                  />
                </Item>
                <Col
                  span={24}
                  className="login-button-container CheckboxRememberPassword"
                >
                  <Checkbox.Group
                    className="CheckboxRememberPassword"
                    options={plainOptions}
                    defaultValue={['Apple']}
                  />
                  <Button
                    style={{ marginLeft: -1 }}
                    loading={loading}
                    className="btn-primary-green md-btn login"
                    htmlType="submit"
                    disabled={!credentials.username || !credentials.password}
                  >
                    {t('login.enter')}
                  </Button>
                </Col>
                <Col
                  span={24}
                  className="login-button-container"
                  style={{ textAlign: 'center' }}
                >
                  <Button
                    type="link"
                    className="ant-btn-link"
                    onClick={() => setShowRecoverPassword(true)}
                  >
                    {t('login.recoverPassword')}
                  </Button>
                </Col>
                <Col
                  span={24}
                  className="signup-button-container"
                  style={{ textAlign: 'center' }}
                >
                  {!learnworldsLogin ? (
                    <Button
                      type="link"
                      className="ant-btn-link"
                      onClick={() => history.push('/register')}
                    >
                      {`${t('login.signUp')}`}
                    </Button>
                  ) : (
                    <div />
                  )}
                </Col>
              </Form>
              <ResetPassword
                open={showRecoverPassword}
                setOpen={setShowRecoverPassword}
              />
            </Spin>
          </div>
        </Col>
      </Row>
      <Modal
        open={credentialsError}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={400}
        footer={null}
        closeIcon="✕"
        destroyOnClose
      >
        <div className="wrongCredentialsTitle">{t('login.loginError')}</div>
        <br />
        <div className="wrongCredentialsModal">
          {t('login.IncorrectEmailOrPassword')}
          <br /> <br />
          <b>{t('login.tryAgain')}</b>
          <br /> <br />
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
            id="wrongCredentialsButton"
            size="large"
          >
            <i>{t('common.ok')}</i>
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Login;

const identifyCustomer = (window, userInfo, additionalColumns) => {
  // eslint-disable-next-line no-underscore-dangle
  const cio = window._cio || window.cio;
  if (cio) {
    cio.identify({
      id: userInfo.id,
      email: userInfo.login,
      name: userInfo.name,
      created_at: parseInt(new Date(userInfo.createdAt).getTime() / 1000, 10),
      ...additionalColumns,
    });
  }
};

export default identifyCustomer;

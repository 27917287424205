import api from './api';

const prefix = '/fulfillment';
const fulfillmentAPI = {
  getFboCompanyNames: () => api.get(`${prefix}/fbo-company-names`),
  getMarkeplaceFulfillmentCompanies: (marketplace) =>
    api.get(`${prefix}/fbo-company-names/${marketplace}`),
  getMarkeplaceFBMCompaniesFBO: (marketplace) =>
    api.get(`${prefix}/fbm-fbo-company/${marketplace}`),
  getFulfillmentByPartnerAndMarketplace: (marketplace) =>
    api.get(`${prefix}/fulfillment-by-partner-and-marketplace/${marketplace}`),
  updateProductFulfillment: (payload) => {
    return api.post(`${prefix}/product/fulfillment`, payload);
  },
  updateCourierShipmentTypes: (courier, payload) =>
    api.post(`${prefix}/${courier}/types/shipment`, payload),
  getFulfill99Permissions: () => api.get(`${prefix}/fulfill99/permissions`),
  getWarehouses: (courier) => api.get(`${prefix}/warehouse/${courier}`),
  createCourierCredential: (payload) =>
    api.post(`${prefix}/courierCredential`, payload),
  getCourierShipmentTypes: (fulfillmentCompany) =>
    api.get(`${prefix}/${fulfillmentCompany}/types/shipment`),
};
export default fulfillmentAPI;

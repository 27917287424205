import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import accessKeysApi from '../../../../api/aws-access-keys';
import openNotification from '../../../../components/Toastr';
import * as Actions from '../../../../redux/session/action';

const { Item } = Form;

export default function DafitiSellerAcount(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loadingIsValidAccount, setLoadingIsValidAccount] = useState(false);
  const { tab, mySellerAccount, session } = props;

  useEffect(() => {
    if (mySellerAccount) {
      form.setFieldsValue(mySellerAccount?.credentials);
    }
  }, [mySellerAccount]);

  const saveCredentials = (credentials) => {
    setLoadingIsValidAccount(true);
    accessKeysApi
      .saveCredentials(
        session?.userInfo?.commercial_partner_id[0],
        tab,
        credentials
      )
      .then(() => {
        Modal.success({
          content: 'Se asocio la cuenta exitosamente',
        });
        dispatch(Actions.updateSellerAccountStatusSession(true));
        setLoadingIsValidAccount(false);
      })
      .catch(() => {
        setLoadingIsValidAccount(false);
        openNotification({
          status: false,
          content: 'No se pudo validar la cuenta',
        });
      });
  };

  return (
    <Form
      name="walmart-form"
      labelCol={{
        span: 5,
      }}
      wrapperCol={{
        span: 19,
      }}
      form={form}
      onFinish={saveCredentials}
    >
      <Col xs={24} sm={24} md={24}>
        <Item
          style={{ margin: 20 }}
          label="País"
          name="country"
          rules={[{ required: true }]}
        >
          <Select>
            <Select.Option key="CL" value="CL">
              Chile
            </Select.Option>
          </Select>
        </Item>
        <Item
          style={{ margin: 20 }}
          label="Client Id"
          name="clientId"
          rules={[{ required: true }]}
        >
          <Input />
        </Item>
        <Item
          style={{ margin: 20 }}
          label="Client Key"
          name="clientSecret"
          rules={[{ required: true }]}
        >
          <Input />
        </Item>
      </Col>
      <Row xs={24} sm={24} md={24} style={{ justifyContent: 'end' }}>
        <Button
          type="primary"
          style={{ marginLeft: 40 }}
          htmlType="submit"
          loading={loadingIsValidAccount}
        >
          {t('myAccount.checkSP')}
        </Button>
      </Row>
    </Form>
  );
}

DafitiSellerAcount.propTypes = {
  tab: PropTypes.string.isRequired,
  mySellerAccount: PropTypes.instanceOf(Object).isRequired,
  session: PropTypes.instanceOf(Object).isRequired,
};

import { Button, Card, Col, Row, Typography, Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import fbaLogo from '../../../assets/fbaLogo.png';

function FBA({ hasCredentials }) {
  const { t } = useTranslation();
  const { Text } = Typography;

  return (
    <Card
      style={{
        borderRadius: '10px',
        marginBottom: '18px',
      }}
      className="courierAvatarCard"
    >
      <Row justify="space-between">
        <div style={{ display: 'flex' }}>
          <Col>
            <Card
              style={{ width: 100, textAlign: 'center' }}
              className="courierAvatarImageCard"
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '90px',
                }}
              >
                <img
                  width="90px"
                  src={fbaLogo}
                  style={{ padding: '10px' }}
                  alt={`img_${'Fulfillment By Amazon'}`}
                />
              </div>
            </Card>
          </Col>

          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Text style={{ marginLeft: '20px', fontSize: '16px' }}>
              Fulfillment By Amazon
            </Text>
            <Space>
              <br />
              <Col>
                <Tooltip title={t('logistics.tooltip.FBA')}>
                  <InfoCircleOutlined />
                </Tooltip>
              </Col>
            </Space>
          </Col>
        </div>

        <div style={{ display: 'flex' }}>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Button
              style={{
                backgroundColor: `${hasCredentials ? '#33BA6A' : '#CACACA'}`,
                color: `${hasCredentials ? 'white' : 'black'}`,
                pointerEvents: 'none',
                width: '150px',
                height: '42px',
              }}
              className={
                hasCredentials ? 'courierConnected' : 'courierNotConnected'
              }
            >
              {hasCredentials ? 'Conectado' : 'No conectado'}
            </Button>
          </Col>
        </div>
      </Row>
    </Card>
  );
}

FBA.propTypes = {
  hasCredentials: PropTypes.bool,
};

FBA.defaultProps = {
  hasCredentials: false,
};

export default FBA;

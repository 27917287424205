import {
  Button,
  Checkbox,
  Col,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Timeline,
  Tooltip,
  Typography,
  Upload,
  List,
  Popover,
} from 'antd';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Joyride from 'react-joyride';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import openNotification from '../../../components/Toastr';
import listingsApi from '../../../api/listing';
import { getErrorMessage } from '../../../api/api';
import categoryApi from '../../../api/aws-category';
import importApi from '../../../api/import';
import {
  stage3Modal1,
  stage3Modal2,
  stage3Modal3,
  stage3Modal4,
  stage3Modal5,
} from '../../../stepsOnboarding';
import { updateOnboarding } from '../../../redux/onboarding/action';

import './publishProductsModal.css';

const { Paragraph } = Typography;

function PublishProductsModal({
  marketplaceToPublish,
  setMarketplaceToPublish,
  selectedListings,
  originMarketplace,
  all,
  total,
}) {
  const [modalLoading, setIsModalLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [productTypes, setProductTypes] = useState([]);
  const [uploadFile, setUploadFile] = useState(null);
  const [canSelectCategory, setCanSelectCategory] = useState(false);
  const [firstStepsVisible, setFirstStepsVisible] = useState(false);
  const [errorsUploadFile, setErrorsUploadFile] = useState('');
  const [warningModalStatus, setWarningModalStatus] = useState(false);
  const [autocompleteColumns, setAutocompleteColumns] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onboardingStatus = useSelector((store) => store.Onboarding);
  const { Text } = Typography;

  const processingLimit = 5;

  const handleModalOk = () => {
    setMarketplaceToPublish(null);
    setAutocompleteColumns(false);
  };

  const handleModalCancel = () => {
    setCurrentCategory(null);
    setProductTypes([]);
    setMarketplaceToPublish(null);
    setAutocompleteColumns(false);
  };

  const showWarningModal = () => {
    setWarningModalStatus(true);
  };

  const handleOkWarning = () => {
    setWarningModalStatus(false);
  };

  const handleCancelWarning = () => {
    setWarningModalStatus(false);
  };

  const handleProductTypeChange = (newValue) => {
    const newValueArray = JSON.parse(newValue);
    if (!marketplaceToPublish?.includes('Amazon')) {
      setCurrentCategory(
        productTypes.find(
          (type) =>
            type.value.productType === newValueArray.productType &&
            type.value.completeName === newValueArray.completeName
        )
      );
    } else if (newValueArray.supported === 0) {
      showWarningModal();
    } else {
      const supportedCategories = productTypes.find(
        (category) =>
          category.label === t('catalogue.publishProductsModal.uploadLAP')
      );
      const processedCategories = supportedCategories?.options.map((item) => ({
        ...item,
        value: JSON.parse(item.value),
      }));
      setCurrentCategory(
        processedCategories?.find(
          (type) =>
            type.value.productType === newValueArray.productType &&
            type.value.completeName === newValueArray.completeName
        )
      );
    }
  };

  useEffect(() => {
    if (!marketplaceToPublish?.includes('Amazon')) {
      setCanSelectCategory(true);
      setFirstStepsVisible(false);
    } else if (marketplaceToPublish) {
      setCurrentCategory(null);
      setProductTypes([]);
      importApi
        .getCompletedImports(marketplaceToPublish)
        .then((result) => {
          if (result?.data?.length === 0) {
            setFirstStepsVisible(true);
            setCanSelectCategory(false);
          } else {
            setFirstStepsVisible(false);
            setCanSelectCategory(true);
          }
        })
        .catch((error) => {
          openNotification({ status: false, content: getErrorMessage(error) });
        });
    }
  }, [marketplaceToPublish]);

  const handleDownload = async () => {
    const params = {};
    const body = {};
    if (marketplaceToPublish) {
      body.marketplace = marketplaceToPublish;
    }
    if (currentCategory) {
      body.productType = currentCategory.value.productType;
      body.categoryName = currentCategory.text;
    }
    if (total > 0) {
      body.productIds = selectedListings;
    }
    if (originMarketplace && originMarketplace !== 'groupedMarketplaces') {
      body.originMarketplace = originMarketplace;
    }
    if (autocompleteColumns) params.autocompleteColumns = true;
    if (all) body.all = all;
    setIsModalLoading(true);
    return listingsApi
      .buildUploadSheet(params, [body])
      .catch((error) => {
        if (
          error.response?.status === 400 &&
          marketplaceToPublish?.includes('Amazon')
        )
          openNotification({
            status: false,
            content: t('products.errors.categoryNotAllowed'),
            helpUrl:
              'https://ayuda.lapmarketplace.com/hc/es-cl/articles/16428729142555',
          });
        else
          openNotification({
            status: false,
            content: getErrorMessage(error),
          });
      })
      .finally(() => {
        setIsModalLoading(false);
      });
  };

  const handleProductTypeSearch = (newValue) => {
    if (newValue) {
      categoryApi
        .getByMarketplaceAndSearch(marketplaceToPublish, newValue)
        .then((response) => {
          if (!marketplaceToPublish?.includes('Amazon')) {
            setProductTypes(
              response.data.map((item) => ({
                value: {
                  productType: item.identifier,
                  completeName: item.completeName,
                },
                text: item.completeName,
              }))
            );
          } else {
            const processedResponse = response.data?.map((item) => ({
              value: JSON.stringify({
                productType: item.productType,
                completeName: item.completeName,
                supported: item.supported,
              }),
              label: item.completeName,
              text: item.completeName,
              supported: item.supported,
            }));
            setProductTypes([
              {
                label: t('catalogue.publishProductsModal.uploadLAP'),
                options: processedResponse.filter(
                  (category) => category.supported === 1
                ),
              },
              {
                label: t('catalogue.publishProductsModal.uploadAmazon'),
                options: processedResponse.filter(
                  (category) => category.supported === 0
                ),
              },
            ]);
          }
        });
    } else {
      setProductTypes([]);
    }
  };

  const debouncedHandleProductTypeSearch = debounce(
    handleProductTypeSearch,
    500
  );

  const handleUpload = async () => {
    setIsModalLoading(true);
    listingsApi
      .uploadProductsSheet(uploadFile)
      .then(() => {
        window.location = '/imports';
      })
      .catch((error) => {
        let content = error?.message;
        if (error?.response?.data?.message) {
          content = error.response.data.message;
        }
        setErrorsUploadFile(content);
      })
      .finally(() => {
        setIsModalLoading(false);
      });
  };

  const firstStepsOptions = [
    {
      primaryText: t(
        'catalogue.publishProductsModal.firstStepsCheckbox.activeSellerAccount.primaryText'
      ),
      secondaryText: t(
        'catalogue.publishProductsModal.firstStepsCheckbox.activeSellerAccount.secondaryText'
      ),
      seeMoreLink:
        'https://ayuda.lapmarketplace.com/hc/es-cl/articles/15931612600347',
    },
    {
      primaryText: t(
        'catalogue.publishProductsModal.firstStepsCheckbox.brandApproved.primaryText'
      ),
      seeMoreLink:
        'https://ayuda.lapmarketplace.com/hc/es-cl/articles/15468932297755-Solicitar-marca-para-categor%C3%ADa-y-exenci%C3%B3n-de-GTIN',
    },
    {
      primaryText: t(
        'catalogue.publishProductsModal.firstStepsCheckbox.productsBarCode.primaryText'
      ),
      secondaryText: t(
        'catalogue.publishProductsModal.firstStepsCheckbox.productsBarCode.secondaryText'
      ),
      seeMoreLink:
        'https://ayuda.lapmarketplace.com/hc/es-cl/articles/15468932297755-Solicitar-marca-para-categor%C3%ADa-y-exenci%C3%B3n-de-GTIN',
    },
  ];

  const beforeUpload = (file) => {
    setUploadFile(file);
    return false;
  };

  const verifyCheckboxCompleteness = (selectedOptions) => {
    if (selectedOptions.length === firstStepsOptions.length) {
      setCanSelectCategory(true);
    } else {
      setCanSelectCategory(false);
    }
  };

  const handleOnboardingModal = (data) => {
    const { action, status, step } = data;
    if (action === 'close' && status === 'finished') {
      setMarketplaceToPublish(null);
      dispatch(updateOnboarding(null, null));
    } else if (action === 'next' && status === 'finished') {
      if (
        onboardingStatus.state === 'FirstPost' &&
        onboardingStatus.step === 1
      ) {
        if (step.target === '.step3FirstPost') {
          dispatch(updateOnboarding('FirstPost', 3));
        } else {
          dispatch(updateOnboarding('FirstPost', 2));
        }
      } else if (
        onboardingStatus.state === 'FirstPost' &&
        onboardingStatus.step === 2
      ) {
        dispatch(updateOnboarding('FirstPost', 3));
      } else if (
        onboardingStatus.state === 'FirstPost' &&
        onboardingStatus.step === 3
      ) {
        dispatch(updateOnboarding('FirstPost', 4));
      } else if (
        onboardingStatus.state === 'FirstPost' &&
        onboardingStatus.step === 4
      ) {
        dispatch(updateOnboarding('FirstPost', 5));
      } else if (
        onboardingStatus.state === 'FirstPost' &&
        onboardingStatus.step === 5
      ) {
        dispatch(updateOnboarding('FirstPost', 6));
        setMarketplaceToPublish(null);
      }
    }
  };

  const selectOptions = () => {
    if (!marketplaceToPublish?.includes('Amazon')) {
      return (productTypes || []).map((d) => ({
        value: JSON.stringify(d.value),
        label: d.text,
      }));
    }
    return productTypes || [];
  };

  const descriptionMeta = (description) => (
    <Space style={{ lineHeight: '15px' }}>
      <Col>{description}</Col>
    </Space>
  );

  const publishProductsCategorySelect = (
    <Select
      id="publish-products-category-select"
      showSearch
      disabled={!canSelectCategory}
      value={currentCategory?.value.completeName}
      defaultActiveFirstOption={false}
      placeholder={
        marketplaceToPublish === 'Amazon' ||
        marketplaceToPublish === 'Ebay' ||
        marketplaceToPublish === 'Walmart' ||
        marketplaceToPublish === 'Amazon Canada' ||
        marketplaceToPublish === 'Ebay Canada'
          ? t('catalogue.publishProductsModal.searchCategoriesPlaceholder')
          : t(
              'catalogue.publishProductsModal.spanishSearchCategoriesPlaceholder'
            )
      }
      showArrow={false}
      filterOption={false}
      style={{
        width: '100%',
      }}
      onSearch={debouncedHandleProductTypeSearch}
      onChange={handleProductTypeChange}
      notFoundContent={null}
      options={selectOptions()}
    />
  );

  const errorsMessageHandle = (errores) => {
    const matches = errores.match(/Row/g);
    const rowCount = matches ? matches.length : 0;

    if (rowCount >= 1) {
      const titulo = errores.split('Row: ')[0];
      const parts = errores.split('Row: ').slice(1);
      return (
        <>
          <span>{titulo}</span>
          <ul style={{ listStyleType: 'disc' }}>
            {parts?.map((part, index) => {
              const lines = part.split('. ');
              const rowInfo = lines[0].split(', columns: ');
              const columns = rowInfo[1].split(', ');

              const rowkey = `row-${index}`;

              return (
                <li key={rowkey}>
                  <span>Row {rowInfo[0]}:</span>
                  <ul style={{ columnCount: '2' }}>
                    {columns?.map((column) => (
                      <li key={`${rowkey}-${column}`}>{column}</li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </ul>
        </>
      );
    }
    if (rowCount === 0 && errores.includes(': ')) {
      const titulo = errores.split(': ')[0];
      const columns = errores.split(': ').slice(1);
      return (
        <>
          <span>{titulo}</span>
          <ul style={{ columnCount: '2' }}>
            {columns[0].split(', ')?.map((column) => (
              <li key={column}>{column}</li>
            ))}
          </ul>
        </>
      );
    }
    return <span>{errores}</span>;
  };

  return (
    <>
      <Modal
        title={t('catalogue.publishProductsModal.title', {
          marketplace: marketplaceToPublish,
        })}
        open={marketplaceToPublish && !total && !all}
        cancelText={t('common.return')}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        style={{ minWidth: '1200px' }}
        className="publishProductsModal"
        footer={false}
        keyboard={false}
      >
        <Spin spinning={modalLoading}>
          {firstStepsVisible && (
            <Row className="step2FirstPost" style={{ marginBottom: '30px' }}>
              <Col>
                <Paragraph italic>
                  {t('catalogue.publishProductsModal.secondTitle')}
                </Paragraph>
              </Col>
              <Col>
                <Paragraph>
                  <Checkbox.Group
                    className="firstStepsCheckbox"
                    onChange={verifyCheckboxCompleteness}
                  >
                    <Space direction="vertical">
                      {firstStepsOptions.map((item) => (
                        <Checkbox
                          key={item.primaryText}
                          value={item.primaryText}
                        >
                          <span className="checkboxPrimaryText">
                            {`${item.primaryText} `}
                          </span>
                          {item?.secondaryText ? (
                            <span className="checkboxSecondaryText">
                              {`${item.secondaryText} `}
                            </span>
                          ) : (
                            ''
                          )}
                          <a
                            className="link"
                            href={item.seeMoreLink}
                            target="blank"
                          >
                            {t('common.seeMore')}
                          </a>
                        </Checkbox>
                      ))}
                    </Space>
                  </Checkbox.Group>
                </Paragraph>
              </Col>
              <Col>
                <Paragraph italic>
                  {t('catalogue.publishProductsModal.firstStepsCompletedText')}
                </Paragraph>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <Timeline>
                <Timeline.Item className="step3FirstPost">
                  <Paragraph>
                    {`${t('products.fields.searchCategories')} `}
                    <Tooltip
                      title={
                        marketplaceToPublish === 'Amazon' ||
                        marketplaceToPublish === 'Ebay' ||
                        marketplaceToPublish === 'Walmart' ||
                        marketplaceToPublish === 'Amazon Canada' ||
                        marketplaceToPublish === 'Ebay Canada'
                          ? t(
                              'catalogue.publishProductsModal.searchCategoriesTooltip'
                            )
                          : t(
                              'catalogue.publishProductsModal.spanishSearchCategoriesTooltip'
                            )
                      }
                      placement="top"
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Paragraph>
                  {!canSelectCategory ? (
                    <Popover content="Marca las casillas para poder adjuntar la planilla">
                      {publishProductsCategorySelect}
                    </Popover>
                  ) : (
                    publishProductsCategorySelect
                  )}
                </Timeline.Item>
                {selectedListings && (
                  <Timeline.Item>
                    <Checkbox
                      disabled={!total || total > processingLimit}
                      checked={autocompleteColumns}
                      onChange={(event) =>
                        setAutocompleteColumns(event.target.checked)
                      }
                    >
                      {`${t(
                        'catalogue.publishProductsModal.autocompleteColumns.checkbox.0'
                      )} ${t(
                        'catalogue.publishProductsModal.autocompleteColumns.checkbox.1',
                        { limit: processingLimit }
                      )} `}
                      <Tooltip
                        title={t(
                          'catalogue.publishProductsModal.autocompleteColumns.tooltip'
                        )}
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Checkbox>
                  </Timeline.Item>
                )}
                <Timeline.Item>
                  <div className="buttonsContainer">
                    <div className="leftButtonsContainer step4FirstPost">
                      <Paragraph>
                        {t(
                          'catalogue.publishProductsModal.downloadTemplateCategory'
                        )}
                      </Paragraph>
                      <Button
                        className="btn-basic width100 leftButton"
                        onClick={handleDownload}
                        disabled={
                          !currentCategory ||
                          !canSelectCategory ||
                          !marketplaceToPublish
                        }
                      >
                        {t('common.download')}
                      </Button>
                    </div>
                    <div className="rightButtonsContainer step5FirstPost">
                      <Paragraph>
                        {t(
                          'catalogue.publishProductsModal.uploadTemplateCategory'
                        )}
                      </Paragraph>
                      <Upload
                        maxCount={1}
                        name="file"
                        beforeUpload={beforeUpload}
                        className="uploadProductsFile width100"
                      >
                        <Button
                          className="btn-basic width100 rightButton"
                          disabled={!currentCategory || !canSelectCategory}
                        >
                          {t('common.uploadFile')}
                        </Button>
                      </Upload>
                      {errorsUploadFile !== '' && (
                        <ul
                          style={{
                            color: 'red',
                            padding: 0,
                            paddingLeft: 18,
                            marginTop: 10,
                            fontSize: '12px',
                          }}
                        >
                          <div>{errorsMessageHandle(errorsUploadFile)}</div>
                        </ul>
                      )}
                    </div>
                  </div>
                </Timeline.Item>
              </Timeline>
            </Col>
          </Row>
          <Paragraph>
            {t('catalogue.publishProductsModal.moreInfo')}{' '}
            <a
              className="link"
              href="https://ayuda.lapmarketplace.com/hc/es-cl/sections/13443298295579-3"
              target="blank"
            >
              {t('common.seeMore')}
            </a>
          </Paragraph>
          <div style={{ textAlign: 'center' }}>
            <Button
              className="btn-basic-green step6FirstPost"
              disabled={!uploadFile}
              onClick={handleUpload}
              style={{ width: '20%' }}
            >
              {t('products.fields.uploadAction')}
            </Button>
          </div>
        </Spin>
        {onboardingStatus.state === 'FirstPost' &&
          onboardingStatus.step === 1 &&
          firstStepsVisible && (
            <Joyride
              steps={stage3Modal1}
              callback={handleOnboardingModal}
              hideBackButton
              disableCloseOnEsc
              spotlightClicks
              styles={{
                options: {
                  zIndex: 2000,
                },
              }}
              continuous
              locale={{
                last: 'Siguiente',
              }}
            />
          )}
        {((!firstStepsVisible &&
          onboardingStatus.state === 'FirstPost' &&
          onboardingStatus.step === 1) ||
          (firstStepsVisible &&
            onboardingStatus.state === 'FirstPost' &&
            onboardingStatus.step === 2)) && (
          <Joyride
            steps={stage3Modal2}
            callback={handleOnboardingModal}
            hideBackButton
            disableCloseOnEsc
            spotlightClicks
            styles={{
              options: {
                zIndex: 2000,
              },
            }}
            continuous
            locale={{
              last: 'Siguiente',
            }}
          />
        )}
        {onboardingStatus.state === 'FirstPost' &&
          onboardingStatus.step === 3 && (
            <Joyride
              steps={stage3Modal3}
              callback={handleOnboardingModal}
              hideBackButton
              disableCloseOnEsc
              spotlightClicks
              styles={{
                options: {
                  zIndex: 2000,
                },
              }}
              continuous
              locale={{
                last: 'Siguiente',
              }}
            />
          )}
        {onboardingStatus.state === 'FirstPost' &&
          onboardingStatus.step === 4 && (
            <Joyride
              steps={stage3Modal4}
              callback={handleOnboardingModal}
              hideBackButton
              disableCloseOnEsc
              spotlightClicks
              styles={{
                options: {
                  zIndex: 2000,
                },
              }}
              continuous
              locale={{
                last: 'Siguiente',
              }}
            />
          )}
        {onboardingStatus.state === 'FirstPost' &&
          onboardingStatus.step === 5 && (
            <Joyride
              steps={stage3Modal5}
              callback={handleOnboardingModal}
              hideBackButton
              disableCloseOnEsc
              spotlightClicks
              styles={{
                options: {
                  zIndex: 2000,
                },
              }}
              continuous
              locale={{
                next: 'Siguiente',
              }}
            />
          )}
      </Modal>
      <Modal
        open={warningModalStatus}
        onOk={handleOkWarning}
        onCancel={handleCancelWarning}
        centered
        width={700}
        footer={null}
        closeIcon="✕"
        destroyOnClose
      >
        <div className="unsupportedCategoriesTitle">
          {t('catalogue.publishProductsModal.unsupportedModalTitle')}
        </div>
        <br />
        <div className="wrongCredentialsModal">
          {t('catalogue.publishProductsModal.unsupportedModalBodyPartOne')}
          <b>
            {t('catalogue.publishProductsModal.unsupportedModalBodyPartTwo')}
          </b>
          <br /> <br />
          <div className="unsupportedModalButtons">
            <Button
              key="submit"
              size="large"
              className="supportedCategoriesButton"
              style={{ marginRight: 30 }}
              onClick={() =>
                window.open(
                  'https://sellercentral.amazon.com/listing/product-template?preferredContributor=A1QNEKLFBB24BZ&mons_sel_mcid=AW1SP2IKKJM4H&mons_sel_mkid=ATVPDKIKX0DER&ref_=xx_swlang_head_xx&mons_sel_locale=es_ES&languageSwitched=1',
                  '_blank'
                )
              }
            >
              {t('catalogue.publishProductsModal.unsupportedModalUploadSheet')}
            </Button>
            <Button
              key="submit"
              size="large"
              className="supportedCategoriesButton"
              onClick={() =>
                window.open(
                  'https://sellercentral.amazon.com/productclassify',
                  '_blank'
                )
              }
            >
              {t('catalogue.publishProductsModal.unsupportedModalUploadSingle')}
            </Button>
          </div>
          <br />
          <List
            itemLayout="horizontal"
            dataSource={[{}]}
            renderItem={() => (
              <List.Item
                style={{
                  background: '#6D7CFF1F',
                  paddingRight: 20,
                  borderRadius: 8,
                }}
              >
                <List.Item.Meta
                  avatar={<InfoCircleOutlined className="iconDisclaimer" />}
                  description={descriptionMeta(
                    <Text style={{ fontSize: 14, fontWeight: 400 }}>
                      <strong>
                        {' '}
                        {t(
                          'catalogue.publishProductsModal.unsupportedModalUploadSheet'
                        )}
                        :{' '}
                      </strong>
                      {t(
                        'catalogue.publishProductsModal.unsupportedModalDescriptionOne'
                      )}{' '}
                      <br /> <br />
                      <strong>
                        {' '}
                        {t(
                          'catalogue.publishProductsModal.unsupportedModalUploadSingle'
                        )}
                        :{' '}
                      </strong>
                      {t(
                        'catalogue.publishProductsModal.unsupportedModalDescriptionTwo'
                      )}{' '}
                      <br />
                    </Text>
                  )}
                />
              </List.Item>
            )}
          />
          <br />
          <a
            href="https://ayuda.lapmarketplace.com/hc/es-cl/articles/14075928200347"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            {t('catalogue.publishProductsModal.unsupportedModalmoreInfo')}
          </a>
        </div>
      </Modal>
    </>
  );
}

PublishProductsModal.propTypes = {
  marketplaceToPublish: PropTypes.string.isRequired,
  setMarketplaceToPublish: PropTypes.func.isRequired,
  selectedListings: PropTypes.arrayOf(PropTypes.string).isRequired,
  originMarketplace: PropTypes.string.isRequired,
  all: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
};

export default PublishProductsModal;

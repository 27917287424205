import React, { useEffect, useState } from 'react';
import { Card, Col, Collapse, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import CompaniesAPI from '../../../api/company';
import openNotification from '../../../components/Toastr';

function Consume() {
  const { Title, Text } = Typography;
  const { t } = useTranslation();

  const [skus, setSkus] = useState();
  const [transactions, setTransactions] = useState();
  const [marketplaces, setMarketplaces] = useState();

  const getConsume = async () => {
    try {
      const response = await CompaniesAPI.getConsume();
      if (response.data) {
        setSkus(response.data.skus);
        setTransactions(response.data.transactions);
        setMarketplaces(response.data.marketplaces);
      }
    } catch (error) {
      openNotification({
        status: false,
        content: t('subscriptions.consume.error'),
      });
    }
  };

  useEffect(() => {
    getConsume();
  }, []);

  return (
    <Card className="plans-section">
      <Collapse defaultActiveKey={['1']} ghost>
        <CollapsePanel
          header={
            <Title className="collapse-header-title" level={3}>
              {t('subscriptions.consume.title')}
            </Title>
          }
        >
          <Row justify="space-evenly" align="middle">
            <Col className="col">
              <Title level={3}>{skus}</Title>
              <Text>{t('plans.skus')}</Text>
            </Col>
            <Col className="col">
              <Title level={3}>{transactions}</Title>
              <Text>{t('plans.transactions').toLowerCase()}</Text>
            </Col>
            <Col className="col">
              <Title level={3}>{marketplaces}</Title>
              <Text>{t('plans.marketplaces').toLowerCase()}</Text>
            </Col>
          </Row>
        </CollapsePanel>
      </Collapse>
    </Card>
  );
}

export default React.memo(Consume);

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography, Tooltip, Image, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import fbaLogo from '../../../../assets/fbaLogo.png';
import fbmLogo from '../../../../assets/FBM_logo.png';
import 'antd/dist/antd.min.css';

const { Text, Link } = Typography;

function FulfillmentShipmentsTable({ order, loadingShippment }) {
  const columnsShipment = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      render: (value, record) => (
        <Text
          ellipsis={{
            tooltip: record?.amazonShipmentId || record?.marketplaceShipmentId,
          }}
        >
          {record?.amazonShipmentId || record?.marketplaceShipmentId}
        </Text>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'orderStatus',
      key: 'status',
      width: 70,
      render: (value, record) => (
        <Text ellipsis={{ tooltip: record?.fulfillmentShipmentStatus }}>
          {record?.fulfillmentShipmentStatus}
        </Text>
      ),
    },
    {
      title: 'SHIP DATE',
      dataIndex: 'date',
      key: 'date',
      width: 60,
      render: (value, record) => (
        <Text ellipsis={{ tooltip: record?.shippingDate }}>
          {record?.shippingDate}
        </Text>
      ),
    },
    {
      title: 'PACKAGE',
      dataIndex: 'date',
      key: 'date',
      width: 300,
      render: (value, record) =>
        record?.fulfillmentShipmentPackage?.map((pack, index) => {
          const key = index;
          return (
            <Row key={key}>
              <Col span={6}>
                <Text
                  style={{ width: '100%' }}
                  ellipsis={{ tooltip: pack?.carrierCode }}
                >
                  Carrier: {pack?.carrierCode}
                </Text>
              </Col>
              <Col span={6}>
                <Tooltip placement="top" title="Ver seguimiento">
                  <Link
                    href={pack?.carrierURL}
                    target="_blank"
                    style={{ width: '100%', paddingLeft: 5 }}
                    ellipsis={{ tooltip: pack?.trackingNumber }}
                  >
                    <SearchOutlined /> {pack?.trackingNumber}{' '}
                  </Link>
                </Tooltip>
              </Col>
              <Col span={12}>
                <Text
                  style={{ width: '100%', paddingLeft: 5 }}
                  ellipsis={{
                    tooltip: pack?.packageNumber || pack?.trackingNumber,
                  }}
                >
                  {pack?.packageNumber ? 'Package number' : 'Tracking number'} :{' '}
                  {pack?.packageNumber || pack?.trackingNumber}
                </Text>
              </Col>
            </Row>
          );
        }),
    },
    {
      title: 'ITEMS IN SHIPMENT',
      dataIndex: 'date',
      key: 'date',
      width: 300,
      render: (value, record) =>
        record?.fulfillmentShipmentItem?.map((items, index) => {
          const key = index;
          return (
            <Row key={key}>
              <Col span={13}>
                <Text
                  style={{ width: '100%' }}
                  ellipsis={{
                    tooltip: items?.packageNumber || items?.trackingNumber,
                  }}
                >
                  {items?.packageNumber ? 'Package number' : 'Tracking number'}{' '}
                  : {items?.packageNumber || items?.trackingNumber}
                </Text>
              </Col>
              <Col span={6}>
                <Text
                  style={{ width: '100%' }}
                  ellipsis={{ tooltip: items?.sellerSku }}
                >
                  SKU: {items?.sellerSku}
                </Text>
              </Col>
              <Col span={5}>
                <Text
                  style={{ width: '100%' }}
                  ellipsis={{ tooltip: items?.quantity }}
                >
                  Quantity: {items?.quantity}
                </Text>
              </Col>
            </Row>
          );
        }),
    },
    {
      title: 'FULFILLMENT ORDER ID',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      render: () => (
        <Text ellipsis={{ tooltip: order?.shipment?.sellerFulfillmentOrderId }}>
          {order?.shipment?.sellerFulfillmentOrderId}
        </Text>
      ),
    },
    {
      title: 'SHIPPED VIA',
      dataIndex: 'fulfillment',
      key: 'fulfillment',
      width: 110,
      render: () => {
        let component;
        switch (order.fulfillmentChannel) {
          case 'FBM':
            component = (
              <Image style={{ height: 20 }} preview={false} src={fbmLogo} />
            );
            break;
          case 'FBA':
            component = (
              <Image style={{ height: 20 }} preview={false} src={fbaLogo} />
            );
            break;
          default:
            component = <Text type="text"> {order.fulfillmentChannel} </Text>;
        }
        return component;
      },
    },
  ];

  return (
    ['Shipped'].includes(order?.orderStatus) &&
    order?.orderItems?.length > 0 && (
      <Row>
        <Col span={24}>
          <Table
            className="home-listing-table"
            size="small"
            loading={loadingShippment}
            columns={columnsShipment}
            dataSource={order?.fulfillmentShipments || []}
            pagination={false}
            scroll={{ x: 1600 }}
          />
        </Col>
      </Row>
    )
  );
}

FulfillmentShipmentsTable.propTypes = {
  order: PropTypes.objectOf(Object).isRequired,
  loadingShippment: PropTypes.bool.isRequired,
};

export default React.memo(FulfillmentShipmentsTable);

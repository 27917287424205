import * as types from './types';

export const startOnboarding = () => {
  return {
    type: types.START_ONBOARDING,
  };
};

export const updateOnboarding = (state, step) => {
  return {
    type: types.UPDATE_ONBOARDING,
    payload: {
      state,
      step,
    },
  };
};

export const completeOnboarding = () => {
  return {
    type: types.COMPLETE_ONBOARDING,
  };
};

import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Col, Image, Row, Space } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { CreditCardOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import moment from 'moment/moment';
import MercadoPagoIcon from '../../../assets/Mercado_Pago_Icon.png';
import StripeIcon from '../../../assets/cc-stripe.svg';

function CurrentSubscription({ subscription, cancelSubscription, cancelling }) {
  const { t } = useTranslation();
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const nextPaymentDate = subscription.nextPaymentDate
    ? new Date(subscription.nextPaymentDate).toLocaleDateString(
        'es-CL',
        options
      )
    : t('subscriptions.currentSubscription.processing');
  const lastPaymentDate = subscription.lastPaymentDate
    ? new Date(subscription.lastPaymentDate).toLocaleDateString(
        'es-CL',
        options
      )
    : t('subscriptions.currentSubscription.processing');
  const lastPaymentAmount = subscription.lastPaymentAmount
    ? new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: subscription.paymentPlan.currency,
      }).format(subscription.lastPaymentAmount)
    : null;
  const { paymentMethod } = subscription.paymentPlan;
  const icons = {
    PayPal:
      'https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-mark-color.svg',
    'Mercado Pago': MercadoPagoIcon,
    Stripe: StripeIcon,
    External: <CreditCardOutlined />,
  };

  let cancelButtonText;
  if (subscription.active) {
    cancelButtonText = cancelling
      ? t('subscriptions.currentSubscription.cancellingSubscription')
      : t('subscriptions.currentSubscription.cancelSubscription');
  } else {
    cancelButtonText = t(
      'subscriptions.currentSubscription.cancelledSubscription'
    );
  }

  const showConfirmCancel = () => {
    confirm({
      title: t('subscriptions.currentSubscription.cancelConfirmation'),
      icon: <ExclamationCircleFilled />,
      okText: 'Si, estoy seguro',
      okType: 'danger',
      cancelText: 'No',
      content: t('subscriptions.currentSubscription.cancelWarning'),
      onOk() {
        cancelSubscription(subscription);
      },
      onCancel() {},
    });
  };

  return (
    <div className="current-subscription">
      <Title level={4}>
        {t(
          `subscriptions.currentSubscription.${subscription.type.toLowerCase()}`
        )}
      </Title>
      <Row justify="space-between" align="top">
        <Col span={11}>
          <Row className="row">
            <span>{t('subscriptions.currentSubscription.paymentMethod')}:</span>
            <span className="item">
              {paymentMethod === 'External' ? (
                <Space>
                  {icons[paymentMethod]}
                  {t('subscriptions.currentSubscription.external')}
                </Space>
              ) : (
                <>
                  <Image
                    className="icon"
                    preview={false}
                    style={{ width: 25, height: 25 }}
                    src={icons[paymentMethod]}
                  />
                  {paymentMethod}
                </>
              )}
            </span>
          </Row>
          {!subscription.onFreeTrial ? (
            <Row className="row">
              <span>{t('subscriptions.currentSubscription.lastPayment')}:</span>
              {lastPaymentAmount && (
                <span className="big-item">{`${lastPaymentAmount}`}</span>
              )}
              {lastPaymentDate && (
                <span className="item">{lastPaymentDate}</span>
              )}
            </Row>
          ) : (
            <Row className="row">
              <span>{t('subscriptions.currentSubscription.freeTrial')}:</span>
              <span className="item">
                {`${moment(subscription?.expirationDate).diff(
                  moment(),
                  'days'
                )} ${t('subscriptions.currentSubscription.daysLeft')}`}
              </span>
            </Row>
          )}
        </Col>
        <Col span={11}>
          <Row className="row">
            <span>
              {t('subscriptions.currentSubscription.subscriptionCode')}:
            </span>
            <span
              className="big-item"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {subscription.paymentPlan.name}
            </span>
          </Row>
          <Row className="row">
            <span>
              {subscription.active
                ? t('subscriptions.currentSubscription.nextPayment')
                : t('subscriptions.currentSubscription.endOfSubscription')}
              :
            </span>
            {subscription.active && subscription.onFreeTrial && (
              <span className="big-item">
                {`${new Intl.NumberFormat('es-CL', {
                  style: 'currency',
                  currency: subscription.paymentPlan.currency,
                }).format(subscription.paymentPlan.price)} ${
                  subscription.paymentPlan.currency
                }`}
              </span>
            )}
            <span className="item">
              {subscription.active
                ? nextPaymentDate
                : new Date(subscription?.expirationDate).toLocaleDateString(
                    'es-CL',
                    options
                  )}
            </span>
          </Row>
          <Row>
            <Button
              danger
              type="text"
              disabled={!subscription.active || cancelling}
              className="cancel-subscription"
              onClick={() => showConfirmCancel()}
            >
              {cancelButtonText}
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

CurrentSubscription.propTypes = {
  subscription: PropTypes.shape({
    id: PropTypes.number,
    active: PropTypes.bool,
    type: PropTypes.string,
    dateCreated: PropTypes.string,
    nextPaymentDate: PropTypes.string,
    lastPaymentDate: PropTypes.string,
    lastPaymentAmount: PropTypes.number,
    expirationDate: PropTypes.string,
    isExpired: PropTypes.bool,
    onFreeTrial: PropTypes.bool,
    paymentPlan: PropTypes.shape({
      name: PropTypes.string,
      price: PropTypes.number,
      paymentMethod: PropTypes.string,
      currency: PropTypes.string,
    }),
  }).isRequired,
  cancelling: PropTypes.bool.isRequired,
  cancelSubscription: PropTypes.func.isRequired,
};

export default memo(CurrentSubscription);

import api from './api';

const prefix = 'products';

const productApi = {
  getAll() {
    return api.get(`${prefix}/listings`);
  },
  buildProductsTree(params) {
    return api.get(`${prefix}/buildProductsTree`, { params });
  },
  getByIds(params) {
    return api.get(`${prefix}/findByIds`, { params });
  },
  getBySalesOrderId(orderId, params) {
    return api.get(`${prefix}/order/${orderId}`, { params });
  },
  getBySalesOrdersId(ordersIds, fulfillmentType = null) {
    const params = {
      ordersIds,
      ...(fulfillmentType ? { fulfillmentType } : {}),
    };
    return api.get(`${prefix}/orders`, { params });
  },
  update(id, payload) {
    return api.put(`${prefix}/${id}`, payload);
  },
  bulkUpdate(payload) {
    return api.patch(`${prefix}/`, payload);
  },
  updateByDefaultCode(defaultCode, payload) {
    return api.put(`${prefix}/sku/${defaultCode}`, payload);
  },
  updateProductTemplate(id, payload) {
    return api.put(`${prefix}/product-template/${id}`, payload);
  },
  deleteProducts(payload) {
    return api.post(`${prefix}/delete-products`, payload);
  },
  getProductStatuses(params) {
    return api.get(`${prefix}/statuses`, { params });
  },
};

export default productApi;

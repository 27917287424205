import PropTypes from 'prop-types';
import { Button, Space, Row, Col, Affix, Alert, Popconfirm } from 'antd';
import {
  WarningOutlined,
  CloseOutlined,
  SaveOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function StickyAlert({
  handleCancelForm,
  handleSaveDraft,
  sendContent,
  firstDisabled,
  secondDisabled,
  thirdDisabled,
  forthDisabled,
  hidden,
  saveDraftLoading,
  sendContentLoading,
}) {
  const { t } = useTranslation();

  return (
    !hidden && (
      <Row justify="end" style={{ marginTop: 24 }}>
        <Col>
          <Affix offsetBottom={10}>
            <Alert
              className="shipment-sticky-alert-message"
              action={
                <Space>
                  <Popconfirm
                    title={t('orders.confirmDelete')}
                    disabled={firstDisabled}
                    onConfirm={handleCancelForm}
                    onCancel={() => {}}
                    icon={<WarningOutlined />}
                    okText={t('yes')}
                    cancelText={t('no')}
                    okButtonProps={{
                      style: { backgroundColor: '#5365E3' },
                    }}
                  >
                    <Button
                      size="small"
                      type="link"
                      icon={<CloseOutlined className="close-button" />}
                      disabled={secondDisabled}
                      style={{ color: 'rgba(32, 48, 60, 1)', fontSize: 14 }}
                    >
                      {t('orders.alertButtonClose')}
                    </Button>
                  </Popconfirm>
                  <Button
                    disabled={thirdDisabled}
                    className="save-draft-shipment"
                    icon={<SaveOutlined className="save-draft-shipment-svg" />}
                    onClick={() => handleSaveDraft(true)}
                    loading={saveDraftLoading}
                  >
                    {t('orders.alertButtonSave')}
                  </Button>
                  <Button
                    disabled={forthDisabled}
                    className="next-step-shipment"
                    onClick={sendContent}
                    loading={sendContentLoading}
                  >
                    {t('orders.alertButtonSend')} <ArrowRightOutlined />
                  </Button>
                </Space>
              }
            />
          </Affix>
        </Col>
      </Row>
    )
  );
}

StickyAlert.propTypes = {
  handleCancelForm: PropTypes.func.isRequired,
  handleSaveDraft: PropTypes.func.isRequired,
  sendContent: PropTypes.func.isRequired,
  firstDisabled: PropTypes.bool.isRequired,
  secondDisabled: PropTypes.bool.isRequired,
  thirdDisabled: PropTypes.bool.isRequired,
  forthDisabled: PropTypes.bool.isRequired,
  hidden: PropTypes.bool,
  saveDraftLoading: PropTypes.bool.isRequired,
  sendContentLoading: PropTypes.bool.isRequired,
};

StickyAlert.defaultProps = {
  hidden: false,
};

export default StickyAlert;

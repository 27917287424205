import { DownOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function CheckboxDropdown({
  selectAll,
  setSelectAll,
  initialTotal,
  totalResults,
  rowKeys,
  setRowKeys,
  skus,
  setSkus,
  productIds,
  setProductIds,
  filteredProducts,
}) {
  const { t } = useTranslation();
  const parentKeys = filteredProducts?.map((prd) => prd?.id);
  const childKeys = filteredProducts?.flatMap((obj) =>
    obj.children?.map((child) => child?.id)
  );
  const allKeys = new Set([...parentKeys, ...childKeys]);

  const mergeArraysWithoutDuplicates = (array1, array2) => [
    ...new Set([...array1, ...array2]),
  ];

  const handleOptionChange = (option) => {
    if (option === 'all') {
      const newValue =
        productIds.length > 0 && productIds.length < totalResults
          ? true
          : !selectAll;
      setRowKeys(newValue ? [...allKeys] : []);
      setSkus([]);
      setProductIds([]);
      setSelectAll(newValue);
    } else {
      const newValue = !(rowKeys.length === allKeys.size);
      setRowKeys(newValue ? [...allKeys] : []);
      const pageSkus = new Set(
        filteredProducts?.map((listing) => listing?.defaultCode)
      );
      if (selectAll === newValue) {
        setSkus(skus.filter((s) => !pageSkus.has(s)));
        setProductIds(productIds.filter((i) => !allKeys.has(i)));
      } else {
        setSkus(mergeArraysWithoutDuplicates(skus, pageSkus));
        setProductIds(mergeArraysWithoutDuplicates(productIds, allKeys));
      }
    }
  };

  const items = ['page', 'all'].map((option) => ({
    key: option,
    label: (
      <Checkbox
        checked={
          option === 'page'
            ? rowKeys.length === allKeys.size
            : selectAll && Number(productIds?.length) === 0
        }
        indeterminate={
          option === 'page'
            ? rowKeys.length > 0 && rowKeys.length < allKeys.size
            : productIds.length > 0 && productIds.length < initialTotal
        }
        onChange={() => handleOptionChange(option)}
      >
        {t(`catalogue.select.${option}`)}
      </Checkbox>
    ),
  }));

  return (
    <Dropdown menu={{ items }}>
      <div>
        <Checkbox checked>
          <DownOutlined />
        </Checkbox>
      </div>
    </Dropdown>
  );
}

CheckboxDropdown.propTypes = {
  selectAll: PropTypes.bool.isRequired,
  setSelectAll: PropTypes.func.isRequired,
  initialTotal: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
  rowKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  setRowKeys: PropTypes.func.isRequired,
  skus: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSkus: PropTypes.func.isRequired,
  productIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setProductIds: PropTypes.func.isRequired,
  filteredProducts: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

export default CheckboxDropdown;

import PropTypes from 'prop-types';
import { Col, Divider, Form, Input, Modal, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import phones from '../../../utils/json/phones.json';
import stateOrProvinces from '../../../utils/json/stateOrProvinces.json';
import WarehousesAPI from '../../../api/warehouses';
import openNotification from '../../../components/Toastr';
import usePlacesInput from '../../shared/usePlacesInput';

function CreateWarehouseModal({
  isModalOpen,
  setIsModalOpen,
  reloadTable,
  selectForm,
  warehouseSelectFormName,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [selectedCountryCode, setSelectedCountryCode] = useState();
  const { Option } = Select;

  const addressInput = usePlacesInput(
    form,
    setSelectedCountryCode,
    stateOrProvinces
  );

  const currStateOrProvinces = stateOrProvinces.find(
    (row) => row.countryCode === selectedCountryCode
  );

  const getStatesOrProvinces = () => {
    return currStateOrProvinces?.states.map((state) => (
      <Select.Option key={state.code} value={state.code}>
        {state.name}
      </Select.Option>
    ));
  };

  const validatePhoneNumber = (_, value, callback) => {
    const phoneNumberRegex = /^\d+$/;
    if (value && phoneNumberRegex.test(value)) {
      callback();
    } else {
      callback(
        `Por favor ingresa ${!value ? 'un número válido' : 'sólo dígitos'}`
      );
    }
  };

  const handleOk = () => {
    form.submit();
  };
  const handleCancel = () => {
    selectForm?.setFieldsValue({ [warehouseSelectFormName]: undefined });
    setIsModalOpen(false);
  };

  const onFieldChange = () => {
    const countryCode = form.getFieldValue('countryCode');
    setSelectedCountryCode(countryCode);
  };

  const onFinish = async (values) => {
    const payload = {
      ...values,
    };
    // Sets stateOrProvinceCode as countryCode
    if (!values.stateOrProvinceCode)
      payload.stateOrProvinceCode = values.countryCode;
    try {
      const response = await WarehousesAPI.createWarehouse(payload);
      openNotification({
        status: true,
        content: 'Bodega creada correctamente.',
      });
      if (selectForm) {
        selectForm?.setFieldsValue({
          [warehouseSelectFormName]: response.data.id,
        });
      }
      await reloadTable();
      setIsModalOpen(false);
    } catch (error) {
      openNotification({
        status: false,
        content: 'Ha ocurrido un error al crear la bodega.',
      });
      selectForm?.setFieldsValue({
        [warehouseSelectFormName]: undefined,
      });
    }
  };

  const prefixSelector = (
    <Form.Item
      name="phoneExtension"
      noStyle
      rules={[
        {
          required: true,
          message: 'Extensión es requerida',
        },
      ]}
    >
      <Select
        className="contentOptionsPhone"
        showSearch
        optionFilterProp="children"
      >
        {phones.length > 0 &&
          phones.map((element) => (
            <Option
              className="flag"
              value={`+${element.dial_code}`}
              key={element.code}
            >
              {element.name} (+
              {element.dial_code})
            </Option>
          ))}
      </Select>
    </Form.Item>
  );

  return (
    <Modal
      className="create-warehouse-modal"
      title="Crear Bodega"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width="75%"
      style={{
        top: 20,
      }}
    >
      <Form
        name="create-warehouse-form"
        layout="vertical"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        <Row gutter={[16, 20]}>
          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.name')}
              name="name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.contactName')}
              name="contactName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.email')}
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Por favor, ingresa un email válido',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.phoneNumber')}
              name="phoneNumber"
              rules={[{ required: true, validator: validatePhoneNumber }]}
            >
              <Input addonBefore={prefixSelector} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.address.streetName')}
              name="streetName"
              rules={[{ required: true }]}
            >
              {addressInput}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.address.additionalAddressInfo')}
              name="additionalAddressInfo"
            >
              <Input showCount maxLength={35} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.address.city')}
              name="city"
              rules={[{ required: true }]}
            >
              <Input showCount maxLength={30} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.address.postalCode')}
              name="postalCode"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('warehouses.createForm.address.countryCode')}
              name="countryCode"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                onChange={onFieldChange}
                filterOption={(input, option) =>
                  option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {phones.map((row) => (
                  <Option key={row.name} value={row.code}>
                    {row.flag} {row.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            {currStateOrProvinces && (
              <Form.Item
                label={t('warehouses.createForm.address.stateOrProvinceCode')}
                name="stateOrProvinceCode"
                rules={[{ required: true }]}
              >
                <Select>{getStatesOrProvinces()}</Select>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

CreateWarehouseModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  reloadTable: PropTypes.func.isRequired,
  selectForm: PropTypes.instanceOf(Form),
  warehouseSelectFormName: PropTypes.string,
};

CreateWarehouseModal.defaultProps = {
  selectForm: null,
  warehouseSelectFormName: null,
};

export default CreateWarehouseModal;

import api from './api';

const prefix = 'openai';

const openAIAPI = {
  optimizeListing(payload) {
    return api.post(`${prefix}/optimizeListing`, payload);
  },
  addMessageToThread(threadId, payload) {
    return api.post(`${prefix}/threads/${threadId}/messages`, payload);
  },
  checkRunStatus(threadId, runId) {
    return api.get(`${prefix}/threads/${threadId}/runs/${runId}/status`);
  },
  listThreadMessages(threadId) {
    return api.get(`${prefix}/threads/${threadId}/messages`);
  },
  createThread() {
    return api.post(`${prefix}/threads`);
  },
  listCompanyThreads() {
    return api.get(`${prefix}/threads`);
  },
  updateThreadMetadata(threadId, payload) {
    return api.patch(`${prefix}/thread/${threadId}`, payload);
  },
};

export default openAIAPI;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Drawer, List, Row, Space, Typography } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import ListItem from './ListItem';

function Notifications({ list, update, deleteItem, open, setOpen }) {
  const { t } = useTranslation();

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      className="notifications-body"
      title={t('notifications.title')}
      width="450px"
      open={open}
      onClose={onClose}
    >
      {list?.length > 0 ? (
        <List
          itemLayout="horizontal"
          dataSource={list}
          renderItem={(item, index) => (
            <ListItem
              item={item}
              index={index}
              update={update}
              deleteItem={deleteItem}
              setOpen={setOpen}
            />
          )}
        />
      ) : (
        <Row
          style={{
            height: '100%',
            opacity: 0.6,
          }}
          align="middle"
        >
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Col
              span={24}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Typography.Text
                className="w-500"
                style={{ color: '#9C9C9C', fontSize: '17px' }}
              >
                {t('notifications.empty')}
              </Typography.Text>
            </Col>
            <Col
              span={24}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <InboxOutlined className="empty-notifications" />
            </Col>
          </Space>
        </Row>
      )}
    </Drawer>
  );
}

Notifications.propTypes = {
  list: PropTypes.instanceOf(Array).isRequired,
  update: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default Notifications;

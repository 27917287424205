import * as React from 'react';
import PropTypes from 'prop-types';

function SvgPrestashop({ height, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 600 99"
    >
      <path
        d="M217.869 58.5685C217.769 57.9685 217.769 57.4686 217.769 56.8686C217.769 51.9686 219.369 48.0686 222.669 45.2686C225.669 42.5686 229.569 41.1685 233.569 41.1685C238.769 41.1685 242.469 43.0685 244.469 47.0685L217.869 58.5685ZM255.969 51.4685C256.069 45.9685 253.669 40.7686 249.369 37.2686C245.069 33.6686 239.569 31.7686 233.069 31.7686C228.969 31.7686 224.869 32.6685 221.269 34.5685C217.469 36.5685 214.169 39.4685 211.869 43.1685C209.269 47.1685 207.969 51.8685 208.069 56.6685C208.069 64.0685 210.469 69.9685 215.369 74.4685C220.069 78.8685 226.369 81.3686 232.869 81.2686C240.669 81.2686 247.369 78.2686 253.069 72.3686L247.169 65.7686C243.969 69.8686 239.069 72.1685 233.869 72.0685C228.469 72.0685 224.269 70.1686 221.069 66.3686L255.969 51.6685V51.4685Z"
        fill="#281143"
      />
      <path
        d="M196.469 34.8684C193.669 36.7684 191.369 39.3684 189.769 42.2684V32.5684H179.769V80.4684H189.869V59.9684C189.869 54.4684 191.169 50.1684 193.669 46.8684C196.169 43.5684 199.869 41.9684 204.869 41.9684H205.369V31.8684C202.169 31.9684 199.069 32.9684 196.469 34.8684Z"
        fill="#281143"
      />
      <path
        d="M292.169 56.1685C290.769 55.1685 289.169 54.3685 287.569 53.6685C286.169 53.0685 284.669 52.4685 283.169 52.0685C281.669 51.5685 279.969 51.1685 278.069 50.6685C276.469 50.2685 274.969 49.7685 273.469 49.1685C271.969 48.6685 271.069 47.2685 271.069 45.6685C270.969 44.0685 271.869 42.4685 273.369 41.7685C275.169 40.8685 277.169 40.4685 279.169 40.4685C283.769 40.4685 288.169 41.7685 291.969 44.3685L295.769 36.8685C291.169 33.6685 285.669 31.9685 280.069 31.9685C274.469 31.9685 270.069 33.2685 266.569 36.0685C263.169 38.5685 261.269 42.5685 261.469 46.7685C261.469 53.0685 266.469 57.5685 276.369 60.2685C276.569 60.3685 276.969 60.4685 277.469 60.5685C283.769 62.4685 286.969 64.6685 286.969 67.0685C286.969 68.6685 286.269 70.1685 285.069 71.0685C283.469 72.1685 281.569 72.7685 279.669 72.6685C274.469 72.6685 269.069 70.5685 263.469 66.3685L258.569 74.2685C261.569 76.5685 264.969 78.3685 268.569 79.5685C272.069 80.7685 275.769 81.3685 279.469 81.4685C284.669 81.4685 288.869 80.0685 292.269 77.3685C295.569 74.6685 297.369 70.6685 297.269 66.3685C296.969 61.5685 295.369 58.2685 292.169 56.1685Z"
        fill="#281143"
      />
      <path
        d="M158.569 49.0684C156.469 51.3684 152.369 52.4684 146.469 52.4684H134.169V27.2684H144.969C150.669 27.2684 154.969 28.1684 157.769 29.9684C160.469 31.7684 161.869 34.8684 161.869 39.1684C161.769 43.3684 160.669 46.7684 158.569 49.0684ZM166.069 23.0684C161.669 19.4684 154.669 17.5684 145.169 17.5684H123.469V80.5684H134.169V61.9684H145.369C154.669 61.9684 161.469 60.1684 165.969 56.6684C170.469 53.0684 172.769 47.5684 172.769 39.8684C172.669 32.2684 170.469 26.6684 166.069 23.0684Z"
        fill="#281143"
      />
      <path
        d="M320.569 72.4684C318.669 72.5684 316.769 71.8684 315.469 70.4684C314.269 68.9684 313.569 66.9684 313.669 64.9684V40.6684H326.369V32.5684H313.669V17.5684H303.569V64.8684C303.569 69.9684 305.069 73.8684 308.169 76.9684C311.069 79.8684 315.069 81.4684 319.169 81.4684C323.469 81.4684 327.369 79.8684 330.869 76.6684L326.769 69.5684C324.869 71.4684 322.769 72.4684 320.569 72.4684Z"
        fill="#281143"
      />
      <path
        d="M366.869 67.8685C364.269 70.7685 360.569 72.3685 356.769 72.2685C352.869 72.2685 349.169 70.7685 346.369 67.9685C343.369 65.0685 341.969 61.2685 341.969 56.7685C341.869 52.5685 343.369 48.3685 346.269 45.2685C348.869 42.3685 352.669 40.6685 356.669 40.6685C360.569 40.5685 364.269 42.2685 366.869 45.2685C369.669 48.2685 370.969 52.1685 370.969 56.7685C371.069 60.8685 369.569 64.8685 366.869 67.8685ZM370.869 40.8685C366.769 34.8685 361.569 31.8685 355.269 31.8685C349.169 31.7685 343.169 34.1685 338.869 38.5685C334.269 43.0685 331.969 48.9685 331.969 56.3685C331.969 63.7685 334.269 69.8685 338.769 74.4685C342.869 78.8685 348.669 81.3685 354.669 81.2685C357.769 81.2685 360.869 80.4685 363.669 79.0685C366.569 77.5685 369.169 75.4685 370.969 72.7685V80.6685H381.069V32.7685H370.869V40.8685Z"
        fill="#281143"
      />
      <path
        d="M530.469 67.8684C524.369 73.7684 514.769 73.7684 508.669 67.8684C505.769 64.8684 504.369 61.1684 504.369 56.5684C504.369 51.9684 505.769 48.1684 508.669 45.2684C514.769 39.3684 524.369 39.3684 530.469 45.2684C533.369 48.2684 534.769 51.9684 534.769 56.5684C534.769 61.1684 533.369 64.9684 530.469 67.8684ZM519.669 31.8684C512.469 31.8684 506.369 34.2684 501.569 39.0684C491.969 48.7684 491.969 64.4684 501.569 74.1684C506.369 78.9684 512.369 81.3684 519.669 81.3684C526.969 81.3684 532.869 78.9684 537.769 74.1684C547.369 64.4684 547.369 48.7684 537.769 39.0684C532.869 34.2684 526.869 31.8684 519.669 31.8684Z"
        fill="#DF1668"
      />
      <path
        d="M585.369 67.8685C582.669 70.6685 578.869 72.1685 574.969 72.1685C571.069 72.2685 567.469 70.6685 564.869 67.7685C562.069 64.7685 560.769 61.0685 560.769 56.5685C560.769 52.0685 562.069 48.1685 564.869 45.1685C567.469 42.2685 571.169 40.5685 575.069 40.5685C579.069 40.5685 582.769 42.1685 585.469 45.1685C588.369 48.2685 589.969 52.4685 589.769 56.6685C589.769 61.2685 588.269 65.0685 585.369 67.8685ZM593.069 38.5685C588.669 34.1685 582.769 31.7685 576.569 31.8685C570.169 31.8685 564.969 34.8685 560.969 40.9685V32.6685H550.869V98.0685H560.969V73.3685C565.769 78.6685 571.169 81.2685 577.269 81.2685C583.369 81.2685 588.569 78.9685 593.169 74.3685C597.769 69.7685 599.969 63.7685 599.969 56.2685C599.969 48.7685 597.569 42.9685 593.069 38.5685Z"
        fill="#DF1668"
      />
      <path
        d="M470.869 31.8685C467.569 31.8685 464.269 32.7685 461.469 34.3685C458.669 35.9685 456.369 38.3685 454.969 41.2685V13.6685H444.869V80.4684H454.969V54.5685C454.969 50.0685 456.269 46.6684 458.869 44.4684C461.369 42.1684 464.669 40.9684 467.969 40.9684C475.269 40.9684 478.969 45.3685 478.969 54.2685V80.5685H489.069V51.0685C489.069 44.8685 487.369 39.9685 484.069 36.6685C480.769 33.4685 476.269 31.8685 470.869 31.8685Z"
        fill="#DF1668"
      />
      <path
        d="M432.769 50.1684C429.469 47.3684 424.669 45.1684 418.169 43.5684C411.669 41.9684 407.469 40.5684 405.469 39.1684C403.569 37.8684 402.469 35.6684 402.469 33.3684C402.369 31.0684 403.469 28.8684 405.469 27.6684C407.769 26.3684 410.369 25.6684 413.069 25.7684C416.269 25.7684 419.469 26.2684 422.469 27.3684C425.269 28.2684 427.869 29.6684 430.069 31.4684L435.769 23.4684C432.769 20.9684 429.269 19.0684 425.469 17.8684C421.569 16.5684 417.569 15.8684 413.469 15.9684C407.269 15.9684 401.969 17.5684 397.769 20.6684C393.569 23.7684 391.469 28.3684 391.469 34.1684C391.469 39.9684 393.369 44.2684 397.069 47.1684C398.969 48.6684 401.169 49.9684 403.469 50.7684C406.869 51.9684 410.469 53.0684 414.069 53.7684C418.669 54.8684 421.869 56.1684 423.869 57.4684C425.769 58.7684 426.769 60.8684 426.769 63.1684C426.869 65.4684 425.669 67.6684 423.769 68.9684C421.769 70.3684 419.069 71.1684 415.569 71.1684C408.769 71.1684 402.169 68.3684 395.769 62.8684L389.069 70.8684C396.669 77.6684 405.469 81.0684 415.269 81.0684C422.069 81.0684 427.469 79.3684 431.569 75.9684C435.569 72.7684 437.869 67.7684 437.769 62.5684C437.669 57.0684 435.969 53.1684 432.769 50.1684Z"
        fill="#DF1668"
      />
      <path
        d="M31.6685 75.1685C32.4685 74.8685 33.2685 74.4685 34.0685 74.2685C34.9685 73.9685 35.8685 73.5685 36.7685 73.3685C37.9685 73.0685 39.0685 72.6685 40.2685 72.4685C41.8685 72.1685 43.4685 71.8685 45.0685 71.6685C44.1685 74.5685 43.1685 77.3685 42.0685 80.1685C42.1685 80.6685 42.1685 81.2685 42.1685 81.7685C46.6685 80.8685 51.1685 80.8685 55.6685 81.7685C55.6685 81.2685 55.6685 80.6685 55.7685 80.1685C54.6685 77.3685 53.6685 74.4685 52.7685 71.6685C54.3685 71.8685 55.9685 72.1685 57.5685 72.4685C58.7685 72.6685 59.9685 73.0685 61.0685 73.3685C61.9685 73.6685 62.8685 73.9685 63.7685 74.2685C64.5685 74.5685 65.3685 74.8685 66.1685 75.1685C71.8685 77.6685 76.8685 81.3685 80.9685 86.0685C101.468 68.3685 103.668 37.4685 86.0685 16.9685C68.4685 -3.53153 37.4685 -5.73154 16.9685 11.8685C-3.53154 29.4685 -5.73154 60.4685 11.8685 80.9685C13.4685 82.7685 15.1685 84.4685 16.9685 86.0685C21.0685 81.4685 26.0685 77.7685 31.6685 75.1685Z"
        fill="#B2DEEB"
      />
      <path
        d="M34.9685 84.0685C37.2685 83.0685 39.7685 82.2685 42.2685 81.7685C42.2685 81.2685 42.2685 80.6685 42.1685 80.1685C43.2685 77.3685 44.2685 74.4685 45.1685 71.6685C43.5685 71.8685 41.9685 72.1684 40.3685 72.4684C39.1685 72.6684 37.9685 73.0685 36.8685 73.3685C35.9685 73.6685 35.0685 73.9685 34.1685 74.2685L37.7685 75.0685L34.9685 84.0685Z"
        fill="#5C5A58"
      />
      <path
        d="M26.5685 89.3685C26.6685 88.4685 26.7685 87.4685 26.7685 86.5685C26.7685 86.0685 27.1685 85.5685 27.6685 85.5685C27.6685 85.5685 27.6685 85.5685 27.7685 85.5685H28.6685L31.7685 75.1685C26.0685 77.6685 21.0685 81.3685 16.9685 86.0685C19.2685 88.0685 21.7685 89.7685 24.3685 91.2685C25.0685 90.5685 25.7685 89.9685 26.5685 89.3685Z"
        fill="#5C5A58"
      />
      <path
        d="M66.1685 75.1685L69.2685 85.5685H70.1685C70.6685 85.5685 71.1685 85.9684 71.1685 86.4684C71.1685 86.4684 71.1685 86.4685 71.1685 86.5685C71.1685 87.4685 71.2685 88.4685 71.3685 89.3685C72.1685 90.0685 72.8685 90.7685 73.5685 91.3685C76.1685 89.8685 78.6685 88.0685 80.9685 86.1685C76.9685 81.4685 71.8685 77.7685 66.1685 75.1685Z"
        fill="#5C5A58"
      />
      <path
        d="M60.2686 75.0685L63.7686 74.2685C62.8686 73.9685 61.9686 73.5685 61.0686 73.3685C59.8686 73.0685 58.7686 72.6684 57.5686 72.4684C55.9686 72.1684 54.3686 71.8685 52.7686 71.6685C53.6686 74.5685 54.6686 77.3685 55.7686 80.1685C55.6686 80.6685 55.6686 81.2685 55.6686 81.7685C58.1686 82.2685 60.5686 82.9685 62.9686 84.0685L60.2686 75.0685Z"
        fill="#5C5A58"
      />
      <path
        d="M48.8684 85.5685H55.7684C55.6684 84.2685 55.5684 83.0685 55.5684 81.7685C51.0684 80.8685 46.5684 80.8685 42.0684 81.7685C42.0684 83.0685 42.0684 84.2685 41.8684 85.5685H48.8684Z"
        fill="#010101"
      />
      <path
        d="M24.3684 91.3686C24.9684 91.6686 25.5684 92.0686 26.0684 92.2686C26.2684 91.2686 26.3684 90.3686 26.4684 89.2686C25.6684 89.9686 24.9684 90.6686 24.3684 91.3686Z"
        fill="#010101"
      />
      <path
        d="M34.4685 85.5685H42.0685C42.1685 84.2685 42.2685 83.0686 42.2685 81.7686C39.7685 82.2686 37.3685 82.9685 34.9685 84.0685L34.4685 85.5685Z"
        fill="#010101"
      />
      <path
        d="M71.3684 89.3684C71.4684 90.3684 71.5684 91.3684 71.7684 92.3684C72.3684 92.0684 72.9684 91.7684 73.4684 91.4684C72.8684 90.6684 72.1684 89.9684 71.3684 89.3684Z"
        fill="#010101"
      />
      <path
        d="M63.4686 85.5685L63.0686 84.0685C60.7686 83.0685 58.2686 82.2686 55.7686 81.7686C55.7686 83.0686 55.7686 84.2685 55.9686 85.5685H63.4686Z"
        fill="#010101"
      />
      <path
        d="M34.4684 85.5685L34.8684 84.0685L37.5684 75.0685L34.0684 74.2686C33.2684 74.5686 32.4684 74.8685 31.6684 75.1685L28.5684 85.5685H34.4684Z"
        fill="#B29975"
      />
      <path
        d="M62.8685 84.0685L63.2685 85.5685H69.1685L66.0685 75.1685C65.2685 74.8685 64.4685 74.4686 63.6685 74.2686L60.1685 75.0685L62.8685 84.0685Z"
        fill="#B29975"
      />
      <path
        d="M48.8684 87.5684H28.7684L28.6684 87.6684C28.5684 89.5684 28.2684 91.4684 27.8684 93.3684C34.4684 96.4684 41.5684 98.0684 48.8684 98.0684V87.5684Z"
        fill="#C9B79E"
      />
      <path
        d="M69.2684 87.5684C69.1684 87.6684 69.1684 87.5684 69.2684 87.5684Z"
        fill="#B29975"
      />
      <path
        d="M69.1684 87.5684H49.0684V97.9684C56.3684 97.9684 63.5684 96.3684 70.0684 93.2684C69.6684 91.3684 69.3684 89.4684 69.2684 87.5684C69.1684 87.6684 69.1684 87.5684 69.1684 87.5684Z"
        fill="#B29975"
      />
      <path
        d="M71.3684 89.3684C71.2684 88.4684 71.1684 87.4684 71.1684 86.5684C71.1684 86.0684 70.7684 85.5684 70.2684 85.5684H70.1684H27.4684C26.9684 85.5684 26.4684 85.9684 26.4684 86.4684C26.4684 86.4684 26.4684 86.4684 26.4684 86.5684C26.4684 87.4684 26.3684 88.4684 26.2684 89.3684C26.1684 90.2684 25.9684 91.3684 25.8684 92.3684C26.4684 92.6684 27.0684 92.9684 27.7684 93.2684C28.1684 91.3684 28.4684 89.4684 28.5684 87.5684V87.4684H68.8684V87.5684C68.9684 89.4684 69.1684 91.3684 69.6684 93.2684C70.2684 92.9684 70.9684 92.6684 71.5684 92.3684C71.5684 91.3684 71.4684 90.3684 71.3684 89.3684Z"
        fill="#967C64"
      />
      <path
        d="M62.9684 78.5683C62.7684 78.3683 62.6684 78.1683 62.5684 77.9683C62.6684 78.1683 62.7684 78.4683 62.9684 78.5683Z"
        fill="#5C5A58"
      />
      <path
        d="M81.5684 38.1683C81.1684 37.5683 80.7684 37.0683 80.2684 36.5683C80.0684 36.3683 79.8684 36.1683 79.6684 35.9683C79.5684 35.8683 79.4684 35.7683 79.3684 35.7683L79.0684 35.4683V39.9683L80.8684 47.9683C80.8684 48.0683 80.8684 48.0683 80.9684 48.1683L82.1684 49.4683H83.3684V49.1683C83.3684 45.4683 82.7684 41.8683 81.6684 38.3683C81.5684 38.2683 81.5684 38.2683 81.5684 38.1683Z"
        fill="#C8C6C7"
      />
      <path
        d="M80.9683 48.6683C80.9683 48.8683 81.0683 49.0683 81.0683 49.3683V49.4683V50.1683V50.2683C81.0683 50.4683 80.9683 50.6683 80.9683 50.8683L82.1683 49.5683L80.9683 48.2683C80.8683 48.2683 80.9683 48.5683 80.9683 48.6683Z"
        fill="#727170"
      />
      <path
        d="M80.9684 50.1684C80.9684 49.8684 81.0684 49.7684 81.0684 49.4684V48.5684C81.0684 48.3684 80.9684 48.1684 80.9684 47.9684C80.9684 47.7684 80.9684 47.8684 80.8684 47.7684C80.7684 47.3684 80.6684 47.0684 80.4684 46.7684L80.3684 46.6684C80.2684 46.5684 80.2684 46.4684 80.1684 46.4684L79.8684 46.1684L79.7684 46.0684C79.6684 45.9684 79.5684 45.9684 79.4684 45.9684H79.5684C79.4684 45.9684 79.3684 45.8684 79.2684 45.8684C79.1684 45.8684 79.0684 45.8684 78.9684 45.9684H78.8684C78.7684 45.9684 78.6684 46.0684 78.6684 46.0684C78.6684 46.0684 78.5684 46.0684 78.5684 46.1684L78.2684 46.4684C78.1684 46.5684 78.1684 46.6684 78.0684 46.6684L77.9684 46.7684C77.7684 47.0684 77.5684 47.3684 77.5684 47.7684V47.8684C77.4684 48.0684 77.4684 48.2684 77.4684 48.4684V48.6684C77.4684 48.8684 77.3684 49.0684 77.3684 49.3684V49.4684C77.3684 51.3684 78.1684 52.8684 79.2684 52.8684C79.4684 52.8684 79.5684 52.7684 79.7684 52.6684C79.7684 52.6684 79.8684 52.6684 79.8684 52.5684C79.9684 52.5684 80.0684 52.4684 80.0684 52.3684L80.1684 52.2684L80.3684 52.0684C80.4684 51.9684 80.4684 51.8684 80.5684 51.7684C80.6684 51.6684 80.6684 51.6684 80.6684 51.5684C80.6684 51.4684 80.7684 51.3684 80.8684 51.2684C80.8684 51.2684 80.8684 51.1684 80.9684 51.1684C81.0684 50.9684 81.0684 50.8684 81.1684 50.6684C80.8684 50.6684 80.9684 50.4684 80.9684 50.1684ZM79.1684 49.0684C78.7684 49.0684 78.3684 48.4684 78.3684 47.7684C78.3684 47.0684 78.6684 46.4684 79.1684 46.4684C79.6684 46.4684 79.9684 47.0684 79.9684 47.7684C79.9684 48.4684 79.5684 49.0684 79.1684 49.0684Z"
        fill="#010101"
      />
      <path
        d="M79.0683 49.0683C79.5101 49.0683 79.8683 48.4863 79.8683 47.7683C79.8683 47.0503 79.5101 46.4683 79.0683 46.4683C78.6265 46.4683 78.2683 47.0503 78.2683 47.7683C78.2683 48.4863 78.6265 49.0683 79.0683 49.0683Z"
        fill="white"
      />
      <path
        d="M77.3684 47.9683C77.4684 47.5683 77.5684 47.2683 77.7684 46.9683L77.8684 46.8683C77.9684 46.7683 77.9684 46.6683 78.0684 46.6683L78.3684 46.3683L78.4684 46.2683C78.5684 46.1683 78.6684 46.1683 78.7684 46.1683H78.8684C78.9684 46.1683 79.0684 46.0683 79.1684 46.0683C79.2684 46.0683 79.3684 46.0683 79.4684 46.1683H79.5684C79.6684 46.1683 79.7684 46.2683 79.8684 46.2683C79.8684 46.2683 79.9684 46.2683 79.9684 46.3683L80.2684 46.6683C80.3684 46.7683 80.3684 46.8683 80.4684 46.8683L80.5684 46.9683C80.7684 47.2683 80.8684 47.5683 80.9684 47.9683L79.1684 39.9683L77.3684 47.9683Z"
        fill="#9C9B9B"
      />
      <path
        d="M55.8683 49.3683V48.8683C55.8683 48.7683 55.8683 48.5683 55.7683 48.4683C55.7683 48.2683 55.6683 48.1683 55.6683 48.0683C55.5683 47.8683 55.5683 47.7683 55.4683 47.6683C55.3683 47.5683 55.2683 47.3683 55.2683 47.2683C55.0683 46.8683 54.7683 46.4683 54.4683 46.1683C54.0683 45.7683 53.5683 45.4683 53.0683 45.2683C52.7683 45.1683 52.5683 45.0683 52.2683 45.0683C51.9683 44.9683 51.6683 44.9683 51.4683 44.9683C51.1683 44.9683 50.8683 44.9683 50.6683 45.0683L49.8683 45.2683C49.5683 45.3683 49.2683 45.4683 49.0683 45.6683C48.7683 45.8683 48.6683 45.9683 48.3683 46.1683C48.0683 46.4683 47.7683 46.8683 47.5683 47.2683C47.4683 47.3683 47.4683 47.4683 47.3683 47.5683C47.2683 47.6683 47.2683 47.7683 47.1683 47.8683V47.9683C47.1683 48.0683 47.0683 48.2683 47.0683 48.2683V48.3683C47.0683 48.4683 47.0683 48.5683 46.9683 48.6683V49.2683C46.9683 49.5683 46.9683 49.8683 47.0683 50.1683C47.1683 50.4683 47.2683 50.7683 47.2683 50.9683C47.4683 51.4683 47.7683 51.9683 48.1683 52.3683C48.4683 52.6683 48.8683 52.9683 49.2683 53.1683C49.3683 53.2683 49.5683 53.3683 49.6683 53.3683C49.7683 53.4683 49.9683 53.4683 49.9683 53.5683H50.0683C50.1683 53.5683 50.3683 53.6683 50.3683 53.6683H50.4683C50.5683 53.6683 50.6683 53.6683 50.7683 53.7683H51.3683C51.9683 53.7683 52.5683 53.6683 53.1683 53.4683C53.4683 53.3683 53.7683 53.2683 53.9683 53.0683C54.4683 52.7683 54.8683 52.2683 55.2683 51.7683C55.4683 51.5683 55.5683 51.2683 55.6683 50.9683C55.8683 50.4683 55.9683 49.8683 55.8683 49.3683ZM51.3683 48.8683C50.3683 48.8683 49.5683 48.0683 49.5683 47.0683C49.5683 46.0683 50.3683 45.2683 51.3683 45.2683C52.3683 45.2683 53.1683 46.0683 53.1683 47.0683C53.0683 48.0683 52.2683 48.8683 51.3683 48.8683Z"
        fill="#010101"
      />
      <path
        d="M51.3684 48.8683C52.3625 48.8683 53.1684 48.0624 53.1684 47.0683C53.1684 46.0742 52.3625 45.2683 51.3684 45.2683C50.3742 45.2683 49.5684 46.0742 49.5684 47.0683C49.5684 48.0624 50.3742 48.8683 51.3684 48.8683Z"
        fill="white"
      />
      <path
        d="M48.1685 46.1684L51.3685 36.4684V34.1684C49.5685 33.7684 47.7685 33.5684 45.8685 33.5684C45.2685 33.5684 44.6685 33.5684 43.9685 33.6684H43.6685C40.4685 33.9684 37.3685 34.7684 34.3685 36.0684C31.8685 37.1684 29.5685 38.6684 27.4685 40.4684C26.8685 40.9684 26.3685 41.4684 25.8685 41.9684L25.6685 42.1684C25.1685 42.6684 24.7685 43.1684 24.2685 43.6684C24.1685 43.7684 24.0685 43.8684 23.9685 43.9684C23.5685 44.4684 23.0685 45.0684 22.5685 45.6684C22.5685 45.7684 22.4685 45.7684 22.4685 45.8684C22.0685 46.4684 21.6685 46.9684 21.2685 47.5684C21.1685 47.6684 21.0685 47.8684 20.9685 47.8684C20.6685 48.3684 20.3685 48.8684 20.1685 49.2684H34.5685L48.1685 46.1684Z"
        fill="#C8C6C7"
      />
      <path
        d="M60.3683 45.0684C60.3683 44.7684 60.4683 44.2684 60.4683 43.9684C60.4683 43.4684 60.5683 42.9684 60.5683 42.4684V40.9684C60.5683 39.9684 60.4683 38.9684 60.4683 37.8684H60.3683C60.1683 37.7684 59.6683 37.5684 59.2683 37.2684C59.1683 37.1684 59.0683 37.1684 58.9683 37.0684C58.9683 37.0684 58.8683 36.9684 58.7683 36.9684C58.6683 36.9684 58.6683 36.8684 58.5683 36.8684C57.8683 36.5684 57.2683 36.1684 56.5683 35.9684C54.8683 35.3684 53.1683 34.7684 51.4683 34.3684V36.6684L54.5683 46.3684C54.8683 46.6684 55.1683 47.0684 55.3683 47.4684C55.4683 47.6684 55.5683 47.7684 55.5683 47.8684C55.6683 48.0684 55.6683 48.1684 55.7683 48.2684C55.8683 48.3684 55.8683 48.5684 55.8683 48.6684C55.8683 48.7684 55.8683 48.9684 55.9683 49.0684V49.5684H59.8683C60.0683 48.1684 60.2683 46.6684 60.4683 45.2684V45.0684H60.3683Z"
        fill="#E9E4E3"
      />
      <path
        d="M55.9684 63.2685L58.4684 60.7685C58.9684 60.2685 59.3684 59.8685 59.7684 59.3685C62.9684 55.8685 65.3684 52.5685 66.7684 48.3685C66.7684 48.2685 66.8684 48.0684 66.8684 47.9684V47.8685C66.8684 47.6685 66.9684 47.5685 66.9684 47.3685V46.9684C66.5684 45.1684 65.9684 43.2685 65.2684 41.5685L64.9684 41.2685C64.6684 40.9685 64.1684 40.5685 63.8684 40.2685C62.7684 39.2685 61.5684 38.4685 60.3684 37.6685C60.4684 38.6685 60.4684 39.6685 60.4684 40.7685V42.2685C60.4684 42.7685 60.4684 43.2685 60.3684 43.7685C60.3684 44.0685 60.2684 44.5685 60.2684 44.8685V44.9684C60.1684 46.3684 59.9684 47.8685 59.6684 49.2685L59.3684 51.6685C58.4684 55.4685 56.4684 58.9685 54.8684 62.5685C54.4684 63.4685 54.0684 64.4684 53.6684 65.4684C53.6684 65.5684 53.5684 65.6685 53.5684 65.7685L54.8684 64.4684C55.1684 63.9684 55.6684 63.6685 55.9684 63.2685Z"
        fill="#F8B232"
      />
      <path
        d="M48.1685 46.0684C48.3685 45.8684 48.5685 45.6684 48.8685 45.5684C49.0685 45.3684 49.3685 45.3684 49.6685 45.1684C49.9685 45.0684 50.1685 44.9684 50.4685 44.9684C50.7685 44.8684 50.9685 44.8684 51.2685 44.8684C51.5685 44.8684 51.8685 44.8684 52.0685 44.9684L52.8685 45.1684C53.3685 45.3684 53.8685 45.6684 54.2685 46.0684L51.1685 36.3684L48.1685 46.0684Z"
        fill="#9C9B9B"
      />
      <path
        d="M47.0683 51.2683C46.9683 50.9683 46.8683 50.6683 46.7683 50.4683C46.6683 50.1683 46.6683 49.8683 46.6683 49.5683V48.9683C46.6683 48.8683 46.6683 48.7683 46.7683 48.6683V48.5683C46.7683 48.4683 46.8683 48.2683 46.8683 48.2683V48.1683C46.8683 48.0683 46.9683 47.9683 47.0683 47.8683C47.1683 47.6683 47.1683 47.5683 47.2683 47.5683C47.4683 47.1683 47.7683 46.7683 48.0683 46.4683L34.7683 49.7683L48.0683 52.9683C47.5683 52.2683 47.2683 51.7683 47.0683 51.2683Z"
        fill="#727170"
      />
      <path
        d="M77.1683 48.6683V48.4683C77.1683 48.2683 77.2683 48.1683 77.2683 47.8683V47.7683L79.0683 39.7683V35.1683C77.7683 34.0683 76.1683 33.3683 74.4683 33.2683C72.4683 33.3683 70.5683 34.2683 69.1683 35.6683C67.6683 37.2683 66.3683 39.0683 65.4683 40.9683C65.3683 41.0683 65.3683 41.1683 65.2683 41.2683C65.5683 41.5683 65.8683 41.7683 66.0683 42.0683C66.7683 42.6683 67.3683 43.2683 67.9683 43.9683L68.7683 44.7683L69.6683 45.6683C70.0683 46.0683 70.4683 46.4683 70.8683 46.9683L71.3683 47.4683C71.7683 47.9683 72.2683 48.4683 72.6683 48.9683L72.8683 49.1683H76.9683V49.0683C77.0683 49.0683 77.0683 48.8683 77.1683 48.6683Z"
        fill="#E9E4E3"
      />
      <path
        d="M17.9684 54.4683C18.1684 53.6683 18.4684 53.0683 18.7684 52.4683L19.0684 51.9683L19.8684 50.4683C19.9684 50.2683 20.0684 50.0684 20.1684 49.8684C20.2684 49.7684 20.2684 49.6683 20.3684 49.5683C20.6684 49.0683 20.9684 48.5683 21.1684 48.1683C21.2684 48.0683 21.3684 47.8684 21.3684 47.8684C21.7684 47.2684 22.0684 46.6683 22.5684 46.1683C22.5684 46.0683 22.6684 46.0683 22.6684 45.9683C23.0684 45.3683 23.4684 44.8683 23.9684 44.2683C24.0684 44.1683 24.1684 44.0683 24.2684 43.9683C24.6684 43.4683 25.1684 42.9683 25.6684 42.4683L25.9684 42.1683C26.4684 41.6683 27.0684 41.1683 27.5684 40.6683C29.7684 33.1683 36.6684 27.9684 44.4684 27.8684C47.6684 27.8684 50.8684 28.7683 53.5684 30.5683C56.3684 32.2683 58.6684 34.7683 60.1684 37.7683V37.8684C60.1684 37.9684 60.2684 38.0683 60.2684 38.1683C61.5684 38.8683 62.7684 39.7683 63.7684 40.7683C64.0684 41.0683 64.5684 41.4683 64.8684 41.7683L65.1684 42.0683C65.1684 41.9683 65.2684 41.8683 65.2684 41.7683C66.3684 34.6683 69.4684 29.5683 73.1684 29.5683C75.9684 29.5683 78.3684 32.4684 79.9684 36.8684V36.9683L81.2684 38.4683C81.2684 38.5683 81.2684 38.6683 81.3684 38.6683L81.2684 38.4683C76.5684 24.4683 63.4684 15.0683 48.6684 15.0683C29.9684 14.8683 14.5684 30.2683 14.5684 49.2683C14.5684 52.8683 15.1684 56.4683 16.2684 59.9683L17.0684 56.9683C17.3684 55.9683 17.6684 55.1683 17.9684 54.4683Z"
        fill="#5C5A58"
      />
      <path
        d="M82.0683 49.4683L80.8683 50.7683C80.7683 50.9683 80.7683 51.0683 80.6683 51.2683C80.6683 51.2683 80.6683 51.3683 80.5683 51.3683C80.4683 51.4683 80.4683 51.6683 80.3683 51.6683C80.2683 51.6683 80.2683 51.7683 80.2683 51.8683C80.2683 51.9683 80.1683 52.0683 80.0683 52.0683L79.7683 52.3683L79.6683 52.4683C79.5683 52.5683 79.4683 52.5683 79.4683 52.6683C79.4683 52.7683 79.3683 52.6683 79.3683 52.7683C79.1683 52.8683 79.0683 52.9683 78.8683 52.9683C77.7683 52.9683 76.9683 51.4683 76.9683 49.5683H72.9683C73.7683 50.3683 74.3683 51.2683 75.1683 52.1683L77.1683 54.6683L77.2683 54.7683C77.9683 55.5683 78.5683 56.3683 79.1683 57.1683C79.2683 57.2683 79.2683 57.3683 79.3683 57.4683C80.0683 58.2683 80.6683 59.2683 81.2683 60.0683C82.3683 56.6683 82.9683 53.0683 83.0683 49.4683H82.0683Z"
        fill="white"
      />
      <path
        d="M43.7683 33.5685H44.0683C44.6683 33.5685 45.3683 33.4684 45.9683 33.4684C47.7683 33.4684 49.6683 33.6685 51.4683 34.0685V33.9684C53.2683 34.3684 54.9683 34.8685 56.5683 35.5685C57.2683 35.8685 57.9683 36.1684 58.5683 36.4684C58.5683 36.4684 58.6683 36.5685 58.7683 36.5685C58.8683 36.5685 58.8683 36.5685 58.9683 36.6685C59.0683 36.7685 59.1683 36.7685 59.2683 36.8685C59.6683 37.1685 60.1683 37.3684 60.3683 37.4684H60.4683C60.4683 37.3684 60.3683 37.2685 60.3683 37.1685V37.0685C58.7683 34.2685 56.4683 31.9685 53.7683 30.2685C51.0683 28.5685 47.8683 27.6685 44.6683 27.6685C36.8683 27.7685 29.9683 32.9684 27.7683 40.4684C29.8683 38.6684 32.1683 37.1685 34.6683 36.0685C37.4683 34.6685 40.5683 33.8685 43.7683 33.5685Z"
        fill="#010101"
      />
      <path
        d="M69.2685 36.0683C70.6685 34.5683 72.5685 33.7683 74.5685 33.6683C76.2685 33.7683 77.8685 34.3683 79.1685 35.5683C79.3685 35.6683 79.3685 35.8683 79.4685 35.8683C79.5685 35.9683 79.6685 36.0683 79.7685 36.0683L80.3685 36.6683V36.5683C78.8685 32.0683 76.3685 29.2683 73.5685 29.2683C69.8685 29.2683 66.7685 34.3683 65.6685 41.4683C66.5685 39.3683 67.7685 37.5683 69.2685 36.0683Z"
        fill="#010101"
      />
      <path
        d="M36.6684 81.0684C36.9684 81.1684 37.2684 81.3684 37.5684 81.3684C41.1684 82.6684 45.0684 83.3684 48.8684 83.2684C53.2684 83.2684 57.5684 82.3684 61.6684 80.8684C61.8684 80.7684 62.0684 80.6684 62.2684 80.5684C62.5684 80.3684 62.9684 80.2684 63.2684 80.1684C63.5684 80.0684 63.8684 79.8684 64.0684 79.7684H63.7684C62.5684 79.9684 61.3684 79.9684 60.1684 79.7684C53.0684 78.7684 51.5684 75.3684 51.7684 71.8684C51.2684 71.5684 50.7684 71.2684 50.2684 70.8684C47.0684 68.4684 44.1684 65.7684 41.5684 62.6684C44.7684 64.9684 48.3684 66.8684 52.0684 68.3684C52.2684 68.4684 52.3684 68.4684 52.4684 68.5684C52.7684 67.5684 53.1684 66.6684 53.3684 65.7684C53.3684 65.6684 53.4684 65.5684 53.4684 65.4684C53.7684 64.4684 54.1684 63.5684 54.6684 62.5684C56.2684 58.9684 58.1684 55.4684 59.1684 51.6684L59.5684 49.5684H55.9684C55.9684 50.1684 55.8684 50.7684 55.6684 51.3684C55.5684 51.6684 55.4684 51.8684 55.2684 52.1684C54.9684 52.6684 54.4684 53.0684 53.9684 53.4684C53.7684 53.6684 53.4684 53.7684 53.1684 53.8684C52.5684 54.0684 51.9684 54.1684 51.3684 54.1684H50.7684C50.6684 54.1684 50.5684 54.1684 50.4684 54.0684H50.3684C50.2684 54.0684 50.0684 53.9684 50.0684 53.9684H49.9684C49.8684 53.8684 49.6684 53.8684 49.6684 53.7684C49.4684 53.6684 49.4684 53.6684 49.2684 53.5684C48.8684 53.3684 48.4684 53.0684 48.1684 52.7684L34.9684 49.5684H20.3684C20.2684 49.6684 20.2684 49.7684 20.1684 49.8684C20.0684 50.0684 19.8684 50.2684 19.8684 50.4684L19.0684 51.9684L18.7684 52.4684C18.5684 53.1684 18.1684 53.7684 17.9684 54.3684V54.4684C17.6684 55.1684 17.3684 55.9684 17.1684 56.7684C16.8684 57.7684 16.5684 58.7684 16.3684 59.7684C18.6684 66.6684 22.9684 72.6684 28.8684 76.8684C29.0684 76.9684 29.1684 77.1684 29.3684 77.1684C30.3684 77.8684 31.3684 78.4684 32.3684 79.0684C33.7684 79.9684 35.2684 80.5684 36.6684 81.0684Z"
        fill="white"
      />
      <path
        d="M60.4684 70.4683C60.3684 70.8683 60.3684 71.1683 60.3684 71.5683C60.3684 72.1683 60.3684 72.7683 60.4684 73.3683C60.5684 73.7683 60.6684 74.1683 60.7684 74.4683C60.9684 74.9683 61.0684 75.4683 61.2684 75.8683C61.4684 76.2683 61.6684 76.7683 61.9684 77.1683C62.0684 77.2683 62.1684 77.4683 62.2684 77.4683C62.3684 77.4683 62.4684 77.7683 62.5684 77.9683C62.7684 78.1683 62.8684 78.3683 62.9684 78.5683C63.2684 78.9683 63.6684 79.3683 64.0684 79.6683V79.7683H64.2684C65.8684 79.5683 67.3684 79.1683 68.8684 78.5683C69.6684 78.2683 70.3684 77.9683 71.1684 77.6683C71.4684 77.5683 71.5684 77.4683 71.8684 77.3683C79.7684 73.5683 86.2684 66.7683 86.2684 66.7683C76.4684 71.7683 66.8684 71.4683 60.4684 70.4683Z"
        fill="#A32553"
      />
      <path
        d="M79.3684 57.2683C78.7684 56.4683 78.0684 55.6683 77.4684 54.8683L77.3684 54.7683L75.3684 52.2683C74.6684 51.4683 73.9684 50.5683 73.1684 49.6683L72.9684 49.4683C72.5684 48.9683 72.0684 48.4683 71.5684 47.9683L71.0684 47.4683C70.6684 47.0683 70.2684 46.6683 69.8684 46.1683L68.9684 45.2683L68.1684 44.4683C67.5684 43.8683 66.8684 43.1683 66.2684 42.5683C66.0684 42.2683 65.6684 42.0683 65.4684 41.7683C66.1684 43.5683 66.7684 45.3683 67.1684 47.2683C67.2684 47.7683 67.3684 48.1683 67.4684 48.6683C68.2684 55.0683 66.2684 59.2683 62.9684 64.8683C62.7684 65.2683 62.4684 65.6683 62.1684 66.0683C61.4684 67.1683 60.9684 68.3683 60.6684 69.5683C60.5684 69.8683 60.4684 70.3683 60.3684 70.6683C66.7684 71.6683 76.4684 71.9683 86.3684 67.0683C86.3684 67.0683 84.4684 64.1683 81.4684 60.2683C80.7684 59.4683 80.1684 58.4683 79.5684 57.6683C79.5684 57.3683 79.4684 57.2683 79.3684 57.2683Z"
        fill="#DF1668"
      />
      <path
        d="M64.0683 79.6684C63.6683 79.3684 63.2683 78.9684 62.9683 78.5684C62.7683 78.3684 62.6683 78.1684 62.5683 77.9684C62.4683 77.7684 62.2683 77.6684 62.2683 77.4684C62.2683 77.2684 62.0683 77.1684 62.0683 77.1684C61.7683 76.7684 61.5683 76.3684 61.3683 75.8684C61.1683 75.3684 60.9683 74.9684 60.8683 74.4684C60.7683 74.1684 60.6683 73.7684 60.5683 73.3684C60.4683 72.7684 60.4683 72.1684 60.4683 71.5684C60.4683 71.2684 60.5683 70.8684 60.5683 70.4684C60.5683 70.0684 60.6683 69.7684 60.8683 69.3684C61.1683 68.0684 61.6683 66.8684 62.3683 65.8684C62.6683 65.4684 62.8683 65.0684 63.0683 64.6684C66.3683 59.0684 68.3683 54.9684 67.5683 48.4684C67.4683 48.0684 67.3683 47.5684 67.2683 47.0684C67.1683 47.3684 67.1683 47.6684 67.0683 47.8684V47.9684C67.0683 48.1684 66.9683 48.2684 66.9683 48.3684C65.5683 52.5684 63.1683 55.8684 59.9683 59.3684C59.5683 59.8684 59.0683 60.2684 58.6683 60.7684C57.8683 61.6684 57.0683 62.4684 56.1683 63.2684L55.0683 64.3684L53.7683 65.6684C53.3683 66.5684 53.0683 67.4684 52.8683 68.4684C52.6683 68.3684 52.5683 68.3684 52.4683 68.2684C48.7683 66.8684 45.2683 64.9684 41.9683 62.5684C44.4683 65.6684 47.3683 68.4684 50.6683 70.7684C51.1683 71.1684 51.6683 71.4684 52.1683 71.7684C51.8683 75.2684 53.4683 78.6684 60.5683 79.6684C61.7683 79.9684 62.9683 79.9684 64.0683 79.6684C64.0683 79.7684 64.0683 79.7684 64.0683 79.6684Z"
        fill="#010101"
      />
    </svg>
  );
}

SvgPrestashop.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

SvgPrestashop.defaultProps = {
  height: 15,
  width: 78,
};

export default SvgPrestashop;

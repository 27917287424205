import { Button, Col, Form, Input, InputNumber, Modal, Row } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import openNotification from '../../../../../../components/Toastr';
import boxApi from '../../../../../../api/box';
import '../FedexSend.css';

function CreateBoxModal({
  showCreateBoxModal,
  setShowCreateBoxModal,
  unitMeasure,
  callback,
  isPallet,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const lengthUnit = unitMeasure === 'CM' || unitMeasure === '' ? 'cm' : 'inch';
  const maxBoxLengthUnit = { cm: 250, inch: 99 };
  const maxPalletLengthUnit = { cm: 250, inch: 99 };
  const formatterNumber = (value) => value.replace(',', '.');
  const parserNumber = (value) => parseFloat(value.replace(',', '.'));

  const onCancel = () => {
    form.resetFields();
    setShowCreateBoxModal(false);
  };

  const onFinish = async (formFields) => {
    const payload = {
      length: formFields.length,
      width: formFields.width,
      height: formFields.height,
      name: formFields.name,
      measureUnit: unitMeasure,
      isPallet,
    };
    try {
      setLoading(true);
      const response = await boxApi.create(payload);
      callback(response.data);
      openNotification({
        status: true,
        content: 'Creado correctamente',
      });
      setShowCreateBoxModal(false);
    } catch (error) {
      openNotification({
        status: false,
        content: 'No se pudo crear',
      });
    }
    setLoading(false);
  };

  return (
    <Modal
      open={showCreateBoxModal}
      closable
      footer={null}
      onCancel={onCancel}
      width={800}
      className="newBoxModal"
    >
      <Row justify="center">
        <div className="titleNewBoxModal">
          {isPallet ? t('orders.pallets.create') : t('orders.boxes.create')}
        </div>
      </Row>
      <Form
        form={form}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        className="text-align-left"
        autoComplete="off"
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              label={
                isPallet ? t('orders.pallets.name') : t('orders.boxes.name')
              }
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Requerido',
                },
              ]}
            >
              <Input
                placeholder={
                  isPallet
                    ? t('orders.pallets.examplePalletName')
                    : t('orders.boxes.exampleBoxName')
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Largo"
              name="length"
              rules={[
                {
                  required: true,
                  message: 'Requerido',
                },
              ]}
            >
              <InputNumber
                addonAfter={<Text>{lengthUnit.toUpperCase()}</Text>}
                placeholder={t('orders.boxes.lengthPlaceholder')}
                min={0}
                max={
                  isPallet
                    ? maxPalletLengthUnit[lengthUnit]
                    : maxBoxLengthUnit[lengthUnit]
                }
                formatter={formatterNumber}
                parser={parserNumber}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t('orders.boxes.width')}
              name="width"
              rules={[
                {
                  required: true,
                  message: 'Requerido',
                },
              ]}
            >
              <InputNumber
                addonAfter={<Text>{lengthUnit.toUpperCase()}</Text>}
                placeholder={t('orders.boxes.widthPlaceholder')}
                min={0}
                max={
                  isPallet
                    ? maxPalletLengthUnit[lengthUnit]
                    : maxBoxLengthUnit[lengthUnit]
                }
                formatter={formatterNumber}
                parser={parserNumber}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t('orders.boxes.height')}
              name="height"
              rules={[
                {
                  required: true,
                  message: 'Requerido',
                },
              ]}
            >
              <InputNumber
                addonAfter={<Text>{lengthUnit.toUpperCase()}</Text>}
                placeholder={t('orders.boxes.heightPlaceholder')}
                min={0}
                max={
                  isPallet
                    ? maxPalletLengthUnit[lengthUnit]
                    : maxBoxLengthUnit[lengthUnit]
                }
                formatter={formatterNumber}
                parser={parserNumber}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 30, justifyContent: 'right' }}>
          <Button
            loading={loading}
            className="saveNewBoxButton"
            htmlType="submit"
          >
            <SaveOutlined className="saveNewBoxButtonIcon" />
            {isPallet ? t('orders.pallets.save') : t('orders.boxes.save')}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}

CreateBoxModal.propTypes = {
  showCreateBoxModal: PropTypes.bool.isRequired,
  setShowCreateBoxModal: PropTypes.func.isRequired,
  unitMeasure: PropTypes.string.isRequired,
  callback: PropTypes.func,
  isPallet: PropTypes.bool,
};

CreateBoxModal.defaultProps = {
  callback: () => {},
  isPallet: false,
};

export default CreateBoxModal;

import React, { useEffect, useState } from 'react';
import { Col, Divider, Spin, Row, Typography } from 'antd';
import {
  CheckOutlined,
  CodeSandboxOutlined,
  ExclamationCircleTwoTone,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import openNotification from '../../../../components/Toastr';
import restockOrdersAPI from '../../../../api/restock-orders';
import couriersApi from '../../../../api/courier';
import './steps.css';
import ReviewShipment from './components/ReviewShipment';
import ReviewBoxes from './components/ReviewBoxes';
import ReviewDetailContent from './components/ReviewDetailContent';
import ReviewLabelsDocuments from './components/ReviewLabelsDocuments';
import InformationBox from './components/InformationBox';
import ShipmentTypeTag from '../../../shipments/components/ShipmentTypeTag';
import ShipmentStatusTag from '../../../shipments/components/ShipmentStatusTag';
import FBMCouriers from '../../../../utils/FBMCouriers';

function FullOrder({ orderId }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();
  const [isPartnered, setIsPartnered] = useState(null);
  const [fdaProducts, setFdaProducts] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState({});
  const [commercialInvoiceRequirements, setCommercialInvoiceRequirements] =
    useState();
  const language = useSelector((store) => store.Session.language);

  const getOrder = async () => {
    setLoading(true);
    try {
      const restockResponse = await restockOrdersAPI.getContent(orderId);
      setOrder(restockResponse.data);
      const partnered =
        restockResponse.data.warehouse.address.countryCode ===
        restockResponse.data.destination;
      setIsPartnered(partnered);
      setFdaProducts(
        restockResponse.data.products.filter(
          (product) => product.expirationDate !== null
        )
      );
      const courierResponse = await couriersApi.getCourierCredentials(false, 1);
      const data = courierResponse.data.results.map((value) => ({
        key: value.id,
        name: value.courierCompanyName,
        accountNumber: value.accountNumber,
      }));
      const actualCourier = data.find(
        (courier) => courier.key === Number(restockResponse.data.courier)
      );
      setSelectedCourier(actualCourier);
    } catch (error) {
      openNotification({
        status: false,
        content: 'Error al obtener orden de restock.',
      });
    }
    setLoading(false);
  };

  const getCommercialInvoiceRequirements = async () => {
    if (selectedCourier === FBMCouriers.FEDEX) {
      try {
        const response = await couriersApi.commercialInvoiceRequirements(
          selectedCourier.name,
          order.warehouse.address.countryCode,
          order.destination
        );
        setCommercialInvoiceRequirements(response.data);
      } catch (error) {
        /* empty */
      }
    }
  };

  useEffect(async () => {
    if (!order) {
      getOrder();
    }
  }, []);

  useEffect(() => {
    if (order && selectedCourier?.name) getCommercialInvoiceRequirements();
  }, [order, selectedCourier]);

  return (
    <div id="boxes" className="text-align-left">
      <Spin spinning={loading}>
        <Row style={{ fontSize: '24px', fontWeight: 500 }}>
          {t('orders.shipment.summary.title2')}
        </Row>
        <Row
          style={{ fontSize: '16px', marginBottom: '20px' }}
          justify="space-between"
        >
          <Col>[RESTOCK] {order?.name}</Col>
          <Col>
            <Row>
              <ShipmentTypeTag
                text={t('shipments.types.restock')}
                icon={<CodeSandboxOutlined />}
              />
              <Divider type="vertical" />
              {new Date(order?.createdAt).toLocaleDateString(language, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
              <Divider type="vertical" />
              <ShipmentStatusTag status={order?.state} type="RestockOrder" />
            </Row>
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col span={24} style={{ marginBottom: '20px' }}>
            <InformationBox
              icon={
                <ExclamationCircleTwoTone twoToneColor="rgb(250, 173, 20, .5)" />
              }
              textInformationBox={
                <div style={{ width: '100%' }}>
                  <Typography.Paragraph className="w-600">
                    {t('orders.shipment.summary.successfullShipment.title')}
                  </Typography.Paragraph>
                  <Typography.Paragraph className="icon-color">
                    <CheckOutlined />{' '}
                    {t(
                      'orders.shipment.summary.successfullShipment.amazonLabel'
                    )}
                  </Typography.Paragraph>
                  <Typography.Paragraph className="icon-color">
                    <CheckOutlined />{' '}
                    {t('orders.shipment.summary.successfullShipment.boxLabel')}
                  </Typography.Paragraph>
                </div>
              }
              status="warning"
              display="block"
            />
          </Col>
          <Col span={24}>
            <ReviewDetailContent
              products={order?.products}
              selectedCourier={selectedCourier}
              name={`[RESTOCK] ${order?.name}`}
              destination={`${order?.fboFulfillmentCompany} ${order?.destination}`}
              warehouse={order?.warehouse}
              shipmentType="Restock"
              isNational={isPartnered}
              boxType={order?.isPack ? 'Uniforme' : 'Mixta'}
              cargoType={order?.isPalletized ? 'Paletizada' : 'Individual'}
            />
          </Col>
          <Col span={24}>
            <ReviewBoxes order={order} isNational={isPartnered} />
          </Col>
          {order?.courierShipments && (
            <Col span={24}>
              <ReviewShipment
                courierShipments={order?.courierShipments}
                order={order}
                selectedCourier={selectedCourier}
                getOrder={getOrder}
              />
            </Col>
          )}
          <Col span={24}>
            <ReviewLabelsDocuments
              restockOrder={order}
              courierShipments={order?.courierShipments}
              isNational={isPartnered}
              fdaProducts={fdaProducts}
              commercialInvoiceRequirements={commercialInvoiceRequirements}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

FullOrder.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default FullOrder;

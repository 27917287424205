import createReducer from '../store/createReducer';
import * as types from './types';

const initialState = {
  state: null,
  step: null,
};

const Onboarding = createReducer(initialState, {
  [types.START_ONBOARDING](state) {
    return {
      ...state,
      state: null,
      step: null,
    };
  },
  [types.UPDATE_ONBOARDING](state, action) {
    const { state: newState, step } = action.payload;
    return {
      ...state,
      state: newState,
      step,
    };
  },
  [types.COMPLETE_ONBOARDING](state) {
    return {
      ...state,
      state: 'Complete',
      step: null,
    };
  },
});

export default Onboarding;

import * as React from 'react';
import PropTypes from 'prop-types';

function SvgCoppel({ height, width }) {
  return (
    <svg
      height={height || 15}
      width={width || 78}
      viewBox="0 0 74 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6908 0H0.602929C0.269772 0 0 0.274921 0 0.613969V14.2898C0 14.6282 0.269772 14.9028 0.602929 14.9028H18.6908C19.0236 14.9028 19.294 14.6282 19.294 14.2898V0.613969C19.294 0.274921 19.0236 0 18.6908 0Z"
        fill="#3160A9"
      />
      <path
        d="M37.7865 10.6429C37.7809 10.6429 37.775 10.6423 37.7689 10.6423C37.7628 10.6423 37.7572 10.6429 37.7511 10.6429C36.5741 10.6429 35.763 9.7201 35.763 8.59945C35.763 7.47945 36.5741 6.55629 37.7511 6.55629C37.7572 6.55629 37.7628 6.55691 37.7689 6.55691C37.775 6.55691 37.7809 6.55629 37.7865 6.55629C38.9639 6.55629 39.7751 7.47945 39.7751 8.59945C39.7751 9.7201 38.9639 10.6429 37.7865 10.6429ZM37.7689 5.13184C35.8421 5.13184 34.2441 6.54262 34.2427 8.59945C34.2441 10.6569 35.8421 12.0677 37.7689 12.0677C39.6957 12.0677 41.2935 10.6569 41.2947 8.59945C41.2935 6.54262 39.6957 5.13184 37.7689 5.13184Z"
        fill="#3160A9"
      />
      <path
        d="M32.7508 9.42106C32.1684 10.1293 31.2933 10.5804 30.3137 10.5804C28.5612 10.5804 27.1402 9.13566 27.1402 7.35314C27.1402 5.57154 28.5612 4.12647 30.3137 4.12647C31.283 4.12647 32.1488 4.56773 32.7314 5.26328L33.8348 4.27947C32.9703 3.27535 31.6898 2.63916 30.2604 2.63916C27.657 2.63916 25.5459 4.74995 25.5459 7.35314C25.5459 9.95695 27.657 12.0677 30.2604 12.0677C31.6998 12.0677 32.9878 11.423 33.8524 10.4065L32.7508 9.42106Z"
        fill="#3160A9"
      />
      <path
        d="M53.0472 10.6412H53.0294H53.0116C51.8326 10.6412 51.0202 9.71713 51.0202 8.59523C51.0202 7.47301 51.8326 6.54825 53.0116 6.54825C53.0175 6.54825 53.0236 6.54887 53.0294 6.54887C53.0358 6.54887 53.0409 6.54825 53.0472 6.54825C54.2256 6.54825 55.0384 7.47301 55.0384 8.59523C55.0384 9.71713 54.2256 10.6412 53.0472 10.6412ZM53.0294 5.12158C51.0998 5.12158 49.498 6.54825 49.498 8.59523V14.8952H51.0202V11.4828C51.5932 11.8581 52.2859 12.0679 53.0294 12.0679C54.9588 12.0679 56.559 10.6549 56.5606 8.59523C56.559 6.53458 54.9588 5.12158 53.0294 5.12158Z"
        fill="#3160A9"
      />
      <path
        d="M45.4147 10.6412H45.3969H45.3789C44.2005 10.6412 43.3876 9.71713 43.3876 8.59523C43.3876 7.47301 44.2005 6.54825 45.3789 6.54825C45.3854 6.54825 45.391 6.54887 45.3969 6.54887C45.4032 6.54887 45.4084 6.54825 45.4147 6.54825C46.5933 6.54825 47.4059 7.47301 47.4059 8.59523C47.4059 9.71713 46.5933 10.6412 45.4147 10.6412ZM45.3969 5.12158C43.4675 5.12158 41.8657 6.54825 41.8657 8.59523V14.8952H43.3876V11.4828C43.9609 11.8581 44.6538 12.0679 45.3969 12.0679C47.3263 12.0679 48.9266 10.6549 48.9283 8.59523C48.9266 6.53458 47.3263 5.12158 45.3969 5.12158Z"
        fill="#3160A9"
      />
      <path
        d="M58.468 7.90508C58.468 7.28984 59.255 6.44794 60.307 6.44794C60.9987 6.44794 61.9188 6.98192 61.9188 7.90508H58.468ZM60.2121 5.12158C58.4239 5.12158 56.9418 6.53491 56.9404 8.59525C56.9418 10.6549 58.4239 12.0679 60.2121 12.0679C61.2884 12.0679 62.4225 11.7273 63.0206 10.8702L62.1256 9.96001C61.7345 10.4181 61.1388 10.7035 60.3985 10.7035C59.2251 10.7035 58.4399 10.0016 58.4399 9.00382H63.4627C63.4763 8.87049 63.4833 8.73396 63.4833 8.59525C63.4822 6.53491 62.2108 5.12158 60.2121 5.12158Z"
        fill="#3160A9"
      />
      <path
        d="M65.5768 8.59521V2.74316H64.0547V8.59521C64.0561 10.633 65.6223 12.0378 67.5238 12.0676V10.64C66.3691 10.6178 65.5768 9.70316 65.5768 8.59521Z"
        fill="#3160A9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.89679 10.1774C5.47933 10.1774 5.13963 9.83199 5.13963 9.40722C5.13963 8.98311 5.47933 8.63834 5.89679 8.63834C6.31426 8.63834 6.65396 8.98311 6.65396 9.40722C6.65396 9.83199 6.31426 10.1774 5.89679 10.1774ZM2.99798 8.1593C2.99798 7.73453 3.33707 7.38946 3.75484 7.38946C4.17201 7.38946 4.5114 7.73453 4.5114 8.1593C4.5114 8.58341 4.17201 8.92851 3.75484 8.92851C3.33707 8.92851 2.99798 8.58341 2.99798 8.1593ZM5.89679 6.14089C6.31426 6.14089 6.65396 6.48627 6.65396 6.91008C6.65396 7.33453 6.31426 7.68056 5.89679 7.68056C5.47933 7.68056 5.13963 7.33453 5.13963 6.91008C5.13963 6.48627 5.47933 6.14089 5.89679 6.14089ZM17.2382 7.40818H7.52322C7.56976 7.25008 7.59535 7.08341 7.59535 6.91008C7.59535 5.95803 6.83381 5.18311 5.89679 5.18311C5.09715 5.18311 4.42647 5.74818 4.24694 6.50627C4.09082 6.4577 3.92596 6.43168 3.75484 6.43168C2.81814 6.43168 2.05566 7.20627 2.05566 8.1593C2.05566 9.11168 2.81814 9.88661 3.75484 9.88661C3.92596 9.88661 4.09082 9.85994 4.24694 9.81232C4.42647 10.5695 5.09715 11.1348 5.89679 11.1348C6.83381 11.1348 7.59535 10.3602 7.59535 9.40722C7.59535 9.23484 7.56976 9.06787 7.52322 8.91042H14.1124V10.9828H14.9007V9.94661H15.6623V10.9818H16.4504V8.91042H17.2382V7.40818Z"
        fill="#FFE630"
      />
      <path
        d="M68.5711 11.3235H68.6486C68.7383 11.3235 68.8109 11.2931 68.8109 11.2194C68.8109 11.1546 68.7638 11.1108 68.6608 11.1108C68.6179 11.1108 68.5889 11.1153 68.5711 11.1191V11.3235ZM68.5673 11.727H68.4053V11.0156C68.4694 11.0022 68.5589 10.9937 68.6741 10.9937C68.8059 10.9937 68.8657 11.0156 68.9172 11.0457C68.9551 11.0762 68.9851 11.1324 68.9851 11.2019C68.9851 11.28 68.9256 11.341 68.8404 11.3664V11.3756C68.9083 11.4013 68.9467 11.4534 68.968 11.5486C68.9898 11.6572 69.0024 11.7003 69.0197 11.727H68.8444C68.823 11.7003 68.8109 11.6356 68.7891 11.5531C68.7767 11.475 68.7336 11.44 68.6441 11.44H68.5673V11.727ZM68.1364 11.354C68.1364 11.6702 68.3676 11.9216 68.6821 11.9216C68.9898 11.9216 69.2155 11.6702 69.2155 11.3581C69.2155 11.0416 68.9898 10.7857 68.6783 10.7857C68.3676 10.7857 68.1364 11.0416 68.1364 11.354ZM69.3944 11.354C69.3944 11.7572 69.0832 12.0734 68.6783 12.0734C68.2779 12.0734 67.9575 11.7572 67.9575 11.354C67.9575 10.9597 68.2779 10.6426 68.6783 10.6426C69.0832 10.6426 69.3944 10.9597 69.3944 11.354Z"
        fill="#3160A9"
      />
    </svg>
  );
}

SvgCoppel.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

SvgCoppel.defaultProps = {
  width: 78,
  height: 15,
};

export default SvgCoppel;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Line } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import JsPDF from 'jspdf';
import { VerticalAlignBottomOutlined, DownOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Card,
  Typography,
  Spin,
  Select,
  Button,
  Space,
  Dropdown,
  Menu,
} from 'antd';
import { Option } from 'antd/lib/mentions';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TopFiveSalesTable from './TopFiveSalesTable';
import SalesPieChartByMarketplace from './SalesPieChartByMarketplace';
import 'antd/dist/antd.min.css';
import {
  convertToISOString,
  thousandsSeparator,
} from '../../../../utils/functions';
import selectors from '../../../../redux/analytic/dashboard/selectors';
import NumericCardInfo from './components/numericCardInfo';
import Map from './components/MapPerCountry';
import TableLocalization from './components/TableLocalizationPerState';
import COUNTRIES from './components/maps';
import STATESORPROVINCE from './components/maps/statesOrProvince';
import dashboardApi from '../../../../api/dashboard';
import { getErrorMessage } from '../../../../api/api';
import openNotification from '../../../../components/Toastr';

const { Title } = Typography;

function Index({
  soldUnits,
  salesTotal,
  pendingSalesTotal,
  grossSalesTotal,
  pendingOrders,
  totalOrders,
  dataSalesSummary,
  dataTopFiveSalesTable,
  salesTotalLoading,
  soldUnitsLoading,
  dataSalesSummaryLoading,
  dataTopFiveSalesTableLoading,
  pendingOrdersLoading,
  totalOrdersLoading,
}) {
  const { t } = useTranslation();
  const session = useSelector((store) => store.Session.session);
  const rawfilter = useSelector(selectors.selectRawFilter);
  const [, setTitleSalesGraphic] = useState('');
  const [dataSalesPerMarketplace, setDataSalesPerMarketplace] = useState([]);
  const [aggregatedSalesPerMarketplace, setAggregatedSalesPerMarketplace] =
    useState([]);
  const [countryJson, setCountryJson] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [dataMapPerCountry, setDataMapPerCountry] = useState([]);
  const [countryCode, setCountryCode] = useState('US');

  const currencyState = useSelector(selectors.selectCurrency);

  useEffect(() => {
    if (!session.userInfo.isAdmin && dataSalesSummary.length > 0) {
      setTitleSalesGraphic(
        `${dataSalesSummary[0]?.nameClient} [${dataSalesSummary[0]?.date}]`
      );
      const ranking = {};
      dataSalesSummary.forEach((sale) => {
        ranking[sale.type] =
          sale.type in ranking ? ranking[sale.type] + sale.value : sale.value;
      });
      const rankedSales = dataSalesSummary.sort(
        (a, b) =>
          ranking[b.type] - ranking[a.type] || a.type.localeCompare(b.type)
      );
      setDataSalesPerMarketplace(rankedSales);
      const newAggregated = Object.keys(ranking)
        .map((type) => ({ type, value: ranking[type] }))
        .sort(
          (a, b) =>
            ranking[b.type] - ranking[a.type] || a.type.localeCompare(b.type)
        );
      setAggregatedSalesPerMarketplace(newAggregated);
    } else {
      setDataSalesPerMarketplace([]);
    }
  }, [dataSalesSummary]);

  const filterArrayToString = (params) => {
    const object = {
      ...params,
      dashboard: 'summary',
    };
    return object;
  };

  const changeCountry = (country, data) => {
    const dataMapPerCountryList = data;
    const dataCountry = COUNTRIES[country];
    const newData = dataCountry?.features.map((stateOrProvince) => {
      const order = dataMapPerCountryList.find(
        (value) =>
          value.stateOrProvince ===
          STATESORPROVINCE[country][stateOrProvince.properties.name]
      );

      let density;
      if (typeof order !== 'undefined') {
        density = Number(order.orders);
      }

      return {
        ...stateOrProvince,
        properties: {
          ...stateOrProvince.properties,
          orders: typeof order !== 'undefined' ? Number(order.orders) : 0,
          density,
        },
      };
    });
    setDataMapPerCountry(data);
    setCountryJson({ ...dataCountry, features: newData });
  };

  const getDataMapPerCountry = async (country) => {
    const filter = filterArrayToString(rawfilter);
    setLoading(true);
    await dashboardApi
      .getDataMapsPerCountry({
        ...filter,
        countryCode: country,
        length: 0,
        dateRangeOne: convertToISOString(filter.dateRangeOne),
        listSkus: [],
      })
      .then((response) => {
        if (response.data.length > 0) {
          changeCountry(country, response.data);
        } else {
          setDataMapPerCountry([]);
          setCountryJson({});
        }
        setCountryCode(country);
        setLoading(false);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
        setLoading(false);
      });
    return true;
  };

  const handleDownloadSalesCSV = async () => {
    setLoadingFile(true);
    return dashboardApi
      .downloadSalesSummaryCSV(rawfilter)
      .then(() => {
        setLoadingFile(false);
      })
      .catch(() => {
        openNotification({
          status: false,
          content: t('download.csvError'),
        });
      });
  };

  const handleDownloadSalesPDF = async () => {
    const doc = new JsPDF();

    doc.text(t('home.charts.totalSales.title'), 80, 15);

    doc.setFontSize(10);
    doc.text(
      dataSalesPerMarketplace?.length !== 0
        ? `${moment(dataSalesPerMarketplace[0]?.date).format(
            "D MMM [']YY"
          )} - ${moment(
            dataSalesPerMarketplace[dataSalesPerMarketplace.length - 1].date
          ).format("D MMM [']YY")}`
        : '',
      150,
      10
    );

    const chart = document.getElementById('graficoSales');
    const canvas = chart.querySelector('canvas');
    const chartDataURI = canvas.toDataURL('image/png', 1.0);
    doc.addImage(chartDataURI, 'PNG', 5, 30, 195, 50);

    doc.save('totalSalesGraph.pdf');
  };

  const handleDownloadSalesPiePDF = async () => {
    const doc = new JsPDF();

    doc.text(t('home.charts.totalSalesByMarketplace'), 50, 15);

    doc.setFontSize(10);
    doc.text(
      dataSalesPerMarketplace?.length !== 0
        ? `${moment(dataSalesPerMarketplace[0]?.date).format(
            "D MMM [']YY"
          )} - ${moment(
            dataSalesPerMarketplace[dataSalesPerMarketplace.length - 1].date
          ).format("D MMM [']YY")}`
        : '',
      160,
      10
    );

    const chart = document.getElementById('donutVentas');
    const canvas = chart.querySelector('canvas');
    const chartDataURI = canvas.toDataURL('image/png', 1.0);
    doc.addImage(chartDataURI, 'PNG', 40, 30, 130, 90);

    doc.save('totalSalesGraphByMarketplace.pdf');
  };

  const dropdownMenu = (theme) => (
    <Menu theme={theme}>
      <Menu.Item key="csv" onClick={handleDownloadSalesCSV}>
        <Space>{t('download.csv')} </Space>
        <Space>
          {loadingFile ? (
            <Spin style={{ marginTop: '3px' }} size="small" />
          ) : (
            <VerticalAlignBottomOutlined />
          )}
        </Space>
      </Menu.Item>
      <Menu.Item key="pdf" onClick={handleDownloadSalesPDF}>
        <Space>{t('download.pdf')} </Space>
        <Space>
          {loadingFile ? (
            <Spin style={{ marginTop: '3px' }} size="small" />
          ) : (
            <VerticalAlignBottomOutlined style={{ marginLeft: '3px' }} />
          )}
        </Space>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (
      dataSalesSummaryLoading === false &&
      Object.keys(rawfilter).length !== 0
    ) {
      getDataMapPerCountry(countryCode);
    }
  }, [rawfilter, dataSalesSummaryLoading]);

  const config = {
    data: dataSalesPerMarketplace,
    xField: 'date',
    height: 200,
    yField: 'value',
    seriesField: 'type',
    theme: {
      colors10: [
        '#00E5A6',
        '#010C33',
        '#969696',
        '#CACACA',
        '#E8E8E8',
        '#9FB40F',
        '#76523B',
        '#DAD5B5',
        '#0E8E89',
        '#E19348',
        '#F383A2',
        '#247FEA',
      ],
    },
    yAxis: {
      style: {
        stroke: '#F4664A',
        lineDash: [2, 2],
      },
    },
    xAxis: {
      label: {
        formatter: (date) => {
          moment.locale('es');
          if (date?.length === 7) {
            return moment(date).format('MMM');
          }
          return moment(date).format('D MMM');
        },
      },
    },
    legend: {
      layout: 'horizontal',
      position: 'top-right',
    },
    tooltip: {
      title: 'dateLabel',
      formatter: (datum) => ({
        name: t(`dashboard.marketplaces.${datum.type.replace(' ', '')}`),
        value: `${thousandsSeparator(datum.value)} ${currencyState}`,
      }),
      domStyles: {
        'g2-tooltip': { fontFamily: 'Poppins' },
        'g2-tooltip-title': { fontFamily: 'Poppins' },
        'g2-tooltip-list': { fontFamily: 'Poppins' },
        'g2-tooltip-list-item': { fontFamily: 'Poppins' },
        'g2-tooltip-marker': { fontFamily: 'Poppins' },
        'g2-tooltip-value': { fontFamily: 'Poppins' },
        'g2-tooltip-name': { fontFamily: 'Poppins' },
      },
    },
  };

  return (
    <>
      <Card className="ventas-home-listing-card" style={{ marginTop: 10 }}>
        <Row className="intro-totales">
          <Col span={6}>
            <NumericCardInfo
              loader={salesTotalLoading}
              data={salesTotal}
              title={t('home.charts.totalSales.title')}
              tooltipTitle={
                <>
                  <p>{t('home.charts.totalSales.tooltip')}</p>
                  <p>{`${t('home.charts.totalSales.completedSales')}: $
                      ${Number(grossSalesTotal).toLocaleString('en-US')}
                  `}</p>
                  <p>{`${t('home.charts.totalSales.pendingSales')}: $
                      ${Number(pendingSalesTotal).toLocaleString('en-US')}
                  `}</p>
                </>
              }
              currency
            />
          </Col>
          <Col span={6}>
            <NumericCardInfo
              loader={soldUnitsLoading}
              data={soldUnits}
              title={t('home.charts.soldUnits.title')}
              tooltipTitle={t('home.charts.soldUnits.tooltip')}
              currency={false}
            />
          </Col>
          <Col span={6}>
            <NumericCardInfo
              loader={totalOrdersLoading}
              data={totalOrders}
              title={t('home.charts.totalOrders.title')}
              tooltipTitle={t('home.charts.totalOrders.tooltip')}
              currency={false}
            />
          </Col>
          <Col span={6}>
            <NumericCardInfo
              loader={pendingOrdersLoading}
              data={pendingOrders}
              title={t('home.charts.pendingOrders.title')}
              tooltipTitle={t('home.charts.pendingOrders.tooltip')}
              currency={false}
            />
          </Col>
        </Row>
      </Card>
      <Row className="titulo" style={{ textAlign: 'left', marginTop: 10 }}>
        <Col id="graficoSales" span={24}>
          <Card className="ventas-home-listing-card">
            <Row>
              <Col span={21}>
                <Title level={4}>{t('home.charts.totalSales.title')}:</Title>
                <Title
                  level={4}
                  style={{ color: '#969696', fontSize: 12, marginTop: 10 }}
                >
                  {dataSalesPerMarketplace?.length > 0 &&
                    (dataSalesPerMarketplace[0].date.length === 7
                      ? t('home.charts.monthlyView')
                      : t('home.charts.dailyView'))}
                </Title>
              </Col>
              <Col span={3}>
                <Dropdown overlay={dropdownMenu('light')}>
                  <Button className="btn-basic">
                    <Space>
                      {t('common.download')}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Col>
            </Row>
            {!dataSalesSummaryLoading ? (
              <Line
                data={config.data}
                xField={config.xField}
                height={config.height}
                yField={config.yField}
                seriesField={config.seriesField}
                theme={config.theme}
                yAxis={config.yAxis}
                xAxis={config.xAxis}
                legend={config.legend}
                tooltip={config.tooltip}
              />
            ) : (
              <Card>
                <center>
                  <Spin size="large" />
                </center>
              </Card>
            )}
          </Card>
        </Col>
      </Row>
      <Row className="bot-3-cards" style={{ textAlign: 'left', marginTop: 10 }}>
        <Col xs={24} md={24} lg={12} xl={12} span={12}>
          <Card className="ventas-home-listing-card">
            <Title level={4} style={{ marginBottom: '15px' }}>
              {t('home.charts.topFiveSales')}
            </Title>
            {!dataTopFiveSalesTableLoading ? (
              <TopFiveSalesTable dataSource={dataTopFiveSalesTable} />
            ) : (
              <Card>
                <center>
                  <Spin size="large" />
                </center>
              </Card>
            )}
          </Card>
        </Col>
        <Col id="donutVentas" xs={24} md={24} lg={12} xl={12} span={12}>
          <Card className="ventas-home-listing-card">
            <Row>
              <Col span={16}>
                <Title level={4}>{t('home.charts.totalSales.title')}</Title>
                <span
                  style={{
                    color: '#969696',
                    fontStyle: 'italic',
                    fontFamily: 'Poppins',
                    fontSize: 12,
                  }}
                >
                  {t('home.charts.breakdownByMarketplace')}
                </span>
              </Col>
              <Col span={4}>
                <Button
                  className="btn-basic"
                  onClick={handleDownloadSalesPiePDF}
                >
                  <Space>
                    {t('download.pdf')}{' '}
                    {loadingFile ? (
                      <Spin style={{ marginTop: '3px' }} size="small" />
                    ) : (
                      <VerticalAlignBottomOutlined />
                    )}
                  </Space>
                </Button>
              </Col>
            </Row>
            {!dataSalesSummaryLoading ? (
              <SalesPieChartByMarketplace
                dataSource={aggregatedSalesPerMarketplace}
                currency
              />
            ) : (
              <Card>
                <center>
                  <Spin size="large" />
                </center>
              </Card>
            )}
          </Card>
        </Col>
      </Row>
      <Card className="ventas-home-listing-card" style={{ marginTop: 10 }}>
        <Row style={{ textAlign: 'left', marginTop: 10 }}>
          <Col xs={24} md={24} lg={12} xl={12} span={12}>
            <Title level={4} style={{ marginBottom: '15px' }}>
              {t('home.charts.location')}
            </Title>
            {!loading && !dataSalesSummaryLoading ? (
              <TableLocalization data={dataMapPerCountry} />
            ) : (
              <Card>
                <center>
                  <Spin size="large" />
                </center>
              </Card>
            )}
          </Col>
          <Col
            xs={24}
            md={24}
            lg={11}
            xl={11}
            span={11}
            style={{ marginLeft: 10 }}
          >
            <div style={{ height: 300 }}>
              <div style={{ textAlign: 'right' }}>
                <Select
                  defaultValue={countryCode}
                  onChange={getDataMapPerCountry}
                  style={{ width: '150px', textAlign: 'center' }}
                >
                  <Option value="US">{t('common.countries.US')}</Option>
                  <Option value="CL">{t('common.countries.CL')}</Option>
                  <Option value="MX">{t('common.countries.MX')}</Option>
                  <Option value="CO">{t('common.countries.CO')}</Option>
                  <Option value="CA">{t('common.countries.CA')}</Option>
                </Select>
              </div>
              {!loading && !dataSalesSummaryLoading ? (
                <Map data={dataMapPerCountry} country={countryJson} />
              ) : (
                <Card>
                  <center>
                    <Spin size="large" />
                  </center>
                </Card>
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default Index;

Index.propTypes = {
  soldUnits: PropTypes.number.isRequired,
  salesTotal: PropTypes.number.isRequired,
  pendingSalesTotal: PropTypes.number.isRequired,
  grossSalesTotal: PropTypes.number.isRequired,
  pendingOrders: PropTypes.number.isRequired,
  totalOrders: PropTypes.number.isRequired,
  dataSalesSummary: PropTypes.arrayOf(Object).isRequired,
  dataTopFiveSalesTable: PropTypes.instanceOf(Object).isRequired,
  salesTotalLoading: PropTypes.bool.isRequired,
  soldUnitsLoading: PropTypes.bool.isRequired,
  dataSalesSummaryLoading: PropTypes.bool.isRequired,
  dataTopFiveSalesTableLoading: PropTypes.bool.isRequired,
  pendingOrdersLoading: PropTypes.bool.isRequired,
  totalOrdersLoading: PropTypes.bool.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { orderStates, orderSteps } from '../../utils/const';
import FboCompanyNames from '../order/components/couriers/enum/fboCompanyNames';
import FullOrder from '../order/components/steps/FullOrder';
import AmazonRestockSteps from './steps/AmazonRestockSteps';

function RestockSteps({ orderDb }) {
  const { fboFulfillmentCompany } = orderDb;

  return (
    <>
      {fboFulfillmentCompany === FboCompanyNames.Amazon &&
        orderDb.steps?.find((step) => step.step === orderSteps.ESTADO).state !==
          orderStates.COMPLETED && <AmazonRestockSteps order={orderDb} />}
      {fboFulfillmentCompany === FboCompanyNames.Amazon &&
        orderDb.steps?.find((step) => step.step === orderSteps.ESTADO).state ===
          orderStates.COMPLETED && <FullOrder orderId={orderDb.id} />}
    </>
  );
}

RestockSteps.propTypes = {
  orderDb: PropTypes.element.isRequired,
};

export default RestockSteps;

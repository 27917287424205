import api from './api';

const prefix = '/aws-s3';

export default {
  deleteImageByKey(key) {
    return api.delete(`${prefix}/${key}`);
  },
  upload(imageData) {
    return api.post(`${prefix}`, imageData);
  },
  uploadOrderDocument(orderId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return api.post(`${prefix}/orders/${orderId}/document`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getOrderDocumentDownloadUrl(orderId, documentId, document) {
    return api.post(
      `${prefix}/orders/${orderId}/document/${documentId}`,
      document
    );
  },
};

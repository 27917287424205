import api from './api';

const prefix = '/plan';
const planAPI = {
  get(id) {
    return api.get(`${prefix}/${id}`);
  },
  list(type, active) {
    return api.get(`${prefix}/list`, { params: { active, type } });
  },
};
export default planAPI;

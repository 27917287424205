import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../home.css';

function OnboardingButton(props) {
  const history = useHistory();
  const { step, text, linkTo, completed, onClick, disabled } = props;
  const textData = text.split(' ');

  let className = 'onboardingButtonIncomplete';

  if (completed === 0) {
    className = 'onboardingButtonIncomplete';
  } else {
    className = 'onboardingButtonComplete';
  }

  const generateLines = (stage) => {
    let linesArray;
    if (stage === 1) {
      linesArray = (
        <>
          <div style={{ position: 'relative', zIndex: '2' }}>{textData[0]}</div>
          <div style={{ position: 'relative', zIndex: '2' }}>{textData[1]}</div>
          <div style={{ position: 'relative', zIndex: '2' }}>
            {textData[2]} {textData[3]}
          </div>
        </>
      );
    } else if (stage === 2 || stage === 3) {
      linesArray = textData.map((line) => (
        <div style={{ position: 'relative', zIndex: '2' }}>{line}</div>
      ));
    } else if (stage === 4) {
      linesArray = (
        <div style={{ position: 'inherit', zIndex: '2' }}>{text}</div>
      );
    } else if (stage === 5) {
      linesArray = (
        <>
          <div style={{ position: 'relative', zIndex: '2' }}>{textData[0]}</div>
          <div style={{ position: 'relative', zIndex: '2' }}>
            {textData[1]} {textData[2]}
          </div>
        </>
      );
    }
    return linesArray;
  };

  return (
    <Button
      className={className}
      onClick={onClick || (() => history.push(linkTo))}
      disabled={disabled}
    >
      <div className="numberOnboarding">{step}</div>
      {generateLines(step)}
    </Button>
  );
}

OnboardingButton.propTypes = {
  step: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  completed: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
};

OnboardingButton.defaultProps = {
  onClick: () => {},
};

export default OnboardingButton;

import api from './api';

const prefix = '/shipments';

const shipmentsAPI = {
  createRestockOrderShipment: (body) => {
    return api.post(`${prefix}/restockOrder`, body);
  },
  createThirdPartyShipment: (body) => {
    return api.post(`${prefix}/thirdParty`, body);
  },
  createSalesOrderShipment: (body) => {
    return api.post(`${prefix}/salesOrder`, body);
  },
  getShipmentStatus: (courierName, externalId) => {
    return api.get(`${prefix}/status`, {
      params: {
        courierName,
        externalId,
      },
    });
  },
  getShipmentRates: (payload) => {
    return api.post(`${prefix}/rates`, payload);
  },
};

export default shipmentsAPI;

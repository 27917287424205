import { LinkOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Select } from 'antd';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Joyride from 'react-joyride';
import LapMarketplaceImg from '../../../assets/LAP_GLOBAL.png';
import {
  FboCouriersEnum,
  FulfillmentLabelMap,
  fulfillmentType as FulfillmentTypes,
} from '../../../utils/const';
import { confirmDeleteCredential } from '../helpers/credentials';
import toggleFulfillment from '../helpers/fulfillment';
import ModalConect from './ModalConnectNew';
import './marketplaceRow.css';
import {
  stage1Marketplace,
  stage1Marketing,
  stage1End,
} from '../../../stepsOnboarding';
import { updateOnboarding } from '../../../redux/onboarding/action';

function MarketplaceRow({ marketplaceByUser }) {
  const { fulfillmentCompanies: allFulfillmentCompanies, parentMarketplace } =
    marketplaceByUser;

  const articleLink =
    parentMarketplace?.article_link || marketplaceByUser?.article_link;
  const marketplaceImg = parentMarketplace?.svg || marketplaceByUser?.svg;

  const [modal, contextHolder] = Modal.useModal();
  const dispatch = useDispatch();

  const session = useSelector((store) => store.Session.session);
  const [isHovering, setIsHovering] = useState(false);
  const [isAdsHovering, setIsAdsHovering] = useState(false);
  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false);

  const onboardingStatus = useSelector((store) => store.Onboarding);

  const toConnectButton = useMemo(() => {
    return (
      marketplaceByUser?.parentMarketplace?.multipleCredentials === true ||
      marketplaceByUser?.marketplaceCredentials?.length === 0
    );
  });

  const isAdsCredentialsConnected = useMemo(() => {
    if (marketplaceByUser?.adsCredentials?.length > 0) {
      return true;
    }

    return false;
  }, [marketplaceByUser]);

  const consentUrl = useMemo(() => {
    return marketplaceByUser?.consentUrl?.consentUrl;
  }, [marketplaceByUser]);

  const adsConsentUrl = useMemo(() => {
    return marketplaceByUser?.adsConsentUrl?.consentUrl;
  }, [marketplaceByUser]);

  const isCredentialsConnected = useMemo(() => {
    if (marketplaceByUser?.marketplaceCredentials?.length > 0) {
      return true;
    }

    return false;
  }, [marketplaceByUser]);

  const finalFullfillmentCompanies = useMemo(() => {
    if (marketplaceByUser?.fulfillmentCompanies) {
      const options = [];
      if (marketplaceByUser?.fulfillmentCompanies.length > 0) {
        marketplaceByUser.fulfillmentCompanies.forEach((company) => {
          options.push({
            label: company,
            value: company,
          });

          if (company === FboCouriersEnum.MELONN) {
            options.push({
              label: `${FboCouriersEnum.MELONN} / ${FulfillmentLabelMap.FBM}`,
              value: `${FboCouriersEnum.MELONN} / ${FulfillmentLabelMap.FBM}`,
            });
          }
        });
      }

      options.unshift({
        value: FulfillmentTypes.FBM,
        label: FulfillmentLabelMap.FBM,
      });

      return options;
    }

    return [
      {
        value: FulfillmentTypes.FBM,
        label: FulfillmentLabelMap.FBM,
      },
    ];
  }, [marketplaceByUser?.fulfillmentCompanies]);

  const fulfillmentCompanySelected = useMemo(() => {
    if (
      marketplaceByUser?.marketplaceCredentials[0]?.fulfillmentType ===
        FulfillmentTypes.FBO &&
      marketplaceByUser?.marketplaceCredentials[0]?.shippingType ===
        FulfillmentTypes.FBM
    ) {
      return `${marketplaceByUser?.marketplaceCredentials[0]?.fboFulfillmentCompany} / ${FulfillmentLabelMap.FBM}`;
    }
    return (
      marketplaceByUser?.marketplaceCredentials[0]?.fboFulfillmentCompany ||
      FulfillmentTypes.FBM
    );
  });

  const finalMarketplaceName = useMemo(() => {
    switch (parentMarketplace?.name) {
      case 'Edicommerce':
        return 'Walmart DSV';
      case 'Mercadolibre':
        return 'Mercado libre';

      default:
        return parentMarketplace?.name;
    }
  }, [parentMarketplace?.name]);

  const handleConnect = () => {
    if (
      onboardingStatus.step === 2 &&
      onboardingStatus.state === 'Marketplace'
    ) {
      dispatch(updateOnboarding('Marketplace', 3));
    }
    switch (parentMarketplace?.name) {
      case 'Coppel':
      case 'Liverpool':
      case 'Ripley':
      case 'Edicommerce':
      case 'Woocommerce':
      case 'Falabella':
      case 'Linio':
      case 'Prestashop':
      case 'Walmart':
      case 'Walmart Mexico':
      case 'Shopify':
      case 'Magento':
      case 'Dafiti':
        setIsModalConnectOpen(true);
        return;
      case 'Amazon':
      case 'Amazon Europe':
      case 'Ebay':
      case 'Mercadolibre':
        window.open(consentUrl, '_blank');
        break;
      default:
    }
  };
  const handleAdsConnect = () => {
    if (
      onboardingStatus.step === 3 &&
      onboardingStatus.state === 'Marketplace'
    ) {
      dispatch(updateOnboarding('Marketplace', 4));
    }
    if (adsConsentUrl) {
      window.open(adsConsentUrl, '_blank');
    }
  };

  const handleConnected = () => {
    if (
      onboardingStatus.step === 2 &&
      onboardingStatus.state === 'Marketplace'
    ) {
      dispatch(updateOnboarding('Marketplace', 3));
    }
    confirmDeleteCredential(
      session,
      {
        parentMarketplace,
        ...marketplaceByUser.marketplaceCredentials[0],
      },
      modal,
      'Seller',
      dispatch
    );
  };

  const handleAdsConnected = () => {
    if (
      onboardingStatus.step === 3 &&
      onboardingStatus.state === 'Marketplace'
    ) {
      dispatch(updateOnboarding('Marketplace', 4));
    }
    confirmDeleteCredential(
      session,
      marketplaceByUser,
      modal,
      'Advertising',
      dispatch
    );
  };

  useEffect(() => {
    setIsModalConnectOpen(false);
  }, [session]);

  const handleSkipTourCallback = (data) => {
    const { action, lifecycle, status } = data;
    if (
      (action === 'close' && lifecycle === 'complete') ||
      (action === 'next' && lifecycle === 'complete' && status === 'finished')
    ) {
      dispatch(updateOnboarding(null, null));
    }
  };

  const handleOnboardingMarketplaceClickLink = () => {
    if (
      onboardingStatus.state === 'Marketplace' &&
      onboardingStatus.step === 1 &&
      parentMarketplace?.name === 'Amazon'
    ) {
      dispatch(updateOnboarding('Marketplace', 2));
    }
  };

  return (
    <>
      <ModalConect
        isModalOpen={isModalConnectOpen}
        handleCancel={() => setIsModalConnectOpen(false)}
        allProps={{
          marketplaceByUser,
          parentMarketplace,
          session,
        }}
      />
      {contextHolder}
      <div className="listMarketplaceRow">
        <Row gutter={[12, 2]} className="contentListMarketplaces">
          <Col xs={24} sm={24} md={5}>
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              {marketplaceImg ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '80%',
                    marginLeft: 25,
                  }}
                >
                  <img
                    height={60}
                    width={60}
                    alt={parentMarketplace?.name}
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(
                      parse(marketplaceImg)
                    )}`}
                    className="marketplaceImg"
                  />
                  <span
                    style={{
                      fontWeight: 500,
                      marginLeft: 20,
                      width: '100%',
                    }}
                  >
                    {finalMarketplaceName}
                  </span>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '80%',
                    marginLeft: 25,
                  }}
                >
                  <img
                    src={LapMarketplaceImg}
                    height={50}
                    width={50}
                    alt="lap_marketplace_logo"
                    className="marketplaceImg"
                  />
                  <span
                    style={{
                      fontWeight: 500,
                      marginLeft: 20,
                      width: '100%',
                    }}
                  >
                    {finalMarketplaceName}
                  </span>
                </div>
              )}
            </div>
          </Col>

          {/* CREDENTIALS BUTTON */}
          <Col xs={24} sm={24} md={5} className="contentButtonsMarketplaces">
            {toConnectButton ? (
              <Button
                type="primary"
                block
                onClick={handleConnect}
                className={`marketplacesSelectAndBtnBaseToConnect marketplacesSelectAndBtnToConnect${
                  parentMarketplace?.name === 'Amazon'
                    ? ' step4Marketplace'
                    : ''
                }`}
              >
                Conectar
              </Button>
            ) : (
              <Button
                type="primary"
                block
                className={`marketplacesSelectAndBtnBase marketplacesSelectAndBtnActive marketplaceConnectedButton${
                  parentMarketplace?.name === 'Amazon'
                    ? ' step4Marketplace'
                    : ''
                }`}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                onClick={handleConnected}
              >
                {isHovering ? 'Desconectar' : 'Conectado'}
              </Button>
            )}
          </Col>

          {/* ADS CREDENTIALS BUTTON */}
          <Col xs={24} sm={24} md={5} className="contentButtonsMarketplaces">
            {isAdsCredentialsConnected ? (
              <Button
                type="primary"
                block
                className={`marketplacesSelectAndBtnBase marketplacesSelectAndBtnActive marketplaceConnectedButton${
                  parentMarketplace?.name === 'Amazon' ? ' step1Marketing' : ''
                }`}
                onMouseEnter={() => setIsAdsHovering(true)}
                onMouseLeave={() => setIsAdsHovering(false)}
                onClick={handleAdsConnected}
              >
                {isAdsHovering ? 'Desconectar' : 'Conectado'}
              </Button>
            ) : (
              <Button
                disabled={!adsConsentUrl}
                type="primary"
                block
                className={`marketplacesSelectAndBtnBaseToConnect marketplacesSelectAndBtnToConnect${
                  parentMarketplace?.name === 'Amazon' ? ' step1Marketing' : ''
                }`}
                onClick={handleAdsConnect}
              >
                Conectar
              </Button>
            )}
          </Col>

          {/* FBO FULFILLMENTS SELECT */}
          <Col xs={24} sm={24} md={5} className="contentButtonsMarketplaces">
            <Select
              defaultValue={fulfillmentCompanySelected}
              disabled={!(isCredentialsConnected && allFulfillmentCompanies)}
              name="status"
              className={
                isCredentialsConnected && allFulfillmentCompanies
                  ? 'marketplacesSelectAndBtnBase marketplacesSelectAndBtnActive'
                  : `selectBg marketplacesSelectAndBtnBase marketplacesSelectAndBtnInactive`
              }
              options={finalFullfillmentCompanies}
              onChange={(values) =>
                toggleFulfillment(
                  marketplaceByUser.marketplaceCredentials[0],
                  values
                )
              }
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={4}
            style={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              target="_blank"
              disabled={!articleLink}
              href={articleLink}
              type="primary"
              className={`btnDownloadManual${
                parentMarketplace?.name === 'Amazon' ? ' step3Marketplace' : ''
              }`}
              onClick={handleOnboardingMarketplaceClickLink}
            >
              Ver paso a paso <LinkOutlined />
            </Button>
          </Col>
        </Row>
      </div>
      {onboardingStatus.state === 'Marketplace' &&
        onboardingStatus.step === 2 &&
        parentMarketplace?.name === 'Amazon' && (
          <Joyride
            steps={stage1Marketplace}
            callback={handleSkipTourCallback}
            disableCloseOnEsc
            spotlightClicks
            disableScrolling
            continuous
          />
        )}
      {onboardingStatus.state === 'Marketplace' &&
        onboardingStatus.step === 3 &&
        parentMarketplace?.name === 'Amazon' && (
          <Joyride
            steps={stage1Marketing}
            callback={handleSkipTourCallback}
            disableCloseOnEsc
            spotlightClicks
            disableScrolling
            continuous
          />
        )}
      {onboardingStatus.state === 'Marketplace' &&
        onboardingStatus.step === 4 &&
        parentMarketplace?.name === 'Amazon' && (
          <Joyride
            steps={stage1End}
            callback={handleSkipTourCallback}
            disableCloseOnEsc
            spotlightClicks
            disableScrolling
            continuous
          />
        )}
    </>
  );
}

MarketplaceRow.propTypes = {
  marketplaceByUser: PropTypes.instanceOf(Object).isRequired,
};

export default MarketplaceRow;

import api from './api';

const prefix = 'companies';
const CompaniesAPI = {
  findById(id) {
    return api.get(`${prefix}/${id}`);
  },
  find(limit, page, keyword = '') {
    const params = { take: limit, page, keyword };
    return api.get(`${prefix}/find/paginate`, {
      params,
    });
  },
  create(values) {
    return api.post(prefix, values);
  },
  update(id, values) {
    return api.put(`${prefix}/${id}`, values);
  },
  delete(id) {
    return api.delete(`${prefix}/${id}`);
  },
  getCompanyAddress(id) {
    return api.get(`${prefix}/${id}/address`);
  },
  updateCompanyAddress(id, payload) {
    return api.put(`${prefix}/${id}/address`, payload);
  },
  createCompanyAddress(id, address) {
    return api.post(`${prefix}/${id}/address`, address);
  },
  getConsume: () => api.get(`${prefix}/consume`),
};
export default CompaniesAPI;

import { Col, Layout, Row, Steps } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { orderStates, orderSteps } from '../../../../../utils/const';
import { mixPanelCreateEvent } from '../../../../../utils/functions';
import Boxes from '../../steps/Boxes';
import Contents from '../../steps/Contents';
import ShipmentOptions from '../../steps/ShipmentOptions';
import FullOrder from '../../steps/FullOrder';

const { Content } = Layout;
const { Step } = Steps;

function AmazonRestockOrderSteps(order) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(0);
  const [redirected, setRedirected] = useState(false);
  const [steps, setSteps] = useState(order?.steps);

  const { orderId } = useParams();

  const inputRadioActive = {
    border: '1px solid rgba(0, 229, 166, 1)',
    backgroundColor: 'rgba(0, 229, 166, 0.05)',
  };

  useEffect(() => {
    mixPanelCreateEvent('Action Button', {
      action: 'Tap Restock',
      step: selected,
      id: orderId,
    });
  }, [selected, orderId]);

  return (
    <>
      <Steps
        current={[selected.toString()]}
        onChange={(param1) => {
          setSelected(param1);
        }}
        className="steps-shipment"
      >
        <Step
          key="1"
          title={t('orders.newOrder.contents.title')}
          status={
            steps?.find((step) => step.step === orderSteps.CONTENIDO).state !==
            orderStates.COMPLETED
              ? 'finish'
              : 'wait'
          }
        />
        <Step
          key="2"
          disabled={
            steps?.find((step) => step.step === orderSteps.CONTENIDO).state !==
            orderStates.COMPLETED
          }
          title={t('orders.newOrder.boxes.title')}
          status={
            steps?.find((step) => step.step === orderSteps.CONTENIDO).state ===
              orderStates.COMPLETED &&
            steps?.find((step) => step.step === orderSteps.CAJAS).state !==
              orderStates.COMPLETED
              ? 'finish'
              : 'wait'
          }
        />
        <Step
          key="3"
          disabled={
            steps?.find((step) => step.step === orderSteps.CAJAS).state !==
            orderStates.COMPLETED
          }
          title={t('orders.newOrder.state.title')}
          status={
            steps?.find((step) => step.step === orderSteps.CAJAS).state ===
              orderStates.COMPLETED &&
            steps?.find((step) => step.step === orderSteps.ESTADO).state !==
              orderStates.COMPLETED
              ? 'finish'
              : 'wait'
          }
        />
        <Step
          key="4"
          disabled={
            steps?.find((step) => step.step === orderSteps.ESTADO).state !==
            orderStates.COMPLETED
          }
          title={t('orders.newOrder.final.title')}
          status={
            steps?.find((step) => step.step === orderSteps.ESTADO).state ===
            orderStates.COMPLETED
              ? 'finish'
              : 'wait'
          }
        />
      </Steps>
      <Row className="side-bar-margin-top">
        <Col span={24}>
          <Layout>
            <Layout>
              <Content
                className="site-layout-background"
                style={{
                  margin: 0,
                  minHeight: 280,
                }}
              >
                {selected === 0 && (
                  <Contents
                    redirected={redirected}
                    setRedirected={setRedirected}
                    orderId={orderId}
                    setSelected={setSelected}
                    selected={selected}
                    setSteps={setSteps}
                    title={t('orders.newOrder.contents.title')}
                    inputRadioActive={inputRadioActive}
                  />
                )}
                {selected === 1 && (
                  <Boxes
                    orderId={orderId}
                    setSelected={setSelected}
                    selected={selected}
                    setSteps={setSteps}
                    inputRadioActive={inputRadioActive}
                    title={t('orders.newOrder.boxes.titleForm')}
                  />
                )}
                {selected === 2 && (
                  <ShipmentOptions
                    orderId={orderId}
                    setSelected={setSelected}
                    selected={selected}
                    setSteps={setSteps}
                    title={t('orders.newOrder.shipment.title')}
                  />
                )}
                {selected === 3 && <FullOrder orderId={orderId} />}
              </Content>
            </Layout>
          </Layout>
        </Col>
      </Row>
    </>
  );
}

AmazonRestockOrderSteps.propTypes = {
  order: PropTypes.shape({
    steps: PropTypes.arrayOf(Object),
  }).isRequired,
};

export default AmazonRestockOrderSteps;

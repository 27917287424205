import api from './api';

const prefix = '/fees';
const feesAPI = {
  calculateFees: (payload) => api.post(`${prefix}/calculate`, payload),
  saveCalculation: (inputs, name) => {
    return api.post(`${prefix}/calculations`, { inputs, name });
  },
  getCalculations(limit = null, page = null, search = null) {
    const params = { limit, page, search };
    return api.get(`${prefix}/calculations`, { params });
  },
  getCalculation: (id) => api.get(`${prefix}/calculations/${id}`),
  deleteCalculation: (id) => api.delete(`${prefix}/calculations/${id}`),
  getCategories: (marketplace) =>
    api.get(`${prefix}/categories?marketplace=${marketplace}`),
  getAddressComponent: (latlng, component) =>
    api.get(
      `${prefix}/addressComponent?latlng=${latlng}&component=${component}`
    ),
};
export default feesAPI;

export const cancelPickUpErrors = {
  'The service is currently unavailable and we are working to resolve the issue. We apologize for any inconvenience. Please check back at a later time.':
    {
      title: 'fedexError.pickUp.internalError.title',
      text: 'fedexError.pickUp.internalError.title',
    },
  default: { text: 'fedexError.cancelPickUp.default.text' },
};

export const createPickUpErrors = {
  'Invalid or missing weight value.': {
    text: 'fedexError.createPickUp.missWeight.text',
    title: 'fedexError.createPickUp.missWeight.title',
  },
  'The service is currently unavailable and we are working to resolve the issue. We apologize for any inconvenience. Please check back at a later time.':
    {
      text: 'fedexError.createPickUp.fedexDown.text',
      title: 'fedexError.createPickUp.fedexDown.title',
    },
  'Pickup ready date timestamp is missing or invalid.': {
    text: 'fedexError.createPickUp.invalidDate.text',
    title: 'fedexError.createPickUp.invalidDate.title',
  },
  'We encountered an unexpected error and are working to resolve the issue. We apologize for any inconvenience. Please check back at a later time.':
    {
      text: 'fedexError.createPickUp.invalidDate.text',
      title: 'fedexError.createPickUp.invalidDate.title',
    },
  'Schedule date and time are not available.': {
    text: 'fedexError.createPickUp.invalidDay.text',
    title: 'fedexError.createPickUp.invalidDay.title',
  },
  default: { text: 'fedexError.createPickUp.default.text' },
};

export const createCourierShipmentsErrors = {
  'Recipient/Third party account validation failed.': {
    text: 'fedexError.createCourierShipments.suspendedAccount.text',
    title: 'fedexError.createCourierShipments.suspendedAccount.title',
  },
  'Recipient State or Province code is invalid. Please update and try again.': {
    text: 'fedexError.createCourierShipments.invalidRecipientStateProvinceCode.text',
    title:
      'fedexError.createCourierShipments.invalidRecipientStateProvinceCode.title',
  },
  'Harmonized code is invalid. Please update and try again.': {
    text: 'fedexError.createCourierShipments.invalidHarmonizedCode.text',
    title: 'fedexError.createCourierShipments.invalidHarmonizedCode.title',
  },
  'Commodity weight is missing or invalid.Please update and try again.': {
    text: 'fedexError.createCourierShipments.invalidCommodityWeight.text',
    title: 'fedexError.createCourierShipments.invalidCommodityWeight.title',
  },
  default: {
    text: 'fedexError.createCourierShipments.default.text',
  },
};

export const changeFulfillmentErrors = {
  'Product does not exist in fulfillment company': {
    text: 'fulfillmentError.changeFulfillment.nonExistingProduct.text',
    title: 'fulfillmentError.changeFulfillment.nonExistingProduct.text',
  },
  default: {
    text: 'fulfillmentError.changeFulfillment.default.text',
    title: 'fulfillmentError.changeFulfillment.default.title',
  },
};

export const uploadDocumentErrors = {
  'ETD shipment validation failure : The cutoff time has been reached for this post shipment document upload. Your document upload has not been processed. Please print your documents and attach to your shipment.':
    {
      text: 'fedexError.uploadDocument.cutOffTimeReached.text',
      title: 'fedexError.uploadDocument.cutOffTimeReached.title',
    },
  default: {
    text: 'fedexError.uploadDocument.default.text',
    title: 'fedexError.uploadDocument.default.title',
  },
};

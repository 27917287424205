/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import RevealEyeButton from '../../../../../components/RevealEyeButton';
import MelonnShipmentTypesList from './MelonnShipmentTypesList';
import openNotification from '../../../../../components/Toastr';
import fulfillmentAPI from '../../../../../api/fulfillment';

function MelonnForm({
  loading,
  name,
  onFinish,
  courierCredentials,
  shipmentTypes,
}) {
  const [form] = useForm();
  const [showSecret, setShowSecret] = useState(false);
  const [loadingTypes, setLoadingTypes] = useState(false);

  const updateShipmentTypesOrder = async (data) => {
    setLoadingTypes(true);
    await fulfillmentAPI
      .updateCourierShipmentTypes(name, data)
      .then(() => {
        setLoadingTypes(false);
        openNotification({
          status: true,
          content: `Se han actualizado los tipos de envio correctamente.`,
        });
      })
      .catch(() => {
        setLoadingTypes(false);
      });
  };

  return (
    <>
      <Form
        form={form}
        name={`${name}-form`}
        layout="vertical"
        labelCol={{
          span: 8,
          offset: 0,
        }}
        wrapperCol={{
          span: 20,
          offset: 0,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={(values) => {
          onFinish(values, form);
        }}
        autoComplete="off"
      >
        <h1
          style={{
            fontWeight: 600,
            marginBottom: 40,
            marginTop: 15,
          }}
        >
          Conectar cuenta Melonn
        </h1>
        <span
          style={{
            paddingLeft: 25,
            maxWidth: '100%',
            textAlign: 'left',
            fontSize: '18px',
            marginBottom: 30,
          }}
        >
          Api Key
        </span>
        <Form.Item
          name="api_key"
          rules={[
            {
              required: true,
              message: 'Ingrese su Clave API de Melonn',
            },
          ]}
          style={{
            fontSize: '18px',
            maxWidth: '100%',
            paddingLeft: 25,
            paddingRight: 25,
          }}
        >
          <Input
            defaultValue={courierCredentials?.client_secret}
            type={showSecret ? 'text' : 'password'}
            onChange={(e) => form.setFieldValue('api_key', e.target.value)}
            size="large"
            style={{ borderRadius: 10, marginTop: 15 }}
          />
          <div style={{ position: 'absolute', right: '-35px', top: '33%' }}>
            <RevealEyeButton
              onClick={() => setShowSecret(!showSecret)}
              show={showSecret}
            />
          </div>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            span: 24,
          }}
          style={{
            marginTop: 40,
            textAlign: 'center',
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{
              height: '50px',
              width: '30%',
            }}
          >
            {courierCredentials ? 'Actualizar' : 'Conectar'}
          </Button>
        </Form.Item>
      </Form>
      <MelonnShipmentTypesList
        shipmentTypes={shipmentTypes}
        loadingTypes={loadingTypes}
        onUpdate={updateShipmentTypesOrder}
      />
    </>
  );
}

export default MelonnForm;

MelonnForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  shipmentTypes: PropTypes.any,
  onFinish: PropTypes.func.isRequired,
  courierCredentials: PropTypes.instanceOf(Object).isRequired,
};

import { useEffect } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import { updateOnboarding } from '../../../../redux/onboarding/action';
import { stage2CourierModal } from '../../../../stepsOnboarding';
import phones from '../../../../utils/json/phones.json';

function FedexLapTab({ loading, name, onFinish, refresh, courierCredentials }) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const onboardingStatus = useSelector((store) => store.Onboarding);
  const availableCountries = phones.filter((value) =>
    ['CO', 'CL', 'MX'].includes(value.code)
  );
  useEffect(() => {
    form.resetFields();
  }, [refresh]);

  const handleCallbackModal = (data) => {
    const { action, lifecycle, status, type } = data;
    if (action === 'close' && lifecycle === 'complete') {
      dispatch(updateOnboarding(null, null));
    } else if (status === 'finished' && type === 'tour:end') {
      dispatch(updateOnboarding(null, null));
    }
  };

  return (
    <>
      <Form
        form={form}
        name={`${name}-form`}
        layout="vertical"
        labelCol={{
          span: 8,
          offset: 0,
        }}
        wrapperCol={{
          span: 20,
          offset: 0,
        }}
        initialValues={{
          remember: true,
          fbm: true,
          fbo: false,
          managedByLap: true,
          accountNumber: courierCredentials
            ? courierCredentials.accountNumber
            : null,
          countryCode: courierCredentials
            ? courierCredentials.countryCode
            : null,
        }}
        onFinish={onFinish}
        autoComplete="off"
        style={{
          marginBottom: 20,
        }}
      >
        <div style={{ marginTop: 12 }}>
          <div className="step5Courier">
            <span
              style={{
                paddingLeft: 25,
                maxWidth: '100%',
                textAlign: 'left',
                fontSize: '18px',
                marginBottom: 30,
              }}
            >
              País
            </span>
            <Form.Item
              name="countryCode"
              labelAlign="left"
              wrapperCol={{
                span: 24,
              }}
              style={{
                fontSize: '18px',
                maxWidth: '100%',
                paddingLeft: 25,
                paddingRight: 25,
              }}
              rules={[
                {
                  required: true,
                  message: 'Ingrese el país de su cuenta',
                },
              ]}
            >
              {availableCountries && (
                <Select>
                  {availableCountries.map((countryInfo) => (
                    <Select.Option
                      key={countryInfo.code}
                      value={countryInfo.code}
                    >
                      {countryInfo.flag} {countryInfo.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <span
              style={{
                paddingLeft: 25,
                maxWidth: '100%',
                textAlign: 'left',
                fontSize: '18px',
                marginBottom: 30,
              }}
            >
              Número de cuenta
            </span>
            <Form.Item
              name="accountNumber"
              labelAlign="left"
              wrapperCol={{
                span: 24,
              }}
              style={{
                fontSize: '18px',
                maxWidth: '100%',
                paddingLeft: 25,
                paddingRight: 25,
              }}
              rules={[
                {
                  required: true,
                  message: 'Ingrese su número de cuenta de Fedex',
                },
                {
                  required: true,
                  min: 9,
                  max: 9,
                  message:
                    'El número de cuenta de Fedex debe ser de 9 dígitos.',
                },
              ]}
            >
              <Input size="large" style={{ borderRadius: 10, marginTop: 15 }} />
            </Form.Item>
            <Form.Item name="managedByLap" />
          </div>
          <div
            className="step4Courier"
            style={{
              fontSize: 17,
              marginTop: 30,
              textAlign: 'center',
            }}
          >
            <span style={{ color: '#969696' }}>¿Aún no tienes una cuenta?</span>
            <Button href="/couriers/solicitar-cuenta" type="link" block>
              <span
                style={{
                  color: '#63B9F0',
                  fontSize: 17,
                }}
              >
                Solicitar cuenta
              </span>
            </Button>
          </div>
          <Form.Item
            wrapperCol={{
              span: 24,
            }}
            style={{
              marginTop: 40,
              textAlign: 'center',
            }}
          >
            <Button
              className="step6Courier"
              style={{
                height: '50px',
                width: '30%',
              }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              {courierCredentials ? 'Actualizar' : 'Conectar'}
            </Button>
          </Form.Item>
        </div>
      </Form>
      {onboardingStatus.state === 'Courier' && onboardingStatus.step === 3 && (
        <Joyride
          steps={stage2CourierModal}
          callback={handleCallbackModal}
          continuous
          hideBackButton
          disableCloseOnEsc
          spotlightClicks
          styles={{
            options: {
              zIndex: 2000,
            },
          }}
          locale={{
            next: 'Siguiente',
            last: 'Terminar tutorial',
          }}
        />
      )}
    </>
  );
}

export default FedexLapTab;

FedexLapTab.propTypes = {
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  courierCredentials: PropTypes.instanceOf(Object).isRequired,
};

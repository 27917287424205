export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const REFRESH_SESSION = 'REFRESH_SESSION';

export const LOADING = 'LOADING';
export const UPDATE_STATE_SELLER_ACCOUNT = 'UPDATE_STATE_SELLER_ACCOUNT';
export const UPDATE_STATE_ADS_ACCOUNT = 'UPDATE_STATE_ADS_ACCOUNT';

export const GET_SESSION_ME = 'GET_SESSION_ME';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_USER_INFO_NEW_COMPANY = 'UPDATE_USER_INFO_NEW_COMPANY';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';

export const SUBSCRIBE_SUCCESSFULLY = 'SUBSCRIBE_SUCCESSFULLY';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';

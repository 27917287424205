import { Col, Layout, Row, Steps } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import './ShipsmartSend.css';
import couriersAPI from '../../../../../api/courier';
import ShipsmartPickupSummaryStep from './steps/ShipsmartPickupSummaryStep';
import ShipsmartProductBoxStep from './steps/ShipsmartProductBoxStep';

const { Content } = Layout;
const { Step } = Steps;

function ShipsmartSend() {
  const location = useLocation();
  const { orders, warehouseId, courierId, fboOrigin } = location.state;
  const [actualStep, setActualStep] = useState(0);
  const [data, setData] = useState([]);
  const [courierCredentials, setCourierCredentials] = useState();

  orders[0].orderItems = orders[0].orderItems.filter(
    (product) => product.fulfillmentType === 'FBM'
  );

  const findCourierCredential = async () => {
    const companyCourierCredentials = await couriersAPI.getCourierCredentials(
      100,
      1
    );
    const courierCreds = companyCourierCredentials?.data?.results?.find(
      (cred) => parseInt(courierId, 10) === cred.id
    );

    setCourierCredentials(courierCreds);
  };

  useEffect(() => {
    findCourierCredential();
  }, [courierId]);

  return (
    <div className="content-div initialContent">
      <div className="container-fedexsend">
        <Col span={12} offset={6}>
          <Steps
            current={[(actualStep + 1).toString()]}
            onChange={(step) => {
              setActualStep(step);
            }}
          >
            <Step
              key="1"
              title="Detalles y contenido"
              status={actualStep === 1 ? 'wait' : 'finish'}
            />
            <Step key="2" disabled={actualStep < 1} title="Generar Envío" />
          </Steps>
        </Col>
        <Row className="side-bar-margin-top">
          <Col span={24}>
            <Layout>
              <Content
                className="site-layout-background"
                style={{
                  margin: 0,
                  minHeight: 280,
                }}
              >
                {actualStep === 0 && (
                  <ShipsmartProductBoxStep
                    orders={orders}
                    setActualStep={setActualStep}
                    setData={setData}
                    warehouseId={warehouseId}
                    courierCredentials={courierCredentials}
                    unidades={['metrico']}
                  />
                )}
                {actualStep === 1 && (
                  <ShipsmartPickupSummaryStep
                    orders={orders}
                    warehouseId={warehouseId}
                    fboOrigin={fboOrigin}
                    data={data}
                    setData={setData}
                    courierId={courierId}
                    courierCredentials={courierCredentials.courierCompanyName}
                  />
                )}
              </Content>
            </Layout>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ShipsmartSend;

import { Table, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import Paragraph from 'antd/lib/typography/Paragraph';
import openNotification from '../../../components/Toastr';
import TooltipTitle from './TooltipTitle';
import productApi from '../../../api/product';
import { marketplaceCurrency, productStatuses } from '../../../utils/const';

const { Text } = Typography;

function EdicommerceTable({
  pagination,
  expandable,
  renderProductColumn,
  rowSelection,
  loading,
  dataSource,
  setDataSource,
  handleProductPriceChange,
}) {
  const handleProductExternalCodeChange = async (value, record) => {
    if (value === '' || value === record.externalId) {
      return;
    }

    try {
      await productApi.updateByDefaultCode(record.defaultCode, {
        externalId: value,
      });

      const newProductsWithCode = dataSource.map((product) => {
        const newProduct = product;
        if (product.id === record.id) {
          newProduct.externalId = value;
        }
        return product;
      });

      setDataSource(newProductsWithCode);

      openNotification({
        status: true,
        content: 'El código se actualizó correctamente',
      });
    } catch (error) {
      openNotification({
        status: false,
        content: 'Hubo un error al actualizar el código',
      });
    }
  };

  const columnsForEdicommerce = [
    {
      title: 'SKU',
      ellipsis: true,
      dataIndex: ['defaultCode'],
      key: 'sku',
      width: '14%',
      render: (value) => (
        <Tooltip title={value}>
          <Text style={{ fontSize: 12 }}>{value}</Text>
        </Tooltip>
      ),
    },
    {
      title: 'Producto',
      dataIndex: ['title'],
      key: 'title',
      align: 'center',
      ellipsis: true,
      width: '35%',
      render: (value, record) => renderProductColumn(value, record),
    },
    {
      title: (
        <TooltipTitle
          label="Precio Marketplace"
          description="Precio real sincronizado desde el marketplace."
        />
      ),
      dataIndex: ['price'],
      key: 'price',
      align: 'center',
      width: '13%',
      ellipsis: false,
      render: (value, record) => {
        if (
          !record.isVariant &&
          record.children.length > 0 &&
          value === '0.00'
        ) {
          return <Text style={{ fontSize: 12 }}>-</Text>;
        }
        return (
          <Text style={{ fontSize: 12 }}>{`${`${value} `}${
            marketplaceCurrency[record.marketplace]
          }`}</Text>
        );
      },
    },
    {
      title: (
        <TooltipTitle
          label="Precio en Dashboard"
          description="Precio que se utilizará en las estadísticas del Dashboard. Al editarlo no modifica el precio real en el marketplace."
        />
      ),
      dataIndex: ['dashboardPrice'],
      key: 'dashboardPrice',
      align: 'center',
      width: '12%',
      ellipsis: false,
      render: (value, record) => {
        if (record.marketplace === 'Edicommerce') {
          return (
            <Paragraph
              style={{ fontSize: 12 }}
              editable={{
                onChange: (e) => handleProductPriceChange(e, record),
                triggerType: 'text',
              }}
              className="labelToInput"
            >
              {record.dashboardPrice ?? record.price}
            </Paragraph>
          );
        }
        return record.dashboardPrice ?? record.price;
      },
    },
    {
      title: 'Código',
      dataIndex: ['externalId'],
      key: 'id',
      align: 'center',
      width: '18%',
      ellipsis: false,
      render: (value, record) => {
        if (record.marketplace === 'Edicommerce') {
          return (
            <Paragraph
              style={{ fontSize: 12 }}
              editable={{
                onChange: (e) => handleProductExternalCodeChange(e, record),
                triggerType: 'text',
              }}
              className="labelToInput"
            >
              {record.externalId}
            </Paragraph>
          );
        }
        return record.externalId;
      },
    },
    {
      title: 'Variantes',
      dataIndex: ['children'],
      key: 'variant',
      width: '11%',
      align: 'center',
      ellipsis: false,
      render: (value, record) => {
        if (record.isVariant) {
          return <Text style={{ fontSize: 12 }}>-</Text>;
        }
        return <Text style={{ fontSize: 12 }}>{value?.length}</Text>;
      },
    },
    {
      title: 'Estado',
      dataIndex: ['status'],
      key: 'id',
      width: '8%',
      align: 'center',
      ellipsis: false,
      render: (value, record) => {
        if (!record.isVariant && record.children?.length > 0) {
          return '';
        }
        if (productStatuses.INACTIVE.includes(value)) {
          if (
            (record.fulfillmentType === 'FBA' &&
              record.inventory?.availableStock === 0) ||
            (record.fulfillmentType === 'FBM' && record.stock === 0)
          ) {
            return <div className="pillBox">Sin stock</div>;
          }
          return <div className="pillBox withError">Inactivo</div>;
        }
        if (productStatuses.ACTIVE.includes(value)) {
          return <div className="pillBox withoutError">Activo</div>;
        }
        return <div className="pillBox">{`${value}`}</div>;
      },
    },
  ];
  return (
    <Table
      rowKey={(record) => record.id}
      dataSource={dataSource}
      loading={loading}
      columns={columnsForEdicommerce}
      pagination={{
        ...pagination,
      }}
      expandable={{
        ...expandable,
      }}
      rowSelection={{
        ...rowSelection,
      }}
      showHeader
      scroll={{ x: '100%' }}
    />
  );
}

EdicommerceTable.propTypes = {
  pagination: PropTypes.objectOf(Object).isRequired,
  expandable: PropTypes.objectOf(Object).isRequired,
  rowSelection: PropTypes.objectOf(Object).isRequired,
  renderProductColumn: PropTypes.func.isRequired,
  dataSource: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool.isRequired,
  setDataSource: PropTypes.func.isRequired,
  handleProductPriceChange: PropTypes.func.isRequired,
};

export default EdicommerceTable;

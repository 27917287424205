import PropTypes from 'prop-types';
import { Button, Modal, Row, Steps, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { mixPanelCreateEvent } from '../../../utils/functions';
import './ShipmentTrackingModal.css';

function ShipmentTrackingModal({
  isModalOpen,
  setIsModalOpen,
  shipmentTrackingInfo,
  setShipmentTrackingInfo,
}) {
  const { Title, Text } = Typography;
  const { t } = useTranslation();
  const [actualStep, setActualStep] = useState(0);

  const handleCancel = () => {
    setShipmentTrackingInfo([]);
    setActualStep(0);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (shipmentTrackingInfo.statuses?.length > 0) {
      if (shipmentTrackingInfo.statuses.includes('ReadyForPickup'))
        setActualStep(1);
      else if (shipmentTrackingInfo.statuses.includes('InTransit'))
        setActualStep(2);
      else if (shipmentTrackingInfo.statuses.includes('Delivered'))
        setActualStep(3);
      else setActualStep(0);
    }
  }, [isModalOpen]);

  const fedexTracking = () => {
    mixPanelCreateEvent('Action Button', {
      action: 'Button track Fedex shipment',
      id: shipmentTrackingInfo.id,
    });
    window.open(
      `https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=${shipmentTrackingInfo.trackingNumbers}`
    );
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
      width="314px"
      className="shipment-tracking-modal"
      style={{ borderRadius: 1000 }}
    >
      <Typography className="shipment-tracking-modal-text">
        <Title
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
          }}
        >
          {t('shipments.shipmentTracking.shipmentTracking')}
        </Title>
        <Text>{shipmentTrackingInfo.name}</Text>
      </Typography>
      <Steps
        direction="vertical"
        current={actualStep + 1}
        className="steps-shipment"
        items={[
          {
            title: t('shipments.shipmentTracking.shipmentCreated'),
          },
          {
            title: t('shipments.shipmentTracking.pickUpScheduled'),
          },
          {
            title: t('common.inTransit'),
          },
          {
            title: t('common.delivered'),
          },
        ]}
      />
      {shipmentTrackingInfo.trackingNumbers && (
        <Row
          style={{
            justifyContent: 'center',
          }}
        >
          <Button className="nextButton" onClick={fedexTracking}>
            Fedex Tracking
          </Button>
        </Row>
      )}
    </Modal>
  );
}

ShipmentTrackingModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  shipmentTrackingInfo: PropTypes.shape({
    statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    trackingNumbers: PropTypes.string.isRequired,
  }).isRequired,
  setShipmentTrackingInfo: PropTypes.func.isRequired,
};

export default ShipmentTrackingModal;

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
import moment from 'moment';
import { getErrorMessage } from '../api/api';
import openNotification from '../components/Toastr';
import { sellerMarketplaces, listingStates } from './const';
import SvgAmazon from './icons/SvgAmazon';
import SvgEbay from './icons/SvgEbay';
import SvgMeli from './icons/SvgMeli';
import SvgWalmart from './icons/SvgWalmart';
import SvgShopify from './icons/SvgShopify';
import SvgCoppel from './icons/SvgCoppel';
import SvgLiverpool from './icons/SvgLiverpool';
import SvgWalmartDSV from './icons/SvgWalmarDSV';
import SvgWoocommerce from './icons/SvgWoocommerce';
import couriersAPI from '../api/courier';
import SvgDafiti from './icons/SvgDafiti';
import SvgMagento from './icons/SvgMagento';
import SvgPrestashop from './icons/SvgPrestashop';
import MercadoPagoIcon from '../assets/Mercado_Pago_Icon.png';
import StripeIcon from '../assets/cc-stripe.svg';

const mixpanel = require('mixpanel-browser');
const axios = require('axios');

export function keyPressSpaceBar(e) {
  const { value } = e.target;
  if (value === null || value === '') {
    if (e.code === 'Space' || e.key === '') {
      e.preventDefault();
    }
  }
}

export const reducer = (accumulator, curr) =>
  parseFloat(accumulator) + parseFloat(curr);

let ipAddress = null;
export async function mixPanelCreateEvent(name, params) {
  const paramsProps = params || {};
  if (!ipAddress) {
    const res = await axios.get('https://geolocation-db.com/json/', {
      withCredentials: false,
    });
    ipAddress = res.data.IPv4;
  }
  if (!paramsProps.url) {
    paramsProps.url = window.location.pathname;
  }
  paramsProps.ipAddress = ipAddress;
  mixpanel.track(`${name}`, paramsProps);
}

export function thousandsSeparator(val, sep) {
  const sRegExp = /(-?[0-9]+)([0-9]{3})/;
  let sValue = `${val}`;
  let sepProp = sep;

  if (sepProp === undefined) {
    sepProp = ',';
  }
  while (sRegExp.test(sValue)) {
    sValue = sValue.replace(sRegExp, `$1${sepProp}$2`);
  }
  return sValue;
}

export const sumaDataArray = (data, param) => {
  const allSuma = [];
  data.map((element) => {
    const paramSelected = Object.keys(element).filter((e) => e === param);
    allSuma.push(element[paramSelected[0]]);
  });
  return data.length > 0
    ? thousandsSeparator(Math.round(allSuma.reduce(reducer) * 100) / 100)
    : 0;
};

export const averageDataArray = (data, param) => {
  const allSuma = [];
  data.map((element) => {
    const paramSelected = Object.keys(element).filter((e) => e === param);
    allSuma.push(element[paramSelected[0]]);
  });
  const promedio = allSuma.reduce(reducer) / allSuma.length;
  return thousandsSeparator(Math.round(promedio * 100) / 100);
};

export function keyPressPhoneNumber(e) {
  const regex = /[0-9]/;
  const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
}

export function clsAlphaNoOnly(e) {
  const regex = /^[a-zA-Z0-9 ]+$/;
  const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
}

export function clsAlphaOnly(e) {
  const regex = /^[A-Za-z ]+$/;
  const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
}

export function clsNif(e) {
  const regex = /[A-Z]{2}d{6}/;
  const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
}

export function isValidNif(abc) {
  const dni = abc.substring(2, abc.length - 1);
  const lett = abc.substring(0, 2);
  if (!Number.isNaN(lett)) {
    // alert('falta el CL');
    return false;
  }
  const cadena = 'TRWAGMYFPDXBNJZSQVHLCKET';
  const posicion = dni % 23;
  const letra = cadena.substring(posicion, posicion + 1);
  if (letra.trim() !== lett.toUpperCase().trim()) {
    // alert("los primeros 2 caracteres deben ser letras en mayúscula");
    return false;
  }

  return true;
}

export function capitalizeFirstLetter(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function capitalizeWord(value) {
  return value.toUpperCase();
}

export function scrapingCurrencyGoogle(from, to) {
  const options = {
    method: 'GET',
    url: 'https://google-finance4.p.rapidapi.com/search/',
    params: { q: `${from}-${to}` },
    headers: {
      'X-RapidAPI-Host': 'google-finance4.p.rapidapi.com',
      'X-RapidAPI-Key': '4b0564c0e9msh73d03a9f5b7dab1p1d298ajsn5b57c1a58216',
    },
  };

  axios
    .request(options)
    .then(() => {})
    .catch(() => {});
}

export function checkProperties(obj) {
  for (const key in obj) {
    if (typeof obj[key] !== 'boolean' && key !== 'vat') {
      if (obj[key] === null || obj[key] === '' || obj[key] === undefined) {
        return false;
      }
    }
  }
  return true;
}

export function checkProfile(objPartner, objSession) {
  // Verificamos si los datos de mi cuenta estan cargados
  const fieldsPartner = ['ref', 'x_fantasy_name', 'email', 'name'];
  const fieldsSession = ['birthday', 'phone', 'passport_id', 'name'];

  for (const key in objPartner) {
    if (fieldsPartner.indexOf(key) > -1) {
      if (
        objPartner[key] === null ||
        objPartner[key] === '' ||
        objPartner[key] === undefined
      ) {
        return {
          res: false,
          msj1: 'option.personalIncomplete',
        };
      }
    }
  }
  for (const key in objSession) {
    if (fieldsSession.indexOf(key) > -1) {
      if (
        objSession[key] === null ||
        objSession[key] === '' ||
        objSession[key] === undefined
      ) {
        return {
          res: false,
          msj1: 'option.personalIncomplete',
        };
      }
    }
  }
  // Si puede publicar verificamos si tiene seller sincronizado
  if (objSession.x_can_publish) {
    // Enviamos mensaje de que debe sincronizar
    return {
      res: true,
      msj1: 'option.personalComplete',
      msj2: 'option.mustAddSeller',
    };
  }
  // Si no puede publciar, verificamos los datos de mi cuenta
  return {
    res: true,
    msj1: 'option.personalComplete',
    msj2: 'option.needSyncPermission',
  };
}

export function nameToSlug(value) {
  return value
    ?.normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '')
    ?.replaceAll(' ', '_')
    ?.toLowerCase();
}

export function scrollClass(value) {
  const element = document.getElementsByClassName(value)[0];
  const rect = element.getBoundingClientRect().top + window.scrollY;
  window.scrollTo({ top: rect, behavior: 'smooth' });
}

export function padLeadingZeros(num, size) {
  let s = `${num}`;
  while (s.length < size) s = `0${s}`;
  return s;
}

export function changeUrlNotification(type) {
  switch (type) {
    case 'NEW_SALE_ORDER':
      return '/pedidos';
    case 'SUCCESSFUL_CATALOGUE_UPLOAD':
      return '/catalogo';
    case 'FAILED_CATALOGUE_UPLOAD':
      return '/imports';
    case 'SET_FBM_INITIAL_STOCK':
    case 'LOW_STOCK':
    case 'OUT_OF_STOCK':
      return '/inventario';
    case 'UPCOMING_SUBSCRIPTION_BILLING':
    case 'FAILED_SUBSCRIPTION_BILLING':
      return '/planes';
    default:
      return '';
  }
}

export const paymentMethodIcons = {
  'Mercado Pago': MercadoPagoIcon,
  PayPal:
    'https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-mark-color.svg',
  Stripe: StripeIcon,
};

export function getSvgMarketplace(marketplace) {
  switch (marketplace) {
    case sellerMarketplaces.AMAZON:
      return <SvgAmazon />;
    case sellerMarketplaces.EBAY:
      return <SvgEbay />;
    case sellerMarketplaces.COPPEL:
      return <SvgCoppel />;
    case sellerMarketplaces.LIVERPOOL:
      return <SvgLiverpool />;
    case sellerMarketplaces.SHOPIFY:
      return <SvgShopify />;
    case sellerMarketplaces.WOOCOMMERCE:
      return <SvgWoocommerce />;
    case sellerMarketplaces.DAFITI:
      return <SvgDafiti />;
    case sellerMarketplaces.MAGENTO:
      return <SvgMagento />;
    case sellerMarketplaces.PRESTASHOP:
      return <SvgPrestashop />;
    case sellerMarketplaces.MERCADOLIBRE_MX:
    case sellerMarketplaces.MERCADOLIBRE_CHILE:
    case sellerMarketplaces.MERCADOLIBRE_CO:
      return <SvgMeli />;
    case sellerMarketplaces.WALMART:
    case sellerMarketplaces.WALMART_MX:
      return <SvgWalmart />;
    default:
      return null;
  }
}

function getSvgWithCountryCode(marketplace, countryCode, showCountryCode) {
  switch (marketplace) {
    case sellerMarketplaces.AMAZON:
      return (
        <>
          <SvgAmazon />
          {showCountryCode && (
            <span className="textMarketPlaceCountry">{countryCode}</span>
          )}
        </>
      );
    case sellerMarketplaces.EBAY:
      return (
        <>
          <SvgEbay />
          {showCountryCode && (
            <span className="textMarketPlaceCountry">{countryCode}</span>
          )}
        </>
      );
    case sellerMarketplaces.MERCADOLIBRE_MX:
    case sellerMarketplaces.MERCADOLIBRE_CHILE:
    case sellerMarketplaces.MERCADOLIBRE_CO:
      return (
        <>
          <SvgMeli />
          {showCountryCode && (
            <span className="textMarketPlaceCountry">{countryCode}</span>
          )}
        </>
      );
    case sellerMarketplaces.WALMART:
    case sellerMarketplaces.WALMART_MX:
      return (
        <>
          <SvgWalmart />
          {showCountryCode && (
            <span className="textMarketPlaceCountry">{countryCode}</span>
          )}
        </>
      );
    case sellerMarketplaces.SHOPIFY_MX:
    case sellerMarketplaces.SHOPIFY_CO:
    case sellerMarketplaces.SHOPIFY_CL:
    case sellerMarketplaces.SHOPIFY_BR:
    case sellerMarketplaces.SHOPIFY_PE:
    case sellerMarketplaces.SHOPIFY_US:
      return (
        <>
          <SvgShopify />
          {showCountryCode && (
            <span className="textMarketPlaceCountry">{countryCode}</span>
          )}
        </>
      );
    default:
      return null;
  }
}

export function getSvgMarketPlace(marketplace, showCountryCode) {
  switch (marketplace) {
    case sellerMarketplaces.AMAZON_DE:
      return getSvgWithCountryCode(
        sellerMarketplaces.AMAZON,
        'DE',
        showCountryCode
      );
    case sellerMarketplaces.AMAZON_IT:
      return getSvgWithCountryCode(
        sellerMarketplaces.AMAZON,
        'IT',
        showCountryCode
      );
    case sellerMarketplaces.AMAZON_FR:
      return getSvgWithCountryCode(
        sellerMarketplaces.AMAZON,
        'FR',
        showCountryCode
      );
    case sellerMarketplaces.AMAZON_ES:
      return getSvgWithCountryCode(
        sellerMarketplaces.AMAZON,
        'ES',
        showCountryCode
      );
    case sellerMarketplaces.AMAZON_MX:
      return getSvgWithCountryCode(
        sellerMarketplaces.AMAZON,
        'MX',
        showCountryCode
      );
    case sellerMarketplaces.AMAZON_BR:
      return getSvgWithCountryCode(
        sellerMarketplaces.AMAZON,
        'BR',
        showCountryCode
      );
    case sellerMarketplaces.AMAZON_CA:
      return getSvgWithCountryCode(
        sellerMarketplaces.AMAZON,
        'CA',
        showCountryCode
      );
    case sellerMarketplaces.AMAZON:
      return getSvgWithCountryCode(
        sellerMarketplaces.AMAZON,
        'US',
        showCountryCode
      );
    case sellerMarketplaces.EBAY:
      return getSvgWithCountryCode(
        sellerMarketplaces.EBAY,
        'US',
        showCountryCode
      );
    case sellerMarketplaces.EBAY_CA:
      return getSvgWithCountryCode(
        sellerMarketplaces.EBAY,
        'CA',
        showCountryCode
      );
    case sellerMarketplaces.EBAY_ES:
      return getSvgWithCountryCode(
        sellerMarketplaces.EBAY,
        'ES',
        showCountryCode
      );
    case sellerMarketplaces.EBAY_DE:
      return getSvgWithCountryCode(
        sellerMarketplaces.EBAY,
        'DE',
        showCountryCode
      );
    case sellerMarketplaces.MERCADOLIBRE_MX:
      return getSvgWithCountryCode(
        sellerMarketplaces.MERCADOLIBRE_MX,
        'MX',
        showCountryCode
      );
    case sellerMarketplaces.MERCADOLIBRE_CO:
      return getSvgWithCountryCode(
        sellerMarketplaces.MERCADOLIBRE_CO,
        'CO',
        showCountryCode
      );
    case sellerMarketplaces.MERCADOLIBRE_CHILE:
      return getSvgWithCountryCode(
        sellerMarketplaces.MERCADOLIBRE_CHILE,
        'CL',
        showCountryCode
      );
    case sellerMarketplaces.WALMART_MX:
      return getSvgWithCountryCode(
        sellerMarketplaces.WALMART_MX,
        'MX',
        showCountryCode
      );
    case sellerMarketplaces.WALMART:
      return getSvgWithCountryCode(
        sellerMarketplaces.WALMART,
        'US',
        showCountryCode
      );
    case sellerMarketplaces.SHOPIFY:
      return <SvgShopify />;
    case sellerMarketplaces.SHOPIFY_MX:
      return getSvgWithCountryCode(
        sellerMarketplaces.SHOPIFY_MX,
        'MX',
        showCountryCode
      );
    case sellerMarketplaces.SHOPIFY_CO:
      return getSvgWithCountryCode(
        sellerMarketplaces.SHOPIFY_CO,
        'CO',
        showCountryCode
      );
    case sellerMarketplaces.SHOPIFY_CL:
      return getSvgWithCountryCode(
        sellerMarketplaces.SHOPIFY_CL,
        'CL',
        showCountryCode
      );
    case sellerMarketplaces.SHOPIFY_BR:
      return getSvgWithCountryCode(
        sellerMarketplaces.SHOPIFY_BR,
        'BR',
        showCountryCode
      );
    case sellerMarketplaces.SHOPIFY_PE:
      return getSvgWithCountryCode(
        sellerMarketplaces.SHOPIFY_PE,
        'PE',
        showCountryCode
      );
    case sellerMarketplaces.SHOPIFY_US:
      return getSvgWithCountryCode(
        sellerMarketplaces.SHOPIFY_US,
        'US',
        showCountryCode
      );
    case sellerMarketplaces.COPPEL:
      return <SvgCoppel />;
    case sellerMarketplaces.LIVERPOOL:
      return <SvgLiverpool />;
    case sellerMarketplaces.EDICOMMERCE:
      return <SvgWalmartDSV />;
    case sellerMarketplaces.MAGENTO:
      return <SvgMagento />;
    case sellerMarketplaces.WOOCOMMERCE:
      return <SvgWoocommerce />;
    case sellerMarketplaces.PRESTASHOP:
      return <SvgPrestashop />;
    case sellerMarketplaces.DAFITI:
    case sellerMarketplaces.DAFITI_AR:
    case sellerMarketplaces.DAFITI_BR:
    case sellerMarketplaces.DAFITI_CL:
    case sellerMarketplaces.DAFITI_CO:
      return <SvgDafiti />;
    default:
      return <div />;
  }
}

export function convertToInches(unity, value) {
  switch (unity) {
    case 'millimeters':
      return parseFloat(value * 0.03937).toFixed(2);
    case 'CENTIMETER':
    case 'centimeters':
      return parseFloat(value * 0.3937).toFixed(2);
    case 'METER':
    case 'meters':
      return parseFloat(value * 39.37).toFixed(2);
    case 'FEET':
    case 'feet':
    case 'ft':
      return parseFloat(value * 12).toFixed(2);
    case 'yards':
      return parseFloat(value * 36).toFixed(2);
    case 'INCH':
    case 'inches':
    case 'in':
      return value;
    default:
      return 0;
  }
}

export function convertToFeets(unity, value) {
  switch (unity) {
    case 'millimeters':
      return parseFloat(value * 0.0032808).toFixed(2);
    case 'CENTIMETER':
    case 'centimeters':
      return parseFloat(value * 0.032808).toFixed(2);
    case 'METER':
    case 'meters':
      return parseFloat(value * 3.2808).toFixed(2);
    case 'INCH':
    case 'inches':
    case 'in':
      return parseFloat(value * 0.0833333).toFixed(2);
    case 'yards':
      return parseFloat(value * 3).toFixed(2);
    case 'FEET':
    case 'feet':
    case 'ft':
      return value;
    default:
      return 0;
  }
}

export function convertToPounds(unity, value) {
  switch (unity) {
    case 'GRAM':
    case 'grams':
    case 'g':
      return parseFloat(value * 0.0022046).toFixed(2);
    case 'KILOGRAM':
    case 'kilograms':
    case 'kg':
      return parseFloat(value * 2.2046).toFixed(2);
    case 'OUNCE':
    case 'ounces':
    case 'oz':
      return parseFloat(value * 0.0625).toFixed(2);
    case 'milligrams':
      return parseFloat(value * 2.20462e-6).toFixed(2);
    case 'ton':
      return parseFloat(value * 2204.6).toFixed(2);
    case 'POUND':
    case 'pounds':
    case 'lb':
      return value;
    default:
      return 0;
  }
}

export function transformWeightUnitToKg(value, unit) {
  const weightUnitsMap = {
    grams: 'GR',
    GRAM: 'GR',
    g: 'GR',
    kilograms: 'KG',
    KILOGRAM: 'KG',
    kg: 'KG',
    lb: 'LB',
    pounds: 'LB',
    POUND: 'LB',
    ounces: 'OZ',
    OUNCE: 'OZ',
  };
  switch (weightUnitsMap[unit]) {
    case 'GR':
      return value / 1000;
    case 'LB':
      return value * 0.453592;
    case 'OZ':
      return value * 0.028349500000294;
    default:
      return value;
  }
}

export function convertUnitWeight(value, originUnit, destinyUnit) {
  const weightUnitsMap = {
    GRAM: 'GR',
    gram: 'GR',
    GRAMS: 'GR',
    grams: 'GR',
    G: 'GR',
    g: 'GR',
    KILOGRAMS: 'KG',
    kilograms: 'KG',
    KILOGRAM: 'KG',
    kilogram: 'KG',
    KG: 'KG',
    kg: 'KG',
    lb: 'LB',
    LB: 'LB',
    POUNDS: 'LB',
    pounds: 'LB',
    POUND: 'LB',
    pound: 'LB',
    OUNCES: 'OZ',
    ounces: 'OZ',
    OUNCE: 'OZ',
    ounce: 'OZ',
  };
  switch (weightUnitsMap[destinyUnit]) {
    case 'KG':
      switch (weightUnitsMap[originUnit]) {
        case 'GR':
          return value / 1000;
        case 'LB':
          return value * 0.453592;
        case 'OZ':
          return value * 0.028349500000294;
        case 'KG':
          return value;
        default:
          return 0;
      }
    case 'LB':
      switch (weightUnitsMap[originUnit]) {
        case 'GR':
          return value * 0.0022046;
        case 'KG':
          return value * 2.2046;
        case 'OZ':
          return value * 0.0625;
        case 'LB':
          return value;
        default:
          return 0;
      }
    default:
      return 0;
  }
}

export function canEdit(session, tab, state) {
  const partnerIdsSelfService = JSON.parse(
    process?.env?.REACT_APP_PARTNER_IDS_SELF_SERVICE
  );

  if (tab === 'Client') {
    if (session && session.userInfo.role === 'Admin') {
      return false;
    }
    return (
      state === listingStates.PENDING_CLIENT || state === listingStates.PENDING
    );
  }
  if (tab === 'LAP') {
    if (
      partnerIdsSelfService.includes(session.userInfo.commercial_partner_id[0])
    ) {
      return session && state === listingStates.PENDING_CLIENT;
    }
    return (
      session &&
      session.userInfo.role === 'Admin' &&
      state === listingStates.PENDING_LAP
    );
  }
  return false;
}

export function formatDateStringForTooltips(date) {
  moment.locale('es');
  if (date.length === 7) {
    let label = moment(date).format('MMMM[,] YYYY');
    label = label.charAt(0).toUpperCase() + label.slice(1);
    return label;
  }
  if (date) {
    let label = moment(date).format('dddd D [de] MMMM[,] YYYY');
    label = label.charAt(0).toUpperCase() + label.slice(1);
    return label;
  }
  return date;
}

export function pieChartValue(value) {
  return (value * 100).toFixed(0) >= 5 ? `${(value * 100).toFixed(0)}%` : '';
}

export async function urlToBase64(url) {
  const reader = new FileReader();
  const res = await fetch(url);
  const blob = await res.blob();
  reader.readAsDataURL(blob);
  return new Promise((response) => {
    reader.onload = (e) => {
      response(e.target.result);
    };
  });
}

export function convertToISOString(dateRange) {
  if (dateRange[0] instanceof Date) {
    return dateRange.map((date) => date.toISOString().split('T')[0]);
  }
  return dateRange.map((date) => moment(date).format('YYYY-MM-DD'));
}

export async function printLabelsWithOrderSales(saleOrders) {
  try {
    saleOrders.forEach(async (row) => {
      const trackingNumbersByCourier = {};
      row?.courierShipments?.forEach((x) => {
        if (x.fulfillmentType !== 'FBM') return;
        if (x.courierCompanyName in trackingNumbersByCourier) {
          trackingNumbersByCourier[x.courierCompanyName].push(
            x.masterTrackingNumber
          );
        } else {
          trackingNumbersByCourier[x.courierCompanyName] = [
            x.masterTrackingNumber,
          ];
        }
      });

      const promises = [];
      for (const [fbmCourier, trackingNumbers] of Object.entries(
        trackingNumbersByCourier
      )) {
        promises.push(
          couriersAPI.getMultipleShipmentDocument(fbmCourier, trackingNumbers)
        );
      }

      const labels = await Promise.allSettled(promises).then((results) => {
        const rejected = [];
        const fulfilled = [];
        results.forEach((result) => {
          if (result.status === 'fulfilled') {
            fulfilled.push(...(result.value?.data || []));
          }
        });
        if (rejected.length > 0) {
          openNotification({
            status: false,
            content: `No se pudo obtener los documentos para los tracking numbers ${rejected.join(
              ', '
            )}`,
          });
        }
        return fulfilled;
      });

      labels.forEach(async (label) => {
        if (label.url) {
          const downloadLink = document.createElement('a');
          downloadLink.target = '_blank';
          if (label.url.startsWith('http')) {
            const pdf = await urlToBase64(label.url);
            downloadLink.href = pdf.replace(
              'data:application/octet-stream',
              'data:application/pdf'
            );
          } else {
            downloadLink.href = label.url;
          }
          const fileName = `etiqueta_${row?.fbmCourier}.pdf`;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      });
    });
  } catch {
    openNotification({
      status: false,
      content: 'No se pudo imprimir la etiqueta',
    });
  }
}

export function translateErrors(error, errorObject, tooltipObject) {
  const backEndError = getErrorMessage(error);
  const response = {};
  if (backEndError in errorObject) {
    response.error = errorObject[backEndError].text;
    response.titleError = errorObject[backEndError]?.title;
  } else {
    response.error = errorObject.default.text;
    response.titleError = errorObject.default.title;
  }
  if (tooltipObject && backEndError in tooltipObject) {
    response.tooltip = tooltipObject[backEndError];
  }
  return response;
}

import { InfoCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';

function ShipsmartConsiderations() {
  const { t } = useTranslation();

  return (
    <div style={{ justifyContent: 'center', display: 'flex' }}>
      <Card
        size="small"
        style={{
          width: 1000,
          background: '#010C330D',
        }}
      >
        <p style={{ fontWeight: 400 }}>
          <InfoCircleOutlined
            style={{
              fontSize: 24,
              position: 'relative',
              top: 4,
              marginRight: 10,
            }}
          />{' '}
          {t('orders.shipment.fbmConsideration')}:
        </p>
        <ul style={{ marginLeft: 20 }}>
          <li>
            {t('orders.notifySectionOne.message1')}{' '}
            <a
              target="_blank"
              href="https://ayuda.lapmarketplace.com/hc/es-cl/categories/12348948571803"
              rel="noreferrer"
            >
              {t('common.wantKnowMore')}
            </a>
          </li>
          <li>
            {t('orders.notifySectionOne.message2')}{' '}
            <a
              target="_blank"
              href="https://ayuda.lapmarketplace.com/hc/es-cl/sections/12540784099099"
              rel="noreferrer"
            >
              {t('common.wantKnowMore')}
            </a>
          </li>
          <li>
            {t('orders.notifySectionOne.message3')}{' '}
            <a
              target="_blank"
              href="https://ayuda.lapmarketplace.com/hc/es-cl/sections/12350130967067"
              rel="noreferrer"
            >
              {t('common.wantKnowMore')}
            </a>{' '}
          </li>
        </ul>
      </Card>
    </div>
  );
}

export default ShipsmartConsiderations;

import { Select } from 'antd';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import openNotification from '../../../components/Toastr';
import marketingApi from '../../../api/marketing';

function KeywordSelector({ asins, setKeywords }) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChange = (value) => {
    setKeywords(value);
  };

  const getKeywordRecommendationOptions = async () => {
    const payload = {
      asins,
    };
    try {
      setLoading(true);
      const response = await marketingApi.getKeywordRecommendation(payload);
      const recommendedKeywordsOptions = response.data.map((opt) => ({
        label: opt.keyword,
        value: opt.keyword,
      }));
      return recommendedKeywordsOptions;
    } catch (error) {
      openNotification({
        status: false,
        content: 'Ha ocurrido un error al obtener keywords.',
      });
    } finally {
      setLoading(false);
    }
    return [];
  };

  const updateOptions = async () => {
    const recommendations = await getKeywordRecommendationOptions();
    setOptions(recommendations);
  };

  useEffect(() => {
    if (asins.length > 0) updateOptions();
  }, [asins]);
  return (
    <Select
      mode="tags"
      placeholder="Selecciona o escribe una keyword"
      options={options}
      loading={loading}
      onChange={onChange}
    />
  );
}
KeywordSelector.propTypes = {
  asins: PropTypes.arrayOf(PropTypes.string).isRequired,
  setKeywords: PropTypes.func.isRequired,
};
export default KeywordSelector;

import { Col, Row, Skeleton } from 'antd';

function MarketplaceRowSkeleton() {
  return (
    <Row
      gutter={[24]}
      style={{
        paddingLeft: 50,
        marginBottom: 15,
        marginTop: 10,
      }}
    >
      <Col xs={24} sm={24} md={5} className="contentButtonsMarketplaces">
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
              width: '100%',
              marginLeft: '-40px',
            }}
          >
            <Skeleton.Image active style={{ height: 50, width: 50 }} />
            <Skeleton.Button active style={{ width: '10%', height: 15 }} />
          </div>
        </div>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={5}
        className="contentButtonsMarketplaces"
        style={{
          marginLeft: '-30px',
        }}
      >
        <Skeleton.Button
          style={{
            height: 35,
          }}
          active
          size="large"
          shape="default"
          block={false}
        />
      </Col>
      <Col xs={24} sm={24} md={5} className="contentButtonsMarketplaces">
        <Skeleton.Button
          style={{
            height: 35,
          }}
          active
          size="large"
          shape="default"
          block={false}
        />
      </Col>
      <Col xs={24} sm={24} md={5} className="contentButtonsMarketplaces">
        <Skeleton.Button
          style={{
            height: 35,
          }}
          active
          size="large"
          shape="default"
          block={false}
        />
      </Col>
      <Col xs={24} sm={24} md={4} className="contentButtonsMarketplaces">
        <Skeleton.Button
          style={{
            height: 35,
          }}
          active
          size="large"
          shape="default"
          block={false}
        />
      </Col>
    </Row>
  );
}

export default MarketplaceRowSkeleton;

import { Col, DatePicker, Form, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
// import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import openNotification from '../../../../../components/Toastr';
import couriersAPI from '../../../../../api/courier';
import FBMCouriers from '../../../../../utils/FBMCouriers';

const DateNow = moment();

function Int99OrderForm({
  form,
  formName,
  orders,
  warehouse,
  fboOrigin,
  setLoading,
  setDisableOkButton,
  setHiddenOkButton,
  send99Minutos,
  setSend99Minutos,
  setCourierShipments,
}) {
  const dateFormat = 'YYYY/MM/DD';
  // const { t } = useTranslation();
  const [warehouseLocation, setWarehouseLocation] = useState(undefined);
  const [ordersToShip, setOrdersToShip] = useState([]);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [pickUpDate, setPickUpDate] = useState(
    DateNow.add(1, 'day').format(dateFormat)
  );
  const [loadingOrdersToShip, setLoadingOrdersToShip] = useState(false);

  useEffect(() => {
    const check99MinutosAvaibility = async () => {
      setLoadingOrdersToShip(true);
      setWarehouseLocation(undefined);
      const orderWarehousePayload = {
        ordersIds: orders.map((order) => {
          return order.id;
        }),
        warehouseId: fboOrigin || warehouse,
      };
      try {
        const response = await couriersAPI.getAllShipmentTypes(
          FBMCouriers.INT99MINUTOS,
          orderWarehousePayload
        );
        const failedOrders = [];
        const readyOrders = [];
        const ordersAndShipments = response.data.orders;
        setWarehouseLocation(response.data.warehouseLocation);
        ordersAndShipments.forEach((orderShipment) => {
          const order = orders.find((o) => o.id === orderShipment.order.id);
          if (orderShipment.shipmentType) {
            readyOrders.push({
              order: orderShipment.order,
              location: orderShipment.location,
              shipmentType: orderShipment.shipmentType,
              hasCoverage: orderShipment.hasCoverage,
            });
          } else {
            failedOrders.push(order);
          }
        });
        setOrdersToShip(readyOrders);
        setEnableSubmit(true);
        setDisableOkButton(false);
      } catch (error) {
        openNotification({
          status: false,
          content: 'El courier no posee cobertura para el envío',
        });
      }
      setLoadingOrdersToShip(false);
    };
    check99MinutosAvaibility();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    if (enableSubmit) {
      const shipmentBody = ordersToShip.map((orderData) => {
        return {
          saleOrderId: orderData.order.id,
          pickUpInfo: { pickUpDate },
          '99minutosData': {
            shipmentType: orderData.shipmentType,
            warehouseLocation,
            destinationLocation: orderData.location,
          },
          warehouseId: warehouse,
        };
      });
      try {
        const response = await couriersAPI.createMultipleCourierShipments(
          FBMCouriers.INT99MINUTOS,
          shipmentBody
        );
        setSend99Minutos(true);
        setHiddenOkButton(true);
        setCourierShipments(response.data);
        openNotification({
          status: true,
          content: 'Envíos creados correctamente',
        });
      } catch (error) {
        openNotification({
          status: false,
          content: 'Ocurrió un error al intentar generar los envíos',
        });
      }
    }
    setLoading(false);
  };

  const onChangeDate = (date, dateString) => {
    setPickUpDate(dateString);
  };

  return (
    <Form
      name={formName}
      form={form}
      autoComplete="off"
      onFinish={handleSubmit}
    >
      {loadingOrdersToShip && (
        <Row style={{ marginTop: 22 }}>
          <Spin />
        </Row>
      )}
      {ordersToShip.length > 0 && (
        <Col>
          <Row style={{ marginTop: 22 }}>
            <span style={{ marginRight: '1rem' }}>Fecha de retiro: </span>
          </Row>
          <Row style={{ marginTop: 14 }}>
            <DatePicker
              onChange={onChangeDate}
              defaultValue={moment(DateNow.add(1, 'day'), dateFormat)}
              format={dateFormat}
              size="large"
              disabled={send99Minutos}
            />
          </Row>
        </Col>
      )}
    </Form>
  );
}

Int99OrderForm.propTypes = {
  form: PropTypes.instanceOf(Form).isRequired,
  formName: PropTypes.string.isRequired,
  orders: PropTypes.arrayOf(Object).isRequired,
  warehouse: PropTypes.number.isRequired,
  setLoading: PropTypes.func.isRequired,
  setDisableOkButton: PropTypes.func.isRequired,
  setHiddenOkButton: PropTypes.func.isRequired,
  send99Minutos: PropTypes.bool.isRequired,
  setSend99Minutos: PropTypes.func.isRequired,
  setCourierShipments: PropTypes.func.isRequired,
  fboOrigin: PropTypes.number.isRequired,
};

export default Int99OrderForm;

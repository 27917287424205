import { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import selectors from '../../../redux/analytic/dashboard/selectors';
import actionsAnalitycs from '../../../redux/analytic/dashboard/actions';
import actionsMarketing from '../../../redux/analytic/marketing/actions';
import { dateTypes, sellerMarketplaces } from '../../../utils/const';
import DualAxesChart from './dualAxesChart';
import CardData from './cardData';
import './IndexGraphics.css';
import { convertToISOString } from '../../../utils/functions';

function IndexGraphics({
  dataTotalSalesLoading,
  dataTotalSales,
  dataSalesPerAdvertisingLoading,
  dataSalesPerAdvertising,
  dataOrganicSalesLoading,
  dataOrganicSales,
  dataInvestmentLoading,
  dataInvestment,
  dataSoldUnits,
  dataUnitsPerAdvertising,
  dataSoldUnitsLoading,
  dataUnitsPerAdvertisingLoading,
  dataOrganicUnitsLoading,
  dataOrganicUnits,
  dataClicks,
  dataClicksLoading,
  dataImpressions,
  dataImpressionsLoading,
  dataCtr,
  dataCvr,
  dataCpc,
  dataCtrLoading,
  dataCvrLoading,
  dataCpcLoading,
  dataAdsLoading,
  dataAds,
  dataTacosLoading,
  dataTacos,
  dataAcosLoading,
  dataAcos,
  dataDualAxesChart,
  dataDualAxeGraphLoading,
  hasAdvertising,
}) {
  const dispatch = useDispatch();
  const rows = useSelector(selectors.selectRows);
  const session = useSelector((store) => store.Session.session);
  const { t } = useTranslation();

  const searchByPeriodAmount = (m, p) => {
    let ptrs = [];
    ptrs = [session.userInfo.commercial_partner_id[0]];
    if (rows?.length > 0 && ptrs) {
      const defaultFilters = {
        sku: [],
        partner: ptrs,
        compare: false,
        dateRangeTwo: [],
        category: ['sales', 'stock'],
        marketplace: Object.values(sellerMarketplaces),
        dateType: dateTypes.PURCHASE_DATE,
        dateRangeOne: convertToISOString([moment().add(-m, p), moment()]),
      };
      dispatch(actionsAnalitycs.doFetch(defaultFilters));
    }
  };

  useEffect(async () => {
    if (hasAdvertising) {
      dispatch(
        actionsMarketing.getClients(session.userInfo.commercial_partner_id[0])
      );
      searchByPeriodAmount(1, 'M');
    }
  }, []);

  return (
    <>
      <Row className="home-listing-main-row listings-main">
        <Col span={24} xs={24} sm={24} md={24}>
          <Card
            className="ventas-home-listing-card"
            style={{ paddingBottom: 20, marginBottom: 15 }}
          >
            <Row className="intro-totales">
              <Col xs={24} md={12} lg={8} xl={4} span={4}>
                <CardData
                  loader={dataTotalSalesLoading}
                  data={dataTotalSales}
                  title={t('marketing.indexGraphics.totalSales.title')}
                  tooltipTitle={t('marketing.indexGraphics.totalSales.tooltip')}
                  currency
                />
              </Col>
              <Col xs={24} md={12} lg={8} xl={4} span={4}>
                <CardData
                  loader={dataSalesPerAdvertisingLoading}
                  data={dataSalesPerAdvertising}
                  title={t('marketing.indexGraphics.salesPerAd.title')}
                  tooltipTitle={t('marketing.indexGraphics.salesPerAd.tooltip')}
                  currency
                />
              </Col>
              <Col xs={24} md={12} lg={8} xl={4} span={4}>
                <CardData
                  loader={dataOrganicSalesLoading}
                  data={dataOrganicSales}
                  title={t('marketing.indexGraphics.organicSales.title')}
                  tooltipTitle={t(
                    'marketing.indexGraphics.organicSales.tooltip'
                  )}
                  currency
                />
              </Col>
              <Col xs={24} md={12} lg={8} xl={4} span={4}>
                <CardData
                  loader={dataInvestmentLoading}
                  data={dataInvestment}
                  title={t('marketing.indexGraphics.investment.title')}
                  tooltipTitle={t('marketing.indexGraphics.investment.tooltip')}
                  currency
                />
              </Col>
              <Col xs={24} md={12} lg={8} xl={4} span={4}>
                <CardData
                  loader={dataAdsLoading}
                  data={dataAds}
                  title="Ads %"
                  tooltipTitle={t('marketing.indexGraphics.adsPercentage')}
                  percentage
                />
              </Col>
              <Col xs={24} md={12} lg={8} xl={4} span={4}>
                <CardData
                  loader={dataTacosLoading}
                  data={dataTacos}
                  title="TACOS"
                  tooltipTitle={t('marketing.indexGraphics.tacos')}
                  percentage
                  normalPercentageColor={false}
                />
              </Col>
              <Col xs={24} md={12} lg={8} xl={4} span={4}>
                <CardData
                  loader={dataSoldUnitsLoading}
                  data={dataSoldUnits}
                  title={t('marketing.indexGraphics.soldUnits.title')}
                  tooltipTitle={t('marketing.indexGraphics.soldUnits.tooltip')}
                  currency={false}
                />
              </Col>
              <Col xs={24} md={12} lg={8} xl={4} span={4}>
                <CardData
                  loader={dataUnitsPerAdvertisingLoading}
                  data={dataUnitsPerAdvertising}
                  title={t('marketing.indexGraphics.unitsPerAd.title')}
                  tooltipTitle={t('marketing.indexGraphics.unitsPerAd.tooltip')}
                  currency={false}
                />
              </Col>
              <Col xs={24} md={12} lg={8} xl={4} span={4}>
                <CardData
                  loader={dataOrganicUnitsLoading}
                  data={dataOrganicUnits}
                  title={t('marketing.indexGraphics.organicUnits.title')}
                  tooltipTitle={t(
                    'marketing.indexGraphics.organicUnits.tooltip'
                  )}
                  currency={false}
                />
              </Col>
            </Row>
            <Row style={{ textAlign: 'left' }}>
              <Col span={24}>
                <Card
                  bordered={false}
                  style={{ width: '100%', marginTop: 5, border: 0 }}
                >
                  {!dataDualAxeGraphLoading ? (
                    <DualAxesChart dataSource={dataDualAxesChart} />
                  ) : (
                    <Card>
                      <center>
                        <Spin size="large" />
                      </center>
                    </Card>
                  )}
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="home-listing-main-row listings-main">
        <Col span={24} xs={24} sm={24} md={24}>
          <Card className="ventas-home-listing-card">
            <Row className="intro-totales">
              <Col xs={24} md={12} lg={8} xl={4} span={4}>
                <CardData
                  loader={dataImpressionsLoading}
                  data={dataImpressions}
                  title={t('marketing.indexGraphics.impressions.title')}
                  tooltipTitle={t(
                    'marketing.indexGraphics.impressions.tooltip'
                  )}
                  currency={false}
                />
              </Col>
              <Col xs={24} md={12} lg={8} xl={4} span={4}>
                <CardData
                  loader={dataClicksLoading}
                  data={dataClicks}
                  title={t('marketing.indexGraphics.clicks.title')}
                  tooltipTitle={t('marketing.indexGraphics.clicks.tooltip')}
                  currency={false}
                />
              </Col>
              <Col xs={24} md={12} lg={8} xl={4} span={4}>
                <CardData
                  loader={dataCtrLoading}
                  data={dataCtr}
                  title={t('marketing.indexGraphics.ctr.title')}
                  tooltipTitle={t('marketing.indexGraphics.ctr.tooltip')}
                  percentage
                />
              </Col>
              <Col xs={24} md={12} lg={8} xl={4} span={4}>
                <CardData
                  loader={dataCvrLoading}
                  data={dataCvr}
                  title={t('marketing.indexGraphics.cvr.title')}
                  tooltipTitle={t('marketing.indexGraphics.cvr.tooltip')}
                  percentage
                />
              </Col>
              <Col xs={24} md={12} lg={8} xl={4} span={4}>
                <CardData
                  loader={dataCpcLoading}
                  data={dataCpc}
                  title={t('marketing.indexGraphics.cpc.title')}
                  tooltipTitle={t('marketing.indexGraphics.cpc.tooltip')}
                  currency
                  normalPercentageColor={false}
                />
              </Col>
              <Col xs={24} md={12} lg={8} xl={4} span={4}>
                <CardData
                  loader={dataAcosLoading}
                  data={dataAcos}
                  title={t('marketing.indexGraphics.acos.title')}
                  tooltipTitle={t('marketing.indexGraphics.acos.tooltip')}
                  percentage
                  normalPercentageColor={false}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default IndexGraphics;

IndexGraphics.propTypes = {
  dataTotalSalesLoading: PropTypes.bool.isRequired,
  dataTotalSales: PropTypes.instanceOf(Object).isRequired,
  dataSalesPerAdvertisingLoading: PropTypes.bool.isRequired,
  dataSalesPerAdvertising: PropTypes.instanceOf(Object).isRequired,
  dataOrganicSalesLoading: PropTypes.bool.isRequired,
  dataOrganicSales: PropTypes.instanceOf(Object).isRequired,
  dataInvestmentLoading: PropTypes.bool.isRequired,
  dataInvestment: PropTypes.instanceOf(Object).isRequired,
  dataSoldUnits: PropTypes.instanceOf(Object).isRequired,
  dataUnitsPerAdvertising: PropTypes.instanceOf(Object).isRequired,
  dataSoldUnitsLoading: PropTypes.bool.isRequired,
  dataUnitsPerAdvertisingLoading: PropTypes.bool.isRequired,
  dataOrganicUnitsLoading: PropTypes.bool.isRequired,
  dataOrganicUnits: PropTypes.instanceOf(Object).isRequired,
  dataClicks: PropTypes.instanceOf(Object).isRequired,
  dataClicksLoading: PropTypes.bool.isRequired,
  dataImpressions: PropTypes.instanceOf(Object).isRequired,
  dataImpressionsLoading: PropTypes.bool.isRequired,
  dataCtr: PropTypes.instanceOf(Object).isRequired,
  dataCvr: PropTypes.instanceOf(Object).isRequired,
  dataCpc: PropTypes.instanceOf(Object).isRequired,
  dataCtrLoading: PropTypes.bool.isRequired,
  dataCvrLoading: PropTypes.bool.isRequired,
  dataCpcLoading: PropTypes.bool.isRequired,
  dataAdsLoading: PropTypes.bool.isRequired,
  dataAds: PropTypes.instanceOf(Object).isRequired,
  dataTacosLoading: PropTypes.bool.isRequired,
  dataTacos: PropTypes.instanceOf(Object).isRequired,
  dataAcosLoading: PropTypes.bool.isRequired,
  dataAcos: PropTypes.instanceOf(Object).isRequired,
  dataDualAxesChart: PropTypes.arrayOf(Object).isRequired,
  dataDualAxeGraphLoading: PropTypes.bool.isRequired,
  hasAdvertising: PropTypes.bool.isRequired,
};

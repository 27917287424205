/* eslint-disable react/jsx-props-no-spreading */
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import DafitiSellerAcount from './marketplaces/DafitiSellerAcount';
import EbaySellerAccount from './marketplaces/EbaySellerAccount';
import EdicommerceSellerAccount from './marketplaces/EdicommerceSellerAccount';
import MagentoSellerAccount from './marketplaces/MagentoSellerAccount';
import MiraklSellerAccount from './marketplaces/MiraklSellerAccount';
import ShopifySellerAccount from './marketplaces/ShopifySellerAccount';
import WalmartSellerAccount from './marketplaces/WalmartSellerAccount';
import WoocommerceSellerAccount from './marketplaces/WoocommerceSellerAccount';
import PrestashopSellerAccount from './marketplaces/PrestashopSellerAccount';
import FalabellaSellerAccount from './marketplaces/FalabellaSellerAccount';
import LinioSellerAccount from './marketplaces/LinioSellerAccount';

const ComponentByMarketplace = {
  Ebay: EbaySellerAccount,
  Walmart: WalmartSellerAccount,
  Shopify: ShopifySellerAccount,
  Coppel: MiraklSellerAccount,
  Liverpool: MiraklSellerAccount,
  Ripley: MiraklSellerAccount,
  Edicommerce: EdicommerceSellerAccount,
  Woocommerce: WoocommerceSellerAccount,
  Falabella: FalabellaSellerAccount,
  Linio: LinioSellerAccount,
  Prestashop: PrestashopSellerAccount,
  Dafiti: DafitiSellerAcount,
  Magento: MagentoSellerAccount,
  'Walmart Mexico': WalmartSellerAccount,
};
function AccountByMarketplace(props) {
  const { parentMarketplace, session } = props;
  const Comp = ComponentByMarketplace[parentMarketplace.name];

  const finalProps = {
    ...props,
    ...parentMarketplace,
    tab: parentMarketplace.name,
    session,
  };

  return (
    <>
      <div>
        <h1
          style={{
            fontWeight: 600,
            fontSize: '20px',
          }}
        >
          Conecta tu cuenta de{' '}
          {parentMarketplace.name === 'Edicommerce'
            ? 'Walmart DSV MX'
            : parentMarketplace.name}
        </h1>
      </div>
      <Comp {...finalProps} />
    </>
  );
}

AccountByMarketplace.propTypes = {
  parentMarketplace: PropTypes.instanceOf(Object).isRequired,
  session: PropTypes.instanceOf(Object).isRequired,
};

function ModalConect({ isModalOpen, handleCancel, allProps }) {
  return (
    <Modal
      footer={null}
      width={500}
      open={isModalOpen}
      onCancel={handleCancel}
      okButtonProps={{ hidden: true }}
    >
      <AccountByMarketplace {...allProps} />
    </Modal>
  );
}

ModalConect.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  allProps: PropTypes.instanceOf(Object).isRequired,
};

export default ModalConect;

import createReducer from '../store/createReducer';
import * as types from './types';

const initialState = {
  error: null,
  session: null,
  language: 'es',
};
const Session = createReducer(initialState, {
  [types.LOGIN](state) {
    return {
      ...state,
      error: null,
      session: state.session,
    };
  },
  [types.LOGIN_SUCCESS](state, action) {
    return {
      ...state,
      error: null,
      session: action.payload,
    };
  },
  [types.LOGIN_FAILED](state, action) {
    return {
      ...initialState,
      error: action.payload,
      session: null,
    };
  },
  [types.REFRESH_SESSION](state, action) {
    return {
      ...state,
      error: null,
      session: action.payload,
    };
  },
  [types.LOGOUT](state) {
    return {
      ...state,
      error: null,
      session: null,
    };
  },
  [types.UPDATE_STATE_SELLER_ACCOUNT](state, action) {
    const newUserInfo = {
      ...state.session.userInfo,
      sellerAccountStatus: action.payload,
    };
    return {
      ...state,
      session: {
        ...state.session,
        userInfo: newUserInfo,
      },
    };
  },
  [types.UPDATE_STATE_ADS_ACCOUNT](state, action) {
    const newUserInfo = {
      ...state.session.userInfo,
      adsAccountStatus: action.payload,
    };
    return {
      ...state,
      session: {
        ...state.session,
        userInfo: newUserInfo,
      },
    };
  },
  [types.UPDATE_USER_INFO](state, action) {
    const currentUserInfo = state.session.userInfo;
    const newUserInfo = {
      ...currentUserInfo,
      name: action.payload.name,
      phone: action.payload.phone,
      birthday: action.payload.birthday,
      passport_id: action.payload.passport_id,
      country_id: action.payload.country_id,
      x_fantasy_name: action.payload.x_fantasy_name,
      x_employees_number: action.payload.x_employees_number,
      x_sales_range: action.payload.x_sales_range,
    };
    return {
      ...state,
      session: {
        ...state.session,
        userInfo: newUserInfo,
      },
    };
  },
  [types.UPDATE_USER_INFO_NEW_COMPANY](state, action) {
    const currentUserInfo = state.session.userInfo;
    const newUserInfo = {
      ...currentUserInfo,
      is_company_new: action.payload,
    };
    return {
      ...state,
      session: {
        ...state.session,
        userInfo: newUserInfo,
      },
    };
  },
  [types.SUBSCRIBE_SUCCESSFULLY](state, action) {
    const currentUserInfo = state.session.userInfo;
    const subscriptionType = `${action.payload.type.toLowerCase()}Subscription`;
    const newUserInfo = {
      ...currentUserInfo,
      subscriptions: {
        ...currentUserInfo.subscriptions,
        [subscriptionType]: { ...action.payload },
      },
    };
    return {
      ...state,
      error: null,
      session: {
        ...state.session,
        companyActive: true,
        userInfo: newUserInfo,
      },
    };
  },
  [types.CANCEL_SUBSCRIPTION](state, action) {
    const currentUserInfo = state.session.userInfo;
    const subscriptionType = `${action.payload.toLowerCase()}Subscription`;
    const newUserInfo = {
      ...currentUserInfo,
      subscriptions: {
        ...currentUserInfo.subscriptions,
        [subscriptionType]: {
          ...currentUserInfo.subscriptions[subscriptionType],
          active: false,
        },
      },
    };
    return {
      ...state,
      error: null,
      session: {
        ...state.session,
        userInfo: newUserInfo,
      },
    };
  },
  [types.UPDATE_LANGUAGE](state, action) {
    return {
      ...state,
      language: action.payload,
    };
  },
});

export default Session;

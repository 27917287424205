import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Tag, Row, Col, Radio, Typography } from 'antd';

function CheckCards({
  isPack,
  radioDefault,
  radioOptionValue,
  inputRadioActive,
  tag,
  title,
  text,
  value,
  hidden,
}) {
  const { t } = useTranslation();
  const styleSelected = value ? isPack : !isPack;

  return (
    <Card
      className="card-radio-inline cardBaseStyle"
      style={styleSelected && radioDefault ? inputRadioActive : {}}
      bodyStyle={{ height: '100%' }}
      hidden={hidden}
    >
      <Row
        justify="space-between"
        align="middle"
        style={{ height: '100%', display: 'flex', flexWrap: 'nowrap' }}
      >
        <Col flex="20px" style={{ marginRight: 10 }}>
          <Radio value={radioOptionValue ?? value} />
        </Col>
        <Col flex="auto" className="card-content-div">
          <Row>
            <Col flex="none">
              <Typography.Title level={5} style={{ fontStyle: 'normal' }}>
                {title}
              </Typography.Title>
            </Col>
            {tag && (
              <Col flex="auto">
                <Tag
                  color="rgba(0, 229, 166, 0.5)"
                  className="tag-shipment-card"
                >
                  {t('common.recommended')}
                </Tag>
              </Col>
            )}
          </Row>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Typography.Text
              style={{
                color: 'rgba(100, 100, 100, 1)',
                fontWeight: 400,
              }}
            >
              {text}
            </Typography.Text>
          </div>
        </Col>
      </Row>
    </Card>
  );
}

CheckCards.propTypes = {
  isPack: PropTypes.bool.isRequired,
  radioDefault: PropTypes.bool.isRequired,
  inputRadioActive: PropTypes.shape({
    border: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
  }).isRequired,
  tag: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  hidden: PropTypes.bool,
  radioOptionValue: PropTypes.string,
};

CheckCards.defaultProps = {
  hidden: false,
  radioOptionValue: null,
};

export default CheckCards;

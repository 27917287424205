import mem from 'mem';
import jwtDecode from 'jwt-decode';
import { isExpired } from '../helpers/auth-helpers';

const refreshToken = async (api, session) => {
  try {
    if (isExpired(session.refresh_token)) {
      throw new Error('Sesión expirada. Por favor, inicia sesión nuevamente.');
    }
    const decodedToken = jwtDecode(session.token);
    const response = await api.post(`auth/token`, {
      refresh_token: session.refresh_token,
      sub: decodedToken.sub,
      username: session.userName,
    });
    if (response.status === 200 && response.data.success) {
      // localStorage.setItem('session', JSON.stringify(response.data));
      return response.data;
    }
    throw new Error(
      'Ocurrió un error al intentar extender la sesión. Por favor, inicia sesión nuevamente.'
    );
  } catch (error) {
    // localStorage.removeItem('session');
    localStorage.removeItem('persist:root');
    return error;
  }
};

const maxAge = 15000;

const refreshSession = mem(refreshToken, { maxAge });
export default refreshSession;

import React, { useEffect, useState } from 'react';
import {
  PictureOutlined,
  InfoCircleOutlined,
  WarningOutlined,
  SaveOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Row,
  Col,
  Card,
  Typography,
  Button,
  Divider,
  Form,
  Select,
  InputNumber,
  Spin,
  Space,
  Tooltip,
  List,
  Tag,
  Modal,
  Input,
  Pagination,
  Checkbox,
} from 'antd';
import Search from 'antd/lib/input/Search';
import { Header } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import './calculator.css';
import openNotification from '../../components/Toastr';
import feesAPI from '../../api/fees';
import { marketplaceCurrency } from '../../utils/const';
import { getErrorMessage } from '../../api/api';
import usePlacesInput from './components/usePlacesInputCalculator';
import couriersAPI from '../../api/courier';
import newLogo from '../../assets/LAP_GLOBAL.png';
import { mixPanelCreateEvent } from '../../utils/functions';
import shipmentsAPI from '../../api/shipments';
import DeliveryFees from './components/calculator/DeliveryFees';

const { Title, Text } = Typography;

const maxUnitsBySystem = {
  intl: {
    height: '178',
    length: '120',
    width: '100',
    weight: '680',
  },
  imperial: {
    height: '70',
    length: '48',
    width: '40',
    weight: '1500',
  },
};

function Calculadora() {
  const { t } = useTranslation();
  const session = useSelector((store) => store.Session.session);
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [calculating, setCalculating] = useState(false);
  const [calculatedFees, setCalculatedFees] = useState(null);
  const [deliveryFeesOptions, setDeliveryFeesOptions] = useState(null);
  const [deliveryCarrierSelected, setDeliveryCarrierSelected] = useState(null);
  const [originAddress, setOriginAddress] = useState(null);
  const [destinationAddress, setDestinationAddress] = useState(null);
  const [marketplaceSelected, setMarketplaceSelected] = useState('');
  const [logisticSelected, setLogisticSelected] = useState('');
  const [logisticServiceSelected, setLogisticServiceSelected] = useState('');
  const [packagingTypeSelected, setPackagingTypeSelected] = useState(null);
  const [availablePackagingTypes, setAvailablePackagingTypes] = useState([]);
  const [currencySelected, setCurrencySelected] = useState('USD');
  const [couriers, setCouriers] = useState([]);
  const [lastPayload, setLastPayload] = useState(null);
  const [lastInputs, setLastInputs] = useState(null);
  const [saving, setSaving] = useState(false);
  const [canSave, setCanSave] = useState(true);
  const [myCalculations, setMyCalculations] = useState(null);
  const [myCalculationsOpen, setMyCalculationsOpen] = useState(false);
  const [myCalculationsFilter, setMyCalculationsFilter] = useState(null);
  const [saveCalculationOpen, setSaveCalculationOpen] = useState(false);
  const [calculationName, setCalculationName] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [shippingOnly, setShippingOnly] = useState(false);
  const [maxUnits, setMaxUnits] = useState(maxUnitsBySystem.intl);

  const img = (
    <img
      src={newLogo}
      className="logo"
      alt="logo de LAP MKT"
      height={50}
      style={{ transition: 'all 0.5s' }}
    />
  );

  const marketplaces = [
    'Amazon',
    'Amazon Canada',
    'Amazon Mexico',
    'Ebay',
    'Mercadolibre Chile',
    'Mercadolibre Colombia',
    'Mercadolibre Mexico',
  ];

  const fulfillment = ['Amazon'];

  const packagingTypes = { BOX: 'ALL', PALLET: ['Fedex', 'Amazon'] };

  const unitaryPackagingServices = ['99Minutos'];

  const currencies = [
    ...new Set(
      marketplaces.map((marketplace) => marketplaceCurrency[marketplace])
    ),
  ];

  const origin = usePlacesInput(setOriginAddress);
  const destination = usePlacesInput(setDestinationAddress);

  useEffect(async () => {
    if (originAddress && destinationAddress && logisticSelected === 'FBM') {
      try {
        form.resetFields(['logisticService']);
        setCouriers([]);
        const { data } = await couriersAPI.getCouriersByCoverage(
          originAddress,
          destinationAddress
        );
        setCouriers(data.map((courier) => courier.courierCompanyName));
        if (data.length === 1) {
          form.setFieldValue('logisticService', data[0].courierCompanyName);
          setLogisticServiceSelected(data[0].courierCompanyName);
        }
      } catch (error) {
        openNotification({ content: getErrorMessage(error) });
      }
    }
  }, [originAddress, destinationAddress, logisticSelected]);

  useEffect(() => {
    form.setFieldsValue({
      currency: 'USD',
      weightUnit: 'KG',
      lengthUnit: 'CM',
      // product: 'newProduct',
    });
    setPackagingTypeSelected(t('calculator.packaging.type.default'));
  }, []);

  useEffect(() => {
    const newPackagingTypes = logisticServiceSelected
      ? Object.keys(packagingTypes).filter(
          (packaging) =>
            packagingTypes[packaging].includes(logisticServiceSelected) ||
            packagingTypes[packaging] === 'ALL'
        )
      : [];
    if (newPackagingTypes.length === 1) {
      form.setFieldValue('packagingType', newPackagingTypes[0]);
    } else {
      form.setFieldValue('packagingType', undefined);
      setPackagingTypeSelected(t('calculator.packaging.type.default'));
    }
    setAvailablePackagingTypes(newPackagingTypes);
    form.setFieldValue(
      'packagingUnits',
      unitaryPackagingServices.includes(logisticServiceSelected) ? 1 : undefined
    );
  }, [logisticServiceSelected]);

  const parseCurrency = (currency) => {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency,
    });
  };

  const getLogisticOperators = () => {
    switch (logisticSelected) {
      case 'FBM':
        return couriers;
      case 'FBO':
        return fulfillment;
      default:
        return [];
    }
  };

  const onSelectMarketplace = async (value) => {
    try {
      form.setFieldsValue({
        category: undefined,
        currency: marketplaceCurrency[value] || 'USD',
      });
      setCurrencySelected(marketplaceCurrency[value] || 'USD');
      setMarketplaceSelected(value);
      const response = await feesAPI.getCategories(value);
      setCategories(response.data);
    } catch (error) {
      openNotification({ content: getErrorMessage(error) });
    }
  };

  const onSelectLogistic = (value) => {
    setLogisticServiceSelected('');
    setLogisticSelected(value);
    form.setFieldsValue({
      logisticService: undefined,
      packagingType: undefined,
    });
    setPackagingTypeSelected(t('calculator.packaging.type.default'));
  };

  const onSelectPackagingType = (value) => {
    setPackagingTypeSelected(
      t(`calculator.packaging.type.${value.toLowerCase()}`)
    );
  };

  const resetForm = () => {
    const fieldNames = Object.keys(form.getFieldsValue());
    let resetValues = {};
    fieldNames.forEach((fieldName) => {
      resetValues[fieldName] = undefined;
    });

    resetValues = {
      ...resetValues,
      // product: 'newProduct',
      originAddress: '',
      destinationAddress: '',
      currency: 'USD',
      lengthUnit: 'CM',
      weightUnit: 'KG',
    };
    form.setFieldsValue(resetValues);
    setMaxUnits(maxUnitsBySystem.intl);
    setPackagingTypeSelected(t('calculator.packaging.type.default'));
    setLastPayload(null);
    setCalculationName(null);
    setCategories([]);
    setCalculatedFees(null);
    setDeliveryFeesOptions(null);
    setDeliveryCarrierSelected(null);
    setOriginAddress(null);
    setDestinationAddress(null);
    setMarketplaceSelected('');
    setLogisticSelected(shippingOnly ? 'FBM' : '');
    setLogisticServiceSelected('');
    setCouriers([]);
  };

  const getRates = async (values) => {
    try {
      const payload = {
        courierName: values.logisticService,
        currency: values.currency,
        palletized: values.packagingType === 'PALLET',
        shipper: {
          address: {
            countryCode: originAddress.countryCode,
            postalCode: originAddress.postalCode,
            city: originAddress.city,
          },
        },
        recipient: {
          address: {
            countryCode: destinationAddress.countryCode,
            postalCode: destinationAddress.postalCode,
            city: destinationAddress.city,
          },
        },
        products: Array.from(
          { length: parseInt(values.packagingUnits, 10) },
          () => ({
            description: 'Producto',
            unitPrice: {
              currency: values.currency,
              value: parseFloat(values.cost),
            },
            quantity: parseInt(values.unitsPerPackaging, 10),
            countryOfManufacture: originAddress.countryCode,
            harmonizedCode: values.harmonizedCode || '6109.10',
            unitWeight: {
              unit: values.weightUnit,
              value:
                parseFloat(values.packagingWeight) /
                parseInt(values.unitsPerPackaging, 10),
            },
          })
        ),
        boxes: Array.from(
          { length: parseInt(values.packagingUnits, 10) },
          () => ({
            pallet: values.packagingType === 'PALLET',
            dimensions: {
              unit: values.lengthUnit,
              length: parseFloat(values.packagingLength),
              width: parseFloat(values.packagingWidth),
              height: parseFloat(values.packagingHeight),
            },
            weight: {
              value: parseFloat(values.packagingWeight),
              unit: values.weightUnit,
            },
          })
        ),
      };
      const response = await shipmentsAPI.getShipmentRates(payload);

      setDeliveryCarrierSelected(0);
      setDeliveryFeesOptions(response.data);

      setCalculatedFees({
        currency: response.data.currency,
        deliveryFees: response.data[0],
      });
    } catch (error) {
      openNotification({ status: false, content: error.response.data.message });
    }
  };

  const onSubmit = async (values) => {
    setCalculating(true);
    if (shippingOnly) await getRates(values);
    else {
      try {
        const payload = {
          ...(values.marketplace !== 'OTHERS'
            ? {
                marketplace: values.marketplace,
                category: values.category,
              }
            : {}),
          ...(shippingOnly
            ? {
                cost: parseFloat(values.cost),
              }
            : {
                price: parseFloat(values.price),
                cost: parseFloat(values.price),
              }),
          currency: values.currency,
          logistic: values.logistic,
          logisticService: values.logisticService,
          // meliListingTypeId: string,
          originAddress: { ...(originAddress || values.originAddress) },
          destinationAddress: {
            ...(destinationAddress || values.destinationAddress),
          },
          dimensions: {
            length: parseFloat(values.length),
            width: parseFloat(values.width),
            height: parseFloat(values.height),
            units: values.lengthUnit?.toUpperCase(),
          },
          weight: {
            value: parseFloat(values.weight),
            units: values.weightUnit?.toUpperCase(),
          },
          ...(values.packagingType
            ? {
                packaging: {
                  weight: {
                    value: parseFloat(values.packagingWeight),
                    units: values.weightUnit?.toUpperCase(),
                  },
                  dimensions: {
                    length: parseFloat(values.packagingLength),
                    width: parseFloat(values.packagingWidth),
                    height: parseFloat(values.packagingHeight),
                    units: values.lengthUnit?.toUpperCase(),
                  },
                  type: values.packagingType?.toUpperCase(),
                  quantity: parseFloat(values.packagingUnits),
                  unitsPerPackaging: parseFloat(values.unitsPerPackaging),
                },
              }
            : {}),
        };
        mixPanelCreateEvent('Action Button', {
          action: 'Button calculate fees',
          payload,
        });
        const response = await feesAPI.calculateFees(payload);
        if (response.status !== 200) {
          throw new Error(response.data);
        }
        const totalMarketplaceFees = response.data?.marketplaceFees
          ? response.data?.marketplaceFees?.reduce(
              (prev, curr) => curr.value + prev,
              0
            )
          : 0;
        const getUnitFee = (total) =>
          payload.packaging
            ? total /
              (payload.packaging.quantity * payload.packaging.unitsPerPackaging)
            : total;
        const unitRestockFees = {
          ACCOUNT: getUnitFee(response.data?.restockFees?.[0]?.totalRate ?? 0),
          LIST: getUnitFee(
            response.data?.restockFees?.[0]?.totalRateWithoutDiscount?.value ??
              0
          ),
        };
        setLastPayload(payload);
        const inputs = Object.fromEntries(
          Object.entries(values).map(([key, value]) => [
            key,
            !Number.isNaN(parseFloat(value)) ? parseFloat(value) : value,
          ])
        );
        inputs.originAddress = originAddress;
        inputs.destinationAddress = destinationAddress;
        if (values.marketplace === 'OTHERS') {
          delete inputs.marketplace;
          delete inputs.category;
        }

        const { deliveryFees } = response.data;
        setDeliveryCarrierSelected(0);
        setDeliveryFeesOptions(deliveryFees);

        setLastInputs(inputs);
        setCalculatedFees({
          ...response.data,
          totalMarketplaceFees,
          unitRestockFees,
          deliveryFees: deliveryFees?.[0],
        });
        setCanSave(true);
        setCalculationName(null);
      } catch (error) {
        openNotification({ status: false, content: getErrorMessage(error) });
        setCalculatedFees(null);
      }
    }
    setCalculating(false);
  };

  const onSaveCalculation = async () => {
    try {
      setSaving(true);
      await feesAPI.saveCalculation(lastInputs, calculationName);
      openNotification({
        status: true,
        content: t('calculator.saveCalculation.success'),
      });
      setCanSave(false);
      setSaveCalculationOpen(false);
    } catch (error) {
      openNotification({
        status: false,
        content: t('calculator.saveCalculation.error', {
          error: getErrorMessage(error),
        }),
      });
    } finally {
      setSaving(false);
    }
  };

  const onSaveCalculationOpen = () => {
    setCalculationName(null);
    setSaveCalculationOpen(true);
  };

  const onSaveCalculationClose = () => {
    setCalculationName(null);
    setSaveCalculationOpen(false);
  };

  const onMyCalculationsOpen = async () => {
    try {
      const { data } = await feesAPI.getCalculations(4, 1);
      setMyCalculationsFilter({ search: '', page: 0 });
      setMyCalculations(data);
      setMyCalculationsOpen(true);
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
  };

  const onCalculationsSearch = async (search) => {
    try {
      const { data } = await feesAPI.getCalculations(4, 1, search ?? null);
      setMyCalculationsFilter({ search, page: 1 });
      setMyCalculations(data);
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
  };

  const onCalculationsPaging = async (page) => {
    try {
      const { data } = await feesAPI.getCalculations(
        4,
        page,
        myCalculationsFilter?.search
      );
      setMyCalculationsFilter({ search: myCalculationsFilter?.search, page });
      setMyCalculations(data);
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
  };

  const onMyCalculationsClose = () => {
    setMyCalculationsOpen(false);
    setMyCalculationsFilter(null);
  };

  const onLoadCalculation = async (id) => {
    try {
      onMyCalculationsClose();
      const { data } = await feesAPI.getCalculation(id);
      resetForm();
      form.setFieldsValue(data?.inputs);
      form.setFieldsValue({
        originAddress: data?.inputs.originAddress.address,
        destinationAddress: data?.inputs.destinationAddress.address,
        ...(!data?.inputs.marketplace ? { marketplace: 'OTHERS' } : {}),
      });
      setOriginAddress(data?.inputs.originAddress);
      setDestinationAddress(data?.inputs.destinationAddress);
      setMarketplaceSelected(form.getFieldValue('marketplace'));
      setLogisticSelected(data?.inputs.logistic);
      setPackagingTypeSelected(
        t(
          `calculator.packaging.type.${
            data?.inputs.packagingType?.toLowerCase() || 'default'
          }`
        )
      );
      form.setFieldsValue({
        logisticService: data?.inputs.logisticService,
      });
      await onSubmit(data?.inputs);
      setCalculationName(data?.name);
      setCanSave(false);
      openNotification({
        status: true,
        content: t('calculator.loadCalculation.success'),
      });
    } catch (error) {
      openNotification({
        status: false,
        content: t('calculator.loadCalculation.error', {
          error: getErrorMessage(error),
        }),
      });
    } finally {
      setLoadingDelete(false);
    }
  };

  const onDeleteCalculation = async (id) => {
    try {
      setLoadingDelete(true);
      await feesAPI.deleteCalculation(id);
      const newCalculations = myCalculations;
      newCalculations.results = newCalculations.results.filter(
        (e) => e.id !== id
      );
      newCalculations.totalResults -= 1;
      setMyCalculations(newCalculations);
      openNotification({
        status: true,
        content: 'Cálculo eliminado con éxito.',
      });
    } catch (error) {
      openNotification({
        status: false,
        content: `Error al eliminar el cálculo (${getErrorMessage(error)})`,
      });
    } finally {
      setLoadingDelete(false);
    }
  };

  const pricePrefixSelector = (
    <Form.Item name="currency" initialValues={['USD', 'USD']} noStyle>
      <Select
        style={{ width: 90 }}
        onSelect={(value) => setCurrencySelected(value)}
      >
        {currencies.map((currency) => (
          <Select.Option value={currency} />
        ))}
      </Select>
    </Form.Item>
  );

  const imperialUnits = ['LB', 'IN'];

  const onSelectUnit = (value) => {
    const isImperial = imperialUnits.includes(value);
    form.setFieldValue('weightUnit', isImperial ? 'LB' : 'KG');
    form.setFieldValue('lengthUnit', isImperial ? 'IN' : 'CM');
    setMaxUnits(maxUnitsBySystem[isImperial ? 'imperial' : 'intl']);
  };

  const weightPrefixSelector = (
    <Form.Item name="weightUnit" initialValue="KG" noStyle>
      <Select style={{ width: 70 }} onSelect={(value) => onSelectUnit(value)}>
        <Select.Option value="KG">kg</Select.Option>
        <Select.Option value="LB">lb</Select.Option>
      </Select>
    </Form.Item>
  );

  const lengthPrefixSelector = (
    <Form.Item name="lengthUnit" initialValues={['CM', 'CM', 'CM']} noStyle>
      <Select
        value="CM"
        style={{ width: 70 }}
        onSelect={(value) => onSelectUnit(value)}
      >
        <Select.Option value="CM">cm</Select.Option>
        <Select.Option value="IN">in</Select.Option>
      </Select>
    </Form.Item>
  );

  const labelTooltip = (label, description) => (
    <Space>
      <Col>
        <Text>{label}</Text>
      </Col>
      <Col>
        <Tooltip title={description}>
          <InfoCircleOutlined />
        </Tooltip>
      </Col>
    </Space>
  );
  const descriptionMeta = (description) => (
    <Space style={{ lineHeight: '15px' }}>
      <Col>{description}</Col>
    </Space>
  );

  return (
    <>
      {!session?.success && (
        <Header
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div className="logo LayoutHeaderLogo">
            {!session?.success ? (
              <a href="https://lapmarketplace.com">{img}</a>
            ) : (
              <Link to="/">{img}</Link>
            )}
          </div>
          <Button>
            <Link to="/login">Iniciar Sesión</Link>
          </Button>
        </Header>
      )}
      <div
        className="content-div"
        style={{
          backgroundColor: '#f9f9f9',
          paddingBottom: 20,
          paddingTop: session?.success ? 70 : 35,
          paddingLeft: 20,
          paddingRight: 15,
        }}
      >
        <Row>
          <Col span={24}>
            <Row style={{ paddingBottom: '10px' }}>
              <Col xs={24} md={24} lg={24} xl={24} span={24}>
                <Card className="ventas-home-listing-card">
                  <Row style={{ width: '100%' }}>
                    <Col className="welcome-container" span={20}>
                      <div>
                        <Title level={3} className="titlePages">
                          {t('calculator.mainTitle')}
                        </Title>
                        <span className="subtitlePages">
                          {t('calculator.mainSubtitle')}
                        </span>
                      </div>
                    </Col>
                    <Col
                      span={4}
                      style={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                      }}
                    >
                      <Checkbox
                        onChange={() => {
                          resetForm();
                          setLogisticSelected(shippingOnly ? null : 'FBM');
                          setShippingOnly(!shippingOnly);
                        }}
                      >
                        {t('calculator.shippingOnly')}
                      </Checkbox>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Form
                  name="formCalculator"
                  form={form}
                  onFinish={onSubmit}
                  layout="vertical"
                  className="text-align-left"
                >
                  <Row style={{ paddingBottom: '10px' }}>
                    <Col span={24}>
                      <Card className="ventas-home-listing-card">
                        <Row gutter={[24, 24]} style={{ marginLeft: '0' }}>
                          {!shippingOnly && (
                            <Row gutter={24} style={{ width: '100%' }}>
                              <Col span={8}>
                                <Form.Item
                                  name="marketplace"
                                  label={labelTooltip(
                                    t('calculator.marketplace.title'),
                                    t('calculator.marketplace.tooltip')
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        'calculator.marketplace.emptyError'
                                      ),
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    className="select-before"
                                    placeholder={t(
                                      'calculator.marketplace.placeholder'
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.value ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    onSelect={(value) =>
                                      onSelectMarketplace(value)
                                    }
                                  >
                                    {marketplaces.map((marketplace) => (
                                      <Select.Option value={marketplace}>
                                        {t(
                                          `dashboard.marketplaces.${marketplace.replace(
                                            ' ',
                                            ''
                                          )}`
                                        )}
                                      </Select.Option>
                                    ))}
                                    <Select.Option value="OTHERS">
                                      {t('calculator.others')}
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name="category"
                                  label={labelTooltip(
                                    t('calculator.category.title'),
                                    t('calculator.category.tooltip')
                                  )}
                                  rules={[
                                    {
                                      required:
                                        marketplaceSelected !== 'OTHERS',
                                      message: t(
                                        'calculator.category.emptyError'
                                      ),
                                    },
                                  ]}
                                >
                                  <Select
                                    disabled={marketplaceSelected === 'OTHERS'}
                                    showSearch
                                    className="select-before"
                                    placeholder={t(
                                      'calculator.category.placeholder'
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.value ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                  >
                                    {categories.map((category) => (
                                      <Select.Option
                                        value={category.id || category}
                                      >
                                        {category.name || category}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name="price"
                                  label={labelTooltip(
                                    t('calculator.price.title'),
                                    t('calculator.price.tooltip')
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t('calculator.price.emptyError'),
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    min="0.01"
                                    placeholder="0.01"
                                    addonBefore={pricePrefixSelector}
                                    step="0.01"
                                    stringMode
                                    controls={false}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          )}
                          {/* <Row gutter={24} style={{ width: '100%' }}>
                          <Col span={8}>
                            <Form.Item
                              name="product"
                              initialValue="newProduct"
                              label={labelTooltip(
                                'Producto',
                                'Selecciona si quieres analizar la cadena de costos de un producto nuevo (que aún no has publicado en el marketplace seleccionado) o de alguno que ya tienes publicado.',
                              )}
                            >
                              <Select
                                showSearch
                                className="select-before"
                                placeholder="Producto seleccionado"
                                filterOption={(input, option) =>
                                  (option?.value ?? '')
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                <Select.Option value="newProduct">
                                  Nuevo producto
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row> */}
                          <Row gutter={24} style={{ width: '100%' }}>
                            <Col span={12}>
                              <Form.Item
                                name="originAddress"
                                label={labelTooltip(
                                  t('calculator.originAddress.title'),
                                  t('calculator.originAddress.tooltip')
                                )}
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      'calculator.originAddress.emptyError'
                                    ),
                                  },
                                ]}
                              >
                                {origin}
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name="destinationAddress"
                                label={labelTooltip(
                                  t('calculator.destinationAddress.title'),
                                  t('calculator.destinationAddress.tooltip')
                                )}
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      'calculator.destinationAddress.emptyError'
                                    ),
                                  },
                                ]}
                              >
                                {destination}
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={24} style={{ width: '100%' }}>
                            <Col span={8}>
                              {!shippingOnly ? (
                                <Form.Item
                                  name="logistic"
                                  label={labelTooltip(
                                    t('calculator.logisticType.title'),
                                    <span>
                                      <span
                                        style={{ textDecoration: 'underline' }}
                                      >
                                        {t(
                                          'calculator.logisticType.origin.title'
                                        )}
                                      </span>
                                      {': '}
                                      {t(
                                        'calculator.logisticType.origin.description'
                                      )}
                                      <br />
                                      <br />
                                      <span
                                        style={{ textDecoration: 'underline' }}
                                      >
                                        {t(
                                          'calculator.logisticType.destination.title'
                                        )}
                                      </span>
                                      {': '}
                                      {t(
                                        'calculator.logisticType.destination.description'
                                      )}
                                    </span>
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        'calculator.logisticType.emptyError'
                                      ),
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    className="select-before"
                                    placeholder={t(
                                      'calculator.logisticType.placeholder'
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.value ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    onSelect={onSelectLogistic}
                                  >
                                    <Select.Option value="FBM">
                                      {t(
                                        'calculator.logisticType.origin.title'
                                      )}
                                    </Select.Option>
                                    <Select.Option value="FBO">
                                      {t(
                                        'calculator.logisticType.destination.title'
                                      )}
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              ) : (
                                <Form.Item
                                  name="cost"
                                  label={labelTooltip(
                                    'Costo del producto',
                                    'Ingresa el costo de producción o adquisición del producto. Este costo representa el precio FOB, es decir, antes de los gastos de envío y otros costos asociados a la exportación.'
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        'Por favor ingresar el costo del producto',
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    min="0.01"
                                    placeholder="0.01"
                                    addonBefore={pricePrefixSelector}
                                    step="0.01"
                                    stringMode
                                    controls={false}
                                  />
                                </Form.Item>
                              )}
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                name="logisticService"
                                label={labelTooltip(
                                  t('calculator.logisticService.title'),
                                  t('calculator.logisticService.tooltip')
                                )}
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      'calculator.logisticService.emptyError'
                                    ),
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  className="select-before"
                                  placeholder={t(
                                    'calculator.logisticService.placeholder'
                                  )}
                                  filterOption={(input, option) =>
                                    (option?.value ?? '')
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  onSelect={(value) =>
                                    setLogisticServiceSelected(value)
                                  }
                                >
                                  {getLogisticOperators().map((service) => (
                                    <Select.Option value={service}>
                                      {service}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            {logisticServiceSelected === 'Shipsmart' && (
                              <Col span={8}>
                                <Form.Item
                                  name="harmonizedCode"
                                  label={labelTooltip(
                                    t('calculator.harmonizedCode.title'),
                                    t('calculator.harmonizedCode.tooltip')
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        'calculator.harmonizedCode.emptyError'
                                      ),
                                    },
                                  ]}
                                  initialValue="6109.10"
                                >
                                  <Input
                                    placeholder={t(
                                      'calculator.harmonizedCode.placeholder'
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                            )}
                          </Row>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  {!shippingOnly && (
                    <Row style={{ paddingBottom: '10px' }}>
                      <Col span={24}>
                        <Card className="ventas-home-listing-card">
                          <Title level={5}>
                            {t('calculator.productUnits')}
                          </Title>
                          <Row gutter={[24, 24]} style={{ marginLeft: '0' }}>
                            <Row gutter={24} style={{ width: '100%' }}>
                              <Col span={6}>
                                <Form.Item
                                  name="weight"
                                  label={labelTooltip(
                                    t('calculator.unit.weight'),
                                    t('calculator.unit.tooltip', {
                                      unit: t('calculator.unit.weight'),
                                      object: t('calculator.product'),
                                    })
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t('calculator.unit.emptyError', {
                                        unit: t(
                                          'calculator.unit.weight'
                                        ).toLowerCase(),
                                        object: t('calculator.product'),
                                      }),
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    min="0.01"
                                    max={maxUnits.weight}
                                    placeholder="0.01"
                                    addonBefore={weightPrefixSelector}
                                    step="0.01"
                                    stringMode
                                    controls={false}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name="width"
                                  label={labelTooltip(
                                    t('calculator.unit.width'),
                                    t('calculator.unit.tooltip', {
                                      unit: t('calculator.unit.width'),
                                      object: t('calculator.product'),
                                    })
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t('calculator.unit.emptyError', {
                                        unit: t(
                                          'calculator.unit.width'
                                        ).toLowerCase(),
                                        object: t('calculator.product'),
                                      }),
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    min="1"
                                    max={maxUnits.width}
                                    placeholder="1.00"
                                    addonBefore={lengthPrefixSelector}
                                    step="0.01"
                                    stringMode
                                    controls={false}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name="length"
                                  label={labelTooltip(
                                    t('calculator.unit.length'),
                                    t('calculator.unit.tooltip', {
                                      unit: t('calculator.unit.length'),
                                      object: t('calculator.product'),
                                    }),
                                    false
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t('calculator.unit.emptyError', {
                                        unit: t(
                                          'calculator.unit.length'
                                        ).toLowerCase(),
                                        object: t('calculator.product'),
                                      }),
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    min="1"
                                    max={maxUnits.length}
                                    placeholder="1.00"
                                    addonBefore={lengthPrefixSelector}
                                    step="0.01"
                                    stringMode
                                    controls={false}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6}>
                                <Form.Item
                                  name="height"
                                  label={labelTooltip(
                                    t('calculator.unit.height'),
                                    t('calculator.unit.tooltip', {
                                      unit: t('calculator.unit.height'),
                                      object: t('calculator.product'),
                                    })
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t('calculator.unit.emptyError', {
                                        unit: t(
                                          'calculator.unit.height'
                                        ).toLowerCase(),
                                        object: t('calculator.product'),
                                      }),
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    min="1"
                                    max={maxUnits.height}
                                    placeholder="1.00"
                                    addonBefore={lengthPrefixSelector}
                                    step="0.01"
                                    stringMode
                                    controls={false}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  )}
                  {(logisticSelected === 'FBO' || shippingOnly) && (
                    <Row style={{ paddingBottom: '10px' }}>
                      <Col span={24}>
                        <Card className="ventas-home-listing-card">
                          <Title level={5}>
                            {t('calculator.packaging.title')}
                          </Title>
                          <Row gutter={[24, 24]} style={{ marginLeft: '0' }}>
                            <Row gutter={24} style={{ width: '100%' }}>
                              <Col span={8}>
                                <Form.Item
                                  name="packagingType"
                                  label={labelTooltip(
                                    t('calculator.packaging.type.title'),
                                    t('calculator.packaging.type.tooltip')
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        'calculator.packaging.type.emptyError'
                                      ),
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    className="select-before"
                                    placeholder={t(
                                      'calculator.packaging.type.placeholder'
                                    )}
                                    filterOption={(input, option) =>
                                      (option?.value ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    onSelect={onSelectPackagingType}
                                    disabled={
                                      availablePackagingTypes.length === 1
                                    }
                                  >
                                    {logisticServiceSelected &&
                                      availablePackagingTypes.map(
                                        (packaging) => {
                                          const option = t(
                                            `calculator.packaging.type.${packaging.toLowerCase()}`
                                          );
                                          return (
                                            <Select.Option value={packaging}>
                                              {option[0].toUpperCase() +
                                                option.slice(1)}
                                            </Select.Option>
                                          );
                                        }
                                      )}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name="packagingUnits"
                                  label={labelTooltip(
                                    t('calculator.packaging.units.title', {
                                      packagingType: t(
                                        `calculator.packaging.type.${
                                          form
                                            .getFieldValue('packagingType')
                                            ?.toLowerCase() || 'default'
                                        }_plural`
                                      ),
                                    }),
                                    t('calculator.packaging.units.tooltip')
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        'calculator.packaging.units.emptyError',
                                        {
                                          packagingType: t(
                                            `calculator.packaging.type.${
                                              form
                                                .getFieldValue('packagingType')
                                                ?.toLowerCase() || 'default'
                                            }_plural`
                                          ),
                                        }
                                      ),
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    min="1"
                                    placeholder="1"
                                    step="1"
                                    stringMode
                                    controls
                                    disabled={unitaryPackagingServices.includes(
                                      logisticServiceSelected
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name="unitsPerPackaging"
                                  label={labelTooltip(
                                    t(
                                      'calculator.packaging.unitsPerPackaging.title',
                                      {
                                        packagingType: packagingTypeSelected,
                                      }
                                    ),
                                    t(
                                      'calculator.packaging.unitsPerPackaging.tooltip'
                                    )
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        'calculator.packaging.unitsPerPackaging.emptyError',
                                        {
                                          packagingType: packagingTypeSelected,
                                        }
                                      ),
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    min="1"
                                    placeholder="1"
                                    step="1"
                                    stringMode
                                    controls
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={24} style={{ width: '100%' }}>
                              <Col span={6} className="calculator-col">
                                <Form.Item
                                  name="packagingWeight"
                                  label={labelTooltip(
                                    t('calculator.packaging.weight.title', {
                                      packagingType: packagingTypeSelected,
                                    }),
                                    t('calculator.packaging.weight.tooltip')
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        'calculator.packaging.weight.emptyError',
                                        {
                                          packagingType: packagingTypeSelected,
                                        }
                                      ),
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    min="0.01"
                                    max={maxUnits.weight}
                                    placeholder="0.01"
                                    addonBefore={weightPrefixSelector}
                                    step="0.01"
                                    stringMode
                                    controls={false}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6} className="calculator-col">
                                <Form.Item
                                  name="packagingWidth"
                                  label={labelTooltip(
                                    t('calculator.unit.width'),
                                    t('calculator.unit.tooltip', {
                                      unit: t('calculator.unit.width'),
                                      object: t(
                                        'calculator.packaging.type.default'
                                      ),
                                    })
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t('calculator.unit.emptyError', {
                                        unit: t(
                                          'calculator.unit.width'
                                        ).toLowerCase(),
                                        object: t(
                                          'calculator.packaging.type.default'
                                        ),
                                      }),
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    min="1"
                                    max={maxUnits.width}
                                    placeholder="1.00"
                                    addonBefore={lengthPrefixSelector}
                                    step="0.01"
                                    stringMode
                                    controls={false}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6} className="calculator-col">
                                <Form.Item
                                  name="packagingLength"
                                  label={labelTooltip(
                                    t('calculator.unit.length'),
                                    t('calculator.unit.tooltip', {
                                      unit: t('calculator.unit.length'),
                                      object: t(
                                        'calculator.packaging.type.default'
                                      ),
                                    })
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t('calculator.unit.emptyError', {
                                        unit: t(
                                          'calculator.unit.length'
                                        ).toLowerCase(),
                                        object: t(
                                          'calculator.packaging.type.default'
                                        ),
                                      }),
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    min="1"
                                    max={maxUnits.length}
                                    placeholder="1.00"
                                    addonBefore={lengthPrefixSelector}
                                    step="0.01"
                                    stringMode
                                    controls={false}
                                    style={{ width: '100%' }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={6} className="calculator-col">
                                <Form.Item
                                  name="packagingHeight"
                                  label={labelTooltip(
                                    t('calculator.unit.height'),
                                    t('calculator.unit.tooltip', {
                                      unit: t('calculator.unit.height'),
                                      object: t(
                                        'calculator.packaging.type.default'
                                      ),
                                    })
                                  )}
                                  rules={[
                                    {
                                      required: true,
                                      message: t('calculator.unit.emptyError', {
                                        unit: t(
                                          'calculator.unit.height'
                                        ).toLowerCase(),
                                        object: t(
                                          'calculator.packaging.type.default'
                                        ),
                                      }),
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    min="1"
                                    max={maxUnits.height}
                                    placeholder="1.00"
                                    addonBefore={lengthPrefixSelector}
                                    step="0.01"
                                    stringMode
                                    controls={false}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  )}
                  <Row
                    gutter={24}
                    style={{ width: '100%', justifyContent: 'right' }}
                  >
                    <Form.Item>
                      <Button
                        className="btn-secondary-white"
                        onClick={resetForm}
                      >
                        {t('common.reset')}
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className="btn-primary-darkBlue"
                        htmlType="submit"
                      >
                        {t('calculator.calculate')}
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
              </Col>
              {calculating && (
                <Col span={8}>
                  <Card
                    className="ventas-home-listing-card"
                    style={{
                      marginLeft: '10px',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Spin
                      size="large"
                      spinning={calculating}
                      tip={
                        <Text className="productTitle">
                          {t('calculator.calculating')}
                        </Text>
                      }
                    />
                  </Card>
                </Col>
              )}
              {!calculating && (
                <Col span={8}>
                  <Card
                    className="ventas-home-listing-card"
                    style={{ marginLeft: '10px', height: '100%' }}
                  >
                    <Row
                      gutter={[24, 24]}
                      style={{ marginLeft: '12', marginRight: '12' }}
                    >
                      {!shippingOnly && (
                        <>
                          <Row
                            gutter={24}
                            justify="space-between"
                            style={{
                              marginLeft: '0',
                              marginRight: '0',
                              width: '100%',
                            }}
                          >
                            <Col
                              className="summaryTitle"
                              flex="auto"
                              style={{
                                paddingLeft: '0',
                                paddingRight: '0',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {t('calculator.mainTitle')}
                            </Col>
                            {session?.success && (
                              <Col
                                style={{
                                  paddingLeft: '0',
                                  paddingRight: '0',
                                }}
                              >
                                <Button
                                  type="link"
                                  onClick={onMyCalculationsOpen}
                                >
                                  {t('calculator.myCalculations')}
                                </Button>
                              </Col>
                            )}
                          </Row>
                          <Row gutter={8}>
                            <Col>
                              <Avatar
                                shape="square"
                                size={75}
                                icon={<PictureOutlined />}
                              />
                            </Col>
                            <Col className="productColumn">
                              <Row className="productTitle">
                                {calculationName ||
                                  t('calculator.newProduct.title')}
                              </Row>
                              <Row className="productSubTitle">
                                {t('calculator.newProduct.subtitle')}
                              </Row>
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row
                        gutter={[24, 8]}
                        style={{ marginLeft: '0', width: '100%' }}
                      >
                        {!shippingOnly && (
                          <Row className="priceRow">
                            <Col span={16}>{t('calculator.price.title')}</Col>
                            {calculatedFees ? (
                              <Col span={8} className="priceColumns">
                                {parseCurrency(calculatedFees.currency).format(
                                  lastPayload?.price
                                )}
                              </Col>
                            ) : (
                              <Col span={8} className="priceColumns">
                                {currencySelected} 0
                              </Col>
                            )}
                          </Row>
                        )}
                        {(lastPayload?.marketplace || marketplaceSelected) !==
                          'OTHERS' &&
                          !shippingOnly && (
                            <Row className="feeRow">
                              <Col span={16}>
                                {t('calculator.marketplaceFees')} (
                                {lastPayload?.marketplace ||
                                  marketplaceSelected}
                                )
                              </Col>
                              {calculatedFees?.totalMarketplaceFees ? (
                                <Col span={8} className="priceColumns">
                                  {`-${parseCurrency(
                                    calculatedFees.currency
                                  ).format(
                                    calculatedFees.totalMarketplaceFees
                                  )}`}
                                </Col>
                              ) : (
                                <Col span={8} className="priceColumns">
                                  - {currencySelected} 0
                                </Col>
                              )}
                            </Row>
                          )}
                        {(lastPayload
                          ? lastPayload.logistic
                          : form.getFieldValue('logistic')) === 'FBO' && (
                          <Row className="feeRow">
                            <Col span={16}>
                              {t('calculator.storageFees')} (
                              {lastPayload
                                ? lastPayload.logisticService
                                : logisticServiceSelected}
                              )
                            </Col>
                            {calculatedFees?.storageFees ? (
                              <Col span={8} className="priceColumns">
                                {`-${parseCurrency(
                                  calculatedFees.currency
                                ).format(calculatedFees.storageFees)}`}
                              </Col>
                            ) : (
                              <Col span={8} className="priceColumns">
                                - {currencySelected} 0
                              </Col>
                            )}
                          </Row>
                        )}
                        {calculatedFees?.deliveryFees && (
                          <DeliveryFees
                            shippingOnly={shippingOnly}
                            logisticService={
                              lastPayload
                                ? lastPayload.logisticService
                                : logisticServiceSelected
                            }
                            currency={calculatedFees?.deliveryFees?.currency}
                            rateWithoutDiscount={
                              calculatedFees?.deliveryFees
                                ?.totalRateWithoutDiscount?.value
                            }
                            rateWithDiscount={
                              calculatedFees?.deliveryFees?.totalRate
                            }
                            discount={
                              calculatedFees?.deliveryFees
                                ?.totalRateWithoutDiscount?.discount
                            }
                            detailedRates={calculatedFees?.deliveryFees?.rate}
                          />
                        )}

                        {(lastPayload
                          ? lastPayload.logistic
                          : form.getFieldValue('logistic')) === 'FBO' && (
                          <Row className="feeRow">
                            <Col span={16}>
                              {t('calculator.unitRestockFees')}
                            </Col>
                            {calculatedFees?.unitRestockFees?.ACCOUNT ? (
                              <Col
                                span={8}
                                className={`priceColumns ${
                                  calculatedFees.unitRestockFees.LIST
                                    ? 'strikedFee'
                                    : ''
                                }`}
                              >
                                <Space>
                                  <Col>
                                    {`-${parseCurrency(
                                      calculatedFees.currency
                                    ).format(
                                      calculatedFees.unitRestockFees.LIST ||
                                        calculatedFees.unitRestockFees.ACCOUNT
                                    )}`}
                                  </Col>
                                  <Col>
                                    <Tooltip
                                      title={
                                        <>
                                          <Row>
                                            {t('calculator.totalRestockFees')}
                                          </Row>
                                          <Row>
                                            <Space>
                                              {t('calculator.totalUnits', {
                                                units: (
                                                  calculatedFees.restockFees[0]
                                                    .totalRateWithoutDiscount
                                                    .value /
                                                  calculatedFees.unitRestockFees
                                                    .LIST
                                                ).toFixed(),
                                              })}
                                              <Text>
                                                {`${parseCurrency(
                                                  calculatedFees.currency
                                                ).format(
                                                  calculatedFees.restockFees[0]
                                                    .totalRateWithoutDiscount
                                                    .value ||
                                                    calculatedFees
                                                      .unitRestockFees.LIST
                                                )}`}
                                              </Text>
                                            </Space>
                                          </Row>
                                        </>
                                      }
                                      placement="topRight"
                                    >
                                      <InfoCircleOutlined />
                                    </Tooltip>
                                  </Col>
                                </Space>
                              </Col>
                            ) : (
                              <Col span={8} className="priceColumns">
                                - {currencySelected} 0
                              </Col>
                            )}
                            {calculatedFees?.unitRestockFees?.LIST && (
                              <>
                                <Col
                                  span={15}
                                  offset={1}
                                  className="priceRow"
                                  style={{ fontSize: 13 }}
                                >
                                  {t('calculator.withLAPDiscount')}
                                  <Tag
                                    color="#00E5A6"
                                    style={{
                                      borderRadius: 8,
                                      color: '#010C33',
                                      fontWeight: 700,
                                      marginLeft: 8,
                                      fontSize: 11,
                                    }}
                                  >
                                    {Math.ceil(
                                      calculatedFees.restockFees[0]
                                        .totalRateWithoutDiscount.discount * 100
                                    )}
                                    % off
                                  </Tag>
                                </Col>
                                <Col span={8} className="priceColumns">
                                  <Space>
                                    <Col>
                                      {`-${parseCurrency(
                                        calculatedFees.currency
                                      ).format(
                                        calculatedFees.unitRestockFees.ACCOUNT
                                      )}`}
                                    </Col>
                                    <Col>
                                      <Tooltip
                                        title={
                                          <>
                                            <Row>
                                              {t('calculator.totalRestockFees')}
                                            </Row>
                                            <Row>
                                              <Space>
                                                {t('calculator.totalUnits', {
                                                  units: (
                                                    calculatedFees
                                                      .restockFees[0]
                                                      .totalRate /
                                                    calculatedFees
                                                      .unitRestockFees.ACCOUNT
                                                  ).toFixed(),
                                                })}
                                                <Text>
                                                  {`${parseCurrency(
                                                    calculatedFees.currency
                                                  ).format(
                                                    calculatedFees
                                                      .restockFees[0].totalRate
                                                  )}`}
                                                </Text>
                                              </Space>
                                            </Row>
                                          </>
                                        }
                                        placement="topRight"
                                      >
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </Col>
                                  </Space>
                                </Col>
                              </>
                            )}
                          </Row>
                        )}
                        {deliveryFeesOptions?.length > 1 && (
                          <Row className="feeRow">
                            <Col
                              offset={1}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {t('calculator.serviceTitle')}
                            </Col>
                            <Col offset={1} span={12}>
                              <Select
                                style={{ width: '100%' }}
                                options={deliveryFeesOptions?.map(
                                  (option, i) => {
                                    return {
                                      value: i,
                                      label: option.serviceName,
                                    };
                                  }
                                )}
                                value={deliveryCarrierSelected}
                                onSelect={(value) => {
                                  setDeliveryCarrierSelected(value);
                                  setCalculatedFees({
                                    ...calculatedFees,
                                    deliveryFees: deliveryFeesOptions?.[value],
                                  });
                                }}
                              />
                            </Col>
                            <Col
                              offset={1}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Tooltip
                                title={t('calculator.serviceTooltip')}
                                placement="topRight"
                              >
                                <InfoCircleOutlined />
                              </Tooltip>
                            </Col>
                          </Row>
                        )}
                      </Row>
                    </Row>
                    <Divider />
                    {!shippingOnly && (
                      <Row className="marginRow">
                        <Col span={16}>{t('calculator.margin')}</Col>
                        {calculatedFees ? (
                          <Col span={8} className="marginColumn">
                            {parseCurrency(calculatedFees.currency).format(
                              lastPayload.price -
                                // lastPayload.cost -
                                calculatedFees.totalMarketplaceFees -
                                (calculatedFees.unitRestockFees.ACCOUNT ?? 0) -
                                (calculatedFees.storageFees ?? 0) -
                                (calculatedFees.deliveryFees?.totalRate ?? 0)
                            )}
                          </Col>
                        ) : (
                          <Col span={8} className="priceColumns">
                            {currencySelected} 0
                          </Col>
                        )}
                      </Row>
                    )}
                    {session?.success && lastPayload && !shippingOnly && (
                      <Row>
                        <Button
                          type="text"
                          className="print-button"
                          onClick={() => onSaveCalculationOpen()}
                          loading={saving}
                          style={{ width: '100%', marginTop: '20px' }}
                          disabled={!canSave}
                        >
                          <Space>
                            <SaveOutlined style={{ color: '#010C33' }} />
                            {t('calculator.saveCalculation.title')}
                          </Space>
                        </Button>
                      </Row>
                    )}
                    <Row>
                      <Col span={24} style={{ marginTop: '30px' }}>
                        <List
                          itemLayout="horizontal"
                          dataSource={[{}]}
                          renderItem={() => (
                            <List.Item
                              style={{
                                background: 'rgba(99, 185, 240, .1)',
                                paddingRight: 30,
                              }}
                            >
                              <List.Item.Meta
                                avatar={
                                  <WarningOutlined
                                    className="iconWarningDisclaimer"
                                    style={{
                                      marginLeft: '15px',
                                      marginTop: '15px',
                                      fontSize: '30px',
                                    }}
                                  />
                                }
                                description={descriptionMeta(
                                  <Text
                                    style={{ fontSize: 12, fontWeight: 400 }}
                                  >
                                    {t('calculator.disclaimer.description')}
                                    <strong>
                                      {t('calculator.disclaimer.warning')}
                                    </strong>
                                    <br />
                                    <br />
                                    {t('calculator.disclaimer.seeMore')}
                                    <a
                                      style={{
                                        color: '#00E5A6',
                                        fontWeight: 'bold',
                                      }}
                                      target="_blank"
                                      href="https://ayuda.lapmarketplace.com/hc/es-cl/articles/16180902787995"
                                      rel="noreferrer"
                                    >
                                      {t('common.seeMore')}
                                    </a>
                                  </Text>
                                )}
                              />
                            </List.Item>
                          )}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
      {session?.success && (
        <>
          <Modal
            open={saveCalculationOpen}
            onCancel={() => onSaveCalculationClose()}
            width="30%"
            okButtonProps={{ disabled: true, style: { display: 'none' } }}
            cancelButtonProps={{ disabled: true, style: { display: 'none' } }}
          >
            <Row justify="center">
              <Space direction="vertical" size="middle">
                <Typography.Title level={4} style={{ textAlign: 'center' }}>
                  {t('calculator.saveCalculation.title')}
                </Typography.Title>
                <Text>{t('calculator.saveCalculation.helper')}</Text>
                <Input
                  value={calculationName}
                  placeholder={t('calculator.saveCalculation.placeholder')}
                  onChange={(e) => setCalculationName(e.target.value)}
                />
                <Button
                  type="text"
                  className="print-button"
                  onClick={onSaveCalculation}
                  loading={saving}
                  style={{ width: '100%', marginTop: '5px' }}
                  disabled={!canSave}
                >
                  <Space>
                    <SaveOutlined style={{ color: '#010C33' }} />
                    {t('common.save')}
                  </Space>
                </Button>
              </Space>
            </Row>
          </Modal>
          <Modal
            open={myCalculationsOpen}
            onCancel={() => onMyCalculationsClose()}
            width="40%"
            okButtonProps={{ disabled: true, style: { display: 'none' } }}
            cancelButtonProps={{ disabled: true, style: { display: 'none' } }}
          >
            <Row justify="center">
              <Space
                direction="vertical"
                size="middle"
                style={{ width: '100%' }}
              >
                <Typography.Title level={4} style={{ textAlign: 'center' }}>
                  {t('calculator.myCalculations')}
                </Typography.Title>
                <Search
                  placeholder={t('calculator.findCalculations')}
                  allowClear
                  onSearch={onCalculationsSearch}
                />
                <List
                  bordered
                  dataSource={myCalculations?.results}
                  renderItem={(item) => (
                    <List.Item>
                      <Row justify="space-between" style={{ width: '100%' }}>
                        <Col>
                          <Space direction="vertical">
                            {item.name}
                            <Row>
                              <Space>
                                <Tag>{item.inputs.marketplace ?? 'Otros'}</Tag>•
                                <Tag color="#ffffff" style={{ color: 'black' }}>
                                  {moment(item.createdAt).format('DD-MM-YYYY')}
                                </Tag>
                              </Space>
                            </Row>
                          </Space>
                        </Col>
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                          <Space>
                            <Button
                              onClick={() => onLoadCalculation(item.id)}
                              size="small"
                            >
                              {t('common.load')}
                            </Button>
                            <Button
                              loading={loadingDelete}
                              onClick={() => onDeleteCalculation(item.id)}
                              icon={<DeleteOutlined />}
                              size="small"
                            />
                          </Space>
                        </Col>
                      </Row>
                    </List.Item>
                  )}
                />
                <Pagination
                  style={{ display: 'flex', justifyContent: 'center' }}
                  pageSize={4}
                  current={myCalculationsFilter?.page || 1}
                  showTotal={(total) => `${total} resultados`}
                  total={myCalculations?.totalResults}
                  onChange={onCalculationsPaging}
                />
              </Space>
            </Row>
          </Modal>
        </>
      )}
    </>
  );
}

export default Calculadora;

import { Col, Form, Input, Row, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import 'antd/dist/antd.min.css';
import PropTypes from 'prop-types';

function ShipsmartSpecificInfo({
  originCountryCode,
  destinationCountryCode,
  checkPayerTaxes,
  display,
  colSpan,
}) {
  const { t } = useTranslation();
  const { Item } = Form;

  return (
    <div id="shipsmart-specific-info" style={{ display: `${display}` }}>
      <Row gutter={[16, 16]}>
        <Col span={colSpan}>
          <Item
            className="input-form-margin-bottom"
            name="insurance"
            label={t('shipsmart.hireInsurance')}
            rules={[
              {
                required: true,
                message: 'Requerido',
              },
            ]}
            tooltip={{
              title: t('orders.shipment.unitMeasurement.title'),
              icon: <InfoCircleOutlined />,
            }}
            initialValue="false"
          >
            <Select defaultActiveFirstOption defaultValue="false">
              <Select.Option value="true">{t('common.yes')}</Select.Option>
              <Select.Option value="false">{t('common.no')}</Select.Option>
            </Select>
          </Item>
        </Col>

        {checkPayerTaxes && (
          <Col span={colSpan}>
            <Item
              className="input-form-margin-bottom"
              name="taxPayer"
              label={t('shipsmart.taxesPayer')}
              rules={[
                {
                  required: true,
                  message: 'Requerido',
                },
              ]}
              tooltip={{
                title: t('orders.shipment.unitMeasurement.title'),
                icon: <InfoCircleOutlined />,
              }}
              initialValue="sender"
            >
              <Select defaultActiveFirstOption defaultValue="Remitente">
                <Select.Option value="sender">
                  {t('shipsmart.sender')}
                </Select.Option>
                <Select.Option value="receiver">
                  {t('shipsmart.receiver')}
                </Select.Option>
              </Select>
            </Item>
          </Col>
        )}

        {(originCountryCode === 'BR' || destinationCountryCode === 'BR') && (
          <Col span={colSpan}>
            <Item
              className="input-form-margin-bottom"
              name="notaFiscal"
              label={t('shipsmart.fiscalNote')}
              rules={[
                {
                  required: true,
                  message: 'Requerido',
                },
                {
                  len: 44,
                  message: 'Debe tener 44 caracteres',
                },
              ]}
            >
              <Input />
            </Item>
          </Col>
        )}
        {(originCountryCode === 'BR' ||
          originCountryCode === 'AR' ||
          destinationCountryCode === 'BR' ||
          destinationCountryCode === 'AR') && (
          <Col span={colSpan}>
            <Item
              className="input-form-margin-bottom"
              name="federalTaxId"
              label={t('shipsmart.federalTax')}
              rules={[
                {
                  required: true,
                  message: 'Requerido',
                },
              ]}
            >
              <Input />
            </Item>
          </Col>
        )}
        {(originCountryCode === 'BR' || destinationCountryCode === 'BR') && (
          <Col span={colSpan}>
            <Item
              className="input-form-margin-bottom"
              name="stateTaxId"
              label={t('shipsmart.federalId')}
              rules={[
                {
                  required: true,
                  message: 'Requerido',
                },
              ]}
            >
              <Input />
            </Item>
          </Col>
        )}
      </Row>
    </div>
  );
}

ShipsmartSpecificInfo.propTypes = {
  originCountryCode: PropTypes.string.isRequired,
  destinationCountryCode: PropTypes.string.isRequired,
  colSpan: PropTypes.number,
  checkPayerTaxes: PropTypes.bool,
  display: PropTypes.string,
};

ShipsmartSpecificInfo.defaultProps = {
  checkPayerTaxes: true,
  display: 'none',
  colSpan: 12,
};

export default ShipsmartSpecificInfo;

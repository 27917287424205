import api from './api';

const prefix = '/accessKeys';

const accessKeysApi = {
  getUserCredentials(id, marketplace, type) {
    return api.get(`${prefix}`, {
      params: {
        marketplace,
        type,
      },
    });
  },
  getUserMarketplaceCredentials() {
    return api.get(`${prefix}/user/marketplace-credentials`);
  },
  create(sellerAccount) {
    return api.post(`${prefix}`, sellerAccount);
  },
  update(id, sellerAccount) {
    return api.put(`${prefix}/user/${id}`, sellerAccount);
  },
  getConsentUrl(id, marketplace) {
    return api.get(
      `${prefix}/sellerMarketplace/${marketplace.toLowerCase()}/consentUrl`
    );
  },
  forgetCredentials(id, credentialId) {
    return api.delete(`${prefix}/user/${id}/sellerMarketplace/${credentialId}`);
  },
  saveCredentials(id, marketplace, payload) {
    return api.post(
      `${prefix}/user/${id}/sellerMarketplace/${marketplace.toLowerCase()}`,
      payload
    );
  },
  verifyCredentials(id, marketplace, payload) {
    return api.get(
      `${prefix}/user/${id}/sellerMarketplace/${marketplace.toLowerCase()}/verifycrendentials`,
      payload
    );
  },
  getAdsConsentUrl(marketplace) {
    return api.get(
      `${prefix}/sellerMarketplace/${marketplace.toLowerCase()}/ads/consentUrl`
    );
  },
  saveAdsCredentials(id, marketplace, payload) {
    return api.post(
      `${prefix}/user/${id}/sellerMarketplace/${marketplace.toLowerCase()}/ads/credentials`,
      payload
    );
  },
  forgetAdsCredentials(id, marketplace) {
    return api.delete(
      `${prefix}/user/${id}/sellerMarketplace/${marketplace.toLowerCase()}/ads`
    );
  },
};
export default accessKeysApi;

import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import couriersAPI from '../../../../api/courier';
import RevealEyeButton from '../../../../components/RevealEyeButton';
import Int99ShipmentTypesList from './Int99ShipmentTypesList';

function Int99MinutosForm({
  loading,
  name,
  onFinish,
  courierCredentials,
  shipmentTypes,
}) {
  const [form] = useForm();
  const [credentials, setCredentials] = useState(undefined);
  const [showSecret, setShowSecret] = useState(false);
  const [loadingTypes, setLoadingTypes] = useState(false);

  useEffect(() => {
    if (courierCredentials && courierCredentials.credentials)
      setCredentials({
        client_id: courierCredentials.credentials.client_id,
        client_secret: courierCredentials.credentials.client_secret,
      });
  }, [courierCredentials]);

  useEffect(() => {
    if (credentials)
      form.setFieldsValue({
        client_id: credentials.client_id,
        client_secret: credentials.client_secret,
      });
  }, [credentials]);

  const updateShipmentTypesOrder = async (data) => {
    setLoadingTypes(true);
    await couriersAPI
      .updateCourierShipmentTypes(name, data)
      .then(() => {
        setLoadingTypes(false);
      })
      .catch(() => {
        setLoadingTypes(false);
      });
  };

  return (
    <>
      <Form
        form={form}
        name={`${name}-form`}
        layout="vertical"
        labelCol={{
          span: 8,
          offset: 0,
        }}
        wrapperCol={{
          span: 20,
          offset: 0,
        }}
        initialValues={{
          remember: true,
          fbm: true,
          fbo: false,
        }}
        onFinish={onFinish}
        autoComplete="off"
        style={{
          marginBottom: 20,
        }}
      >
        <h1
          style={{
            fontWeight: 600,
            marginBottom: 40,
            marginTop: 15,
          }}
        >
          Conectar cuenta 99Minutos
        </h1>
        <span
          style={{
            paddingLeft: 25,
            maxWidth: '100%',
            textAlign: 'left',
            fontSize: '18px',
            marginBottom: 30,
          }}
        >
          Client Id
        </span>
        <Form.Item
          name="client_id"
          labelAlign="left"
          wrapperCol={{
            span: 24,
          }}
          style={{
            fontSize: '18px',
            maxWidth: '100%',
            paddingLeft: 25,
            paddingRight: 25,
            marginBottom: 18,
          }}
          rules={[
            {
              required: true,
              message: 'Ingrese su cliente id de 99Minutos',
            },
          ]}
        >
          <Input
            defaultValue={credentials ? credentials.client_id : ''}
            onChange={(e) => form.setFieldValue('client_id', e.target.value)}
            size="large"
            style={{ borderRadius: 10, marginTop: 6 }}
          />
        </Form.Item>
        <span
          style={{
            paddingLeft: 25,
            maxWidth: '100%',
            textAlign: 'left',
            fontSize: '18px',
            marginBottom: 30,
          }}
        >
          Client Secret
        </span>
        <Form.Item
          name="client_secret"
          rules={[
            {
              required: true,
              message: 'Ingrese su cliente secret de 99Minutos',
            },
          ]}
          wrapperCol={{
            span: 24,
          }}
          style={{
            fontSize: '18px',
            maxWidth: '100%',
            paddingLeft: 25,
            paddingRight: 25,
          }}
        >
          <Input
            defaultValue={credentials ? credentials.client_secret : ''}
            type={showSecret ? 'text' : 'password'}
            onChange={(e) =>
              form.setFieldValue('client_secret', e.target.value)
            }
            size="large"
            style={{ borderRadius: 10, marginTop: 6 }}
          />
          <div style={{ position: 'absolute', right: '-35px', top: '24%' }}>
            <RevealEyeButton
              onClick={() => setShowSecret(!showSecret)}
              show={showSecret}
            />
          </div>
        </Form.Item>
        <Form.Item
          label="El courier es FBM?"
          name="fbm"
          style={{ display: 'none' }}
        />
        <Form.Item
          label="El courier es FBO?"
          name="fbo"
          style={{ display: 'none' }}
        />
        <div
          style={{
            fontSize: 17,
            marginTop: 30,
            textAlign: 'center',
          }}
        >
          <span style={{ color: '#969696' }}>¿Aún no tienes una cuenta?</span>
          <Button
            target="_blank"
            href="https://ayuda.lapmarketplace.com/hc/es-cl/articles/14265207898011"
            type="link"
            block
          >
            <span
              style={{
                color: '#63B9F0',
                fontSize: 17,
              }}
            >
              Ver el paso a paso
            </span>
          </Button>
        </div>
        <Form.Item
          wrapperCol={{
            span: 24,
          }}
          style={{
            marginTop: 35,
            textAlign: 'center',
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{
              height: '50px',
              width: '30%',
            }}
          >
            {courierCredentials ? 'Actualizar' : 'Conectar'}
          </Button>
        </Form.Item>
      </Form>
      <Int99ShipmentTypesList
        shipmentTypes={shipmentTypes}
        loadingTypes={loadingTypes}
        onUpdate={updateShipmentTypesOrder}
      />
    </>
  );
}

export default Int99MinutosForm;

Int99MinutosForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  courierCredentials: PropTypes.instanceOf(Object),
  shipmentTypes: PropTypes.instanceOf(Array).isRequired,
};

Int99MinutosForm.defaultProps = {
  courierCredentials: null,
};

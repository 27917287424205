import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import RevealEyeButton from '../../../../components/RevealEyeButton';

function ShipsmartForm({ loading, name, onFinish, courierCredentials }) {
  const [form] = useForm();
  const [showSecret, setShowSecret] = useState(false);

  return (
    <Form
      form={form}
      name={`${name}-form`}
      layout="vertical"
      labelCol={{
        span: 8,
        offset: 0,
      }}
      wrapperCol={{
        span: 20,
        offset: 0,
      }}
      initialValues={{
        remember: true,
        fbm: true,
        fbo: false,
      }}
      onFinish={onFinish}
      autoComplete="off"
      style={{
        marginBottom: 20,
      }}
    >
      <h1
        style={{
          fontWeight: 600,
          marginBottom: 40,
          marginTop: 15,
        }}
      >
        Conectar cuenta {name}
      </h1>

      <span
        style={{
          paddingLeft: 25,
          maxWidth: '100%',
          textAlign: 'left',
          fontSize: '18px',
          marginBottom: 30,
        }}
      >
        Client Secret
      </span>
      <Form.Item
        name="client_secret"
        rules={[
          {
            required: true,
            message: `Ingrese su client secret de ${name}`,
          },
        ]}
        wrapperCol={{
          span: 24,
        }}
        style={{
          fontSize: '18px',
          maxWidth: '100%',
          paddingLeft: 25,
          paddingRight: 25,
        }}
      >
        <Input
          defaultValue=""
          type={showSecret ? 'text' : 'password'}
          onChange={(e) => form.setFieldValue('client_secret', e.target.value)}
          size="large"
          style={{ borderRadius: 10, marginTop: 6 }}
        />
        <div style={{ position: 'absolute', right: '-35px', top: '24%' }}>
          <RevealEyeButton
            onClick={() => setShowSecret(!showSecret)}
            show={showSecret}
          />
        </div>
      </Form.Item>

      <Form.Item
        label="El courier es FBM?"
        name="fbm"
        style={{ display: 'none' }}
      />

      <Form.Item
        label="El courier es FBO?"
        name="fbo"
        style={{ display: 'none' }}
      />

      {/* <div
          style={{
            fontSize: 17,
            marginTop: 30,
            textAlign: 'center',
          }}
        >
          <span style={{ color: '#969696' }}>¿Aún no tienes una cuenta?</span>
          <Button
            target="_blank"
            href="https://ayuda.lapmarketplace.com/hc/es-cl/articles/14265207898011"
            type="link"
            block
          >
            <span
              style={{
                color: '#63B9F0',
                fontSize: 17,
              }}
            >
              Ver el paso a paso
            </span>
          </Button>
        </div> */}

      <Form.Item
        wrapperCol={{
          span: 24,
        }}
        style={{
          marginTop: 35,
          textAlign: 'center',
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{
            height: '50px',
            width: '30%',
          }}
        >
          {courierCredentials ? 'Actualizar' : 'Conectar'}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ShipsmartForm;

ShipsmartForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  courierCredentials: PropTypes.instanceOf(Object).isRequired,
};

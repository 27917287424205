import api from './api';

const prefix = '/third-party-shipment';
const thirdPartyShipmentAPI = {
  getThirdPartyShipmentById: (thirdPartyShipmentId) =>
    api.get(`${prefix}/${thirdPartyShipmentId}`),
  getThirdPartyShipments: () => api.get(`${prefix}`),
  createThirdPartyShipment: (payload = null) => api.post(`${prefix}/`, payload),
  updateThirdPartyShipmentById: (thirdPartyShipmentId, payload) =>
    api.put(`${prefix}/${thirdPartyShipmentId}`, payload),
  cancelThirdPartyShipmentById: (thirdPartyShipmentId) =>
    api.delete(`${prefix}/${thirdPartyShipmentId}`),
};
export default thirdPartyShipmentAPI;

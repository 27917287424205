import { Skeleton } from 'antd';

function MySellerAccountFiltersSkeleton() {
  return (
    <div
      style={{
        display: 'flex',
        marginBottom: '40px',
        gap: '35px',
        marginTop: '20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '20%',
          gap: '8px',
        }}
      >
        <Skeleton.Input active style={{ height: 15, width: '10px' }} />
        <Skeleton.Button active style={{ width: '100%', height: 30 }} />
      </div>
    </div>
  );
}

export default MySellerAccountFiltersSkeleton;

import React, { useEffect, useState } from 'react';
import { Row, Col, Layout, Form, Button, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CompanyData from './components/steps/CompanyData';
import { initialMyAccountData } from '../../utils/const';
import { mixPanelCreateEvent, checkProfile } from '../../utils/functions';
import openNotification from '../../components/Toastr';
import partnerApi from '../../api/partner';
import * as Actions from '../../redux/partner/action';
// import './myAccount.css';
import './myAccountNew.css';
import companiesAPI from '../../api/company';
import usersAPI from '../../api/users';
// import MySellerAccount from './components/steps/MySellerAccount';
import MySellerAccount from './components/steps/MySellerAccountNew';
import { getErrorMessage } from '../../api/api';

const { Content } = Layout;

export default function MyAccount() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { marketplace, consent } = useParams();
  const session = useSelector((store) => store.Session.session);
  const partner = useSelector((store) => store.Partner.partner);
  const myAccountDataPersisted = useSelector(
    (store) => store.Partner.myAccountData
  );
  const { t } = useTranslation();

  const [selected, setSelected] = useState(-1);
  const [myAccountData, setMyAccountData] = useState(initialMyAccountData);
  const [mySellerAccount, setMySellerAccount] = useState();
  const [profileCompleted, setProfileCompleted] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [form] = Form.useForm();

  const searchParams = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );
  const linkToStep = searchParams?.get('step');
  const linkToMarketplace = searchParams?.get('tomarketplace');

  useEffect(() => {
    if (linkToStep) {
      if (Number(linkToStep) >= 2) {
        setSelected(1);
      } else {
        setSelected(Number(linkToStep) - 1);
      }
    }
  }, [linkToStep, profileCompleted, myAccountData.id]);

  useEffect(() => {
    if (marketplace && consent) {
      setSelected(1);
    }
  }, [marketplace, consent]);

  useEffect(() => {
    const getPartnerData = async () => {
      try {
        const response = await partnerApi.findById(
          session.userInfo.commercial_partner_id[0]
        );
        const companyAddress = await companiesAPI.getCompanyAddress(
          session.userInfo.commercial_partner_id[0]
        );
        delete response.data.x_product_template_ids;
        const data = {
          ...response.data,
          address: { ...companyAddress?.data },
        };
        dispatch(Actions.getPartnerSuccess(data));
        setProfileCompleted(checkProfile(data, session.userInfo));
      } catch (error) {
        dispatch(Actions.getPartnerFailed(error));
      }
    };

    if (session) {
      dispatch(Actions.getPartner());
      getPartnerData();
    }
  }, [selected]);

  useEffect(() => {
    if (myAccountDataPersisted || partner) {
      const dataPerAccount = !myAccountDataPersisted
        ? partner
        : myAccountDataPersisted;
      delete dataPerAccount.bank_ids;

      const vat = dataPerAccount.ref ? dataPerAccount.ref : dataPerAccount.vat;
      setMyAccountData({
        ...dataPerAccount,
        company_id: session?.userInfo?.company_id[0],
        id: session?.userInfo?.commercial_partner_id[0],
        ref: vat || '',
        zip: !dataPerAccount.zip ? null : dataPerAccount.zip,
        industry_id: !dataPerAccount.industry_id
          ? null
          : dataPerAccount.industry_id,
        birthday: session?.userInfo?.birthday || null,
        passport_id: session?.userInfo?.passport_id || null,
        x_fantasy_name:
          myAccountDataPersisted?.x_fantasy_name ||
          partner?.x_fantasy_name ||
          null,
        x_employees_number:
          myAccountDataPersisted?.x_employees_number ||
          partner?.x_employees_number ||
          null,
        x_sales_range:
          myAccountDataPersisted?.x_sales_range ||
          partner?.x_sales_range ||
          null,
        phone: session?.userInfo?.phone || null,
        name: session?.userInfo?.name || null,
        country_id:
          myAccountDataPersisted?.country_id || partner?.country_id || null,
      });
    } else if (session) {
      setMyAccountData({
        ...initialMyAccountData,
        company_id: session.userInfo.company_id[0],
        id: session.userInfo.commercial_partner_id[0],
      });
    }
  }, [partner, myAccountDataPersisted]);

  const saveBillingInformation = async (values) => {
    const updateCompanyPayload = {
      contactName: values.contactName,
      name: values.name,
      email: values.email,
      fantasyName: values.fantasyName,
      ref: values.ref,
      industry: values.industry,
      countryCode: values.countryCode,
    };
    const updateCompanyAddressPayload = {
      streetName: values.streetName,
      additionalAddressInfo: values.additionalAddressInfo,
      city: values.city,
      countryCode: values.countryCode,
      stateOrProvinceCode: values.stateOrProvinceCode ?? values.countryCode,
      postalCode: values.postalCode,
    };
    const companyId = session.userInfo.company_id[0];
    // Update Company columns
    try {
      const response = await companiesAPI.update(
        companyId,
        updateCompanyPayload
      );
      mixPanelCreateEvent('API Rest', {
        status: true,
        message: 'Update Company',
        method: 'PUT',
        url: response.config.url,
      });
    } catch (error) {
      openNotification({ status: false });
    }
    // Upsert company address
    try {
      const method = currentAddress
        ? companiesAPI.updateCompanyAddress
        : companiesAPI.createCompanyAddress;
      const response = await method(companyId, updateCompanyAddressPayload);
      setCurrentAddress(response.data);
      mixPanelCreateEvent('API Rest', {
        status: true,
        message: currentAddress
          ? 'Update Company Address'
          : 'Create Company Address',
        method: currentAddress ? 'PUT' : 'POST',
        url: response.config.url,
      });
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
  };

  const saveUserAccountInformation = async (values) => {
    const payload = {
      name: values.userName,
    };
    try {
      const response = await usersAPI.update(session.userInfo.id, payload);
      mixPanelCreateEvent('API Rest', {
        status: true,
        message: 'Update User',
        method: 'PUT',
        url: response.config.url,
      });
    } catch (error) {
      openNotification({
        status: false,
        content: t('myAccount.save.error'),
      });
    }
  };

  const onFinish = async (values) => {
    await saveBillingInformation(values);
    await saveUserAccountInformation(values);
    openNotification({
      status: true,
      content: t('myAccount.save.success'),
    });
  };

  useEffect(() => {
    if (selected === 1) {
      dispatch(Actions.savePartnerData(myAccountData));
    }
  }, [selected]);

  return (
    <div className="content-div initialContent">
      <Row>
        <Col span={24}>
          <Layout>
            <Layout className="padding-layout">
              <Content
                className="site-layout-background padding-layout-content"
                style={{
                  padding: 0,
                }}
              >
                {selected === 0 && (
                  <Col span={24}>
                    <Space direction="vertical">
                      <CompanyData
                        form={form}
                        onFinish={onFinish}
                        showBillingForm
                        showAccountForm
                        setCurrentAddress={setCurrentAddress}
                      />
                      <Button
                        style={{ marginTop: 10 }}
                        type="primary"
                        onClick={() => form.submit()}
                      >
                        {t('myAccount.save.btn')}
                      </Button>
                    </Space>
                  </Col>
                )}
                {selected === 1 && (
                  <MySellerAccount
                    mySellerAccount={mySellerAccount}
                    setMySellerAccount={setMySellerAccount}
                    setSelected={setSelected}
                    selected={selected}
                    linkToMarketplace={linkToMarketplace}
                  />
                )}
              </Content>
            </Layout>
          </Layout>
        </Col>
      </Row>
    </div>
  );
}

import OrderSales from './components/OrderSales';

function Pedidos() {
  return (
    <div className="initialContent">
      <OrderSales />
    </div>
  );
}

export default Pedidos;

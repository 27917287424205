import api from './api';

const prefix = '/categories';

export default {
  getAll() {
    return api.get(`${prefix}`);
  },
  getByMarketplaceAndSearch(marketplace, search) {
    return api.get(
      `${prefix}/marketplace/${marketplace}/suggestions/${search}`
    );
  },
  getProductTypeDefinition(partnerId, marketplace, value) {
    return api.get(
      `${prefix}/partner/${partnerId}/marketplace/${marketplace}/productType/${value}/definition`
    );
  },
  getAttributesByMarketplace(partnerId, marketplace, value) {
    return api.get(
      `${prefix}/partner/${partnerId}/marketplace/${marketplace}/category/${value}/attributes`
    );
  },
  getMatchedCategories(payload) {
    return api.post(`${prefix}/match`, payload);
  },
};

import api from './api';

const prefix = 'benchmark';

const benchmarkApi = {
  getBenchmark(params) {
    return api.get(`${prefix}`, { params });
  },
  classifyCompetitor(competitor, marketplace) {
    return api.post(`${prefix}/classifyCompetitor`, {
      competitor,
      marketplace,
    });
  },
  generateReport(competitors) {
    return api.post(
      `${prefix}/generate-report`,
      { competitors },
      {
        responseType: 'blob',
      }
    );
  },
};

export default benchmarkApi;
